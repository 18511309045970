import * as React from "react";

import { Icon, Label } from "semantic-ui-react";
import { FaRegArrowAltCircleLeft, FaSpinner } from "react-icons/fa";
import { useHistory, useRouteMatch } from "react-router-dom";

import LogoutModal from "../../../components/Modals/LogoutModal";
import NewServicesModal from "../../../components/Modals/NewServicesModal";

import styles from "./UserDashboard.module.css";
import { useStoreActions, useStoreState } from "../../../hooks";
import { FaUsers, FaRegHandshake, FaDollarSign } from "react-icons/fa";
import { getPaymentMethods } from "../../../api/requests";

const { useCallback, useEffect, useState } = React;

function UserDashboard() {
  const history = useHistory();
  const match = useRouteMatch();

  const userId = useStoreState((state) => state.authStore.authObject.userId);
  const centerId = useStoreState((state) => state.nqaStore.serviceDetails.id);
  const currentUser = useStoreState((state) => state.userStore.loggedInUser);
  const selectedUser = useStoreState((state) => state.userStore.selectedUser);

  const authObj = useStoreState((state) => state.authStore.authObject);

  const hasPaymentMethods = useStoreState(
    (state) => state.userStore.hasPaymentMethods
  );
  const paymentMethods = useStoreState(
    (state) => state.userStore.paymentMethods
  );

  const setSelectedUser = useStoreActions(
    (actions) => actions.userStore.setSelectedUser
  );
  const fetchUserDetails = useStoreActions(
    (actions) => actions.userStore.fetchUserDetails
  );

  const [userLoading, setLoading] = useState(selectedUser ? false : true);
  const [logoutModalVisibility, setLogoutModalVisibility] = useState(false);
  const [newServicesModalVisibility, setNewServicesModalVisibility] =
    useState(false);

  const onUpdateDetails = () => {
    setSelectedUser(currentUser);
    history.push(`${match.path}/update-user`);
    window.scrollTo({ top: 0 });
  };

  const getUserDetails = useCallback(async () => {
    try {
      await fetchUserDetails({
        centerId,
        userId,
      });
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  }, [centerId, fetchUserDetails, userId]);

  useEffect(() => {
    getUserDetails();
  }, [getUserDetails]);

  return userLoading ? (
    <div
      className={`w-full h-screen flex flex-col justify-center items-center`}
    >
      <FaSpinner className={`animate-spin text-qipGreen font-bold text-6xl`} />
      <span className={`block`}> Loading data...</span>
    </div>
  ) : (
    <div className={styles.mainContainer}>
      {logoutModalVisibility && (
        <LogoutModal
          visibility={logoutModalVisibility}
          onOpenModal={() => setLogoutModalVisibility(true)}
          onCloseModal={() => setLogoutModalVisibility(false)}
        />
      )}
      {newServicesModalVisibility && (
        <NewServicesModal
          visibility={newServicesModalVisibility}
          onOpenModal={() => setNewServicesModalVisibility(true)}
          onCloseModal={() => setNewServicesModalVisibility(false)}
        />
      )}

      <div className={styles.backButtonContainer}>
        <button
          className={styles.backButtonText}
          onClick={() => history.goBack()}
        >
          <FaRegArrowAltCircleLeft
            className={styles.backButton}
            color="#C4C4C4"
            size={24}
          />
        </button>
      </div>

      <div className={styles.content}>
        <div className={styles.headerTaskMessageGroup}>
          <div
            className={styles.headerTaskMessageContainer}
            style={{ visibility: "hidden" }}
          >
            <text className={styles.headerSubText}>
              You have
              <span className={styles.unsolvedTaskText}>12</span>
              unsolved tasks.
            </text>

            <div>
              <button className={styles.viewTasksButton}>View Tasks</button>
              <Icon
                className={styles.viewTasksButtonArrow}
                size="big"
                name="arrow circle right"
                fitted
              />
            </div>
          </div>
          {/*<div className={styles.headerTaskMessageContainer}>
      
                  <text className={styles.headerSubText}>
                    You have
                    <span className={styles.unreadMessageText}>
                      12
                    </span>
                    unread messages.
                  </text>
      
                  <div>
                    <button className={styles.viewMessagesButton}>
                      View messages
                    </button>
                    <Icon
                      className={styles.viewMessagesButtonArrow}
                      size="big"
                      name="arrow circle right"
                      fitted
                    />
                  </div>
      
                </div>*/}
        </div>

        <div className={styles.headerGroupRow}>
          <div className={styles.headerTextGroup}>
            <h1 className={styles.headerText}>Account dashboard</h1>

            <text className={styles.headerSubText}>
              Update and manage your users, services and billing details
            </text>
          </div>

          <div>
            <button
              className={styles.buttonLogout}
              onClick={() => setLogoutModalVisibility(true)}
            >
              Logout
            </button>
          </div>
        </div>

        <div className={styles.cardsGroup}>
          <div className={styles.cardContainer}>
            <Icon className={styles.icon} name="users" />
            {/* <FaUsers className={styles.icon} /> */}

            <text className={styles.cardHeaderText}>User Centre</text>

            <text className={styles.cardSubText}>
              Add, remove, update and view all users on the platform. Also,
              update your details.
            </text>

            <button
              className={styles.buttonContainer}
              // onClick={onUpdateDetails}
              onClick={() =>
                (window.location.href = "https://holisticmanagementhub.com.au/user-accounts")
              }
            >
              Update my details
            </button>

            <button
              className={styles.buttonContainer}
              // onClick={() => {
              //   history.push(`${match.path}/create-user`);
              //   window.scrollTo({ top: 0 });
              // }}
              onClick={() =>
                (window.location.href = "https://holisticmanagementhub.com.au/user-accounts")
              }
            >
              Create a new user
            </button>

            <button
              className={styles.buttonContainer}
              // onClick={() => {
              //   history.push(`${match.path}/user-list`);
              //   window.scrollTo({ top: 0 });
              // }}
              onClick={() =>
                (window.location.href = "https://holisticmanagementhub.com.au/user-accounts")
              }
            >
              View all users
            </button>
          </div>

          <div className={styles.cardContainer}>
            <Icon className={styles.icon} name="handshake outline" />
            {/* <FaRegHandshake className={styles.icon} /> */}
            <text className={styles.cardHeaderText}>Services</text>

            <text className={styles.cardSubText}>
              Add, remove, update and view all services on the platform.
            </text>

            <button
              className={styles.buttonContainer}
              onClick={() => {
                history.push(`/qip/nqa/service-details/1`);
                window.scrollTo({ top: 0 });
              }}
            >
              Update service details
            </button>

            <button
              className={styles.buttonContainer}
              onClick={() => {
                if (hasPaymentMethods) {
                  history.push(`/qip/pricing-plans`, {
                    forAdditionalService: true,
                    paymentMethods: paymentMethods,
                  });
                } else {
                  setNewServicesModalVisibility(true);
                }
              }}
              // onClick={() => setNewServicesModalVisibility(true)}
            >
              Add another service
            </button>
            {/*<button
                    className={styles.buttonContainer}
                    onClick={() => history.push(`${match.path}/services`)}
                  >
                    View all services
                  </button>*/}
          </div>

          <div className={styles.cardContainer}>
            <Icon className={styles.icon} name="dollar" />

            {/* <FaDollarSign className={styles.icon} /> */}

            <text className={styles.cardHeaderText}>Billing</text>

            <text className={styles.cardSubText}>
              View and edit your payment details. See your subscription
              information in one place.
            </text>

            <button
              disabled={!hasPaymentMethods && authObj.stripeId}
              className={styles.buttonContainer}
              onClick={() => history.push(`${match.path}/billing/2`)}
            >
              Subscription Details
              {!hasPaymentMethods && authObj.stripeId && (
                <Label pointing="below">Coming soon</Label>
              )}
            </button>
            {authObj.stripeId && (
              <>
                <button
                  disabled={!hasPaymentMethods && authObj.stripeId}
                  className={` hidden ${styles.buttonContainer}`}
                  onClick={() => history.push(`${match.path}/billing/1`)}
                >
                  Payment Details
                  {!hasPaymentMethods && authObj.stripeId && (
                    <Label pointing="below">Coming soon</Label>
                  )}
                </button>
                <button
                  disabled={!hasPaymentMethods && authObj.stripeId}
                  className={`hidden ${styles.buttonContainer} `}
                  onClick={() => history.push(`${match.path}/billing/3`)}
                >
                  Past payments
                  {!hasPaymentMethods && authObj.stripeId && (
                    <Label pointing="below">Coming soon</Label>
                  )}
                </button>{" "}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserDashboard;
