import * as React from "react";

import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";

import NQA from "../NQA";
import Tasks from "../Tasks";
import Reports from "../Reports";
import Messages from "../Messages";
import HelpForm from "../HelpForm";
import Services from "../Services";
import UserManagement from "../UserManagement";
import UserResource from "../UserResource";
import PricingPlans from "../../pages/PricingPlans";
import Payment from "../Payment";
import NewService from "../../pages/NewService";
import UpdatePaymentMethod from "../../pages/UpdatePaymentMethod/UpdatePaymentMethod";
import NewServiceLegalNotice from "../../pages/LegalNotice/NewServiceLegalNotice";
import ServiceDetails from "../ServiceDetails";
import { useStoreActions, useStoreState } from "../../hooks";
import useSWR, { mutate } from "swr";
import { getUserMessages } from "../../api/requests";

const { useState, useEffect, useCallback } = React;

export default function LoggedInPages() {
  const match = useRouteMatch();
  const [planChoice, setPlanChoice] = useState(""); // portal or mentoring
  const [planSubscription, setPlanSubscription] = useState("monthly"); // monthly or yearly

  const userId = useStoreState((state) => state.authStore.authObject.userId);

  const clearSession = useStoreActions((actions) => actions.authStore.logout);

  const setUnreadMessagesCount = useStoreActions(
    (actions) => actions.appStore.setUnreadMessagesCount
  );

  const messages = useStoreState((state) => state.appStore.messages);

  const setMessages = useStoreActions(
    (actions) => actions.appStore.setMessages
  );

  const selectedMessage = useStoreState(
    (state) => state.appStore.selectedMessage
  );

  const setSelectedMessage = useStoreActions(
    (actions) => actions.appStore.setSelectedMessage
  );

  const clearNQASession = useStoreActions(
    (actions) => actions.nqaStore.clearSession
  );
  const clearUserSession = useStoreActions(
    (actions) => actions.userStore.clearSession
  );

  const setHeaderMenu = useStoreActions((state) =>
    state.appStore.setHeaderMenu(true)
  );

  const { data, isValidating: fetchingMessages } = useSWR(
    ["/messages", userId],
    (_, userId) => getUserMessages(userId),
    {
      onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
        // Only retry up to 3 times.
        if (retryCount && retryCount >= 3) {
          if (!messages) setFetchLimitError(true);

          return;
        }
        // Retry after 3 seconds.
        setTimeout(() => revalidate({ retryCount: retryCount + 1 }), 3000);
      },
      onSuccess: (data) => {
        setUnreadMessagesCount(data.unreadCount);
        setMessages(data);
        const curMessage = data.messages.filter(
          (msg) => Number(msg.message_id) === Number(selectedMessage.message_id)
        )[0];
        setSelectedMessage(curMessage);
      },
    }
  );

  const handleTokenExpiry = (token) => {
    const t = JSON.parse(atob(token.split(".")[1]));
    const dateNow = new Date();

    if (t.exp - dateNow.getTime() / 1000 < 0) {
      clearNQASession();
      clearUserSession();
      clearSession();
    }
  };

  const checkTokenExpiry = useCallback(async () => {
    try {
      setTimeout(function run() {
        const cachedObject = localStorage.getItem("inteli_token");
        const authObject = JSON.parse(cachedObject);
        if (authObject) {
          handleTokenExpiry(authObject.accessToken);
        }
        setTimeout(run, 5000);
      }, 5000);
    } catch (error) {
      console.log(error);
    }
  }, []);

  // useEffect(() => {
  //   checkTokenExpiry();
  // }, []);

  return (
    <Switch>
      <Route path={`${match.path}/nqa`} component={NQA} />
      <Route
        path={`${match.path}/user-management`}
        component={UserManagement}
      />
      <Route path={`${match.path}/user-resource`} component={UserResource} />
      <Route path={`${match.path}/messages`} component={Messages} />
      <Route path={`${match.path}/help`} component={HelpForm} />
      <Route
        path={`${match.path}/reports`}
        render={(props) => <Reports {...props} />}
      />
      <Route path={`${match.path}/tasks`} component={Tasks} />
      <Route path={`${match.path}/services`} component={Services} />

      <Route
        path={`${match.path}/update-payment-method`}
        render={(props) => <UpdatePaymentMethod {...props} />}
      />
      <Route
        exact
        path={`${match.path}/pricing-plans`}
        render={(props) => (
          <PricingPlans
            setPlanChoice={setPlanChoice}
            setPlanSubscription={setPlanSubscription}
            {...props}
          />
        )}
      />
      <Route
        exact
        path={`${match.path}/payment`}
        render={(props) => (
          <Payment
            planVariant={planSubscription}
            plan={planChoice}
            stripeState={"auth"}
            {...props}
          />
        )}
      />

      <Route
        exact
        path={`${match.path}/add-new-service`}
        render={() => (
          <NewService planVariant={planSubscription} plan={planChoice} />
        )}
      />

      <Route
        exact
        path={`${match.path}/legal-notice`}
        component={NewServiceLegalNotice}
      />

      <Route
        path={`${match.path}/onboarding`}
        render={() => <ServiceDetails onboarding={true} />}
      />

      <Route
        path={`${match.path}`}
        render={() => {
          return <Redirect to={`${match.path}/nqa`} />;
        }}
      />
    </Switch>
  );
}
