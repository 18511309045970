import { Action, action, thunk, Thunk } from "easy-peasy";

import { NQA, ProgressResponse, ServiceDetails } from "../../types";
import {
  fetchNQAData,
  fetchNQAProgress,
  fetchServiceDetails,
  updateServiceDetails,
} from "../../api/requests";
import { CLIEngine } from "eslint";

export interface NQAModel {
  totalProgress?: number;
  fetchNQAProgress: Thunk<NQAModel, number>;
  setTotalProgress: Action<NQAModel, number>;
  setNQAProgress: Action<NQAModel, ProgressResponse>;
  NQAList?: NQA[];
  setNQAList: Action<NQAModel, NQA[]>;
  selectedNQA?: NQA;
  setSelectedNQA: Action<NQAModel, number>;
  fetchNQAData: Thunk<NQAModel>;
  serviceDetails?: ServiceDetails;
  fetchServiceDetails: Thunk<NQAModel, number>;
  setServiceDetails: Action<NQAModel, ServiceDetails>;
  updateServiceDetails: Thunk<
    NQAModel,
    { serviceDetails: ServiceDetails; centerId: number }
  >;
  clearSession: Action<NQAModel>;
  navigate: boolean;
  setNavigate: Action<NQAModel, boolean>;
}

const dummyNQAList = [
  {
    id: 1,
    name: "NQA1 - Educational Program & Practice",
    title: "Educational Program & Practice",
    completed: 40,
    levels: [
      {
        id: 1,
        name: "Program",
        completed: 40,
        info: "The educational program enhances each child’s learning and development.",
        subLevels: [
          {
            sourceId: 1,
            id: 1,
            label: "1.1.1",
            name: "Approved Learning Framework",
            completed: 40,
            description:
              "Curriculum decision making contributes to each child’s learning and development outcomes in relation to their identity, connection with the community, wellbeing, confidence as learners and effectiveness as communicators",
          },
          {
            sourceId: 1,
            id: 2,
            label: "1.1.2",
            name: "Approved Learning Framework",
            completed: 40,
            description:
              "Curriculum decision making contributes to each child’s learning and development outcomes in relation to their identity, connection with the community, wellbeing, confidence as learners and effectiveness as communicators",
          },
          {
            sourceId: 1,
            id: 3,
            label: "1.1.3",
            name: "Approved Learning Framework",
            completed: 40,
            description:
              "Curriculum decision making contributes to each child’s learning and development outcomes in relation to their identity, connection with the community, wellbeing, confidence as learners and effectiveness as communicators",
          },
        ],
      },
      {
        id: 2,
        name: "Practice",
        completed: 95,
        info: "The educational program enhances each child’s learning and development.",
        subLevels: [
          {
            sourceId: 1,
            id: 1,
            label: "1.2.1",
            name: "Approved Learning Framework",
            completed: 40,
            description:
              "Curriculum decision making contributes to each child’s learning and development outcomes in relation to their identity, connection with the community, wellbeing, confidence as learners and effectiveness as communicators",
          },
          {
            sourceId: 1,
            id: 2,
            label: "1.2.2",
            name: "Approved Learning Framework",
            completed: 40,
            description:
              "Curriculum decision making contributes to each child’s learning and development outcomes in relation to their identity, connection with the community, wellbeing, confidence as learners and effectiveness as communicators",
          },
          {
            sourceId: 1,
            id: 3,
            label: "1.2.3",
            name: "Approved Learning Framework",
            completed: 40,
            description:
              "Curriculum decision making contributes to each child’s learning and development outcomes in relation to their identity, connection with the community, wellbeing, confidence as learners and effectiveness as communicators",
          },
        ],
      },
      {
        id: 3,
        name: "Assessment & planning",
        completed: 5,
        info: "The educational program enhances each child’s learning and development.",
        subLevels: [
          {
            sourceId: 1,
            id: 1,
            label: "1.3.1",
            name: "Approved Learning Framework",
            completed: 40,
            description:
              "Curriculum decision making contributes to each child’s learning and development outcomes in relation to their identity, connection with the community, wellbeing, confidence as learners and effectiveness as communicators",
          },
          {
            sourceId: 1,
            id: 2,
            label: "1.3.2",
            name: "Approved Learning Framework",
            completed: 40,
            description:
              "Curriculum decision making contributes to each child’s learning and development outcomes in relation to their identity, connection with the community, wellbeing, confidence as learners and effectiveness as communicators",
          },
          {
            sourceId: 1,
            id: 3,
            label: "1.3.3",
            name: "Approved Learning Framework",
            completed: 40,
            description:
              "Curriculum decision making contributes to each child’s learning and development outcomes in relation to their identity, connection with the community, wellbeing, confidence as learners and effectiveness as communicators",
          },
        ],
      },
    ],
  },
  {
    id: 2,
    name: "NQA2 - Educational Program & Practice",
    title: "Educational Program & Practice",
    completed: 40,
    levels: [
      {
        id: 1,
        name: "Program",
        completed: 40,
        info: "The educational program enhances each child’s learning and development.",
        subLevels: [
          {
            sourceId: 1,
            id: 1,
            label: "2.1.1",
            name: "Approved Learning Framework",
            completed: 40,
            description: "Sample description text",
          },
          {
            sourceId: 1,
            id: 2,
            label: "2.1.2",
            name: "Approved Learning Framework",
            completed: 40,
            description: "Sample description text",
          },
          {
            sourceId: 1,
            id: 3,
            label: "2.1.3",
            name: "Approved Learning Framework",
            completed: 40,
            description: "Sample description text",
          },
        ],
      },
      {
        id: 2,
        name: "Practice",
        completed: 95,
        info: "The educational program enhances each child’s learning and development.",
        subLevels: [
          {
            sourceId: 1,
            id: 1,
            label: "2.2.1",
            name: "Approved Learning Framework",
            completed: 40,
            description: "Sample description text",
          },
          {
            sourceId: 1,
            id: 2,
            label: "2.2.2",
            name: "Approved Learning Framework",
            completed: 40,
            description: "Sample description text",
          },
          {
            sourceId: 1,
            id: 3,
            label: "2.2.3",
            name: "Approved Learning Framework",
            completed: 40,
            description: "Sample description text",
          },
        ],
      },
    ],
  },
  {
    id: 3,
    name: "NQA3 - Educational Program & Practice",
    title: "Educational Program & Practice",
    completed: 40,
    levels: [
      {
        id: 1,
        name: "Program",
        completed: 40,
        info: "The educational program enhances each child’s learning and development.",
        subLevels: [
          {
            sourceId: 1,
            id: 1,
            label: "3.1.1",
            name: "Approved Learning Framework",
            completed: 40,
            description: "Sample description text",
          },
          {
            sourceId: 1,
            id: 2,
            label: "3.1.2",
            name: "Approved Learning Framework",
            completed: 40,
            description: "Sample description text",
          },
          {
            sourceId: 1,
            id: 3,
            label: "3.1.3",
            name: "Approved Learning Framework",
            completed: 40,
            description: "Sample description text",
          },
        ],
      },
      {
        id: 2,
        name: "Practice",
        completed: 95,
        info: "The educational program enhances each child’s learning and development.",
        subLevels: [
          {
            sourceId: 1,
            id: 1,
            label: "3.2.1",
            name: "Approved Learning Framework",
            completed: 40,
            description: "Sample description text",
          },
          {
            sourceId: 1,
            id: 2,
            label: "3.2.2",
            name: "Approved Learning Framework",
            completed: 40,
            description: "Sample description text",
          },
          {
            sourceId: 1,
            id: 3,
            label: "3.2.3",
            name: "Approved Learning Framework",
            completed: 40,
            description: "Sample description text",
          },
        ],
      },
    ],
  },
  {
    id: 4,
    name: "NQA4 - Educational Program & Practice",
    title: "Educational Program & Practice",
    completed: 40,
    levels: [
      {
        id: 1,
        name: "Program",
        completed: 40,
        info: "The educational program enhances each child’s learning and development.",
        subLevels: [
          {
            sourceId: 1,
            id: 1,
            label: "4.1.1",
            name: "Approved Learning Framework",
            completed: 40,
            description: "Sample description text",
          },
          {
            sourceId: 1,
            id: 2,
            label: "4.1.2",
            name: "Approved Learning Framework",
            completed: 40,
            description: "Sample description text",
          },
          {
            sourceId: 1,
            id: 3,
            label: "4.1.3",
            name: "Approved Learning Framework",
            completed: 40,
            description: "Sample description text",
          },
        ],
      },
      {
        id: 2,
        name: "Practice",
        completed: 95,
        info: "The educational program enhances each child’s learning and development.",
        subLevels: [
          {
            sourceId: 1,
            id: 1,
            label: "4.2.1",
            name: "Approved Learning Framework",
            completed: 40,
            description: "Sample description text",
          },
          {
            sourceId: 1,
            id: 2,
            label: "4.2.2",
            name: "Approved Learning Framework",
            completed: 40,
            description: "Sample description text",
          },
          {
            sourceId: 1,
            id: 3,
            label: "4.2.3",
            name: "Approved Learning Framework",
            completed: 40,
            description: "Sample description text",
          },
        ],
      },
    ],
  },
  {
    id: 5,
    name: "NQA5 - Educational Program & Practice",
    title: "Educational Program & Practice",
    completed: 40,
    levels: [
      {
        id: 1,
        name: "Program",
        completed: 40,
        info: "The educational program enhances each child’s learning and development.",
        subLevels: [
          {
            sourceId: 1,
            id: 1,
            label: "5.1.1",
            name: "Approved Learning Framework",
            completed: 40,
            description: "Sample description text",
          },
          {
            sourceId: 1,
            id: 2,
            label: "5.1.2",
            name: "Approved Learning Framework",
            completed: 40,
            description: "Sample description text",
          },
          {
            sourceId: 1,
            id: 3,
            label: "5.1.3",
            name: "Approved Learning Framework",
            completed: 40,
            description: "Sample description text",
          },
        ],
      },
      {
        id: 2,
        name: "Practice",
        completed: 95,
        info: "The educational program enhances each child’s learning and development.",
        subLevels: [
          {
            sourceId: 1,
            id: 1,
            label: "5.2.1",
            name: "Approved Learning Framework",
            completed: 40,
            description: "Sample description text",
          },
          {
            sourceId: 1,
            id: 2,
            label: "5.2.2",
            name: "Approved Learning Framework",
            completed: 40,
            description: "Sample description text",
          },
          {
            sourceId: 1,
            id: 3,
            label: "5.2.3",
            name: "Approved Learning Framework",
            completed: 40,
            description: "Sample description text",
          },
        ],
      },
    ],
  },
  {
    id: 6,
    name: "NQA6 - Educational Program & Practice",
    title: "Educational Program & Practice",
    completed: 40,
    levels: [
      {
        id: 1,
        name: "Program",
        completed: 40,
        info: "The educational program enhances each child’s learning and development.",
        subLevels: [
          {
            sourceId: 1,
            id: 1,
            label: "6.1.1",
            name: "Approved Learning Framework",
            completed: 40,
            description: "Sample description text",
          },
          {
            sourceId: 1,
            id: 2,
            label: "6.1.2",
            name: "Approved Learning Framework",
            completed: 40,
            description: "Sample description text",
          },
          {
            sourceId: 1,
            id: 3,
            label: "6.1.3",
            name: "Approved Learning Framework",
            completed: 40,
            description: "Sample description text",
          },
        ],
      },
      {
        id: 2,
        name: "Practice",
        completed: 95,
        info: "The educational program enhances each child’s learning and development.",
        subLevels: [
          {
            sourceId: 1,
            id: 1,
            label: "6.2.1",
            name: "Approved Learning Framework",
            completed: 40,
            description: "Sample description text",
          },
          {
            sourceId: 1,
            id: 2,
            label: "6.2.2",
            name: "Approved Learning Framework",
            completed: 40,
            description: "Sample description text",
          },
          {
            sourceId: 1,
            id: 3,
            label: "6.2.3",
            name: "Approved Learning Framework",
            completed: 40,
            description: "Sample description text",
          },
        ],
      },
    ],
  },
  {
    id: 7,
    name: "NQA7 - Governance and Leadership",
    title: "Educational Program & Practice",
    completed: 40,
    levels: [
      {
        id: 1,
        name: "Governance",
        completed: 40,
        info: "The educational program enhances each child’s learning and development.",
        subLevels: [
          {
            sourceId: 1,
            id: 1,
            label: "7.1.1",
            name: "Statement of philosophy",
            completed: 40,
            description:
              "A statement of philosophy guides all aspects of the service’s operations.",
          },
          {
            sourceId: 1,
            id: 2,
            label: "7.1.2",
            name: "Approved Learning Framework",
            completed: 40,
            description:
              "A statement of philosophy guides all aspects of the service’s operations.",
          },
          {
            sourceId: 1,
            id: 3,
            label: "7.1.3",
            name: "Approved Learning Framework",
            completed: 40,
            description:
              "A statement of philosophy guides all aspects of the service’s operations.",
          },
        ],
      },
      {
        id: 2,
        name: "Practice",
        completed: 95,
        info: "The educational program enhances each child’s learning and development.",
        subLevels: [
          {
            sourceId: 1,
            id: 1,
            label: "7.2.1",
            name: "Approved Learning Framework",
            completed: 40,
            description:
              "A statement of philosophy guides all aspects of the service’s operations.",
          },
          {
            sourceId: 1,
            id: 2,
            label: "7.2.2",
            name: "Approved Learning Framework",
            completed: 40,
            description:
              "A statement of philosophy guides all aspects of the service’s operations.",
          },
          {
            sourceId: 1,
            id: 3,
            label: "7.2.3",
            name: "Approved Learning Framework",
            completed: 40,
            description:
              "A statement of philosophy guides all aspects of the service’s operations.",
          },
        ],
      },
    ],
  },
];

const NQAStore: NQAModel = {
  navigate: false,
  setNavigate: action((state, payload) => {
    state.navigate = payload;
  }),
  clearSession: action((state) => {
    state.NQAList = undefined;
    state.selectedNQA = undefined;
    state.totalProgress = undefined;
    state.serviceDetails = undefined;
  }),

  setTotalProgress: action((state, payload) => {
    state.totalProgress = payload;
  }),

  setNQAProgress: action((state, payload) => {
    payload.nqaItems.forEach((nqa, index) => {
      state.NQAList![index].completed = nqa.completed;

      state.NQAList![index].levels.forEach((level, levelIndex) => {
        level.completed = nqa.levels[levelIndex].completed;

        level.subLevels.forEach((subLevel, subLevelIndex) => {
          subLevel.completed =
            nqa.levels[levelIndex].subLevels[subLevelIndex].completed;
        });
      });
    });
  }),

  setNQAList: action((state, payload) => {
    state.NQAList = payload;
  }),

  setSelectedNQA: action((state, payload) => {
    state.selectedNQA = state.NQAList?.find((nqa) => nqa.id === payload);
  }),

  fetchNQAProgress: thunk(async (actions, payload) => {
    try {
      const response = await fetchNQAProgress(payload);
      actions.setTotalProgress(response.totalProgress);
      console.log("progress ", response);
      actions.setNQAProgress(response);
    } catch (error) {
      console.log(error);
    }
  }),

  fetchNQAData: thunk(async (actions, _, { getState }) => {
    try {
      const nqaItems = await fetchNQAData();

      nqaItems.forEach((nqa) => {
        if (nqa.levels.length === 0)
          nqa.levels = dummyNQAList[nqa.id - 1].levels;
        else {
          nqa.levels.forEach((level) => {
            if (level.subLevels.length === 0)
              level.subLevels =
                dummyNQAList[nqa.id - 1].levels[level.id - 1].subLevels;
          });
        }
      });

      actions.setNQAList(nqaItems);

      const centerId = getState().serviceDetails?.id;
      //
      await actions.fetchNQAProgress(centerId as number);

      return true;
    } catch (error) {
      console.log(error);

      return false;
    }
  }),

  fetchServiceDetails: thunk(async (actions, payload) => {
    if (payload) {
      try {
        const serviceDetails = await fetchServiceDetails(payload);
        actions.setServiceDetails(serviceDetails);

        return serviceDetails.id;
      } catch (error) {
        console.log(error);

        return false;
      }
    }
  }),

  setServiceDetails: action((state, payload) => {
    state.serviceDetails = payload;
  }),

  updateServiceDetails: thunk(async (actions, payload) => {
    try {
      const serviceDetails = await updateServiceDetails(
        payload.serviceDetails,
        payload.centerId
      );

      actions.setServiceDetails(serviceDetails);

      return true;
    } catch (error) {
      console.log(error);

      return false;
    }
  }),
};

export default NQAStore;
