import * as React from 'react';

import PropTypes from 'prop-types';
import { Icon } from 'semantic-ui-react';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';

import styles from './AdditionalInformation.module.css';
import BlueButton from '../../../components/BlueButton';
import InputComponent from '../../../components/InputComponent';
import { ServiceDetailsContext } from '../../../context/ServiceDetailsContext';
import { useStoreState } from '../../../hooks';

import { ServiceSteps } from '../../../components/Steps';

import { FaSpinner } from 'react-icons/fa';

const { useContext, useEffect, useState, useCallback } = React;

function AdditionalInformation({
	onNextStep,
	onSaveStep,
	onSkipServiceDetails,
}) {
	const history = useHistory();
	const match = useRouteMatch();

	const {
		saveLoading,
		serviceDetailsState,
		changeInputDispatch,
		stepsIndicator,
	} = useContext(ServiceDetailsContext);

	const isLoggedIn = useStoreState(state => state.authStore.isLoggedIn);

	const [fieldChecked, setFieldChecked] = useState(false);

	const [errors, setErrors] = useState([]);

	const [capErrors, setCapErrors] = useState([]);

	const [initialRooms, setInitialRooms] = useState(
		serviceDetailsState.input.rooms != undefined
			? serviceDetailsState.input.rooms.length > 0
				? serviceDetailsState.input.rooms
				: []
			: []
	);

	// set number of rooms
	const [rooms, setRooms] = useState(
		serviceDetailsState.input.rooms != undefined
			? serviceDetailsState.input.rooms.length > 0
				? serviceDetailsState.input.rooms.length
				: 0
			: 0
	);

	const setRoomCapacity = (index, capacity, value) => {
		setErrors([]);

		const currentRooms = serviceDetailsState.input.rooms
			? [...serviceDetailsState.input.rooms]
			: [
					{
						roomNumber: index,
						toAge: 0,
						fromAge: 0,
						capacity: 0,
					},
			  ];

		if (currentRooms.find(room => room.roomNumber === index)) {
			currentRooms[currentRooms.findIndex(room => room.roomNumber === index)][
				capacity
			] = value;
		} else {
			const roomObject = {
				roomNumber: index,
				toAge: 0,
				fromAge: 0,
				capacity: 0,
			};

			roomObject[capacity] = value;
			currentRooms.push(roomObject);
		}

		changeInputDispatch('rooms', currentRooms);
	};

	const setChildrenAgeGroup = (index, ageInput, value) => {
		setErrors([]);
		const currentRooms = serviceDetailsState.input.rooms
			? [...serviceDetailsState.input.rooms]
			: [
					{
						roomNumber: index,
						toAge: 0,
						fromAge: 0,
						capacity: 0,
					},
			  ];

		if (currentRooms.find(room => room.roomNumber === index)) {
			currentRooms[currentRooms.findIndex(room => room.roomNumber === index)][
				ageInput
			] = value;
		} else {
			const roomObject = {
				roomNumber: index,
				toAge: 0,
				fromAge: 0,
				capacity: 0,
			};

			roomObject[ageInput] = value;
			currentRooms.push(roomObject);
		}

		changeInputDispatch('rooms', currentRooms);
	};

	const onSave = async () => {
		setFieldChecked(true);

		const ageValidated = AgeGroupValidation();
		// const capValidated = CapacityValidation();

		if (!ageValidated) {
			return;
		}

		if (!(!isNaN(rooms) && rooms > 0)) {
			return;
		}
		await onSaveStep();

		onNextStep();
		history.push(
			`${match.path
				.split('/')
				.slice(0, match.path.split('/').length - 1)
				.join('/')}/9`
		);
		// } else alert("Please fill all required fields.");
	};

	const CapacityValidation = () => {
		let tempErrors = [];

		rooms != undefined &&
			!isNaN(rooms) &&
			rooms > 0 &&
			Array.from({ length: rooms }, (v, index) => {
				let message = '';
				if (
					serviceDetailsState.input.rooms &&
					!serviceDetailsState.input.rooms[index]
				) {
					message = 'This field is required.';
				} else {
					if (serviceDetailsState.input.rooms[index].capacity <= 0) {
						message = 'This field is required';
					}
				}

				if (message.length > 0) {
					if (!tempErrors.some(error => error.index === index)) {
						const tempError = {
							index: index,
							message: message,
						};
						tempErrors = [...tempErrors, tempError];
					}
				}
			});

		setCapErrors([...tempErrors]);

		if (tempErrors.length > 0) {
			return false;
		} else {
			return true;
		}
	};

	const AgeGroupValidation = () => {
		let tempErrors = [];

		rooms != undefined &&
			!isNaN(rooms) &&
			rooms > 0 &&
			Array.from({ length: rooms }, (v, index) => {
				let message = '';
				if (
					serviceDetailsState.input.rooms &&
					!serviceDetailsState.input.rooms[index]
				) {
					message = 'These fields are required.';
				} else {
					if (
						serviceDetailsState.input.rooms[index].toAge > 0 ||
						serviceDetailsState.input.rooms[index].fromAge > 0
					) {
						if (
							serviceDetailsState.input.rooms[index].toAge <
							serviceDetailsState.input.rooms[index].fromAge
						) {
							message = 'Invalid Age Range';
						}
					} else {
						message = 'These fields are required';
					}
				}

				if (message.length > 0) {
					if (!tempErrors.some(error => error.index === index)) {
						const tempError = {
							index: index,
							message: message,
						};
						tempErrors = [...tempErrors, tempError];
					}
				}
			});

		setErrors([...tempErrors]);

		if (tempErrors.length > 0) {
			return false;
		} else {
			return true;
		}
	};

	useEffect(() => {
		if (rooms < 0) setRooms(0);
	}, [rooms]);

	const setAdditionalInputValue = (key, itemName) => {
		if (!key) return 0;

		if (serviceDetailsState?.input?.rooms.length > 0) {
			const filterItems = serviceDetailsState?.input?.rooms.filter(
				room => room.roomNumber === key
			);
			const getCapacity = filterItems.length > 0 ? filterItems[0][itemName] : 0;

			return getCapacity;
		}

		return 0;
	};

	return (
		<div className={styles.mainContainer}>
			{isLoggedIn && (
				<>
					<div className={styles.headerContainer}>
						<h1>Complete your service details</h1>
						<p>
							Please fill out all missing fields marked with an ‘
							<span className={styles.required}>*</span>’
						</p>
					</div>
				</>
			)}
			<div className={styles.mainCard}>
				<div className={styles.cardContent}>
					{/* title */}
					<h1 className={styles.headerText}>Additional information</h1>

					<text className={styles.pleaseComplete}>
						Please complete the below fields about your service.
					</text>

					<InputComponent
						className={styles.additionalInfo}
						textArea
						fieldName="additionalInfo"
						label="Additional information about your service"
						inputValue={serviceDetailsState.input.additionalInfo}
						setInputValue={changeInputDispatch}
					/>

					<text className={styles.headerText}>How are children grouped?</text>

					<text className={styles.descriptionText}>
						How many rooms are available at your center?
					</text>

					<input
						className={styles.roomsInput}
						type="number"
						min={0}
						value={rooms}
						onChange={e => {
							setErrors([]);
							if (!isNaN(e.target.value)) {
								changeInputDispatch(
									'rooms',
									initialRooms.slice(0, Math.abs(parseInt(e.target.value)))
								);
							}
							setRooms(Math.abs(parseInt(e.target.value)));
						}}
					/>
					{fieldChecked && !(!isNaN(rooms) && rooms > 0) > 0 && (
						<div className={`p-2 text-red-400 font-thin`}>
							Please enter number of rooms available at your center.
						</div>
					)}

					<div className={styles.roomEntryGroup}>
						{rooms != undefined && !isNaN(rooms) && rooms > 0
							? Array.from({ length: rooms }, (v, index) => (
									<React.Fragment key={index}>
										<div className={`${styles.roomEntry} `}>
											<text className={`${styles.groupChildrenTitle} flex-1`}>
												Room {index + 1} has capacity of{' '}
												<div
													style={{
														marginLeft: '0.5em',
														marginRight: '0.5em',
													}}
													className={`inline-block relative`}
												>
													<input
														className={`${styles.ageInput} `}
														type="number"
														min={0}
														value={setAdditionalInputValue(
															index + 1,
															'capacity'
														)}
														onChange={e =>
															setRoomCapacity(
																index + 1,
																'capacity',
																Math.abs(parseInt(e.target.value))
															)
														}
													/>
													<div
														style={{
															top: '60px',
															width: `400px`,
														}}
														className={`absolute inset-x-0 text-sm p-2 text-red-400 font-thin -ml-5`}
													>
														{/* {capErrors.map((error) => {
                              if (error.index === index) {
                                return error.message;
                              }
                            })} */}
													</div>
												</div>{' '}
												children group by ages
												{index === 0 && (
													<span className={styles.required}>*</span>
												)}
											</text>
											<div className={`flex flex-col items-center mt-3.5`}>
												<div className={styles.ageGroup}>
													<input
														className={styles.ageInput}
														type="number"
														min={0}
														value={setAdditionalInputValue(
															index + 1,
															'fromAge'
														)}
														onChange={e => {
															setChildrenAgeGroup(
																index + 1,
																'fromAge',
																Math.abs(parseInt(e.target.value))
															);
														}}
													/>

													<text>to</text>

													<input
														className={styles.ageInput}
														type="number"
														min={0}
														value={setAdditionalInputValue(index + 1, 'toAge')}
														onChange={e => {
															setChildrenAgeGroup(
																index + 1,
																'toAge',
																Math.abs(parseInt(e.target.value))
															);
														}}
													/>
												</div>
												<div>
													<div className={`p-2 text-red-400 font-thin`}>
														{errors.map(error => {
															if (error.index === index) {
																return error.message;
															}
														})}
													</div>
												</div>
											</div>
										</div>
									</React.Fragment>
							  ))
							: null}
					</div>

					<div className={styles.buttonHolders}>
						<BlueButton // buttonText="Save step"
							buttonText={
								saveLoading ? (
									<div className={`flex justify-center items-center`}>
										<FaSpinner className={`animate-spin`} />
										<span className={`block pl-2`}>Save step</span>
									</div>
								) : (
									<> Save step</>
								)
							}
							onClick={onSave}
						/>

						<button
							className={styles.buttonNextStep}
							onClick={() =>
								history.push(
									`${match.path
										.split('/')
										.slice(0, match.path.split('/').length - 1)
										.join('/')}/9`
								)
							}
						>
							Next step
						</button>
					</div>

					<div className={styles.buttonStep}>
						<button
							className={styles.buttonStepBack}
							onClick={() =>
								history.push(
									`${match.path
										.split('/')
										.slice(0, match.path.split('/').length - 1)
										.join('/')}/7`
								)
							}
						>
							<Icon name="angle left" />
							Back a step
						</button>

						<button
							className={styles.buttonFinishLater}
							onClick={onSkipServiceDetails}
						>
							Finish Later
							<Icon name="angle double right" />
						</button>
					</div>
				</div>

				<div className={styles.stepsColumn}>
					<ServiceSteps route={match.path} />
				</div>
			</div>
		</div>
	);
}

AdditionalInformation.propTypes = {
	onNextStep: PropTypes.func,
	onSkipStep: PropTypes.func,
	onSaveStep: PropTypes.func,
	onSkipServiceDetails: PropTypes.func,
};

export default AdditionalInformation;
