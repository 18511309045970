import * as React from 'react';

import PropTypes from 'prop-types';
import Switch from 'react-ios-switch';
import { Button, Dropdown } from 'semantic-ui-react';
  
import styles from './ComposeMessageView.module.css';
import InputComponent from '../../../components/InputComponent';
import BlueButton from '../../../components/BlueButton';

const { useState } = React;

const filterOptions = [
  {
    key: 'User 1',
    text: 'User 1',
    value: 'User 1',
  },
  {
    key: 'User 2',
    text: 'User 2',
    value: 'User 2',
  },
  {
    key: 'User 3',
    text: 'User 3',
    value: 'User 3',
  },
];

function ComposeMessageView({ onSendMessage }) {
  const [sendToAll, setSendToAll] = useState(true);
  const [messageType, setMessageType] = useState('important');

  return (
    <div className={styles.mainContainer}>

      <div className={styles.messageSelectionRow}>

        <div className={styles.messageButtonGroup}>

          <Button
            className={messageType === 'important' ? styles.urgentMessageIconSelected: styles.urgentMessageIcon}
            icon="warning circle"
            onClick={() => setMessageType('important')}
          />

          <text className={styles.messageButtonText}>
            Important message
          </text>

        </div>

        <div className={styles.messageButtonGroup}>

          <Button
            className={messageType === 'regular' ? styles.regularMessageIconSelected: styles.regularMessageIcon}
            icon="mail"
            onClick={() => setMessageType('regular')}
          />

          <text className={styles.messageButtonText}>
            Regular message
          </text>

        </div>

      </div>

      <InputComponent
        className={styles.messageSubjectInput}
        label="Message subject"
      />

      <div className={styles.sendToEverybodyRow}>

        <text className={styles.subText}>
          Send this message to everybody
        </text>

        <Switch
          checked={sendToAll}
          onColor="#8BC334"
          onChange={() => setSendToAll(prev => !prev)}
        />

      </div>

      <div className={styles.dropdownGroup}>

        <text className={styles.dropdownText}>
          Choose users
        </text>

        <Dropdown
          className={styles.dropdownBox}
          placeholder="Choose"
          fluid
          multiple
          selection
          options={filterOptions}
        />

      </div>

      <button className={styles.addMoreUsersButton}>
        Add more users
      </button>

      <InputComponent
        className={styles.messageBox}
        textArea
        label="Will this be a rich text editor?"
      />

      <BlueButton
        className={styles.blueButton}
        buttonText="Send message"
        onClick={onSendMessage}
      />

    </div>
  );
}

ComposeMessageView.propTypes = {
  onSendMessage: PropTypes.func.isRequired,
};

export default ComposeMessageView;
