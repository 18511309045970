import * as React from 'react';

import PropTypes from 'prop-types';
// import { useHistory } from 'react-router-dom';
import { Button, Icon } from 'semantic-ui-react';

import styles from './ServiceCard.module.css';

// const { useState } = React;

function ServiceCard({ serviceData, onRemoveService }) {
  // const history = useHistory();

  return (
    <div className={styles.serviceCardContainer}>

      <div className={styles.serviceCardTextGroup}>

        <text className={styles.serviceCardHeaderText}>
          {serviceData.name}
        </text>

        <div className={styles.serviceCardSubTextGroup}>

          <div>

            <text className={styles.serviceCardSubText}>
              Subscription type: {serviceData.subscriptionType}
            </text>

            <text className={styles.serviceCardSubText}>
              Number of users: {serviceData.numberOfUsers}
            </text>

            <text className={styles.serviceCardSubText}>
              Renews monthly: {serviceData.monthlyRenewDate}
            </text>

          </div>

          <div className={styles.serviceCardSubTextProgress}>

            <text className={styles.serviceCardSubText}>
              QIP Completed
            </text>

            <div className={styles.progressBarContainer}>
              <div
                className={styles.progressBar}
                style={{ width: `${serviceData.qipCompleted}%` }}
              />
            </div>

            <div
              className={styles.progressBarText}
              style={{ width: `${serviceData.qipCompleted}%` }}
            >

              <text>
                {serviceData.qipCompleted}%
              </text>

            </div>

          </div>

        </div>

      </div>

      <div className={styles.serviceCardActionsGroup}>

        <div className={styles.serviceCardActionsRow}>

          <div className={styles.serviceCardButtonGroup}>

            <Button
              className={styles.serviceCardTasksButton}
              icon="flag checkered"
            />

            <text className={styles.serviceCardButtonText}>
              Tasks
            </text>

          </div>

          <div className={styles.serviceCardButtonGroup}>

            <Button
              className={styles.serviceCardEditButton}
              icon="edit"
              size="massive"
            // onClick={onEditButtonSelect}
            />

            <text className={styles.serviceCardButtonText}>
              Edit
            </text>

          </div>

          <div className={styles.serviceCardButtonGroup}>

            <Button
              className={styles.serviceCardRemoveButton}
              icon="close"
              size="massive"
              onClick={onRemoveService}
            />

            <text className={styles.serviceCardButtonText}>
              Remove
            </text>

          </div>
        </div>

        {serviceData.subscriptionStatus === 'active'
          ? (
            <div className={styles.activeSubscription}>

              <Icon name="check circle" />

              <text>
                This subscription is active
              </text>

            </div>
          )
          : (
            <div className={styles.cancelledSubscription}>

              <Icon name="close" />

              <text>
                Subscription was cancelled
              </text>

            </div>
          )}

      </div>

    </div>
  );
}

ServiceCard.propTypes = {
  serviceData: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    subscriptionType: PropTypes.string,
    numberOfUsers: PropTypes.number,
    monthlyRenewDate: PropTypes.string,
    qipCompleted: PropTypes.number,
    subscriptionStatus: PropTypes.string,
  }),
  onRemoveService: PropTypes.func,
};

export default ServiceCard;
