import React, { useState } from "react";
import { FaCog, FaEnvelope, FaHeart, FaUsers } from "react-icons/fa";
import logoImg from "../../static/images/logo.png";
import styles from "./Header.module.css";
import { useStoreState, useStoreActions } from "../../hooks";
import ChooseService from "../../components/Modals/ChooseService";
import ChangeServiceModal from "../Modals/ChangeServiceModal";
import LogoutModal from "../Modals/LogoutModal";
import { useHistory } from "react-router-dom";
import { isEmpty } from "lodash";
import { useEffect } from "react";

const NewHeader = () => {
  const [servicesModalOpen, setServicesModalOpen] = useState(false);
  const history = useHistory();
  const totalProgress = useStoreState((state) => state.nqaStore.totalProgress);
  const [logoutModalVisibility, setLogoutModalVisibility] = useState(false);

  const authObj = useStoreState((state) => state.authStore.authObject);
  const services = useStoreState((state) => state.authStore.services);
  const userSelectedService = useStoreState(
    (state) => state.authStore.selectedService
  );

  const fetchUserDetails = useStoreActions(
    (actions) => actions.userStore.fetchUserDetails
  );

  const selectedUser = useStoreState((state) => state.userStore.selectedUser);

  const isNotTrial = !(services?.length === 1 && !services[0].hasSub);

  const [toggleMobileMenu, setToggleMobileMenu] = useState(false);

  useEffect(() => {
    const getUserDetails = async () => {
      await fetchUserDetails(userSelectedService.id, authObj.userId);
    };

    if (!isEmpty(userSelectedService)) {
      getUserDetails();
    }
  }, [userSelectedService]);
  const [activeMenu, setActiveMenu] = useState("nqa");

  return (
    <div className="header">
      {logoutModalVisibility && (
        <LogoutModal
          visibility={logoutModalVisibility}
          onOpenModal={() => setLogoutModalVisibility(true)}
          onCloseModal={() => setLogoutModalVisibility(false)}
        />
      )}
      <ChangeServiceModal
        visibility={servicesModalOpen}
        onCloseModal={() => setServicesModalOpen(false)}
        onOpenModal={() => setServicesModalOpen(true)}
      />

      {/* <ChooseService
        isOpen={servicesModalOpen}
        setIsOpen={setServicesModalOpen}
      /> */}
      <div className={`topHeader ${authObj ? "" : "hidden"} `}>
        <div className="flex items-center justify-between headerRow">
          <div className="topHeaderLeft">
            <ul className="flex items-center topHeaderMenu">
              <li>
                <button
                  className="flex items-center"
                  onClick={() => {
                    history.push("/qip/messages");
                  }}
                >
                  <FaEnvelope className={`mr-2`} />
                  Messages
                </button>
              </li>
              <li>
                <button
                  className="flex items-center"
                  onClick={() => {
                    // history.push("/qip/user-management");
                    window.open(
                      ` ${process.env.REACT_APP_HUB_URL}user-accounts`
                    );
                  }}
                >
                  <FaUsers className={`mr-2`} />
                  Users
                </button>
              </li>
              {!isEmpty(selectedUser) &&
                selectedUser.userType.toLowerCase() !== "user" && (
                  <li>
                    <a
                      onClick={() => {
                        // history.push("/qip/user-management");
                        window.open(
                          ` ${process.env.REACT_APP_HUB_URL}subscription`
                        );
                      }}
                      className="flex items-center"
                    >
                      <FaCog className={`mr-2`} />
                      Subscriptions
                    </a>
                  </li>
                )}
              <li>
                <a
                  // href="https://dev.holisticmanagementhub.com.au/Help/Help.php"
                  href="https://holisticmanagement.net.au/get-help/"
                  className="flex items-center"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaHeart className={`mr-2`} />
                  Help
                </a>
              </li>
            </ul>
          </div>

          <div className="topHeaderRight">
            {!isEmpty(services) && isNotTrial && (
              <div className="services">
                <span className="selectedService">
                  {!isEmpty(userSelectedService)
                    ? userSelectedService.name
                    : ""}
                </span>
                <span
                  onClick={() => {
                    setServicesModalOpen(true);
                  }}
                  data-toggle="modal"
                  data-target="#servicesListModal"
                  className="flex items-center changeService"
                >
                  Change{" "}
                  <svg
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    data-icon="caret-down"
                    className="svg-inline--fa fa-caret-down fa-w-10"
                    role="img"
                    width="10"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 320 512"
                  >
                    <path
                      fill="currentColor"
                      d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z"
                    ></path>
                  </svg>
                </span>
              </div>
            )}

            <div className="LogoutBtn">
              <button onClick={() => setLogoutModalVisibility(true)}>
                Logout
              </button>
            </div>
          </div>

          <div className="flex items-center justify-center searchBar">
            <div className="flex items-center justify-between search">
              <input
                type="text"
                value=""
                name="search"
                id="s"
                placeholder="| Type your search term here & hit enter"
              />
              <button
                type="submit"
                className="flex items-center justify-center"
              >
                <i className="fa fa-search" aria-hidden="true"></i>
              </button>
            </div>
            <div className="closeSearch">
              <i className="fa fa-times"></i>
            </div>
          </div>
        </div>
      </div>

      <div className="bottomHeader">
        <div className="flex items-center justify-between headerRow">
          <div
            onClick={() => {
              history.push("/qip/nqa-dashboard");
            }}
            className="logo"
          >
            <a>
              <img src={logoImg} alt="QIP logo" />
            </a>
          </div>

          <div className={`headerMenu   ${authObj ? "visible" : "invisible"}`}>
            <ul className="flex items-center mainHeaderMenu">
              <li
                onClick={() => {
                  history.push("/qip/nqa-dashboard");
                  window.scrollTo({ top: 0 });
                  setActiveMenu("nqa");
                }}
                className={"menuItem " + (activeMenu == "nqa" ? "active" : "")}
              >
                <a className="menuItemText">{`NQA's`}</a>
              </li>
              <li
                onClick={() => {
                  history.push("/qip/reports");
                  window.scrollTo({ top: 0 });
                  setActiveMenu("reports");
                }}
                className={
                  "menuItem " + (activeMenu == "reports" ? "active" : "")
                }
              >
                <a className="menuItemText">REPORTS</a>
              </li>
              <li
                onClick={() => {
                  history.push("/qip/tasks");
                  window.scrollTo({ top: 0 });
                  setActiveMenu("tasks");
                }}
                className={
                  "menuItem " + (activeMenu == "tasks" ? "active" : "")
                }
              >
                <a className="menuItemText">TASKS</a>
              </li>
              <li
                onClick={() => {
                  history.push("/qip/user-resource");
                  setActiveMenu("resource");
                  window.scrollTo({ top: 0 });
                }}
                className={
                  "menuItem " + (activeMenu == "resource" ? "active" : "")
                }
              >
                <a className="menuItemText">RESOURCES</a>
              </li>
              <li
                onClick={() => {
                  history.push("/qip/nqa/service-details");
                  window.scrollTo({ top: 0 });
                  setActiveMenu("service");
                }}
                className={
                  "menuItem " + (activeMenu == "service" ? "active" : "")
                }
              >
                <a className="menuItemText">SERVICE DETAILS</a>
              </li>
            </ul>

            <div className={styles.detailsFinishedWrapper}>
              <div className={styles.detailsFinishedContent}>
                <div className={styles.detailsFinishedPercentageContainer}>
                  <text className={styles.detailsFinishedPercentage}>
                    {/* {console.log(`total progress `, totalProgress)} */}
                    {Math.ceil(totalProgress) ? Math.ceil(totalProgress) : "0"}
                    {/* {Number(totalProgress) !== 99.94462901439645
                      ? Math.floor(totalProgress)
                        ? Math.floor(totalProgress)
                        : "0"
                      : "100"} */}
                  </text>

                  <text className={styles.detailsFinishedPercentSymbol}>%</text>
                </div>

                <text className={styles.detailsFinishedText}>QIP Finished</text>
              </div>

              <div className={styles.progressBar}>
                <div
                  className={styles.progressBarInside}
                  // style={{ width: `${Math.ceil(totalProgress)}%` }}
                  style={{
                    width: `${
                      Number(totalProgress) !== 94462901439645
                        ? Math.floor(totalProgress)
                        : 100
                    }%`,
                  }}
                />
              </div>
            </div>

            <div className="mobileMenu">
              <button
                onClick={() => setToggleMobileMenu(!toggleMobileMenu)}
                className="flex items-center justify-center menuBars"
              >
                <div className="bars"></div>
              </button>
              <ul
                className={`mainHeaderMobileMenu ${
                  toggleMobileMenu ? "slideDown" : ""
                }`}
              >
                <li
                  onClick={() => {
                    history.push("/qip/nqa-dashboard");
                    window.scrollTo({ top: 0 });
                    setActiveMenu("nqa");
                    setToggleMobileMenu(false);
                  }}
                  className={`menuItem ${activeMenu === "nqa" ? "active" : ""}`}
                >
                  <a className="menuItemText">NQA&apos;S</a>
                </li>
                <li
                  onClick={() => {
                    history.push("/qip/reports");
                    window.scrollTo({ top: 0 });
                    setActiveMenu("reports");
                    setToggleMobileMenu(false);
                  }}
                  className={`menuItem ${
                    activeMenu === "reports" ? "active" : ""
                  }`}
                >
                  <a className="menuItemText">REPORTS</a>
                </li>
                <li
                  onClick={() => {
                    history.push("/qip/tasks");
                    window.scrollTo({ top: 0 });
                    setActiveMenu("tasks");
                    setToggleMobileMenu(false);
                  }}
                  className={`menuItem ${
                    activeMenu === "tasks" ? "active" : ""
                  }`}
                >
                  <a className="menuItemText">TASKS</a>
                </li>
                <li
                  onClick={() => {
                    history.push("/qip/user-resource");
                    setActiveMenu("resource");
                    window.scrollTo({ top: 0 });
                    setToggleMobileMenu(false);
                  }}
                  className={`menuItem ${
                    activeMenu === "resource" ? "active" : ""
                  }`}
                >
                  <a className="menuItemText">RESOURCES</a>
                </li>
                <li
                  onClick={() => {
                    history.push("/qip/nqa/service-details");
                    window.scrollTo({ top: 0 });
                    setActiveMenu("service");
                    setToggleMobileMenu(false);
                  }}
                  className={`menuItem ${
                    activeMenu === "service" ? "active" : ""
                  }`}
                >
                  <a className="menuItemText">SERVICE&nbsp;DETAILS</a>
                </li>
                {/* <li className="menuItem hasDropdown">
                  <a href="javascript: void(0)" className="menuItemText">
                    Reports
                    <svg
                      fontSize="15"
                      width="1em"
                      height="1em"
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="caret-down"
                      className="svg-inline--fa fa-caret-down fa-w-10"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 320 512"
                    >
                      <path
                        fill="currentColor"
                        d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z"
                      ></path>
                    </svg>
                  </a>
                  <ul className="subMenu">
                    <li className="subMenuItem">
                      <a href="reports.php" className="subMenuItemText">
                        Reports
                      </a>
                    </li>
                    <li className="subMenuItem">
                      <a href="reports.php" className="subMenuItemText">
                        AP Reports
                      </a>
                    </li>
                    <li className="subMenuItem">
                      <a href="reports.php" className="subMenuItemText">
                        Mentoring Reports
                      </a>
                    </li>
                  </ul>
                </li> */}
                {/* <li className="menuItem">
                  <a href="bookmentorsession.php" className="menuItemText">
                    Book a Mentor Session
                  </a>
                </li> */}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewHeader;
