import * as React from "react";

import PropTypes from "prop-types";
import { Button, Modal } from "semantic-ui-react";

import styles from "./NQANotesModal.module.css";
import { FaSpinner } from "react-icons/fa";
import BlueButton from "../../BlueButton";
import InputComponent from "../../InputComponent";
import { postComply } from "../../../api/requests";

const { useState } = React;

function NQANotesModal({
  visibility,
  onOpenModal,
  onCloseModal,
  questionObject,
}) {
  const [note, setNote] = useState(questionObject.notes);
  const [complyLoading, setComplyLoading] = useState(false);

  const onSave = async () => {
    if (questionObject.notes !== note) {
      setComplyLoading(true);
      try {
        const response = await postComply(
          questionObject.centerId,
          questionObject.questionId,
          questionObject.status,
          questionObject.goalStrategy,
          questionObject.self_assessment,
          {
            notes: note,
          }
        );

        if (response) {
          setComplyLoading(false);
          onCloseModal();
        }
      } catch (error) {
        console.log(error);
        setComplyLoading(false);
      }
    } else onCloseModal();
  };

  return (
    <Modal
      size="large"
      centered={false}
      open={visibility}
      onOpen={onOpenModal}
      onClose={onCloseModal}
    >
      <div className={styles.modalContainer}>
        <Button
          className={styles.closeButton}
          onClick={onCloseModal}
          size="big"
          icon="close"
        />

        {complyLoading ? (
          <div
            className={`w-full h-screen flex flex-col justify-center items-center`}
          >
            <FaSpinner
              className={`animate-spin text-qipGreen font-bold text-6xl`}
            />
            <span className={`block`}>Uploading...</span>
          </div>
        ) : (
          <div className={styles.modalContent}>
            <text className={styles.headerText}>General notes or comments</text>

            <text className={styles.subText}>
              {questionObject.noteDescription
                ? questionObject.noteDescription
                : `
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua
                  `}
            </text>

            <InputComponent
              className={styles.notesBox}
              label="Write any notes here"
              textArea
              inputValue={note}
              setInputValue={(fieldName, value) => setNote(value)}
            />

            <BlueButton
              className={styles.blueButton}
              buttonText="Save changes"
              onClick={onSave}
            />
          </div>
        )}
      </div>
    </Modal>
  );
}

NQANotesModal.propTypes = {
  questionObject: PropTypes.shape({
    status: PropTypes.number,
    centerId: PropTypes.number,
    questionId: PropTypes.number,
    notes: PropTypes.string,
    goalStrategy: PropTypes.string,
    noteDescription: PropTypes.string,
  }).isRequired,
  visibility: PropTypes.bool.isRequired,
  onOpenModal: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired,
};

export default NQANotesModal;
