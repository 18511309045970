import { Action, action, computed, Computed, Thunk, thunk } from "easy-peasy";

import { setAxios } from "../../services/axios";
import {
  fetchAuthObject,
  fetchUserCenters,
  registerUser,
  getAdminAttr,
  fetchServiceDetails,
  createServiceCenter,
  fetchAuthUser,
  getUserProducts,
} from "../../api/requests";
import {
  AuthObject,
  LoginCreds,
  ServiceDetails,
  SignUpData,
  AdminAttr,
} from "../../types";

export interface AuthModel {
  isLoggedIn: Computed<AuthModel, boolean>;
  isAdmin: boolean;
  setIsAdmin: Action<AuthModel, boolean>;
  // user?: User;
  // setUser: Action<AuthModel, User>;
  authObject?: AuthObject;
  baseName: string;
  viewMode: boolean;
  setBaseName: Action<AuthModel, string>;
  setViewMode: Action<AuthModel, boolean>;
  adminAttr?: AdminAttr;
  setAuthObject: Action<AuthModel, AuthObject>;
  loadAuthObject: Thunk<
    AuthModel,
    void,
    Record<string, unknown>,
    Record<string, unknown>,
    Promise<boolean>
  >;
  setAdminAttr: Action<AuthModel, AdminAttr>;
  services: ServiceDetails[] | [];
  selectedService: ServiceDetails;
  setSelectedService: Action<AuthModel, ServiceDetails>;
  setServices: Action<AuthModel, ServiceDetails[]>;
  fetchServices: Thunk<AuthModel, number>;
  login: Thunk<AuthModel, LoginCreds>;
  clearSession: Action<AuthModel>;
  clearServices: Action<AuthModel>;
  logout: Thunk<AuthModel>;
  signUpCreds?: SignUpData;
  signUp: Thunk<AuthModel, SignUpData>;
  setSignUpCreds: Action<AuthModel, SignUpData | undefined>;
  fetchAdminAttr: Thunk<AuthModel, { memberId: number }>;
  activeServicesCount: boolean;
  setActiveServicesCount: Action<AuthModel, boolean>;
  // fetchUserDetails: Thunk<AuthModel, number>;
}

const authStore: AuthModel = {
  services: [],
  selectedService: {},

  activeServicesCount: false,
  setActiveServicesCount: action((state, payload) => {
    state.activeServicesCount = payload;
  }),
  isAdmin: false,
  setIsAdmin: action((state, payload) => {
    state.isAdmin = payload;
  }),
  baseName: `/`,
  viewMode: false,
  setBaseName: action((state, payload) => {
    state.baseName = payload;
  }),
  setViewMode: action((state, payload) => {
    state.viewMode = payload;
  }),
  isLoggedIn: computed((state) => {
    if (state.authObject) return true;

    return false;
  }),
  setAdminAttr: action((state, payload) => {
    console.log("attr ", payload);
    state.adminAttr = { ...payload };
  }),
  fetchAdminAttr: thunk(async (actions, payload) => {
    try {
      const adminUser = await getAdminAttr(payload.memberId);

      actions.setAdminAttr(adminUser);
    } catch (error) {
      console.log(error);
    }
  }),

  // setUser: action((state, payload) => {
  //   state.user = {
  //     ...state.user,
  //     ...payload,
  //   };
  // }),

  setSelectedService: action((state, payload) => {
    localStorage.setItem("selectedService", String(payload.id));
    state.selectedService = payload;
  }),

  setServices: action((state, payload) => {
    state.services = payload;
  }),

  fetchServices: thunk(async (actions, payload) => {
    const userId = payload;
    if (userId) {
      try {
        const services = await fetchUserCenters(userId);

        if (services.length > 0) {
          actions.setServices(services);

          const filteredServices = services.filter(
            (service) => Number(service.diffTime) > 0
          );

          actions.setActiveServicesCount(filteredServices.length > 0);

          if (filteredServices.length > 0) {
            const selectedServiceId = localStorage.getItem("selectedService");

            const setService: any = selectedServiceId
              ? filteredServices.find(
                  (s: any) => s.id === Number(selectedServiceId)
                )
                ? filteredServices.filter(
                    (s: any) => s.id === Number(selectedServiceId)
                  )[0]
                : filteredServices[0]
              : filteredServices[0];

            actions.setSelectedService(setService);
          } else {
            actions.setSelectedService(services[0]);
          }
        }
      } catch (error) {
        console.log(error);
        // window.location.reload();
      }
    }
  }),

  setAuthObject: action((state, payload) => {
    localStorage.setItem("inteli_token", JSON.stringify(payload));
    state.authObject = payload;
  }),

  loadAuthObject: thunk(async (actions) => {
    const cachedObject = localStorage.getItem("inteli_token");

    if (cachedObject) {
      const authObject: AuthObject = JSON.parse(cachedObject);

      if (authObject.viewer) {
        actions.setViewMode(true);
        actions.setBaseName(`viewer-${authObject.userId}`);
      }

      //TODO: request for another access token if token is expired

      setAxios(authObject.accessToken);
      actions.setAuthObject(authObject);
      // await actions.fetchAdminAttr({ memberId: authObject.userId });
      actions.fetchServices(authObject.userId);

      document.body.classList.add("loggedIn");
      document.body.classList.remove("loggedOut");

      return true;
    }

    document.body.classList.add("loggedOut");
    document.body.classList.remove("loggedIn");

    return false;
  }),

  login: thunk(async (actions, payload) => {
    try {
      const authObject = await fetchAuthObject(payload); // api request for login

      //################# intercept for super admin #######################
      const authUser = await fetchAuthUser(authObject.accessToken);
      if (authUser.user_type === "SUPER_ADMIN") {
        return false;
      }
      //###################################################################

      //######### intercept for incomplete service type/name #############
      // const serviceDetails = await fetchServiceDetails(
      //   authObject.userId,
      //   authObject.accessToken,
      //   payload.email
      // );

      // if (serviceDetails.name === payload.email) {
      //   const url = new URL(window.location.href);
      //   return {
      //     redirect: true,
      //     location:
      //       url.origin +
      //       "/qip/auth/finish-free-trial-signup?userId=" +
      //       authObject.userId +
      //       "&accessToken=" +
      //       authObject.accessToken,
      //   };
      //################################################################
      else {
        setAxios(authObject.accessToken);
        await actions.fetchServices(authObject.userId);
        actions.fetchAdminAttr({ memberId: authObject.userId });
        actions.setAuthObject(authObject);
        actions.setSignUpCreds(undefined);

        document.body.classList.add("loggedIn");
        document.body.classList.remove("loggedOut");

        return true;
      }
    } catch (error) {
      return false;
    }
  }),

  clearSession: action((state) => {
    setAxios("");
    // state.user = undefined;
    state.services = [];
    state.selectedService = {};
    state.signUpCreds = undefined;
    state.authObject = undefined;
    document.body.classList.add("loggedOut");
    document.body.classList.remove("loggedIn");
  }),

  clearServices: action((state) => {
    state.services = [];
    state.selectedService = {};
  }),

  logout: thunk(async (actions) => {
    localStorage.removeItem("inteli_token");
    actions.clearSession();
  }),

  signUp: thunk(async (actions, payload) => {
    try {
      const registerResponse = await registerUser(payload);

      if (registerResponse.status === 201) {
        actions.setSignUpCreds({
          ...payload,
          userId: registerResponse.userId,
          centerId: registerResponse.centerId,
        });
      }

      return true;
    } catch (error) {
      console.log(error);

      return false;
    }
  }),

  setSignUpCreds: action((state, payload) => {
    state.signUpCreds = payload;
  }),
};

export default authStore;
