import * as React from "react";

import { Link } from "react-router-dom";
import { HiCheckCircle } from "react-icons/hi";
import { Icon } from "semantic-ui-react";

import styles from "./LoginCard.module.css";

import { useStoreActions } from "../../hooks";
import BlueButton from "../../components/BlueButton";
import InputComponent from "../../components/InputComponent";
import IncorrectCreds from "../../components/Modals/CredentialErrorModal";
import { FaInfoCircle, FaSpinner } from "react-icons/fa";
import { isEmpty } from "lodash";

const { useCallback, useReducer, useState } = React;

const loginReducer = (state, action) => {
  switch (action.type) {
    case "LOGIN": {
      const updatedInput = {
        ...state.input,
        [action.field]: action.value,
      };

      const updatedValidation = {
        ...state.validation,
        [action.field]: action.valid,
      };

      const formValidity = Object.values(updatedValidation).every(Boolean);

      return {
        input: updatedInput,
        validation: updatedValidation,
        formValidation: formValidity,
      };
    }
    default:
      return state;
  }
};

export default function LoginCard() {
  const loginAction = useStoreActions((actions) => actions.authStore.login);

  useStoreActions((state) => state.appStore.setFirstSignUp(false));

  const initialLoginState = {
    input: {
      username: "",
      password: "",
    },
    validation: {
      username: true,
      password: true,
    },
    formValidation: true,
  };

  const [loginLoading, setLoading] = useState(false);

  const [loginState, loginDispatch] = useReducer(
    loginReducer,
    initialLoginState
  );

  const changeInputDispatch = useCallback((field, value) => {
    let valid = false;

    if (value) valid = true;
    const action = {
      type: "LOGIN",
      field,
      value,
      valid,
    };
    loginDispatch(action);
  }, []);

  const [incorrectCredsModalVis, setIncorrectCredsModalVis] = useState(false);

  const onLogin = async () => {
    setLoading(true);
    const loginCreds = {
      ...loginState.input,
    };

    const response = await loginAction(loginCreds);

    if (response !== true && response !== false) {
      if (!isEmpty(response)) {
        if (response.redirect) {
          window.location.replace(response.location);
        }
      }
    }

    if (response === false) {
      setLoading(false);
      setIncorrectCredsModalVis(true);
    }
  };

  const onConfirmIncorrect = () => {
    setIncorrectCredsModalVis(false);
  };

  return (
    <div className={styles.mainContainer}>
      {incorrectCredsModalVis && (
        <IncorrectCreds
          visibility={incorrectCredsModalVis}
          onOpenModal={() => setIncorrectCredsModalVis(true)}
          onCloseModal={onConfirmIncorrect}
        />
      )}
      {loginLoading ? (
        // <div className={styles.spinnerDiv}>
        <div
          className={`w-full h-screen flex flex-col justify-center items-center`}
        >
          <FaSpinner
            className={`animate-spin text-qipGreen font-bold text-6xl`}
          />

          <span className={`block`}>Loading data...</span>
        </div>
      ) : (
        <div className={styles.cardContainer}>
          <div className={styles.cardLeft}>
            <h2 className={styles.headerText}>Login</h2>
            <text className={styles.lightText}>Already have an account?</text>
            <text className={styles.lightText}>Sign in below.</text>

            <InputComponent
              fieldName="email"
              label="Registered Email Address"
              type="text"
              inputValue={loginState.input.username}
              setInputValue={changeInputDispatch}
            />

            <InputComponent
              fieldName="password"
              label="Password"
              type="password"
              inputValue={loginState.input.password}
              setInputValue={changeInputDispatch}
            />

            <button className={styles.loginButton} onClick={onLogin}>
              Login
            </button>

            <div className={styles.forgotPasswordGroup}>
              {/* <Icon className={styles.forgotPasswordIcon} name="info circle" /> */}
              <FaInfoCircle className={styles.forgotPasswordIcon} />
              <Link
                className={styles.forgotPasswordText}
                to="/qip/auth/forgot-password"
              >
                I forgot my password
              </Link>
            </div>
          </div>

          <div className={styles.cardRight}>
            <h2 className={styles.headerText}>Create an account</h2>

            <text className={`${styles.lightText} ${styles.bottomMargin}`}>
              Your full directory to assess your service and prepare your QIP
              and SAT for submission. Start your free trial today.
            </text>

            <div className={styles.checkTextGroup}>
              <HiCheckCircle color="#388cef" />
              <text className={styles.checkText}>
                Create and save your QIP online
              </text>
            </div>

            <div className={styles.checkTextGroup}>
              <HiCheckCircle color="#388cef" />
              <text className={styles.checkText}>
                Generate fast, detailed QIP reports
              </text>
            </div>

            <div className={styles.checkTextGroup}>
              <HiCheckCircle color="#388cef" />
              <text className={styles.checkText}>
                Work as a team to complete QIP goals
              </text>
            </div>

            <div className={styles.checkTextGroup}>
              <HiCheckCircle color="#388cef" />
              <text className={styles.checkText}>
                Send Education Department ready reports
              </text>
            </div>

            <BlueButton
              className={styles.buttonContainer}
              link="/qip/auth/pricing-plans"
              buttonText="Create an account"
            />
          </div>
        </div>
      )}
    </div>
  );
}
