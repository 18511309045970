import * as React from "react";

import { useHistory } from "react-router-dom";
import { FaRegArrowAltCircleLeft, FaInfoCircle } from "react-icons/fa";

import styles from "./UpgradeSubscription.module.css";
import { Button, Icon } from "semantic-ui-react";
import BlueButton from "../../../components/BlueButton";
import UpgradeSubsriptionModal from "../../../components/Modals/UpgradeSubscriptionModal";
import { useStoreActions, useStoreState } from "../../../hooks";
import { changePlan } from "../../../api/requests";
import { FaSpinner } from "react-icons/fa";

const { useState } = React;

const benefitsList = [
  "Everything inside the QIP Portal",
  "Request feedback to all NQAs",
  "Get guidance from our mentors",
  "Termly mentoring sessions",
  "Montly bespoke service resources",
  "A&R preview & preparation",
  "Access montly expert webinars",
  "Email and support",
];

const getPrice = (planSubscription = 1) => {
  if (planSubscription === 1) {
    if (process.env.REACT_APP_PRICEID === "TEST") {
      return "price_1HjdWJDeO3XG77wN2ZWtoJzb";
    }

    if (process.env.REACT_APP_PRICEID === "LIVE") {
      return "price_1IOWb0DeO3XG77wNEhS9fx7l";
    }
  }
  if (planSubscription === 2) {
    if (process.env.REACT_APP_PRICEID === "TEST") {
      return "price_1HjdWJDeO3XG77wNw70ZhCMf";
    }

    if (process.env.REACT_APP_PRICEID === "LIVE") {
      return "price_1IOWb0DeO3XG77wN3N58kPjh";
    }
  }
};

function UpgradeSubscription() {
  const history = useHistory();

  const [tab, setTab] = useState(1);

  const userId = useStoreState((state) => state.authStore.authObject.userId);

  const [loading, setLoading] = useState(false);

  const selectedService = useStoreState(
    (state) => state.authStore.selectedService
  );

  const [
    upgradeSubsriptionModalVisibility,
    setupgradeSubsriptionModalVisibility,
  ] = useState(false);

  const changeSub = async () => {
    setLoading(true);
    try {
      const response = await changePlan(
        userId,
        selectedService.id,
        getPrice(tab)
      );
      setLoading(false);
      setupgradeSubsriptionModalVisibility(true);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  return (
    <div className={styles.mainContainer}>
      <UpgradeSubsriptionModal
        visibility={upgradeSubsriptionModalVisibility}
        onOpenModal={() => setupgradeSubsriptionModalVisibility(true)}
        onCloseModal={() => setupgradeSubsriptionModalVisibility(false)}
      />

      <div className={styles.backButtonContainer}>
        <button
          className={styles.backButtonText}
          onClick={() => history.goBack()}
        >
          <FaRegArrowAltCircleLeft
            className={styles.backButton}
            color="#C4C4C4"
            size={24}
          />
        </button>
      </div>

      <div className={`${styles.content} flex`}>
        <div className={styles.leftCardColumn}>
          <div className={`${styles.leftCardColumnContent} flex flex-col`}>
            <Button
              className={`${styles.btnUpgrade} flex items-center justify-center`}
              icon="rocket"
            />

            <text className={`${styles.leftCardHeader} mt-7`}>
              Upgrade your subscription to access Mentoring
            </text>

            <text className={`${styles.descriptionText} mt-5`}>
              Get guidance & feedback from experienced mentors. With our premium
              plan, our mentors will review your QIP documentation.
            </text>

            <text className={`${styles.descriptionText} flex mt-7`}>
              <FaInfoCircle className={`mr-3.5`} size={40} />
              You and your team will continue to have full access to all of your
              existing features, settings and content within the Holistic QIP
              app.
            </text>
          </div>
        </div>

        <div className={`${styles.rightCardColumn} flex flex-col`}>
          <div className={`flex`}>
            <div className={`${styles.cardTabSelected} p-5 w-3/6`}>Upgrade</div>
          </div>

          <div className={`${styles.subscriptionContent} flex flex-col`}>
            <div
              className={`${styles.subscriptionPriceRow} flex items-baseline`}
            >
              <text className={`mt-3.5 self-start`}>$</text>

              <text>197</text>

              <text>/month</text>
            </div>

            <text className={`${styles.subscriptionHeader} mt-4`}>
              Portal + Mentoring
            </text>

            <text className={`${styles.descriptionText} mt-4 mb-3`}>
              The QIP Portal plus guidance & feedback from our mentors.
            </text>

            {Array.from(benefitsList).map((benefit) => (
              <div
                className={`${styles.subscriptionBenefitEntry} flex mt-4`}
                key={benefit}
              >
                <Icon className={styles.checkCircle} name="check circle" />

                <text>{benefit}</text>
              </div>
            ))}

            <BlueButton
              className={`${styles.blueButton} mt-10`}
              onClick={changeSub}
              // onClick={setupgradeSubsriptionModalVisibility}
              // onClick={() => {
              //   history.push("/qip/payment", {
              //     planVarian: "monthly",
              //     plan: `portal`,
              //   });
              // }}
              // buttonText="Upgrade now"

              buttonText={
                loading ? (
                  <div className={`flex justify-center items-center`}>
                    <FaSpinner className={`animate-spin`} />
                    <span className={`ml-2`}>Please wait... </span>
                  </div>
                ) : (
                  `Upgrade now`
                )
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default UpgradeSubscription;
