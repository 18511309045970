import * as React from "react";

import { Dropdown, Modal } from "semantic-ui-react";
import BlueButton from "../../../components/BlueButton";
import SendEmailModal from "../../../components/Modals/SendEmailModal";

import InvoiceDownloadingModal from "../../../components/Modals/InvoiceDownloadingModal";
import SuccessSendEmailModal from "../../../components/Modals/SuccessSendEmailModal";
import { useStoreActions, useStoreState } from "../../../hooks";
import { getInvoices } from "../../../api/requests";
import { FaSpinner } from "react-icons/fa";

import styles from "./PastPayments.module.css";
import { isEmpty } from "lodash";

const { useState, useEffect, useCallback, useRef } = React;

// const servicesFilterOptions = [
//   {
//     key: "Service1",
//     text: "Service1",
//     value: "Service1",
//   },
//   {
//     key: "Service2",
//     text: "Service2",
//     value: "Service2",
//   },
//   {
//     key: "Service3",
//     text: "Service3",
//     value: "Service3",
//   },
// ];

// const datesFilterOptions = [
//   {
//     key: "6/12/20",
//     text: "6/12/20",
//     value: "6/12/20",
//   },
//   {
//     key: "5/12/20",
//     text: "5/12/20",
//     value: "5/12/20",
//   },
//   {
//     key: "7/12/20",
//     text: "7/12/20",
//     value: "7/12/20",
//   },
// ];

// const invoicesFilterOptions = [
//   {
//     key: "0027",
//     text: "0027",
//     value: "0027",
//   },
//   {
//     key: "0037",
//     text: "0037",
//     value: "0037",
//   },
//   {
//     key: "0037",
//     text: "0037",
//     value: "0037",
//   },
// ];

// const dummyinvoices = [
//   {
//     invoiceId: 1,
//     subsName: "Little Smarties ST Claire",
//     invoiceNumber: "0027",
//     date: "6/12/20",
//   },
//   {
//     invoiceId: 2,
//     subsName: "Little Smarties ST Claire",
//     invoiceNumber: "0027",
//     date: "6/12/20",
//   },
//   {
//     invoiceId: 3,
//     subsName: "Little Smarties ST Claire",
//     invoiceNumber: "0027",
//     date: "6/12/20",
//   },
//   {
//     invoiceId: 4,
//     subsName: "Little Smarties ST Claire",
//     invoiceNumber: "0027",
//     date: "6/12/20",
//   },
//   {
//     invoiceId: 5,
//     subsName: "Little Smarties ST Claire",
//     invoiceNumber: "0027",
//     date: "6/12/20",
//   },
// ];

function PastPayments() {
  const [invoices, setInvoices] = useState([]);

  const [loadingInvoices, setLoadingInvoices] = useState(false);
  const [downloading, setDownloading] = useState(false);

  const [invoiceName, setInvoiceName] = useState("");
  const [invoiceFile, setInvoiceFile] = useState("");

  const [serviceFilter, setServiceFilter] = useState("All");
  const [dateFilter, setDateFilter] = useState("All");
  const [invoiceFilter, setInvoiceFilter] = useState("All");
  const invoiceRef = useRef();

  const userId = useStoreState((state) => state.authStore.authObject.userId);

  const [emailModalVisibility, setemailModalVisibility] = useState(false);

  const [invoiceModalVisibility, setinvoiceModalVisibility] = useState(false);
  const [successeEmailModalVisibility, setsuccesseEmailModalVisibility] =
    useState(false);

  const onConfirmEmail = () => {
    setemailModalVisibility(false);
    setsuccesseEmailModalVisibility(true);
  };

  const [servicesFilterOptions, setServicesFilterOptions] = useState([
    { key: "All", text: "All", value: "All" },
  ]);
  const [datesFilterOptions, setDatesFilterOptions] = useState([
    { key: "All", text: "All", value: "All" },
  ]);
  const [invoicesFilterOptions, setInvoicesFilterOptions] = useState([
    { key: "All", text: "All", value: "All" },
  ]);

  const onDownloadInvoice = async (invoiceURL, invoiceName) => {
    await setDownloading(true);

    await setInvoiceName(invoiceName);
    await setInvoiceFile(invoiceURL);
    await invoiceRef.current.click();

    setDownloading(false);
  };

  const fetchInvoices = useCallback(async () => {
    setLoadingInvoices(true);
    try {
      const response = await getInvoices(userId);

      let inFilters = [{ key: "All", text: "All", value: "All" }];
      let serFilters = [{ key: "All", text: "All", value: "All" }];
      let datFilters = [{ key: "All", text: "All", value: "All" }];
      response.forEach((r) => {
        if (
          !serFilters.some(
            (filter) => String(filter.value) === String(r.center)
          )
        ) {
          serFilters.push({
            key: r.center,
            text: r.center,
            value: r.center,
          });
        }
        if (!datFilters.some((filter) => filter.value === r.date)) {
          datFilters.push({
            key: r.date,
            text: r.date,
            value: r.date,
          });
        }

        if (
          !inFilters.some(
            (filter) => String(filter.value) === String(r.invoice.number)
          )
        ) {
          inFilters.push({
            key: r.invoice.number,
            text: r.invoice.number,
            value: r.invoice.number,
          });
        }
      });

      setServicesFilterOptions(serFilters);
      setDatesFilterOptions(datFilters);
      setInvoicesFilterOptions(inFilters);

      setInvoices(response);

      setLoadingInvoices(false);
    } catch (error) {
      setLoadingInvoices(false);
      console.log(error);
    }
  }, []);

  useEffect(() => {
    fetchInvoices();
  }, []);

  return (
    <div className={styles.mainContainer}>
      {downloading && (
        <Modal
          size={`small`}
          className={`pt-14 pb-8 px-14`}
          centered={true}
          open={downloading}
        >
          <div className={`w-full flex flex-col justify-center items-center`}>
            <FaSpinner
              className={`animate-spin text-qipGreen font-bold text-6xl`}
            />
            <div className={`text-3xl w-full text-center pb-5 mt-3`}>
              {/* QIP is being downloaded */}
              Your invoice is being downloaded
            </div>
          </div>

          {/* <div className={styles.progressBarContainer}>
            <div className={styles.progressBar} style={{ width: `100%` }} />
          </div>

          <div className={styles.progressBarText}>
            <text>Downloading</text>

            <text>100 %</text>
          </div> */}
        </Modal>
      )}
      <SendEmailModal
        visibility={emailModalVisibility}
        onOpenModal={() => setemailModalVisibility(true)}
        onCloseModal={() => setemailModalVisibility(false)}
        onRemoveEmail={onConfirmEmail}
      />
      <SuccessSendEmailModal
        visibility={successeEmailModalVisibility}
        onOpenModal={() => setsuccesseEmailModalVisibility(true)}
        onCloseModal={() => setsuccesseEmailModalVisibility(false)}
      />
      <InvoiceDownloadingModal
        visibility={invoiceModalVisibility}
        onOpenModal={() => setinvoiceModalVisibility(true)}
        onCloseModal={() => setinvoiceModalVisibility(false)}
      />

      <text className={styles.headerText}>
        Past invoices on this subscription
      </text>

      <div className={`${styles.invoiceFiltersRow} flex`}>
        <Dropdown
          className={`${styles.filterDropdown} md:mr-8 lg:mr-14`}
          inline
          header="Services"
          placeholder="Service"
          onChange={(e, { value }) => {
            setServiceFilter(value);
          }}
          options={servicesFilterOptions}
        />

        <Dropdown
          className={`${styles.filterDropdown} md:mr-8 lg:mr-14`}
          inline
          header="Date Added"
          placeholder="Date Added"
          onChange={(e, { value }) => {
            setDateFilter(value);
          }}
          options={datesFilterOptions}
        />

        <Dropdown
          className={styles.filterDropdown}
          inline
          header="Invoice Nº"
          placeholder="Invoice Nº"
          onChange={(e, { value }) => {
            setInvoiceFilter(value);
          }}
          options={invoicesFilterOptions}
        />
      </div>

      <div className={styles.invoiceContainer}>
        {loadingInvoices && (
          <div className={`py-14 flex flex-col justify-center items-center`}>
            <FaSpinner
              className={`animate-spin text-qipGreen font-bold text-6xl`}
            />
            <span className={`py-2`}>Loading Invoices...</span>
          </div>
        )}
        {!loadingInvoices &&
          invoices &&
          invoices
            .filter((invoice) => {
              if (serviceFilter !== "All") {
                return String(invoice.center) === String(serviceFilter);
              } else {
                return true;
              }
            })
            .filter((invoice) => {
              if (dateFilter !== "All") {
                return String(invoice.date) === String(dateFilter);
              } else {
                return true;
              }
            })
            .filter((invoice) => {
              if (invoiceFilter !== "All") {
                return String(invoice.invoice.number) === String(invoiceFilter);
              } else {
                return true;
              }
            })
            .map((invoice) => (
              <div key={invoice.invoice.id} className={styles.invoice}>
                <div className={styles.invoiceTextDetailsColumn}>
                  <text className={styles.invoiceServiceLabel}>
                    {invoice.center}
                  </text>

                  <div className={styles.subtext}>
                    <span className={styles.date}>
                      <strong>Date:</strong> {invoice.date}
                    </span>{" "}
                    <span className={styles.invoicenumber}>
                      <strong>Invoice number:</strong> {invoice.invoice.number}
                    </span>
                  </div>
                </div>

                <div className={styles.buttonColumn}>
                  <BlueButton
                    className={styles.download}
                    buttonText="Download PDF"
                    onClick={() => {
                      setDownloading(true);

                      onDownloadInvoice(
                        invoice.invoice.invoice_pdf,
                        `Invoice-${invoice.invoice.number}`
                      );
                    }}
                    // onClick={setinvoiceModalVisibility}
                  />
                  <a
                    ref={invoiceRef}
                    href={invoiceFile}
                    download={invoiceName}
                    className={`hidden`}
                  ></a>
                  {/* <div>
                <button
                  className={styles.sendEmailButton}
                  onClick={setemailModalVisibility}
                >
                  Send via e-mail
                </button>
              </div> */}
                </div>
              </div>
            ))}
      </div>
    </div>
  );
}

export default PastPayments;
