import * as React from "react";

import PropTypes from "prop-types";
import { Button, Dropdown, Input } from "semantic-ui-react";

import styles from "./MessageInbox.module.css";
import { getUserUnreadMessages } from "../../../api/requests";
import useSWR, { mutate } from "swr";
import { isEmpty } from "lodash";
import Pagination from "../../../components/Pagination/Pagination";
import { FaSpinner, FaArrowRight, FaAngleRight } from "react-icons/fa";
import { useStoreActions, useStoreState } from "../../../hooks";
import moment from "moment";

const { useState } = React;

const messagesPerPage = 24;

const dummyMessages = [
  {
    id: 1,
    subject: "Attention all users, please update your service details",
    senderName: "Charlotte Wilson",
    senderRole: "Admin",
    timeSent: "10:04AM",
    isNew: true,
    isUrgent: true,
    starred: true,
    hasAttachments: true,
  },
  {
    id: 2,
    subject: "Not urgent - uploading evidence (attached .pdf files)",
    senderName: "Charlotte Wilson",
    senderRole: "Admin",
    timeSent: "10:04AM",
    isNew: true,
    isUrgent: false,
    starred: false,
    hasAttachments: true,
  },
  {
    id: 3,
    subject: "Little Smarties Report missing details",
    senderName: "Charlotte Wilson",
    senderRole: "Admin",
    timeSent: "10:04AM",
    isNew: true,
    isUrgent: false,
    starred: false,
    hasAttachments: false,
  },
  {
    id: 4,
    subject: "NQA1 Documents attached",
    senderName: "Charlotte Wilson",
    senderRole: "Admin",
    timeSent: "10:04AM",
    isNew: false,
    isUrgent: false,
    starred: false,
    hasAttachments: true,
  },
  {
    id: 5,
    subject: "New tasks for this week",
    senderName: "Charlotte Wilson",
    senderRole: "Admin",
    timeSent: "10:04AM",
    isNew: false,
    isUrgent: true,
    starred: false,
    hasAttachments: false,
  },
];

function MessageInbox({ onDelete, onDownload, onSelectMessage, messages }) {
  const [pages] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);

  const userId = useStoreState((state) => state.authStore.authObject.userId);

  const [searchKey, setSearchKey] = useState("");

  const checkIconStyle = (message) => {
    if (message.isNew) {
      const style = message.isUrgent
        ? styles.iconHeaderAlert
        : styles.iconHeaderMessage;

      return style;
    }

    return styles.iconHeaderMessageRead;
  };

  const checkIcon = (message) => {
    if (!message.isUrgent) {
      const icon = message.isNew ? "mail" : "envelope open outline";

      return icon;
    }

    return "warning circle";
  };

  return (
    <div className={styles.mainContainer}>
      <div className={styles.searchFilterRow}>
        <Input
          className={styles.searchInput}
          icon="search"
          placeholder="Search"
          onChange={(e) => {
            if (currentPage !== 1) {
              setCurrentPage(1);
            }
            setSearchKey(e.target.value);
          }}
        />
      </div>

      {!isEmpty(messages.messages) &&
        messages.messages
          .filter((message) => {
            if (searchKey) {
              return message.subject
                .toLowerCase()
                .includes(searchKey.trim().toLowerCase());
            } else {
              return true;
            }
          })
          .slice(
            currentPage * messagesPerPage - messagesPerPage,
            currentPage * messagesPerPage
          )
          .map((message) => (
            <>
              <div key={message} className={`${styles.messageRow} `}>
                <div className={`${styles.messageDetailsColumn} `}>
                  <div className={styles.iconHeaderGroup}>
                    <Button
                      // className={checkIconStyle(message)}
                      className={
                        message.read
                          ? styles.iconHeaderMessageRead
                          : styles.iconHeaderMessage
                      }
                      // icon={checkIcon(message)}
                      icon={`mail`}
                    />
                  </div>

                  <div className={styles.messageTextGroup}>
                    <text
                      className={`${styles.messageSubjectText} overflow-ellipsis overflow-hidden`}
                      onClick={() => onSelectMessage(message)}
                    >
                      {message.subject}
                    </text>

                    <div className={styles.messageSubText}>
                      {/* <text>{message.senderName}</text> */}
                      <text>{`${message.message_from_name} (Admin)`}</text>

                      <text>
                        {/* ({message.user_from}) sent on {message.date_send} */}
                        sent on {message.created_at.split("-").join("/")}
                      </text>
                    </div>
                  </div>
                </div>

                <div className={`${styles.actionButtonGroup}`}>
                  <Button
                    icon="close"
                    className={styles.deleteButton}
                    onClick={() => {
                      onDelete(message);
                    }}
                  />
                </div>
              </div>
            </>
          ))}

      <div className={styles.pageButtonGroup}>
        {!isEmpty(messages.messages) &&
          messagesPerPage < messages.messages.length && (
            <Pagination
              currentPage={currentPage}
              onPageChange={setCurrentPage}
              lastPage={Math.ceil(messages.messages.length / messagesPerPage)}
            />
          )}
      </div>
    </div>
  );
}

MessageInbox.propTypes = {
  onDelete: PropTypes.func,
  onDownload: PropTypes.func,
  onSelectMessage: PropTypes.func,
};

export default MessageInbox;
