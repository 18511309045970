import * as React from "react";

import { useHistory, useRouteMatch, useParams } from "react-router-dom";
import {
  FaRegArrowAltCircleLeft,
  FaChevronRight,
  FaChevronLeft,
} from "react-icons/fa";
import styles from "./ResourceShop.module.css";
import prod1 from "../../static/images/critical-reflection-the-holistic-way.jpg";
import { Button, Button as IconButton } from "semantic-ui-react";
import BlueButton from "../../components/BlueButton";
import { imageShopLink } from "../../api/requests";
import { isEmpty } from "lodash";

const { useState, useEffect } = React;

export default function ResourceShop({ resourceShops, currentId, setViewAll }) {
  const history = useHistory();
  const match = useRouteMatch();
  const { webinarsChoiceId } = useParams();
  const [selectedOption, setOption] = useState(parseInt(2));
  const [nqaVisibility, setNQAVisibility] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(
    resourceShops.indexOf(resourceShops.filter((r) => r.id === currentId)[0])
  );
  const [shopLink, setShopLink] = useState("");
  const [shopLength, setShopLength] = useState(
    !isEmpty(resourceShops) ? resourceShops.length : 0
  );
  const [loading, setLoading] = useState(false);

  const [ids, setIds] = useState(resourceShops.map((r) => r.id));

  const onBackToResources = () => {
    const currentPath = match.path.split("/");
    const dashboardPath = currentPath
      .slice(0, currentPath.length - 1)
      .join("/");

    history.push(dashboardPath);
  };

  const getImageShopLink = async (id) => {
    try {
      setLoading(true);
      const response = await imageShopLink(Number(id));
      setShopLink(response);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    getImageShopLink(currentId);
  }, [currentId]);

  return (
    <div className={styles.mainContainer}>
      <div className={styles.resourcesContainer}>
        <div className={styles.contentHolder}>
          <div className={styles.shopResourcesContainer}>
            {!isEmpty(resourceShops) && (
              <>
                <div className={styles.shopResourcesImg}>
                  <img src={shopLink} />
                </div>

                <div className={styles.shopResourcesContent}>
                  {/* <h2>Holistic Critical Reflection</h2> */}
                  <h2>{resourceShops[selectedIndex].product_name}</h2>

                  {/* <text className={`block font-medium mb-1.5`}>
                    Over 100 Critical Reflection tools across all 7 NQA
                  </text>

                  <text className={`block font-light`}>
                    A complete guide to How, What and Why Critically Reflect in
                    your service.
                  </text>

                  <ul>
                    <li>300 NQF evidences for an exceeding achievement.</li>
                    <li>Over 250 QIP Goals and Strategies</li>
                    <li>
                      Over 120 Checklists, forms, templates and surveys to help
                      any director!
                    </li>
                  </ul> */}
                  <div
                    className={`disabledPreflight`}
                    dangerouslySetInnerHTML={{
                      __html: resourceShops[selectedIndex].product_description,
                    }}
                  ></div>

                  <text className={`${styles.prize} block font-normal`}>
                    {/* $147.00 inc.GST */}
                    {resourceShops[selectedIndex].product_price}
                  </text>

                  <BlueButton
                    className={`${styles.viewButton}`}
                    buttonText="View Product"
                    // onClick={() =>
                    //   (window.location.href =
                    //     "https://holisticmanagement.net.au/product/")
                    // }

                    onClick={() =>
                      window.open(
                        resourceShops[selectedIndex].product_link,
                        "_blank"
                      )
                    }
                  />
                </div>
              </>
            )}
          </div>

          <div className={`${styles.resourcesPagination} flex justify-between`}>
            <Button
              disabled={loading}
              onClick={async () => {
                if (selectedIndex > 0) {
                  await getImageShopLink(resourceShops[selectedIndex - 1].id);
                  setSelectedIndex(selectedIndex - 1);
                }
              }}
            >
              <FaChevronLeft className={`mr-1`} size={12} />
              Previous resource
            </Button>

            <Button
              onClick={() => {
                setViewAll();
              }}
            >
              All resources
            </Button>

            <Button
              disabled={loading}
              onClick={async () => {
                if (selectedIndex < ids.length - 1) {
                  await getImageShopLink(resourceShops[selectedIndex + 1].id);
                  setSelectedIndex(selectedIndex + 1);
                }
              }}
            >
              Next resource
              <FaChevronRight className={`ml-1`} size={12} />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
