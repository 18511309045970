import * as React from "react";
import { Route, Switch, useRouteMatch, Redirect } from "react-router-dom";
import { useStoreActions, useStoreState } from "../../hooks";
import {
  fetchServiceDetails,
  updateServiceDetails,
  fetchUserCenters,
} from "../../api/requests";
import { generateHash, generatePlainText } from "../../services/crypto";
import { FaSpinner } from "react-icons/fa";
import { useSWRConfig } from "swr";

const { useEffect } = React;

export default function HubAppRedirect({ iAmLoading }) {
  const { cache } = useSWRConfig();

  const match = useRouteMatch();

  const url = new URL(window.location.href);

  const userId = url.searchParams.get("userId");
  const centerId = url.searchParams.get("centerId");
  const stripeId = url.searchParams.get("stripeId");
  const cipherToken = decodeURIComponent(url.searchParams.get("accessToken"));
  const tokenHash = decodeURIComponent(url.searchParams.get("tokenHash"));
  const cipherAuth = decodeURIComponent(url.searchParams.get("auth"));
  const authHash = decodeURIComponent(url.searchParams.get("authHash"));
  const counter = url.searchParams.get("counter");

  const setAuthObject = useStoreActions(
    (actions) => actions.authStore.setAuthObject
  );

  const clearServices = useStoreActions(
    (actions) => actions.authStore.clearServices
  );

  const initDetails = async () => {
    iAmLoading(true);
    localStorage.clear();
    clearServices();
    cache.clear();
    const accessToken = generatePlainText(cipherToken);
    const authUser = generatePlainText(cipherAuth);

    const tHash = generateHash(accessToken);
    const aHash = generateHash(authUser);

    if (tHash !== tokenHash) {
      window.location = "/qip";
      // return <Redirect to={`/qip`} />;
    } else {
      if (centerId > 0) {
        localStorage.setItem("selectedService", centerId);
      }

      if (stripeId === "empty" || stripeId === "null") {
        setAuthObject({
          userId: Number(userId),
          stripeId: null,
          accessToken: accessToken,
          // accessToken: "test",
        });
      } else {
        setAuthObject({
          userId: Number(userId),
          stripeId: stripeId,
          accessToken: accessToken,
          // accessToken: "test",
        });
      }

      // await fetchServices(Number(userId));
      // if (stripeId !== "empty" || stripeId !== "null") {
      //   await fetchPaymentMethods({ userId: Number(userId) });
      // }

      // await fetchAdmin({ memberId: Number(userId) });
    }
    iAmLoading(false);
  };

  useEffect(() => {
    initDetails().then(() => {
      window.location = "/";
    });
  }, [counter]);

  return (
    <div
      className={`w-full h-screen flex flex-col justify-center items-center`}
    >
      <FaSpinner className={`animate-spin text-qipGreen font-bold text-6xl`} />

      <div>Please wait...</div>
    </div>
  );
}
