import * as React from 'react';

import { useHistory } from 'react-router-dom';
import { FaRegArrowAltCircleLeft } from 'react-icons/fa';

import styles from './UpgradeServiceView.module.css';
import { Icon } from 'semantic-ui-react';
import BlueButton from '../../../components/BlueButton';

const { useState } = React;

const benefitsList = [
  'Create and save your QIP online',
  'Create fast, detailed QIP reports',
  'Work as a team to complete QIP goals',
  'Send Education Department ready reports',
];
  
function UpgradeServiceView() {
  const history = useHistory();

  const [tab, setTab] = useState(1);

  return (
    <div className={styles.mainContainer}>

      <div className={styles.backButtonContainer}>

        <button
          className={styles.backButtonText}
          onClick={() => history.goBack()}
        >
          <FaRegArrowAltCircleLeft
            className={styles.backButton}
            color="#C4C4C4"
            size={24}
          />
        </button>

      </div>

      <div className={styles.content}>

        <div className={styles.leftCardColumn}>

          <div className={styles.leftCardColumnContent}>

            <text className={styles.leftCardHeader}>
              To add a new service you must upgrade your subscription
            </text>

            <text className={styles.descriptionText}>
              Lorem ipsum dolor sit amet euismo, consectetur adipiscing elit, sed do eiusmod tempor incididunt. Lorem ipsum dolor sit.
            </text>

            {Array.from({ length: 7 }, (_, i) => benefitsList[i % benefitsList.length]).map(benefit =>
              (
                <div
                  className={styles.benefitEntry}
                  key={benefit}
                >

                  <Icon
                    className={styles.checkCircle}
                    name="check circle"
                  />

                  <text>
                    {benefit}
                  </text>

                </div>
              ))}

          </div>

        </div>

        <div className={styles.rightCardColumn}>

          <div className={styles.cardTabRow}>

            <div
              className={tab === 1 ? styles.cardTabSelected : styles.cardTab}
              onClick={() => setTab(1)}
            >
              QIP
            </div>

            <div
              className={tab === 2 ? styles.cardTabSelected : styles.cardTab}
              onClick={() => setTab(2)}
            >
              QIP Plus
            </div>

          </div>

          <div className={styles.subscriptionContent}>

            <div className={styles.subscriptionPriceRow}>

              <text>
                $
              </text>

              <text>
                {tab === 1 ? '97' : '197'}
              </text>

              <text>
                {tab === 1 ? '/month' : '/yearly'}
              </text>

            </div>

            <text className={styles.subscriptionHeader}>
              {tab === 1 ? 'QIP Portal' : 'QIP Plus'}
            </text>

            <text className={styles.descriptionText}>
              Lorem ipsum dolor sit amet euismo, consectetur adipiscing elit, sed do eiusmod tempor incididunt.
            </text>

            {Array.from({ length: 4 }, (_, i) => benefitsList[i % benefitsList.length]).map(benefit =>
              (
                <div
                  className={styles.subscriptionBenefitEntry}
                  key={benefit}
                >

                  <Icon
                    className={styles.checkCircle}
                    name="check circle"
                  />

                  <text>
                    {benefit}
                  </text>

                </div>
              ))}

            <BlueButton
              className={styles.blueButton}
              buttonText="Add new"
            />

          </div>

        </div>

      </div>

    </div >
  );
}

export default UpgradeServiceView;
