import * as React from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { Button, Button as IconButton, Input } from "semantic-ui-react";

import {
  FaPlusCircle,
  FaCommentDots,
  FaEdit,
  FaLink,
  FaTimes,
  FaRegArrowAltCircleLeft,
  FaRegFileWord,
  FaFileWord,
  FaFilePdf,
  FaFileExcel,
  FaFilePowerpoint,
  FaImage,
  FaPlay,
  FaVolumeUp,
  FaFileArchive,
  FaFile,
  FaShoppingBag,
} from "react-icons/fa";

import useSWR, { mutate } from "swr";
import { isEmpty } from "lodash";
import {
  fetchNQAData,
  getAllResources,
  downloadResource,
  getAllResourcesShop,
  getAllResourcesTutorial,
} from "../../../api/requests";

import BlueButton from "../../../components/BlueButton";
import ResourceDownloadingModal from "../../../components/Modals/ResourceDownloadingModal";
import Pagination from "../../../components/Pagination/Pagination";
import ResourceShop from "../../../components/ResourceShop/ResourceShop";
import ResourceTutorial from "../../../components/ResourceTutorial/ResourceTutorial";

import fileLogo from "../../../static/images/wordlogo.JPG";
import webinar1 from "../../../static/images/webinarBox1.png";
import webinar2 from "../../../static/images/webinarBox2.png";
import webinar3 from "../../../static/images/webinarBox3.png";
import webinar4 from "../../../static/images/webinarBox4.png";
import prod1 from "../../../static/images/critical-reflection-the-holistic-way.jpg";
import prod2 from "../../../static/images/Holistic-Director_A4-Flyer_Final.jpg";
import prod3 from "../../../static/images/holistic-documentation-displays.jpg";
import prod4 from "../../../static/images/self-assessment-oosh-manual.jpg";
import prod5 from "../../../static/images/stem-the-holistic-way.jpg";
import prod6 from "../../../static/images/the-holistic-director.jpg";
import styles from "./ResourceCenter.module.css";

const { useState, useRef } = React;

const resourcesPerPage = 10;

export default function ResourceCenter() {
  const history = useHistory();
  const match = useRouteMatch();
  const [currentPage, setCurrentPage] = useState(1);

  const [currentPageShop, setCurrentPageShop] = useState(1);
  const [currentPageTutorial, setCurrentPageTutorial] = useState(1);

  const [currentFilter, setCurrentFilter] = useState(-1);
  const [subCurrentFilter, setSubCurrentFilter] = useState("all");
  // const [currentFilter, setCurrentFilter] = useState('all');
  const [selectedOption, setOption] = useState(1);
  const [nqaVisibility, setNQAVisibility] = useState(false);

  const [fetchLimitError, setFetchLimitError] = useState(false);

  const [resourceId, setResourceId] = useState(-1);
  const [resourceName, setResourceName] = useState("");
  const [resourceFile, setResourceFile] = useState("");
  const [keySearch, setKeySearch] = useState("");

  const [resourceShopView, setResourceShopView] = useState(false);
  const [resourceTutorialView, setResourceTutorialView] = useState(false);
  const [shopCurrentId, setShopCurrentId] = useState(0);
  const [tutorialCurrentId, setTutorialCurrentId] = useState(0);

  const resourceRef = useRef();

  const [
    resourceDownloadingModalVisibility,
    setResourceDownloadingModalVisibility,
  ] = useState(false);

  const onResourceDownload = async (rId) => {
    try {
      const responseURL = await downloadResource(Number(rId));

      setResourceName(responseURL.file_name);

      let blob = await fetch(responseURL.url).then((r) => r.blob());
      let reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onload = function () {
        setResourceFile(reader.result);
        resourceRef.current.click();
      };
      reader.onerror = function (error) {
        console.log(error);
      };

      setResourceDownloadingModalVisibility(false);

    } catch (error) {
      setResourceDownloadingModalVisibility(false);
      console.log(error);
    }
  };

  const styleGrabber = (file) => {
    const ext = file.split(".")[1];
    switch (ext) {
      case "pdf":
        return "pdfFile";
      case "jpg":
      case "jpeg":
      case "png":
        return "imageFile";
      case "zip":
        return "archiveFile";
      case "webm":
      case "wmv":
      case "ogg":
      case "mp4":
      case "avi":
      case "mov":
        return "videoFile";

      case "mp3":
      case "wave":
        return "audioFile";
      case "xlsx":
        return "excelFile";
      case "pptx":
        return "powerPointfile";
      case "docx":
        return "wordFile";
      default:
        return "randomFile";
    }
  };

  const fileTypeGrabber = (file) => {
    const ext = file.split(".")[1];
    switch (ext) {
      case "pdf":
        return "PDF document";
      case "jpg":
      case "jpeg":
      case "png":
        return "Image file";
      case "zip":
        return "ZIP file";
      case "webm":
      case "wmv":
      case "ogg":
      case "mp4":
      case "avi":
      case "mov":
        return "Video file";

      case "mp3":
      case "wave":
        return "Audio file";
      case "xlsx":
        return "Excel file";
      case "pptx":
        return "Powerpoint file";
      case "docx":
        return "Word document";
      default:
        return "File";
    }
  };

  const iconGrabber = (file) => {
    const ext = file.split(".")[1];
    switch (ext) {
      case "pdf":
        return <FaFilePdf />;
      case "jpg":
      case "jpeg":
      case "png":
        return <FaImage />;
      case "zip":
        return <FaFileArchive />;
      case "webm":
      case "wmv":
      case "ogg":
      case "mp4":
      case "avi":
      case "mov":
        return <FaPlay />;
      case "mp3":
      case "wave":
        return <FaVolumeUp />;
      case "xlsx":
        return <FaFileExcel />;
      case "pptx":
        return <FaFilePowerpoint />;
      case "docx":
        return <FaFileWord />;
      default:
        return <FaFile />;
    }
  };

  const {
    data: allResourcesTutorial,
    isValidating: isValidatingResourcesTutorial,
  } = useSWR(["/allResourcesTutorial"], () => getAllResourcesTutorial(), {
    onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
      // Only retry up to 3 times.
      if (retryCount && retryCount >= 3) {
        if (!allResourcesTutorial) setFetchLimitError(true);

        return;
      }
      // Retry after 3 seconds.
      setTimeout(() => revalidate({ retryCount: retryCount + 1 }), 3000);
    },
  });

  const { data: allResourcesshop, isValidating: isValidatingResourcesShop } =
    useSWR(["/allResourcesShop"], () => getAllResourcesShop(), {
      onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
        // Only retry up to 3 times.
        if (retryCount && retryCount >= 3) {
          if (!allResourcesshop) setFetchLimitError(true);

          return;
        }
        // Retry after 3 seconds.
        setTimeout(() => revalidate({ retryCount: retryCount + 1 }), 3000);
      },
    });

  const { data: allResources, isValidating: isValidatingResources } = useSWR(
    ["/allResources"],
    () => getAllResources(),
    {
      onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
        // Only retry up to 3 times.
        if (retryCount && retryCount >= 3) {
          if (!allResources) setFetchLimitError(true);

          return;
        }
        // Retry after 3 seconds.
        setTimeout(() => revalidate({ retryCount: retryCount + 1 }), 3000);
      },
    }
  );

  const getSublevels = (filter) => {
    if (filter !== -1) {
      let sublevels = [];
      let id = 0;
      if (nqaData) {
        console.log(nqaData);
        nqaData[filter - 1].levels.forEach((level) => {
          id = id + 1;
          sublevels.push({ label: level.name, id: id });
          // level.subLevels.forEach((sub) => {
          //   id = id + 1;
          //   sublevels.push({ label: sub.label, id: id });
          // });
        });
      }
      return sublevels;
    } else {
      return [];
    }
  };

  const { data: nqaData, isValidating } = useSWR(
    ["/nqaData"],
    () => fetchNQAData(),
    {
      onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
        // Only retry up to 3 times.
        if (retryCount && retryCount >= 3) {
          if (!nqaData) setFetchLimitError(true);

          return;
        }
        // Retry after 3 seconds.
        setTimeout(() => revalidate({ retryCount: retryCount + 1 }), 3000);
      },
    }
  );

  return (
    <div className={styles.mainContainer}>
      {resourceDownloadingModalVisibility && (
        <ResourceDownloadingModal
          visibility={resourceDownloadingModalVisibility}
          resourceId={resourceId}
          onOpenModal={() => setResourceDownloadingModalVisibility(true)}
          onCloseModal={() => setResourceDownloadingModalVisibility(false)}
        />
      )}

      <div className={styles.backButtonContainer}>
        <button
          className={styles.backButtonText}
          onClick={() => history.goBack()}
        >
          <FaRegArrowAltCircleLeft
            className={styles.backButton}
            color="#C4C4C4"
            size={24}
          />
        </button>
      </div>

      <div className={styles.resourcesContainer}>
        <div className={styles.defaultRowHolder}>
          <div className={styles.newQIPUpdateText}>
            <text className={styles.newQipVid}>
              A new QIP video webinar is now available to watch!
            </text>

            <text className={styles.briefDesc}>
              Brief description ipsum dolor sit amet, consectetur adipiscing
              elit, sed do eiusmod tempor incididunt.
            </text>
          </div>

          <button
            className={styles.viewUpdateButton}
            onClick={() => history.push(`${match.path}/updated-webinars/1`)}
          >
            Watch Now
          </button>
        </div>

        <div className={styles.userDashboardGroupText}>
          <h1 className={styles.userDashboardText}>Resource Centre</h1>

          <text className={styles.userDashboardAltText}>
            Find resources to help you with your QIP/SAT and to navigate the
            Holistic QIP App.
          </text>
        </div>

        <div className={styles.chooseNQA}>
          <span className={styles.havingProblemText}>
            Having a problem with NQA&apos;s? Click here and choose.
          </span>

          <div>
            {/* Show hide this buttons if Clicked */}
            {!nqaVisibility ? (
              <IconButton
                className={styles.ArrowButton}
                icon="arrow down"
                circular
                onClick={() => setNQAVisibility((prev) => !prev)}
              />
            ) : (
              <IconButton
                className={styles.ArrowButton}
                icon="arrow up"
                circular
                onClick={() => setNQAVisibility(false)}
              />
            )}
          </div>
        </div>

        {/* Show Hide this section if Button is Clicked */}
        {nqaVisibility && (
          <div className={styles.nqaSelectionGroup}>
            <div className={styles.nqaStagesRow}>
              {[...Array(7).keys()].map((stage) => (
                <div key={stage} className={styles.nqaStageBubble}>
                  NQA{stage + 1}
                </div>
              ))}
            </div>
            {[...Array(3).keys()].map((row) => (
              <div key={row} className={styles.nqaLevelsRow}>
                {[...Array(7).keys()].map((level) => (
                  <div key={level} className={styles.nqaLevelBubble}>
                    {level + 1}.{row + 1}
                  </div>
                ))}
              </div>
            ))}
          </div>
        )}
        {/* End Of View When Clicked */}

        <div className={styles.navigationOptionsRow}>
          <div
            className={
              selectedOption === 1
                ? styles.navigationOptionSelected
                : styles.navigationOption
            }
            onClick={() => {
              setResourceShopView(false);
              setResourceTutorialView(false);
              setOption(1);
            }}
          >
            QIP/SAT resources
          </div>

          <div
            className={`${
              selectedOption === 2
                ? styles.navigationOptionSelected
                : styles.navigationOption
            } ${styles.middleNavigationOptionBorder}`}
            onClick={() => {
              setResourceShopView(false);
              setResourceTutorialView(false);
              setOption(2);
            }}
          >
            Shop resources
          </div>

          <div
            className={
              selectedOption === 3
                ? styles.navigationOptionSelected
                : styles.navigationOption
            }
            onClick={() => {
              setResourceShopView(false);
              setResourceTutorialView(false);
              setOption(3);
            }}
          >
            App tutorials
          </div>
        </div>

        <div className={styles.contentHolder}>
          {(() => {
            if (selectedOption === 1) {
              return (
                <div className={styles.resourcesContent}>
                  <div className={styles.tabContent}>
                    <text>
                      Our extensive resource library will help guide you. Find a
                      collection of downloadable QIP/SAT resources to support
                      you across all 7 NQA areas, or search specific documents
                      by name.
                    </text>
                  </div>

                  <Input
                    className={styles.searchBox}
                    icon="search"
                    placeholder="Type your search"
                    onChange={(e) => {
                      if (currentPage !== 1) {
                        setCurrentPage(1);
                      }

                      setKeySearch(e.target.value);
                    }}
                  />

                  <div
                    className={`${styles.resourcesFilterRow} flex flex-wrap pb-2.5 mt-14`}
                  >
                    <div
                      className={`${
                        subCurrentFilter === "all"
                          ? styles.resourcesFilterSelected
                          : styles.resourcesFilter
                      }  flex items-center justify-center`}
                      onClick={() => {
                        setCurrentPage(1);
                        setCurrentFilter(-1);
                        setSubCurrentFilter("all");
                        // setCurrentFilter(1); /*setCurrentFilterClass("nqa_1")*/
                      }}
                    >
                      All
                    </div>

                    <div
                      className={`${
                        currentFilter === 1
                          ? styles.resourcesFilterSelected
                          : styles.resourcesFilter
                      }  flex items-center justify-center`}
                      onClick={() => {
                        setCurrentPage(1);
                        setSubCurrentFilter("1.1");
                        setCurrentFilter(1); /*setCurrentFilterClass("nqa_1")*/
                      }}
                    >
                      NQA 1
                    </div>
                    <div
                      className={`${
                        currentFilter === 2
                          ? styles.resourcesFilterSelected
                          : styles.resourcesFilter
                      }  flex items-center justify-center`}
                      onClick={() => {
                        setCurrentPage(1);
                        setSubCurrentFilter("2.1");
                        setCurrentFilter(2); /*setCurrentFilterClass("nqa_2")*/
                      }}
                    >
                      NQA 2
                    </div>
                    <div
                      className={`${
                        currentFilter === 3
                          ? styles.resourcesFilterSelected
                          : styles.resourcesFilter
                      }  flex items-center justify-center`}
                      onClick={() => {
                        setCurrentPage(1);
                        setSubCurrentFilter("3.1");
                        setCurrentFilter(3); /*setCurrentFilterClass("nqa_3")*/
                      }}
                    >
                      NQA 3
                    </div>
                    <div
                      className={`${
                        currentFilter === 4
                          ? styles.resourcesFilterSelected
                          : styles.resourcesFilter
                      }  flex items-center justify-center`}
                      onClick={() => {
                        setCurrentPage(1);
                        setSubCurrentFilter("4.1");
                        setCurrentFilter(4); /*setCurrentFilterClass("nqa_4")*/
                      }}
                    >
                      NQA 4
                    </div>
                    <div
                      className={`${
                        currentFilter === 5
                          ? styles.resourcesFilterSelected
                          : styles.resourcesFilter
                      }  flex items-center justify-center`}
                      onClick={() => {
                        setCurrentPage(1);
                        setSubCurrentFilter("5.1");
                        setCurrentFilter(5); /*setCurrentFilterClass("nqa_5")*/
                      }}
                    >
                      NQA 5
                    </div>
                    <div
                      className={`${
                        currentFilter === 6
                          ? styles.resourcesFilterSelected
                          : styles.resourcesFilter
                      }  flex items-center justify-center`}
                      onClick={() => {
                        setCurrentPage(1);
                        setSubCurrentFilter("6.1");
                        setCurrentFilter(6); /*setCurrentFilterClass("nqa_6")*/
                      }}
                    >
                      NQA 6
                    </div>
                    <div
                      className={`${
                        currentFilter === 7
                          ? styles.resourcesFilterSelected
                          : styles.resourcesFilter
                      }  flex items-center justify-center`}
                      onClick={() => {
                        setCurrentPage(1);
                        setSubCurrentFilter("7.1");
                        setCurrentFilter(7); /*setCurrentFilterClass("nqa_7")*/
                      }}
                    >
                      NQA 7
                    </div>
                  </div>

                  <div
                    className={`${styles.subResourcesFilterRow} ${
                      styles[`nqa_${currentFilter}`]
                    } flex flex-wrap`}
                  >
                    {!isEmpty(getSublevels(currentFilter)) &&
                      getSublevels(currentFilter).map((sub, key) => {
                        return (
                          <div
                            key={sub.label}
                            className={`${
                              subCurrentFilter === sub.label
                                ? styles.subResourcesFilterSelected
                                : styles.subResourcesFilter
                            } flex items-center justify-center`}
                            onClick={() => {
                              setCurrentPage(1);
                              setSubCurrentFilter(sub.label);
                            }}
                          >
                            NQA{sub.label}
                          </div>
                        );
                      })}
                  </div>

                  <div className={`mt-14 mb-16`}>
                    {!isEmpty(allResources) &&
                      allResources
                        .filter((resource) => {
                          if (keySearch.trim().length !== 0) {
                            if (resource.label) {
                              return resource.label
                                .toLowerCase()
                                .includes(keySearch.trim().toLowerCase());
                            } else {
                              let r = "resource file";
                              return r.includes(keySearch.trim().toLowerCase());
                            }
                          }

                          if (keySearch.trim().length === 0) {
                            if (subCurrentFilter === "all") {
                              return true;
                            } else {
                              return (
                                String(resource.child_nqa_id).trim() ===
                                String(subCurrentFilter).trim()
                              );
                            }
                          }
                        })
                        .sort((a, b) => {
                          if (a.label < b.label) {
                            return -1;
                          }
                          if (a.label > b.label) {
                            return 1;
                          }
                          return 0;
                        })
                        .slice(
                          currentPage * resourcesPerPage - resourcesPerPage,
                          currentPage * resourcesPerPage
                        )
                        .map((resource) => {
                          return (
                            <div
                              className={`${styles.documentLists} flex items-center justify-between py-4`}
                            >
                              <div className={styles.fileContainer}>
                                <div
                                  className={`${styles.filelogo} ${
                                    styles[`${styleGrabber(resource.name)}`]
                                  } flex items-center justify-center`}
                                >
                                  {iconGrabber(resource.name)}
                                </div>
                                <div className={styles.fileContent}>
                                  <text className={styles.filename}>
                                    {resource.label
                                      ? resource.label
                                      : "Test File"}
                                  </text>
                                  <text className={styles.fileType}>
                                    {" "}
                                    {fileTypeGrabber(resource.name)} /{` `}
                                    NQA {resource.child_nqa_id}
                                  </text>
                                </div>
                              </div>
                              <div className={styles.btnGroup}>
                                <BlueButton
                                  buttonText="Download"
                                  onClick={() => {
                                    setResourceDownloadingModalVisibility(true);
                                    onResourceDownload(resource.id);
                                  }}
                                />
                              </div>
                            </div>
                          );
                        })}
                  </div>
                  <a
                    ref={resourceRef}
                    href={resourceFile}
                    className={`hidden`}
                    download={resourceName}
                  >
                    {" "}
                  </a>

                  <div className={styles.pageButtonGroup}>
                    {!isEmpty(allResources) &&
                      resourcesPerPage <
                        allResources.filter((resource) => {
                          if (keySearch.trim().length !== 0) {
                            if (resource.label) {
                              return resource.label
                                .toLowerCase()
                                .includes(keySearch.trim().toLowerCase());
                            } else {
                              let r = "resource file";
                              return r.includes(keySearch.trim().toLowerCase());
                            }
                          }

                          if (keySearch.trim().length === 0) {
                            if (subCurrentFilter === "all") {
                              return true;
                            } else {
                              return (
                                String(resource.child_nqa_id).trim() ===
                                String(subCurrentFilter).trim()
                              );
                            }
                          }
                        }).length && (
                        <Pagination
                          currentPage={currentPage}
                          lastPage={Math.ceil(
                            allResources.filter((resource) => {
                              if (keySearch.trim().length !== 0) {
                                if (resource.label) {
                                  return resource.label
                                    .toLowerCase()
                                    .includes(keySearch.trim().toLowerCase());
                                } else {
                                  let r = "resource file";
                                  return r.includes(
                                    keySearch.trim().toLowerCase()
                                  );
                                }
                              }

                              if (keySearch.trim().length === 0) {
                                if (subCurrentFilter === "all") {
                                  return true;
                                } else {
                                  return (
                                    String(resource.child_nqa_id).trim() ===
                                    String(subCurrentFilter).trim()
                                  );
                                }
                              }
                            }).length / resourcesPerPage
                          )}
                          onPageChange={setCurrentPage}
                        />
                      )}
                  </div>
                </div>
              );
            } else if (selectedOption === 2) {
              return !resourceShopView ? (
                <div className={styles.shopResourcesContent}>
                  <div className={styles.tabContent}>
                    <text>
                      We try to provide early childhood services with the most
                      wide-ranging resources in Australia. Here you&apos;ll find
                      links to purchase additional resources that will further
                      help you across your business.
                    </text>
                  </div>

                  <div className={`flex flex-wrap`}>
                    {!isEmpty(allResourcesshop) &&
                      allResourcesshop
                        .sort((a, b) => {
                          if (a.label < b.label) {
                            return -1;
                          }
                          if (a.label > b.label) {
                            return 1;
                          }
                          return 0;
                        })
                        .slice(
                          currentPageShop * resourcesPerPage - resourcesPerPage,
                          currentPageShop * resourcesPerPage
                        )
                        .map((resource, k) => {
                          return (
                            <div
                              key={k}
                              className={`${styles.shopResourcesContainer} w-6/12`}
                              onClick={() => {
                                setShopCurrentId(resource.id);
                                setResourceShopView(true);
                              }}
                              // onClick={() =>
                              //   history.push(`${match.path}/updated-webinars/1`)
                              // }
                            >
                              <img
                                src={
                                  process.env.REACT_APP_SHOP_PREVIEW_URL +
                                  resource.thumbnail
                                }
                              />
                            </div>
                          );
                        })}

                    {/* <div
                      className={`${styles.shopResourcesContainer} w-6/12`}
                      onClick={() =>
                        history.push(`${match.path}/updated-webinars/1`)
                      }
                    >
                      <img src={prod1} />
                    </div>

                    <div className={`${styles.shopResourcesContainer} w-6/12`}>
                      <img src={prod6} />
                    </div> */}
                  </div>

                  <div className={styles.pageButtonGroup}>
                    {!isEmpty(allResourcesshop) &&
                      resourcesPerPage < allResourcesshop.length && (
                        <Pagination
                          currentPage={currentPageShop}
                          lastPage={Math.ceil(
                            allResourcesshop.length / resourcesPerPage
                          )}
                          onPageChange={setCurrentPageShop}
                        />
                      )}
                  </div>
                </div>
              ) : !isEmpty(allResourcesshop) && shopCurrentId !== 0 ? (
                // <div className={styles.shopResourcesContent}>
                <ResourceShop
                  resourceShops={allResourcesshop}
                  currentId={shopCurrentId}
                  setViewAll={() => setResourceShopView(false)}
                />
              ) : (
                // </div>
                <> </>
              );
            } else {
              return !resourceTutorialView ? (
                <div className={styles.appTutorialContent}>
                  <div className={styles.tabContent}>
                    <text>
                      New to Holistic QIP? View a collection of short
                      easy-to-follow video tutorials that will help you to get
                      started with navigating around the key features of the
                      Holistic QIP App.
                    </text>
                  </div>

                  <div className={`flex flex-wrap`}>
                    {!isEmpty(allResourcesTutorial) &&
                      allResourcesTutorial.map((resource, k) => {
                        return (
                          <div
                            key={k}
                            className={`${styles.webinarContainer}  `}
                            style={{
                              backgroundImage: `url(${
                                process.env.REACT_APP_TUTORIAL_PREVIEW_URL +
                                resource.thumbnail
                              })`,
                            }}
                            onClick={() => {
                              setTutorialCurrentId(resource.id);
                              setResourceTutorialView(true);
                            }}
                            // onClick={() =>
                            //   history.push(`${match.path}/updated-webinars/1`)
                            // }
                          >
                            <div className={styles.webinarTextGroup}>
                              <text className={styles.webinarLabel}>
                                Webinars
                              </text>

                              <text className={styles.webinarHeaderTitle}>
                                {/* Video #{`${resource.id}`} –{" "} */}
                                {resource.tutorial_name}
                              </text>
                            </div>

                            <Button
                              className={styles.playButton}
                              size="massive"
                              icon="play"
                              circular
                            />
                          </div>
                        );
                      })}
                    {/* <div
                      className={styles.webinarContainer}
                      style={{ backgroundImage: `url(${webinar1})` }}
                      onClick={() =>
                        history.push(`${match.path}/updated-webinars/1`)
                      }
                    >
                      <div className={styles.webinarTextGroup}>
                        <text className={styles.webinarLabel}>Webinars</text>

                        <text className={styles.webinarHeaderTitle}>
                          Video #1 – Welcome to Holistic QIP
                        </text>
                      </div>

                      <Button
                        className={styles.playButton}
                        size="massive"
                        icon="play"
                        circular
                      />
                    </div>

                    <div
                      className={styles.webinarContainer}
                      style={{ backgroundImage: `url(${webinar2})` }}
                    >
                      <div className={styles.webinarTextGroup}>
                        <text className={styles.webinarLabel}>Webinars</text>

                        <text className={styles.webinarHeaderTitle}>
                          Video #2 – Work through your NQA elements
                        </text>
                      </div>

                      <Button
                        className={styles.playButton}
                        size="massive"
                        icon="play"
                        circular
                      />
                    </div>

                    <div
                      className={styles.webinarContainer}
                      style={{ backgroundImage: `url(${webinar3})` }}
                    >
                      <div className={styles.webinarTextGroup}>
                        <text className={styles.webinarLabel}>Webinars</text>

                        <text className={styles.webinarHeaderTitle}>
                          Video #3 – Adding your teammates
                        </text>
                      </div>

                      <Button
                        className={styles.playButton}
                        size="massive"
                        icon="play"
                        circular
                      />
                    </div>

                    <div
                      className={styles.webinarContainer}
                      style={{ backgroundImage: `url(${webinar4})` }}
                    >
                      <div className={styles.webinarTextGroup}>
                        <text className={styles.webinarLabel}>Webinars</text>

                        <text className={styles.webinarHeaderTitle}>
                          Video #4 – Create, assign & edit tasks
                        </text>
                      </div>

                      <Button
                        className={styles.playButton}
                        size="massive"
                        icon="play"
                        circular
                      />
                    </div>

                    <div
                      className={styles.webinarContainer}
                      style={{ backgroundImage: `url(${webinar1})` }}
                    >
                      <div className={styles.webinarTextGroup}>
                        <text className={styles.webinarLabel}>Webinars</text>

                        <text className={styles.webinarHeaderTitle}>
                          Video #5 – Creating different reports
                        </text>
                      </div>

                      <Button
                        className={styles.playButton}
                        size="massive"
                        icon="play"
                        circular
                      />
                    </div>

                    <div
                      className={styles.webinarContainer}
                      style={{ backgroundImage: `url(${webinar2})` }}
                    >
                      <div className={styles.webinarTextGroup}>
                        <text className={styles.webinarLabel}>Webinars</text>

                        <text className={styles.webinarHeaderTitle}>
                          Video #6 – Updating your service details
                        </text>
                      </div>

                      <Button
                        className={styles.playButton}
                        size="massive"
                        icon="play"
                        circular
                      />
                    </div> */}
                  </div>
                  <div className={styles.pageButtonGroup}>
                    {!isEmpty(allResourcesTutorial) &&
                      resourcesPerPage < allResourcesTutorial.length && (
                        <Pagination
                          currentPage={currentPageTutorial}
                          lastPage={Math.ceil(
                            allResourcesTutorial.length / resourcesPerPage
                          )}
                          onPageChange={setCurrentPageTutorial}
                        />
                      )}
                  </div>
                </div>
              ) : !isEmpty(allResourcesTutorial) && tutorialCurrentId !== 0 ? (
                // <div className={styles.shopResourcesContent}>
                <ResourceTutorial
                  resourceTutorials={allResourcesTutorial}
                  currentId={tutorialCurrentId}
                  setViewAll={() => setResourceTutorialView(false)}
                />
              ) : (
                // </div>
                <> </>
              );
            }
          })()}
        </div>
      </div>
    </div>
  );
}
