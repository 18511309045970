export const subnqas = [
  {
    nqa: 1,
    sub: [
      {
        id: `1.1.1`,
        label: "NQA1.1.1",
      },
      {
        id: `1.1.2`,
        label: "NQA1.1.2",
      },
      {
        id: `1.1.3`,
        label: "NQA1.1.3",
      },
      {
        id: `1.2.1`,
        label: "NQA1.2.1",
      },
      {
        id: `1.2.2`,
        label: "NQA1.2.2",
      },
      {
        id: `1.2.3`,
        label: "NQA1.2.3",
      },
      {
        id: `1.3.1`,
        label: "NQA1.3.1",
      },
      {
        id: `1.3.2`,
        label: "NQA1.3.2",
      },
      {
        id: `1.3.3`,
        label: "NQA1.3.3",
      },
    ],
  },
  {
    nqa: 2,
    sub: [
      {
        id: `2.1.1`,
        label: "NQA2.1.1",
      },
      {
        id: `2.1.2`,
        label: "NQA2.1.2",
      },
      {
        id: `2.1.3`,
        label: "NQA2.1.3",
      },
      {
        id: `2.2.1`,
        label: "NQA2.2.1",
      },
      {
        id: `2.2.2`,
        label: "NQA2.2.2",
      },
      {
        id: `2.2.3`,
        label: "NQA2.2.3",
      },
    ],
  },
  {
    nqa: 3,
    sub: [
      {
        id: `3.1.1`,
        label: "NQA3.1.1",
      },
      {
        id: `3.1.2`,
        label: "NQA3.1.2",
      },
      {
        id: `3.2.1`,
        label: "NQA3.2.1",
      },
      {
        id: `3.2.2`,
        label: "NQA3.2.2",
      },
      {
        id: `3.2.3`,
        label: "NQA3.2.3",
      },
    ],
  },
  {
    nqa: 4,
    sub: [
      {
        id: `4.1.1`,
        label: "NQA4.1.1",
      },
      {
        id: `4.1.2`,
        label: "NQA4.1.2",
      },
      {
        id: `4.2.1`,
        label: "NQA4.2.1",
      },
      {
        id: `4.2.2`,
        label: "NQA4.2.2",
      },
    ],
  },
  {
    nqa: 5,
    sub: [
      {
        id: `5.1.1`,
        label: "NQA5.1.1",
      },
      {
        id: `5.1.2`,
        label: "NQA5.1.2",
      },
      {
        id: `5.2.1`,
        label: "NQA5.2.1",
      },
      {
        id: `5.2.2`,
        label: "NQA5.2.2",
      },
    ],
  },
  {
    nqa: 6,
    sub: [
      {
        id: `6.1.1`,
        label: "NQA6.1.1",
      },
      {
        id: `6.1.2`,
        label: "NQA6.1.2",
      },
      {
        id: `6.1.3`,
        label: "NQA6.1.3",
      },
      {
        id: `6.2.1`,
        label: "NQA6.2.1",
      },
      {
        id: `6.2.2`,
        label: "NQA6.2.2",
      },
      {
        id: `6.2.3`,
        label: "NQA6.2.3",
      },
    ],
  },
  {
    nqa: 7,
    sub: [
      {
        id: `7.1.1`,
        label: "NQA7.1.1",
      },
      {
        id: `7.1.2`,
        label: "NQA7.1.2",
      },
      {
        id: `7.1.3`,
        label: "NQA7.1.3",
      },
      {
        id: `7.2.1`,
        label: "NQA7.2.1",
      },
      {
        id: `7.2.2`,
        label: "NQA7.2.2",
      },
      {
        id: `7.2.3`,
        label: "NQA7.2.3",
      },
    ],
  },
];
