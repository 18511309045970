import * as React from "react";

import { useHistory } from "react-router-dom";
import { Dropdown } from "semantic-ui-react";
import { FaRegArrowAltCircleLeft, FaSpinner } from "react-icons/fa";

import styles from "./FreeTrialForm.module.css";
import { useStoreActions } from "../../hooks";
import BlueButton from "../../components/BlueButton";
import InputComponent from "../../components/InputComponent";
import TrialAccountModal from "../../components/Modals/TrialAccountModal";
import UserExistingModal from "../../components/Modals/UserExistingModal";
import { registerUser } from "../../api/requests";

const { useCallback, useReducer, useState } = React;

const serviceTypeOption = [
  {
    key: "1",
    value: 1,
    text: "Long Daycare",
  },
  {
    key: "2",
    value: 2,
    text: "Family Daycare",
  },
  {
    key: "3",
    value: 3,
    text: "Pre-school",
  },
  {
    key: "4",
    value: 4,
    text: "OOSH",
  },
];

const signUpReducer = (state, action) => {
  switch (action.type) {
    case "SIGN_UP": {
      const updatedInput = {
        ...state.input,
        [action.field]: action.value,
      };

      const updatedValidation = {
        ...state.validation,
        [action.field]: action.field === "phoneNumber" ? true : action.valid,
      };

      const formValidity = Object.values(updatedValidation).every(Boolean);

      return {
        input: updatedInput,
        validation: updatedValidation,
        formValidation: formValidity,
      };
    }
    default:
      return state;
  }
};

export default function FreeTrialForm() {
  const history = useHistory();
  const signup = useStoreActions((actions) => actions.authStore.signUp);

  const [signUpLoading, setLoading] = useState(false);

  const [fieldChecked, setFieldChecked] = useState(false);

  const [trialAccountModalVisibility, setTrialAccountModalVisibility] =
    useState(false);

  const [userExistingModalVis, setUserExistingModalVis] = useState(false);

  const [invalidEmail, setInvalidEmail] = useState(false);

  const initialState = {
    input: {
      firstName: "",
      lastName: "",
      email: "",
      confirmEmail: "",
      password: "",
      phoneNumber: "",
      serviceName: "",
      serviceApprovalNumber: "",
      serviceCategory: "",
      preferredDate: "",
      preferredTime: "",
      price: null,
    },
    validation: {
      firstName: false,
      lastName: false,
      email: false,
      confirmEmail: false,
      password: false,
      phoneNumber: false,
      serviceName: false,
      serviceApprovalNumber: false,
      serviceCategory: false,
      preferredDate: false,
      preferredTime: false,
    },
    formValidation: false,
  };

  const [signUpState, loginDispatch] = useReducer(signUpReducer, initialState);

  const changeInputDispatch = useCallback((field, value) => {
    let valid = false;

    if (value) valid = true;
    const action = {
      type: "SIGN_UP",
      field,
      value,
      valid,
    };
    loginDispatch(action);
  }, []);

  const validateEmailFormat = (val) => {
    const reg = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    return reg.test(val);
  };

  const onStartTrial = async () => {
    setFieldChecked(true);
    const {
      firstName,
      lastName,
      email,
      confirmEmail,
      phoneNumber,
      password,
      serviceName,
      serviceApprovalNumber,
      serviceCategory,
    } = signUpState.input;

    if (!(validateEmailFormat(email) && validateEmailFormat(confirmEmail))) {
      return;
    }

    if (email && confirmEmail && email !== confirmEmail) {
      return;
    }

    if (
      firstName &&
      email &&
      confirmEmail &&
      phoneNumber &&
      password &&
      serviceName &&
      serviceCategory
    ) {
      setLoading(true);

      try {
        const signupPayload = {
          ...signUpState.input,
        };

        const singUpresponse = await registerUser(signupPayload);

        if (singUpresponse) {
          setLoading(false);
          setTrialAccountModalVisibility(true);
        }
      } catch (error) {
        setLoading(false);
        setUserExistingModalVis(true);
      }
    }
    // else {
    //   alert("Please fill all required fields.");
    // }
  };

  return (
    <div className={styles.mainContainer}>
      {trialAccountModalVisibility && (
        <TrialAccountModal
          visibility={trialAccountModalVisibility}
          onOpenModal={() => setTrialAccountModalVisibility(true)}
          onCloseModal={() => setTrialAccountModalVisibility(false)}
        />
      )}

      {userExistingModalVis && (
        <UserExistingModal
          visibility={userExistingModalVis}
          onOpenModal={() => setUserExistingModalVis(true)}
          onCloseModal={() => setUserExistingModalVis(false)}
        />
      )}

      <div className={styles.backButtonContainer}>
        <button
          className={styles.backButtonText}
          onClick={() => history.goBack()}
        >
          <FaRegArrowAltCircleLeft
            className={styles.backButton}
            color="#C4C4C4"
            size={24}
          />
        </button>
      </div>

      {/* {signUpLoading ? (
        <div
          className={`w-full h-screen flex flex-col justify-center items-center`}
        >
          <img src={spinner} className={`block`} />

          <span className={`block`}> Registering user...</span>
        </div>
      ) : ( */}
      <div className={styles.mainCard}>
        <div className={styles.cardContent}>
          <h1 className={styles.headerText}>Let&apos;s get started</h1>

          <text className={styles.descriptionText}>
            Start your 30-day free trial of Holistic QIP and start making your
            QIP preparation and submission process easier. First, we need some
            information about your service to get you setup.
          </text>

          <div className={styles.inputGroup}>
            <div className={styles.inputColumn}>
              <InputComponent
                fieldName="firstName"
                label="First name"
                type="text"
                required
                showNotFilled={fieldChecked}
                inputValue={signUpState.input.firstName}
                setInputValue={changeInputDispatch}
              />

              <InputComponent
                fieldName="lastName"
                label="Last name"
                type="text"
                inputValue={signUpState.input.lastName}
                setInputValue={changeInputDispatch}
              />
            </div>

            <div className={styles.inputColumn}>
              <InputComponent
                fieldName="email"
                label="Email address"
                type="email"
                fieldType={`email`}
                required
                setInEmail={setInvalidEmail}
                showNotFilled={fieldChecked}
                inputValue={signUpState.input.email}
                setInputValue={changeInputDispatch}
              />

              <InputComponent
                fieldName="confirmEmail"
                label="Confirm email address"
                type="email"
                fieldType={`email`}
                required
                setInEmail={setInvalidEmail}
                showNotFilled={fieldChecked}
                inputValue={signUpState.input.confirmEmail}
                setInputValue={changeInputDispatch}
              />
            </div>
            {fieldChecked &&
              signUpState.input.email &&
              signUpState.input.confirmEmail &&
              signUpState.input.email !== signUpState.input.confirmEmail &&
              !invalidEmail && (
                <div className={`text-red-400 p-2 font-thin italic`}>
                  The email address and the confirm email address do not match.
                </div>
              )}
            <div className={styles.inputColumn}>
              <InputComponent
                fieldName="phoneNumber"
                label="Phone number"
                type="text"
                fieldType={`phone`}
                required
                autoComplete={`off`}
                showNotFilled={fieldChecked}
                inputValue={signUpState.input.phoneNumber}
                setInputValue={changeInputDispatch}
              />

              <InputComponent
                fieldName="password"
                label="Create a password"
                type="password"
                required
                autoComplete={`off`}
                showNotFilled={fieldChecked}
                inputValue={signUpState.input.password}
                setInputValue={changeInputDispatch}
              />
            </div>
            <div className={styles.inputColumn}>
              <InputComponent
                fieldName="serviceName"
                label="Service name"
                type="text"
                required
                showNotFilled={fieldChecked}
                inputValue={signUpState.input.serviceName}
                setInputValue={changeInputDispatch}
              />

              <InputComponent
                className={styles.serviceNumberInput}
                fieldName="serviceApprovalNumber"
                label="Service approval number"
                type="text"
                // showNotFilled={
                //   fieldChecked && !signUpState.validation.serviceApprovalNumber
                // }
                inputValue={signUpState.input.serviceApprovalNumber}
                setInputValue={changeInputDispatch}
              />
            </div>
            <div className={styles.inputColumn}>
              <div className={styles.dropdownGroup}>
                <text className={styles.dropdownText}>
                  Service type<span className={styles.required}>*</span>
                </text>

                <Dropdown
                  className={styles.dropdownBox}
                  fluid
                  selection
                  placeholder="Choose"
                  options={serviceTypeOption}
                  onChange={(e, data) =>
                    changeInputDispatch("serviceCategory", data.value)
                  }
                />
                {fieldChecked && !signUpState.validation.serviceCategory && (
                  <div className={`text-red-400 p-2 font-thin italic`}>
                    This field is required.
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className={`mt-14`}>
            <text className={styles.walkThroughHeader}>
              Free personal 15 min walkthrough
            </text>
            <span className={styles.walkThroughSub}>
              {`Let us know a preferred time (Before / During or After hours) for
              our consultant to show you everything Holistic QIP has to offer,
              to ensure you get the most out of your software.`}
            </span>
            <div className={styles.inputColumn}>
              <InputComponent
                // className={styles.serviceNumberInput}
                fieldName="preferredDate"
                label="Preferred date"
                type="text"
                inputValue={signUpState.input.preferredDate}
                setInputValue={changeInputDispatch}
              />

              <InputComponent
                // className={styles.serviceNumberInput}
                fieldName="preferredTime"
                label="Preferred time of day"
                type="text"
                inputValue={signUpState.input.preferredTime}
                setInputValue={changeInputDispatch}
              />
            </div>
          </div>
          <BlueButton
            disabled={signUpLoading}
            className={styles.blueButton}
            buttonText={
              signUpLoading ? (
                <div className={`flex justify-center items-center`}>
                  <FaSpinner className={`animate-spin`} />{" "}
                  <span className={`ml-2`}>Please wait...</span>
                </div>
              ) : (
                <> Start Trial</>
              )
            }
            onClick={onStartTrial}
          />
        </div>
      </div>
      {/* )} */}
    </div>
  );
}
