import * as React from "react";

import useSWR, { mutate } from "swr";
import { useHistory } from "react-router-dom";
// import { Dropdown, Input } from 'semantic-ui-react';
import { FaRegArrowAltCircleLeft, FaSpinner } from "react-icons/fa";

import styles from "./UsersList.module.css";

import { useStoreState } from "../../../hooks";
import UserCard from "../../../components/UserCard";
import {
  fetchCenterUsers,
  fetchAllUsers,
  fetchAllUsersCenters,
} from "../../../api/requests";
import { isEmpty } from "lodash";

const { useState, useEffect, useCallback } = React;

// const filterOptions = [
//   {
//     key: 'A-Z',
//     text: 'A-Z',
//     value: 'A-Z',
//   },
//   {
//     key: 'Z-A',
//     text: 'Z-A',
//     value: 'Z-A',
//   },
//   {
//     key: '0-9',
//     text: '0-9',
//     value: '0-9',
//   },
//   {
//     key: '9-0',
//     text: '9-0',
//     value: '9-0',
//   },
// ];

// const dummyServices = [
//   {
//     key: 'a1',
//     text: 'Amigoss',
//     value: 'testValue',
//     content: 'Amigoss'
//   },
// ];

function UsersList() {
  const history = useHistory();

  const services = useStoreState((state) => state.authStore.services);

  const centerId = useStoreState((state) => state.nqaStore.serviceDetails.id);

  const [users, setUsers] = useState([]);

  const [centerIds, setCenterIds] = useState(
    services ? services.map((service) => service.id) : []
  );

  const [deleteLoading, setLoading] = useState(false);

  const onDeleteUser = async () => {
    getAllUsers();
    // await mutate([`/centers/users`, centerIds]);
  };

  // const { data: users } = useSWR(
  //   [`/centers/users`, centerIds],
  //   (_, centerIds) => {
  //     fetchAllUsersCenters(centerIds);
  //   }
  // );

  const getAllUsers = useCallback(async () => {
    try {
      const response = await fetchAllUsersCenters(centerIds);
      if (!isEmpty(response)) {
        setUsers(response);
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    getAllUsers();
  }, [centerIds]);

  return (
    <div className={styles.mainContainer}>
      <div className={styles.backButtonContainer}>
        <button
          className={styles.backButtonText}
          onClick={() => history.goBack()}
        >
          <FaRegArrowAltCircleLeft
            className={styles.backButton}
            color="#C4C4C4"
            size={24}
          />
        </button>
      </div>

      {deleteLoading ? (
        <div
          className={`w-full h-screen flex flex-col justify-center items-center`}
        >
          <FaSpinner
            className={`animate-spin text-qipGreen font-bold text-6xl`}
          />

          <span className={`block`}>Loading...</span>
        </div>
      ) : (
        <div className={styles.content}>
          <div className={styles.headerContainer}>
            <div className={styles.headerTextGroup}>
              <h1 className={styles.headerText}>All Users</h1>

              <text className={styles.headerSubText}>
                Add, remove and edit users and user details here.
              </text>
            </div>

            <button
              className={styles.backToDashboardButton}
              onClick={() => history.push("/qip/user-management/")}
            >
              Back to dashboard
            </button>
          </div>

          <div className={styles.cardContainer}>
            <div className={styles.searchFilterRow}>
              {/*<div style={{visibility:'hidden'}}>

                  <Input
                    className={styles.searchInput}
                    icon="search"
                    placeholder="Type name"
                  />

                  <Dropdown
                    className={styles.filterDropdown}
                    inline
                    header="Filters"
                    options={filterOptions}
                    defaultValue={filterOptions[0].value}
                  />

                  <Dropdown
                    className={styles.filterDropdown}
                    inline
                    header="Services"
                    placeholder="By Service"
                    options={dummyServices}
                  />

                </div>*/}

              <button
                className={styles.newUserButton}
                onClick={() => history.push("/qip/user-management/create-user")}
              >
                New User
              </button>
            </div>
            {isEmpty(users) ? (
              <div
                className={`w-full h-full flex flex-col justify-center items-center py-10 `}
              >
                <FaSpinner
                  className={`animate-spin text-qipGreen font-bold text-6xl`}
                />
                <span className={`block`}> Loading data...</span>
              </div>
            ) : (
              users.map((user) => (
                <UserCard
                  key={`${user.id}`}
                  userData={{
                    id: user.id,
                    username: user.username,
                    email: user.email,
                    name: user.name,
                    role: user.type,
                    streetAddress: user.streetAddress,
                    suburb: user.suburb,
                    state: user.state,
                    postalCode: user.postalCode,
                    telNum: user.telNum,
                    mobileNum: user.mobileNum,
                    additionalEmail: user.additionalEmail,
                  }}
                  finishLoading={onDeleteUser}
                />
              ))
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default UsersList;
