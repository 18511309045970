import authStore, { AuthModel } from "./authModel";
import taskStore, { TaskModel } from "./taskModel";
import userStore, { UserModel } from "./userModel";
import nqaStore, { NQAModel } from "./nqaModel";
import appStore, { AppModel } from "./appModel";

export interface StoreModel {
  authStore: AuthModel;
  userStore: UserModel;
  taskStore: TaskModel;
  nqaStore: NQAModel;
  appStore: AppModel;
}

const storeModel: StoreModel = {
  authStore,
  userStore,
  taskStore,
  nqaStore,
  appStore,
};

export default storeModel;
