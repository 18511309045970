import * as React from "react";

import PropTypes from "prop-types";
import { Button, Modal } from "semantic-ui-react";

import styles from "./DeleteTaskModal.module.css";
import { FaSpinner } from "react-icons/fa";
import SkipButton from "../../SkipButton";

const { useState } = React;

function DeleteTaskModal({
  visibility,
  onOpenModal,
  onCloseModal,
  onRemoveTask,
}) {
  const [deleteLoading, setLoading] = useState(false);

  const onDeleteTask = async () => {
    setLoading(true);
    await onRemoveTask();
    setLoading(false);
  };

  return (
    <Modal
      size="small"
      centered={true}
      open={visibility}
      style={{ marginTop: "30vh" }}
      onOpen={onOpenModal}
      onClose={onCloseModal}
      closeOnDimmerClick={false}
    >
      {deleteLoading ? (
        <div
          style={{ height: "20vh" }}
          className={`w-full flex flex-col justify-center items-center`}
        >
          <FaSpinner
            className={`animate-spin text-qipGreen font-bold text-6xl`}
          />

          <span className={`block`}>Deleting task...</span>
        </div>
      ) : (
        <div className={styles.modalContainer}>
          <Button
            className={styles.closeButton}
            onClick={onCloseModal}
            size="big"
            icon="close"
          />

          <text className={styles.headerText}>
            Are you sure you want to remove task?
          </text>

          <div className={styles.buttonGroup}>
            <button className={styles.nextTipButton} onClick={onDeleteTask}>
              Yes
            </button>

            <SkipButton onClick={onCloseModal} buttonText="No" />
          </div>
        </div>
      )}
    </Modal>
  );
}

DeleteTaskModal.propTypes = {
  visibility: PropTypes.bool.isRequired,
  onOpenModal: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  onRemoveTask: PropTypes.func.isRequired,
};

export default DeleteTaskModal;
