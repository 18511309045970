import React from "react";
import styles from "./NewService.module.css";
import { useStoreState, useStoreActions } from "../../hooks";
import { useHistory } from "react-router-dom";
import { FaSpinner, FaRegArrowAltCircleLeft } from "react-icons/fa";
import BlueButton from "../../components/BlueButton";
import InputComponent from "../../components/InputComponent";
import { Dropdown } from "semantic-ui-react";
import PaymentMadeModal from "../../components/Modals/PaymentMadeModal/PaymentMadeModal";
import { createServiceCenter, fetchUserCenters } from "../../api/requests";
import { isEmpty } from "lodash";
import { Products } from "../../components/Products/Products";

const { useCallback, useReducer, useState, useEffect } = React;

const serviceTypeOption = [
  {
    key: "1",
    value: "Long Daycare",
    text: "Long Daycare",
  },
  {
    key: "2",
    value: "Family Daycare",
    text: "Family Daycare",
  },
  {
    key: "3",
    value: "Pre-school",
    text: "Pre-school",
  },
  {
    key: "4",
    value: "OOSH",
    text: "OOSH",
  },
];

// test
const priceIds = {
  withNoFreeTrial: {
    portal: {
      yearly: "price_1HjdVfDeO3XG77wNA9yXwT1m",
      monthly: "price_1HjdVfDeO3XG77wNweTXFOcB",
    },
    mentoring: {
      yearly: "price_1HjdWJDeO3XG77wNw70ZhCMf",
      monthly: "price_1HjdWJDeO3XG77wN2ZWtoJzb",
    },
  },
  withFreeTrial: {
    portal: {
      yearly: "price_1ISv7XDeO3XG77wNZHPAFVGy",
      monthly: "price_1ISv7HDeO3XG77wNIKR5IAk3",
      // monthly: "price_1JIq4HDeO3XG77wNu7sfk1zK",
    },
    mentoring: {
      yearly: "price_1ISv8XDeO3XG77wNAPhSKSsG",
      monthly: "price_1ISv8MDeO3XG77wNfKE2mu9G",
    },
  },
};

const NewService = ({ plan, planVariant }) => {
  const history = useHistory();

  useStoreActions((state) => state.appStore.setHeaderMenu(false));
  const showBanner = useStoreActions((state) => {
    state.appStore.showBanner(false);
  });

  const [new_service_name, set_new_service_name] = useState("");
  const [new_service_approval_number, set_new_service_approval_number] =
    useState("");
  const [new_service_type, set_new_service_type] = useState("");

  const user = useStoreState((state) => state.userStore.loggedInUser);
  const userRegData = useStoreState((state) => state.authStore.signUpCreds);
  const userEmail = user ? user.email : userRegData ? userRegData.email : null;

  const userId = useStoreState((state) => state.authStore.authObject.userId);

  const userPaymentMethods = useStoreState(
    (state) => state.userStore.paymentMethods
  );

  const apiURI = process.env.REACT_APP_API_URL;

  const [fieldChecked, setFieldChecked] = useState(false);

  const [submitting, setSubmitting] = useState(false);

  const [showPaymentMade, setShowPaymentMade] = useState(false);
  const [newServiceDetail, setNewServiceDetail] = useState({});

  const nqaSetToNewService = useStoreActions(
    (actions) => actions.nqaStore.setServiceDetails
  );

  const authSetToNewService = useStoreActions(
    (actions) => actions.authStore.setSelectedService
  );

  const fetchServicesSubs = useStoreActions(
    (actions) => actions.authStore.fetchAdminAttr
  );

  const handleFormSubmit = async () => {
    setFieldChecked(true);

    if (
      !(new_service_name && new_service_approval_number && new_service_type)
    ) {
      return;
    }

    setSubmitting(true);

    const payload = {
      userId: userId,
      serviceName: new_service_name,
      serviceCatId: serviceTypeOption.find(
        (item) => item.value === new_service_type
      ).key,
      approvalNumber: new_service_approval_number,
    };

    await createService({ ...payload });

    setSubmitting(false);

    setShowPaymentMade(true);
  };
  const setStateVal = (key, value) => {
    switch (key) {
      case "new_service_name":
        set_new_service_name(value);
        break;
      case "new_service_approval_number":
        set_new_service_approval_number(value);
        break;

      case "new_service_type":
        set_new_service_type(value);
        break;
    }
  };

  const createService = async (payload = {}) => {
    try {
      const response = await createServiceCenter({ ...payload });

      if (response) {
        await processSubscription(response.id);
        await getTheNewService(response.id);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getTheNewService = async (centerId) => {
    try {
      const serviceDetails = await fetchUserCenters(userId);
      await fetchServicesSubs({ memberId: userId });
      if (!isEmpty(serviceDetails)) {
        const theNewServiceDetail = serviceDetails.filter(
          (center) => center.id === centerId
        )[0];

        localStorage.setItem("selectedService", theNewServiceDetail.id);
        setNewServiceDetail(theNewServiceDetail);

        nqaSetToNewService(theNewServiceDetail);
        authSetToNewService(theNewServiceDetail);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const processSubscription = async (centerId) => {
    // const price = priceIds["withFreeTrial"][plan][planVariant];

    const price = Products()["withFreeTrial"][plan][planVariant];

    const subsPayload = {
      center_id: centerId,
      email: userEmail,
      price,
      plan,
      payment_method_id: userPaymentMethods[0].id,
    };

    try {
      const rawResponse = await fetch(`${apiURI}/api/subscribe`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(subsPayload),
      });
    } catch (error) {
      console.log(error);
    }
  };

  const renderInfoText = () => {
    if (plan == "portal") {
      if (planVariant == "monthly") {
        return (
          <>
            To add a new service to your current subscription we first we need
            some information to get setup and to complete your{" "}
            <span className={`font-medium`}>$97/month{` `}</span>
            subscription to a{` `}
            <span className={`font-medium`}>
              Holistic QIP Portal monthly plan.
            </span>
          </>
        );
      } else {
        return (
          <>
            To add a new service to your current subscription we first we need
            some information to get setup and to complete your{" "}
            <span className={`font-medium`}>$997/year{` `}</span>
            subscription to a{` `}
            <span className={`font-medium`}>
              Holistic QIP Portal yearly plan
            </span>
            .
          </>
        );
      }
    }

    if (plan == "mentoring") {
      if (planVariant == "monthly") {
        return (
          <>
            To add a new service to your current subscription we first we need
            some information to get setup and to complete your{" "}
            <span className={`font-medium`}>$197/month{` `}</span>
            subscription to a{" "}
            <span className={`font-medium`}>
              Holistic QIP Portal + Mentoring monthly plan.
            </span>
          </>
        );
      } else {
        return (
          <>
            To add a new service to your current subscription we first we need
            some information to get setup and to complete your{" "}
            <span className={`font-medium`}>$1,997/year{` `}</span>
            subscription to a{` `}
            <span className={`font-medium`}>
              Holistic QIP Portal + Mentoring yearly plan.
            </span>
          </>
        );
      }
    }
  };

  return (
    <div
      className={`${styles.mainContainer} relative flex flex-col justify-center items-center`}
    >
      {showPaymentMade && (
        <PaymentMadeModal
          visibility={showPaymentMade}
          onCloseModal={() => setShowPaymentMade(false)}
        />
      )}
      <div
        className={`${styles.backButtonContainer} absolute top-0 right-0 -mt-14`}
      >
        <button
          className={styles.backButtonArrow}
          onClick={() => history.goBack()}
        >
          <FaRegArrowAltCircleLeft
            className={styles.backButton}
            color="#C4C4C4"
            size={24}
          />
        </button>
      </div>
      <div className={`${styles.container} top-0 mt-10`}>
        <div className={styles.mainContent}>
          <text className={styles.headerText}>
            Let&apos;s add your new service
          </text>

          <text className={styles.subText}>{renderInfoText()}</text>

          <div className={styles.inputGroup}>
            <div className={styles.inputColumn}>
              <InputComponent
                label="New Service name"
                type="text"
                required
                showNotFilled={fieldChecked && !new_service_name}
                fieldName="new_service_name"
                inputValue={new_service_name}
                setInputValue={setStateVal}
              />
              <InputComponent
                className={styles.serviceNumberInput}
                label="New Service approval number"
                type="text"
                required
                showNotFilled={fieldChecked && !new_service_approval_number}
                fieldName="new_service_approval_number"
                inputValue={new_service_approval_number}
                setInputValue={setStateVal}
              />
            </div>
          </div>
          <div className={styles.inputColumn}>
            <div className={styles.dropdownGroup}>
              <text className={styles.dropdownText}>
                New Service type <span className={styles.required}>*</span>
              </text>

              <Dropdown
                className={styles.dropdownBox}
                fluid
                selection
                placeholder="Choose"
                options={serviceTypeOption}
                onChange={(e, data) =>
                  setStateVal("new_service_type", data.value)
                }
              />
              {fieldChecked && !new_service_type && (
                <div className={`text-red-400 p-2 font-thin italic`}>
                  This field is required.{" "}
                </div>
              )}
            </div>
          </div>
          <BlueButton
            disabled={submitting}
            className={styles.blueButton}
            buttonText={
              submitting ? (
                <div className={`flex justify-center items-center`}>
                  <FaSpinner className={`animate-spin`} />
                  <span className={`block pl-2`}>Please wait...</span>
                </div>
              ) : (
                <> Add to your subscription</>
              )
            }
            onClick={handleFormSubmit}
          />
        </div>
      </div>
    </div>
  );
};

export default NewService;
