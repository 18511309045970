import React from "react";
import styles from "./ServicePhilosophy.module.css";
import { useStoreActions, useStoreState } from "../../../hooks";
import { FaSpinner } from "react-icons/fa";

const { useState, useEffect } = React;

const ServicePhilosophy = () => {
  const [saving, setSaving] = useState(false);

  const authObject = useStoreState((state) => state.authStore.authObject);
  const updateServiceDetails = useStoreActions(
    (actions) => actions.nqaStore.updateServiceDetails
  );

  const serviceDetails = useStoreState(
    (state) => state.nqaStore.serviceDetails
  );

  const [detailsState, setDetailsState] = useState(serviceDetails);

  const onUpdateServiceDetails = async () => {
    try {
      setSaving(true);
      const payload = {
        serviceDetails: {
          ...detailsState,
          userId: authObject.userId,
        },
        centerId: detailsState.id,
      };

      await updateServiceDetails(payload);
      setSaving(false);
    } catch (error) {
      setSaving(false);
      console.log(error);
    }
  };

  return (
    <div
      className={`${styles.mainContainer} w-full md:px-8 lg:px-24 mt-14 pb-14`}
    >
      <div className={`${styles.headerText}`}> Service Philosophy</div>
      <div className={`${styles.description} my-5`}>
        {`Your service’s statement of philosophy should be a living document that
        is the foundation for daily practice. It should describe the core
        beliefs and values you hold for children and families as they apply to
        your delivery of quality education and care. Your service philosophy
        should be the foundation for your approach to achieving quality outcomes
        for children and should be clear enough to guide your decision making
        and service practice.`}
      </div>
      <textarea
        value={detailsState.philosophy}
        onChange={(e) =>
          setDetailsState({ ...detailsState, philosophy: e.target.value })
        }
        className={`${styles.textArea} p-4 w-full`}
        placeholder={`Write here...`}
      ></textarea>
      <div className={`flex justify-center items-center mt-12 mb-8`}>
        <button
          onClick={onUpdateServiceDetails}
          disabled={saving}
          className={`${styles.saveButton}`}
        >
          {saving ? (
            <div className={`flex justify-center items-center`}>
              <FaSpinner className={`animate-spin`} />{" "}
              <span className={`ml-3`}>Please wait...</span>
            </div>
          ) : (
            ` Save service philosopy`
          )}
        </button>
      </div>
    </div>
  );
};

export default ServicePhilosophy;
