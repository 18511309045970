import * as React from "react";

import PropTypes from "prop-types";
import { Modal } from "semantic-ui-react";
import { FaSpinner } from "react-icons/fa";

import styles from "./ResourceDownloadingModal.module.css";

const { useEffect, useCallback } = React;

function ResourceDownloadingModal({ visibility, onOpenModal, onCloseModal }) {
  const downloadProgress = 60; // variable width of progress bar

  return (
    <Modal
      size="small"
      centered={false}
      open={visibility}
      onOpen={onOpenModal}
      onClose={onCloseModal}
      // closeOnDimmerClick={false} enable when download progress is implemented
    >
      <div className={styles.modalContainer}>
        <text className={styles.headerText}>
          Resources are being downloaded
        </text>

        {/* <div className={styles.progressBarContainer}>
          <div
            className={styles.progressBar}
            style={{ width: `${downloadProgress}%` }}
          />
        </div> */}

        <FaSpinner
          className={`animate-spin text-qipGreen font-bold text-6xl`}
        />
      </div>
    </Modal>
  );
}

ResourceDownloadingModal.propTypes = {
  visibility: PropTypes.bool.isRequired,
  onOpenModal: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired,
};

export default ResourceDownloadingModal;
