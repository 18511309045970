import * as React from "react";
import PropTypes from "prop-types";
import { Link, useHistory } from "react-router-dom";

import clockImg from "../../static/images/clock.png";

import { FaExclamationTriangle } from "react-icons/fa";

import styles from "./Banner.module.css";
const { useState } = React;

// http://localhost:3000/qip/user-management/billing/1

const PaymentFailed = () => {
  const history = useHistory();

  return (
    <div className={styles.alertContainer}>
      <div className={styles.alert}>
        <FaExclamationTriangle className={`${styles.warningIcon}`} />
        <text className={`ml-2`}>
          We were unable to process your payment. Please review or update your
          <span
            className={`cursor-pointer`}
            onClick={() => {
              history.push(`/qip/user-management/billing/1`);
            }}
          >
            {" "}
            payment details
          </span>{" "}
          to continue enjoying all your benefits.
        </text>
      </div>
    </div>
  );
};

export default function PaymentFailedBanner({ service }) {
  return <PaymentFailed service={service} />;
}
