import * as React from "react";

import PropTypes from "prop-types";
import { Button, Modal } from "semantic-ui-react";
import { useHistory } from "react-router-dom";

import BlueButton from "../../BlueButton";
import styles from "./DowngradeSubscriptionModal.module.css";

function DowngradeSubscriptionModal({ visibility, onOpenModal, onCloseModal }) {
  const history = useHistory();

  return (
    <Modal
      size="small"
      closeOnDimmerClick={false}
      centered
      open={visibility}
      onOpen={onOpenModal}
      onClose={onCloseModal}
    >
      <div className={styles.modalContainer}>
        <Button
          className={styles.closeButton}
          // onClick={onCloseModal}
          onClick={() => {
            history.push(`/qip/user-management/billing/2`);
          }}
          size="big"
          icon="close"
        />

        <text className={styles.headerText}>
          You have downgraded your access to just the QIP Portal you will no
          longer have access to mentoring and NQA feedback. Your updated
          subscription payment will show on your next payment.
        </text>

        <BlueButton
          className={styles.saveButton}
          buttonText="Close"
          onClick={() => {
            history.push(`/qip/user-management/billing/2`);
          }}
          // onClick={onCloseModal}
        />
      </div>
    </Modal>
  );
}

DowngradeSubscriptionModal.propTypes = {
  visibility: PropTypes.bool.isRequired,
  onOpenModal: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired,
};

export default DowngradeSubscriptionModal;
