import * as React from "react";

import useSWR, { mutate } from "swr";
import {
  FaRegArrowAltCircleLeft,
  FaPlusCircle,
  FaTimes,
  FaUser,
  FaPencilAlt,
  FaExclamationCircle,
  FaSpinner,
  FaBalanceScale,
  FaCheck,
} from "react-icons/fa";
import { Button, Icon } from "semantic-ui-react";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";

import styles from "./NQAView.module.css";

import { useStoreActions, useStoreState } from "../../../hooks";
import {
  fetchNQAQuestions,
  deleteCustomQuestion,
  deleteTask,
  postComply,
  fetchAuthor,
  addQuestionToSat,
  fetchSATQuestions,
  removeQuestionFromSat,
  removeSatData,
  getLawsRegulations,
} from "../../../api/requests";
import NQANotesModal from "../../../components/Modals/NQANotesModal";
import { NQAEvidenceModalv2 as NQAEvidenceModal } from "../../../components/Modals/NQAEvidenceModal";
import AddCustomGoalsEvidencesModal from "../../../components/Modals/AddCustomGoalsEvidencesModal";
import EditCustomGoalsEvidencesModal from "../../../components/Modals/EditCustomGoalsEvidencesModal";
import NQAStrategyModal from "../../../components/Modals/NQAStrategyModal";
import LawsRegulationsModal from "../../../components/Modals/LawsRegulationsModal";
import AddWarmingSatLimit from "../../../components/Modals/AddWarningSatLimit";
import ConfirmRemoveSatModal from "../../../components/Modals/ConfirmRemoveSatModal";
import { Popconfirm, Tooltip, Popover } from "antd";
import SatDrawer from "../../../components/SatDrawer";
import Checkbox from "../../../components/_base/Checkbox";
import { isEmpty } from "lodash";

const { useEffect, useReducer, useState, useRef, useCallback } = React;

const nqaQuestionTypes = [
  {
    name: "Embedded",
    value: "embedded",
  },
  {
    name: "Critical Reflections",
    value: "critical_reflections",
  },
  {
    name: "Partnerships with Families",
    value: "partnerships_with_family",
  },
];

const questionFilters = ["All questions", "Unanswered", "Answered", "Custom"];
// const questionFilters = ["All questions", "Unanswered", "Answered"];
// status: 1 - Yes, 2 - No, 3 - N/A
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const questionItemList = [
  {
    id: 1,
    name: "Physical Documentation",
    status: 1,
    tasks: 3,
    questionInfo:
      "Are all NQA 1 Policies and Procedures for documentation available at the service?",
    notes: "",
    strategy: {
      downloadResources: [
        {
          name: "Improving children’s wonderings tutorial.pdf",
          uploadDate: "12/03/2020",
        },
      ],
      goalStrategy: "",
      tasks: 0,
      comments: "",
    },
    evidence: {
      evidenceDocs: [
        {
          name: "NQA1_Document_2020.pdf",
          uploadDate: "12/03/2020",
        },
        {
          name: "NQA2_Document.pdf",
          uploadDate: "12/03/2020",
        },
      ],
      tasks: 3,
      comments: "",
    },
  },
  {
    id: 2,
    name: "Program recording",
    status: 0,
    tasks: 0,
    questionInfo:
      "Children’s ideas, wonderings and experiments may be recorded in the program, reflection book, wonder wall, electronic programming, morning and afternoon minutes.",
    notes: "",
    strategy: {
      downloadResources: [
        {
          name: "Improving children’s wonderings tutorial.pdf",
          uploadDate: "12/03/2020",
        },
      ],
      goalStrategy: "",
      tasks: 0,
      comments: "",
    },
    evidence: {
      evidenceDocs: [],
      tasks: 0,
      comments: "",
    },
  },
  {
    id: 3,
    name: "Co-programming",
    status: 0,
    tasks: 0,
    questionInfo:
      "Co-programming occurs at the service through morning/ afternoon minutes e.g. planning and reflective sessions with the children.",
    notes: "",
    strategy: {
      downloadResources: [
        {
          name: "Improving children’s wonderings tutorial.pdf",
          uploadDate: "12/03/2020",
        },
      ],
      goalStrategy: "",
      tasks: 0,
      comments: "",
    },
    evidence: {
      evidenceDocs: [],
      tasks: 0,
      comments: "",
    },
  },
  {
    id: 4,
    name: "EYLF",
    status: 2,
    tasks: 0,
    questionInfo:
      "The Early Years Learning Framework (EYLF) is the foundation of the program.",
    notes: "",
    strategy: {
      downloadResources: [
        {
          name: "Improving children’s wonderings tutorial.pdf",
          uploadDate: "12/03/2020",
        },
      ],
      goalStrategy: "",
      tasks: 0,
      comments: "",
    },
    evidence: {
      evidenceDocs: [],
      tasks: 0,
      comments: "",
    },
  },
  {
    id: 5,
    name: "Birth to school transition",
    status: 3,
    tasks: 0,
    questionInfo:
      "The service provides a program from birth to school transition.",
    notes: "",
    strategy: {
      downloadResources: [
        {
          name: "Improving children’s wonderings tutorial.pdf",
          uploadDate: "12/03/2020",
        },
      ],
      goalStrategy: "",
      tasks: 0,
      comments: "",
    },
    evidence: {
      evidenceDocs: [],
      tasks: 0,
      comments: "",
    },
  },
  {
    id: 6,
    name: "Service documents",
    status: 0,
    tasks: 0,
    questionInfo:
      "The service documents through a variety of formats, e.g. mind maps, video/audio recordings, learning stories, and a series of jottings and anecdotes.",
    notes: "Sample note data",
    strategy: {
      downloadResources: [
        {
          name: "Improving children’s wonderings tutorial.pdf",
          uploadDate: "12/03/2020",
        },
      ],
      goalStrategy: "",
      tasks: 0,
      comments: "",
    },
    evidence: {
      evidenceDocs: [
        {
          name: "NQA1_Document_2020.pdf",
          uploadDate: "12/03/2020",
        },
        {
          name: "NQA2_Document.pdf",
          uploadDate: "12/03/2020",
        },
        {
          name: "NQA3_Document_edited.pdf",
          uploadDate: "12/03/2020",
        },
      ],
      tasks: 1,
      comments: "",
    },
  },
  {
    id: 7,
    name: "Summaries",
    status: 0,
    tasks: 0,
    questionInfo:
      "The service uses summative reports/ developmental summaries for each child.",
    notes: "",
    strategy: {
      downloadResources: [
        {
          name: "Improving children’s wonderings tutorial.pdf",
          uploadDate: "12/03/2020",
        },
      ],
      goalStrategy: "",
      tasks: 0,
      comments: "",
    },
    evidence: {
      evidenceDocs: [],
      tasks: 0,
      comments: "",
    },
  },
];

const initialHoverStates = {
  types: {
    0: false,
    1: false,
    2: false,
  },
  questions: {
    0: false,
    1: false,
    2: false,
  },
};

const hoverReducer = (state, action) => {
  const updatedState = {
    ...state[action.field],
    [action.key]: action.value,
  };

  return {
    ...state,
    [action.field]: updatedState,
  };
};

function NQAView() {
  const history = useHistory();
  const match = useRouteMatch();
  const { nqaId } = useParams();

  const selectedNQA = useStoreState((state) => state.nqaStore.selectedNQA);
  const userId = useStoreState((state) => state.authStore.authObject.userId);
  const centerId = useStoreState((state) => state.nqaStore.serviceDetails.id);
  const userSelectedService = useStoreState(
    (state) => state.authStore.selectedService
  );

  const setSelectedNQA = useStoreActions(
    (actions) => actions.nqaStore.setSelectedNQA
  );
  const fetchNQAProgress = useStoreActions(
    (actions) => actions.nqaStore.fetchNQAProgress
  );

  const [nqaColorStyle, setColor] = useState("");
  const [nqaSelectionVisibility, setNQASelectionVisibility] = useState(false);
  const [addCustomGoalsEvidencesModal, setAddCustomGoalsEvidencesModal] =
    useState(false);

  const [editCustomGoalsEvidencesModal, setEditCustomGoalsEvidencesModal] =
    useState(false);

  const [selectedQuestionType, setQuestionType] = useState(nqaQuestionTypes[0]);
  const [selectedNQALevel, setSelectedNQALevel] = useState(
    selectedNQA.levels[0]
  );
  const [selectedQuestionFilter, setQuestionFilter] = useState(
    questionFilters[0]
  );
  const [selectedNQASubLevel, setSelectedNQASubLevel] = useState(
    selectedNQA.levels[0].subLevels[0]
  );

  const setLevel = useStoreActions(
    (actions) => actions.taskStore.setLevelInformation
  );

  const [selectedEvidence, setEvidence] = useState({});
  const [selectedStrategy, setStrategy] = useState({});
  const [selectedQuestionNote, setNote] = useState({});
  const [complyLoading, setComplyLoading] = useState(false);

  const [notesModalVisibility, setNotesModalVisibility] = useState(false);
  const [evidenceModalVisibility, setEvidenceModalVisibility] = useState(false);
  const [strategyModalVisibility, setStrategyModalVisibility] = useState(false);

  const [fetchLimitError, setFetchLimitError] = useState(false);

  const [customPayload, setCustomPayload] = useState({});

  const [isCustom, setIsCustom] = useState(false);

  const [openSatDrawer, setOpenSatDrawer] = useState(false);

  const [warningLimitModal, setWarningLimitModal] = useState(false);

  const [showRemoveSatModal, setShowRemoveSatModal] = useState(false);

  const [optionLoading, setOptionLoading] = useState({
    questionId: 0,
    status: 0,
  });

  const resetLevelInfo = useStoreActions(
    (actions) => actions.taskStore.resetLevelInformation
  );

  const levelInfo = useStoreState((state) => state.taskStore.levelInformation);

  // level maintainers
  const [levelMaintainer, setLevelMaintainer] = useState({
    level: levelInfo.navigate ? levelInfo.nqaLevel : 0,
    subLevel: levelInfo.navigate ? levelInfo.nqaSublevel : 0,
    category: levelInfo.navigate ? levelInfo.qtype : 0,
  });

  const [hoverStates, dispatch] = useReducer(hoverReducer, initialHoverStates);

  const [satLoading, setSatLoading] = useState(false);

  const [satQuestions, setSatQuestions] = useState([]);

  const [selectedForSat, setSelectedForSat] = useState(0);

  const [selectedSat, setSelectedSat] = useState({});

  const [openLawsRegModal, setOpenLawsRegModal] = useState(false);

  const navigate = useStoreState((state) => state.nqaStore.navigate);

  const setNavigate = useStoreActions(
    (actions) => actions.nqaStore.setNavigate
  );

  const [lawsRegulations, setLawsRegulations] = useState({});

  const [toggleSatShowPopup, setToggleSatShowPopup] = useState(false);

  const setQuestionStatus = async (status, question) => {
    if (complyLoading) {
      return;
    }
    if (question.status !== status) {
      setComplyLoading(true);
      setOptionLoading({
        questionId: question.id,
        status,
      });

      try {
        const response = await postComply(
          centerId,
          question.id,
          status,
          question?.strategy?.goalStrategy,
          question?.strategy?.self_assessment,
          {
            notes: question?.notes,
            evidence_note: question?.evidence?.comments,
          }
        );

        if (response) {
          await mutate([
            "/questions",
            centerId,
            selectedQuestionType.value,
            selectedNQASubLevel,
            userSelectedService.serviceCatId,
          ]);
          // TESTING ONLY
          await fetchNQAProgress(centerId);
          //comment below line for fix with maintaining questions level but will not update percentage unless refresh the page
          setSelectedNQA(parseInt(nqaId));
          //
        }
      } catch (error) {
        console.log(error);
      }

      setComplyLoading(false);
    }
  };

  const checkNQALevelStyle = (index) => {
    if (index === 2 && selectedNQA.levels.length !== 3)
      return styles.nqaLevelCardInvisible;

    if (selectedNQALevel.id === index + 1) return styles.nqaLevelCardSelected;

    return styles.nqaLevelCard;
  };

  const renderQuestionStatusHelpText = (status, evidence, strategy, notes) => {
    if (status === 1) {
      return (
        <div
          className={
            evidence ? styles.evidenceStrategySetText : styles.unsetHelperText
          }
        >
          <Icon name={evidence ? "check circle" : "exclamation circle"} />
          <text>
            {evidence ? "Evidence provided" : "Evidence not provided"}
          </text>
        </div>
      );
    } else if (status === 2) {
      return (
        <div
          className={
            strategy ? styles.evidenceStrategySetText : styles.unsetHelperText
          }
        >
          <Icon name={notes ? "check circle" : "exclamation circle"} />
          <text>Strategy {strategy ? "is set" : "not set"}</text>
        </div>
      );
    }

    return (
      <div className={notes ? styles.noteCreatedText : styles.noNoteText}>
        <Icon name={notes ? "check circle" : "exclamation circle"} />
        <text>{notes ? "Note added" : "No note created"}</text>
      </div>
    );
  };
  const openNQAQuestionModal = (type, questionObject) => {
    switch (type) {
      case "evidence":
        setEvidence(questionObject);
        setEvidenceModalVisibility(true);
        break;

      case "strategy":
        setStrategy(questionObject);
        setStrategyModalVisibility(true);
        break;

      case "notes":
        setNote(questionObject);
        setNotesModalVisibility(true);
        break;

      default:
        break;
    }
  };
  const viewTasksModal = (questionItem, openTasks = false) => {
    switch (questionItem.status) {
      case 1:
        openNQAQuestionModal("evidence", {
          centerId,
          openTasks,
          ...questionItem.evidence,
          strategy: { ...questionItem.strategy },
          cqrId: questionItem.cqrId,
          questionId: questionItem.id,
          yesDescription: questionItem.yesDescription,
        });
        break;

      case 2:
        openNQAQuestionModal("strategy", {
          centerId,
          openTasks,
          ...questionItem.strategy,
          notes: questionItem.notes,
          status: questionItem.status,
          questionId: questionItem.id,
          noDescription: questionItem.noDescription,
        });
        break;

      case 3:
        openNQAQuestionModal("notes", {
          centerId,
          notes: questionItem.notes,
          status: questionItem.status,
          questionId: questionItem.id,
          goalStrategy: questionItem.strategy.goalStrategy,
          self_assessment: questionItem.strategy.self_assessment,
          noteDescription: questionItem.noteDescription,
        });
        break;

      default:
        break;
    }
  };

  const manualViewTasksModal = (
    questionItem,
    questionStatus,
    openTasks = false
  ) => {
    switch (questionStatus) {
      case 1:
        openNQAQuestionModal("evidence", {
          centerId,
          openTasks,
          ...questionItem.evidence,
          strategy: { ...questionItem.strategy },
          cqrId: questionItem.cqrId,
          questionId: questionItem.id,
          yesDescription: questionItem.yesDescription,
        });
        break;

      case 2:
        openNQAQuestionModal("strategy", {
          centerId,
          openTasks,
          ...questionItem.strategy,
          notes: questionItem.notes,
          status: questionItem.status,
          questionId: questionItem.id,
          noDescription: questionItem.noDescription,
        });
        break;

      case 3:
        openNQAQuestionModal("notes", {
          centerId,
          notes: questionItem.notes,
          status: questionItem.status,
          questionId: questionItem.id,
          goalStrategy: questionItem.strategy.goalStrategy,
          self_assessment: questionItem.strategy.self_assessment,
          noteDescription: questionItem.noteDescription,
        });
        break;

      default:
        break;
    }
  };

  const renderQuestionButtonAction = (
    questionItem,
    openTasks = false,
    custom = false
  ) => {
    switch (questionItem.status) {
      case 1:
        return (
          <button
            className={styles.editEvidenceButton}
            onClick={() =>
              openNQAQuestionModal("evidence", {
                isCustom: custom,
                centerId,
                openTasks,
                ...questionItem.evidence,
                strategy: { ...questionItem.strategy },
                cqrId: questionItem.cqrId,
                questionId: questionItem.id,
                yesDescription: questionItem.yesDescription,
              })
            }
          >
            {questionItem.evidence.evidenceDocs.length !== 0
              ? "Edit evidence"
              : "Provide evidence"}
          </button>
        );

      case 2:
        return (
          <button
            className={styles.addStrategyButton}
            onClick={() =>
              openNQAQuestionModal("strategy", {
                centerId,
                openTasks,
                ...questionItem.strategy,
                notes: questionItem.notes,
                status: questionItem.status,
                questionId: questionItem.id,
                noDescription: questionItem.noDescription,
              })
            }
          >
            {questionItem.strategy.goalStrategy
              ? "Edit goal strategy"
              : custom
              ? "Add Goal Strategy"
              : "View QIP Goal"}
          </button>
        );

      case 3:
        return (
          <button
            className={styles.createNoteButton}
            onClick={() =>
              openNQAQuestionModal("notes", {
                centerId,
                notes: questionItem.notes,
                status: questionItem.status,
                questionId: questionItem.id,
                goalStrategy: questionItem.strategy.goalStrategy,
                self_assessment: questionItem.strategy.self_assessment,
                noteDescription: questionItem.noteDescription,
              })
            }
          >
            {questionItem.notes ? "Edit notes" : "Create a note"}
          </button>
        );

      // commented out since a question has no cqr id until it is assigned a 'status' with postComply endpoint
      // return (
      //   <button
      //     className={styles.provideEvidenceButton}
      //     onClick={() => openNQAQuestionModal('evidence', emptyEvidence, questionItem.cqrId)}
      //   >
      //     Provide evidence
      //   </button>
      // );
      default:
        break;
    }
  };

  const displayTaskText = (questionItem) => {
    if (questionItem.status === 1) {
      if (questionItem.evidence.taskCount === 0) return "Add tasks";

      return "View evidence tasks";
    }

    if (questionItem.strategy.taskCount === 0) return "Add tasks";

    return "View goal tasks";
  };

  const setNQAColors = (nqa) => {
    switch (nqa) {
      case 1:
        setColor("#61A447");
        break;
      case 2:
        setColor("#FBD539");
        break;
      case 3:
        setColor("#E395A5");
        break;
      case 4:
        setColor("#ACCF6B");
        break;
      case 5:
        setColor("#F3A23E");
        break;
      case 6:
        setColor("#DB308F");
        break;
      case 7:
        setColor("#3A96CF");
        break;

      default:
        break;
    }
  };

  const onNQASelect = (nqa) => {
    setSelectedNQA(nqa);
    setNQAColors(nqa);
    setNQASelectionVisibility(false);

    if (nqa !== parseInt(nqaId)) {
      const urlArray = match.path.split("/");
      const newUrl = urlArray.slice(0, urlArray.length - 1);

      history.push(`${newUrl.join("/")}/${nqa}`);
    }
  };

  const nqaQuestionFilter = (status, qCustom = false) => {
    switch (selectedQuestionFilter) {
      case "All questions":
        return true;
      case "Unanswered":
        if (status === 0) return true;
        break;
      case "Answered":
        if (status !== 0) return true;
        break;
      case "Custom":
        if (qCustom) return true;
        break;

      default:
        break;
    }
  };

  const { data: questionItems, isValidating } = useSWR(
    [
      "/questions",
      centerId,
      selectedQuestionType.value,
      selectedNQASubLevel,
      userSelectedService.serviceCatId,
    ],
    (_, centerId, selectedQuestionType, selectedNQASubLevel) =>
      fetchNQAQuestions(
        centerId,
        selectedQuestionType,
        selectedNQASubLevel,
        userSelectedService.serviceCatId
      ),
    {
      onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
        // Only retry up to 5 times.
        if (retryCount && retryCount >= 5) {
          if (!questionItems) setFetchLimitError(true);

          return;
        }
        // Retry after 3 seconds.
        setTimeout(() => revalidate({ retryCount: retryCount + 1 }), 3000);
      },
    }
  );

  const onDeleteCustomQuestion = async (question) => {
    try {
      const response = await deleteCustomQuestion(question.id, question.qcId);

      question.evidence.tasks.forEach(async (task) => {
        await deleteTask(task.id);
      });

      question.strategy.tasks.forEach(async (task) => {
        await deleteTask(task.id);
      });

      await mutate([
        "/questions",
        centerId,
        selectedQuestionType.value,
        selectedNQASubLevel,
        userSelectedService.serviceCatId,
      ]);
    } catch (error) {
      console.log(error);
    }
  };

  const onEditCustomQuestion = (questionItem) => {
    setCustomPayload({
      qId: questionItem.id,
      status: questionItem.status,
      description: questionItem.description,
      title: "custom",
      type: selectedQuestionType.value,
      name: selectedQuestionType.name,
      user_id: userId,
      center_id: centerId,
      category_id: selectedNQASubLevel.sourceId,
      label: selectedNQASubLevel.label,
    });
    setEditCustomGoalsEvidencesModal(true);
  };

  const onCreateCustomQuestion = () => {
    setCustomPayload({
      title: "custom",
      type: selectedQuestionType.value,
      name: selectedQuestionType.name,
      user_id: userId,
      center_id: centerId,
      category_id: selectedNQASubLevel.sourceId,
      label: selectedNQASubLevel.label,
    });
    setAddCustomGoalsEvidencesModal(true);
  };

  const PostedByUser = ({ user_id }) => {
    const { data: userData, isValidating } = useSWR(
      ["/author", user_id],
      (_, user_id) => fetchAuthor(user_id),
      {
        onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
          // Only retry up to 3 times.
          if (retryCount && retryCount >= 3) {
            if (!userData) setFetchLimitError(true);

            return;
          }
          // Retry after 3 seconds.
          setTimeout(() => revalidate({ retryCount: retryCount + 1 }), 3000);
        },
      }
    );

    return (
      <>
        {userData ? (
          <div className={`${styles.customPostedBy}`}>
            <button className={`flex items-center mr-4`}>
              <FaUser className={`mr-3`} size={15} />
              Post by {userData.name}
            </button>
          </div>
        ) : (
          ""
        )}
      </>
    );
  };

  const setCustomPreStatus = async (customQuestionItems, status) => {
    customQuestionItems.forEach(async (q) => {
      await setQuestionStatus(status, q);
    });
  };

  const onAddQuestionToSat = async (questionId) => {
    try {
      setSatLoading(true);
      const response = await addQuestionToSat(centerId, questionId);
      await onFetchSatQuestions();
      setToggleSatShowPopup(!toggleSatShowPopup);
      setSatLoading(false);
    } catch (error) {
      setSatLoading(false);
      console.log(error);
    }
  };

  const onFetchSatQuestions = async () => {
    try {
      const response = await fetchSATQuestions(centerId);
      setSatQuestions(response);
    } catch (error) {
      console.log(error);
    }
  };

  const onRemoveQuestionFromSat = async (questionId) => {
    try {
      setSatLoading(true);
      const response = await removeQuestionFromSat(centerId, questionId);
      await onFetchSatQuestions();
      setSatLoading(false);
    } catch (error) {
      setSatLoading(false);
      console.log(error);
    }
  };

  const onGoToQuestion = async (nId, level) => {
    setOpenSatDrawer(false);
    setLevel(level);
    setSelectedNQA(nId);

    history.push(`/qip/nqa/${nId}`);
  };

  const checkConfirmSatRemoval = async (questionItem) => {
    if (
      satQuestions.some(
        (question) => question.question_id === questionItem.id
      ) &&
      satQuestions.filter(
        (question) => question.question_id === questionItem.id
      )[0].hasDataDescription
    ) {
      setSelectedSat({
        questionItem,
        satData: {
          ...satQuestions.filter(
            (question) => question.question_id === questionItem.id
          )[0].satData,
        },
      });
      setShowRemoveSatModal(true);
    } else {
      await setQuestionStatus(2, questionItem);
    }
  };

  const onConfirmSatRemove = async () => {
    try {
      const response = await removeSatData(
        true,
        satQuestions.filter(
          (question) => question.question_id === selectedSat.questionItem.id
        )[0].satData.id,
        selectedSat.questionItem.id,
        centerId
      );
      await onFetchSatQuestions();
      await setQuestionStatus(2, selectedSat.questionItem);
      setShowRemoveSatModal(false);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchLawsRegulations = useCallback(async () => {
    try {
      const response = await getLawsRegulations(selectedNQA.id, centerId);

      setLawsRegulations(response);
    } catch (error) {
      console.log(error);
    }
  }, [nqaId]);

  useEffect(() => {
    fetchLawsRegulations();
  }, [selectedNQA]);

  useEffect(() => {
    onFetchSatQuestions();
  }, []);

  useEffect(() => {
    if (isValidating) setFetchLimitError(false);
  }, [isValidating]);

  useEffect(() => {
    setNQAColors(parseInt(nqaId));
    setSelectedNQA(parseInt(nqaId));
    setSelectedNQALevel(selectedNQA.levels[0]);
    setSelectedNQASubLevel(selectedNQA.levels[0].subLevels[0]);
  }, [nqaId, selectedNQA.levels, setSelectedNQA]);

  useEffect(() => {
    setSelectedNQALevel(selectedNQA.levels[0]);
    setSelectedNQASubLevel(selectedNQA.levels[0].subLevels[0]);
  }, [selectedNQA]);

  // useEffect(() => {
  //   setNQAColors(parseInt(nqaId));
  //   setSelectedNQA(parseInt(nqaId));
  //   setSelectedNQALevel(selectedNQA.levels[levelMaintainer.level]);
  //   setSelectedNQASubLevel(
  //     selectedNQA.levels[levelMaintainer.level].subLevels[
  //       levelMaintainer.subLevel
  //     ]
  //   );
  //   setQuestionType(nqaQuestionTypes[levelMaintainer.category]);
  // }, [nqaId, selectedNQA.levels, setSelectedNQA, levelMaintainer]);

  // useEffect(() => {
  //   setSelectedNQALevel(selectedNQA.levels[levelMaintainer.level]);
  //   setSelectedNQASubLevel(
  //     selectedNQA.levels[levelMaintainer.level].subLevels[
  //       levelMaintainer.subLevel
  //     ]
  //   );
  //   setQuestionType(nqaQuestionTypes[levelMaintainer.category]);
  // }, [selectedNQA, levelMaintainer]);
  return (
    <div
      ref={(nqaViewPage) => {
        if (!nqaViewPage) return;
        // preserving levels
        setSelectedNQALevel(selectedNQA.levels[levelMaintainer.level]);
        setSelectedNQASubLevel(
          selectedNQA.levels[levelMaintainer.level].subLevels[
            levelMaintainer.subLevel
          ]
        );
        setQuestionType(nqaQuestionTypes[levelMaintainer.category]);
      }}
      className={styles.mainContainer}
    >
      {showRemoveSatModal && (
        <ConfirmRemoveSatModal
          satQuestion={selectedSat}
          visibility={showRemoveSatModal}
          onCloseModal={() => setShowRemoveSatModal(false)}
          onConfirm={async () => await onConfirmSatRemove()}
        />
      )}
      <AddWarmingSatLimit
        visibility={warningLimitModal}
        onCloseModal={() => setWarningLimitModal(false)}
      />
      {openLawsRegModal && !isEmpty(lawsRegulations) && (
        <LawsRegulationsModal
          lawsRegs={lawsRegulations}
          nqaId={nqaId}
          visibility={openLawsRegModal}
          onCloseModal={() => setOpenLawsRegModal(false)}
          refetchLar={() => fetchLawsRegulations()}
        />
      )}

      <SatDrawer
        navigate={async (nqaId) => {
          setNavigate(true);
          setOpenSatDrawer(false);
          setSelectedNQA(nqaId);

          history.push(`/qip/nqa/${nqaId}`);
          setLevelMaintainer({
            level: 0,
            subLevel: 0,
            category: 0,
          });
        }}
        curSat={
          satQuestions.filter(
            (question) => question.label === selectedNQASubLevel.label
          ).length
        }
        satQuestions={satQuestions}
        visibility={openSatDrawer}
        onToggle={() => {
          setOpenSatDrawer(!openSatDrawer);
        }}
        refetchQuestions={() => onFetchSatQuestions()}
        goToQuestion={(nqaId, level) => {
          onGoToQuestion(nqaId, level);
        }}
        satDrawerState={{
          cfilter: selectedNQA.id,
          subCFilter: selectedNQASubLevel.label,
        }}
        toggleShowPopup={toggleSatShowPopup}
      />

      <EditCustomGoalsEvidencesModal
        customPayload={{ ...customPayload }}
        visibility={editCustomGoalsEvidencesModal}
        onOpenModal={() => setEditCustomGoalsEvidencesModal(true)}
        onCloseModal={() => setEditCustomGoalsEvidencesModal(false)}
        onMutation={async (qId, curStatus, prevStatus) => {
          if (Number(curStatus !== Number(prevStatus))) {
            await postComply(centerId, Number(qId), Number(curStatus));
          }

          await mutate([
            "/questions",
            centerId,
            selectedQuestionType.value,
            selectedNQASubLevel,
            userSelectedService.serviceCatId,
          ]);
        }}
      />
      <AddCustomGoalsEvidencesModal
        customPayload={customPayload}
        visibility={addCustomGoalsEvidencesModal}
        onOpenModal={() => setAddCustomGoalsEvidencesModal(true)}
        onCloseModal={() => setAddCustomGoalsEvidencesModal(false)}
        onMutation={async (status) => {
          await mutate([
            "/questions",
            centerId,
            selectedQuestionType.value,
            selectedNQASubLevel,
            userSelectedService.serviceCatId,
          ]).then(async (newQuestions) => {
            await setCustomPreStatus(
              newQuestions.filter(
                (q) => q.centerId === centerId && q.status === 0
              ),
              status
            );
          });
        }}
      />
      <div style={{ height: "50px" }}></div>
      {/* <div className={styles.backButtonContainer}>
        <button
          className={styles.backButtonText}
          onClick={() => history.goBack()}
        >
          <FaRegArrowAltCircleLeft
            className={styles.backButton}
            color="#C4C4C4"
            size={24}
          />
        </button>
      </div> */}

      {evidenceModalVisibility && (
        <NQAEvidenceModal
          isCustom={isCustom}
          evidenceObject={selectedEvidence}
          visibility={evidenceModalVisibility}
          onOpenModal={() => setEvidenceModalVisibility(true)}
          onCloseModal={() => setEvidenceModalVisibility(false)}
          onMutation={async () => {
            await mutate([
              "/questions",
              centerId,
              selectedQuestionType.value,
              selectedNQASubLevel,
              userSelectedService.serviceCatId,
            ]);
            // TESTING ONLY
            await fetchNQAProgress(centerId);
            setSelectedNQA(parseInt(nqaId)); //
            setEvidenceModalVisibility(false);
          }}
          refreshModal={async (selectedEvidence) => {
            // setEvidenceModalVisibility(false);
            const updatedQuestions = await mutate([
              "/questions",
              centerId,
              selectedQuestionType.value,
              selectedNQASubLevel,
              userSelectedService.serviceCatId,
            ]);
            const updatedTasks = updatedQuestions.find(
              (item) => item.id === selectedEvidence.questionId
            ).evidence.tasks;

            const updatedEvidenceDocs = updatedQuestions.find(
              (item) => item.id === selectedEvidence.questionId
            ).evidence.evidenceDocs;

            openNQAQuestionModal("evidence", {
              ...selectedEvidence,
              evidenceDocs: updatedEvidenceDocs,
              tasks: updatedTasks,
            });
          }}
        />
      )}

      {strategyModalVisibility && (
        <NQAStrategyModal
          strategyObject={selectedStrategy}
          visibility={strategyModalVisibility}
          onOpenModal={() => setStrategyModalVisibility(true)}
          onCloseModal={() => setStrategyModalVisibility(false)}
          onMutation={async () => {
            await mutate([
              "/questions",
              centerId,
              selectedQuestionType.value,
              selectedNQASubLevel,
              userSelectedService.serviceCatId,
            ]);
            // TESTING ONLY
            await fetchNQAProgress(centerId);
            setStrategyModalVisibility(false);
          }}
          refreshModal={async (selectedStrategy) => {
            // setStrategyModalVisibility(false);
            const updatedQuestions = await mutate([
              "/questions",
              centerId,
              selectedQuestionType.value,
              selectedNQASubLevel,
              userSelectedService.serviceCatId,
            ]);
            const updatedTasks = updatedQuestions.find(
              (item) => item.id === selectedStrategy.questionId
            ).strategy.tasks;

            const updatedDownloadResources = updatedQuestions.find(
              (item) => item.id === selectedStrategy.questionId
            ).strategy.downloadResources;

            const updatedStrategy = updatedQuestions.find(
              (item) => item.id === selectedStrategy.questionId
            );

            const newUpdatedStrategy = {
              centerId,
              questionId: updatedStrategy.id,
              status: updatedStrategy.status,
              notes: updatedStrategy.notes,
              goalStrategy: updatedStrategy.strategy.goalStrategy,
              noDescription: updatedStrategy.noDescription,
              self_assessment: updatedStrategy.strategy.self_assessment,
            };
            openNQAQuestionModal("strategy", {
              ...newUpdatedStrategy,
              downloadResources: updatedDownloadResources,
              tasks: updatedTasks,
            });
          }}
        />
      )}

      {notesModalVisibility && (
        <NQANotesModal
          visibility={notesModalVisibility}
          questionObject={selectedQuestionNote}
          onOpenModal={() => setNotesModalVisibility(true)}
          onCloseModal={async () => {
            await mutate([
              "/questions",
              centerId,
              selectedQuestionType.value,
              selectedNQASubLevel,
              userSelectedService.serviceCatId,
            ]);
            //TESTING ONLY
            await fetchNQAProgress(centerId);
            setNotesModalVisibility(false);
          }}
        />
      )}

      {/* {complyLoading ? (
        <div
          className={`w-full h-screen flex flex-col justify-center items-center`}
        >
          <img src={spinner} className={`block`} />
          <span className={`block`}> Loading data...</span>
        </div>
      ) : ( */}
      <div className={styles.content}>
        <div className={styles.headerContainer}>
          <div>
            <div className={styles.nqaPicker}>
              {nqaSelectionVisibility && (
                <div className={styles.nqaSelectionRow}>
                  {[...Array(7).keys()].map((nqa) => (
                    <div
                      key={nqa}
                      className={styles[`nqa${nqa + 1}Circle`]}
                      onClick={() => {
                        setLevelMaintainer({
                          level: 0,
                          subLevel: 0,
                          category: 0,
                        });
                        onNQASelect(nqa + 1);
                      }}
                    >
                      <text>NQA{nqa + 1}</text>
                    </div>
                  ))}
                </div>
              )}

              <div
                className={styles[`nqa${selectedNQA.id}Circle`]}
                onClick={() => setNQASelectionVisibility(true)}
              >
                <text>NQA{selectedNQA.id}</text>
              </div>

              <div className={styles.nqaPickerText}>
                <text>Switch</text>

                <Icon
                  name={
                    nqaSelectionVisibility ? "chevron left" : "chevron right"
                  }
                />
              </div>
            </div>

            <div className={styles.headerTextGroup}>
              <text className={styles.headerText}>
                {`${selectedNQA.name} - ${selectedNQA.title}`}
              </text>

              <text className={styles.headerSubText}>
                {userSelectedService.name}
              </text>
            </div>
          </div>

          <button
            className={styles.backToDashboardButton}
            onClick={() => history.push("/nqa")}
          >
            Back to dashboard
          </button>
        </div>

        <div
          className={`${styles.accordanceContainer} px-5  flex justify-between items-center mt-7 mb-16 py-4`}
        >
          <div className={`flex justify-start items-center`}>
            <div
              className={`${styles.accordanceScaleIcon} md:w-1/5 lg:w-auto ${
                styles[`accordanceScaleIcon_nqa${selectedNQA.id}`]
              }  flex justify-center items-center`}
            >
              <FaBalanceScale />
            </div>
            <div className={`ml-8 md:w-4/5 lg:w-auto`}>
              <div className={styles.headerLaw}>
                In accordance with the National Law and Regulations
              </div>
              <div className={styles.headerLawSub}>
                Please assess whether your service is compliant or non-compliant
              </div>
            </div>
          </div>
          <div
            className={`flex md:flex-col lg:flex-row justify-end  items-center`}
          >
            <div
              className={`flex flex-col items-start md:mr-0 lg:mr-5 md:mb-2 lg:mb-0`}
            >
              <div className={styles.accordanceProgressText}>{`${
                !isEmpty(lawsRegulations)
                  ? Math.floor(lawsRegulations.compliance * 100)
                  : 0
              }% completed`}</div>
              <div
                className={`${styles.accordancePbContainer} flex justify-start items-center`}
              >
                <div
                  style={{
                    width: `${
                      !isEmpty(lawsRegulations)
                        ? Math.floor(lawsRegulations.compliance * 100)
                        : 0
                    }%`,
                  }}
                  className={`${styles.accordanceProgressBar}  ${
                    styles[`accordance_nqa${selectedNQA.id}`]
                  } `}
                ></div>
                <div
                  style={{
                    width: `${
                      !isEmpty(lawsRegulations)
                        ? Math.floor((1 - lawsRegulations.compliance) * 100)
                        : 100
                    }%`,
                  }}
                  className={`${styles.accordanceProgressBar} ${styles.accordanceProgressInComplete} `}
                ></div>
              </div>
            </div>
            <button
              onClick={() => setOpenLawsRegModal(true)}
              className={`${styles.accordanceFinishButton}  ${
                styles[`accordance_nqa${selectedNQA.id}`]
              }`}
            >
              {lawsRegulations.compliance > 0 &&
                lawsRegulations.compliance < 1 && <> Finish </>}
              {lawsRegulations.compliance === 0 && <> Start </>}
              {lawsRegulations.compliance === 1 && (
                <div className={`flex justify-center items-center`}>
                  {" "}
                  <span className={`mr-2`}>Finished </span>
                  <div
                    className={`${styles.checkedCompliance} ${
                      styles[`accordanceScaleIcon_nqa${selectedNQA.id}`]
                    } flex justify-center items-center rounded-full overflow-hidden`}
                  >
                    <FaCheck />
                  </div>
                </div>
              )}
            </button>
          </div>
        </div>

        <div className={styles.nqaLevelSelectionRow}>
          {Array.from(
            { length: 3 },
            (_, i) => selectedNQA.levels[i % selectedNQA.levels.length]
          ).map((level, index) => (
            <div
              key={
                selectedNQA.levels.length === 3
                  ? level.id
                  : index === 2
                  ? level.name
                  : level.id
              }
              className={checkNQALevelStyle(index)}
              onClick={() => {
                setLevelMaintainer({
                  level: index,
                  subLevel: 0,
                  category: levelMaintainer.category,
                });
                //  setSelectedNQALevel(level); /////
                //  setSelectedNQASubLevel(level.subLevels[0]); ////
              }}
            >
              <text className={styles.nqaLevelCardHeader}>
                {/\d/.test(level.name)
                  ? `${level.name} ${level.title}`
                  : `${selectedNQA.id}.${level.id} ${level.title}`}
              </text>

              <div className={styles.progressBarContainer}>
                <div
                  className={styles.progressBar}
                  style={{
                    // width: `${Math.ceil(level.completed)}%`,
                    width: `${
                      level.name !== "2.1"
                        ? Math.floor(level.completed)
                        : level.completed !== 99.22480620155038
                        ? Math.floor(level.completed)
                        : 100
                    }%`,
                    backgroundColor: `${nqaColorStyle}`,
                  }}
                />
              </div>

              <text className={styles.progressBarText}>
                {/* {`${Math.ceil(level.completed)}% ${
                  Math.ceil(level.completed) === 100 ? "Finished" : "Completed"
                  }`} */}
                {`${
                  level.name !== "2.1"
                    ? Math.floor(level.completed)
                    : level.completed !== 99.22480620155038
                    ? Math.floor(level.completed)
                    : 100
                }% ${
                  level.name !== "2.1"
                    ? Math.floor(level.completed) === 100
                      ? "Finished"
                      : "Completed"
                    : level.completed !== 99.22480620155038
                    ? Math.floor(level.completed) === 100
                      ? "Finished"
                      : "Completed"
                    : "Finished"
                }`}
              </text>
            </div>
          ))}
        </div>
        <div
          ref={(subLevelRow) => {
            if (!subLevelRow) return;
            if (subLevelRow && navigate) {
              subLevelRow.scrollIntoView();
              setNavigate(false);
            }
          }}
          className={styles.nqaLevelInfoRow}
        >
          <div>
            <Button
              className={`${styles.nqaLevelInfoIcon} ${
                styles[`nqa${parseInt(nqaId)}Info`]
              }`}
              icon="info"
              circular
            />
          </div>
          <text>
            {`${selectedNQA.id}.${selectedNQALevel.id} ${selectedNQALevel.info}`}
          </text>
        </div>

        <div className={styles.nqaSubLevelSelectionRow}>
          {selectedNQALevel.subLevels.map((sublevel, index) => (
            <div
              key={sublevel.id}
              className={
                sublevel.id === selectedNQASubLevel.id
                  ? styles.nqaSubLevelCardSelected
                  : styles.nqaSubLevelCard
              }
              onClick={() => {
                setLevelMaintainer({
                  level: levelMaintainer.level,
                  subLevel: index,
                  category: levelMaintainer.category,
                });

                //  setSelectedNQASubLevel(sublevel); ///////
              }}
            >
              <text className={styles.nqaSubLevelCardText}>
                {sublevel.label}
              </text>
            </div>
          ))}
        </div>

        <div className={styles.nqaSubLevelInfoCard}>
          <div className={styles.nqaSubLevelInfoCardLeftColumn}>
            <text className={styles.nqaSubLevelInfoCardHeader}>
              {selectedNQASubLevel.name}
            </text>

            <text className={styles.nqaSubLevelInfoCardSubText}>
              {selectedNQASubLevel.description}
            </text>
          </div>

          <div className={styles.nqaSubLevelInfoCardRightColumn}>
            <div>
              <text className={styles.nqaSubLevelInfoCardHeader}>
                Your progress
              </text>

              <div className={styles.progressBarContainer}>
                <div
                  className={styles.progressBar}
                  style={{
                    // width: `${Math.ceil(selectedNQASubLevel.completed)}%`,
                    width: `${
                      selectedNQASubLevel.label !== "2.1.2"
                        ? Math.floor(selectedNQASubLevel.completed)
                        : selectedNQASubLevel.completed !== 97.67441860465115
                        ? Math.floor(selectedNQASubLevel.completed)
                        : 100
                    }%`,
                    backgroundColor: `${nqaColorStyle}`,
                  }}
                />
              </div>

              <text className={styles.progressBarText}>
                {/* {`${Math.ceil(selectedNQASubLevel.completed)}%`} */}
                {`${
                  selectedNQASubLevel.label !== "2.1.2"
                    ? Math.floor(selectedNQASubLevel.completed)
                    : selectedNQASubLevel.completed !== 97.67441860465115
                    ? Math.floor(selectedNQASubLevel.completed)
                    : 100
                }%`}
              </text>
            </div>
          </div>
        </div>

        <div className={styles.nqaSubLevelCategoriesRow}>
          {nqaQuestionTypes.map((type, index) => (
            <div
              key={type.name}
              className={
                hoverStates["types"][index] &&
                selectedQuestionType.name !== type.name
                  ? `${styles[`nqa${nqaId}Info`]} ${
                      styles.nqaSubLevelCategoryCard
                    }`
                  : selectedQuestionType.name === type.name
                  ? styles.nqaSubLevelCategoryCardSelected
                  : styles.nqaSubLevelCategoryCard
              }
              style={
                selectedQuestionType.name === type.name
                  ? { backgroundColor: `${nqaColorStyle}` }
                  : null
              }
              onMouseEnter={() =>
                dispatch({
                  field: "types",
                  key: `${index}`,
                  value: true,
                })
              }
              onMouseLeave={() =>
                dispatch({
                  field: "types",
                  key: `${index}`,
                  value: false,
                })
              }
              onClick={() => {
                setLevelMaintainer({
                  level: levelMaintainer.level,
                  subLevel: levelMaintainer.subLevel,
                  category: index,
                });
                // setQuestionType(type);
              }}
            >
              {selectedQuestionType.name === type.name && (
                <div
                  className={styles.nqaSubLevelCategoryCardSelectedArrow}
                  style={{ borderTopColor: `${nqaColorStyle}` }}
                />
              )}
              <text className={styles.nqaSubLevelCategoryText}>
                {type.name}
              </text>
            </div>
          ))}
        </div>

        <div className={`${styles.questionFilterText} mb-16`}>
          <text className={`block font-medium`}>
            Simply answer yes or no to the question in the list.
          </text>
          <text className={`block font-light`}>
            View automated goals, set goal strategies, or add evidences. You can
            also add your own goals and strength elements to further enhance
            your capabilities.
          </text>
        </div>

        <div
          className={`${styles.questionFilterRow} flex flex-wrap items-center justify-center mb-10`}
        >
          {questionFilters.map((question, index) => (
            <text
              key={question}
              className={
                hoverStates["questions"][index] &&
                selectedQuestionFilter !== question
                  ? `${styles[`nqa${nqaId}Info`]} ${styles.questionFilter}`
                  : selectedQuestionFilter === question
                  ? styles.questionFilterSelected
                  : styles.questionFilter
              }
              style={
                selectedQuestionFilter === question
                  ? { backgroundColor: `${nqaColorStyle}` }
                  : null
              }
              onMouseEnter={() =>
                dispatch({
                  field: "questions",
                  key: `${index}`,
                  value: true,
                })
              }
              onMouseLeave={() =>
                dispatch({
                  field: "questions",
                  key: `${index}`,
                  value: false,
                })
              }
              onClick={() => setQuestionFilter(question)}
            >
              {question}
            </text>
          ))}
          <text
            className={`${styles.questionFilter} flex items-center justify-center `}
            onClick={onCreateCustomQuestion}
          >
            <FaPlusCircle className={`mr-3`} size={15} />
            Add your own goals & strengths
          </text>
        </div>

        {fetchLimitError ? (
          <h3>
            Failed to load data. Please refresh the page and try again, or
            contact your system administrator.
          </h3>
        ) : !questionItems ? (
          <div
            className={`w-full h-screen flex flex-col justify-center items-center`}
          >
            <FaSpinner
              className={`animate-spin text-qipGreen font-bold text-6xl`}
            />
            <span className={`block`}> Loading data...</span>
          </div>
        ) : (
          <>
            <div className={styles.nqaQuestions}>
              {questionItems
                .filter((question) => {
                  return question.archive === 0;
                })
                .filter((question) => {
                  return question.centerId ? false : true;
                })
                .filter((question) => nqaQuestionFilter(question.status, false))
                .map((questionItem) => (
                  <div
                    ref={(cursorTaskQ) => {
                      if (
                        cursorTaskQ &&
                        levelInfo.navigate &&
                        levelInfo.qId === questionItem.id
                      ) {
                        cursorTaskQ.scrollIntoView({
                          behavior: "smooth",
                          block: "center",
                        });

                        if (levelInfo.navigate) {
                          setTimeout(resetLevelInfo, 1000);
                          // resetLevelInfo();
                        }
                      }
                    }}
                    key={questionItem.id}
                    className={styles.nqaQuestionCard}
                  >
                    <div className={`h-0`}></div>
                    {questionItem.status === 1 && (
                      <div
                        className={`${styles.satQuestion} px-4 flex justify-between items-center`}
                      >
                        <div className={`flex justify-start items-center`}>
                          <Checkbox
                            loading={
                              satLoading && selectedForSat === questionItem.id
                            }
                            disabled={satLoading}
                            checked={satQuestions.some(
                              (question) =>
                                question.question_id === questionItem.id
                            )}
                            onToggle={async (value) => {
                              if (
                                !value &&
                                satQuestions.filter(
                                  (question) =>
                                    question.label === selectedNQASubLevel.label
                                ).length < 5
                              ) {
                                setSelectedForSat(questionItem.id);
                                await onAddQuestionToSat(questionItem.id);
                              }

                              if (
                                !value &&
                                satQuestions.filter(
                                  (question) =>
                                    question.label === selectedNQASubLevel.label
                                ).length >= 5
                              ) {
                                setWarningLimitModal(true);
                              }

                              if (value) {
                                setSelectedForSat(questionItem.id);
                                await onRemoveQuestionFromSat(questionItem.id);
                              }
                            }}
                            style={{
                              width: "35px",
                              height: "35px",
                            }}
                          />
                          <text className={`${styles.satLeftText} ml-2`}>
                            {satQuestions.some(
                              (question) =>
                                question.question_id === questionItem.id
                            )
                              ? `This question has been added to SAT`
                              : `Add this question to SAT Report`}
                          </text>
                        </div>

                        {satQuestions.some(
                          (question) => question.question_id === questionItem.id
                        ) ? (
                          <div className={`flex justify-end items-center`}>
                            <text>
                              {`
                                ${
                                  satQuestions.filter(
                                    (question) =>
                                      question.label ===
                                      selectedNQASubLevel.label
                                  ).length
                                }
                                of 5 SAT slots used`}
                            </text>
                            <Popover
                              placement={`topRight`}
                              content={
                                <div
                                  className={`${styles.satToolTip} flex flex-col items-start relative`}
                                >
                                  <div>
                                    <span
                                      className={`font-medium`}
                                    >{`What's this? `}</span>
                                    <span>
                                      {`Adding this strength as a favourite will
                                    mean it’ll be used as part of your SAT
                                    report, ready for the government portal.`}
                                    </span>
                                  </div>
                                  <hr className={`${styles.toolTipHr} my-2`} />
                                  <div>
                                    <span
                                      className={`font-medium`}
                                    >{`Why only 5? `}</span>
                                    <span>{`You can only choose 5 strengths for your SAT report per element (e.g. NQA1.1.1) as part of the government requirements.`}</span>
                                  </div>
                                  <div
                                    className={`${styles.toolTip} absolute right-0`}
                                  ></div>
                                </div>
                              }
                            >
                              <FaExclamationCircle
                                className={`mx-2 cursor-pointer ${styles.infoSat}`}
                              />
                            </Popover>
                            <button
                              onClick={() => {
                                setOpenSatDrawer(true);
                              }}
                              className={styles.infoSatButton}
                            >
                              View SAT submission
                            </button>
                          </div>
                        ) : (
                          <div className={`flex justify-end items-center`}>
                            <text>
                              {5 -
                                satQuestions.filter(
                                  (question) =>
                                    question.label === selectedNQASubLevel.label
                                ).length}{" "}
                              of 5 slots remaining
                            </text>
                            <Popover
                              placement={`topRight`}
                              content={
                                <div
                                  className={`${styles.satToolTip} flex flex-col items-start relative`}
                                >
                                  <div>
                                    <span
                                      className={`font-medium`}
                                    >{`What's this? `}</span>
                                    <span>
                                      {`Adding this strength as a favourite will
                                    mean it’ll be used as part of your SAT
                                    report, ready for the government portal.`}
                                    </span>
                                  </div>
                                  <hr className={`${styles.toolTipHr} my-2`} />
                                  <div>
                                    <span
                                      className={`font-medium`}
                                    >{`Why only 5? `}</span>
                                    <span>{`You can only choose 5 strengths for your SAT report per element (e.g. NQA1.1.1) as part of the government requirements.`}</span>
                                  </div>
                                  <div
                                    className={`${styles.toolTip} absolute right-0`}
                                  ></div>
                                </div>
                              }
                            >
                              <FaExclamationCircle
                                className={`mx-2 cursor-pointer ${styles.infoSat}`}
                              />
                            </Popover>
                          </div>
                        )}
                      </div>
                    )}
                    <div className={styles.nqaQuestionCardMain}>
                      <div className={styles.nqaQuestionCardRow}>
                        <div className={styles.nqaQuestionLeftColumn}>
                          <text className={styles.nqaQuestionHeader}>
                            {questionItem.title}
                          </text>

                          <p className={styles.nqaQuestionDescription}>
                            {questionItem.description}
                          </p>
                        </div>

                        <div className={styles.nqaQuestionRightColumn}>
                          <div className={styles.nqaQuestionButtonRow}>
                            <div className={styles.nqaQuestionButtonGroup}>
                              {complyLoading &&
                              optionLoading.status === 1 &&
                              optionLoading.questionId === questionItem.id ? (
                                <div className={styles.questionButton}>
                                  {/* <img
                                    src={spinner}
                                    className={`transform scale-50`}
                                  /> */}
                                  <div
                                    className={`flex justify-center items-center`}
                                    style={{ height: "57px", width: "59px" }}
                                  >
                                    <FaSpinner
                                      className={`animate-spin text-5xl`}
                                    />
                                  </div>
                                </div>
                              ) : (
                                <Button
                                  className={
                                    questionItem.status === 1
                                      ? styles.yesButton
                                      : styles.questionButton
                                  }
                                  onClick={async () =>
                                    await setQuestionStatus(1, questionItem)
                                  }
                                  icon="check"
                                />
                              )}

                              <text className={styles.questionButtonText}>
                                Yes
                              </text>
                            </div>

                            <div className={styles.nqaQuestionButtonGroup}>
                              {complyLoading &&
                              optionLoading.status === 2 &&
                              optionLoading.questionId === questionItem.id ? (
                                <div className={styles.questionButton}>
                                  {/* <img
                                    src={spinner}
                                    className={`transform scale-50`}
                                  /> */}
                                  <div
                                    className={`flex justify-center items-center`}
                                    style={{ height: "57px", width: "59px" }}
                                  >
                                    <FaSpinner
                                      className={`animate-spin text-5xl`}
                                    />
                                  </div>
                                </div>
                              ) : (
                                <Button
                                  className={
                                    questionItem.status === 2
                                      ? styles.noButton
                                      : styles.questionButton
                                  }
                                  onClick={async () => {
                                    checkConfirmSatRemoval(questionItem);
                                  }}
                                  // await setQuestionStatus(2, questionItem)

                                  icon="close"
                                />
                              )}

                              <text className={styles.questionButtonText}>
                                No
                              </text>
                            </div>

                            <div className={styles.nqaQuestionButtonGroup}>
                              {complyLoading &&
                              optionLoading.status === 3 &&
                              optionLoading.questionId === questionItem.id ? (
                                <div className={styles.questionButton}>
                                  {/* <img
                                    src={spinner}
                                    className={`transform scale-50`}
                                  /> */}
                                  <div
                                    className={`flex justify-center items-center`}
                                    style={{ height: "57px", width: "59px" }}
                                  >
                                    <FaSpinner
                                      className={`animate-spin text-5xl`}
                                    />
                                  </div>
                                </div>
                              ) : (
                                <Button
                                  className={
                                    questionItem.status === 3
                                      ? styles.notApplicableButton
                                      : styles.questionButton
                                  }
                                  onClick={async () =>
                                    await setQuestionStatus(3, questionItem)
                                  }
                                  icon="minus"
                                />
                              )}

                              <text className={styles.questionButtonText}>
                                n/a
                              </text>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className={styles.nqaQuestionCardRow}>
                        <div className={styles.nqaQuestionLeftColumn}>
                          {questionItem.status !== 0 &&
                            renderQuestionStatusHelpText(
                              questionItem.status,
                              questionItem.evidence.evidenceDocs.length != 0,
                              questionItem.strategy.goalStrategy,
                              questionItem.strategy.self_assessment,
                              questionItem.notes
                            )}
                        </div>

                        <div className={styles.nqaQuestionRightColumn}>
                          {renderQuestionButtonAction(questionItem)}
                        </div>
                      </div>
                    </div>
                    {questionItem.status !== 0 && questionItem.status !== 3 ? (
                      <>
                        <div
                          style={{
                            borderTop: "1px solid #e4e4e4",
                            padding: "4.706vh 4.118vw 2.353vh 5.294vw",
                          }}
                        >
                          {questionItem.taskCount <= 0 ? (
                            <div className={styles.nqaQuestionCardTaskRow}>
                              <>
                                <div>
                                  <text className={styles.nqaTaskCount}>
                                    {questionItem.taskCount}
                                  </text>

                                  <text className={styles.nqaTaskText}>
                                    Tasks assigned to this question
                                  </text>
                                </div>

                                <div>
                                  <button
                                    className={styles.viewTasksButton}
                                    onClick={() =>
                                      viewTasksModal(questionItem, true)
                                    }
                                  >
                                    <Icon name="flag checkered" />
                                    {/* <text>{displayTaskText(questionItem)}</text> */}
                                    <text> Add tasks</text>
                                  </button>
                                </div>
                              </>
                            </div>
                          ) : (
                            <>
                              {questionItem.evidence.taskCount > 0 && (
                                <div
                                  className={`${styles.nqaQuestionCardTaskRow} `}
                                  style={{
                                    marginBottom:
                                      questionItem.strategy.taskCount > 0
                                        ? "2.343vh"
                                        : "0",
                                  }}
                                >
                                  <div>
                                    <text className={styles.nqaTaskCount}>
                                      {questionItem.evidence.taskCount}
                                    </text>

                                    <text className={styles.nqaTaskText}>
                                      Evidence task(s) assigned to this question
                                    </text>
                                  </div>
                                  <div>
                                    <button
                                      className={styles.viewTasksButton}
                                      onClick={() =>
                                        manualViewTasksModal(
                                          questionItem,
                                          1,
                                          true
                                        )
                                      }
                                    >
                                      {/* <Icon name="flag checkered" /> */}
                                      {/* <text>{displayTaskText(questionItem)}</text> */}
                                      <text>View evidence tasks </text>
                                    </button>
                                  </div>
                                </div>
                              )}

                              {questionItem.strategy.taskCount > 0 && (
                                <div className={styles.nqaQuestionCardTaskRow}>
                                  <div>
                                    <text className={styles.nqaTaskCount}>
                                      {questionItem.strategy.taskCount}
                                    </text>

                                    <text className={styles.nqaTaskText}>
                                      Goal task(s) assigned to this question
                                    </text>
                                  </div>
                                  <div>
                                    <button
                                      className={styles.viewTasksButton}
                                      onClick={() =>
                                        manualViewTasksModal(
                                          questionItem,
                                          2,
                                          true
                                        )
                                      }
                                    >
                                      {/* <Icon name="flag checkered" /> */}
                                      {/* <text>{displayTaskText(questionItem)}</text> */}
                                      <text>View goal tasks</text>
                                    </button>
                                  </div>
                                </div>
                              )}
                            </>
                          )}
                        </div>
                      </>
                    ) : null}
                  </div>
                ))}
              <text className={`${styles.customHeaderText} block mb-9 mt-8`}>
                Custom created goals & strengths
              </text>

              {questionItems
                .filter((question) => {
                  return question.archive === 0;
                })
                .filter((question) => {
                  return question.centerId === centerId;
                })
                .filter((question) => nqaQuestionFilter(question.status, true))
                .map((questionItem) => (
                  <div
                    ref={(cursorTaskQ) => {
                      if (
                        cursorTaskQ &&
                        levelInfo.navigate &&
                        levelInfo.qId === questionItem.id
                      ) {
                        cursorTaskQ.scrollIntoView({
                          behavior: "smooth",
                          block: "center",
                        });

                        if (levelInfo.navigate) {
                          setTimeout(resetLevelInfo, 1000);
                          // resetLevelInfo();
                        }
                      }
                    }}
                    key={questionItem.id}
                    className={styles.nqaQuestionCard}
                  >
                    {questionItem.status === 1 && (
                      <div
                        className={`${styles.satQuestion} px-4 flex justify-between items-center`}
                      >
                        <div className={`flex justify-start items-center`}>
                          <Checkbox
                            loading={
                              satLoading && selectedForSat === questionItem.id
                            }
                            disabled={satLoading}
                            checked={satQuestions.some(
                              (question) =>
                                question.question_id === questionItem.id
                            )}
                            onToggle={async (value) => {
                              if (
                                !value &&
                                satQuestions.filter(
                                  (question) =>
                                    question.label === selectedNQASubLevel.label
                                ).length < 5
                              ) {
                                setSelectedForSat(questionItem.id);
                                await onAddQuestionToSat(questionItem.id);
                              }

                              if (
                                !value &&
                                satQuestions.filter(
                                  (question) =>
                                    question.label === selectedNQASubLevel.label
                                ).length >= 5
                              ) {
                                setWarningLimitModal(true);
                              }

                              if (value) {
                                setSelectedForSat(questionItem.id);
                                await onRemoveQuestionFromSat(questionItem.id);
                              }
                            }}
                            style={{
                              width: "35px",
                              height: "35px",
                            }}
                          />
                          <text className={`${styles.satLeftText} ml-2`}>
                            {satQuestions.some(
                              (question) =>
                                question.question_id === questionItem.id
                            )
                              ? `This question has been added to SAT`
                              : `Add this question to SAT Report`}
                          </text>
                        </div>

                        {satQuestions.some(
                          (question) => question.question_id === questionItem.id
                        ) ? (
                          <div className={`flex justify-end items-center`}>
                            <text>
                              {`
                                ${
                                  satQuestions.filter(
                                    (question) =>
                                      question.label ===
                                      selectedNQASubLevel.label
                                  ).length
                                }
                                of 5 SAT slots used`}
                            </text>
                            <Popover
                              placement={`topRight`}
                              content={
                                <div
                                  className={`${styles.satToolTip} flex flex-col items-start relative`}
                                >
                                  <div>
                                    <span
                                      className={`font-medium`}
                                    >{`What's this? `}</span>
                                    <span>
                                      {`Adding this strength as a favourite will
                                    mean it’ll be used as part of your SAT
                                    report, ready for the government portal.`}
                                    </span>
                                  </div>
                                  <hr className={`${styles.toolTipHr} my-2`} />
                                  <div>
                                    <span
                                      className={`font-medium`}
                                    >{`Why only 5? `}</span>
                                    <span>{`You can only choose 5 strengths for your SAT report per element (e.g. NQA1.1.1) as part of the government requirements.`}</span>
                                  </div>
                                  <div
                                    className={`${styles.toolTip} absolute right-0`}
                                  ></div>
                                </div>
                              }
                            >
                              <FaExclamationCircle
                                className={`mx-2 cursor-pointer ${styles.infoSat}`}
                              />
                            </Popover>
                            <button
                              onClick={() => {
                                setOpenSatDrawer(true);
                              }}
                              className={styles.infoSatButton}
                            >
                              View SAT submission
                            </button>
                          </div>
                        ) : (
                          <div className={`flex justify-end items-center`}>
                            <text>
                              {5 -
                                satQuestions.filter(
                                  (question) =>
                                    question.label === selectedNQASubLevel.label
                                ).length}{" "}
                              of 5 slots remaining
                            </text>
                            <Popover
                              placement={`topRight`}
                              content={
                                <div
                                  className={`${styles.satToolTip} flex flex-col items-start relative`}
                                >
                                  <div>
                                    <span
                                      className={`font-medium`}
                                    >{`What's this? `}</span>
                                    <span>
                                      {`Adding this strength as a favourite will
                                    mean it’ll be used as part of your SAT
                                    report, ready for the government portal.`}
                                    </span>
                                  </div>
                                  <hr className={`${styles.toolTipHr} my-2`} />
                                  <div>
                                    <span
                                      className={`font-medium`}
                                    >{`Why only 5? `}</span>
                                    <span>{`You can only choose 5 strengths for your SAT report per element (e.g. NQA1.1.1) as part of the government requirements.`}</span>
                                  </div>
                                  <div
                                    className={`${styles.toolTip} absolute right-0`}
                                  ></div>
                                </div>
                              }
                            >
                              <FaExclamationCircle
                                className={`mx-2 cursor-pointer ${styles.infoSat}`}
                              />
                            </Popover>
                          </div>
                        )}
                      </div>
                    )}
                    <div className={styles.nqaQuestionCardMain}>
                      <div className={styles.nqaQuestionCardRow}>
                        <div className={styles.nqaQuestionLeftColumn}>
                          <text className={styles.nqaQuestionHeader}>
                            {questionItem.title}
                          </text>

                          <p className={styles.nqaQuestionDescription}>
                            {questionItem.description}
                          </p>
                        </div>

                        <div className={styles.nqaQuestionRightColumn}>
                          <div className={styles.nqaCustomQuestionButtonRow}>
                            <div
                              className={styles.nqaQuestionButtonGroup}
                              style={{ marginRight: "2.5rem" }}
                            >
                              {complyLoading &&
                              optionLoading.status === 1 &&
                              optionLoading.questionId === questionItem.id ? (
                                <div className={styles.questionButton}>
                                  {/* <img
                                    src={spinner}
                                    className={`transform scale-50`}
                                  /> */}
                                  <div
                                    className={`flex justify-center items-center`}
                                    style={{ height: "57px", width: "59px" }}
                                  >
                                    <FaSpinner
                                      className={`animate-spin text-5xl`}
                                    />
                                  </div>
                                </div>
                              ) : (
                                <Button
                                  className={
                                    questionItem.status === 1
                                      ? styles.yesButton
                                      : styles.questionButton
                                  }
                                  onClick={async () =>
                                    await setQuestionStatus(1, questionItem)
                                  }
                                  icon="check"
                                />
                              )}

                              <text className={styles.questionButtonText}>
                                Yes
                              </text>
                            </div>

                            <div className={styles.nqaQuestionButtonGroup}>
                              {complyLoading &&
                              optionLoading.status === 2 &&
                              optionLoading.questionId === questionItem.id ? (
                                <div className={styles.questionButton}>
                                  {/* <img
                                    src={spinner}
                                    className={`transform scale-50`}
                                  /> */}
                                  <div
                                    className={`flex justify-center items-center`}
                                    style={{ height: "57px", width: "59px" }}
                                  >
                                    <FaSpinner
                                      className={`animate-spin text-5xl`}
                                    />
                                  </div>
                                </div>
                              ) : (
                                <Button
                                  className={
                                    questionItem.status === 2
                                      ? styles.noButton
                                      : styles.questionButton
                                  }
                                  onClick={
                                    async () =>
                                      checkConfirmSatRemoval(questionItem)
                                    // await setQuestionStatus(2, questionItem)
                                  }
                                  icon="close"
                                />
                              )}

                              <text className={styles.questionButtonText}>
                                No
                              </text>
                            </div>

                            {/* <div className={styles.nqaQuestionButtonGroup}>
                              {complyLoading &&
                              optionLoading.status === 3 &&
                              optionLoading.questionId === questionItem.id ? (
                                <div className={styles.questionButton}>
                                  <img
                                    src={spinner}
                                    className={`transform scale-50`}
                                  />
                                </div>
                              ) : (
                                <Button
                                  className={
                                    questionItem.status === 3
                                      ? styles.notApplicableButton
                                      : styles.questionButton
                                  }
                                  onClick={async () =>
                                    await setQuestionStatus(3, questionItem)
                                  }
                                  icon="minus"
                                />
                              )}

                              <text className={styles.questionButtonText}>
                                n/a
                              </text>
                            </div> */}
                          </div>
                        </div>
                      </div>

                      <div className={styles.nqaQuestionCardRow}>
                        <div className={styles.nqaQuestionLeftColumn}>
                          {questionItem.status !== 0 &&
                            renderQuestionStatusHelpText(
                              questionItem.status,
                              questionItem.evidence.evidenceDocs.length != 0,
                              questionItem.strategy.goalStrategy,
                              questionItem.strategy.self_assessment,
                              questionItem.notes
                            )}
                        </div>

                        <div className={styles.nqaQuestionRightColumn}>
                          {renderQuestionButtonAction(questionItem, _, true)}
                        </div>
                      </div>
                    </div>

                    {questionItem.status !== 0 && questionItem.status !== 3 && (
                      <>
                        <div
                          style={{
                            borderTop: "1px solid #e4e4e4",
                            padding: "4.706vh 4.118vw 2.353vh 5.294vw",
                          }}
                        >
                          {questionItem.taskCount <= 0 ? (
                            <div className={styles.nqaQuestionCardTaskRow}>
                              <>
                                <div>
                                  <text className={styles.nqaTaskCount}>
                                    {questionItem.taskCount}
                                  </text>

                                  <text className={styles.nqaTaskText}>
                                    Tasks assigned to this question
                                  </text>
                                </div>

                                <div>
                                  <button
                                    className={styles.viewTasksButton}
                                    onClick={() =>
                                      viewTasksModal(questionItem, true)
                                    }
                                  >
                                    <Icon name="flag checkered" />
                                    {/* <text>{displayTaskText(questionItem)}</text> */}
                                    <text> Add tasks</text>
                                  </button>
                                </div>
                              </>
                            </div>
                          ) : (
                            <>
                              {questionItem.evidence.taskCount > 0 && (
                                <div
                                  className={`${styles.nqaQuestionCardTaskRow} `}
                                  style={{
                                    marginBottom:
                                      questionItem.strategy.taskCount > 0
                                        ? "2.343vh"
                                        : "0",
                                  }}
                                >
                                  <div>
                                    <text className={styles.nqaTaskCount}>
                                      {questionItem.evidence.taskCount}
                                    </text>

                                    <text className={styles.nqaTaskText}>
                                      Evidence task(s) assigned to this question
                                    </text>
                                  </div>
                                  <div>
                                    <button
                                      className={styles.viewTasksButton}
                                      onClick={() =>
                                        manualViewTasksModal(
                                          questionItem,
                                          1,
                                          true
                                        )
                                      }
                                    >
                                      {/* <Icon name="flag checkered" /> */}
                                      {/* <text>{displayTaskText(questionItem)}</text> */}
                                      <text>View evidence tasks </text>
                                    </button>
                                  </div>
                                </div>
                              )}

                              {questionItem.strategy.taskCount > 0 && (
                                <div className={styles.nqaQuestionCardTaskRow}>
                                  <div>
                                    <text className={styles.nqaTaskCount}>
                                      {questionItem.strategy.taskCount}
                                    </text>

                                    <text className={styles.nqaTaskText}>
                                      Goal task(s) assigned to this question
                                    </text>
                                  </div>
                                  <div>
                                    <button
                                      className={styles.viewTasksButton}
                                      onClick={() =>
                                        manualViewTasksModal(
                                          questionItem,
                                          2,
                                          true
                                        )
                                      }
                                    >
                                      {/* <Icon name="flag checkered" /> */}
                                      {/* <text>{displayTaskText(questionItem)}</text> */}
                                      <text>View goal tasks</text>
                                    </button>
                                  </div>
                                </div>
                              )}
                            </>
                          )}
                        </div>
                      </>
                    )}
                    <div
                      className={`${styles.customFilterContainer} flex flex-wrap items-center justify-between p-4 `}
                    >
                      <div className={`${styles.btnGroup} flex`}>
                        <button
                          className={`flex items-center mr-4`}
                          onClick={() => onEditCustomQuestion(questionItem)}
                        >
                          <FaPencilAlt className={`mr-3`} size={15} />
                          Edit this
                        </button>

                        <Popconfirm
                          placement={`rightBottom`}
                          title={
                            <div className={`w-52`}>
                              Are you sure you want to delete this question?
                            </div>
                          }
                          okText={`Yes`}
                          cancelText={`No`}
                          onConfirm={() => onDeleteCustomQuestion(questionItem)}
                        >
                          <button className={`flex items-center`}>
                            <FaTimes className={`mr-3`} size={15} />
                            Remove
                          </button>
                        </Popconfirm>
                      </div>

                      <PostedByUser user_id={questionItem.userId} />
                    </div>
                  </div>
                ))}
            </div>

            <button
              className={`${styles.addCustomGoalsEvidences} flex items-center justify-center p-9 mt-7 `}
              onClick={onCreateCustomQuestion}
            >
              <FaPlusCircle className={`mr-3`} size={15} />
              Add your custom goals or strengths
            </button>

            {/* archived questions */}
            {questionItems
              .filter((question) => {
                return question.archive === 1;
              })
              .filter((question) => {
                return (
                  (question.centerId ? false : true) ||
                  question.centerId === centerId
                );
              }).length > 0 && (
              <div
                className={`${styles.archiveInfoGroup} flex justify-start items-center py-8 border-t-2 px-2 `}
              >
                <Tooltip
                  color={`#F56666`}
                  placement={`topLeft`}
                  overlay={
                    <div
                      style={{
                        backgroundColor: "#F56666",
                        height: "auto",
                      }}
                      className={`${styles.toolTipCard} p-3`}
                    >
                      <span className={`font-medium `}>{`What's this? `}</span>
                      <span>{`This question will no longer be added to your progress, but you can continue to use it to manage your goals and strengths.`}</span>
                    </div>
                  }
                >
                  <FaExclamationCircle
                    className={`${styles.archiveInfo} cursor-pointer`}
                  />
                </Tooltip>
                <text className={`${styles.archiveText} ml-2`}>
                  Archived questions{" "}
                </text>
              </div>
            )}
            {questionItems
              .filter((question) => {
                return question.archive === 1;
              })
              .filter((question) => {
                return (
                  (question.centerId ? false : true) ||
                  question.centerId === centerId
                );
              })
              .filter((question) => nqaQuestionFilter(question.status, false))
              .map((questionItem) => (
                <div
                  ref={(cursorTaskQ) => {
                    if (
                      cursorTaskQ &&
                      levelInfo.navigate &&
                      levelInfo.qId === questionItem.id
                    ) {
                      cursorTaskQ.scrollIntoView({
                        behavior: "smooth",
                        block: "center",
                      });

                      if (levelInfo.navigate) {
                        setTimeout(resetLevelInfo, 1000);
                        // resetLevelInfo();
                      }
                    }
                  }}
                  key={questionItem.id}
                  className={styles.nqaQuestionCard}
                >
                  <div className={styles.nqaQuestionCardMain}>
                    <div className={styles.nqaQuestionCardRow}>
                      <div className={styles.nqaQuestionLeftColumn}>
                        <text className={styles.nqaQuestionHeader}>
                          {questionItem.title}
                        </text>

                        <p className={styles.nqaQuestionDescription}>
                          {questionItem.description}
                        </p>
                      </div>

                      <div className={styles.nqaQuestionRightColumn}>
                        <div className={styles.nqaQuestionButtonRow}>
                          <div className={styles.nqaQuestionButtonGroup}>
                            {complyLoading &&
                            optionLoading.status === 1 &&
                            optionLoading.questionId === questionItem.id ? (
                              <div className={styles.questionButton}>
                                {/* <img
                                  src={spinner}
                                  className={`transform scale-50`}
                                /> */}
                                <div
                                  className={`flex justify-center items-center`}
                                  style={{ height: "57px", width: "59px" }}
                                >
                                  <FaSpinner
                                    className={`animate-spin text-5xl`}
                                  />
                                </div>
                              </div>
                            ) : (
                              <Button
                                className={
                                  questionItem.status === 1
                                    ? styles.yesButton
                                    : styles.questionButton
                                }
                                onClick={async () =>
                                  await setQuestionStatus(1, questionItem)
                                }
                                icon="check"
                              />
                            )}

                            <text className={styles.questionButtonText}>
                              Yes
                            </text>
                          </div>

                          <div className={styles.nqaQuestionButtonGroup}>
                            {complyLoading &&
                            optionLoading.status === 2 &&
                            optionLoading.questionId === questionItem.id ? (
                              <div className={styles.questionButton}>
                                {/* <img
                                  src={spinner}
                                  className={`transform scale-50`}
                                /> */}
                                <div
                                  className={`flex justify-center items-center`}
                                  style={{ height: "57px", width: "59px" }}
                                >
                                  <FaSpinner
                                    className={`animate-spin text-5xl`}
                                  />
                                </div>
                              </div>
                            ) : (
                              <Button
                                className={
                                  questionItem.status === 2
                                    ? styles.noButton
                                    : styles.questionButton
                                }
                                onClick={async () =>
                                  await setQuestionStatus(2, questionItem)
                                }
                                icon="close"
                              />
                            )}

                            <text className={styles.questionButtonText}>
                              No
                            </text>
                          </div>

                          <div className={styles.nqaQuestionButtonGroup}>
                            {complyLoading &&
                            optionLoading.status === 3 &&
                            optionLoading.questionId === questionItem.id ? (
                              <div className={styles.questionButton}>
                                {/* <img
                                  src={spinner}
                                  className={`transform scale-50`}
                                /> */}
                                <div
                                  className={`flex justify-center items-center`}
                                  style={{ height: "57px", width: "59px" }}
                                >
                                  <FaSpinner
                                    className={`animate-spin text-5xl`}
                                  />
                                </div>
                              </div>
                            ) : (
                              <Button
                                className={
                                  questionItem.status === 3
                                    ? styles.notApplicableButton
                                    : styles.questionButton
                                }
                                onClick={async () =>
                                  await setQuestionStatus(3, questionItem)
                                }
                                icon="minus"
                              />
                            )}

                            <text className={styles.questionButtonText}>
                              n/a
                            </text>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className={styles.nqaQuestionCardRow}>
                      <div className={styles.nqaQuestionLeftColumn}>
                        {questionItem.status !== 0 &&
                          renderQuestionStatusHelpText(
                            questionItem.status,
                            questionItem.evidence.evidenceDocs.length != 0,
                            questionItem.strategy.goalStrategy,
                            questionItem.strategy.self_assessment,
                            questionItem.notes
                          )}
                      </div>

                      <div className={styles.nqaQuestionRightColumn}>
                        {renderQuestionButtonAction(questionItem)}
                      </div>
                    </div>
                  </div>

                  {questionItem.status !== 0 && questionItem.status !== 3 ? (
                    <>
                      <div
                        style={{
                          borderTop: "1px solid #e4e4e4",
                          padding: "4.706vh 4.118vw 2.353vh 5.294vw",
                        }}
                      >
                        {questionItem.taskCount <= 0 ? (
                          <div className={styles.nqaQuestionCardTaskRow}>
                            <>
                              <div>
                                <text className={styles.nqaTaskCount}>
                                  {questionItem.taskCount}
                                </text>

                                <text className={styles.nqaTaskText}>
                                  Tasks assigned to this question
                                </text>
                              </div>

                              <div>
                                <button
                                  className={styles.viewTasksButton}
                                  onClick={() =>
                                    viewTasksModal(questionItem, true)
                                  }
                                >
                                  <Icon name="flag checkered" />
                                  {/* <text>{displayTaskText(questionItem)}</text> */}
                                  <text> Add tasks</text>
                                </button>
                              </div>
                            </>
                          </div>
                        ) : (
                          <>
                            {questionItem.evidence.taskCount > 0 && (
                              <div
                                className={`${styles.nqaQuestionCardTaskRow} `}
                                style={{
                                  marginBottom:
                                    questionItem.strategy.taskCount > 0
                                      ? "2.343vh"
                                      : "0",
                                }}
                              >
                                <div>
                                  <text className={styles.nqaTaskCount}>
                                    {questionItem.evidence.taskCount}
                                  </text>

                                  <text className={styles.nqaTaskText}>
                                    Evidence task(s) assigned to this question
                                  </text>
                                </div>
                                <div>
                                  <button
                                    className={styles.viewTasksButton}
                                    onClick={() =>
                                      manualViewTasksModal(
                                        questionItem,
                                        1,
                                        true
                                      )
                                    }
                                  >
                                    {/* <Icon name="flag checkered" /> */}
                                    {/* <text>{displayTaskText(questionItem)}</text> */}
                                    <text>View evidence tasks </text>
                                  </button>
                                </div>
                              </div>
                            )}

                            {questionItem.strategy.taskCount > 0 && (
                              <div className={styles.nqaQuestionCardTaskRow}>
                                <div>
                                  <text className={styles.nqaTaskCount}>
                                    {questionItem.strategy.taskCount}
                                  </text>

                                  <text className={styles.nqaTaskText}>
                                    Goal task(s) assigned to this question
                                  </text>
                                </div>
                                <div>
                                  <button
                                    className={styles.viewTasksButton}
                                    onClick={() =>
                                      manualViewTasksModal(
                                        questionItem,
                                        2,
                                        true
                                      )
                                    }
                                  >
                                    {/* <Icon name="flag checkered" /> */}
                                    {/* <text>{displayTaskText(questionItem)}</text> */}
                                    <text>View goal tasks</text>
                                  </button>
                                </div>
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    </>
                  ) : null}
                </div>
              ))}
          </>
        )}
      </div>
      {/* )} */}
    </div>
  );
}

export default NQAView;
