import * as React from "react";

import PropTypes from "prop-types";
import { Button, Modal } from "semantic-ui-react";

import BlueButton from "../../BlueButton";
import InputComponent from "../../InputComponent";
import { useStoreState } from "../../../hooks";
import styles from "./CreateNewPasswordModal.module.css";

const { useState } = React;

function CreateNewPasswordModal({
  visibility,
  onOpenModal,
  onCloseModal,
  onSavePassword,
}) {
  const [password, setpassword] = useState("");
  const [passwordConfirm, setpasswordConfirm] = useState("");
  const [hasError, setHasError] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const apiURI = process.env.REACT_APP_API_URL;
  const userId = useStoreState((state) => state.authStore.authObject.userId);

  const onSubmitPassword = async () => {
    console.log(password, passwordConfirm);
    if (password != passwordConfirm) {
      setErrMsg("Passwords do not match!");
      setHasError(true);
      return;
    }

    if (password.length < 8) {
      setErrMsg("Password must be at least 8 characters!");
      setHasError(true);
      return;
    }

    const payload = {
      user_id: userId,
      password,
    };
    const rawResponse = await fetch(
      `${apiURI}/api/notifications/reset/confirm`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      }
    );
    onSavePassword();
  };

  return (
    <Modal
      size="small"
      centered={false}
      open={visibility}
      onOpen={onOpenModal}
      onClose={onCloseModal}
    >
      <div className={styles.modalContainer}>
        <Button
          className={styles.closeButton}
          onClick={onCloseModal}
          size="big"
          icon="close"
        />

        <text className={styles.headerText}>Create a new password</text>

        <div className={styles.inputRow}>
          <InputComponent
            className={styles.inputBox}
            label="Enter new password"
            type="password"
            fieldName="password"
            inputValue={password}
            setInputValue={(f, v) => setpassword(v)}
          />

          <InputComponent
            className={styles.inputBox}
            label="Repeat new password"
            type="password"
            fieldName="passwordConfirm"
            inputValue={passwordConfirm}
            setInputValue={(f, v) => setpasswordConfirm(v)}
          />
        </div>

        <div style={!hasError ? { display: "none" } : null}>
          <span style={{ color: "red" }}>{errMsg}</span>
        </div>

        <BlueButton
          className={styles.saveButton}
          buttonText="Save password"
          onClick={onSubmitPassword}
        />
      </div>
    </Modal>
  );
}

CreateNewPasswordModal.propTypes = {
  visibility: PropTypes.bool.isRequired,
  onOpenModal: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  onSavePassword: PropTypes.func.isRequired,
};

export default CreateNewPasswordModal;
