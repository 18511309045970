import * as React from "react";

import PropTypes from "prop-types";
import { Button, Modal } from "semantic-ui-react";

import SkipButton from "../../SkipButton";
import styles from "./DeleteMessageModal.module.css";
import { FaSpinner } from "react-icons/fa";

function DeleteMessageModal({
  visibility,
  loading,
  onOpenModal,
  onCloseModal,
  onRemoveMessage,
}) {
  return (
    <Modal
      size="small"
      centered={false}
      open={visibility}
      onOpen={onOpenModal}
      onClose={onCloseModal}
      closeOnDimmerClick={false}
    >
      <div className={styles.modalContainer}>
        {loading ? (
          <div className={`flex flex-col justify-center items-center`}>
            <FaSpinner
              className={`animate-spin text-qipGreen font-bold text-6xl`}
            />

            <div>Deleting message...</div>
          </div>
        ) : (
          <>
            <Button
              className={styles.closeButton}
              onClick={onCloseModal}
              size="big"
              icon="close"
            />

            <text className={styles.headerText}>
              Are you sure you want to delete this message?
            </text>

            <div className={styles.buttonGroup}>
              <button
                className={styles.nextTipButton}
                onClick={onRemoveMessage}
              >
                Yes
              </button>

              <SkipButton onClick={onCloseModal} buttonText="No" />
            </div>
          </>
        )}
      </div>
    </Modal>
  );
}

DeleteMessageModal.propTypes = {
  visibility: PropTypes.bool.isRequired,
  onOpenModal: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  onRemoveMessage: PropTypes.func.isRequired,
};

export default DeleteMessageModal;
