import React from "react";
import { CKEditor } from "ckeditor4-react";

function CustomEditor({ initData, onSetData }) {
    return <CKEditor
            type="classic"
            initData={initData}
            onChange={({ editor }) => {
                const data = editor.getData();
                onSetData(data);
            }}
            config={{ versionCheck: false }}
        />;
}

export default CustomEditor;