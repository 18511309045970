import * as React from "react";

import { useHistory } from "react-router-dom";

import { Icon } from "semantic-ui-react";
import {
  FaRocket,
  FaRegArrowAltCircleDown,
  FaDollarSign,
  FaArrowRight,
  FaTimes,
  FaRedo,
  FaSpinner,
} from "react-icons/fa";

import styles from "./SubscriptionDetails.module.css";

import BlueButton from "../../../components/BlueButton";
import SkipButton from "../../../components/SkipButton";
import NewServicesModal from "../../../components/Modals/NewServicesModal";
import ChangeSubscriptionModal from "../../../components/Modals/ChangeSubscriptionModal";
import CancelSubscriptionModal from "../../../components/Modals/CancelSubscriptionModal";
import ConfirmCancelSubscriptionModal from "../../../components/Modals/ConfirmCancelSubscriptionModal";
import ConfirmReactivateSubscriptionModal from "../../../components/Modals/ConfirmReactivateSubscriptionModal";
import NoticeCancelSubscriptionModal from "../../../components/Modals/NoticeCancelSubscriptionModal";
import NoticeReactivateSubscriptionModal from "../../../components/Modals/NoticeReactivateSubscriptionModal";
import { useStoreActions, useStoreState } from "../../../hooks";
import useSWR, { mutate } from "swr";

import { getUserProducts } from "../../../api/requests";
import moment from "moment";
import { isEmpty } from "lodash";

const { useState, useEffect, useCallback } = React;

const dummysubscription = [
  {
    subsId: 1,
    subsName: "Little Smarties",
    substType: "QIP",
    cost: "$97",
    btnsubstType: "Uprade",
    btnsubsLink: "upgrade-subscription",
    btnsubsIcon: "rocket",
  },
  {
    subsId: 2,
    subsName: "Little Smarties at ST Claire",
    substType: "QIP Plus",
    cost: "$197",
    btnsubsIcon: "arrow alternate circle down outline",
    btnsubsLink: "downgrade-subscription",
    btnsubstType: "Downgrade",
  },
  {
    subsId: 3,
    subsName: "Little Smarties Brisbane",
    substType: "QIP",
    cost: "$97",
    btnsubsIcon: "rocket",
    btnsubsLink: "upgrade-subscription",
    btnsubstType: "Uprade",
  },
];

const getPrice = (planChoice, planSubscription) => {
  if (planChoice === "QIP Portal") {
    if (planSubscription === "monthly") {
      return `$97`;
    } else {
      return `$997`;
    }
  } else {
    if (planSubscription === "monthly") {
      return `$197`;
    } else {
      return `$1997`;
    }
  }
};

function SubscriptionDetails() {
  const history = useHistory();
  const [services] = useState(3);

  const userId = useStoreState((state) => state.authStore.authObject.userId);

  const [fetchLimitError, setFetchLimitError] = useState("");

  const authObj = useStoreState((state) => state.authStore.authObject);

  const remainingTrial = useStoreState(
    (state) => state.userStore.remainingTrial
  );

  const userServices = useStoreState((state) => state.authStore.services);

  const selectedService = useStoreState(
    (state) => state.authStore.selectedService
  );

  const setSelectedService = useStoreActions(
    (actions) => actions.authStore.setSelectedService
  );

  const setDisableNavBar = useStoreActions(
    (actions) => actions.appStore.setDisableMenu
  );

  const [product, setProduct] = useState("");
  // const [products, setProducts] = useState([]);

  const [subInfo, setSubInfo] = useState({});

  const [loading, setLoading] = useState(false);

  const hasPaymentMethods = useStoreState(
    (state) => state.userStore.hasPaymentMethods
  );

  const paymentMethods = useStoreState(
    (state) => state.userStore.paymentMethods
  );

  const [newServicesModalVisibility, setNewServicesModalVisibility] =
    useState(false);
  const [
    changeSubscriptionModalVisibility,
    setChangeSubscriptionModalVisibility,
  ] = useState(false);

  const [
    cancelSubscriptionModalVisibility,
    setCancelSubscriptionModalVisibility,
  ] = useState(false);
  const [
    confirmCancelSubscriptionModalVisibility,
    setConfirmCancelSubscriptionModalVisibility,
  ] = useState(false);

  const [
    confirmReactivateSubscriptionModalVisibility,
    setConfirmReactivateSubscriptionModalVisibility,
  ] = useState(false);

  const [
    noticeCancelSubscriptionModalVisibility,
    setNoticeCancelSubscriptionModalVisibility,
  ] = useState(false);

  const [
    noticeReactivateSubscriptionModalVisibility,
    setNoticeReactivateSubscriptionModalVisibility,
  ] = useState(false);

  const onConfirmCancel = () => {
    setCancelSubscriptionModalVisibility(false);
    setConfirmCancelSubscriptionModalVisibility(true);
  };

  const onNoticeCancel = () => {
    setConfirmCancelSubscriptionModalVisibility(false);
    setNoticeCancelSubscriptionModalVisibility(true);
  };

  const onNoticeReactivate = () => {
    setConfirmReactivateSubscriptionModalVisibility(false);
    setNoticeReactivateSubscriptionModalVisibility(true);
  };

  const { data: products, isValidating } = useSWR(
    ["/products", userId, selectedService.id],
    (_, userId, selectedService) => getUserProducts(userId, selectedService),
    {
      onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
        // Only retry up to 3 times.
        if (retryCount && retryCount >= 3) {
          if (!products) setFetchLimitError(true);

          return;
        }
        // Retry after 3 seconds.
        setTimeout(() => revalidate({ retryCount: retryCount + 1 }), 3000);
      },
    }
  );

  // const fetchUserProducts = useCallback(async () => {
  //   setLoading(true);
  //   try {
  //     const response = await getUserProducts(userId, selectedService.id);
  //     if (response.products) {
  //       setProducts(response.products);
  //     } else {
  //       setProducts([]);
  //     }
  //     setLoading(false);
  //   } catch (error) {
  //     setLoading(false);
  //     console.log(error);
  //   }
  // }, []);

  // useEffect(() => {
  //   fetchUserProducts();
  // }, []);

  return (
    <>
      <div className={`${styles.mainContainer} w-full`}>
        {cancelSubscriptionModalVisibility && (
          <CancelSubscriptionModal
            visibility={cancelSubscriptionModalVisibility}
            onOpenModal={() => setCancelSubscriptionModalVisibility(true)}
            onCloseModal={() => setCancelSubscriptionModalVisibility(false)}
            onCancel={onConfirmCancel}
          />
        )}
        {confirmCancelSubscriptionModalVisibility && (
          <ConfirmCancelSubscriptionModal
            visibility={confirmCancelSubscriptionModalVisibility}
            subInfo={subInfo}
            onOpenModal={() =>
              setConfirmCancelSubscriptionModalVisibility(true)
            }
            onCloseModal={() =>
              setConfirmCancelSubscriptionModalVisibility(false)
            }
            onNotice={onNoticeCancel}
          />
        )}

        {confirmReactivateSubscriptionModalVisibility && (
          <ConfirmReactivateSubscriptionModal
            visibility={confirmReactivateSubscriptionModalVisibility}
            subInfo={subInfo}
            onOpenModal={() =>
              setConfirmReactivateSubscriptionModalVisibility(true)
            }
            onCloseModal={() =>
              setConfirmReactivateSubscriptionModalVisibility(false)
            }
            onNotice={onNoticeReactivate}
          />
        )}

        {noticeCancelSubscriptionModalVisibility && (
          <NoticeCancelSubscriptionModal
            visibility={noticeCancelSubscriptionModalVisibility}
            onOpenModal={() => setNoticeCancelSubscriptionModalVisibility(true)}
            onCloseModal={async () => {
              mutate(["/products", userId, selectedService.id]);
              setNoticeCancelSubscriptionModalVisibility(false);
            }}
          />
        )}

        {noticeReactivateSubscriptionModalVisibility && (
          <NoticeReactivateSubscriptionModal
            visibility={noticeReactivateSubscriptionModalVisibility}
            onOpenModal={() =>
              setNoticeReactivateSubscriptionModalVisibility(true)
            }
            onCloseModal={async () => {
              mutate(["/products", userId, selectedService.id]);
              setNoticeReactivateSubscriptionModalVisibility(false);
            }}
          />
        )}

        {/* <div className={`${styles.subscriptionServicesDetailsColumn} hidden`}> */}

        <div className={`hidden`}>
          {newServicesModalVisibility && (
            <NewServicesModal
              visibility={newServicesModalVisibility}
              onOpenModal={() => setNewServicesModalVisibility(true)}
              onCloseModal={() => setNewServicesModalVisibility(false)}
            />
          )}
          {changeSubscriptionModalVisibility && (
            <ChangeSubscriptionModal
              visibility={changeSubscriptionModalVisibility}
              onOpenModal={() => setChangeSubscriptionModalVisibility(true)}
              onCloseModal={() => setChangeSubscriptionModalVisibility(false)}
            />
          )}

          <text className={styles.headerText}>
            Subscription & payment details
          </text>

          <div className={styles.subscriptionServicesDetails}>
            <div className={styles.subscriptionrow}>
              <div className={styles.subscriptionlabel}>
                <text className={styles.subsdetailsText}>
                  Subscription amount:
                </text>
                <text className={styles.subsdetailsText}>Next payment</text>
              </div>
              <div className={styles.subscriptiondata}>
                <text className={styles.subsdetailsText}>
                  {getPrice(product, "monthly")}
                </text>
                <text className={styles.subsdetailsText}>12/10/2020</text>
              </div>
            </div>
          </div>

          <text className={styles.headerText}>Update payment frequency</text>
          <div className={styles.buttonColumn}>
            <button className={styles.monthlyPlan}>
              <Icon className={styles.checkIcon} name="check" size="small" />
              Monthly plan
            </button>

            <button className={styles.yearlyPlan}>Yearly plan</button>
          </div>
          <div className={`${styles.cancelsubsGroup}`}>
            <div
              className={`p-3 rounded-full text-white flex justify-center items-center`}
              style={{ backgroundColor: "#f56666" }}
            >
              <FaArrowRight style={{ fontSize: "18px" }} />
            </div>
            <span
              className={`${styles.cancelsubsText} cursor-pointer`}
              onClick={setCancelSubscriptionModalVisibility}
            >
              Cancel subscription
            </span>
          </div>
        </div>
        {isValidating && (
          <div className={`py-14 flex flex-col justify-center items-center`}>
            <FaSpinner
              className={`animate-spin text-qipGreen font-bold text-6xl`}
            />
            <span className={`py-2`}>Loading Subscriptions...</span>
          </div>
        )}
        {!isValidating && (
          <div className={`{styles.subscriptionServicesDetailsColumn} w-full`}>
            {!isEmpty(authObj.stripeId) ? (
              <text className={styles.headerText}>
                {!isEmpty(products)
                  ? products.filter(
                      (subs) => subs.subscription.status !== "canceled"
                    ).length > 1
                    ? `${
                        products.filter(
                          (subs) => subs.subscription.status !== "canceled"
                        ).length
                      } Services on this subscription`
                    : `${
                        products.filter(
                          (subs) => subs.subscription.status !== "canceled"
                        ).length
                      } Service on this subscription`
                  : ""}
              </text>
            ) : (
              <text className={styles.headerText}>
                1 Service on this subscription
              </text>
            )}
            {console.log("products ", products)}
            <div className={styles.servicesContainer}>
              {!isEmpty(authObj.stripeId) &&
                products &&
                products
                  .filter((subs) => subs.subscription.status !== "canceled")
                  .map((subs, i) => (
                    <div key={i} className={styles.services}>
                      <div className={styles.servicesDetailsGroup}>
                        <text className={styles.serviceName}>
                          {subs.center_name}
                        </text>

                        <div className={styles.subtext}>
                          <text className={styles.subsType}>
                            <span className={styles.serviceLabel}>
                              Subscription type:
                            </span>{" "}
                            {subs.product}
                          </text>

                          <div className={`flex justify-start`}>
                            <text className={styles.subsCost}>
                              <span className={styles.serviceLabel}>Cost:</span>
                              {` $${subs.subscription.plan.amount / 100}`}/
                              {subs.subscription.plan.interval}
                            </text>

                            {/* {!subs.subscription.cancel_at_period_end ? ( */}

                            {subs.subscription.status === "incomplete" ||
                            subs.subscription.status === "incomplete_expired" ||
                            subs.subscription.status === "past_due" ||
                            subs.subscription.status === "unpaid" ? (
                              <text
                                className={`${styles.subsCostFailed} text-red-400 ml-8`}
                              >
                                <span
                                  className={`${styles.serviceLabel1} text-red-400`}
                                >
                                  Payment failed:
                                </span>{" "}
                                {moment
                                  .unix(subs.subscription.current_period_end)
                                  .format("DD/MM/YYYY")}
                              </text>
                            ) : subs.subscription.status === "canceled" ? (
                              <text className={`${styles.subsCost} ml-8`}>
                                <span
                                  className={`${styles.serviceLabel1} text-red-400`}
                                >
                                  Canceled:
                                </span>{" "}
                                {moment
                                  .unix(subs.subscription.canceled_at)
                                  .format("DD/MM/YYYY")}
                              </text>
                            ) : (
                              <text className={`${styles.subsCost} ml-8`}>
                                <span className={styles.serviceLabel}>
                                  Next payment:
                                </span>{" "}
                                {moment
                                  .unix(subs.subscription.current_period_end)
                                  .format("DD/MM/YYYY")}
                              </text>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className={styles.servicesActionsGroup}>
                        <div className={`${styles.buttonRow}`}>
                          <div className={`${styles.buttonGroup}`}>
                            {moment().diff(
                              moment.unix(subs.subscription.current_period_end),
                              "days"
                            ) >= 0 ? (
                              subs.subscription.status === "incomplete" ||
                              subs.subscription.status ===
                                "incomplete_expired" ||
                              subs.subscription.status === "past_due" ||
                              subs.subscription.status === "unpaid" ? (
                                <div
                                  onClick={() => {
                                    history.push(
                                      `/qip/user-management/billing/1`
                                    );
                                  }}
                                  className={`${styles.btnReview} flex justify-center items-center cursor-pointer rounded-md `}
                                >
                                  <FaDollarSign />
                                  <span className={`${styles.buttonText} ml-2`}>
                                    {`Review payment details`}
                                  </span>
                                </div>
                              ) : (
                                <> </>
                              )
                            ) : subs.subscription.status === "canceled" ? (
                              <> </>
                            ) : (
                              <div
                                onClick={() =>
                                  history.push(
                                    `/qip/user-management/subscription/${
                                      subs.product === "QIP Portal"
                                        ? "upgrade-subscription"
                                        : "downgrade-subscription"
                                    }`
                                  )
                                }
                                className={`${styles.btnUpgrade} flex justify-center items-center cursor-pointer rounded-md `}
                              >
                                {subs.product === `QIP Portal` ? (
                                  <FaRocket />
                                ) : (
                                  <FaRegArrowAltCircleDown />
                                )}
                                <span className={`${styles.buttonText} ml-2`}>
                                  {subs.product === "QIP Portal"
                                    ? "Upgrade"
                                    : "Downgrade"}
                                </span>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}

              {isEmpty(authObj.stripeId) && (
                <div className={styles.services}>
                  <div className={styles.servicesDetailsGroup}>
                    <text className={styles.serviceName}>
                      {userServices[0].name}
                    </text>

                    <div className={styles.subtext}>
                      <text className={styles.subsType}>
                        <span className={styles.serviceLabel}>
                          Subscription type:
                        </span>
                        {` Trial`}
                      </text>

                      <div className={`flex justify-start`}>
                        <text className={styles.subsCost}>
                          <span className={styles.serviceLabel}>Cost:</span>
                          {` 30 Day Free Trial`}
                        </text>

                        <text className={`${styles.subsCost} ml-8`}>
                          <span className={styles.serviceLabel}>
                            End of trial:
                          </span>{" "}
                          {moment()
                            .add(remainingTrial, `days`)
                            .format(`DD/MM/YYYY`)}
                        </text>
                      </div>
                    </div>
                  </div>

                  <div className={styles.servicesActionsGroup}>
                    <div className={`${styles.buttonRow}`}>
                      <div className={`${styles.buttonGroup}`}>
                        <div
                          onClick={() =>
                            history.push(`/qip/pricing-plans/`, {
                              from: "subDetails",
                            })
                          }
                          className={`${styles.btnUpgrade} flex justify-center items-center cursor-pointer rounded-md `}
                        >
                          <FaDollarSign />
                          <span className={`${styles.buttonText} ml-2`}>
                            {`Purchase`}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      {!isEmpty(authObj.stripeId) && (
        <>
          <hr
            className={`w-full border mt-10 -ml-16`}
            style={{ width: `calc(100% + 8rem)` }}
          />
          <div className={`w-full flex justify-between items-center mt-10`}>
            <div className={`w-2/5 `}>
              <text
                className={`${styles.serviceName} `}
              >{`Add a new service`}</text>
              <text className={`${styles.subsType} pt-4`}>
                Add another childcare center to your current Holistic QIP
                subscription.{" "}
              </text>
            </div>
            <BlueButton
              onClick={() => {
                if (hasPaymentMethods) {
                  history.push(`/qip/pricing-plans`, {
                    forAdditionalService: true,
                    paymentMethods: paymentMethods,
                  });
                } else {
                  setNewServicesModalVisibility(true);
                }
              }}
              buttonText={`Add a new service`}
            />
          </div>
        </>
      )}
    </>
  );
}

export default SubscriptionDetails;
