import React from "react";
import { Alert } from "antd";
import { Modal } from "semantic-ui-react";
import Checkbox from "../../../components/_base/Checkbox";
import styles from "./LawsRegulationsModal.module.css";
import { FaCheck, FaTimes, FaMinus, FaSpinner } from "react-icons/fa";
import {
  getLawsRegulations,
  answerLawsRegulations,
  updateLawsRegulationsResult,
} from "../../../api/requests";
import { isEmpty } from "lodash";
import { useStoreActions, useStoreState } from "../../../hooks";

const { useState, useEffect, useCallback } = React;

const sep = "•";

const LawsRegulationsModal = ({
  lawsRegs = {},
  nqaId,
  visibility,
  onCloseModal,
  onOpenModal,
  refetchLar,
}) => {
  const [lawsRegulations, setLawsRegulations] = useState({});

  const [lawsRegResults, setLawsRegResults] = useState([]);

  const centerId = useStoreState((state) => state.nqaStore.serviceDetails.id);

  const [note, setNote] = useState("");

  const [saveLoading, setSaveLoading] = useState(false);

  const parseRowTexts = (rawtext = "") => {
    const text = rawtext.replace(/(\r\n|\n|\r)/gm, " ").replace(";", ":");
    let els = [];
    if (text.includes(sep)) {
      const group = text.split(":");
      let html = "";
      group.forEach((g) => {
        let lis = "";
        let span = "";
        if (g.includes(sep)) {
          const subGroup = g.split(sep);

          let subSpan = "";
          subGroup.forEach((sub) => {
            if (sub.trim().length !== 0) {
              const subSub = sub.split("?");
              if (subSub.length > 1 && subSub[1].trim().length > 5) {
                subSpan = `<span>${subSub[1].trim()}:</span>`;

                lis = lis + `<li>${subSub[0].trim()}?</li>`;
              } else {
                lis = lis + `<li>${sub}</li>`;
              }
            }
          });

          lis = `<ul> ${lis} </ul>`;
          html = html + lis + subSpan;
        } else {
          span = `<span>${g.trim()}:</span>`;
          html = html + span + lis;
        }
      });

      return html;
    } else {
      return text;
    }
  };

  const fetchLawsRegulations = useCallback(async () => {
    try {
      setLawsRegulations(lawsRegs);

      let larResults = [];
      setNote(lawsRegs.note);
      lawsRegs.lawsRegs.forEach((law) => {
        if (law.hasResult) {
          larResults = [
            ...larResults,
            {
              lar_id: law.id,
              result: law.result,
            },
          ];
        }
      });

      setLawsRegResults([...larResults]);
    } catch (error) {
      console.log(error);
    }
  }, [nqaId]);

  const toggleCheck = (value, lawId) => {
    if (lawsRegResults.some((res) => res.lar_id === lawId)) {
      setLawsRegResults([
        ...lawsRegResults.map((res) => {
          if (res.lar_id === lawId) {
            return {
              lar_id: res.lar_id,
              result: value,
            };
          } else {
            return res;
          }
        }),
      ]);
    } else {
      setLawsRegResults([
        ...lawsRegResults,
        {
          lar_id: lawId,
          result: value,
        },
      ]);
    }
  };

  const ComplianceOptions = ({
    law,
    lawsRegResults = [],
    toggleCheck = () => null,
  }) => {
    const isChecked = (value) => {
      return lawsRegResults.some((res) => res.lar_id === law.id) &&
        lawsRegResults.filter((res) => res.lar_id === law.id)[0].result ===
          value
        ? true
        : false;
    };

    return (
      <>
        <div className={`flex justify-start items-center`}>
          <Checkbox
            checked={isChecked(1)}
            onToggle={(value) => {
              if (!value) {
                toggleCheck(1, law.id);
              }
            }}
            CheckIcon={FaCheck}
            style={{
              width: "30px",
              height: "30px",
              borderRadius: "9999px",
              backgroundColor: "#8BC334",
              fontSize: "15px",
              lineHeight: "25px",
              fontWeight: 900,
            }}
          />
          <text className={`ml-3 w-2/3`}>Compliant</text>
        </div>
        <div className={`flex justify-start items-center my-2`}>
          <Checkbox
            checked={isChecked(2)}
            onToggle={(value) => {
              if (!value) {
                toggleCheck(2, law.id);
              }
            }}
            CheckIcon={FaTimes}
            style={{
              width: "30px",
              height: "30px",
              borderRadius: "9999px",
              backgroundColor: "#F56666",
              fontSize: "15px",
              lineHeight: "25px",
              fontWeight: 900,
            }}
          />
          <text className={`ml-3 w-2/3`}>Non-compliant</text>
        </div>
        <div className={`flex justify-start items-center`}>
          <Checkbox
            checked={isChecked(3)}
            onToggle={(value) => {
              if (!value) {
                toggleCheck(3, law.id);
              }
            }}
            CheckIcon={FaMinus}
            style={{
              width: "30px",
              height: "30px",
              borderRadius: "9999px",
              backgroundColor: "#388CEF",
              fontSize: "15px",
              lineHeight: "25px",
              fontWeight: 900,
            }}
          />
          <text className={`ml-3 w-2/3`}>Not applicable</text>
        </div>
      </>
    );
  };

  const onAnswerLawsRegulations = async () => {
    try {
      setSaveLoading(true);
      const response = await answerLawsRegulations({
        centerId,
        nqaId,
        result: lawsRegResults,
        note,
      });
    } catch (error) {
      setSaveLoading(false);
      console.log(error);
    }
  };

  const onUpdateLawsRegulationsResult = async () => {
    try {
      setSaveLoading(true);
      const response = await updateLawsRegulationsResult(
        lawsRegulations.resultId,
        {
          centerId,
          nqaId,
          result: lawsRegResults,
          note,
        }
      );
    } catch (error) {
      setSaveLoading(false);
      console.log(error);
    }
  };

  const processLawsRegulations = async () => {
    if (lawsRegulations.hasResult) {
      await onUpdateLawsRegulationsResult();
    } else {
      await onAnswerLawsRegulations();
    }
    await refetchLar();
    setSaveLoading(false);
    onCloseModal();
  };

  useEffect(() => {
    fetchLawsRegulations();
  }, [nqaId]);

  return (
    <Modal
      open={visibility}
      centered={true}
      onClose={onCloseModal}
      onOpen={onOpenModal}
      closeOnDimmerClick={false}
      size={`large`}
    >
      <div className={`${styles.modalContainer}`}>
        <div
          onClick={onCloseModal}
          className={`${styles.closeButton} flex justify-center items-center`}
        >
          <FaTimes />{" "}
        </div>
        <div className={`flex flex-col items-center`}>
          <text className={`${styles.headerText} mt-5`}>
            {`Law and Regulations for NQA ${nqaId}`}
          </text>
          <text className={`${styles.headerSub} mt-8 mb-10`}>
            {`The table below includes the sections of the National Law (S) and
            National Regulations (R) underpinning Quality Area ${nqaId}.`}
          </text>
        </div>
        <div className={`flex justify-between items-center`}>
          <div
            className={`${styles.alertLeft} w-1/2 mr-3 flex justify-center items-center p-5`}
          >
            {`Please assess whether your service is compliant or non-compliant in accordance with the National Law and Regulations, relevant to your service type.`}
          </div>

          <div
            className={`${styles.alertRight} w-1/2 ml-3 flex justify-center items-center p-5`}
          >
            {`NOTE: If a law or regulation is not applicable to your service or service type, you are not required to select whether you are compliant or non-compliant.`}
          </div>
        </div>

        <table className={` my-14 `}>
          <thead className={`${styles.tableHead}`}>
            <tr>
              <th>Law(S) / Regulation(R)</th>
              <th>NQS</th>
              <th>Does your service meet legislative requirements?</th>
              <th>Compliant / Non-compliant</th>
            </tr>
          </thead>
          <tbody className={`${styles.tableBody}`}>
            {!isEmpty(lawsRegulations) &&
              lawsRegulations.lawsRegs.map((law) => {
                return (
                  <tr key={law.id}>
                    <td>{law.law_regulation}</td>
                    <td>{law.nqs}</td>
                    <td
                      dangerouslySetInnerHTML={{
                        __html: parseRowTexts(law.description),
                      }}
                    ></td>

                    <td>
                      <ComplianceOptions
                        law={law}
                        lawsRegResults={lawsRegResults}
                        toggleCheck={toggleCheck}
                      />
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>

        <div className={`flex flex-col items-center`}>
          <div
            className={`${styles.noticeCompliance} mb-14`}
          >{`If you answered ‘non-compliant’: you are not meeting regulatory requirements and should take immediate steps to rectify this non-compliance. Use the box below to note the relevant Law/Regulation with your action.`}</div>

          <textarea
            value={note}
            onChange={(e) => setNote(e.target.value)}
            className={`${styles.actionArea} p-4 mb-8`}
          />
          <button
            disabled={saveLoading}
            onClick={() => processLawsRegulations()}
            className={`${styles.saveButton}`}
          >
            {saveLoading ? (
              <div className={`flex justify-center items-center`}>
                {" "}
                <FaSpinner className={`animate-spin`} />{" "}
                <span className={`ml-2`}>Please wait...</span>
              </div>
            ) : (
              <span>Save changes</span>
            )}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default LawsRegulationsModal;
