import * as React from "react";

import useSWR from "swr";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import { Button, Modal, Icon, Dropdown } from "semantic-ui-react";

import styles from "./NQAEvidenceModal.module.css";
import { FaSpinner } from "react-icons/fa";
import BlueButton from "../../BlueButton";
import InputComponent from "../../InputComponent";
import ChangeStatusModal from "../ChangeStatusModal";
import {
  deleteAttachment,
  fetchCenterUsers,
  postTask,
  updateTask,
  uploadEvidence,
} from "../../../api/requests";

const { useEffect, useRef, useState } = React;

function NQAEvidenceModal({
  visibility,
  onOpenModal,
  onCloseModal,
  refreshModal,
  evidenceObject,
}) {
  const { nqaId } = useParams();

  const inputRef = useRef(null);
  const tasksRef = useRef(null);

  const [taskDetail, setTaskDetail] = useState(undefined); // taskDetail values reset when task detail view is folded
  const [tasksVisibility, setTasksVisibility] = useState(
    evidenceObject.openTasks
  );

  const [taskModalVis, setTaskModalVis] = useState(false);
  const [statusModalVis, setStatusModalVis] = useState(false);

  const [newTask, setNewTask] = useState({
    status: 1,
    taskDue: "",
  });

  // const [evidenceDocuments, setDocuments] = useState();
  const [evidenceLabel, setEvidenceLabel] = useState("");
  const [evidenceDocument, setEvidenceDocument] = useState("");

  const [postLoading, setLoading] = useState(false);
  // const [imagesLoading, setImagesLoading] = useState(true);

  // 1 - task status edit, 2 - new evidence task status edit
  const [changeStatusParent, setChangeStatusParent] = useState(1);

  const onChangeStatus = (parent) => {
    setChangeStatusParent(parent);
    setStatusModalVis(true);
  };

  const onManageNewTask = (statusField, value) => {
    setNewTask({
      ...newTask,
      [statusField]: value,
    });
  };

  const onChangeHandler = (statusField, value) => {
    setTaskDetail({
      ...taskDetail,
      [statusField]: value,
    });
  };

  const onSelectTaskItem = (task) => {
    if (taskDetail) {
      if (task.id === taskDetail.id) setTaskDetail(undefined);
      else setTaskDetail(task);
    } else setTaskDetail(task);
  };

  const onSelectEvidence = (event) => {
    const files = event.target.files;
    const reader = new FileReader();

    reader.readAsDataURL(files[0]);

    reader.onload = (e) => setEvidenceDocument(e.target.result);
  };

  const onUploadEvidence = async () => {
    if (!evidenceLabel) {
      alert("Please give the evidence documennt a filname.");

      return false;
    }

    const imagePayload = {
      label: evidenceLabel,
      image: evidenceDocument,
      cqrId: evidenceObject.cqrId,
    };

    try {
      const response = await uploadEvidence(imagePayload);

      if (response) return true;
    } catch (error) {
      console.log(error);

      return false;
    }
  };

  const onCreateTask = async () => {
    const taskPayload = {
      nqaId: Number(nqaId),
      centerId: evidenceObject.centerId,
      questionId: evidenceObject.questionId,
      type: "evidence",
      name: newTask.name,
      taskDue: newTask.taskDue,
      status: newTask.status,
      summary: newTask.summary,
      asignee: newTask.asignee,
    };

    if (Object.values(taskPayload).every(Boolean)) {
      setLoading(true);

      try {
        const response = await postTask(taskPayload);

        if (response) {
          setLoading(false);
          setTaskModalVis(true);
        }
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    } else alert("Please fill up all fields.");
  };

  const onUpdateTask = async () => {
    setLoading(true);

    try {
      const response = await updateTask(taskDetail);

      if (response) await refreshModal(evidenceObject);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const onDeleteEvidence = async (attachmentId) => {
    setLoading(true);

    try {
      const response = await deleteAttachment(attachmentId);

      if (response) await refreshModal(evidenceObject);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const onSaveEvidence = async () => {
    if (evidenceDocument) {
      setLoading(true);

      try {
        const response = await onUploadEvidence();

        if (response) await refreshModal(evidenceObject);
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    }
  };

  // const loadDocuments = useCallback(async () => {
  //   const docs = [];

  //   for (let index = 0; index < evidenceObject.evidenceDocs.length; index++) {
  //     const document = evidenceObject.evidenceDocs[index];

  //     try {
  //       const imageUrl = await fetchImage(document.id);

  //       docs.push({
  //         ...document,
  //         url: imageUrl
  //       });

  //     } catch (error) {
  //       console.log(error);
  //       setImagesLoading(false);
  //     }
  //   }

  //   setDocuments(docs);
  //   setImagesLoading(false);
  // }, [evidenceObject.evidenceDocs]);

  const onSave = async () => {
    if (evidenceDocument) {
      setLoading(true);

      try {
        await onUploadEvidence();

        setLoading(false);
        onCloseModal();
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    } else onCloseModal();
  };

  const { data: users } = useSWR(
    [`/users`, evidenceObject.centerId],
    (_, centerId) => fetchCenterUsers(centerId)
  );

  useEffect(() => {
    if (evidenceObject.openTasks) {
      if (tasksRef.current)
        tasksRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [evidenceObject.openTasks]);

  // useEffect(() => {
  //   loadDocuments();
  // }, [loadDocuments]);

  return (
    <Modal
      size="large"
      centered={false}
      open={visibility}
      onOpen={onOpenModal}
      onClose={onCloseModal}
    >
      {!users ? (
        <div
          className={`w-full h-screen flex flex-col justify-center items-center`}
        >
          <FaSpinner
            className={`animate-spin text-qipGreen font-bold text-6xl`}
          />
          <span className={`block`}> Loading data...</span>
        </div>
      ) : postLoading ? (
        <div
          className={`w-full h-screen flex flex-col justify-center items-center`}
        >
          <FaSpinner
            className={`animate-spin text-qipGreen font-bold text-6xl`}
          />
          <span className={`block`}> Loading data...</span>
        </div>
      ) : (
        <Modal.Content scrolling style={{ maxHeight: "95vh" }}>
          <div className={styles.modalContainer}>
            <Button
              className={styles.closeButton}
              onClick={onCloseModal}
              size="big"
              icon="close"
            />

            {statusModalVis && (
              <ChangeStatusModal
                visibility={statusModalVis}
                onSelectStatus={
                  changeStatusParent === 1 ? onChangeHandler : onManageNewTask
                }
                onOpenModal={() => setStatusModalVis(true)}
                onCloseModal={() => setStatusModalVis(false)}
              />
            )}

            {taskModalVis && (
              <Modal
                centered
                size="small"
                open={taskModalVis}
                onOpen={() => setTaskModalVis(true)}
                onClose={() => setTaskModalVis(false)}
              >
                <div className={styles.taskConfirmationModal}>
                  <text className={styles.headerText}>
                    Task set and team member notified.
                  </text>

                  <BlueButton
                    buttonText="Close"
                    className={styles.blueButton}
                    onClick={async () => {
                      setTaskModalVis(false);
                      await refreshModal(evidenceObject);
                    }}
                  />
                </div>
              </Modal>
            )}

            <div className={styles.modalContent}>
              <div className={styles.modalHeaderRow}>
                <text className={styles.headerText}>Required evidence</text>

                <text className={styles.subText}>
                  {evidenceObject.yesDescription
                    ? evidenceObject.yesDescription
                    : "Add or update your QIP/SAT evidences. These can be documents, image to support your ‘YES’ answers."}
                </text>
              </div>

              <div className={styles.divider} />

              <div className={styles.evidenceRow}>
                <text className={styles.headerText}>Your QIP evidence</text>

                <text className={styles.subText}>
                  Is the evidence provided?
                </text>

                <div className={styles.evidenceStatusRow}>
                  <div>
                    <Button
                      className={
                        evidenceObject.evidenceDocs.length !== 0
                          ? styles.evidenceStatusYes
                          : styles.evidenceStatusIcon
                      }
                      icon="check"
                    />

                    <text>Yes</text>
                  </div>

                  <div>
                    <Button
                      className={
                        evidenceObject.evidenceDocs.length === 0
                          ? styles.evidenceStatusNo
                          : styles.evidenceStatusIcon
                      }
                      icon="close"
                    />

                    <text>No</text>
                  </div>
                </div>
              </div>

              <div className={styles.evidenceDocumentGroup}>
                {evidenceObject.evidenceDocs.map((document) => (
                  <div key={document.id} className={styles.evidenceDocumentRow}>
                    <div className={styles.evidenceDocumentDisplay}>
                      {/* <div className={styles.evidenceImageContainer}>
                          <img
                            className={styles.evidenceImage}
                            alt="Evidence image"
                            src={`data:image/jpeg;base64,${document.url}`}
                          />
                        </div> */}

                      <div className={styles.evidenceDocumentDetails}>
                        <text className={styles.evidenceDocumentFileName}>
                          {document.name}
                        </text>

                        <text className={styles.evidenceDocumentUploadDate}>
                          Uploaded on {document.uploadDate}
                        </text>
                      </div>
                    </div>

                    <div className={styles.evidenceDocumentActionGroup}>
                      <div className={styles.documentButtonGroup}>
                        <Button
                          className={styles.removeButton}
                          icon="close"
                          onClick={() => onDeleteEvidence(document.id)}
                        />

                        <text className={styles.documentButtonText}>
                          Remove
                        </text>
                      </div>

                      <div className={styles.documentButtonGroup}>
                        <Button
                          className={styles.downloadButton}
                          icon="download"
                          onClick={() =>
                            (window.location.href = `${process.env.REACT_APP_API_URL}/api/uploads/${document.id}`)
                          }
                        />

                        <text className={styles.documentButtonText}>
                          Download
                        </text>
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              <div className={styles.uploadEvidenceRow}>
                <input
                  ref={inputRef}
                  type="file"
                  style={{ display: "none" }}
                  onChange={onSelectEvidence}
                />

                <div
                  className={styles.uploadEvidencePlaceholder}
                  onClick={() => inputRef.current.click()}
                >
                  {evidenceDocument ? (
                    <embed width="80%" height="80%" src={evidenceDocument} />
                  ) : (
                    <div
                      style={{
                        paddingTop: "10%",
                        paddingBottom: "10%",
                      }}
                    >
                      <Icon
                        className={styles.uploadEvidenceIcon}
                        name="arrow up"
                      />

                      <text className={styles.uploadEvidenceText}>
                        Upload evidence documentation
                      </text>
                    </div>
                  )}
                </div>

                <InputComponent
                  label="Give this file a name"
                  inputValue={evidenceLabel}
                  setInputValue={(fieldName, value) => setEvidenceLabel(value)}
                />

                <BlueButton
                  className={styles.blueButton}
                  disabled={!evidenceDocument}
                  buttonText="Save changes"
                  onClick={onSaveEvidence}
                />
              </div>

              <div className={styles.divider} />

              <text className={styles.tasksHeaderText}>
                Manage your evidence tasks
              </text>
              {evidenceObject.tasks.length !== 0 && (
                <>
                  <div ref={tasksRef} className={`${styles.tasksCountRow}`}>
                    <div>
                      <div>
                        <text>{evidenceObject.tasks.length}</text>

                        <text>assigned tasks</text>
                      </div>

                      {!tasksVisibility ? (
                        <button
                          className={styles.showTasksButton}
                          onClick={() => setTasksVisibility((prev) => !prev)}
                        >
                          Show tasks
                        </button>
                      ) : (
                        <BlueButton
                          buttonText="Close tasks"
                          onClick={() => setTasksVisibility((prev) => !prev)}
                        />
                      )}
                    </div>
                  </div>

                  {tasksVisibility && users && (
                    <div className={styles.tasksGroup}>
                      {evidenceObject.tasks.map((task) => (
                        <div key={task.id} className={styles.taskItemContainer}>
                          <div
                            className={styles.taskContentRow}
                            onClick={() => onSelectTaskItem(task)}
                          >
                            <div className={styles.taskDescriptionColumn}>
                              <text className={styles.taskDescriptionHeader}>
                                {task.name}
                              </text>

                              <div className={styles.taskDescriptionSubtext}>
                                <text
                                  className={
                                    task.status === 1
                                      ? styles.taskDetailNotStarted
                                      : task.status === 2
                                      ? styles.taskDetailInProgress
                                      : styles.taskDetailCompleted
                                  }
                                >
                                  {task.status === 1
                                    ? "Not started"
                                    : task.status === 2
                                    ? "In progress"
                                    : "Completed"}
                                </text>

                                <text>
                                  {
                                    users.filter(
                                      (user) =>
                                        parseInt(user.id) === task.assignee
                                    )[0].name
                                  }
                                </text>

                                <text>
                                  {task.taskDue
                                    ? task.taskDue
                                        .substring(0, 10)
                                        .replace(/-/g, "/")
                                    : ""}
                                </text>
                              </div>
                            </div>

                            <Button
                              className={
                                taskDetail && taskDetail.id === task.id
                                  ? styles.taskCollapseButtonSelected
                                  : styles.taskCollapseButton
                              }
                              icon={
                                taskDetail && taskDetail.id === task.id
                                  ? "arrow up"
                                  : "arrow down"
                              }
                              onClick={() => setTaskDetail(task)}
                            />
                          </div>

                          {taskDetail && taskDetail.id === task.id ? (
                            <div className={styles.taskDetails}>
                              <InputComponent
                                fieldName="name"
                                label="Task name"
                                type="text"
                                inputValue={taskDetail.name}
                                setInputValue={onChangeHandler}
                              />

                              <div className={styles.dropdownRow}>
                                <div className={styles.dropdownGroup}>
                                  <text className={styles.dropdownText}>
                                    Task for
                                  </text>

                                  <Dropdown
                                    className={styles.dropdownBox}
                                    fluid
                                    selection
                                    scrolling
                                    placeholder="Choose"
                                    options={users.map((user) => ({
                                      text: user.name,
                                      value: user.id,
                                    }))}
                                    defaultValue={
                                      users.filter(
                                        (user) =>
                                          parseInt(user.id) ===
                                          taskDetail.assignee
                                      )[0].id
                                    }
                                    onChange={(e, data) =>
                                      onChangeHandler("asignee", data.value)
                                    }
                                  />
                                </div>

                                <div className={styles.dropdownGroup}>
                                  <text className={styles.dropdownText}>
                                    To be completed by
                                  </text>

                                  <div className={styles.datePickerGroup}>
                                    <input
                                      className={styles.datePickerBox}
                                      type="date"
                                      value={taskDetail.taskDue.substring(
                                        0,
                                        10
                                      )}
                                      onChange={(e) =>
                                        onChangeHandler(
                                          "taskDue",
                                          e.target.value
                                        )
                                      }
                                    />

                                    {/* <Icon
                                                className={styles.datePickerIcon}
                                                name="caret down"
                                              /> */}
                                  </div>
                                </div>
                              </div>

                              <InputComponent
                                className={styles.summaryTextBox}
                                fieldName="summary"
                                label="Task summary"
                                textArea
                                inputValue={taskDetail.summary}
                                setInputValue={onChangeHandler}
                              />

                              <div className={styles.dropdownGroup}>
                                <text className={styles.dropdownText}>
                                  Change task status
                                </text>

                                <div
                                  className={
                                    styles[`statusSelect${taskDetail.status}`]
                                  }
                                  onClick={() => onChangeStatus(1)}
                                >
                                  <text className={styles.dropdownText}>
                                    {taskDetail.status === 1
                                      ? "Not started"
                                      : taskDetail.status === 2
                                      ? "In progress"
                                      : "Completed"}
                                  </text>

                                  <Icon name="caret down" />
                                </div>
                              </div>

                              <BlueButton
                                className={styles.blueButton}
                                buttonText="Update task"
                                onClick={onUpdateTask}
                              />
                            </div>
                          ) : null}
                        </div>
                      ))}
                    </div>
                  )}
                  <text className={styles.headerText}>Add another task</text>
                </>
              )}

              <div className={styles.evidenceTaskRow}>
                <div className={styles.evidenceTaskForm}>
                  <InputComponent
                    fieldName="name"
                    label="Task name"
                    type="text"
                    inputValue={newTask.name}
                    setInputValue={onManageNewTask}
                  />

                  <div className={styles.dropdownRow}>
                    <div className={styles.dropdownGroup}>
                      <text className={styles.dropdownText}>Task for</text>

                      <Dropdown
                        className={styles.dropdownBox}
                        fluid
                        selection
                        scrolling
                        placeholder="Choose"
                        options={users.map((user) => ({
                          text: user.name,
                          value: user.id,
                        }))}
                        defaultValue={newTask.asignee}
                        onChange={(e, data) =>
                          onManageNewTask("asignee", data.value)
                        }
                      />
                    </div>

                    <div className={styles.dropdownGroup}>
                      <text className={styles.dropdownText}>
                        To be completed by
                      </text>

                      {/* <div className={styles.datePickerGroup}> */}

                      <input
                        className={styles.datePickerBox}
                        type="date"
                        value={newTask.taskDue}
                        onChange={(e) =>
                          onManageNewTask("taskDue", e.target.value)
                        }
                      />

                      {/* <Icon
                          className={styles.datePickerIcon}
                          name="caret down"
                        /> */}

                      {/* </div> */}
                    </div>
                  </div>

                  <InputComponent
                    className={styles.summaryTextBox}
                    fieldName="summary"
                    label="Task summary"
                    textArea
                    inputValue={newTask.summary}
                    setInputValue={onManageNewTask}
                  />

                  <div className={styles.dropdownGroup}>
                    <text className={styles.dropdownText}>
                      Change task status
                    </text>

                    <div
                      className={
                        styles[
                          `statusSelect${
                            newTask.status !== undefined ? newTask.status : 1
                          }`
                        ]
                      }
                      onClick={() => onChangeStatus(2)}
                    >
                      <text className={styles.dropdownText}>
                        {newTask.status === 1
                          ? "Not started"
                          : newTask.status === 2
                          ? "In progress"
                          : newTask.status === 3
                          ? "Completed"
                          : "Not started"}
                      </text>

                      <Icon name="caret down" />
                    </div>
                  </div>

                  <BlueButton
                    className={styles.blueButton}
                    buttonText="Set task"
                    onClick={onCreateTask}
                  />
                </div>
              </div>

              <div className={styles.divider} />

              <div className={styles.generalCommentsRow}>
                <text className={styles.headerText}>
                  General notes or comments
                </text>

                <InputComponent
                  className={styles.summaryTextBox}
                  label="Add additional comments here"
                  textArea
                />

                <BlueButton
                  buttonText="Save changes"
                  className={styles.blueButton}
                  onClick={onSave}
                />
              </div>
            </div>
          </div>
        </Modal.Content>
      )}
    </Modal>
  );
}

NQAEvidenceModal.propTypes = {
  visibility: PropTypes.bool.isRequired,
  onOpenModal: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  refreshModal: PropTypes.func.isRequired,
  evidenceObject: PropTypes.shape({
    evidenceDocs: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        title: PropTypes.string,
        uploadDate: PropTypes.string,
        url: PropTypes.string,
      })
    ),
    tasks: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        centerId: PropTypes.number,
        questionId: PropTypes.number,
        name: PropTypes.string,
        status: PropTypes.number,
        nqaArea: PropTypes.string,
        taskDue: PropTypes.string,
        summary: PropTypes.string,
        assignee: PropTypes.number,
      })
    ),
    comments: PropTypes.string,
    cqrId: PropTypes.number,
    centerId: PropTypes.number,
    questionId: PropTypes.number,
    yesDescription: PropTypes.string,
    openTasks: PropTypes.bool,
  }).isRequired,
};

export default NQAEvidenceModal;
