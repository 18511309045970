import React from "react";
import ReactDOM from "react-dom";
import { useHistory } from "react-router-dom";
import styles from "./SatDrawer.module.css";
import {
  FaAngleRight,
  FaAngleLeft,
  FaAngleDown,
  FaExclamationCircle,
  FaAngleUp,
  FaQuestionCircle,
  FaCopy,
  FaEye,
  FaTimesCircle,
  FaSpinner,
  FaExclamationTriangle,
  FaCheck,
  FaExclamation,
  FaTimes,
} from "react-icons/fa";
import {
  createSatData,
  updateSatData,
  removeSatData,
} from "../../api/requests";
import { useStoreActions, useStoreState } from "../../hooks";
import ConfirmRemoveSatModal from "../../components/Modals/ConfirmRemoveSatModal";

import { subnqas } from "../../data/subnqas";
import { isEmpty, debounce } from "lodash";
import {
  useSpring,
  animated,
  config,
  useSprings,
  useTrail,
} from "react-spring";
import { Popover } from "antd";

const { useState, useEffect, useRef, useCallback, useMemo } = React;

const body = document.body;

const nqas = [
  {
    id: 1,
    label: "NQA1",
    satCount: 45,
  },
  {
    id: 2,
    label: "NQA2",
    satCount: 30,
  },
  {
    id: 3,
    label: "NQA3",
    satCount: 20,
  },
  {
    id: 4,
    label: "NQA4",
    satCount: 20,
  },
  {
    id: 5,
    label: "NQA5",
    satCount: 20,
  },
  {
    id: 6,
    label: "NQA6",
    satCount: 30,
  },
  {
    id: 7,
    label: "NQA7",
    satCount: 30,
  },
];

const wordLimit = 500;

const getQType = (qtype) => {
  switch (qtype) {
    case "embedded":
      return 0;
    case "critical_reflections":
      return 1;
    case "partnerships_with_family":
      return 2;
  }
};

const duration = 3000;

const Drawer = ({
  open,
  onClose,
  onDrawerToggle,
  questions,
  currentQuestionsCount = 0,
  navigateToNqa,
  refetchQuestions,
  goToQuestion,
  satDrawerState,
  toggleShowPopup,
}) => {
  const drawerRef = useRef();

  const [satQuestionCount, setSatQuestionCount] = useState(0);
  const [enablePopover, setEnablePopover] = useState(false);
  const [rendered, setRendered] = useState(false);
  const [transitTimer, setTransitTimer] = useState(2000);
  const [startTime, setStartTime] = useState(new Date());

  const springConfig = { ...config.default };

  const props = useSpring({
    right: open
      ? "0px"
      : `${
          window.innerWidth < 1100
            ? -1 * window.innerWidth * 0.8 + "px"
            : "-950px"
        }`,
    config: springConfig,
  });

  const propsDrawerHandle = useSpring({
    right: open
      ? `${window.innerWidth < 1100 ? window.innerWidth * 0.8 + "px" : "950px"}`
      : "0px",
    config: springConfig,
  });

  const propsMask = useSpring({
    background: open ? "rgba(0, 0, 0, 0.5)" : "rgba(0, 0, 0, 0)",
    zIndex: "50",
    config: springConfig,
  });

  const popUpBurst = () => {
    const timeout = setTimeout(() => {
      const date = new Date();
      const timeDiff = date.getTime() - startTime.getTime();
      if (timeDiff >= duration) {
        setEnablePopover(false);
      } else {
        setTransitTimer(timeDiff);
      }
    }, 1000);
  };

  useEffect(() => {
    popUpBurst();
  }, [transitTimer]);

  useEffect(() => {
    if (rendered) {
      setEnablePopover(true);
      setStartTime(new Date());
      popUpBurst();
    }
    setRendered(true);
  }, [toggleShowPopup]);

  return (
    <animated.div
      className={`${
        open ? styles.drawerCanvas : "w-0"
      } h-screen top-0 fixed right-0  `}
      style={propsMask}
    >
      <Popover
        visible={enablePopover}
        placement={`topRight`}
        content={
          <div className={`${styles.satAddedNotif} relative`}>
            Question added to SAT!
            <div className={`${styles.toolTip} absolute right-0`}></div>
          </div>
        }
      >
        <animated.div
          onClick={() => {
            onDrawerToggle();
          }}
          className={`cursor-pointer fixed bg-blue-400 flex flex-col justify-center items-center ${styles.drawerHandle}`}
          style={propsDrawerHandle}
        >
          <div
            className={`${styles.drawerHandleIcon} flex justify-center items-center`}
          >
            {open ? <FaAngleRight /> : <FaAngleLeft />}
          </div>

          <div className={`mt-3 ${styles.drawerHandleText} `}>
            {`${currentQuestionsCount} SAT questions`}
          </div>
        </animated.div>
      </Popover>

      <animated.div
        className={` absolute top-0  h-screen overflow-x-hidden overflow-y-auto ${styles.mainContainer}`}
        style={props}
      >
        <DrawerContent
          questions={questions}
          navigate={navigateToNqa}
          refetchQuestions={refetchQuestions}
          goToQuestion={goToQuestion}
          isOpen={open}
          satDrawerState={satDrawerState}
          onDrawerToggle={onDrawerToggle}
        />
      </animated.div>
    </animated.div>
  );
};

const DrawerContent = ({
  questions = [],
  navigate,
  refetchQuestions,
  goToQuestion = { goToQuestion },
  isOpen,
  satDrawerState = { cfilter: 1, subCFilter: "1.1.1" },
  onDrawerToggle = { onDrawerToggle },
}) => {
  const history = useHistory();

  const [currentPage, setCurrentPage] = useState(1);
  const [currentFilter, setCurrentFilter] = useState(satDrawerState.cfilter);
  const [subCurrentFilter, setSubCurrentFilter] = useState(
    satDrawerState.subCFilter
  );

  const setSelectedNQA = useStoreActions(
    (actions) => actions.nqaStore.setSelectedNQA
  );
  const [openSub, setOpenSub] = useState(false);
  const [satLoading, setSatLoading] = useState(false);
  const [selectedSat, setSelectedSat] = useState({});
  const [questionState, setQuestionState] = useState([]);

  const [enablePopover, setEnablePopover] = useState(false);

  const [removeLoading, setRemoveLoading] = useState(false);

  const [showRemoveModal, setShowRemoveModal] = useState(false);

  const [transitTimer, setTransitTimer] = useState(2000);

  const [startTime, setStartTime] = useState(new Date());

  const areaRefs = useRef([]);

  const topDiv = useRef(null);

  const setLevel = useStoreActions(
    (actions) => actions.taskStore.setLevelInformation
  );

  const subNqaSprings = useSprings(
    subnqas.filter((subnqa) => subnqa.nqa === currentFilter)[0].sub.length,
    subnqas
      .filter((subnqa) => subnqa.nqa === currentFilter)[0]
      .sub.map((item, k) => {
        const length = subnqas.filter(
          (subnqa) => subnqa.nqa === currentFilter
        )[0].sub.length;

        return {
          display: openSub ? "flex" : "none",
          delay: openSub ? k * 30 : 0,
        };
      })
  );

  const handleOnChange = (value, question) => {
    if (
      !isEmpty(questionState) &&
      questionState.some((q) => q.questionId === question.question_id)
    ) {
      setQuestionState([
        ...questionState.map((q) => {
          if (q.questionId === question.question_id) {
            return {
              questionId: question.question_id,
              description: value,
              descriptionLength: wordLimit - value.length,
              textOverflow: value.length > wordLimit,
              overFlow: value.length - wordLimit,
            };
          } else {
            return q;
          }
        }),
      ]);
    } else {
      setQuestionState([
        ...questionState,
        {
          questionId: question.question_id,
          description: value,
          descriptionLength: wordLimit - value.length,
          textOverflow: value.length > wordLimit,
          overFlow: value.length - wordLimit,
        },
      ]);
    }

    onProcessData(value, question);
  };

  const onProcessData = useMemo(
    () => debounce((value, question) => processSatData(value, question), 2000),
    []
  );

  const processSatData = async (value, question) => {
    popUpBurst();
    if (question.hasData) {
      await onUpdateSatData(value, question);
    } else {
      await onCreateSatData(value, question);
    }

    await refetchQuestions();
  };

  const onRemoveSatData = async (question) => {
    const satId = !isEmpty(question.satData) ? question.satData.id : 0;

    try {
      setRemoveLoading(true);
      const response = await removeSatData(
        question.hasData,
        satId,
        question.question_id,
        question.center_id
      );
      await refetchQuestions();
      setRemoveLoading(false);
    } catch (error) {
      setRemoveLoading(false);
      console.log(error);
    }
  };

  const onCreateSatData = async (value, question) => {
    try {
      const response = await createSatData({
        centerId: question.center_id,
        questionId: question.question_id,
        label: question.label,
        description: question.description,
        satDescription: value,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const onUpdateSatData = async (value, question) => {
    try {
      const response = await updateSatData(question.satData.id, {
        centerId: question.center_id,
        questionId: question.question_id,
        label: question.label,
        description: question.description,
        satDescription: value,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const onGoToQuestion = (question) => {
    const level = {
      navigate: true,
      nqaLevel: parseInt(subCurrentFilter.split(".")[1]) - 1,
      nqaSublevel: parseInt(subCurrentFilter.split(".")[2]) - 1,
      qtype: getQType(question.type),
      qId: question.question_id,
    };

    goToQuestion(currentFilter, level);
  };

  const onCopyToClipboard = (question) => {
    const currentDescription = areaRefs.current[question.question_id];
    currentDescription.select();
    document.execCommand("copy");
  };

  const checkConfirmRemoveSat = async (question) => {
    if (question.hasData && question.hasDataDescription) {
      setShowRemoveModal(true);
    } else {
      await onRemoveSatData(question);
    }
  };

  const CharLimitDisplay = ({ question }) => {
    const overflow =
      !isEmpty(questionState) &&
      questionState.some((q) => q.questionId === question.question_id)
        ? questionState.filter((q) => q.questionId === question.question_id)[0]
            .textOverflow
          ? true
          : false
        : false;

    if (overflow) {
      return (
        <>
          <div className={`${styles.satCharCountOverflow} flex justify-start`}>
            <span className={`${styles.overLimitCount}`}>
              {`
                +${
                  questionState.filter(
                    (q) => q.questionId === question.question_id
                  )[0].overFlow
                }
              `}
            </span>{" "}
            <FaExclamationTriangle className={`${styles.iconLimit}`} />
          </div>
          <div className={styles.satCharTextOverflow}>over the limit</div>
        </>
      );
    } else {
      return (
        <>
          <div className={styles.satCharCount}>
            {!isEmpty(questionState) &&
            questionState.some((q) => q.questionId === question.question_id)
              ? questionState.filter(
                  (q) => q.questionId === question.question_id
                )[0].descriptionLength
              : wordLimit}
          </div>

          <div className={styles.satCharText}>characters left</div>
        </>
      );
    }
  };

  const updateQuestionsStates = () => {
    if (!isEmpty(questions)) {
      const filteredQuestions = questions.filter(
        (q) => q.label === subCurrentFilter
      );

      setQuestionState([
        ...filteredQuestions.map((q) => {
          if (
            !isEmpty(questionState) &&
            questionState.some((qs) => qs.questionId === q.question_id)
          ) {
            const qs = questionState.filter(
              (qs) => qs.questionId === q.question_id
            )[0];
            return {
              questionId: q.question_id,
              description: qs.description,
              descriptionLength: wordLimit - qs.description.length,
              textOverflow: qs.description.length > wordLimit,
              overFlow: qs.description.length - wordLimit,
            };
          } else {
            return {
              questionId: q.question_id,
              description: q.dataDescription,
              descriptionLength: wordLimit - q.dataDescription.length,
              textOverflow: q.dataDescription.length > wordLimit,
              overFlow: q.dataDescription.length - wordLimit,
            };
          }
        }),
      ]);
    }
  };

  const popUpBurst = () => {
    setEnablePopover(true);
    const timeout = setTimeout(() => {
      const date = new Date();
      const timeDiff = date.getTime() - startTime.getTime();
      if (timeDiff >= duration) {
        setEnablePopover(false);
      } else {
        setTransitTimer(timeDiff);
      }
    }, 1000);
  };

  useEffect(() => {
    popUpBurst();
  }, [transitTimer]);

  useEffect(() => {
    return () => {
      onProcessData.cancel();
    };
  }, []);

  useEffect(() => {
    updateQuestionsStates();
  }, [currentFilter, subCurrentFilter, questions]);

  useEffect(() => {
    if (!isOpen) {
      setCurrentFilter(satDrawerState.cfilter);
      setSubCurrentFilter(satDrawerState.subCFilter);
      // topDiv.current.scrollIntoView();
    }
  }, [satDrawerState, isOpen]);

  useEffect(() => {
    if (isOpen) {
      topDiv.current.scrollIntoView();
    }
  }, [isOpen]);

  return (
    <div ref={topDiv} className={`${styles.drawerContent} `}>
      {showRemoveModal && (
        <ConfirmRemoveSatModal
          satQuestion={{ questionItem: {}, satData: selectedSat.satData }}
          visibility={showRemoveModal}
          onCloseModal={() => setShowRemoveModal(false)}
          onConfirm={async () => {
            await onRemoveSatData(selectedSat);
            setShowRemoveModal(false);
          }}
        />
      )}

      <div
        onClick={() => onDrawerToggle()}
        className={`${styles.closeDrawerButton} cursor-pointer flex justify-center items-center absolute top-0 right-0`}
      >
        <FaTimes />
      </div>
      <div
        className={`${styles.drawerHeader} flex flex-col justify-center items-start`}
      >
        <div className={styles.headerContainer}>
          <FaExclamationCircle className={styles.headerIcon} />{" "}
          <text className={`${styles.aboutHeader} ml-1`}>About this: </text>
        </div>

        <text className={styles.subText}>
          {`Identify and document your 5 Key Practices for each NQA element, so you can easily transfer them onto your Regulatory Authority Online Self Assessment Form with a simple cut & paste!`}
        </text>
      </div>

      <div
        className={`${styles.resourceFilterRow} flex justify-between items-center pb-2.5 mt-7`}
      >
        {nqas.map((nqa) => {
          return (
            <div
              key={nqa.id}
              className={`relative ${
                currentFilter === nqa.id
                  ? styles.resourceFilterSelected
                  : styles.resourceFilter
              }  flex flex-col items-center justify-center`}
              onClick={async () => {
                setSubCurrentFilter(`${nqa.id}.1.1`);
                setCurrentPage(1);
                setCurrentFilter(nqa.id);
              }}
            >
              <span>{nqa.label}</span>
              <span
                style={{
                  color: currentFilter === nqa.id ? "#ffffff" : "#272828",
                }}
                className={`${styles.nqaSatCount}`}
              >{`${
                questions.filter(
                  (q) => Number(q.label.split(".")[0]) === nqa.id
                ).length
              }/${nqa.satCount} `}</span>
              {questions.filter((q) => Number(q.label.split(".")[0]) === nqa.id)
                .length !== nqa.satCount && (
                <div
                  style={{
                    background:
                      currentFilter === nqa.id ? "#ffffff" : "#F56666",
                    color: currentFilter === nqa.id ? "#F56666" : "#ffffff",
                  }}
                  className={`${styles.satCheck} absolute top-0 right-0 mr-2 mt-2 flex justify-center items-center rounded-full `}
                >
                  <FaExclamation />
                </div>
              )}

              {questions.filter((q) => Number(q.label.split(".")[0]) === nqa.id)
                .length === nqa.satCount && (
                <div
                  style={{
                    background:
                      currentFilter === nqa.id ? "#ffffff" : "#8BC334",
                    color: currentFilter === nqa.id ? "#8BC334" : "#ffffff",
                  }}
                  className={`${styles.satCheck} absolute top-0 right-0 mr-2 mt-2 flex justify-center items-center rounded-full`}
                >
                  <FaCheck />
                </div>
              )}
            </div>
          );
        })}
      </div>

      <div
        className={`relative ${styles.subResourcesFilterRow} ${
          styles[`nqa_${currentFilter}`]
        } flex flex-col pb-10`}
      >
        <div
          className={`relative ${styles.subResourcesFilterSelector} flex justify-center items-center`}
        >
          <span> {`NQA ${subCurrentFilter}`}</span>{" "}
          <div className={`flex justify-center items-center absolute right-0`}>
            <span>Change</span>
            <div
              onClick={() => {
                setOpenSub(!openSub);
              }}
              className={`${styles.subDropdown} flex justify-center items-center overflow-hidden mx-2`}
            >
              {openSub ? <FaAngleUp /> : <FaAngleDown />}
            </div>
          </div>
        </div>

        {openSub &&
          subNqaSprings.map(({ ...props }, k) => (
            <animated.div
              key={k}
              style={{ ...props }}
              className={`${
                subCurrentFilter ===
                subnqas.filter((subnqa) => subnqa.nqa === currentFilter)[0].sub[
                  k
                ].label
                  ? styles.subResourcesFilterSelected
                  : styles.subResourcesFilter
              }  ${
                subCurrentFilter ===
                subnqas.filter((subnqa) => subnqa.nqa === currentFilter)[0].sub[
                  k
                ].id
                  ? "opacity-80"
                  : "opacity-1"
              }           items-center justify-center`}
              onClick={() => {
                setSubCurrentFilter(
                  `${
                    subnqas.filter((subnqa) => subnqa.nqa === currentFilter)[0]
                      .sub[k].id
                  }`
                );

                setOpenSub(false);
              }}
            >
              {
                subnqas.filter((subnqa) => subnqa.nqa === currentFilter)[0].sub[
                  k
                ].label
              }
            </animated.div>
          ))}
      </div>

      {isEmpty(
        questions.filter((question) => question.label === subCurrentFilter)
      ) ? (
        <>
          <div
            className={`${styles.nqaSatQuestionsHeader} flex flex-col items-center`}
          >
            <text
              className={styles.nqaSubSatHeader}
            >{`NQA${subCurrentFilter}`}</text>
            <text className={`${styles.nqaSatSub} my-4`}>
              {`${
                questions.filter((question) => {
                  return question.label === subCurrentFilter;
                }).length
              }/5 questions chosen for this element`}
            </text>
            <text className={styles.nqaSatText}>
              Update, remove and edit chosen strengths. Or visit other NQA areas
              and choose questions which you want to add to your SAT report. All
              changes here will autosaved.
            </text>
            <div className={`flex justify-center items-center`}>
              <button
                onClick={() => {
                  setLevel({
                    navigate: true,
                    nqaLevel: parseInt(subCurrentFilter.split(".")[1]) - 1,
                    nqaSublevel: parseInt(subCurrentFilter.split(".")[2]) - 1,
                    qtype: getQType("embedded"),
                    qId: 1,
                  });

                  setSelectedNQA(currentFilter);
                  history.push(`/qip/nqa/${currentFilter}`);
                  onDrawerToggle();
                }}
                className={`${styles.saveSatButton1} mr-2`}
              >
                {`Go to NQA${subCurrentFilter}`}
              </button>
              <button
                onClick={() => {
                  const scrollY = body.style.top;
                  body.style.position = "";
                  body.style.top = "";
                  window.scrollTo(0, parseInt(scrollY || "0") * -1);
                  history.push(`/qip/reports`, {
                    selectionOption: 2,
                  });
                }}
                className={`${styles.gotoReportButton} my-10`}
              >
                Go to report
              </button>
            </div>
          </div>
          <div
            className={`${styles.noQuestionsAdded} flex flex-col items-center`}
          >
            <FaQuestionCircle
              className={`${styles.noQuestionsAddedIcon} mt-14 mb-12 `}
            />
            <text className={styles.noQuestionsHeader}>
              No questions added to SAT
            </text>
            <text className={`${styles.noQuestionsText} mt-5 mb-10`}>
              Use the shortcuts below to navigate to NQA areas and choose
              questions which you want to add to your SAT report. All changes
              here will autosaved.
            </text>
          </div>
          <div
            className={`${styles.resourceFilterRow} flex justify-between items-center pb-14 mt-7`}
          >
            {nqas.map((nqa) => {
              return (
                <div
                  key={nqa.id}
                  className={`${styles.resourceFilter1}  flex items-center justify-center`}
                  onClick={() => navigate(nqa.id)}
                >
                  {nqa.label}
                </div>
              );
            })}
          </div>
          {/* <div
            className={`${styles.footerHow} flex flex-col items-center mt-14 pb-14`}
          >
            <text className={`${styles.headerHow} mb-2 font-medium`}>
              How does this work?
            </text>
            <text className={styles.headerText}>
              Gestas quis ipsum suspendisse ultrices gravida dictum fusce ut
              placerat orci nulla pellentesque dignissim enim sit amet venenatis
              urna cursus eget nunc scelerisque viverra mauris in aliquam sem
              fringilla ut morbi tincidunt augue interdum velit euismod in
              pellentesque.
            </text>
          </div> */}
        </>
      ) : (
        <>
          {" "}
          <div
            className={`${styles.nqaSatQuestionsHeader} flex flex-col items-center`}
          >
            <text
              className={styles.nqaSubSatHeader}
            >{`NQA${subCurrentFilter}`}</text>
            <text className={`${styles.nqaSatSub} my-4`}>
              {`${
                questions.filter((question) => {
                  return question.label === subCurrentFilter;
                }).length
              }/5 questions chosen for this element`}
            </text>
            <text className={styles.nqaSatText}>
              Update, remove and edit chosen strengths. Or visit other NQA areas
              and choose questions which you want to add to your SAT report. All
              changes here will autosaved.
            </text>
            <div className={`flex justify-center items-center`}>
              <button
                onClick={() => {
                  setLevel({
                    navigate: true,
                    nqaLevel: parseInt(subCurrentFilter.split(".")[1]) - 1,
                    nqaSublevel: parseInt(subCurrentFilter.split(".")[2]) - 1,
                    qtype: getQType("embedded"),
                    qId: 1,
                  });

                  setSelectedNQA(currentFilter);
                  history.push(`/qip/nqa/${currentFilter}`);
                  onDrawerToggle();
                }}
                className={`${styles.saveSatButton1} mr-2`}
              >
                {`Go to NQA${subCurrentFilter}`}
              </button>
              <button
                onClick={() => {
                  const scrollY = body.style.top;
                  body.style.position = "";
                  body.style.top = "";
                  window.scrollTo(0, parseInt(scrollY || "0") * -1);
                  history.push(`/qip/reports`, {
                    selectionOption: 2,
                  });
                }}
                className={`${styles.gotoReportButton} my-10`}
              >
                Go to report
              </button>
            </div>
          </div>
          {questions
            .filter((question) => question.label === subCurrentFilter)
            .map((question, k) => {
              return (
                <div
                  key={question.question_id}
                  className={`${styles.satQuestionContainer} py-14`}
                >
                  <div className={`flex items-start mb-12`}>
                    <Popover
                      visible={
                        enablePopover &&
                        selectedSat.question_id === question.question_id
                      }
                      placement={`topLeft`}
                      content={
                        <div
                          className={`${styles.changesSavedNotif} relative flex justify-center items-center`}
                        >
                          <FaCheck />
                          <span className={`ml-2`}>Changes saved!</span>
                          <div
                            className={`${styles.toolTipSavedNotif} absolute left-0`}
                          ></div>
                        </div>
                      }
                    >
                      <div>
                        <text
                          style={{
                            background:
                              !isEmpty(questionState) &&
                              questionState.some(
                                (q) => q.questionId === question.question_id
                              )
                                ? questionState.filter(
                                    (q) => q.questionId === question.question_id
                                  )[0].textOverflow
                                  ? "#F56666"
                                  : "#388cef"
                                : "#388cef",
                          }}
                          className={`${styles.satQuestionNumber} `}
                        >
                          {k + 1}
                        </text>
                      </div>
                    </Popover>
                    <div className={`${styles.satQuestionDesc} ml-4`}>
                      {`NQA ${question.label} ${question.description}`}
                    </div>
                  </div>
                  <textarea
                    style={{
                      color:
                        !isEmpty(questionState) &&
                        questionState.some(
                          (q) => q.questionId === question.question_id
                        )
                          ? questionState.filter(
                              (q) => q.questionId === question.question_id
                            )[0].textOverflow
                            ? "#F56666"
                            : "#272828"
                          : "#272828",
                    }}
                    ref={(el) => {
                      areaRefs.current[question.question_id] = el;
                    }}
                    value={
                      !isEmpty(questionState) &&
                      questionState.some(
                        (q) => q.questionId === question.question_id
                      )
                        ? questionState.filter(
                            (q) => q.questionId === question.question_id
                          )[0].description
                        : ""
                    }
                    onChange={(e) => {
                      setSelectedSat(question);
                      handleOnChange(e.target.value, question);
                    }}
                    className={`${styles.satQuestionDescription}`}
                  />
                  <div className={`flex justify-between items-center mt-4 `}>
                    <div
                      className={`flex flex-col justiify-center items-start transform md:scale-75 lg:scale-100 origin-left`}
                    >
                      <CharLimitDisplay question={question} />
                    </div>
                    <div
                      className={`flex justify-end items-center  transform md:scale-75 lg:scale-100 origin-left`}
                    >
                      <button
                        onClick={() => onCopyToClipboard(question)}
                        className={`${styles.satCopyButton} flex justify-center items-center `}
                      >
                        {" "}
                        <FaCopy />{" "}
                        <span className={`ml-3 `}>Copy to clipboard</span>
                      </button>
                      <button
                        onClick={() => {
                          onGoToQuestion(question);
                        }}
                        className={`${styles.satStrengthButton} mx-4 flex justify-center items-center `}
                      >
                        <FaEye />{" "}
                        <span className={`ml-3`}> Go to strength</span>
                      </button>
                      <button
                        disabled={removeLoading}
                        onClick={() => {
                          setSelectedSat(question);
                          checkConfirmRemoveSat(question);
                        }}
                        className={`${styles.satRemoveButton} flex justify-center items-center  `}
                      >
                        {removeLoading &&
                        selectedSat.question_id === question.question_id ? (
                          <FaSpinner className={`animate-spin`} />
                        ) : (
                          <FaTimesCircle />
                        )}{" "}
                        <span className={`ml-3`}>Remove</span>
                      </button>
                    </div>
                  </div>
                </div>
              );
            })}
          <div className={`${styles.wordLimitContainer} py-14`}>
            {questionState.some((q) => q.textOverflow === true) && (
              <div className={styles.wordLimitText}>
                <span className={styles.wordLimitWarningText}>
                  {`Word limit reached on one or more questions! `}
                </span>
                <span>{`Please review them.`}</span>
              </div>
            )}

            {!questionState.some(
              (q) =>
                (q.textOverflow === true && q.overFlow > 0) ||
                (q.textOverflow === false && q.description.length === 0)
            ) && (
              <div className={styles.wordLimitText}>
                <span>{`Your descriptions seem good, you can continue working on you SAT report.`}</span>
              </div>
            )}

            <div className={`flex justify-center items-center mt-5`}>
              {/* <button className={`${styles.saveSatButton} mr-2`}>
                Save SAT
              </button> */}
              <button
                onClick={() => {
                  setLevel({
                    navigate: true,
                    nqaLevel: parseInt(subCurrentFilter.split(".")[1]) - 1,
                    nqaSublevel: parseInt(subCurrentFilter.split(".")[2]) - 1,
                    qtype: getQType("embedded"),
                    qId: 1,
                  });

                  setSelectedNQA(currentFilter);
                  history.push(`/qip/nqa/${currentFilter}`);
                  onDrawerToggle();
                }}
                className={`${styles.saveSatButton1} mr-2`}
              >
                {`Go to NQA${subCurrentFilter}`}
              </button>
              <button
                onClick={() => {
                  const scrollY = body.style.top;
                  body.style.position = "";
                  body.style.top = "";
                  window.scrollTo(0, parseInt(scrollY || "0") * -1);
                  history.push(`/qip/reports`, {
                    selectionOption: 2,
                  });
                }}
                className={`${styles.goReportButton} ml-2`}
              >
                Go to report
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

const SatDrawer = ({
  navigate,
  visibility,
  onClose,
  onToggle,
  satQuestions = [],
  curSat = 0,
  refetchQuestions,
  goToQuestion,
  satDrawerState,
  toggleShowPopup = { toggleShowPopup },
}) => {
  const onResetBody = () => {
    if (visibility) {
      const scrollY =
        document.documentElement.style.getPropertyValue("--scroll-y");
      body.style.position = "fixed";
      body.style.top = `-${scrollY}`;
    } else {
      const scrollY = body.style.top;
      body.style.position = "";
      body.style.top = "";
      window.scrollTo(0, parseInt(scrollY || "0") * -1);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", () => {
      document.documentElement.style.setProperty(
        "--scroll-y",
        `${window.scrollY}px`
      );
    });

    onResetBody();

    return () => {
      window.removeEventListener("scroll", () => {
        document.documentElement.style.setProperty(
          "--scroll-y",
          `${window.scrollY}px`
        );
      });
    };
  }, [visibility]);

  return ReactDOM.createPortal(
    <Drawer
      navigateToNqa={navigate}
      currentQuestionsCount={curSat}
      questions={satQuestions}
      open={visibility}
      onClose={onClose}
      onDrawerToggle={() => {
        onToggle();
      }}
      refetchQuestions={refetchQuestions}
      goToQuestion={goToQuestion}
      satDrawerState={satDrawerState}
      toggleShowPopup={toggleShowPopup}
    />,
    document.getElementById("root")
  );
};

export default SatDrawer;
