import * as React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { useStoreActions } from "../../hooks";
import {
  fetchServiceDetails,
  updateServiceDetails,
  fetchUserCenters,
} from "../../api/requests";
import { FaSpinner } from "react-icons/fa";

const { useEffect } = React;

export default function ViewServiceDashboard() {
  const match = useRouteMatch();

  const url = new URL(window.location.href);

  const userId = url.searchParams.get("userId");
  const centerId = url.searchParams.get("centerId");
  const stripeId = url.searchParams.get("stripeId");
  const accessToken = url.searchParams.get("accessToken");
  const counter = url.searchParams.get("counter");

  const setAuthObject = useStoreActions(
    (actions) => actions.authStore.setAuthObject
  );
  const resetSession = useStoreActions((actions) => actions.authStore.logout);

  const fetchServices = useStoreActions(
    (actions) => actions.authStore.fetchServices
  );
  const fetchAdmin = useStoreActions(
    (actions) => actions.authStore.fetchAdminAttr
  );

  const fetchPaymentMethods = useStoreActions(
    (actions) => actions.userStore.fetchPaymentMethods
  );

  const initDetails = async () => {
    localStorage.clear();
    await resetSession();
    localStorage.setItem("selectedService", centerId);
    if (stripeId === "empty" || stripeId === "null") {
      setAuthObject({
        userId: Number(userId),
        stripeId: null,
        accessToken: accessToken,
        viewer: "viewer",
      });
    } else {
      setAuthObject({
        userId: Number(userId),
        stripeId: stripeId,
        accessToken: accessToken,
        viewer: "viewer",
      });
    }

    // if (stripeId !== "empty" || stripeId !== "null") {
    //   await fetchPaymentMethods({ userId: Number(userId) });
    // }

    // await fetchAdmin({ memberId: Number(userId) });
    await fetchServices(Number(userId));
  };

  useEffect(() => {
    initDetails().then(() => {
      window.location = "/";
    });
  }, [counter]);

  return (
    <div
      className={`w-full h-screen flex flex-col justify-center items-center`}
    >
      <FaSpinner className={`animate-spin text-qipGreen font-bold text-6xl`} />

      <div>Logging in...</div>
    </div>
  );
}
