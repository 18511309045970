import React, { useState } from "react";
import styles from "./Pagination.module.css";
import { Button } from "semantic-ui-react";

const Pagination = ({ currentPage, lastPage, onPageChange, className }) => {
  const isFirstPage = () => {
    return currentPage === 1;
  };

  const isLastPage = () => {
    return currentPage === lastPage;
  };

  const dspBtns = () => {
    const n = lastPage;
    const i = currentPage;
    /* eslint-disable */
    // if (n <= 9)
    //   return ((n) => {
    //     const arr = Array(n);
    //     while (n) {
    //       arr[n - 1] = n--;
    //     }
    //     return arr;
    //   })(n);
    if (n <= 7)
      return ((n) => {
        const arr = Array(n);
        while (n) {
          arr[n - 1] = n--;
        }
        return arr;
      })(n);
    // if (i <= 5) return [1, 2, 3, 4, 5, 6, 7, 0, n]; // 0 represents `···`
    if (i <= 4) return [1, 2, 3, 4, 5, 0, n]; // 0 represents `···`
    // if (i >= n - 4) return [1, 0, n - 6, n - 5, n - 4, n - 3, n - 2, n - 1, n];
    if (i >= n - 3) return [1, 0, n - 4, n - 3, n - 2, n - 1, n];
    // if (i < n - 4 && i > 5) return [1, 0, i - 1, i, i + 1, 0, n]; //
    // return [1, 0, i - 2, i - 1, i, i + 1, i + 2, 0, n];
    // return [1, 0, i, i + 1, i + 2, 0, n];
    return [1, 0, i - 1, i, i + 1, 0, n];
    /* eslint-enable */
  };

  const handleClick = (n) => {
    onPageChange(n);
  };

  const turnPage = (page) => {
    onPageChange(page);
  };
  return (
    <div className={`${styles.pageButtonGroup} ${className}`}>
      {!isFirstPage() && (
        <Button
          className={styles.pageButtonStartEnd}
          icon="angle left"
          onClick={() => turnPage(currentPage - 1)}
        />
      )}
      {dspBtns().map((page, i) => {
        return (
          <>
            {page !== 0 && (
              <button
                key={i}
                className={
                  currentPage === page
                    ? styles.pageButtonSelected
                    : styles.pageButton
                }
                onClick={() => {
                  handleClick(page);
                }}
              >
                {page}
              </button>
            )}
            {page === 0 && (
              <div key={i} className={styles.pageButton}>
                {`...`}{" "}
              </div>
            )}
          </>
        );
      })}
      {!isLastPage() && (
        <Button
          className={styles.pageButtonStartEnd}
          icon="angle right"
          onClick={() => turnPage(currentPage + 1)}
        />
      )}
    </div>
  );
};

export default Pagination;
