import axios from "axios";

axios.defaults.baseURL = process.env.REACT_APP_QIP_API_URL;
axios.defaults.headers = {
  "Access-Control-Allow-Origin": "*",
  "Content-Type": "application/json",
};

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // TODO: create an interceptor for response errors

    if (error.response && error.response.data) {
      if (error.response.status === 401) {
        localStorage.clear();
        window.location.reload();
      }
      return Promise.reject(error);
    }

    return Promise.reject(error);
  }
);

export function setAxios(token: string) {
  if (!axios.defaults.headers.common) return;
  if (token)
    axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
  else delete axios.defaults.headers.common["Authorization"];
}

export default axios;
