import * as React from "react";

import PropTypes from "prop-types";
import { Button, Modal } from "semantic-ui-react";
import { FaDownload } from "react-icons/fa";
import styles from "./ReportRecord.module.css";
import BlueButton from "../BlueButton";
import { deleteReport, downloadReport } from "../../api/requests";
import DeleteReportModal from "../Modals/DeleteReportModal";
import { FaSpinner } from "react-icons/fa";

const { useState, useRef } = React;

function ReportRecord({ reportData, onRemove }) {
  const [reportRemovedModalVis, setReportRemovedModalVis] = useState(false);
  const [removeReportModalVis, setRemoveReportModalVisModalVis] =
    useState(false);

  const reportRef = useRef();

  const [downloadingReport, setDownloadingReport] = useState(false);

  const [reportFile, setReportFile] = useState("");
  const [reportName, setReportName] = useState("");

  const parseNQAFilters = (filterString) => {
    const areas = filterString.substring(3).split(",");

    return areas.join(", ");
  };

  const onDownloadReport = async () => {
    setDownloadingReport(true);
    try {
      const reportResponseURL = await downloadReport(reportData.id);

      // const newWindow = window.open(
      //   reportData.url,
      //   "_blank",
      //   "noopener,noreferrer"
      // );
      // if (newWindow) newWindow.opener = null;

      // setReportName(
      //   reportResponseURL.substr(reportResponseURL.lastIndexOf("/") + 1)
      // );
      setReportName(reportResponseURL.file_name);

      let blob = await fetch(reportResponseURL.url).then((r) => r.blob());
      let reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onload = function () {
        setReportFile(reader.result);
        reportRef.current.click();
      };
      reader.onerror = function (error) {
        console.log(error);
      };

      setDownloadingReport(false);
    } catch (error) {
      console.log(error);
      setDownloadingReport(false);
    }
  };

  const onConfirmRemoveReport = async () => {
    try {
      await deleteReport(reportData.id);
    } catch (error) {
      console.log(error);
    }

    setReportRemovedModalVis(true);
  };

  const displayDateString = (dateString) => {
    const date = new Date(dateString);

    return date.toLocaleDateString();
  };

  return (
    <div className={`${styles.reportRecordContainer} flex justify-between`}>
      {downloadingReport && (
        <Modal
          size={`small`}
          className={`pt-14 pb-8 px-14`}
          centered={true}
          open={downloadingReport}
        >
          <div className={`w-full flex flex-col justify-center items-center`}>
            <FaSpinner
              className={`animate-spin text-qipGreen font-bold text-6xl`}
            />
            <div className={`text-3xl w-full text-center pb-5 mt-3`}>
              {/* QIP is being downloaded */}
              Your report is being downloaded
            </div>
          </div>

          {/* <div className={styles.progressBarContainer}>
            <div className={styles.progressBar} style={{ width: `100%` }} />
          </div>

          <div className={styles.progressBarText}>
            <text>Downloading</text>

            <text>100 %</text>
          </div> */}
        </Modal>
      )}

      {removeReportModalVis && (
        <DeleteReportModal
          visibility={removeReportModalVis}
          onOpenModal={() => setRemoveReportModalVisModalVis(true)}
          onCloseModal={() => setRemoveReportModalVisModalVis(false)}
          onRemoveReport={onConfirmRemoveReport}
        />
      )}
      {reportRemovedModalVis && (
        <Modal
          size="small"
          centered={true}
          open={reportRemovedModalVis}
          onOpen={() => setReportRemovedModalVis(true)}
          onClose={() => setReportRemovedModalVis(false)}
        >
          <div className={styles.modalContainer}>
            <text className={styles.modalHeaderText}>Report removed.</text>

            <BlueButton
              className={styles.blueButton}
              buttonText="Close"
              onClick={async () => {
                await onRemove();
                setReportRemovedModalVis(false);
                setRemoveReportModalVisModalVis(false);
              }}
            />
          </div>
        </Modal>
      )}
      <div className={styles.reportDetailsGroup}>
        <text className={styles.reportHeader}>{reportData.centerName}</text>

        <div className={styles.reportSubDetailsGroup}>
          <div className={styles.subDetailsTitle}>
            <text>Report type:</text>

            <text>NQA Area:</text>

            <text>Created by:</text>

            <text>Date created:</text>
          </div>

          <div className={styles.subDetailsData}>
            <text>{reportData.type}</text>

            <text>{parseNQAFilters(reportData.filters)}</text>

            <text>{reportData.createdBy}</text>

            <text>{reportData.createdAt.split("-").join("/")}</text>
          </div>
        </div>
      </div>
      <div className={`${styles.reportActionsGroup}`}>
        <div className={styles.reportActionsRow}>
          <div className={styles.reportButtonGroup}>
            <Button
              className={styles.reportTasksButton}
              onClick={onDownloadReport}
              icon="download"
            />
            <a
              ref={reportRef}
              href={reportFile}
              className={`hidden`}
              download={reportName}
            >
              {" "}
            </a>

            <text className={styles.reportButtonText}>Download</text>
          </div>
          {/*<div className={styles.reportButtonGroup}>
  
              <Button
                className={styles.reportEditButton}
                icon="mail"
                size="massive"
                onClick={onEmail}
              />
  
              <text className={styles.reportButtonText}>
                E-mail
              </text>
  
            </div>*/}
          <div className={styles.reportButtonGroup}>
            <Button
              className={styles.reportRemoveButton}
              icon="close"
              size="massive"
              onClick={() => setRemoveReportModalVisModalVis(true)}
            />

            <text className={styles.reportButtonText}>Remove</text>
          </div>
        </div>
      </div>
    </div>
  );
}

ReportRecord.propTypes = {
  reportData: PropTypes.shape({
    id: PropTypes.number,
    url: PropTypes.string,
    type: PropTypes.string,
    filters: PropTypes.string,
    createdBy: PropTypes.string,
    createdAt: PropTypes.string,
    centerName: PropTypes.string,
  }),
  onEmail: PropTypes.func,
  onRemove: PropTypes.func,
  onDownload: PropTypes.func,
};

export default ReportRecord;
