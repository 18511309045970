import * as React from "react";

import PropTypes from "prop-types";
import { Modal } from "semantic-ui-react";
import { useStoreState } from "../../../hooks";
import styles from "./FirstSignupWelcomeModal.module.css";
import BlueButton from "../../BlueButton";

function FirstSignupWelcomeModal({ visibility, onOpenModal, onCloseModal }) {
  const userId = useStoreState((state) => state.authStore.authObject.userId);

  return (
    <Modal
      size="small"
      centered
      closeOnDimmerClick={false}
      open={visibility}
      onOpen={onOpenModal}
      onClose={onCloseModal}
    >
      <div className={styles.modalContainer}>
        <div className={styles.headerClip}>
          <svg
            width="289"
            height="142"
            viewBox="0 0 289 142"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M207.224 7.71052L201.173 23.9396L190.144 10.5846L207.224 7.71052Z"
              fill="#8BC334"
            />
            <path
              d="M239.945 111.372L233.801 106.094L241.444 103.413L239.945 111.372Z"
              fill="#8BC334"
            />
            <path
              d="M9.11947 63.1871L8.53448 71.2657L1.83067 66.7198L9.11947 63.1871Z"
              fill="#8BC334"
            />
            <path
              d="M85.4634 119.301L99.7331 129.118L84.0965 136.567L85.4634 119.301Z"
              fill="#8BC334"
            />
            <path
              d="M252.014 31.4376L249.432 42.3365L241.284 34.6503L252.014 31.4376Z"
              fill="#E7E7E7"
            />
            <path
              d="M42.3586 56.927L49.9167 56.4094L46.5859 63.2137L42.3586 56.927Z"
              fill="#E7E7E7"
            />
            <path
              d="M203.689 133.127L186.919 128.795L199.056 116.437L203.689 133.127Z"
              fill="#FBD539"
            />
            <path
              d="M90.2741 24.4662L84.4414 8.15734L101.482 11.2605L90.2741 24.4662Z"
              fill="#FBD539"
            />
            <path
              d="M227.288 67.1859L224.255 79.8976L214.763 70.9153L227.288 67.1859Z"
              fill="#F56666"
            />
            <path
              d="M66.0522 70.7536L76.7979 63.3162L77.8661 76.3409L66.0522 70.7536Z"
              fill="#F56666"
            />
            <path
              d="M204.379 96.5962L204.208 89.9364L210.061 93.1187L204.379 96.5962Z"
              fill="#7D68FC"
            />
            <path
              d="M95.5659 49.3877L90.9578 44.5764L97.4286 42.9914L95.5659 49.3877Z"
              fill="#7D68FC"
            />
            <path
              d="M271.845 98.7767L262.28 98.2835L267.49 90.2469L271.845 98.7767Z"
              fill="#388CEF"
            />
            <path
              d="M30.97 28.6245L33.1106 37.9596L23.9559 35.1458L30.97 28.6245Z"
              fill="#388CEF"
            />
            <path
              d="M288.464 65.4823L281.796 69.4599L281.686 61.6964L288.464 65.4823Z"
              fill="#FCD53A"
            />
            <path
              d="M30.1232 102.527L34.8916 96.3989L37.8141 103.592L30.1232 102.527Z"
              fill="#FCD53A"
            />
            <g>
              <path
                d="M148.955 24H145.046V39.6522H148.955V24Z"
                fill="#388CEF"
              />
              <path
                d="M117.97 35.2253L115.206 37.9922L126.263 49.0598L129.027 46.2929L117.97 35.2253Z"
                fill="#388CEF"
              />
              <path
                d="M119.636 65.0859H104V68.999H119.636V65.0859Z"
                fill="#388CEF"
              />
              <path
                d="M126.268 85.0339L115.212 96.1016L117.976 98.8685L129.033 87.8008L126.268 85.0339Z"
                fill="#388CEF"
              />
              <path
                d="M167.737 85.0339L164.973 87.8008L176.029 98.8684L178.793 96.1015L167.737 85.0339Z"
                fill="#388CEF"
              />
              <path
                d="M190 65.0859H174.363V68.999H190V65.0859Z"
                fill="#388CEF"
              />
              <path
                d="M176.023 35.2175L164.967 46.2852L167.731 49.0521L178.787 37.9844L176.023 35.2175Z"
                fill="#388CEF"
              />
              <path
                d="M151.476 108.696L150.108 110.085H143.912L142.524 108.696C142.153 108.344 141.664 108.129 141.136 108.129H139.182V112.042H140.335L141.723 113.431C142.074 113.783 142.563 113.998 143.091 113.998H150.909C151.437 113.998 151.925 113.783 152.297 113.431L153.665 112.042H154.818V108.129H152.863C152.336 108.129 151.847 108.344 151.476 108.696Z"
                fill="#388CEF"
              />
              <path
                d="M158.727 96.3906H135.272V100.304H158.727V96.3906Z"
                fill="#388CEF"
              />
              <path
                d="M156.773 102.262H137.228V106.175H156.773V102.262Z"
                fill="#388CEF"
              />
              <path
                d="M135.348 33.7599L131.736 35.2578L134.729 42.4876L138.34 40.9896L135.348 33.7599Z"
                fill="#388CEF"
              />
              <path
                d="M115.252 51.7558L113.756 55.3711L120.979 58.3661L122.475 54.7509L115.252 51.7558Z"
                fill="#388CEF"
              />
              <path
                d="M120.971 75.7112L113.749 78.707L115.245 82.3219L122.468 79.3261L120.971 75.7112Z"
                fill="#388CEF"
              />
              <path
                d="M173.023 75.7168L171.527 79.332L178.751 82.3271L180.247 78.7118L173.023 75.7168Z"
                fill="#388CEF"
              />
              <path
                d="M178.752 51.7659L171.529 54.7617L173.026 58.3766L180.248 55.3808L178.752 51.7659Z"
                fill="#388CEF"
              />
              <path
                d="M158.659 33.7664L155.666 40.9961L159.277 42.494L162.27 35.2643L158.659 33.7664Z"
                fill="#388CEF"
              />
              <path
                d="M142.446 88.4463C142.661 88.5246 142.876 88.5637 143.091 88.5637C143.677 88.5637 144.244 88.3094 144.616 87.8203L160.252 68.255C160.721 67.6681 160.819 66.8659 160.487 66.1811C160.154 65.5159 159.47 65.0855 158.727 65.0855H152.903L153.137 53.9529C153.157 53.1311 152.649 52.3681 151.867 52.0746C151.105 51.7811 150.225 52.0159 149.678 52.642L133.768 71.6398C133.279 72.2268 133.182 73.029 133.494 73.7333C133.807 74.4377 134.511 74.8681 135.273 74.8681H141.137V86.6072C141.137 87.429 141.664 88.1724 142.446 88.4463ZM139.456 70.955L149.111 59.4311L148.955 67.0029C148.935 67.5311 149.15 68.0398 149.522 68.4116C149.873 68.7833 150.382 68.9985 150.909 68.9985H154.662L145.046 81.0311V72.9116C145.046 71.8355 144.166 70.955 143.091 70.955H139.456Z"
                fill="#388CEF"
              />
              <path
                d="M147 43.5664C134.061 43.5664 123.546 54.0925 123.546 67.0447C123.546 75.6925 128.569 80.7012 130.836 82.9708C132.478 84.6142 133.319 87.099 133.319 90.3468V94.436H137.228V90.3664C137.228 86.0425 136.016 82.6186 133.455 80.0751C131.364 77.9816 127.455 74.0686 127.455 67.0447C127.455 56.2642 136.231 47.4794 147 47.4794C157.77 47.4794 166.546 56.2642 166.546 67.0447C166.546 74.0686 162.637 77.9816 160.409 80.2121C157.985 82.6186 156.773 86.0425 156.773 90.3664V94.436H160.682V90.3664C160.682 87.1186 161.523 84.6338 163.301 82.8534C165.432 80.7012 170.455 75.6925 170.455 67.0447C170.455 54.0925 159.94 43.5664 147 43.5664Z"
                fill="#388CEF"
              />
            </g>
          </svg>
        </div>
        <div className={`${styles.headerText} mt-7 mb-2`}>
          Welcome to your Dashboard. Choose an NQA to get started.
        </div>
        <div className={styles.subText}>
          You can easily track the progress of each NQA here.
        </div>

        <div
          onClick={() => {
            localStorage.removeItem(`${userId}_completed`);
            onCloseModal();
          }}
          className={`${styles.closeButton} mt-10`}
        >
          Got&nbsp;it
        </div>
      </div>
    </Modal>
  );
}

FirstSignupWelcomeModal.propTypes = {
  visibility: PropTypes.bool.isRequired,
  onOpenModal: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired,
};

export default FirstSignupWelcomeModal;
