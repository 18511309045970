import * as React from "react";

import { useHistory } from "react-router-dom";
import { FaInfo } from "react-icons/fa";

import styles from "./LegalNotice.module.css";
import BlueButton from "../../components/BlueButton";
import { useStoreActions } from "../../hooks";

function LegalNotice() {
  const history = useHistory();

  useStoreActions((state) => state.appStore.setFirstSignUp(false));

  // const login = useStoreActions(actions => actions.authStore.login);
  // const signUpCreds = useStoreState(state => state.authStore.signUpCreds);

  // const [loginLoading, setLoading] = useState(false);

  // const onSkipServiceDetails = async () => {
  //   setLoading(true);
  //   const loginCreds = {
  //     email: signUpCreds.email,
  //     password: signUpCreds.password
  //   };

  //   const response = await login(loginCreds);

  //   if (!response) setLoading(false);
  // };

  return (
    <div className={styles.mainContainer}>
      <div className={styles.mainCard}>
        <div className={styles.cardContent}>
          <div className={styles.infoCircleContainer}>
            <FaInfo className={styles.infoCircle} color="#F56666" size={50} />
          </div>

          <h1 className={styles.headerText}>
            Before starting your QIP document let’s complete your Service
            details
          </h1>

          <text className={styles.descriptionText}>
            In order to send your QIP report to the Education Department you
            will need to complete your Service details. We’ve made this easy
            with simple steps. You can complete now or skip until later.
          </text>

          <BlueButton
            className={styles.blueButton}
            buttonText="Start now"
            onClick={() => history.push("/qip/auth/onboarding/")}
          />
        </div>
      </div>
    </div>
  );
}

export default LegalNotice;
