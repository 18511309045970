import * as React from "react";
import { FaRegArrowAltCircleLeft, FaSpinner } from "react-icons/fa";

import useSWR, { mutate } from "swr";
import { useHistory, useParams } from "react-router-dom";
import { Button, Icon, Input } from "semantic-ui-react";

import styles from "./TaskDashboard.module.css";

import { useStoreActions, useStoreState } from "../../../hooks";
import ChooseNQAModal from "../../../components/Modals/ChooseNQAModal";
import ManageTaskModal from "../../../components/Modals/ManageTaskModal";
import ChooseDateRangeModal from "../../../components/Modals/ChooseDateRangeModal";
import { fetchCenterUsers, fetchTasks } from "../../../api/requests";
import Pagination from "../../../components/Pagination/Pagination";
import moment from "moment";
import { isEmpty } from "lodash";

const { useEffect, useState, useCallback } = React;

const tasksPerPage = 10;

function TaskDashboard() {
  const history = useHistory();
  const { username } = useParams();

  const authObject = useStoreState((state) => state.authStore.authObject);
  // const services = useStoreState(state => state.authStore.user.services);
  const centerId = useStoreState((state) => state.nqaStore.serviceDetails.id);

  // const tasks = useStoreState(state => state.taskStore.tasks);
  const setSelectedTask = useStoreActions(
    (actions) => actions.taskStore.setSelectedTask
  );

  useStoreActions((actions) => actions.nqaStore.fetchNQAData);

  const getTaskInfo = useStoreActions((state) => state.taskStore.fetchTaskInfo);

  const [currentPage, setCurrentPage] = useState(1);

  const [toDate, setToDate] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [assigneeName, setName] = useState(username ? username : "");
  const [selectedNQAList, setSelectedNQAList] = useState([]);
  const [selectedTaskFilter, setSelectedTaskFilter] = useState(1);
  // const [selectedService, setSelectedService] = useState(services[0]);
  const nqaData = useStoreState((state) => state.nqaStore.NQAList);
  const [openingTaskModal, setOpeningTaskModal] = useState(false);
  const [currentTask, setCurrentTask] = useState({});

  const [chooseNQAModalVis, setNQAModalVis] = useState(false);
  const [chooseDateModalVis, setDateModalVis] = useState(false);
  const [manageTaskModalVis, setTaskModalVis] = useState(false);

  const [fetchLimitError, setFetchLimitError] = useState(false);

  const [fetchLimitError1, setFetchLimitError1] = useState(false);

  const userSelectedService = useStoreState(
    (state) => state.authStore.selectedService
  );

  const parseTaskStatus = (status) => {
    if (status === 1) return "Not started";
    else if (status === 2) return "In progress";

    return "Completed";
  };

  const parseTaskStatusStyle = (status) => {
    if (status === 1) return styles.statusNotStarted;
    else if (status === 2) return styles.statusInProgress;

    return styles.statusCompleted;
  };

  const fetchQuestionInfo = useCallback(
    async (currentInfo) => {
      if (nqaData) {
        await getTaskInfo({
          ...currentInfo,
          serviceCatId: userSelectedService.serviceCatId,
        });

        setOpeningTaskModal(false);
        setTaskModalVis(true);
      }
    },
    [nqaData]
  );

  const onOpenTask = (task) => {
    const taskNQAId = task.nqaId.split(".")[0];

    setOpeningTaskModal(true);
    setSelectedTask(task);
    fetchQuestionInfo({
      qId: task.questionId,
      taskType: task.type,
      nqaList: nqaData.find((nqa) => nqa.id === Number(taskNQAId)),
      centerId: task.centerId,
    });
  };

  const returnTaskFilter = () => {
    switch (selectedTaskFilter) {
      case 1:
        return "";

      case 2:
        return true;

      default:
        break;
    }
  };

  const setDateRange = (fromDate, toDate) => {
    setFromDate(fromDate);
    setToDate(toDate);
  };

  const displayDateString = (dateString) => {
    // const date = new Date(dateString);
    return moment(dateString, "YYYY-MM-DD hh:mm:ss").format("DD/MM/YYYY");

    // return date.toLocaleDateString();
  };

  const { data: tasks, isValidating } = useSWR(
    [
      "/tasks",
      centerId,
      assigneeName,
      selectedNQAList,
      fromDate,
      toDate,
      returnTaskFilter(),
    ],
    (_, centerId, assigneeName, nqaAreas, fromDate, toDate, overdue) =>
      fetchTasks(centerId, overdue, assigneeName, fromDate, toDate, nqaAreas),
    {
      onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
        // Only retry up to 5 times.
        if (retryCount && retryCount >= 5) {
          if (!tasks) setFetchLimitError(true);

          return;
        }
        // Retry after 3 seconds.
        setTimeout(() => revalidate({ retryCount: retryCount + 1 }), 3000);
      },
    }
  );

  const { data: users } = useSWR(
    [`/users`, centerId],
    (_, centerId) => fetchCenterUsers(centerId),
    {
      onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
        // Only retry up to 3 times.
        if (retryCount && retryCount >= 3) {
          if (!users) setFetchLimitError1(true);

          return;
        }
        // Retry after 3 seconds.
        setTimeout(() => revalidate({ retryCount: retryCount + 1 }), 3000);
      },
    }
  );

  useEffect(() => {
    if (isValidating) {
      setFetchLimitError(false);
      setCurrentPage(1);
    }
  }, [isValidating]);

  return (
    <div className={styles.mainContainer}>
      <div className={styles.backButtonContainer}>
        <button
          className={styles.backButtonText}
          onClick={() => history.goBack()}
        >
          <FaRegArrowAltCircleLeft
            className={styles.backButton}
            color="#C4C4C4"
            size={24}
          />
        </button>
      </div>

      {chooseNQAModalVis && (
        <ChooseNQAModal
          nqaList={selectedNQAList}
          visibility={chooseNQAModalVis}
          onSetAreas={setSelectedNQAList}
          onOpenModal={() => setNQAModalVis(true)}
          onCloseModal={() => setNQAModalVis(false)}
        />
      )}

      {chooseDateModalVis && (
        <ChooseDateRangeModal
          onSetDateFilter={setDateRange}
          visibility={chooseDateModalVis}
          onOpenModal={() => setDateModalVis(true)}
          onCloseModal={() => setDateModalVis(false)}
        />
      )}

      {manageTaskModalVis && (
        <ManageTaskModal
          centerId={centerId}
          visibility={manageTaskModalVis}
          onOpenModal={() => setTaskModalVis(true)}
          onCloseModal={async () => {
            await mutate([
              "/tasks",
              centerId,
              assigneeName,
              selectedNQAList,
              fromDate,
              toDate,
              returnTaskFilter(),
            ]);
            setTaskModalVis(false);
          }}
        />
      )}

      <div className={styles.content}>
        <h2 className={styles.headerText}>Tasks dashboard</h2>

        <text className={styles.headerSubText}>
          Control all tasks on all services from one place.
        </text>

        {/* {services.map((service, index) => (
          <div
            key={service.id}
            className={selectedService.id === service.id ? `${styles.servicesRow} ${styles.activeServiceRow}` : styles.servicesRow}
            onClick={() => setSelectedService(services[index])}
          >

            <text>
              {service.name}
            </text>

            <div>

              <text className={styles.inProgress}>
                {service.tasks.inProgress}
              </text>

              <text>
                In Progress,
              </text>

              <text className={styles.completed}>
                {service.tasks.completed}
              </text>

              <text>
                completed and
              </text>

              <text className={styles.notStarted}>
                {service.tasks.notStarted}
              </text>

              <text>
                not started tasks
              </text>

            </div>

            <div>

              <text className={selectedService.id === service.id ? `${styles.serviceButton} ${styles.activeServiceButton}` : styles.serviceButton}>
                {selectedService.id === service.id ? 'Active' : 'Open'}
              </text>

              <Button
                className={selectedService.id === service.id ? `${styles.serviceButtonIcon} ${styles.activeServiceButtonIcon}` : styles.serviceButtonIcon}
                icon={selectedService.id === service.id ? 'arrow down' : 'arrow right'}
                circular
              />

            </div>

          </div>
        ))}

        <h2 className={styles.serviceHeaderText}>
          Tasks for {selectedService.name}
        </h2> */}

        <div className={styles.searchFilterContainer}>
          <div className={styles.searchFilterRow}>
            <div onClick={() => setNQAModalVis(true)}>
              <text className={styles.searchFilterDropdownText}>
                {selectedNQAList.length > 0
                  ? `Areas: ${selectedNQAList.join(", ")}`
                  : "Choose NQA Areas"}
              </text>

              <Icon className={styles.caretDownIcon} name="caret down" />
            </div>

            <div onClick={() => setDateModalVis(true)}>
              <text className={styles.searchFilterDropdownText}>
                {/* {fromDate && toDate
                  ? `${fromDate} - ${toDate}`
                  : "Choose date range"} */}
                {fromDate && toDate
                  ? `${moment(fromDate).format("DD/MM/YYYY")} - ${moment(
                      toDate
                    ).format("DD/MM/YYYY")}`
                  : "Choose date range"}
              </text>

              <Icon className={styles.caretDownIcon} name="caret down" />
            </div>

            <Input
              className={styles.searchBox}
              icon="search"
              placeholder="Assignee name"
              value={assigneeName}
              onChange={(_, data) => setName(data.value)}
            />
          </div>

          <div className={styles.searchFilterButtonGroup}>
            <button
              className={
                selectedTaskFilter === 1
                  ? styles.selectedTaskFilterButton
                  : styles.taskFilterButton
              }
              onClick={() => setSelectedTaskFilter(1)}
            >
              All tasks
            </button>

            <button
              className={
                selectedTaskFilter === 2
                  ? styles.selectedTaskFilterButton
                  : styles.taskFilterButton
              }
              onClick={() => setSelectedTaskFilter(2)}
            >
              Overdue tasks
            </button>

            {/*<button
              disabled
              style={{ pointerEvents: 'none', visibility:'hidden' }}
              className={selectedTaskFilter === 3 ? styles.selectedTaskFilterButton : styles.taskFilterButton}
              onClick={() => setSelectedTaskFilter(3)}
            >
              Upcoming tasks
            </button>

            <button
              disabled
              style={{ pointerEvents: 'none', visibility:'hidden' }}
              className={selectedTaskFilter === 4 ? styles.selectedTaskFilterButton : styles.taskFilterButton}
              onClick={() => setSelectedTaskFilter(4)}
            >
              Completed tasks
            </button>*/}
          </div>
        </div>

        <div className={styles.cardContainer}>
          <div className={styles.taskHeadersRow}>
            <div>
              <text>Task name</text>
            </div>

            <div>
              <text>NQA Area</text>
            </div>

            <div>
              <text>Assignee</text>
            </div>

            <div>
              <text>Assigner</text>
            </div>

            <div>
              <text>Task due</text>
            </div>

            <div>
              <text>Status</text>
            </div>
          </div>
          {fetchLimitError ? (
            <h3>
              Failed to load data. Please refresh the page and try again, or
              contact your system administrator.
            </h3>
          ) : !tasks ? (
            <div
              className={`w-full h-screen flex flex-col justify-center items-center`}
            >
              <FaSpinner
                className={`animate-spin text-qipGreen font-bold text-6xl`}
              />
              <span className={`block`}> Loading data...</span>
            </div>
          ) : !users ? (
            <div
              className={`w-full h-screen flex flex-col justify-center items-center`}
            >
              <FaSpinner
                className={`animate-spin text-qipGreen font-bold text-6xl`}
              />
              <span className={`block`}> Loading data...</span>
            </div>
          ) : (
            tasks
              .filter((task) =>
                users.some((user) => Number(user.id) === Number(task.asignee))
              )
              .slice(
                currentPage * tasksPerPage - tasksPerPage,
                currentPage * tasksPerPage
              )
              .map((task) => (
                <div className={styles.taskDataRow} key={task.id}>
                  <text>{task.name}</text>
                  <text>{task.nqaArea}</text>
                  <text>
                    {
                      users.filter(
                        (user) => Number(user.id) === Number(task.asignee)
                      )[0].name
                    }
                  </text>

                  <text>
                    {
                      users?.filter(
                        (user) => Number(user.id) === Number(authObject.userId)
                      )[0]?.name
                    }
                  </text>

                  <text>{displayDateString(task.taskDue)}</text>

                  <div>
                    <text className={parseTaskStatusStyle(task.status)}>
                      {parseTaskStatus(task.status)}
                    </text>

                    <button
                      disabled={openingTaskModal}
                      className={styles.taskOpenButton}
                      onClick={() => {
                        setCurrentTask(task);
                        onOpenTask(task);
                      }}
                    >
                      <>
                        {currentTask &&
                        openingTaskModal &&
                        currentTask.id === task.id ? (
                          <span
                            className={`flex justify-center items-center px-4`}
                          >
                            <FaSpinner className={`animate-spin`} />{" "}
                            {/* <span className={`ml-2`}>Open </span> */}
                          </span>
                        ) : (
                          <> Open</>
                        )}
                      </>
                    </button>
                  </div>
                </div>
              ))
          )}
          {tasks &&
            users &&
            tasksPerPage <
              tasks.filter((task) =>
                users.some((user) => Number(user.id) === Number(task.asignee))
              ).length && (
              <Pagination
                className={`pt-20`}
                lastPage={Math.ceil(
                  tasks.filter((task) =>
                    users.some(
                      (user) => Number(user.id) === Number(task.asignee)
                    )
                  ).length / tasksPerPage
                )}
                currentPage={currentPage}
                onPageChange={setCurrentPage}
              />
            )}
        </div>
      </div>
    </div>
  );
}

export default TaskDashboard;
