import * as React from 'react';

import PropTypes from 'prop-types';
import { Button, Modal } from 'semantic-ui-react';

import BlueButton from '../../BlueButton';
import styles from './PasswordSavedModal.module.css'; 

function PasswordSavedModal({ visibility, onOpenModal, onCloseModal, onSaveConfirm, content }) {
  return (
    <Modal
      size="tiny"
      centered={false}
      open={visibility}
      onOpen={onOpenModal}
      onClose={onCloseModal}
    >
      <div className={styles.modalContainer}>

        <Button
          className={styles.closeButton}
          icon="close"
          onClick={onCloseModal}
        />

        <text className={styles.headerText}>
          {
            content ? content : "Your password has been changed!"
          }
        </text>

        <BlueButton
          className={styles.filterButton}
          buttonText="Ok"
          onClick={onSaveConfirm}
        />

      </div>
    </Modal>
  );
}

PasswordSavedModal.propTypes = {
  visibility: PropTypes.bool.isRequired,
  onOpenModal: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  onSaveConfirm: PropTypes.func,
  content: PropTypes.String,
};

export default PasswordSavedModal;
