import * as React from "react";

import PropTypes from "prop-types";
import { Button, Modal } from "semantic-ui-react";
import { useHistory } from "react-router-dom";

import BlueButton from "../../BlueButton";
import styles from "./UpgradeSubscriptionModal.module.css";

function UpgradeSubscriptionModal({ visibility, onOpenModal, onCloseModal }) {
  const history = useHistory();

  return (
    <Modal
      size="small"
      centered
      closeOnDimmerClick={false}
      open={visibility}
      onOpen={onOpenModal}
      onClose={onCloseModal}
    >
      <div className={styles.modalContainer}>
        <Button
          className={styles.closeButton}
          // onClick={onCloseModal}
          onClick={() => {
            history.push(`/qip/user-management/billing/2`);
          }}
          size="big"
          icon="close"
        />

        <text className={styles.headerText}>
          Thank you for upgrading your subscription. You now have access to all
          the QIP + Mentoring features and benefits. Your updated subscription
          payment will show on your next payment.
        </text>

        <BlueButton
          className={styles.saveButton}
          buttonText="Close"
          onClick={() => {
            history.push(`/qip/user-management/billing/2`);
          }}
          // onClick={onCloseModal}
        />
      </div>
    </Modal>
  );
}

UpgradeSubscriptionModal.propTypes = {
  visibility: PropTypes.bool.isRequired,
  onOpenModal: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired,
};

export default UpgradeSubscriptionModal;
