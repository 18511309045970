import * as React from "react";

import PropTypes from "prop-types";
import { Route, useHistory, useRouteMatch } from "react-router-dom";
import { Button, Dropdown, Icon, Modal } from "semantic-ui-react";

import styles from "./MessageView.module.css";
import BlueButton from "../../../components/BlueButton";
import InputComponent from "../../../components/InputComponent";
import attachment_img from "../../../static/images/attachment_placeholder.png";
import { readMessage } from "../../../api/requests";
import { useStoreActions, useStoreState } from "../../../hooks";
import useSWR, { mutate } from "swr";
import { FaSpinner } from "react-icons/fa";

const { useEffect, useRef, useState, useCallback } = React;

function MessageView({
  message = {},
  onDelete,
  onDownload,
  loadingView = false,
}) {
  // const { messageId } = useParams();
  const history = useHistory();
  const replyDivRef = useRef(null);

  const userId = useStoreState((state) => state.authStore.authObject.userId);

  const [replyVisibility, setReplyVisibility] = useState(false);
  const [replyModalVisibility, setReplyModalVisibility] = useState(false);

  const onReply = () => {
    setReplyVisibility(true);

    if (replyDivRef.current)
      replyDivRef.current.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    if (replyDivRef.current)
      replyDivRef.current.scrollIntoView({ behavior: "smooth" });
  }, [replyVisibility]);

  return (
    <>
      <div className={styles.mainContainer}>
        <Modal
          size="small"
          centered={false}
          open={replyModalVisibility}
          onOpen={() => setReplyModalVisibility(true)}
          onClose={() => setReplyModalVisibility(false)}
        >
          <div className={styles.modalContainer}>
            <text className={styles.modalHeaderText}>
              Your reply was sent to username
            </text>

            <BlueButton
              className={styles.blueButton}
              buttonText="Ok"
              onClick={() => {
                setReplyModalVisibility(false);
                setReplyVisibility(false);
              }}
            />
          </div>
        </Modal>
        {loadingView ? (
          <div className={`w-full flex flex-col justify-center items-center`}>
            <FaSpinner
              className={`animate-spin text-qipGreen font-bold text-6xl`}
            />

            <div>Loading message...</div>
          </div>
        ) : (
          <>
            <div className={styles.headerGroup}>
              <Button className={styles.unreadIcon} icon="mail" />

              <div className={styles.headerTextColumn}>
                <text className={styles.headerText}>
                  {message ? message.subject : ""}
                </text>

                <div className={styles.headerSubText}>
                  <text>Sent by</text>

                  <text>{message ? message.message_from_name : ""}</text>

                  <text>
                    (Admin) sent on{" "}
                    {message ? message.created_at.split("-").join("/") : ""}
                  </text>
                </div>
              </div>
            </div>

            <div className={styles.cardContent}>
              <text className={styles.messageContent}>
                {message ? (
                  <div
                    className={`disabledPreflight`}
                    dangerouslySetInnerHTML={{ __html: message.content }}
                  ></div>
                ) : (
                  ""
                )}
              </text>

              {/* <div className={styles.attachmentsHeader}>
            <Button className={styles.attachmentIcon} icon="attach" />

            <text className={styles.attachmentText}>Attached files</text>
          </div> */}

              {/* <div className={styles.attachmentsGroup}>
            {[...Array(3).keys()].map((row) => (
              <div className={styles.attachmentRow} key={row}>
                <div className={styles.attachmentDisplay}>
                  <img alt="attachment preview" src={attachment_img} />

                  <div className={styles.attachmentDetails}>
                    <text className={styles.attachmentFileName}>
                      NQA{row + 1}_Document_2020.pdf
                    </text>

                    <text className={styles.attachmentSize}>Size: 1.5 Mb</text>
                  </div>
                </div>

                <div className={styles.downloadButtonGroup}>
                  <Button
                    className={styles.downloadButton}
                    onClick={onDownload}
                    icon="download"
                  />

                  <text className={styles.dowloadButtonText}>Download</text>
                </div>
              </div>
            ))}
          </div> */}

              <div className={styles.actionButtonGroup}>
                {/* <BlueButton height="60px" onClick={onReply} buttonText="Reply" />

            <button className={styles.forwardButton}>Forward</button>

            <button className={styles.starButton}>Star</button> */}

                <button
                  className={styles.deleteButton}
                  onClick={() => {
                    onDelete(message);
                  }}
                >
                  <text>Delete</text>

                  <Icon name="close" />
                </button>
                <button
                  onClick={() => history.goBack()}
                  className={styles.forwardButton}
                >
                  Back to messages
                </button>
              </div>
            </div>
          </>
        )}
      </div>

      {/* {replyVisibility && (
        <div className={styles.replyContainer} ref={replyDivRef}>
          <div className={styles.replyContent}>
            <text className={styles.replyHeaderText}>
              Reply to: Attention all users, update your service details
            </text>

            <div className={styles.replyHeaderSubText}>
              <text>To</text>

              <text>Charlotte Wilson</text>

              <text>(Admin)</text>
            </div>

            <div className={styles.dropdownGroup}>
              <text className={styles.dropdownText}>
                Add users to conversation
              </text>

              <Dropdown
                className={styles.dropdownBox}
                placeholder="Choose"
                selection
              />
            </div>

            <button className={styles.addMoreUsersButton}>
              Add more users
            </button>

            <InputComponent
              className={styles.replyInputBox}
              label="Write your reply"
            />

            <BlueButton
              onClick={() => setReplyModalVisibility(true)}
              className={styles.blueButton}
              buttonText="Send reply"
            />
          </div>
        </div>
      )} */}
    </>
  );
}

MessageView.propTypes = {
  onDelete: PropTypes.func,
  onDownload: PropTypes.func,
};

export default MessageView;
