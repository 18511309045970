import * as React from "react";

import { useHistory, useRouteMatch, useParams } from "react-router-dom";
import {
  FaRegArrowAltCircleLeft,
  FaChevronRight,
  FaChevronLeft,
} from "react-icons/fa";
import styles from "./ResourceTutorial.module.css";
import prod1 from "../../static/images/critical-reflection-the-holistic-way.jpg";
import { Button, Button as IconButton } from "semantic-ui-react";
import BlueButton from "../../components/BlueButton";
import { imageAppTutorialLink } from "../../api/requests";
import { isEmpty } from "lodash";
import moment from "moment";
import ReactPlayer from "react-player/lazy";

const { useState, useEffect } = React;

export default function ResourceTutorial({
  resourceTutorials,
  currentId,
  setViewAll,
}) {
  const history = useHistory();
  const match = useRouteMatch();
  const { webinarsChoiceId } = useParams();
  const [selectedOption, setOption] = useState(parseInt(2));
  const [nqaVisibility, setNQAVisibility] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(
    resourceTutorials.indexOf(
      resourceTutorials.filter((r) => r.id === currentId)[0]
    )
  );
  const [tutorialLink, setTutorialLink] = useState("");
  const [tutorialLength, setTutorialLength] = useState(
    !isEmpty(resourceTutorials) ? resourceTutorials.length : 0
  );
  const [loading, setLoading] = useState(false);

  const [ids, setIds] = useState(resourceTutorials.map((r) => r.id));

  const onBackToResources = () => {
    const currentPath = match.path.split("/");
    const dashboardPath = currentPath
      .slice(0, currentPath.length - 1)
      .join("/");

    history.push(dashboardPath);
  };

  const getImageTutorialLink = async (id) => {
    try {
      setLoading(true);
      const response = await imageAppTutorialLink(Number(id));
      setTutorialLink(response);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    getImageTutorialLink(currentId);
  }, [currentId]);

  return (
    <div className={styles.mainContainer}>
      <div className={styles.knowledgebaseContent}>
        <div
          className={styles.webinarContainer}
          //   style={{ backgroundImage: `url(${tutorialLink})` }}
        >
          <ReactPlayer
            url={
              resourceTutorials[selectedIndex].tutorial_link
                ? resourceTutorials[selectedIndex].tutorial_link
                : ""
            }
            width={`100%`}
            height={`100%`}
          />

          {/* <div className={styles.webinarTextGroup}>
            <text className={styles.webinarLabel}>Webinars</text>

            <text className={styles.webinarHeaderTitle}>
            
              {resourceTutorials[selectedIndex].tutorial_name}
            </text>
          </div> */}

          {/* <Button
            className={styles.playButton}
            size="massive"
            icon="play"
            circular
          /> */}
        </div>

        <div className={styles.webinarDescriptionRow}>
          <div className={styles.webinarDescriptionTextColumn}>
            <text className={styles.webinarDescriptionHeader}>
              Tutorial description
            </text>

            {/* <text className={`text-xl block font-semibold mb-6`}>
              Viewing time: 3 minutes
            </text> */}

            <div
              dangerouslySetInnerHTML={{
                __html: resourceTutorials[selectedIndex].tutorial_description,
              }}
            ></div>
          </div>

          <div className={styles.webinarDetailsColumn}>
            <text className={styles.webinarDetailLabel}>Tutorial by</text>

            <text className={styles.webinarDetailText}>
              {resourceTutorials[selectedIndex].tutorial_author}
            </text>

            <text className={styles.webinarDetailLabel}>Posted on</text>

            <text className={styles.webinarDetailText}>
              {moment(resourceTutorials[selectedIndex].created_at).format(
                "DD/MM/YYYY"
              )}
            </text>
          </div>
        </div>
      </div>

      <div className={`${styles.resourcesPagination} flex justify-between`}>
        <Button
          disabled={loading}
          onClick={async () => {
            if (selectedIndex > 0) {
              await getImageTutorialLink(
                resourceTutorials[selectedIndex - 1].id
              );
              setSelectedIndex(selectedIndex - 1);
            }
          }}
        >
          <FaChevronLeft className={`mr-1`} size={12} />
          Previous resource
        </Button>

        <Button
          onClick={() => {
            setViewAll();
          }}
        >
          All resources
        </Button>

        <Button
          disabled={loading}
          onClick={async () => {
            if (selectedIndex < ids.length - 1) {
              await getImageTutorialLink(
                resourceTutorials[selectedIndex + 1].id
              );
              setSelectedIndex(selectedIndex + 1);
            }
          }}
        >
          Next resource
          <FaChevronRight className={`ml-1`} size={12} />
        </Button>
      </div>
    </div>
  );
}
