import * as React from "react";

import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { Button, Icon } from "semantic-ui-react";

import styles from "./UserCard.module.css";
import DeleteUserModal from "../Modals/DeleteUserModal";
import UserRemovedModal from "../Modals/UserRemovedModal";
import { deleteUser, fetchUserCenters, removeUser } from "../../api/requests";
import { useStoreActions, useStoreState } from "../../hooks";
import useSWR, { mutate } from "swr";
import { isEmpty } from "lodash";

const { useState, useEffect } = React;

function UserCard({ userData, finishLoading }) {
  const history = useHistory();

  const service = useStoreState((state) => state.authStore.selectedService);
  const loggedInUser = useStoreState((state) => state.userStore.loggedInUser);

  const setSelectedUser = useStoreActions(
    (actions) => actions.userStore.setSelectedUser
  );

  const isAdmin = useStoreState((state) => state.authStore.isAdmin);

  const adminAttr = useStoreState((state) => state.authStore.adminAttr);

  const [userDeletedModalVis, setUserDeletedModalVis] = useState(false);
  const [deleteModalVisibility, setDeleteModalVisibility] = useState(false);
  console.log("admin ", adminAttr);
  const onDeleteUser = () => {
    if (userData.id === loggedInUser.id) {
      // alert('Cannot delete user.');
    } else {
      setDeleteModalVisibility(true);
    }
  };

  const onUserDeleteConfirm = async () => {
    try {
      const response = await deleteUser(
        Number(userData.id),
        Number(service.id),
        Number(adminAttr.admin_id)
      );
      // const response = await removeUser(Number(userData.id));

      if (response) {
        setDeleteModalVisibility(false);
        setUserDeletedModalVis(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onEditButtonSelect = () => {
    setSelectedUser(userData);
    history.push("/qip/user-management/update-user");
  };

  const { data: userCenters, isValidating } = useSWR(
    ["/user/centers", userData.id],
    (_, user_id) => fetchUserCenters(userData.id),
    {
      onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
        // Only retry up to 3 times.
        if (retryCount && retryCount >= 3) {
          if (!userCenters) setFetchLimitError(true);

          return;
        }
        // Retry after 3 seconds.
        setTimeout(() => revalidate({ retryCount: retryCount + 1 }), 3000);
      },
    }
  );

  return (
    <div className={`${styles.userCardContainer} flex justify-between`}>
      <div className={styles.userCardTextGroup}>
        <text className={styles.userCardHeaderText}>
          {`${userData.name.split(" ")[0]} ${userData.name.split(" ")[1]}`}{" "}
          {userData.role === "admin" ? (
            <span className={`text-red-400`}>(account owner)</span>
          ) : (
            ""
          )}
        </text>

        <div className={styles.userCardSubTextGroup}>
          <text className={styles.userCardSubTextLeft}>Assigned to:</text>

          <div className={styles.userCardSubTextServices}>
            {userCenters &&
              userCenters.map((service) => {
                return (
                  <div>
                    <Icon
                      className={styles.userCardSubTextServiceCheck}
                      name="check circle outline"
                      size="large"
                    />

                    <text className={styles.userCardSubTextRight}>
                      {service.name}
                    </text>
                  </div>
                );
              })}
          </div>
        </div>
      </div>

      <div className={styles.userCardActionsGroup}>
        <div className={styles.userCardActionsRow}>
          <div className={styles.userCardButtonGroup}>
            <Button
              className={styles.userCardTasksButton}
              icon="flag checkered"
              onClick={() => history.push(`/qip/tasks/${userData.name}`)}
            />

            <text className={styles.userCardButtonText}>Tasks</text>
          </div>

          <div className={styles.userCardButtonGroup}>
            <Button
              className={styles.userCardEditButton}
              icon="edit"
              size="massive"
              onClick={onEditButtonSelect}
            />

            <text className={styles.userCardButtonText}>Edit</text>
          </div>

          {isAdmin && userData.role !== "admin" && (
            <div className={styles.userCardButtonGroup}>
              <Button
                className={styles.userCardRemoveButton}
                icon="close"
                size="massive"
                onClick={onDeleteUser}
              />

              <text className={styles.userCardButtonText}>Remove</text>
            </div>
          )}
        </div>
      </div>

      {deleteModalVisibility && (
        <DeleteUserModal
          visibility={deleteModalVisibility}
          onOpenModal={() => setDeleteModalVisibility(true)}
          onCloseModal={() => setDeleteModalVisibility(false)}
          onRemoveUser={onUserDeleteConfirm}
        />
      )}

      {userDeletedModalVis && (
        <UserRemovedModal
          visibility={userDeletedModalVis}
          onOpenModal={() => setUserDeletedModalVis(true)}
          onCloseModal={() => {
            finishLoading();
            setUserDeletedModalVis(false);
            setDeleteModalVisibility(false);
          }}
        />
      )}
    </div>
  );
}

UserCard.propTypes = {
  userData: PropTypes.shape({
    id: PropTypes.number,
    username: PropTypes.string,
    email: PropTypes.string,
    name: PropTypes.string,
    role: PropTypes.string,
    // centers: PropTypes.array,
    streetAddress: PropTypes.string,
    suburb: PropTypes.string,
    state: PropTypes.string,
    postalCode: PropTypes.string,
    telNum: PropTypes.string,
    mobileNum: PropTypes.string,
    additionalEmail: PropTypes.string,
  }),
  finishLoading: PropTypes.func.isRequired,
};

export default UserCard;
