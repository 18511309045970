import * as React from 'react';

import { Route, Switch, useRouteMatch } from 'react-router-dom';

import ResourceCenter from '../../pages/UserResource/ResourceCenter';
import ResourceCenterWebinars from '../../pages/UserResource/ResourceCenterWebinars';

export default function UserResource() {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route
        exact
        path={`${match.path}`}
        component={ResourceCenter}
      />
      <Route
        exact
        path={`${match.path}/updated-webinars/:webinarsChoiceId`}
        component={ResourceCenterWebinars}
      />
    </Switch>
  );
}
