/* eslint-disable @typescript-eslint/no-explicit-any */
// we allow any in case of any changes to the backend
import axios from "../services/axios";
import {
  AuthObject,
  LoginCreds,
  NQA,
  NQALevel,
  NQAQuestion,
  ProgressResponse,
  RawNQAData,
  ServiceDetails,
  SignUpData,
  SignUpResponse,
  Task,
  UploadResponse,
  UploadData,
  NQASubLevel,
  EvidenceData,
  UserResponse,
  CreateUserData,
  Report,
  Feedback,
  AdminAttr,
  CustomQuestionPayload,
  InitialServiceDetails,
  SATPayload,
  LawsRegPayload,
} from "../types";
import { isEmpty } from "lodash";

import { app_cache } from "../utils";
import moment from "moment";

const accessId = (process.env.ACCESS_ID = "3");
const accessKey = (process.env.ACCESS_KEY =
  "87OF7fxU9k8Oqg1CPMxVUeRB0zHpvn0nx2dbALuD");
const userIdentity = JSON.parse(localStorage.getItem("inteli_token")!)?.userId;

export async function registerUser(data: SignUpData): Promise<SignUpResponse> {
  const payload = {
    price: data.price,
    name: `${data.firstName} ${data.lastName}`,
    email: data.email,
    phone: data.phoneNumber,
    password: data.password,
    user_type: "ADMIN",
    service_name: data.serviceName,
    service_app_num: data.serviceApprovalNumber
      ? data.serviceApprovalNumber
      : "#",
    service_cat: data.serviceCategory,
    center_user_type: "admin",
    preferred_date: data.preferredDate,
    preferred_time: data.preferredTime,
    client_id: accessId,
    client_secret: accessKey,
  };

  const response = await axios.post("/api/register", payload);

  let cachtoken = {
    userId: response.data.id,
    accessToken: response.data.accessToken,
  };

  localStorage.setItem("inteli_token", JSON.stringify(cachtoken));

  return {
    status: response.status,
    userId: response.data.id,
    centerId: response.data.center_id,
  };
}

export async function createNewUser(data: CreateUserData) {
  const payload = {
    name: data.name,
    email: data.email,
    user_type: data.userType,
    center_user_type: data.role,
    password: data.password,
    street_address: data.streetAddress,
    suburb: data.suburb,
    state: data.state,
    postal_code: data.postalCode,
    telnum: data.telNum,
    mobilenum: data.mobileNum,
    add_email: data.additionalEmail,
    center_ids: data.centerIds,
    center_id: data.centerId,
    client_id: accessId,
    client_secret: accessKey,
  };

  const response = await axios.post("/api/register", payload);

  if (response.status === 200 || response.status === 201) return true;

  return false;
}

export async function updateUserDetails(userDetails: UserResponse) {
  const payload = {
    name: userDetails.name,
    username: userDetails.username,
    email: userDetails.email,
    user_type: userDetails.userType,
    center_user_type: userDetails.role,
    street_address: userDetails.streetAddress,
    suburb: userDetails.suburb,
    state: userDetails.state,
    postal_code: userDetails.postalCode,
    telnum: userDetails.telNum,
    mobilenum: userDetails.mobileNum,
    add_email: userDetails.additionalEmail,
    center_ids: userDetails.centerIds,
    // center_id: userDetails.centerId,
    client_id: accessId,
    client_secret: accessKey,
  };
  let token: any = JSON.parse(localStorage.getItem("inteli_token")!);
  let config = {
    headers: {
      Authorization: "Bearer " + token.accessToken,
    },
  };

  const response = await axios.put(
    `/api/user/${userDetails.id}`,
    payload,
    config
  );

  if (response.status === 200 || response.status === 201) return true;

  return false;
}

export async function deleteUser(
  userId: number,
  centerId: number,
  adminId: number
) {
  let token: any = JSON.parse(localStorage.getItem("inteli_token")!);
  let config = {
    headers: {
      Authorization: "Bearer " + token.accessToken,
    },
  };

  let payload = {
    user_id: userId,
    center_id: centerId,
    admin_id: adminId,
  };

  const response = await axios.delete(`/api/user`, {
    data: { ...payload },
    headers: { ...config.headers },
  });

  if (response.status === 200 || response.status === 201) return true;

  return false;
}

export async function removeUser(userId: number) {
  let token: any = JSON.parse(localStorage.getItem("inteli_token")!);
  let config = {
    headers: {
      Authorization: "Bearer " + token.accessToken,
    },
  };

  const response = await axios.get(`/api/finduser/${userId}`, config);

  if (response.status === 200 || response.status === 201) return true;

  return false;
}

export async function restoreUser(userId: number, centerId: number) {
  let token: any = JSON.parse(localStorage.getItem("inteli_token")!);
  let config = {
    headers: {
      Authorization: "Bearer " + token.accessToken,
    },
  };

  const payload = {
    user_id: userId,
    center_id: centerId,
  };

  const response = await axios.post(`/api/restore`, payload, config);

  if (response.status === 200 || response.status === 201) return true;

  return false;
}

export async function fetchAuthObject(data: LoginCreds): Promise<AuthObject> {
  const loginPayload = {
    password: data.password,
    grant_type: "password",
    username: data.email,
    client_id: accessId,
    client_secret: accessKey,
    remember_me: 0,
  };

  const response = await axios.post("/oauth/token", loginPayload);

  app_cache.token = response.data.access_token;
  app_cache.loggedIn = true;

  let config = {
    headers: {
      Authorization: "Bearer " + app_cache.token,
    },
  };

  const response2 = await axios.get("/api/user", config);

  return {
    userId: response2.data.id,
    stripeId: response2.data.stripe_id,
    createdAt: response2.data.created_at,
    expiresAt: response.data.expires_at,
    accessToken: response.data.access_token,
  };
}

export async function fetchAllUsers(): Promise<UserResponse[]> {
  let token: any = JSON.parse(localStorage.getItem("inteli_token")!);

  let config = {
    headers: {
      Authorization: "Bearer " + token.accessToken,
    },
  };

  const response = await axios.get("/api/service", config);

  return response.data.map((user: any) => ({
    id: user.id,
    name: user.name,
    email: user.email,
    phone: user.phone,
    userType: user.user_type,
  }));
}

export async function fetchCenterUsers(
  centerId: number
): Promise<UserResponse[]> {
  let token: any = JSON.parse(localStorage.getItem("inteli_token")!);

  let config = {
    headers: {
      Authorization: "Bearer " + token.accessToken,
    },
  };

  const response = await axios.get(`/api/center/${centerId}/users`, config);

  return response.data.map((user: any) => ({
    id: Number(user.user_id),
    name: user.name ? user.name : "Center User",
    role: user.type,
    email: user.email,
    phone: user.phone,
    username: user.username,
    userType: user.type === "admin" ? "ADMIN" : "USER",
    centerId: user.center_id,
    streetAddress: user.street_address ? user.street_address : "",
    suburb: user.suburb ? user.suburb : "",
    state: user.state ? user.state : "",
    postalCode: user.postal_code ? user.postal_code : "",
    telnum: user.telnum ? user.telnum : "",
    mobilenum: user.mobilenum ? user.mobilenum : "",
    additionalEmail: user.add_email ? user.add_email : "",
    rememberToken: user.remember_token ? user.remember_token : "",
  }));
}

export async function fetchUserCenters(
  userId: number
): Promise<ServiceDetails[]> {
  let token: any = JSON.parse(localStorage.getItem("inteli_token")!);
  if (!token) window.location.reload();

  let config = {
    headers: {
      Authorization: "Bearer " + token.accessToken,
    },
  };

  const res = await axios.get(
    `/api/user/${userId}/centers/subscription`,
    config
  );

  const response = res.data.user_centers.original;

  const centerData = res.data.user_details.original;

  const subData = res.data.subscription.original;

  const checkedServices = response
    .filter((r: any) => r.has_qip === 1)
    .map((data: any) => {
      const datetime_end_sub_unix = subData.products
        ? subData.products.some(
            (product: any) => Number(product.center_id) === Number(data.id)
          )
          ? subData.products.filter(
              (p: any) => Number(p.center_id) === Number(data.id)
            )[0].subscription.current_period_end
          : false
        : false;

      let datetime_end = "";

      if (
        datetime_end_sub_unix === undefined ||
        datetime_end_sub_unix === false
      ) {
        datetime_end = centerData.centers.filter(
          (center: any) => Number(center.center_id) === Number(data.id)
        )[0].datetime_end;
      } else {
        datetime_end = moment.unix(datetime_end_sub_unix).format("DD-MM-YYYY");
      }

      let subStatus: any = {};

      const subStatusCheck = subData.products
        ? subData.products.filter(
            (sub: any) => Number(sub.center_id) === Number(data.id)
          )
          ? subData.products.filter(
              (sub: any) => Number(sub.center_id) === Number(data.id)
            )[0]
          : {}
        : {};

      if (subStatusCheck !== undefined) {
        subStatus = subStatusCheck;
      }

      const failedPayment = !isEmpty(subStatus)
        ? subStatus.subscription.status === "incomplete_expired" ||
          subStatus.subscription.status === "incomplete" ||
          subStatus.subscription.status === "past_due" ||
          subStatus.subscription.status === "unpaid"
        : false;

      // const failedPayment = true;

      const dt1 = datetime_end.split("-");
      //date string: 26-04-2022

      const year1 = Number(dt1[2]);
      const month1 = Number(dt1[1].replace(/^0+/, ""));
      const day1 = Number(dt1[0].replace(/^0+/, ""));

      const date1 = new Date(year1, month1 - 1, day1, 0, 0, 0);

      const date2 = new Date();
      const diffTime = Number(date1) - Number(date2);

      const dayDiffTime = Math.ceil(diffTime / (1000 * 60 * 60 * 24)) + 1;
      // const dayDiffTime = -8;

      const getpFDayDiff = () => {
        if (failedPayment) {
          const dateUnix = subData.products.some(
            (product: any) => Number(product.center_id) === Number(data.id)
          )
            ? subData.products.filter(
                (p: any) => Number(p.center_id) === Number(data.id)
              )[0].subscription.current_period_start
            : -1;

          const date = moment.unix(dateUnix);
          const dateNow = moment.unix(Math.floor(Date.now() / 1000));

          const diff = Math.ceil(moment.duration(date.diff(dateNow)).asDays());

          return diff;
        } else {
          return 1000;
        }
      };

      const trialRemainingBanner = {
        show: dayDiffTime <= 5 && dayDiffTime > 0 && isEmpty(subStatus),
        remaining: dayDiffTime,
      };

      const showPaymentFailedBanner =
        !isEmpty(subStatus) &&
        failedPayment &&
        getpFDayDiff() <= 0 &&
        getpFDayDiff() > -30;

      const canStillAccessTrial = isEmpty(subStatus) && dayDiffTime > 0;

      const canStillAccessSub =
        !isEmpty(subStatus) && subStatus.subscription.status === "active";

      const redirectToPricingPlanPage =
        (failedPayment && getpFDayDiff() < -30) ||
        (!isEmpty(subStatus) && subStatus.subscription.status === "canceled");

      const active = canStillAccessTrial || canStillAccessSub;

      const overdue =
        failedPayment && getpFDayDiff() >= -30 && getpFDayDiff() <= -7;

      const notOverdue = failedPayment && getpFDayDiff() > -7;

      const resubscribe =
        (!isEmpty(subStatus) && failedPayment && getpFDayDiff() < -30) ||
        (!isEmpty(subStatus) && subStatus.subscription.status === "canceled");

      const trialEnded = isEmpty(subStatus) && dayDiffTime <= 0;

      const status = active
        ? "active"
        : overdue
        ? "overdue"
        : notOverdue
        ? "notoverdue"
        : resubscribe
        ? "resubscribe"
        : trialEnded
        ? "subscribe"
        : "";

      return {
        id: data.id,
        name: data.name,
        approvalNumber: data.approval_number,
        serviceCatId: data.service_cat_id,
        locationStreet: data.location_street,
        locationSuburb: data.location_suburb,
        locationState: data.location_state,
        locationPostalCode: data.location_postal_code,
        postalStreet: data.postal_street,
        postalSuburb: data.postal_suburb,
        postalState: data.postal_state,
        postalCode: data.postal_postal_code,
        contactPhoneNum: data.contact_phonenum,
        contactMobileNum: data.contact_mobilenum,
        contactFaxNum: data.contact_faxnum,
        contactEmail: data.contact_email,
        contactAdditionalEmail: data.contact_add_email,
        providerFirstName: data.provider_fname,
        providerLastName: data.provider_lname,
        providerPhoneNum: data.provider_phonenum,
        providerMobileNum: data.provider_mobilenum,
        providerFaxNum: data.provider_faxnum,
        providerEmail: data.provider_email,
        supervisorFirstName: data.supervisor_fname,
        supervisorLastName: data.supervisor_lname,
        supervisorPhoneNum: data.supervisor_phonenum,
        supervisorMobileNum: data.supervisor_mobilenum,
        supervisorFaxNum: data.supervisor_faxnum,
        supervisorEmail: data.supervisor_email,
        leaderFirstName: data.leader_fname,
        leaderLastName: data.leader_lname,
        leaderPhoneNum: data.leader_phonenum,
        leaderMobileNum: data.leader_mobilenum,
        leaderFaxNum: data.leader_faxnum,
        leaderEmail: data.leader_email,
        operatingHours: data.operating_hours
          ? JSON.parse(data.operating_hours)
          : {},
        outsideSchoolHours: data.outside_school_hours
          ? JSON.parse(data.outside_school_hours)
          : {},
        vacationCareHours: data.vacation_care_hours
          ? JSON.parse(data.vacation_care_hours)
          : {},
        additionalInfo: data.additional_info,
        rooms: data.rooms ? JSON.parse(data.rooms) : {},
        educators: data.educators ? JSON.parse(data.educators) : {},
        employees: data.employees ? JSON.parse(data.employees) : {},
        philosophy: data.philosophy,
        logo: {},
        diffTime: dayDiffTime,
        hasSub: !isEmpty(subStatus),
        // diffTime: !isEmpty(subStatus)
        //   ? failedPayment
        //     ? 1
        //     : subStatus.subscription.status === "canceled"
        //     ? 0
        //     : dayDiffTime
        //   : dayDiffTime,
        // showPMBanner: showPaymentFailedBanner,
        // !isEmpty(subStatus) &&
        // dayDiffTime <= 0 &&
        // dayDiffTime >= -7 &&
        // failedPayment,
        //showing banner prompting there's an error with the payment.
        // lapsed: !isEmpty(subStatus)
        //   ? !failedPayment && subStatus.subscription.status === "canceled"
        //   : dayDiffTime <= 0, // redirect to pricing plans
        // redirectPaymentFailed:
        //   !isEmpty(subStatus) && failedPayment && dayDiffTime < -7,
        // hasSub: !isEmpty(subStatus),
        // subStatus: !isEmpty(subStatus) ? subStatus.subscription.status : "noStat",
        // paymentFailed: failedPayment,
        status,
      };
    });

  return [
    ...checkedServices.filter((s: any) => s.status === "active"),
    ...checkedServices.filter((s: any) => s.status === "notoverdue"),
    ...checkedServices.filter(
      (s: any) => s.status !== "active" && s.status !== "notoverdue"
    ),
  ];
}

export async function fetchServiceDetails(
  userId: number,
  app_token?: string,
  email?: string
): Promise<ServiceDetails> {
  let token: any = JSON.parse(localStorage.getItem("inteli_token")!);

  let config = {
    headers: {
      Authorization: "Bearer " + (app_token ? app_token : token.accessToken),
    },
  };

  let responseData: any[] = [];

  const res = await axios.get(
    `/api/user/${userId}/centers/subscription`,
    config
  );

  const response = res.data.user_centers.original;

  const centerData = res.data.user_details.original;

  const subData = res.data.subscription.original;

  let unExpired: any[] = [];
  let expired: any[] = [];

  response
    .filter((r: any) => r.has_qip === 1)
    .map((res: any) => {
      const datetime_end_sub_unix = subData.products
        ? subData.products.some(
            (product: any) => Number(product.center_id) === Number(res.id)
          )
          ? subData.products.filter(
              (p: any) => Number(p.center_id) === Number(res.id)
            )[0].subscription.current_period_end
          : false
        : false;

      let datetime_end = "";

      if (
        datetime_end_sub_unix === undefined ||
        datetime_end_sub_unix === false
      ) {
        datetime_end = centerData.centers.filter(
          (center: any) => Number(center.center_id) === Number(res.id)
        )[0].datetime_end;
      } else {
        datetime_end = moment.unix(datetime_end_sub_unix).format("DD-MM-YYYY");
      }

      let subStatus: any = {};

      const subStatusCheck = subData.products
        ? subData.products.filter(
            (sub: any) => Number(sub.center_id) === Number(res.id)
          )
          ? subData.products.filter(
              (sub: any) => Number(sub.center_id) === Number(res.id)
            )[0]
          : {}
        : {};

      if (subStatusCheck !== undefined) {
        subStatus = subStatusCheck;
      }
      // const subStatus: any = [];

      const failedPayment = !isEmpty(subStatus)
        ? subStatus.subscription.status === "incomplete_expired" ||
          subStatus.subscription.status === "incomplete" ||
          subStatus.subscription.status === "past_due" ||
          subStatus.subscription.status === "unpaid"
        : false;

      // const failedPayment = true;

      const dt1 = datetime_end.split("-");
      //date string: 26-04-2022

      const year1 = Number(dt1[2]);
      const month1 = Number(dt1[1].replace(/^0+/, ""));
      const day1 = Number(dt1[0].replace(/^0+/, ""));

      const date1 = new Date(year1, month1 - 1, day1, 0, 0, 0);

      const date2 = new Date();
      const diffTime = Number(date1) - Number(date2);

      const dayDiffTime = Math.ceil(diffTime / (1000 * 60 * 60 * 24)) + 1;
      // const dayDiffTime = -8;

      const diffTimeLapsed = !isEmpty(subStatus)
        ? failedPayment
          ? 1
          : subStatus.subscription.status === "canceled"
          ? 0
          : dayDiffTime
        : dayDiffTime;

      const getpFDayDiff = () => {
        if (failedPayment) {
          const dateUnix = subData.products.some(
            (product: any) => Number(product.center_id) === Number(res.id)
          )
            ? subData.products.filter(
                (p: any) => Number(p.center_id) === Number(res.id)
              )[0].subscription.current_period_start
            : -1;

          if (dateUnix === -1) {
            return 1000;
          }

          const date = moment.unix(dateUnix);
          const dateNow = moment.unix(Math.floor(Date.now() / 1000));

          const diff = Math.ceil(moment.duration(date.diff(dateNow)).asDays());

          return diff;
        } else {
          return 1000;
        }
      };

      const showPaymentFailedBanner =
        failedPayment && getpFDayDiff() <= 0 && getpFDayDiff() > -30;

      const lapse = !isEmpty(subStatus)
        ? subStatus.subscription.status === "canceled" && !failedPayment
        : dayDiffTime <= 0;

      const canStillAccessTrial = isEmpty(subStatus) && dayDiffTime > 0;

      const canStillAccessSub =
        !isEmpty(subStatus) && subStatus.subscription.status === "active";

      const active = canStillAccessTrial || canStillAccessSub;

      const overdue =
        failedPayment && getpFDayDiff() >= -30 && getpFDayDiff() <= -7;

      const notOverdue = failedPayment && getpFDayDiff() > -7;

      const resubscribe =
        (failedPayment && getpFDayDiff() < -30) ||
        (!isEmpty(subStatus) && subStatus.subscription.status === "canceled");

      const trialEnded = isEmpty(subStatus) && dayDiffTime <= 0;

      const status = active
        ? "active"
        : overdue
        ? "overdue"
        : notOverdue
        ? "notoverdue"
        : resubscribe
        ? "resubscribe"
        : trialEnded
        ? "subscribe"
        : "";

      if (status !== "active") {
        expired = [
          ...expired,
          {
            ...res,
            // showPMBanner: showPaymentFailedBanner,
            // showPMBanner:
            //   !isEmpty(subStatus) &&
            //   dayDiffTime <= 0 &&
            //   dayDiffTime >= -7 &&
            //   failedPayment, //showing banner prompting there's an error with the payment.
            // lapsed: lapse, // redirect to pricing plans
            // redirectPaymentFailed:
            //   !isEmpty(subStatus) && failedPayment && dayDiffTime < -7,
            // hasSub: !isEmpty(subStatus),
            // subStatus: !isEmpty(subStatus)
            //   ? subStatus.subscription.status
            //   : "noStat",
            // diffTime: !isEmpty(subStatus)
            //   ? failedPayment
            //     ? 1
            //     : subStatus.subscription.status === "canceled"
            //     ? 0
            //     : dayDiffTime
            //   : dayDiffTime,
            diffTime: dayDiffTime,
            // paymentFailed: failedPayment,

            status,
            hasSub: !isEmpty(subStatus),
          },
        ];
      } else {
        unExpired = [
          ...unExpired,
          {
            ...res,
            // showPMBanner: showPaymentFailedBanner,
            // showPMBanner:
            //   !isEmpty(subStatus) &&
            //   dayDiffTime <= 0 &&
            //   dayDiffTime >= -7 &&
            //   failedPayment, //showing banner prompting there's an error with the payment.
            // lapsed: !lapse, // redirect to pricing plans
            // redirectPaymentFailed:
            //   !isEmpty(subStatus) && failedPayment && dayDiffTime < -7,
            // hasSub: !isEmpty(subStatus),
            // subStatus: !isEmpty(subStatus)
            //   ? subStatus.subscription.status
            //   : "noStat",
            diffTime: dayDiffTime,
            // diffTime: !isEmpty(subStatus)
            //   ? failedPayment
            //     ? 1
            //     : subStatus.subscription.status === "canceled"
            //     ? 0
            //     : dayDiffTime
            //   : dayDiffTime,
            // paymentFailed: failedPayment,

            status,
            hasSub: !isEmpty(subStatus),
          },
        ];
      }
    });

  responseData = [...unExpired, ...expired];

  // }

  const selectedServiceId = localStorage.getItem("selectedService");

  const activeServices = responseData.filter(
    (service) => service.status === "active"
  );

  const data = selectedServiceId
    ? activeServices.some(
        (s: any) => Number(s.id) === Number(selectedServiceId)
      )
      ? activeServices.filter((s: any) => s.id === Number(selectedServiceId))[0]
      : activeServices[0]
    : activeServices[0];

  if (!isEmpty(data)) {
    const logoResource = app_token ? {} : await getLogo(data.id, "logo");

    return {
      id: data.id,
      name: data.name,
      approvalNumber: data.approval_number,
      serviceCatId: data.service_cat_id,
      locationStreet: data.location_street,
      locationSuburb: data.location_suburb,
      locationState: data.location_state,
      locationPostalCode: data.location_postal_code,
      postalStreet: data.postal_street,
      postalSuburb: data.postal_suburb,
      postalState: data.postal_state,
      postalCode: data.postal_postal_code,
      contactPhoneNum: data.contact_phonenum,
      contactMobileNum: data.contact_mobilenum,
      contactFaxNum: data.contact_faxnum,
      contactEmail: data.contact_email,
      contactAdditionalEmail: data.contact_add_email,
      providerFirstName: data.provider_fname,
      providerLastName: data.provider_lname,
      providerPhoneNum: data.provider_phonenum,
      providerMobileNum: data.provider_mobilenum,
      providerFaxNum: data.provider_faxnum,
      providerEmail: data.provider_email,
      supervisorFirstName: data.supervisor_fname,
      supervisorLastName: data.supervisor_lname,
      supervisorPhoneNum: data.supervisor_phonenum,
      supervisorMobileNum: data.supervisor_mobilenum,
      supervisorFaxNum: data.supervisor_faxnum,
      supervisorEmail: data.supervisor_email,
      leaderFirstName: data.leader_fname,
      leaderLastName: data.leader_lname,
      leaderPhoneNum: data.leader_phonenum,
      leaderMobileNum: data.leader_mobilenum,
      leaderFaxNum: data.leader_faxnum,
      leaderEmail: data.leader_email,
      operatingHours: data.operating_hours
        ? JSON.parse(data.operating_hours)
        : {},
      outsideSchoolHours: data.outside_school_hours
        ? JSON.parse(data.outside_school_hours)
        : {},
      vacationCareHours: data.vacation_care_hours
        ? JSON.parse(data.vacation_care_hours)
        : {},
      additionalInfo: data.additional_info,
      rooms: data.rooms ? JSON.parse(data.rooms) : {},
      educators: data.educators ? JSON.parse(data.educators) : {},
      employees: data.employees ? JSON.parse(data.employees) : {},
      philosophy: data.philosophy,
      logo: logoResource,
      // showPMBanner: data.showPMBanner,
      // lapsed: data.lapsed,
      // redirectPaymentFailed: data.redirectPaymentFailed,
      // hasSub: data.hasSub,
      // subStatus: data.subStatus,
      diffTime: data.diffTime,
      // paymentFailed: data.paymentFailed,
      status: data.status,
    };
  } else {
    return {};
  }
}

export async function updateServiceDetails(
  data: ServiceDetails,
  centerId?: number,
  app_token?: string
): Promise<ServiceDetails> {
  const payload = {
    name: data.name,
    approval_number: data.approvalNumber,
    service_cat_id: data.serviceCatId,
    location_street: data.locationStreet,
    location_suburb: data.locationSuburb,
    location_state: data.locationState,
    location_postal_code: data.locationPostalCode,
    postal_street: data.postalStreet,
    postal_suburb: data.postalSuburb,
    postal_state: data.postalState,
    postal_postal_code: data.postalCode,
    contact_phonenum: data.contactPhoneNum,
    contact_mobilenum: data.contactMobileNum,
    contact_faxnum: data.contactFaxNum,
    contact_email: data.contactEmail,
    contact_add_email: data.contactAdditionalEmail,
    provider_fname: data.providerFirstName,
    provider_lname: data.providerLastName,
    provider_phonenum: data.providerPhoneNum,
    provider_mobilenum: data.providerMobileNum,
    provider_faxnum: data.providerFaxNum,
    provider_email: data.providerEmail,
    supervisor_fname: data.supervisorFirstName,
    supervisor_lname: data.supervisorLastName,
    supervisor_phonenum: data.supervisorPhoneNum,
    supervisor_mobilenum: data.supervisorMobileNum,
    supervisor_faxnum: data.supervisorFaxNum,
    supervisor_email: data.supervisorEmail,
    leader_fname: data.leaderFirstName,
    leader_lname: data.leaderLastName,
    leader_phonenum: data.leaderPhoneNum,
    leader_mobilenum: data.leaderMobileNum,
    leader_faxnum: data.leaderFaxNum,
    leader_email: data.leaderEmail,
    operating_hours: data.operatingHours
      ? JSON.stringify(data.operatingHours)
      : {},
    outside_school_hours: data.outsideSchoolHours
      ? JSON.stringify(data.outsideSchoolHours)
      : {},
    vacation_care_hours: data.vacationCareHours
      ? JSON.stringify(data.vacationCareHours)
      : {},
    additional_info: data.additionalInfo,
    rooms: data.rooms ? JSON.stringify(data.rooms) : {},
    employees: data.employees ? JSON.stringify(data.employees) : {},
    educators: data.educators ? JSON.stringify(data.educators) : {},
    philosophy: data.philosophy,
    logo: data.logo,
    user_id: data.userId,
    client_id: accessId,
    client_secret: accessKey,
  };

  const id = centerId ? centerId : data.id;

  let token: any = JSON.parse(localStorage.getItem("inteli_token")!);
  let config = {
    headers: {
      Authorization: "Bearer " + (app_token ? app_token : token.accessToken),
    },
  };
  const response = await axios.put(`/api/center/${id}`, payload, config);

  const logoResource = app_token ? {} : await getLogo(response.data.id, "logo");

  const serviceDetails: ServiceDetails = {
    id: response.data.id,
    name: response.data.name,
    approvalNumber: response.data.approval_number,
    serviceCatId: response.data.service_cat_id,
    locationStreet: response.data.location_street,
    locationSuburb: response.data.location_suburb,
    locationState: response.data.location_state,
    locationPostalCode: response.data.location_postal_code,
    postalStreet: response.data.postal_street,
    postalSuburb: response.data.postal_suburb,
    postalState: response.data.postal_state,
    postalCode: response.data.postal_postal_code,
    contactPhoneNum: response.data.contact_phonenum,
    contactMobileNum: response.data.contact_mobilenum,
    contactFaxNum: response.data.contact_faxnum,
    contactEmail: response.data.contact_email,
    contactAdditionalEmail: response.data.contact_add_email,
    providerFirstName: response.data.provider_fname,
    providerLastName: response.data.provider_lname,
    providerPhoneNum: response.data.provider_phonenum,
    providerMobileNum: response.data.provider_mobilenum,
    providerFaxNum: response.data.provider_faxnum,
    providerEmail: response.data.provider_email,
    supervisorFirstName: response.data.supervisor_fname,
    supervisorLastName: response.data.supervisor_lname,
    supervisorPhoneNum: response.data.supervisor_phonenum,
    supervisorMobileNum: response.data.supervisor_mobilenum,
    supervisorFaxNum: response.data.supervisor_faxnum,
    supervisorEmail: response.data.supervisor_email,
    leaderFirstName: response.data.leader_fname,
    leaderLastName: response.data.leader_lname,
    leaderPhoneNum: response.data.leader_phonenum,
    leaderMobileNum: response.data.leader_mobilenum,
    leaderFaxNum: response.data.leader_faxnum,
    leaderEmail: response.data.leader_email,
    operatingHours: response.data.operating_hours
      ? JSON.parse(response.data.operating_hours)
      : {},
    outsideSchoolHours: response.data.outside_school_hours
      ? JSON.parse(response.data.outside_school_hours)
      : {},
    vacationCareHours: response.data.vacation_care_hours
      ? JSON.parse(response.data.vacation_care_hours)
      : {},
    additionalInfo: response.data.additional_info,
    rooms: response.data.rooms ? JSON.parse(response.data.rooms) : {},
    educators: response.data.educators
      ? JSON.parse(response.data.educators)
      : {},

    employees: response.data.employees
      ? JSON.parse(response.data.employees)
      : {},

    philosophy: response.data.philosophy,
    logo: logoResource,
  };

  return serviceDetails;
}

export async function fetchNQAProgress(centerId: number) {
  let token: any = JSON.parse(localStorage.getItem("inteli_token")!);
  let config = {
    headers: {
      Authorization: "Bearer " + token.accessToken,
    },
  };

  const response = await axios.get(`/api/category/${centerId}`, config);

  const progressObject: ProgressResponse = {
    totalProgress: response.data.progress,
    nqaItems: [],
  };

  response.data.children.map((nqa: any) => {
    progressObject.nqaItems.push({
      completed: nqa.progress,
      levels: nqa.children.map((level: any) => ({
        completed: level.progress,
        subLevels: level.children.map((subLevel: any) => ({
          completed: subLevel.progress,
        })),
      })),
    });
  });

  return progressObject;
}

export async function fetchNQAData(): Promise<NQA[]> {
  let token: any = JSON.parse(localStorage.getItem("inteli_token")!);
  let config = {
    headers: {
      Authorization: "Bearer " + token.accessToken,
    },
  };

  const response = await axios.get("/api/categories", config);

  const rawNQAData: RawNQAData[] = response.data;

  const nqaParents = rawNQAData.slice(0, 7);

  const nqaItems: NQA[] = [];
  nqaParents.map((nqa) => {
    nqaItems.push({
      id: nqa.id,
      name: nqa.label,
      title: nqa.title ? nqa.title : "",
      completed: undefined,
      levels: [],
    });
  });

  const nqaChildren = rawNQAData.slice(7);

  const nqaLevels: RawNQAData[] = [];
  const nqaSubLevels: RawNQAData[] = [];

  nqaChildren.forEach((child) => {
    if (child.parent < 8 && child.parent > 0) nqaLevels.push(child);
    else nqaSubLevels.push(child);
  });

  nqaLevels.forEach((level) => {
    const levelObject: NQALevel = {
      sourceId: level.id,
      id: parseInt(level.label.split(".")[1]),
      completed: undefined,
      info: level.description ? level.description : "",
      name: level.label,
      title: level.title ? level.title : "",
      subLevels: [],
    };

    nqaSubLevels.forEach((subLevel) => {
      if (subLevel.parent === level.id) {
        levelObject.subLevels.push({
          sourceId: subLevel.id,
          id: parseInt(subLevel.label.split(".")[2]),
          label: subLevel.label,
          name: subLevel.title ? subLevel.title : "",
          completed: undefined,
          description: subLevel.description ? subLevel.description : "",
        });
      }
    });

    const nqaParentIndex = nqaItems.findIndex(
      (item) => item.id === level.parent
    );

    nqaItems[nqaParentIndex].levels.push(levelObject);
  });

  return nqaItems;
}

export async function fetchNQAQuestions(
  centerId: number,
  type: string,
  selectedNQASublevel: NQASubLevel,
  serviceCatId?: number
): Promise<NQAQuestion[]> {
  const params = new URLSearchParams();

  params.set("type", type);
  params.set("center_id", centerId.toString());
  params.set("category_id", selectedNQASublevel.sourceId.toString());

  let token: any = JSON.parse(localStorage.getItem("inteli_token")!);
  let config = {
    headers: {
      Authorization: "Bearer " + token.accessToken,
    },
  };

  const response = await axios.get(
    `/api/questions?${params.toString()}`,
    config
  );
  return (
    response.data
      // .filter((question: any) => question.service_type.includes(serviceCatId))
      .map((question: any) => ({
        id: question.id,
        type: question.type,
        archive: question.archive,
        centerId: question.center_id,
        userId: question.user_id,
        qcId: question.qc_id,
        notes: question.notes,
        title: question.title,
        categoryId: question.category_id,
        description: question.description,
        yesDescription: question.yes_description
          ? question.yes_description
          : question.description,
        noDescription: question.no_description
          ? question.no_description
          : question.description,
        noteDescription: question.na_description ? question.na_description : "",
        cqrId: question.cqr_id ? question.cqr_id : null,
        taskCount: question.taskCount ? question.taskCount : 0,
        status: question.status ? Number(question.status) : 0,
        evidence: question.evidence
          ? {
              ...question.evidence,
              tasks: question.evidence.tasks.map((task: any) => ({
                id: task.id,
                name: task.name,
                taskDue: task.due,
                summary: task.summary,
                assignee: task.assignee,
                centerId: task.center_id,
                status: Number(task.status),
                questionId: task.question_id,
              })),
              evidenceDocs: question.evidence.evidenceDocs.map(
                (evidence: any) => ({
                  ...evidence,
                  id: evidence.attachment_id,
                  userName: evidence.user_name,
                  userId: evidence.user_id,
                })
              ),
            }
          : null,
        strategy: question.strategy
          ? {
              ...question.strategy,
              tasks: question.strategy.tasks.map((task: any) => ({
                id: task.id,
                name: task.name,
                taskDue: task.due,
                summary: task.summary,
                assignee: task.assignee,
                centerId: task.center_id,
                status: Number(task.status),
                questionId: task.question_id,
                step_outcome: task.step_outcome,
                success_measure: task.success_measure,
                priority: task.priority,
                progress_notes: task.progress_notes,
              })),
              downloadResources: question.strategy.downloadResources.map(
                (evidence: any) => ({
                  ...evidence,
                  id: evidence.attachment_id,
                })
              ),
            }
          : null,
      }))
  );
}

export async function postComply(
  centerId: number,
  questionId: number,
  result: number,
  goalStrategy = "",
  self_assessment = "",
  commentsNotes: {
    notes?: string;
    evidence_note?: string;
  }
): Promise<boolean> {
  const payload = {
    center_id: centerId,
    question_id: questionId,
    result,
    notes: commentsNotes.notes,
    evidence_note: commentsNotes.evidence_note,
    goalStrategy,
    client_id: accessId,
    client_secret: accessKey,
    self_assessment,
  };

  let token: any = JSON.parse(localStorage.getItem("inteli_token")!);
  let config = {
    headers: {
      Authorization: "Bearer " + token.accessToken,
    },
  };

  const response = await axios.post("/api/question/comply", payload, config);

  if (response.status === 200 || response.status === 201) return true;

  return false;
}

export async function uploadEvidence(
  imageData: EvidenceData
): Promise<boolean> {
  const payload = {
    title: imageData.title,
    label: imageData.label,
    cqr_id: imageData.cqrId,
    base64_image: imageData.image,
    client_id: accessId,
    client_secret: accessKey,
    user_id: userIdentity,
  };

  let token: any = JSON.parse(localStorage.getItem("inteli_token")!);
  let config = {
    headers: {
      Authorization: "Bearer " + token.accessToken,
    },
  };

  const response = await axios.post("/api/question/evidence", payload, config);
  if (response.status === 201) {
    return true;
  } else if (response.status === 200) {
    return response.data.message;
  } else {
    alert("Error has occured.");
  }
  return false;
}

export async function uploadEvidenceLink(
  linkData: EvidenceData
): Promise<boolean> {
  const payload = {
    name: linkData.name,
    label: linkData.label,
    cqr_id: linkData.cqrId,
    title: linkData.title,
    client_id: accessId,
    client_secret: accessKey,
  };

  let token: any = JSON.parse(localStorage.getItem("inteli_token")!);
  let config = {
    headers: {
      Authorization: "Bearer " + token.accessToken,
    },
  };

  const response = await axios.post("/api/question/evidence", payload, config);

  if (response.status === 201) return true;

  return false;
}

export async function uploadEvidenceComment(
  commentData: EvidenceData
): Promise<boolean> {
  const payload = {
    title: commentData.title,
    cqr_id: commentData.cqrId,
    label: commentData.label,
    name: commentData.name,
    client_id: accessId,
    client_secret: accessKey,
    user_id: userIdentity,
  };

  let token: any = JSON.parse(localStorage.getItem("inteli_token")!);
  let config = {
    headers: {
      Authorization: "Bearer " + token.accessToken,
    },
  };

  const response = await axios.post("/api/question/evidence", payload, config);
  if (response.status === 201) return true;

  return false;
}

export async function editEvidence(
  attachmentId: number,
  comment: string
): Promise<boolean> {
  let token: any = JSON.parse(localStorage.getItem("inteli_token")!);
  let config = {
    headers: {
      Authorization: "Bearer " + token.accessToken,
    },
  };
  const payload = {
    comment: comment,
    user_id: userIdentity,
    center_id: localStorage.getItem("selectedService"),
  };
  const response = await axios.post(
    `/api/question/evidence/${attachmentId}`,
    payload,
    config
  );

  if (response.status === 200 || response.status === 201) return true;

  return false;
}

export async function deleteAttachment(attachmentId: number): Promise<boolean> {
  let token: any = JSON.parse(localStorage.getItem("inteli_token")!);
  let config = {
    headers: {
      Authorization: "Bearer " + token.accessToken,
    },
  };

  const response = await axios.delete(
    `/api/attachments/${attachmentId}`,
    config
  );

  if (response.status === 200 || response.status === 201) return true;

  return false;
}

export async function uploadImage(
  imageData: UploadData
): Promise<UploadResponse> {
  const payload = {
    entity_id: imageData.centerId,
    entity: imageData.entity,
    name: imageData.name,
    title: imageData.title,
    label: imageData.label,
    base64_image: imageData.data,
    client_id: accessId,
    client_secret: accessKey,
  };

  let token: any = JSON.parse(localStorage.getItem("inteli_token")!);
  let config = {
    headers: {
      Authorization: "Bearer " + token.accessToken,
    },
  };

  const response = await axios.post("/api/attachments", payload, config);

  return {
    id: response.data.id,
    entity: response.data.entity,
    entityId: response.data.entity_id,
    name: response.data.name,
    label: response.data.label,
    title: response.data.title,
  };
}

export async function fetchImage(imageId: number) {
  let token: any = JSON.parse(localStorage.getItem("inteli_token")!);
  let config = {
    headers: {
      Authorization: "Bearer " + token.accessToken,
    },
  };

  const response = await axios.get(`/api/uploads/${imageId}`, {
    responseType: "arraybuffer",
    headers: config.headers,
  });

  return Buffer.from(response.data, "binary").toString("base64");
}

export async function fetchTasks(
  centerId: number,
  overdue?: boolean,
  assigneeName?: string,
  fromDate?: string,
  toDate?: string,
  nqaAreas?: number[]
): Promise<Task[]> {
  const params = new URLSearchParams();

  params.set("center_id", String(centerId));

  if (overdue) {
    params.set("overdue", String(overdue));
  }

  if (nqaAreas && nqaAreas.length > 0) {
    params.set("nqa_areas", JSON.stringify(nqaAreas));
  }

  if (assigneeName) {
    params.set("assignee_name", assigneeName);
  }

  if (fromDate && toDate) {
    const dateRange = {
      from: fromDate,
      to: toDate,
    };

    params.set("date_range", JSON.stringify(dateRange));
  }
  let token: any = JSON.parse(localStorage.getItem("inteli_token")!);
  let config = {
    headers: {
      Authorization: "Bearer " + token.accessToken,
    },
  };

  const response = await axios.get(`/api/tasks?${params.toString()}`, config);

  return response.data.map((task: any) => ({
    id: task.id,
    asignee: String(task.assignee),
    name: task.name,
    status: Number(task.status),
    summary: task.summary,
    taskDue: task.due,
    centerId: task.center_id,
    nqaArea: task.nqa_id,
    nqaId: task.nqa_id,
    questionId: task.question_id,
    type: task.task_type,
  }));
}

export async function postTask(task: Task): Promise<boolean> {
  console.log("finbaltask", task);
  const taskPayload = {
    user_id: task.userId,
    nqa_id: task.nqaId,
    center_id: task.centerId,
    question_id: task.questionId,
    task_type: task.type,
    name: task.name,
    due: task.taskDue,
    summary: task.summary,
    assignee: task.asignee,
    status: String(task.status),
    client_id: accessId,
    client_secret: accessKey,
    step_outcome: task.step_outcome,
    success_measure: task.success_measure,
    progress_notes: task.progress_notes,
    priority: task.priority,
  };
  let token: any = JSON.parse(localStorage.getItem("inteli_token")!);
  let config = {
    headers: {
      Authorization: "Bearer " + token.accessToken,
    },
  };

  const response = await axios.post("/api/tasks", taskPayload, config);

  if (response.status === 200 || response.status === 201) return true;

  return false;
}

export async function updateTask(task: Task) {
  const taskPayload = {
    user_id: task.userId,
    nqa_id: task.nqaId,
    center_id: task.centerId,
    question_id: task.questionId,
    task_type: task.type,
    name: task.name,
    due: task.taskDue,
    summary: task.summary,
    assignee: task.asignee,
    status: String(task.status),
    client_id: accessId,
    client_secret: accessKey,
    priority: task.priority,
    step_outcome: task.step_outcome,
    success_measure: task.success_measure,
    progress_notes: task.progress_notes,
  };
  let token: any = JSON.parse(localStorage.getItem("inteli_token")!);
  let config = {
    headers: {
      Authorization: "Bearer " + token.accessToken,
    },
  };

  const response = await axios.put(
    `/api/tasks/${task.id}`,
    taskPayload,
    config
  );

  if (response.status === 200 || response.status === 201) return true;

  return false;
}

export async function fetchCenterReports(
  centerId: number,
  userId: number
): Promise<Report[]> {
  const params = new URLSearchParams();

  params.set("center_id", String(centerId));
  params.set("user_id", String(userId));

  let token: any = JSON.parse(localStorage.getItem("inteli_token")!);
  let config = {
    headers: {
      Authorization: "Bearer " + token.accessToken,
    },
  };
  const response = await axios.get(`/api/reports?${params.toString()}`, config);

  return response.data
    .map((report: any) => ({
      id: report.id,
      url: report.url,
      reportlabel: report.report_label,
      type: report.type,
      filters: report.filters,
      createdBy: report.user_name,
      createdAt: report.created_at,
      centerName: report.center_name,
    }))
    .reverse();
}

export async function generateReport(
  userId: number,
  centerId: number,
  reportLabel: string,
  type: number,
  filters: number[]
) {
  const nqaFilters = filters.map((filter) => ({ filter }));
  const payload = {
    user_id: userId,
    center_id: centerId,
    report_label: reportLabel,
    type,
    filters: nqaFilters,
    client_id: accessId,
    client_secret: accessKey,
  };

  let token: any = JSON.parse(localStorage.getItem("inteli_token")!);
  let config = {
    headers: {
      Authorization: "Bearer " + token.accessToken,
    },
  };

  const response = await axios.post("/api/report", payload, config);

  return response.data;
}

export async function deleteReport(reportId: number) {
  const payload = {
    report_id: reportId,
    client_id: accessId,
    client_secret: accessKey,
  };

  let token: any = JSON.parse(localStorage.getItem("inteli_token")!);

  let config = {
    headers: {
      Authorization: "Bearer " + token.accessToken,
    },
  };

  const response = await axios.delete(`/api/reports/${reportId}`, {
    data: { ...payload },
    headers: { ...config.headers },
  });

  // const response = await axios.delete(`/api/reports/${payload}`);
  if (response.status === 200 || response.status === 201) return true;

  return false;
}

export async function requestFeedback(feedback: Feedback): Promise<boolean> {
  const payload = {
    user_id: feedback.userId,
    center_id: feedback.centerId,
    comment: feedback.comments,
    nqa: feedback.requestNQA,
    status: feedback.status,
    client_id: accessId,
    client_secret: accessKey,
  };

  let token: any = JSON.parse(localStorage.getItem("inteli_token")!);
  let config = {
    headers: {
      Authorization: "Bearer " + token.accessToken,
    },
  };

  const response = await axios.post(
    "/api/notifications/feedback/request",
    payload,
    config
  );

  if (response.status === 200 || response.status === 201) return true;

  return false;
}

export async function getUserProduct(userId: number, centerId: number) {
  let token: any = JSON.parse(localStorage.getItem("inteli_token")!);
  let config = {
    headers: {
      Authorization: "Bearer " + token.accessToken,
    },
  };
  // api/stripe/product/{user_id}/{center_id}
  const response = await axios.get(
    `api/stripe/product/${userId}/${centerId}`,
    config
  );

  return response.data.product;
}

export async function getUserProducts(userId: number, centerId: number) {
  let token: any = JSON.parse(localStorage.getItem("inteli_token")!);
  let config = {
    headers: {
      Authorization: "Bearer " + token.accessToken,
    },
  };

  // api/stripe/products/{user_id}
  const response = await axios.get(`api/stripe/products/${userId}`, config);

  return response.data.products;
}

export async function changePlan(
  userId: number,
  centerId: number,
  price: string
) {
  const changePlanPayload = {
    user_id: userId,
    center_id: centerId,
    price,
  };

  let token: any = JSON.parse(localStorage.getItem("inteli_token")!);
  let config = {
    headers: {
      Authorization: "Bearer " + token.accessToken,
    },
  };
  // POST /changeplan
  // { "user_id":455,
  //   "price":"pr_asdjashdgas123"
  // }
  const response = await axios.post(
    `api/changeplan`,
    changePlanPayload,
    config
  );

  return response.data;
}

export async function cancelSubscription(userId: number, centerId: number) {
  let token: any = JSON.parse(localStorage.getItem("inteli_token")!);
  let config = {
    headers: {
      Authorization: "Bearer " + token.accessToken,
    },
  };

  const payload = {
    user_id: userId,
    center_id: centerId,
  };

  const response = await axios.post(`/api/cancelsubscription`, payload, config);

  return response.data;
}

export async function reactivateSubscription(userId: number, centerId: number) {
  let token: any = JSON.parse(localStorage.getItem("inteli_token")!);
  let config = {
    headers: {
      Authorization: "Bearer " + token.accessToken,
    },
  };

  const payload = {
    user_id: userId,
    center_id: centerId,
  };

  const response = await axios.post(
    `/api/reactivatesubscription`,
    payload,
    config
  );

  return response.data;
}

export async function getPaymentMethods(userId: number) {
  let token: any = JSON.parse(localStorage.getItem("inteli_token")!);
  let config = {
    headers: {
      Authorization: "Bearer " + token.accessToken,
    },
  };

  // GET stripe/paymentmethod/{user_id}
  const response = await axios.get(
    `api/stripe/paymentmethod/${userId}`,
    config
  );

  return response.data;
}

export async function addPaymentMethod(userId: number, pmId: string) {
  const payload = {
    user_id: userId,
    payment_method_id: pmId,
  };

  let token: any = JSON.parse(localStorage.getItem("inteli_token")!);
  let config = {
    headers: {
      Authorization: "Bearer " + token.accessToken,
    },
  };
  //   PUT stripe/paymentmethod
  // {
  //   "user_id":554,
  //   "payment_method_id":"pm_1IYNmHDeO3XG77wN947MwTl0"
  // }

  const response = await axios.put(`api/stripe/paymentmethod`, payload, config);
}

export async function removePaymentMethod(pmId: string) {
  const payload = {
    payment_method_id: pmId,
  };

  let token: any = JSON.parse(localStorage.getItem("inteli_token")!);
  let config = {
    headers: {
      Authorization: "Bearer " + token.accessToken,
    },
  };
  //   POST stripe/paymentmethod/detach
  // {
  //   "payment_method_id":"pm_1IYNmHDeO3XG77wN947MwTl0"
  // }
  const response = await axios.post(
    `api/stripe/paymentmethod/detach`,
    payload,
    config
  );
}

export async function getInvoices(userId: number) {
  let token: any = JSON.parse(localStorage.getItem("inteli_token")!);
  let config = {
    headers: {
      Authorization: "Bearer " + token.accessToken,
    },
  };
  // GET stripe/invoices/{user_id}
  const response = await axios.get(`api/stripe/invoices/${userId}`, config);

  return response.data;
}

export async function getAdminAttr(memberId: number) {
  let token: any = JSON.parse(localStorage.getItem("inteli_token")!);
  let config = {
    headers: {
      Authorization: "Bearer " + token.accessToken,
    },
  };

  // GET  /user/{id}
  const response = await axios.get(`api/user/${memberId}`, config);

  return response.data;
}

export async function getQuestion(qId: number) {
  let token: any = JSON.parse(localStorage.getItem("inteli_token")!);
  let config = {
    headers: {
      Authorization: "Bearer " + token.accessToken,
    },
  };
  const params = new URLSearchParams();

  params.set("id", String(qId));

  const response = await axios.get(
    `/api/question/0?${params.toString()}`,
    config
  );

  return response.data[0];
}

export async function downloadReport(reportId: number) {
  const payload = {
    report_id: reportId,
    client_id: accessId,
    client_secret: accessKey,
  };

  let token: any = JSON.parse(localStorage.getItem("inteli_token")!);
  let config = {
    headers: {
      Authorization: "Bearer " + token.accessToken,
    },
  };
  // GET api/report/download/{id}
  const response = await axios.get(`/api/report/download/${reportId}`, {
    data: { ...payload },
    headers: { ...config.headers },
  });
  return response.data;
}

export async function deleteTask(taskId: number) {
  const payload = {
    task_id: taskId,
    client_id: accessId,
    client_secret: accessKey,
  };

  let token: any = JSON.parse(localStorage.getItem("inteli_token")!);
  let config = {
    headers: {
      Authorization: "Bearer " + token.accessToken,
    },
  };
  // DELETE : tasks/{id}

  const response = await axios.delete(`/api/tasks/${taskId}`, {
    data: { ...payload },
    headers: { ...config.headers },
  });

  if (response.status === 200 || response.status === 201) return true;

  return false;
}

export async function getLogo(centerId: number, title: string) {
  let token: any = JSON.parse(localStorage.getItem("inteli_token")!);
  let config = {
    headers: {
      Authorization: "Bearer " + token.accessToken,
    },
  };

  const params = new URLSearchParams();

  params.set("entity_id", String(centerId));
  params.set("title", title);

  const response = await axios.get(
    `/api/attachments?${params.toString()}`,
    config
  );

  if (response.data.length > 0) {
    return {
      id: response.data[0].id,
      name: response.data[0].name,
      // url: `${process.env.REACT_APP_PUBLIC_URL}qip_backend/storage/uploads/${response.data[0].name}`,
      url: response.data[0].name,
      created_at: response.data[0].created_at,
      updated_at: response.data[0].updated_at,
    };
  }

  return {};
}

export async function addCustomQuestion(customPayload: CustomQuestionPayload) {
  let token: any = JSON.parse(localStorage.getItem("inteli_token")!);
  let config = {
    headers: {
      Authorization: "Bearer " + token.accessToken,
      ContentType: "application/json",
    },
  };

  const payload = {
    title: customPayload.title,
    type: customPayload.type,
    center_id: customPayload.center_id,
    user_id: customPayload.user_id,
    description: customPayload.description,
    category_id: customPayload.category_id,
  };

  // {
  //   "title": "",
  //   "type": "",
  //   "description": "",
  //   "center_id": "",
  //   "user_id": "",
  //   "category_id": "",
  // }
  // POST : questions

  const response = await axios.post(`/api/questions`, payload, config);

  if (response.status === 200 || response.status === 201) return true;

  return false;
}

export async function updateCustomQuestion(
  qId: number,
  customPayload: CustomQuestionPayload
) {
  let token: any = JSON.parse(localStorage.getItem("inteli_token")!);
  let config = {
    headers: {
      Authorization: "Bearer " + token.accessToken,
      ContentType: "application/json",
    },
  };

  const payload = {
    title: customPayload.title,
    type: customPayload.type,
    center_id: customPayload.center_id,
    user_id: customPayload.user_id,
    description: customPayload.description,
    category_id: customPayload.category_id,
    archive: customPayload.archive,
  };
  // put('question/{id}'
  const response = await axios.put(`/api/question/${qId}`, payload, config);

  if (response.status === 200 || response.status === 201) return true;

  return false;
}

export async function deleteCustomQuestion(qId: number, qcId: number) {
  let token: any = JSON.parse(localStorage.getItem("inteli_token")!);
  let config = {
    headers: {
      Authorization: "Bearer " + token.accessToken,
      ContentType: "application/json",
    },
  };
  // delete('questions/{id}'
  const response = await axios.delete(`/api/questions/${qId}/${qcId}`, config);

  if (response.status === 200 || response.status === 201) return true;

  return false;
}

export async function fetchAuthor(userId: number) {
  let token: any = JSON.parse(localStorage.getItem("inteli_token")!);
  let config = {
    headers: {
      Authorization: "Bearer " + token.accessToken,
    },
  };

  const response = await axios.get(`/api/question/author/${userId}`, config);

  return response.data[0];
}

export async function createServiceCenter(
  initialDetails: InitialServiceDetails
): Promise<boolean> {
  let token: any = JSON.parse(localStorage.getItem("inteli_token")!);
  let config = {
    headers: {
      Authorization: "Bearer " + token.accessToken,
    },
  };
  const payload = {
    user_id: initialDetails.userId,
    user_type: "ADMIN",
    name: initialDetails.serviceName,
    approval_number: initialDetails.approvalNumber,
    service_cat_id: initialDetails.serviceCatId,
  };

  const response = await axios.post(`/api/centers`, payload, config);

  return response.data;
}

export async function fetchAllUsersCenters(centerIds: number[]) {
  let token: any = JSON.parse(localStorage.getItem("inteli_token")!);

  let config = {
    headers: {
      Authorization: "Bearer " + token.accessToken,
    },
  };

  const payload = {
    center_id: centerIds,
  };

  // POST https://dev.holisticqip.com.au/qip_backend/public/api/centers/users

  const response = await axios.post("/api/centers/users", payload, config);

  return Array.from(new Set(response.data.map((user: any) => user.id))).map(
    (id) => {
      return response.data.find((user: any) => user.id === id);
    }
  );
}

export async function fetchAuthUser(app_token?: string) {
  let token: any = JSON.parse(localStorage.getItem("inteli_token")!);

  let config = {
    headers: {
      Authorization: "Bearer " + (app_token ? app_token : token.accessToken),
    },
  };
  const response = await axios.get("/api/user", config);

  return response.data;
}

export async function getAllResources() {
  let token: any = JSON.parse(localStorage.getItem("inteli_token")!);

  let config = {
    headers: {
      Authorization: "Bearer " + token.accessToken,
    },
  };

  const response = await axios.get("/api/getallresources", config);

  return response.data;
}

export async function getAllResourcesShop() {
  let token: any = JSON.parse(localStorage.getItem("inteli_token")!);

  let config = {
    headers: {
      Authorization: "Bearer " + token.accessToken,
    },
  };

  const response = await axios.get("/api/resourcesshop", config);

  return response.data;
}

export async function getAllResourcesTutorial() {
  let token: any = JSON.parse(localStorage.getItem("inteli_token")!);

  let config = {
    headers: {
      Authorization: "Bearer " + token.accessToken,
    },
  };

  const response = await axios.get("/api/resourcestutorial", config);

  return response.data;
}

export async function downloadResource(id: number) {
  let token: any = JSON.parse(localStorage.getItem("inteli_token")!);

  let config = {
    headers: {
      Authorization: "Bearer " + token.accessToken,
    },
  };

  const response = await axios.get(`/api/resources/download/${id}`, config);

  return response.data;
}

export async function requestFeedbackList() {
  let token: any = JSON.parse(localStorage.getItem("inteli_token")!);

  let config = {
    headers: {
      Authorization: "Bearer " + token.accessToken,
    },
  };

  const response = await axios.get(`/api/requestfeedback/all/list`, config);

  return response.data;
}

export async function replyFeedbackList() {
  let token: any = JSON.parse(localStorage.getItem("inteli_token")!);

  let config = {
    headers: {
      Authorization: "Bearer " + token.accessToken,
    },
  };

  const response = await axios.get(`/api/replyfeedback/all/list`, config);

  return response.data;
}

export async function imageShopLink(id: number) {
  let token: any = JSON.parse(localStorage.getItem("inteli_token")!);

  let config = {
    headers: {
      Authorization: "Bearer " + token.accessToken,
    },
  };

  const response = await axios.get(
    `/api/resourcesshop/shop-downloadimage/${id}`,
    config
  );

  return response.data;
}

export async function imageAppTutorialLink(id: number) {
  let token: any = JSON.parse(localStorage.getItem("inteli_token")!);

  let config = {
    headers: {
      Authorization: "Bearer " + token.accessToken,
    },
  };

  const response = await axios.get(
    `api/resourcestutorial/tutorial-downloadimage/${id}`,
    config
  );

  return response.data;
}

export async function getUserMessages(userId: number) {
  let token: any = JSON.parse(localStorage.getItem("inteli_token")!);

  let config = {
    headers: {
      Authorization: "Bearer " + token.accessToken,
    },
  };

  const payload = {
    user_id: userId,
  };

  //get unread messages
  const response = await axios.post(
    `/api/message-inbox-unread`,
    payload,
    config
  );

  //get read messages

  const response1 = await axios.post(`/api/message-inbox`, payload, config);

  let rawUnread: any = response.data.filter((unread: any) => {
    return !response1.data.some(
      (read: any) => Number(read.message_id) === Number(unread.id)
    );
  });

  let readMessages: any = response1.data.map((read: any) => {
    return {
      content: read.content,
      subject: read.subject,
      created_at: read.created_at,
      message_from: read.message_from,
      message_from_name: read.message_from_name,
      user_id: read.user_id,
      message_id: read.message_id,
      read: true,
    };
  });

  let unreadMessages: any = rawUnread.map((unread: any) => {
    return {
      content: unread.content,
      subject: unread.subject,
      created_at: unread.created_at,
      message_from: unread.message_from,
      message_from_name: unread.message_from_name,
      user_id: unread.user_id,
      message_id: unread.id,
      read: false,
    };
  });

  const sortedMessages: any = [...unreadMessages, ...readMessages].sort(
    (a, b) => {
      return b.message_id - a.message_id;
    }
  );

  return {
    messages: sortedMessages,
    readMessages: readMessages,
    unreadMessages: unreadMessages,
    inboxCount: readMessages.length + unreadMessages.length,
    unreadCount: unreadMessages.length,
  };
}

export async function readMessage(messageId: number, userId: number) {
  let token: any = JSON.parse(localStorage.getItem("inteli_token")!);

  let config = {
    headers: {
      Authorization: "Bearer " + token.accessToken,
    },
  };

  const payload = {
    id: messageId,
    user_id: userId,
  };

  const response = await axios.post(`/api/message-view`, payload, config);

  return response.data;
}

export async function deleteUnreadMessage(messageId: number, userId: number) {
  let token: any = JSON.parse(localStorage.getItem("inteli_token")!);

  let config = {
    headers: {
      Authorization: "Bearer " + token.accessToken,
    },
  };

  const payload = {
    id: messageId,
    user_id: userId,
  };

  const response = await axios.post(
    `/api/message-delete-unread`,
    payload,
    config
  );

  return response.data;
}

export async function deleteReadMessage(messageId: number, userId: number) {
  let token: any = JSON.parse(localStorage.getItem("inteli_token")!);

  let config = {
    headers: {
      Authorization: "Bearer " + token.accessToken,
    },
  };

  const payload = {
    message_id: messageId,
    user_id: userId,
  };

  const response = await axios.delete(`/api/message-delete`, {
    data: { ...payload },
    headers: { ...config.headers },
  });

  return response.data;
}

export async function addQuestionToSat(
  centerId: number,
  questionId: number
): Promise<boolean> {
  let token: any = JSON.parse(localStorage.getItem("inteli_token")!);
  let config = {
    headers: {
      Authorization: "Bearer " + token.accessToken,
    },
  };

  const payload = {
    center_id: centerId,
    question_id: questionId,
    SAT: 1,
    client_id: accessId,
    client_secret: accessKey,
  };

  const response = await axios.post("/api/question/comply", payload, config);

  if (response.status === 200 || response.status === 201) return true;

  return false;
}

export async function fetchSATQuestions(centerId: number) {
  let token: any = JSON.parse(localStorage.getItem("inteli_token")!);
  let config = {
    headers: {
      Authorization: "Bearer " + token.accessToken,
    },
  };

  const response = await axios.get(
    `/api/questions-result-sat/${centerId}`,
    config
  );

  const response1 = await axios.get(`/api/sat-data/${centerId}`, config);

  return response.data.map((res: any) => {
    let hasData = false;
    if (!isEmpty(response1.data)) {
      hasData = response1.data.some((r: any) => {
        return Number(r.question_id) === Number(res.question_id);
      });
    }
    const hasDataDesc = hasData
      ? response1.data.filter(
          (r: any) => Number(r.question_id) === Number(res.question_id)
        )[0].sat_description
        ? true
        : false
      : false;

    return {
      center_id: res.center_id,
      question_id: res.question_id,
      type: res.type,
      parent: res.parent,
      category_id: res.category_id,
      label: res.label,
      description: res.description,
      hasData,
      dataDescription: hasDataDesc
        ? response1.data.filter(
            (r: any) => Number(r.question_id) === Number(res.question_id)
          )[0].sat_description
        : "",
      hasDataDescription: hasData
        ? response1.data.filter(
            (r: any) => Number(r.question_id) === Number(res.question_id)
          )[0].sat_description
          ? true
          : false
        : false,
      satData: hasData
        ? response1.data.filter(
            (r: any) => Number(r.question_id) === Number(res.question_id)
          )[0]
        : {},
    };
  });
}

export async function createSatData(data: SATPayload) {
  let token: any = JSON.parse(localStorage.getItem("inteli_token")!);
  let config = {
    headers: {
      Authorization: "Bearer " + token.accessToken,
    },
  };

  const payload = {
    center_id: data.centerId,
    question_id: data.questionId,
    label: data.label,
    description: data.description,
    sat_description: data.satDescription,
  };

  const response = await axios.put(`/api/sat-data`, payload, config);

  return response.data;
}

export async function updateSatData(satId: number, data: SATPayload) {
  let token: any = JSON.parse(localStorage.getItem("inteli_token")!);
  let config = {
    headers: {
      Authorization: "Bearer " + token.accessToken,
    },
  };

  const payload = {
    center_id: data.centerId,
    question_id: data.questionId,
    label: data.label,
    description: data.description,
    sat_description: data.satDescription,
  };

  const response = await axios.put(
    `/api/sat-data-update/${satId}`,
    payload,
    config
  );

  return response.data;
}

export async function removeSatData(
  hasData: boolean,
  satId: number,
  questionId: number,
  centerId: number
) {
  let token: any = JSON.parse(localStorage.getItem("inteli_token")!);
  let config = {
    headers: {
      Authorization: "Bearer " + token.accessToken,
    },
  };
  if (hasData) {
    const response = await axios.delete(
      `/api/sat-data-delete/${satId}`,
      config
    );
  }

  const payload = {
    center_id: centerId,
    question_id: questionId,
    SAT: 0,
    client_id: accessId,
    client_secret: accessKey,
  };

  const response1 = await axios.post("/api/question/comply", payload, config);
}

export async function removeQuestionFromSat(
  centerId: number,
  questionId: number
) {
  let token: any = JSON.parse(localStorage.getItem("inteli_token")!);
  let config = {
    headers: {
      Authorization: "Bearer " + token.accessToken,
    },
  };

  const payload = {
    center_id: centerId,
    question_id: questionId,
    SAT: 0,
    client_id: accessId,
    client_secret: accessKey,
  };

  const response = await axios.post("/api/question/comply", payload, config);

  if (response.status === 200 || response.status === 201) return true;

  return false;
}

export async function getLawsRegulations(nqaId: number, centerId: number) {
  let token: any = JSON.parse(localStorage.getItem("inteli_token")!);
  let config = {
    headers: {
      Authorization: "Bearer " + token.accessToken,
    },
  };

  const payload = {
    nqa: nqaId,
    center_id: centerId,
  };

  const response = await axios.get(`/api/laws-regulations-get/${nqaId}`, {
    headers: { ...config.headers },
    data: payload,
  });

  const larResult = await axios.get(
    `/api/laws-regulations-result-get-nqa/${centerId}/${nqaId}`,
    config
  );

  const hasResult = larResult.data.length > 0 ? true : false;

  let complianceCount = 0;

  const lawsRegs = response.data.map((res: any) => {
    const hasLarResult = hasResult
      ? larResult.data[0].lar_result.some((r: any) => {
          return Number(r.lar_id) === Number(res.id);
        })
      : false;

    if (hasLarResult) {
      complianceCount = complianceCount + 1;
    }

    return {
      id: res.id,
      law_regulation: res.law_regulation,
      nqa: res.nqa,
      nqs: res.nqs,
      description: res.description,
      hasResult: hasResult
        ? larResult.data[0].lar_result.some((r: any) => r.lar_id === res.id)
        : false,
      result: hasLarResult
        ? larResult.data[0].lar_result.filter(
            (r: any) => r.lar_id === res.id
          )[0].result
        : 0,
    };
  });

  return {
    lawsRegs: lawsRegs,
    resultId: hasResult ? larResult.data[0].id : 0,
    hasResult,
    note: hasResult ? larResult.data[0].note : "",
    complianceCount,
    larCount: response.data.length,
    compliance: complianceCount / response.data.length,
  };
}

export async function answerLawsRegulations(lawsReg: LawsRegPayload) {
  let token: any = JSON.parse(localStorage.getItem("inteli_token")!);
  let config = {
    headers: {
      Authorization: "Bearer " + token.accessToken,
    },
  };

  const payload = {
    center_id: lawsReg.centerId,
    nqa_id: lawsReg.nqaId,
    lar_result: lawsReg.result,
    note: lawsReg.note,
  };

  const response = await axios.post(`/api/laws-regulations`, payload, config);

  return response.data;
}

export async function updateLawsRegulationsResult(
  resultId: number,
  data: LawsRegPayload
) {
  let token: any = JSON.parse(localStorage.getItem("inteli_token")!);
  let config = {
    headers: {
      Authorization: "Bearer " + token.accessToken,
    },
  };

  const payload = {
    center_id: data.centerId,
    nqa_id: data.nqaId,
    lar_result: data.result,
    note: data.note,
  };

  const response = await axios.put(
    `/api/laws-regulations-result-update/${resultId}`,
    payload,
    config
  );

  return response.data;
}
