import * as React from "react";

import PropTypes from "prop-types";
import { Modal } from "semantic-ui-react";

import styles from "./FeedbackSentModal.module.css";
import BlueButton from "../../BlueButton";

function FeedbackSentModal({ visibility, onOpenModal, onCloseModal }) {
  return (
    <Modal
      size="tiny"
      centered
      open={visibility}
      onOpen={onOpenModal}
      onClose={onCloseModal}
    >
      <div className={styles.modalContainer}>
        <text className={styles.headerText}>
          Your mentor will be notified and you will receive detailed feedback
          soon.
        </text>

        <BlueButton
          className={styles.blueButton}
          buttonText="Go back"
          onClick={onCloseModal}
        />
      </div>
    </Modal>
  );
}

FeedbackSentModal.propTypes = {
  visibility: PropTypes.bool.isRequired,
  onOpenModal: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired,
};

export default FeedbackSentModal;
