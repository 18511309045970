import * as React from "react";
import { FaRegArrowAltCircleLeft } from "react-icons/fa";

import { useHistory } from "react-router-dom";
import { Button, Icon } from "semantic-ui-react";

import styles from "./MentorFeedbackView.module.css";

import useSWR, { mutate } from "swr";

import { replyFeedbackList } from "../../../api/requests";
import { isEmpty } from "lodash";
import moment from "moment";

const { useState } = React;

const dummyFeedback = [
  {
    id: 1,
    name: "NQA 1 - Feedback Assessment",
    nqaStatus: 2,
    reviewedBy: "Jonathan Patterson",
    reviewedOn: "12/11/2020",
  },
  {
    id: 2,
    name: "NQA 2 - Feedback Assessment",
    nqaStatus: 1,
    reviewedBy: "Jonathan Patterson",
    reviewedOn: "12/11/2020",
  },
  {
    id: 3,
    name: "NQA 3 - Feedback Assessment",
    nqaStatus: 3,
    reviewedBy: "Jonathan Patterson",
    reviewedOn: "12/11/2020",
  },
];

const sampleBodyText = `
Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Parturient montes nascetur ridiculus mus mauris vitae ultricies leo integer. Laoreet id donec ultrices tincidunt arcu. Viverra mauris in aliquam sem. In vitae turpis massa sed.

Eget gravida cum sociis natoque penatibus et magnis dis parturient. In tellus integer feugiat scelerisque varius morbi. Aliquam sem et tortor consequat id porta. Ac turpis egestas maecenas pharetra convallis posuere.
Non odio euismod lacinia at quis risus sed vulputate. Diam sollicitudin tempor id eu nisl nunc mi ipsum faucibus. Pharetra vel turpis nunc eget lorem dolor.

Metus dictum at tempor commodo ullamcorper a lacus vestibulum. Tellus elementum sagittis vitae et leo. Semper auctor neque vitae tempus quam pellentesque nec nam aliquam. Faucibus et molestie ac feugiat sed. Aliquam ultrices sagittis orci a. Gravida arcu ac tortor dignissim. Hac habitasse platea dictumst quisque. Et molestie ac feugiat sed lectus. Volutpat lacus laoreet non curabitur gravida arcu ac tortor dignissim.
Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Parturient montes nascetur ridiculus mus mauris vitae ultricies leo integer. Laoreet id donec ultrices tincidunt arcu. Viverra mauris in aliquam sem. In vitae turpis massa sed.
              
Eget gravida cum sociis natoque penatibus et magnis dis parturient. In tellus integer feugiat scelerisque varius morbi. Aliquam sem et tortor consequat id porta. Ac turpis egestas maecenas pharetra convallis posuere.
`;

function MentorFeedbackView({ location }) {
  const history = useHistory();

  const { feedbacks } = location.state;

  const [selectedFeedback, setSelectedFeedback] = useState({});

  const [fetchLimitError, setFetchLimitError] = useState(false);

  const onPreviousFeedback = () => {
    const currentIndex = feedbacks.indexOf(selectedFeedback);

    if (currentIndex > 0) {
      setSelectedFeedback(feedbacks[currentIndex - 1]);
    }
  };

  const onNextFeedback = () => {
    const currentIndex = feedbacks.indexOf(selectedFeedback);

    if (currentIndex < feedbacks.length - 1) {
      setSelectedFeedback(feedbacks[currentIndex + 1]);
    }
  };

  // const { data: feedbacks, isValidating } = useSWR(
  //   ["/feedbacks"],
  //   () => replyFeedbackList(),
  //   {
  //     onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
  //       // Only retry up to 3 times.
  //       if (retryCount && retryCount >= 3) {
  //         if (!feedbacks) setFetchLimitError(true);

  //         return;
  //       }
  //       // Retry after 3 seconds.
  //       setTimeout(() => revalidate({ retryCount: retryCount + 1 }), 3000);
  //     },
  //   }
  // );

  return (
    <div className={styles.mainContainer}>
      <div className={styles.backButtonContainer}>
        <button
          className={styles.backButtonText}
          onClick={() => history.goBack()}
        >
          <FaRegArrowAltCircleLeft
            className={styles.backButton}
            color="#C4C4C4"
            size={24}
          />
        </button>
      </div>

      <div className={styles.content}>
        <div className={styles.headerContainer}>
          <div className={styles.headerTextGroup}>
            <h1 className={styles.headerText}>Mentor NQA reviews</h1>

            <text className={styles.headerSubText}>
              You can find all previous mentor feedback here.
            </text>
          </div>

          <button
            className={styles.backToDashboardButton}
            onClick={() => history.push("/nqa")}
          >
            Back to dashboard
          </button>
        </div>

        {/* {dummyFeedback.map((feedback, index) => (
          <div
            key={feedback.id}
            className={
              selectedFeedback.id === feedback.id
                ? `${styles.feedbackRow} ${styles.activeFeedbackRow}`
                : styles.feedbackRow
            }
            onClick={() => setSelectedFeedback(dummyFeedback[index])}
          >
            <div>
              <text>{feedback.reviewedOn}</text>

              <text>{feedback.name}</text>
            </div>

            <div>
              <text
                className={
                  selectedFeedback.id === feedback.id
                    ? `${styles.feedbackButton} ${styles.activeFeedbackButton}`
                    : styles.feedbackButton
                }
              >
                {selectedFeedback.id === feedback.id ? "Active" : "Open"}
              </text>

              <Button
                className={
                  selectedFeedback.id === feedback.id
                    ? `${styles.feedbackButtonIcon} ${styles.activeFeedbackButtonIcon}`
                    : styles.feedbackButtonIcon
                }
                icon={
                  selectedFeedback.id === feedback.id
                    ? "arrow down"
                    : "arrow right"
                }
                circular
              />
            </div>
          </div>
        ))} */}

        {!isEmpty(feedbacks) &&
          feedbacks.map((feedback, index) => {
            return (
              <div
                key={feedback.id}
                className={
                  selectedFeedback.request_id === feedback.request_id
                    ? `${styles.feedbackRow} ${styles.activeFeedbackRow}`
                    : styles.feedbackRow
                }
                onClick={() => setSelectedFeedback(feedback)}
              >
                <div>
                  <text>{moment(feedback.date).format("DD/MM/YYYY")}</text>

                  <text>
                    NQA {JSON.parse(feedback.nqa).join(", ")} - Feedback
                    Assessment
                  </text>
                </div>

                <div>
                  <text
                    className={
                      selectedFeedback.request_id === feedback.request_id
                        ? `${styles.feedbackButton} ${styles.activeFeedbackButton}`
                        : styles.feedbackButton
                    }
                  >
                    {selectedFeedback.request_id === feedback.request_id
                      ? "Active"
                      : "Open"}
                  </text>

                  <Button
                    className={
                      selectedFeedback.request_id === feedback.request_id
                        ? `${styles.feedbackButtonIcon} ${styles.activeFeedbackButtonIcon}`
                        : styles.feedbackButtonIcon
                    }
                    icon={
                      selectedFeedback.request_id === feedback.request_id
                        ? "arrow down"
                        : "arrow right"
                    }
                    circular
                  />
                </div>
              </div>
            );
          })}

        {!isEmpty(selectedFeedback) && (
          <div className={styles.cardContainer}>
            <div className={styles.cardLeftColumn}>
              <text className={styles.cardHeaderText}>
                {!isEmpty(selectedFeedback) &&
                  `NQA ${JSON.parse(selectedFeedback.nqa).join(
                    ", "
                  )} - Feedback Assessment`}
              </text>

              <text className={styles.cardSubText}>
                {/* {!isEmpty(selectedFeedback) && selectedFeedback.provide_feedback} */}
              </text>

              {/* <text className={styles.cardHeaderText}>Feedback notes</text> */}

              {/* <text className={styles.cardSubText}> */}
              {!isEmpty(selectedFeedback) && (
                <div
                  className={`disabledPreflight`}
                  dangerouslySetInnerHTML={{
                    __html: selectedFeedback.provide_feedback,
                  }}
                ></div>
              )}
              {/* </text> */}
            </div>

            <div className={styles.cardRightColumn}>
              <div>
                <text className={styles.cardHeaderText}>NQA Status</text>

                <text
                  className={
                    selectedFeedback.status === 1
                      ? styles.statusActive
                      : styles.statusInactive
                  }
                >
                  Working towards
                </text>

                <div className={styles.stepLine} />

                <text
                  className={
                    selectedFeedback.status === 2
                      ? styles.statusActive
                      : styles.statusInactive
                  }
                >
                  Meeting
                </text>

                <div className={styles.stepLine} />

                <text
                  className={
                    selectedFeedback.status === 3
                      ? styles.statusActive
                      : styles.statusInactive
                  }
                >
                  Exceeding
                </text>

                <text className={styles.reviewDetailHeader}>Review by</text>

                <text className={styles.reviewDetailSubText}>
                  {!isEmpty(selectedFeedback) && selectedFeedback.provided_by}
                </text>

                <text className={styles.reviewDetailHeader}>
                  Review written on
                </text>

                <text className={styles.reviewDetailSubText}>
                  {!isEmpty(selectedFeedback) &&
                    moment(selectedFeedback.date_reviewed).format("DD/MM/YYYY")}
                </text>
              </div>
            </div>
          </div>
        )}

        {!isEmpty(selectedFeedback) && (
          <div className={styles.reportButtonRow}>
            <button
              className={
                feedbacks.indexOf(selectedFeedback) === 0
                  ? styles.prevNextReportButton
                  : styles.activePrevNextReportButton
              }
              onClick={onPreviousFeedback}
            >
              <Icon name="angle double left" />
              <text>Previous report</text>
            </button>

            <button
              className={
                feedbacks.indexOf(selectedFeedback) === feedbacks.length - 1
                  ? styles.prevNextReportButton
                  : styles.activePrevNextReportButton
              }
              onClick={onNextFeedback}
            >
              <text>Next report</text>
              <Icon name="angle double right" />
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default MentorFeedbackView;
