import * as React from "react";

import PropTypes from "prop-types";
import { Button, Input, Dropdown } from "semantic-ui-react";

import styles from "./TasksReportView.module.css";
import BlueButton from "../../../components/BlueButton";
import InputComponent from "../../../components/InputComponent";
import { generateReport, fetchCenterUsers } from "../../../api/requests";
import { FaSpinner } from "react-icons/fa";
import { isEmpty } from "lodash";
import { useStoreState } from "../../../hooks";

const { useState, useCallback } = React;

const users = [
  {
    key: "1",
    value: 1,
    text: "Add An Additional Service",
  },
  {
    key: "2",
    value: 2,
    text: "Remove A Service",
  },
  {
    key: "3",
    value: 3,
    text: "Upgrade to Portal + Mentoring",
  },
  {
    key: "4",
    value: 4,
    text: "Downgrade to the QIP Portal",
  },
  {
    key: "5",
    value: 5,
    text: "Cancel Subscription",
  },
  {
    key: "6",
    value: 6,
    text: "Other",
  },
];

function TasksReportView({
  onGenerateFinish,
  onGenerateReportError,
  usersLists,
  setTaskReportFilter,
  filtered,
}) {
  const [seletectedReportType, setSelectedReportType] = useState(5);
  const [selectedNQAList, setSelectedNQAList] = useState([1]);
  const [generateLoading, setLoading] = useState(false);

  const userId = useStoreState((state) => state.authStore.authObject.userId);
  const centerId = useStoreState((state) => state.nqaStore.serviceDetails.id);

  const [taskUser, setTaskUser] = useState("All");

  const [reportLabel, setReportLabel] = useState("");

  const [fieldChecked, setFieldChecked] = useState(false);

  const [filter, setFilter] = useState("All");

  const onSelectNQA = (nqa) => {
    if (selectedNQAList.includes(nqa)) {
      setSelectedNQAList(selectedNQAList.filter((number) => number !== nqa));
    } else {
      setSelectedNQAList(selectedNQAList.concat(nqa));
    }
  };

  const onGenerateReport = async () => {
    setFieldChecked(true);

    if (!reportLabel) {
      // alert("Please give this report a name.");
      return false;
    }
    setLoading(true);

    try {
      await generateReport(
        userId,
        centerId,
        reportLabel,
        seletectedReportType,
        selectedNQAList
      );
      await onGenerateFinish();
      // setFieldChecked(true);
    } catch (error) {
      console.log(error);
      // setFieldChecked(true);
    }
    setFieldChecked(false);
    setLoading(false);
  };

  return (
    <div
      className={`${styles.createReportContainer} flex flex-col mb-1 md:px-16 lg:px-24 py-16`}
    >
      <text className={styles.descriptionText}>
        Easily generate reports here and save them as pdf files. Choose the type
        of report that you need (Evidence Task report, Goal Task, or All Tasks),
        choose the NQAs you want to run your report on and click ‘Generate a
        Report’.
        {/* Easily generate reports here and save them as .pdf files. Choose the
        type of report that you need, and Evidence Task report, or a Goal Task
        and click ‘generate report’. You can also filter reports by both NQA
        area, and also by person assigned to tasks. */}
      </text>

      <div className={`${styles.reportTypeRow} flex justify-between`}>
        <div className={styles.reportTypeColumn}>
          <Button
            className={
              seletectedReportType === 5
                ? styles.reportTypeSelected
                : styles.reportType
            }
            onClick={() => setSelectedReportType(5)}
            icon="check"
          />

          <text className={styles.reportTypeText}>Evidence Tasks Report</text>
        </div>

        <div className={styles.reportTypeColumn}>
          <Button
            className={
              seletectedReportType === 6
                ? styles.reportTypeSelected
                : styles.reportType
            }
            onClick={() => setSelectedReportType(6)}
            icon="check"
          />

          <text className={styles.reportTypeText}>Goal Tasks Report</text>
        </div>

        <div className={styles.reportTypeColumn}>
          <Button
            className={
              seletectedReportType === 7
                ? styles.reportTypeSelected
                : styles.reportType
            }
            onClick={() => setSelectedReportType(7)}
            icon="check"
          />

          <text className={styles.reportTypeText}>All Tasks Report</text>
        </div>
      </div>

      <text className={styles.nqaReportFilterHeader}>
        Filter report by selected NQA Area
      </text>

      <div className={`${styles.nqaReportFilterRow} flex justify-between`}>
        {[...Array(7).keys()].map((nqa) => (
          <button
            className={
              selectedNQAList.includes(nqa + 1)
                ? styles.nqaReportFilterSelected
                : styles.nqaReportFilter
            }
            key={nqa}
            onClick={() => onSelectNQA(nqa + 1)}
          >
            NQA{nqa + 1}
          </button>
        ))}
      </div>

      {/* <div className={styles.searchFilterRow}>
        <text className={styles.nqaReportFilterHeader}>
          Filter reports by person
        </text>

        <Dropdown
          fluid
          selection
          className={styles.dropdownBox}
          placeholder="Choose"
          value={filtered}
          options={
            usersLists
              ? [{ id: 0, value: "All", text: "All" }, ...usersLists]
              : []
          }
          onChange={(e, data) => {
            setTaskReportFilter(data.value);
          }}
        />
      </div> */}

      <div className={styles.reportName}>
        <InputComponent
          label="Give this report a name"
          required
          showNotFilled={fieldChecked}
          inputValue={reportLabel}
          setInputValue={(fieldName, value) => setReportLabel(value)}
        />
      </div>

      <BlueButton
        // buttonText="Generate a Report"
        buttonText={
          generateLoading ? (
            <div className={`flex justify-center items-center`}>
              <FaSpinner className={`animate-spin`} />
              <span className={`block pl-2`}>Generating Report...</span>
            </div>
          ) : (
            <> Generate a Report</>
          )
        }
        width="100%"
        height="80px"
        onClick={onGenerateReport}
      />
    </div>
  );
}

TasksReportView.propTypes = {
  onGenerateFinish: PropTypes.func,
  onGenerateReportError: PropTypes.func,
};

export default TasksReportView;
