import * as React from "react";

import PropTypes from "prop-types";
import { Modal } from "semantic-ui-react";

import SkipButton from "../../SkipButton";
import styles from "./ConfirmCancelSubscriptionModal.module.css";
import { cancelSubscription } from "../../../api/requests";
import { FaSpinner } from "react-icons/fa";

const { useState } = React;

function ConfirmCancelSubscriptionModal({
  visibility,
  subInfo,
  onOpenModal,
  onCloseModal,
  onNotice,
}) {
  const [cancelling, setCancelling] = useState(false);

  const onCancelSubscription = async () => {
    try {
      setCancelling(true);
      const response = await cancelSubscription(
        Number(subInfo.userId),
        Number(subInfo.centerId)
      );
      onNotice();
      setCancelling(false);
    } catch (error) {
      setCancelling(false);
      console.log(error);
    }
  };

  return (
    <Modal
      size="small"
      centered
      open={visibility}
      onOpen={onOpenModal}
      closeOnDimmerClick={false}
      onClose={onCloseModal}
    >
      <>
        {cancelling ? (
          <div className={`flex flex-col justify-center items-center py-14 `}>
            <FaSpinner
              className={`animate-spin text-qipGreen font-bold text-6xl`}
            />
            <span className={`text-lg py-2`}>
              Cancelling your subscription...
            </span>
          </div>
        ) : (
          <div className={styles.modalContainer}>
            <text className={styles.headerText}>
              Are you sure you want to cancel your subscription?
            </text>

            <div className={styles.buttonGroup}>
              <button
                className={styles.nextTipButton}
                onClick={onCancelSubscription}
              >
                Yes
              </button>

              <SkipButton onClick={onCloseModal} buttonText="No" />
            </div>
          </div>
        )}
      </>
    </Modal>
  );
}

ConfirmCancelSubscriptionModal.propTypes = {
  visibility: PropTypes.bool.isRequired,
  onOpenModal: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  onNotice: PropTypes.func.isRequired,
  onRemoveMessage: PropTypes.func.isRequired,
};

export default ConfirmCancelSubscriptionModal;
