import React from "react";
import { FaCheck, FaTimes } from "react-icons/fa";
import { useStoreState, useStoreActions } from "../../hooks";
import { useHistory } from "react-router-dom";

const NewFooter = () => {
  const authObj = useStoreState((state) => state.authStore.authObject);
  const history = useHistory();
  return (
    <>
      {" "}
      <div className="portalFooter mt-10">
        <div className={`footerTop `}>
          <div className={`footerRow ${authObj ? "" : "hidden"} `}>
            <h3 className="text-center">Holistic products shortcuts</h3>

            <ul className="products">
              <li className="product commingSoon text-center">
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="times"
                  className="svg-inline--fa fa-times fa-w-11"
                  role="img"
                  width=" 14"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 352 512"
                >
                  <path
                    fill="currentColor"
                    d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"
                  ></path>
                </svg>
                <span className="block">CCS Software</span>
                <a
                  href="javascript:void(0)"
                  className="inline-block"
                //   target="_blank"
                >
                  COMING SOON
                </a>
              </li>

              <li className="product visit text-center">
                <FaCheck size={14} />
                <span className="block">Products & Events</span>
                <a
                  href="https://holisticmanagement.net.au/resources/"
                  target="_blank"
                  rel="noreferrer"
                  className="inline-block"
                >
                  VISIT
                </a>
              </li>

              <li className="product commingSoon text-center">
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="times"
                  className="svg-inline--fa fa-times fa-w-11"
                  role="img"
                  width=" 14"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 352 512"
                >
                  <path
                    fill="currentColor"
                    d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"
                  ></path>
                </svg>
                <span className="block">HR Software</span>
                <a
                  href="javascript:void(0)"
                  className="inline-block"
                //   target="_blank"
                >
                  COMING SOON
                </a>
              </li>

              <li className="product commingSoon text-center">
                <FaTimes size={20} />
                <span className="block">Resources</span>
                <a className="inline-block">COMING SOON</a>
              </li>

              <li className="product subscribed text-center">
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="check"
                  className="svg-inline--fa fa-check fa-w-16"
                  role="img"
                  width=" 14"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                >
                  <path
                    fill="currentColor"
                    d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"
                  ></path>
                </svg>
                <span className="block">HMC Portal</span>
                <a
                  href="https://app.holisticportal.com.au/"
                  className="inline-block"
                  target="_blank"
                  rel="noreferrer"
                >
                  VISIT
                </a>
              </li>

              <li className="product subscribed active text-center">
                <FaCheck size={14} />
                <span className="block">Holistic QIP</span>
                <a className="inline-block">ACTIVE</a>
              </li>
            </ul>
          </div>
        </div>

        <div className="footerBottom">
          <div className="footerRow flex items-center justify-between">
            <div className={`footerMenu ${authObj ? "visible" : "invisible"}`}>
              <ul className="flex items-center">
                <li>
                  <span
                    onClick={() => {
                      history.push("/qip/nqa");
                    }}
                    className="footerMenuText cursor-pointer">
                    {`NQA's`}
                  </span>
                </li>
                <li>
                  <span
                    onClick={() => {
                      history.push("/qip/reports");
                    }}
                    className="footerMenuText cursor-pointer">
                    Reports
                  </span>
                </li>
                <li>
                  <span
                    onClick={() => {
                      history.push("/qip/tasks");
                    }}
                    className="footerMenuText cursor-pointer">
                    Tasks
                  </span>
                </li>
                <li>
                  <span
                    onClick={() => {
                      history.push("/qip/user-resource");
                    }}
                    className="footerMenuText cursor-pointer">
                    Resources
                  </span>
                </li>
              </ul>
            </div>

            <div className="footerCopyright">
              <span className="block">
                Copyright © 2021 Holistic Management And Consultancy. All Rights
                Reserved
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewFooter;
