import * as React from 'react';

import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Modal } from 'semantic-ui-react'; 

import BlueButton from '../../BlueButton';
import styles from './TrialAccountModal.module.css';

function TrialAccountModal({ visibility, onOpenModal, onCloseModal }) {
  const history = useHistory();

  return (
    <Modal
      size="small"
      centered
      open={visibility}
      onOpen={onOpenModal}
      onClose={onCloseModal}
    >
      <div className={styles.modalContainer}>

        <text className={styles.headerText}>
          Congratulations! <br/> Your trial account has been created.<br/> Let’s get started.
        </text>

        <BlueButton
          className={styles.saveButton}
          buttonText="Next"
          onClick={() => history.push('/qip/auth/legal-notice')}
        />

      </div>

    </Modal>
  );
}

TrialAccountModal.propTypes = {
  visibility: PropTypes.bool.isRequired,
  onOpenModal: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  onSavePassword: PropTypes.func.isRequired,
};

export default TrialAccountModal;
