import * as React from "react";
import BlueButton from "../../../components/BlueButton";

import { Button } from "semantic-ui-react";

import styles from "./PaymentDetails.module.css";

import {
  FaSpinner,
  FaCcAmex,
  FaCcVisa,
  FaCcJcb,
  FaCcMastercard,
} from "react-icons/fa";
import { Popconfirm } from "antd";
import { isEmpty } from "lodash";
import { useStoreActions, useStoreState } from "../../../hooks";

import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";

import { addPaymentMethod, removePaymentMethod } from "../../../api/requests";

const { useState, useCallback, useRef } = React;

import { styleCardNumber, styleCardExpiry, styleCardCvc } from "./CardStyle";

function PaymentDetails() {
  const stripe = useStripe();
  const elements = useElements();

  const nameEl = useRef(null);

  const userId = useStoreState((state) => state.authStore.authObject.userId);

  const paymentMethods = useStoreState(
    (state) => state.userStore.paymentMethods
  );

  const [name, setName] = useState("");
  const [cnerror, setCnerror] = useState({});
  const [cvcerror, setCvcerror] = useState({});
  const [expiryerror, setExpiryerror] = useState({});
  const [fieldsChecked, setFieldsChecked] = useState(false);
  const [loading, setLoading] = useState(false);

  const reFetchPMs = useStoreActions(
    (actions) => actions.userStore.fetchPaymentMethods
  );

  const reFetchPaymentMethods = useCallback(async () => {
    try {
      reFetchPMs({ userId });
    } catch (error) {
      console.log(error);
    }
  }, [reFetchPMs, userId]);

  const onRemovePaymentMethod = useCallback(
    async (pmId) => {
      try {
        const response = await removePaymentMethod(pmId);
        await reFetchPaymentMethods();
      } catch (error) {
        console.log(error);
      }
    },
    [reFetchPaymentMethods]
  );

  const onAddPaymentMethod = useCallback(
    async (pmId) => {
      try {
        const reponse = await addPaymentMethod(userId, pmId);
        await reFetchPaymentMethods();
      } catch (error) {
        console.log(error);
      }
    },
    [reFetchPaymentMethods, userId]
  );

  const sendPaymentMethod = () => {
    setLoading(true);
    setFieldsChecked(true);
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      setLoading(false);
      return;
    }

    if (isEmpty(name)) {
      setLoading(false);
      return;
    }

    stripe
      .createPaymentMethod({
        type: `card`,
        card: elements.getElement(CardNumberElement),
        billing_details: {
          name,
        },
      })
      .then(async (result) => {
        if (result.error) {
          setLoading(false);
        } else {
          console.log("pm result ", result);
          await onAddPaymentMethod(result.paymentMethod.id);

          elements.getElement(CardNumberElement).clear();
          elements.getElement(CardExpiryElement).clear();
          elements.getElement(CardCvcElement).clear();
          nameEl.current.value = "";
          setName("");
          setFieldsChecked(false);
          setLoading(false);
        }
      });
  };

  return (
    <div className={styles.mainContainer}>
      <text className={styles.headerText}>
        Subscription by Credit Card(Stripe)
      </text>
      <table className={`w-2/3`}>
        <thead className={` `}>
          <tr>
            <th className={`${styles.subscriptionHeader} w-5 font-normal `}>
              Method
            </th>
            <th className={`${styles.subscriptionHeader} w-4 font-normal `}>
              Expires
            </th>
            <th className={`${styles.subscriptionHeader} w-4 font-normal `}>
              Delete
            </th>
          </tr>
        </thead>
        <tbody>
          {paymentMethods &&
            paymentMethods.map((pm) => {
              return (
                <tr key={pm.id} className={` `}>
                  <td className={`${styles.subscriptionData} w-5 py-3`}>{`${
                    pm.card.brand.charAt(0).toUpperCase() +
                    pm.card.brand.slice(1)
                  } ending ${pm.card.last4}`}</td>
                  <td
                    className={`${styles.subscriptionData} w-4 py-3`}
                  >{`${pm.card.exp_month}/${pm.card.exp_year}`}</td>
                  <td
                    className={`${styles.subscriptionData} py-3 cursor-default`}
                  >
                    {paymentMethods.length > 1 && (
                      <Popconfirm
                        placement={`right`}
                        title={
                          <div className={`w-60 ${styles.warningText}`}>
                            Are you sure you want to delete this payment method?
                          </div>
                        }
                        okText={`Yes`}
                        cancelText={`No`}
                        onConfirm={async () => {
                          await onRemovePaymentMethod(pm.id);
                        }}
                      >
                        {" "}
                        <Button
                          className={styles.deleteButton}
                          icon="close"
                          size={`tiny`}
                          // onClick={async () => {
                          //   await onRemovePaymentMethod(pm.id);
                          //   setRefetch(!refetch);
                          // }}
                        />
                      </Popconfirm>
                    )}
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>

      <text className={`${styles.headerText} border-t mt-10 pt-12`}>
        Add a payment method{" "}
      </text>
      <span
        className={`${styles.subscriptionHeader} mb-8 flex justify-start items-center`}
      >
        <span className={`mr-5`}>Pay with your credit card via Stripe.</span>{" "}
        <FaCcVisa
          className={`mr-2`}
          style={{
            fontSize: "30px",
            color: "#003E94",
          }}
        />
        <FaCcMastercard
          className={`mr-2`}
          style={{
            fontSize: "30px",
            color: "#FD0E26",
          }}
        />{" "}
        <FaCcAmex
          className={`mr-2`}
          style={{
            fontSize: "30px",
            color: "#1C4AD4",
          }}
        />
        <FaCcJcb
          className={`mr-2`}
          style={{
            fontSize: "30px",
            color: "#004695",
          }}
        />
      </span>
      <div className={`w-full flex flex-col justify-center items-center `}>
        <div className={`w-full flex justify-around`}>
          <label className={`w-1/2 pr-5`}>
            <span className={styles.inputName}>
              Card name<span className={styles.warning}>*</span>
            </span>
            <input
              ref={nameEl}
              className={`${styles.inputCCName} w-full border block antialiased py-4 px-4 rounded-md`}
              onChange={(e) => setName(e.target.value)}
            />
            {fieldsChecked && isEmpty(name) && (
              <div className={`p-2 ${styles.warning} font-thin`}>
                {`Name is required.`}
              </div>
            )}
          </label>

          <label className={`w-1/2 pl-5`}>
            <span className={styles.inputName}>
              Card number<span className={styles.warning}>*</span>
            </span>
            <CardNumberElement
              className={`w-full border py-4 px-4 rounded-md`}
              options={styleCardNumber}
              onChange={(e) => setCnerror(e)}
            />
            {!isEmpty(cnerror) && fieldsChecked && !isEmpty(cnerror.error) && (
              <div className={`p-2 ${styles.warning} font-thin`}>
                {cnerror.error.message}
              </div>
            )}
            {!isEmpty(cnerror) &&
              fieldsChecked &&
              isEmpty(cnerror.error) &&
              !cnerror.complete &&
              cnerror.empty && (
                <div className={`p-2 ${styles.warning} font-thin `}>
                  {`Credit card number is required.`}
                </div>
              )}
            {fieldsChecked && isEmpty(cnerror) && (
              <div className={`p-2 ${styles.warning} font-thin `}>
                {`Credit card number is required.`}
              </div>
            )}
          </label>
        </div>
        <div className={`w-full flex justify-between mt-5`}>
          <label className={`w-1/2 pr-5`}>
            <span className={styles.inputName}>
              Expiry date<span className={styles.warning}>*</span>
            </span>
            <CardExpiryElement
              className={`w-full border py-4 px-4 rounded-md`}
              options={styleCardExpiry}
              onChange={(e) => setExpiryerror(e)}
            />
            {!isEmpty(expiryerror) &&
              fieldsChecked &&
              !isEmpty(expiryerror.error) && (
                <div className={`p-2 ${styles.warning} font-thin`}>
                  {expiryerror.error.message}
                </div>
              )}

            {!isEmpty(expiryerror) &&
              fieldsChecked &&
              isEmpty(expiryerror.error) &&
              !expiryerror.complete &&
              expiryerror.empty && (
                <div className={`p-2 ${styles.warning} font-thin`}>
                  {`CC expiry date is required.`}
                </div>
              )}

            {fieldsChecked && isEmpty(expiryerror) && (
              <div className={`p-2 ${styles.warning} font-thin`}>
                {`CC expiry date is required.`}
              </div>
            )}
          </label>
          <label className={`w-1/2 pl-5`}>
            <span className={styles.inputName}>
              Card code (CVC)<span className={styles.warning}>*</span>
            </span>
            <CardCvcElement
              className={`w-full border py-4 px-4 rounded-md`}
              options={styleCardCvc}
              onChange={(e) => setCvcerror(e)}
            />
            {!isEmpty(cvcerror) &&
              fieldsChecked &&
              !isEmpty(cvcerror.error) && (
                <div className={`p-2 ${styles.warning} font-thin`}>
                  {cvcerror.error.message}
                </div>
              )}

            {!isEmpty(cvcerror) &&
              fieldsChecked &&
              isEmpty(cvcerror.error) &&
              !cvcerror.complete &&
              cvcerror.empty && (
                <div className={`p-2 ${styles.warning} font-thin`}>
                  {`CVC is required.`}
                </div>
              )}

            {fieldsChecked && isEmpty(cvcerror) && (
              <div className={`p-2 ${styles.warning} font-thin`}>
                {`CVC is required.`}
              </div>
            )}
          </label>
        </div>
      </div>

      {/* <div className={styles.inputRow}>
        <InputComponent
          className={styles.inputBox}
          label="CC number"
          type="tel"
        />

        <InputComponent label="CC CVC" type="tel" />
      </div>

      <div className={styles.inputRow}>
        <InputComponent
          className={styles.inputBox}
          label="CC name"
          type="tel"
        />

        <InputComponent label="CC expiry" type="tel" />
      </div> */}
      {/* {!isEmpty(error) && (
        <div
          className={`text-red-400 font-thin flex justify-center items-center mt-8 `}
        >
          <div className={`flex justify-center items-center`}>
            <FaTimesCircle /> <span className={`ml-2`}>{error.message} </span>
          </div>
        </div>
      )} */}
      <BlueButton
        className={styles.blueButton}
        onClick={sendPaymentMethod}
        //buttonText="Save changes"
        height="60px"
        disabled={loading}
        buttonText={
          loading ? (
            <div className={`flex justify-center items-center`}>
              <FaSpinner className={`animate-spin`} />{" "}
              <span className={`ml-2`}>Please wait...</span>{" "}
            </div>
          ) : (
            <>Add payment method</>
          )
        }
      />
    </div>
  );
}

export default PaymentDetails;
