import * as React from "react";

import ReactPixel from "react-facebook-pixel";

import PropTypes from "prop-types";
import { HiCheckCircle } from "react-icons/hi";
import { useHistory, Redirect } from "react-router-dom";
import { FaRegArrowAltCircleLeft } from "react-icons/fa";
import { Icon } from "semantic-ui-react";
import styles from "./PricingPlans.module.css";
import BlueButton from "../../../components/BlueButton";
import { useStoreState, useStoreActions } from "../../../hooks";
import { Modal, Button as btn } from "antd";

const { useState, useEffect, useCallback } = React;

function PricingPlans({ setPlanChoice, setPlanSubscription }) {
  const history = useHistory();

  const isLoggedIn = useStoreState((state) => state.authStore.isLoggedIn);

  const [portalState, setPortalState] = useState("monthly");
  const [mentoringState, setMentoringState] = useState("monthly");

  useStoreActions((state) => state.appStore.setFirstSignUp(true));

  //##################### REACT PIXEL #####################################
  const advancedMatching = { em: "some@email.com" }; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/pixel-with-ads/conversion-tracking#advanced_match
  const options = {
    autoConfig: true, // set pixel's autoConfig
    debug: false, // enable logs
  };

  ReactPixel.init("791679465004341", advancedMatching, options);

  ReactPixel.pageView();
  {
    /* ReactPixel.track(event, data);
  ReactPixel.trackSingle('791679465004341', event, data);
  ReactPixel.trackCustom(event, data); */
  }
  //##################### REACT PIXEL ######################################

  const firstSignUp = useStoreState((state) => state.appStore.firstSignUp);

  const redirectUrl = isLoggedIn ? "/qip/payment" : "/qip/auth/signup";

  const MentoringPlanBenefits = () => {
    const benefitsArray = [
      "Everything inside the QIP Portal",
      "Request feedback to all NQAs",
      "Get guidance from our mentors",
      "Termly mentoring sessions",
      "Monthly bespoke service resources",
      "A&R preview & preparation",
      "Access monthly expert webinars",
      "Email and phone support",
    ];

    return benefitsArray.map((item) => (
      // <div key={item} className={styles.checkTextGroup}>
      <div key={item} className={`flex justify-start items-center py-2`}>
        <HiCheckCircle color="#8BC334" />
        <text className={styles.checkText}>{item}</text>
      </div>
    ));
  };

  const SatPlanBenefits = () => {
    const benefitsArray = [
      "Create & save QIP/SATs online",
      "Generate fast QIP/SAT reports",
      "Achieve NQA goals as a team",
      "Access our exclusive resources",
      "A&R preview & preparation",
      "New monthly resources",
      "Access monthly expert webinars",
      "Email and phone support",
    ];

    return benefitsArray.map((item) => (
      // <div key={item} className={styles.checkTextGroup}>
      <div key={item} className={`flex justify-start items-center py-2`}>
        <HiCheckCircle color="#8BC334" />
        <text className={styles.checkText}>{item}</text>
      </div>
    ));
  };

  const FreePlanBenefits = () => {
    const benefitsArray = [
      "Create & save QIP/SATs online",
      "Generate fast QIP/SAT reports",
      "Achieve NQA goals as a team",
      "Access our exclusive resources",
      "A&R preview & preparation",
      "New monthly resources",
      "Access monthly expert webinars",
      "Email and phone support",
    ];

    return benefitsArray.map((item) => (
      // <div key={item} className={styles.checkTextGroup}>
      <div key={item} className={`flex justify-start items-center py-2`}>
        <HiCheckCircle color="#8BC334" />
        <text className={`${styles.checkText} `}>{item}</text>
      </div>
    ));
  };

  return (
    <>
      <div className={styles.mainContainer}>
        <div className={`${styles.backButtonContainer} mt-8`}>
          {/* <button
            className={styles.backButtonText}
            onClick={() => history.goBack()}
          >
            <FaRegArrowAltCircleLeft
              className={styles.backButton}
              color="#C4C4C4"
              size={24}
            />
          </button> */}
        </div>

        {isLoggedIn && (
          <div className={styles.headerText}>
            <h1>Signup to a plan</h1>
            <text>
              To continue to enjoy all the benefits of Holistic QIP after your
              trial period ends, signup to one of our plans.
            </text>
          </div>
        )}

        <div
          className={
            !isLoggedIn
              ? `${styles.cardsContainer} ${styles.loggedOut}`
              : styles.cardsContainer
          }
        >
          {!isLoggedIn && (
            <div className={styles.cardGroup}>
              <div className={styles.cardGroupHeader}>
                <div className={styles.cardTabHighlighted}>
                  <text>Promotional</text>
                </div>
                <div className={styles.emptyColumn} />
              </div>

              <div className={styles.cardContainer}>
                <div className={styles.cardContent}>
                  <div className={styles.cardHeaderGroup}>
                    <text className={styles.selectedCardHeader}>Free</text>
                  </div>

                  <h4 className={styles.selectedCardSubheader}>30 day trial</h4>

                  {!isLoggedIn && (
                    <div className={styles.cardDescriptionGreen}>
                      <Icon
                        className={styles.cardDescriptionGreenIcon}
                        name="info circle"
                      />
                      <text>
                        No payment details required. Choose a payment plan after
                        30 days.
                      </text>
                    </div>
                  )}

                  <div className={styles.selectedCardDescription}>
                    <text>
                      Get full access to the Holistic QIP app for 30 days free
                      of charge!
                    </text>
                  </div>

                  <FreePlanBenefits />

                  <BlueButton
                    className={styles.blueButton}
                    buttonText="Start trial"
                    link="/qip/auth/free-trial"
                  />
                </div>
              </div>
            </div>
          )}

          <div className={styles.cardGroup}>
            <div className={styles.cardGroupHeader}>
              <div
                className={
                  portalState === "monthly"
                    ? styles.cardTabHighlighted
                    : `${styles.cardTabFaded} ${styles.cardTabBorder} ${styles.left}`
                }
                onClick={() => setPortalState("monthly")}
              >
                <text>Monthly</text>
              </div>

              <div
                className={
                  portalState === "yearly"
                    ? styles.cardTabHighlighted
                    : `${styles.cardTabFaded} ${styles.cardTabBorder} ${styles.right}`
                }
                onClick={() => setPortalState("yearly")}
              >
                <text>Yearly</text>
              </div>
            </div>

            <div className={styles.cardContainer}>
              <div className={styles.cardContent}>
                <div className={styles.cardHeaderGroup}>
                  <text
                    style={{
                      fontSize: "20px",
                      lineHeight: "50px",
                      marginRight: ".5rem",
                    }}
                  >
                    $
                  </text>
                  <text className={styles.selectedCardHeader}>
                    {portalState === "monthly" ? "97" : "997"}
                  </text>
                  <text
                    style={{
                      fontSize: "20px",
                      lineHeight: "55px",
                      alignSelf: "flex-end",
                    }}
                  >
                    {portalState === "yearly" ? (
                      "/yearly"
                    ) : (
                      <span>
                        /month<span className={styles.asterisk}>*</span>
                      </span>
                    )}
                  </text>
                </div>

                <h4 className={styles.selectedCardSubheader}>QIP/SAT Portal</h4>

                {!isLoggedIn && (
                  <div className={styles.cardDescriptionGreen}>
                    <Icon
                      className={styles.cardDescriptionGreenIcon}
                      name="info circle"
                    />
                    <text>
                      Your first 30 days are free! Payments start after this
                      introductory period.
                    </text>
                  </div>
                )}

                <div className={styles.selectedCardDescription}>
                  <text>
                    Full access to the Holistic QIP app and all our available
                    resources.
                  </text>
                </div>

                <SatPlanBenefits />

                <BlueButton
                  className={styles.blueButton}
                  buttonText="Buy now"
                  onClick={() => {
                    setPlanChoice("portal");
                    setPlanSubscription(portalState);
                    history.push(redirectUrl);
                  }}
                />

                <div className={styles.pricingFooterText}>
                  <text>
                    <i>
                      <span className={styles.asterisk}>*</span>Minimum 6 month
                      commitment.
                    </i>
                  </text>
                </div>
              </div>
            </div>
          </div>

          <div className={styles.cardGroup}>
            <div className={styles.cardGroupHeader}>
              <div
                className={
                  mentoringState === "monthly"
                    ? styles.cardTabHighlighted
                    : `${styles.cardTabFaded} ${styles.cardTabBorder} ${styles.left}`
                }
                onClick={() => setMentoringState("monthly")}
              >
                <text>Monthly</text>
              </div>

              <div
                className={
                  mentoringState === "yearly"
                    ? styles.cardTabHighlighted
                    : `${styles.cardTabFaded} ${styles.cardTabBorder} ${styles.right}`
                }
                onClick={() => setMentoringState("yearly")}
              >
                <text>Yearly</text>
              </div>
            </div>

            <div className={styles.cardContainer}>
              <div className={styles.cardContent}>
                <div className={styles.cardHeaderGroup}>
                  <text
                    style={{
                      fontSize: "20px",
                      lineHeight: "50px",
                      marginRight: ".5rem",
                    }}
                  >
                    $
                  </text>
                  <text className={styles.selectedCardHeader}>
                    {mentoringState === "monthly" ? "197" : "1,997"}
                  </text>
                  <text
                    style={{
                      fontSize: "20px",
                      lineHeight: "55px",
                      alignSelf: "flex-end",
                    }}
                  >
                    {mentoringState === "monthly" ? (
                      <span>
                        /month<span className={styles.asterisk}>*</span>
                      </span>
                    ) : (
                      "/year"
                    )}
                  </text>
                </div>

                <h4 className={styles.selectedCardSubheader}>
                  Portal + Mentoring
                </h4>

                {!isLoggedIn && (
                  <div className={styles.cardDescriptionGreen}>
                    <Icon
                      className={styles.cardDescriptionGreenIcon}
                      name="info circle"
                    />
                    <text>
                      Your first 30 days are free! Payments start after this
                      introductory period.
                    </text>
                  </div>
                )}

                <div className={styles.selectedCardDescription}>
                  <text>
                    The QIP Portal plus guidance & feedback from our mentors.
                  </text>
                </div>

                <MentoringPlanBenefits />

                <BlueButton
                  className={styles.blueButton}
                  buttonText="Buy now"
                  onClick={() => {
                    setPlanChoice("mentoring");
                    setPlanSubscription(mentoringState);
                    history.push(redirectUrl);
                  }}
                />

                <div className={styles.pricingFooterText}>
                  <text>
                    <i>
                      <span className={styles.asterisk}>*</span>Minimum 6 month
                      commitment.
                    </i>
                  </text>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.pricesText}>
          <text>All prices in Australian Dollars, and include GST.</text>
        </div>
      </div>
    </>
  );
}

PricingPlans.propTypes = {
  setPlanChoice: PropTypes.func,
  setPlanSubscription: PropTypes.func,
};

export default PricingPlans;
