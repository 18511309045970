import * as React from "react";

import PropTypes from "prop-types";
import { Button, Modal } from "semantic-ui-react";

import BlueButton from "../../BlueButton";
import InputComponent from "../../InputComponent";
import styles from "./SendEmailModal.module.css";

function SendEmailModal({
  visibility,
  onOpenModal,
  onCloseModal,
  onRemoveEmail,
}) {
  return (
    <Modal
      size="small"
      centered={true}
      open={visibility}
      onOpen={onOpenModal}
      onClose={onCloseModal}
    >
      <div className={styles.modalContainer}>
        <Button
          className={styles.closeButton}
          onClick={onCloseModal}
          size="big"
          icon="close"
        />

        <text className={styles.headerText}>Send via e-mail</text>

        <InputComponent label="Enter e-mail address" type="text" />

        <InputComponent
          className={styles.inputBox}
          label="Add comment for this file"
          type="text"
          textArea
        />

        <BlueButton
          className={styles.saveButton}
          buttonText="Send file"
          onClick={onRemoveEmail}
          height="80px"
          width="220px"
        />
      </div>
    </Modal>
  );
}

SendEmailModal.propTypes = {
  visibility: PropTypes.bool.isRequired,
  onOpenModal: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  onRemoveEmail: PropTypes.func.isRequired,
  onSavePassword: PropTypes.func.isRequired,
};

export default SendEmailModal;
