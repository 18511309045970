import * as React from "react";

import PropTypes from "prop-types";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";

import PaymentSignUpForm from "../../pages/PaymentSignUp/PaymentSignUpForm";
import PaymentForm from "../../pages/PaymentSignUp/PaymentForm/PaymentForm";
import { useStoreActions } from "../../hooks";
import { isEmpty } from "lodash";

function Payment({ plan, planVariant, stripeState, location }) {
  const match = useRouteMatch();
  const setHeaderMenu = useStoreActions((state) =>
    state.appStore.setHeaderMenu(false)
  );
  const showBanner = useStoreActions((state) => {
    state.appStore.showBanner(false);
  });

  // redirect to pricing-plans if accidentially went to payment page.

  if (!plan && isEmpty(location.state)) {
    return <Redirect to={`/qip/pricing-plans`} />;
  }

  return (
    <Switch>
      <Route exact path={`${match.path}`}>
        <PaymentForm
          planVariant={
            !isEmpty(location) && !isEmpty(location.state)
              ? location.state.planVariant
              : planVariant
          }
          plan={
            !isEmpty(location) && !isEmpty(location.state)
              ? location.state.plan
              : plan
          }
          stripeState={stripeState}
        />
      </Route>
    </Switch>
  );
}

Payment.propTypes = {
  plan: PropTypes.string,
  planVariant: PropTypes.string,
  stripeState: PropTypes.string,
};

export default Payment;
