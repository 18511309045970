import { Action, action, computed, Computed, Thunk, thunk } from "easy-peasy";

import { getUserMessages } from "../../api/requests";

export interface AppModel {
  setHeaderMenu: Action<AppModel, boolean>;
  headerMenu: boolean;
  setDisableMenu: Action<AppModel, boolean>;
  disableMenu: boolean;
  hasSubExpired: boolean;
  setSubExpired: Action<AppModel, boolean>;
  banner: boolean;

  showBanner: Action<AppModel, boolean>;
  firstSignUp: boolean;
  setFirstSignUp: Action<AppModel, boolean>;
  setMessages: Action<AppModel, any>;
  messages: any;
  selectedMessage: any;
  setSelectedMessage: Action<AppModel, any>;
  unreadMessagesCount: number;
  setUnreadMessagesCount: Action<AppModel, number>;
}

const appStore: AppModel = {
  unreadMessagesCount: 0,
  selectedMessage: {},
  messages: [],
  firstSignUp: false,
  setFirstSignUp: action((state, payload) => {
    state.firstSignUp = payload;
  }),
  headerMenu: false,
  disableMenu: false,
  setDisableMenu: action((state, payload) => {
    state.disableMenu = payload;
  }),
  hasSubExpired: false,
  banner: true,
  setHeaderMenu: action((state, payload) => {
    state.headerMenu = payload;
  }),
  setSubExpired: action((state, payload) => {
    state.hasSubExpired = payload;
  }),
  showBanner: action((state, payload) => {
    state.banner = payload;
  }),

  setMessages: action((state, payload) => {
    state.messages = payload;
  }),

  setSelectedMessage: action((state, payload) => {
    state.selectedMessage = payload;
  }),
  setUnreadMessagesCount: action((state, payload) => {
    state.unreadMessagesCount = payload;
  }),
};

export default appStore;
