import * as React from 'react';

import PropTypes from 'prop-types';
import { Modal } from 'semantic-ui-react';

import styles from './UserCreatedModal.module.css';
import BlueButton from '../../BlueButton';

function UserCreatedModal({ visibility, onOpenModal, onCloseModal }) {

  return (
    <Modal 
      size="small"
      centered={false}
      open={visibility}
      onOpen={onOpenModal}
      onClose={onCloseModal}
      closeOnDimmerClick={false}
    >
      <div className={styles.modalContainer}>

        <text className={styles.headerText}>
          New user created! Their user login details will be sent to the email provided.
        </text>

        <BlueButton
          buttonText="Ok"
          onClick={onCloseModal}
        />

      </div>

    </Modal>
  );
}

UserCreatedModal.propTypes = {
  visibility: PropTypes.bool.isRequired,
  onOpenModal: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired,
};

export default UserCreatedModal;
