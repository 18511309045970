export const styleCardNumber = {
  style: {
    base: {
      fontSmoothing: "antialiased",
      fontSize: "20px",
      "::placeholder": {
        color: "#C0C0C0",
      },
      iconColor: "#C4C4C4",
    },
    invalid: {
      color: "#f56666",
      iconColor: "#f56666",
    },
  },
  placeholder: "",
  showIcon: true,
  iconStyle: "solid",
};

export const styleCardExpiry = {
  style: {
    base: {
      fontSmoothing: "antialiased",
      fontSize: "20px",
      "::placeholder": {
        color: "#C0C0C0",
      },
    },
    invalid: {
      color: "#f56666",
    },
  },
};

export const styleCardCvc = {
  style: {
    base: {
      fontSmoothing: "antialiased",
      fontSize: "20px",
      "::placeholder": {
        color: "#C0C0C0",
      },
    },
    invalid: {
      color: "##f56666",
    },
  },
  placeholder: "",
};
