import * as React from "react";

import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { Modal } from "semantic-ui-react";
import {
  useStripe,
  CardElement,
  useElements,
  CardCvcElement,
  CardNumberElement,
  CardExpiryElement,
} from "@stripe/react-stripe-js";

import { FaTimesCircle } from "react-icons/fa";

import styles from "./CardForm.module.css";
import spinner from "../../static/images/preloader.png";
import { FaSpinner } from "react-icons/fa";
import BlueButton from "../BlueButton";
import useResponsiveFontSize from "./useResponsiveFontSize";
import { useStoreState } from "../../hooks";
import { isEmpty } from "lodash";

import { app_cache } from "../../utils";

import { Products } from "../../components/Products/Products";

import { fetchAuthUser } from "../../api/requests";

import { styleCardNumber, styleCardExpiry, styleCardCvc } from "./CardStyle";

const { useMemo, useState, useRef } = React;

// test
const priceIds = {
  auth: {
    portal: {
      yearly: "price_1HjdVfDeO3XG77wNA9yXwT1m",
      monthly: "price_1HjdVfDeO3XG77wNweTXFOcB",
    },
    mentoring: {
      yearly: "price_1HjdWJDeO3XG77wNw70ZhCMf",
      monthly: "price_1HjdWJDeO3XG77wN2ZWtoJzb",
    },
  },
  nonauth: {
    portal: {
      yearly: "price_1ISv7XDeO3XG77wNZHPAFVGy",
      monthly: "price_1ISv7HDeO3XG77wNIKR5IAk3",
      // monthly: "price_1JIq4HDeO3XG77wNu7sfk1zK",
    },
    mentoring: {
      yearly: "price_1ISv8XDeO3XG77wNAPhSKSsG",
      monthly: "price_1ISv8MDeO3XG77wNfKE2mu9G",
    },
  },
};

const useOptions = () => {
  const fontSize = useResponsiveFontSize();
  const options = useMemo(
    () => ({
      style: {
        base: {
          fontSize,
          width: "100%",
          color: "#424770",
          letterSpacing: "0.025em",
          fontFamily: "Source Code Pro, monospace",
          "::placeholder": {
            color: "#aab7c4",
          },
        },
        invalid: {
          color: "#9e2146",
        },
      },
    }),
    [fontSize]
  );

  return options;
};

const CardForm = ({ className, plan, planVariant, stripeState, callback }) => {
  const history = useHistory();

  const stripe = useStripe();
  const options = useOptions();
  const elements = useElements();
  const apiURI = process.env.REACT_APP_API_URL;
  let nameEl = useRef(null);
  const user = useStoreState((state) => state.userStore.loggedInUser);
  const userRegData = useStoreState((state) => state.authStore.signUpCreds);
  const email = user ? user.email : userRegData ? userRegData.email : null;
  const [name, setName] = useState("");

  const firstSignUp = useStoreState((state) => state.appStore.firstSignUp);

  const [paymentLoading, setPaymentLoading] = useState(false);
  const [paymentConfirmedModalVis, setPaymentConfirmedVis] = useState(false);
  const [error, setError] = useState({});
  //
  const [cnerror, setCnerror] = useState({});
  const [cvcerror, setCvcerror] = useState({});
  const [expiryerror, setExpiryerror] = useState({});
  const [fieldsChecked, setFieldsChecked] = useState(false);

  console.log(plan, planVariant);

  const onConfirmTrial = () => {
    setPaymentLoading(false);
    setPaymentConfirmedVis(false);
    history.push("/qip/auth/legal-notice");
  };

  const onConfirmPayment = () => {
    setPaymentLoading(false);
    if (callback) callback(true);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setFieldsChecked(true);
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      setPaymentLoading(false);
      return;
    }

    if (isEmpty(name)) {
      setPaymentLoading(false);
      return;
    }

    setPaymentLoading(true);

    const cardElement = elements.getElement(CardElement);

    const stripePayload = await stripe.createPaymentMethod({
      type: "card",
      // card: cardElement,
      card: elements.getElement(CardNumberElement),

      billing_details: {
        name,
        email,
      },
    });

    if (stripePayload.error) {
      setError(stripePayload.error);
      setPaymentLoading(false);
      return;
    }

    // const price = priceIds[firstSignUp ? "nonauth" : "auth"][plan][planVariant];

    const price =
      Products()[firstSignUp ? "withFreeTrial" : "withNoFreeTrial"][plan][
        planVariant
      ];

    const subsPayload = {
      email,
      price,
      plan,
      payment_method_id: stripePayload.paymentMethod.id,
    };

    try {
      const rawResponse = await fetch(`${apiURI}/api/subscribe`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(subsPayload),
      });

      if (firstSignUp) {
        const response = await fetchAuthUser();

        const cachedObject = localStorage.getItem("inteli_token");
        const authObject = JSON.parse(cachedObject);

        let cachtoken = {
          userId: authObject.userId,
          accessToken: authObject.accessToken,
          stripeId: response.stripe_id,
        };

        localStorage.setItem("inteli_token", JSON.stringify(cachtoken));
      }
    } catch (error) {
      //alert(error);
      console.log(error);
    }

    setPaymentLoading(false);

    if (user) onConfirmPayment();
    else onConfirmTrial();
  };

  return (
    <>
      {paymentConfirmedModalVis && (
        <Modal
          centered
          size="small"
          closeOnDimmerClick={false}
          open={paymentConfirmedModalVis}
          onOpen={() => setPaymentConfirmedVis(true)}
          onClose={() => setPaymentConfirmedVis(false)}
        >
          <div className={styles.paymentConfirmationModal}>
            <text className={styles.modalHeaderText}>
              Payment made. Thank you.
            </text>

            <BlueButton
              buttonText="Close"
              className={styles.blueButton}
              onClick={onConfirmPayment}
            />
          </div>
        </Modal>
      )}
      <form className={className} onSubmit={handleSubmit}>
        <>
          {/* <div style={{ marginBottom: "1.5rem" }}>
            <label className={styles.inputName}>Card Number</label>

            <CardElement
              className={styles.inputBox}
              options={options}
              hidePostalCode={true}
            />
            {error && (
              <div className={`text-red-400 py-1 px-2 italic`}>
                {error.message}
              </div>
            )}
          </div> */}

          <div
            className={`w-full flex flex-col justify-center items-center mb-8`}
          >
            <div className={`w-full flex justify-around`}>
              <label className={`${styles.cardInput} pr-5`}>
                <span className={styles.inputName}>
                  Card holder&apos;s name
                  <span className={styles.warning}>*</span>
                </span>
                <input
                  ref={nameEl}
                  className={`${styles.inputCCName} w-full border block antialiased py-4 px-4 rounded-md`}
                  onChange={(e) => setName(e.target.value)}
                />
                {fieldsChecked && isEmpty(name) && (
                  <div className={`p-2 ${styles.warning} font-thin`}>
                    {`Name is required.`}
                  </div>
                )}
              </label>
              <label className={`${styles.cardInput} pl-5`}>
                <span className={styles.inputName}>
                  Card number<span className={`text-red-400`}>*</span>
                </span>
                <CardNumberElement
                  className={`w-full border py-4 px-4 rounded-md`}
                  options={styleCardNumber}
                  onChange={(e) => setCnerror(e)}
                />
                {!isEmpty(cnerror) &&
                  fieldsChecked &&
                  !isEmpty(cnerror.error) && (
                    <div className={`p-2 text-red-400 font-thin`}>
                      {cnerror.error.message}
                    </div>
                  )}
                {!isEmpty(cnerror) &&
                  fieldsChecked &&
                  isEmpty(cnerror.error) &&
                  !cnerror.complete &&
                  cnerror.empty && (
                    <div className={`p-2 text-red-400 font-thin `}>
                      {`Credit card number is required.`}
                    </div>
                  )}
                {fieldsChecked && isEmpty(cnerror) && (
                  <div className={`p-2 text-red-400 font-thin `}>
                    {`Credit card number is required.`}
                  </div>
                )}
              </label>
            </div>
            <div className={`w-full flex justify-between mt-5`}>
              <label className={`${styles.cardInput} pr-5`}>
                <span className={styles.inputName}>
                  Expiry date<span className={`text-red-400`}>*</span>
                </span>
                <CardExpiryElement
                  className={`w-full border py-4 px-4 rounded-md`}
                  options={styleCardExpiry}
                  onChange={(e) => setExpiryerror(e)}
                />
                {!isEmpty(expiryerror) &&
                  fieldsChecked &&
                  !isEmpty(expiryerror.error) && (
                    <div className={`p-2 text-red-400 font-thin`}>
                      {expiryerror.error.message}
                    </div>
                  )}

                {!isEmpty(expiryerror) &&
                  fieldsChecked &&
                  isEmpty(expiryerror.error) &&
                  !expiryerror.complete &&
                  expiryerror.empty && (
                    <div className={`p-2 text-red-400 font-thin`}>
                      {`CC expiry date is required.`}
                    </div>
                  )}

                {fieldsChecked && isEmpty(expiryerror) && (
                  <div className={`p-2 text-red-400 font-thin`}>
                    {`CC expiry date is required.`}
                  </div>
                )}
              </label>
              <label className={`${styles.cardInput} pl-5`}>
                <span className={styles.inputName}>
                  Card code (CVC)<span className={`text-red-400`}>*</span>
                </span>
                <CardCvcElement
                  className={`w-full border py-4 px-4 rounded-md`}
                  options={styleCardCvc}
                  onChange={(e) => setCvcerror(e)}
                />
                {!isEmpty(cvcerror) &&
                  fieldsChecked &&
                  !isEmpty(cvcerror.error) && (
                    <div className={`p-2 text-red-400 font-thin`}>
                      {cvcerror.error.message}
                    </div>
                  )}

                {!isEmpty(cvcerror) &&
                  fieldsChecked &&
                  isEmpty(cvcerror.error) &&
                  !cvcerror.complete &&
                  cvcerror.empty && (
                    <div className={`p-2 text-red-400 font-thin`}>
                      {`CVC is required.`}
                    </div>
                  )}

                {fieldsChecked && isEmpty(cvcerror) && (
                  <div className={`p-2 text-red-400 font-thin`}>
                    {`CVC is required.`}
                  </div>
                )}
              </label>
            </div>
          </div>

          {/* {paymentLoading ? ( */}
          {/* <div */}
          {/* className={`w-full h-full flex justify-center items-center`} */}
          {/* style={{ marginBottom: "2.0rem" }} */}
          {/* > */}
          {/* <div className={styles.spinnerDiv}> */}
          {/* <img src={spinner} /> */}
          {/* </div> */}
          {/* ) : ( */}

          <button
            className={`${styles.payButton} `}
            type="submit"
            disabled={!stripe || paymentLoading}
          >
            {paymentLoading ? (
              <div className={`w-full flex justify-center items-center`}>
                <img src={spinner} className={`h-7 w-7`} />
                <span className={`block ml-3`}>Please wait...</span>
              </div>
            ) : (
              <span> Subscribe Now </span>
            )}
          </button>
          <span
            className={`${styles.disclaimerText}`}
          >{`We don't hold any of your credit card details. Your payments are handled securely via Stripe.`}</span>
          {/* {!isEmpty(error) && (
            <div
              className={`text-red-400 font-thin flex justify-center items-center mt-5 `}
            >
              <div className={`flex justify-center items-center`}>
                <FaTimesCircle />{" "}
                <span className={`ml-2`}>{error.message} </span>
              </div>
            </div>
          )} */}
          {/* )} */}
        </>
      </form>
    </>
  );
};

CardForm.propTypes = {
  className: PropTypes.string,
  plan: PropTypes.string,
  planVariant: PropTypes.string,
  stripeState: PropTypes.string,
  callback: PropTypes.func,
};

export default CardForm;
