import * as React from 'react';

import PropTypes from 'prop-types';
import { Modal } from 'semantic-ui-react';

import styles from './UserExistingModal.module.css';
import SkipButton from '../../SkipButton';

function UserExistingModal({ visibility, onOpenModal, onCloseModal }) {

  return (
    <Modal 
      size="small"
      centered={false}
      open={visibility}
      onOpen={onOpenModal}
      onClose={onCloseModal}
      closeOnDimmerClick={false}
    >
      <div className={styles.modalContainer}>

        <text className={styles.headerText}>
          <span className={styles.headerError}>Error:</span> This Email is already registered.
          <p>Please choose another one.</p>
        </text>

        <SkipButton
          buttonText="Ok"
          onClick={onCloseModal}
        />

      </div>

    </Modal>
  );
}

UserExistingModal.propTypes = {
  visibility: PropTypes.bool.isRequired,
  onOpenModal: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired,
};

export default UserExistingModal;
