import React from "react";
import styles from "./OperatingTimes.module.css";
import { FaAngleDown, FaAngleUp, FaSpinner } from "react-icons/fa";
import { TimePicker, Switch } from "antd";
import { useSpring, animated, config } from "react-spring";
import { useStoreActions, useStoreState } from "../../../hooks";
import moment from "moment";
import { isEmpty } from "lodash";

const { useState, useEffect } = React;

const initialHours = [
  {
    day: "monday",
    openTime: "06:00AM",
    closeTime: "07:00PM",
    working: true,
  },
  {
    day: "tuesday",
    openTime: "06:00AM",
    closeTime: "07:00PM",
    working: true,
  },
  {
    day: "wednesday",
    openTime: "06:00AM",
    closeTime: "07:00PM",
    working: true,
  },
  {
    day: "thursday",
    openTime: "06:00AM",
    closeTime: "07:00PM",
    working: true,
  },
  {
    day: "friday",
    openTime: "06:00AM",
    closeTime: "07:00PM",
    working: true,
  },
  {
    day: "saturday",
    openTime: "06:00AM",
    closeTime: "07:00PM",
    working: false,
  },
  {
    day: "sunday",
    openTime: "06:00AM",
    closeTime: "07:00PM",
    working: false,
  },
];

const initialOutsideSchoolHours = [
  {
    day: "monday",
    session1Start: "06:00AM",
    session1Finish: "07:00AM",
    session2Start: "06:00AM",
    session2Finish: "07:00AM",
  },
  {
    day: "tuesday",
    session1Start: "06:00AM",
    session1Finish: "07:00AM",
    session2Start: "06:00AM",
    session2Finish: "07:00AM",
  },
  {
    day: "wednesday",
    session1Start: "06:00AM",
    session1Finish: "07:00AM",
    session2Start: "06:00AM",
    session2Finish: "07:00AM",
  },
  {
    day: "thursday",
    session1Start: "06:00AM",
    session1Finish: "07:00AM",
    session2Start: "06:00AM",
    session2Finish: "07:00AM",
  },
  {
    day: "friday",
    session1Start: "06:00AM",
    session1Finish: "07:00AM",
    session2Start: "06:00AM",
    session2Finish: "07:00AM",
  },
  {
    day: "saturday",
    session1Start: "06:00AM",
    session1Finish: "07:00AM",
    session2Start: "06:00AM",
    session2Finish: "07:00AM",
  },
  {
    day: "sunday",
    session1Start: "06:00AM",
    session1Finish: "07:00AM",
    session2Start: "06:00AM",
    session2Finish: "07:00AM",
  },
];

const initialVacationCareHours = [
  {
    day: "monday",
    openTime: "06:00AM",
    closeTime: "07:00PM",
    open24Hours: false,
  },
  {
    day: "tuesday",
    openTime: "06:00AM",
    closeTime: "07:00PM",
    open24Hours: false,
  },
  {
    day: "wednesday",
    openTime: "06:00AM",
    closeTime: "07:00PM",
    open24Hours: false,
  },
  {
    day: "thursday",
    openTime: "06:00AM",
    closeTime: "07:00PM",
    open24Hours: false,
  },
  {
    day: "friday",
    openTime: "06:00AM",
    closeTime: "07:00PM",
    open24Hours: false,
  },
  {
    day: "saturday",
    openTime: "06:00AM",
    closeTime: "07:00PM",
    open24Hours: true,
  },
  {
    day: "sunday",
    openTime: "06:00AM",
    closeTime: "07:00PM",
    open24Hours: true,
  },
];

const days = [
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday",
];

const OperatingTimes = () => {
  const [saving, setSaving] = useState(false);

  const authObject = useStoreState((state) => state.authStore.authObject);
  const updateServiceDetails = useStoreActions(
    (actions) => actions.nqaStore.updateServiceDetails
  );

  const serviceDetails = useStoreState(
    (state) => state.nqaStore.serviceDetails
  );

  const [detailsState, setDetailsState] = useState({});

  const [serviceTable, setServiceTable] = useState(true);
  const [careTable, setCareTable] = useState(false);
  const [vacayTable, setVacayTable] = useState(false);

  const serviceSpring = useSpring({
    opacity: serviceTable ? 1 : 0,
    display: serviceTable ? "block" : "none",
  });
  const careSpring = useSpring({
    opacity: careTable ? 1 : 0,
    display: careTable ? "block" : "none",
  });

  const vacaySpring = useSpring({
    opacity: vacayTable ? 1 : 0,
    display: vacayTable ? "block" : "none",
  });

  const onUpdateServiceDetails = async () => {
    try {
      setSaving(true);
      const payload = {
        serviceDetails: {
          ...detailsState,
          userId: authObject.userId,
        },
        centerId: detailsState.id,
      };

      await updateServiceDetails(payload);
      setDetailsState({ ...serviceDetails });
      setSaving(false);
    } catch (error) {
      setSaving(false);
      console.log(error);
    }
  };

  const onSelectTime = (day, timeField, value) => {
    const operatingHours = detailsState.operatingHours
      ? [...detailsState.operatingHours]
      : [...initialHours];
    operatingHours.find((item) => item.day === day)[timeField] = value;

    setDetailsState({ ...detailsState, operatingHours });
  };

  const onSelectTimeOutsideSchoolHours = (day, timeField, value) => {
    const outsideSchoolHours = detailsState.outsideSchoolHours
      ? [...detailsState.outsideSchoolHours]
      : [...initialOutsideSchoolHours];
    outsideSchoolHours.find((item) => item.day === day)[timeField] = value;

    setDetailsState({ ...detailsState, outsideSchoolHours });
  };

  const onSelectTimeVacationCareHours = (day, timeField, value) => {
    const vacationCareHours = detailsState.vacationCareHours
      ? [...detailsState.vacationCareHours]
      : [...initialVacationCareHours];
    vacationCareHours.find((item) => item.day === day)[timeField] = value;

    setDetailsState({ ...detailsState, vacationCareHours });
  };

  const onToggle = (day, value) => {
    const operatingHours = detailsState.operatingHours
      ? [...detailsState.operatingHours]
      : [...initialHours];
    operatingHours.find((item) => item.day === day).working = !value;

    setDetailsState({ ...detailsState, operatingHours });
  };

  const onToggleVacationCareHours = (day, value) => {
    const vacationCareHours = detailsState.vacationCareHours
      ? [...detailsState.vacationCareHours]
      : [...initialVacationCareHours];
    vacationCareHours.find((item) => item.day === day).open24Hours = !value;

    setDetailsState({ ...detailsState, vacationCareHours });
  };

  useEffect(() => {
    let serviceDetailState = { ...serviceDetails };
    if (!serviceDetails.operatingHours) {
      serviceDetailState = {
        ...serviceDetailState,
        operatingHours: initialHours,
      };
    }
    if (!serviceDetails.vacationCareHours) {
      serviceDetailState = {
        ...serviceDetailState,
        vacationCareHours: initialVacationCareHours,
      };
    }

    if (!serviceDetails.outsideSchoolHours) {
      serviceDetailState = {
        ...serviceDetailState,
        outsideSchoolHours: initialOutsideSchoolHours,
      };
    }

    setDetailsState({ ...serviceDetailState });
  }, []);

  return (
    <div
      className={`${styles.mainContainer} w-full md:px-8 lg:px-24 mt-14 pb-10`}
    >
      <div className={`${styles.serviceOperatingTimes}`}>
        <div
          className={`${styles.headerContainer} pl-8 pr-5 py-3 flex justify-between items-center`}
        >
          <text className={`${styles.headerText} w-4/5`}>
            Service Operating Times
          </text>
          <div
            onClick={() => {
              setServiceTable(!serviceTable);
            }}
            style={{
              background: serviceTable ? "#8BC334" : "#ffffff",
              color: serviceTable ? "#ffffff" : "#222222",
              border: !serviceTable ? "1px solid #E4E4E4" : "none",
            }}
            className={`${styles.toggleIcon} w-1/5 cursor-pointer flex justify-center items-center rounded-full overflow-hidden`}
          >
            {serviceTable ? <FaAngleUp /> : <FaAngleDown />}
          </div>
        </div>
        <animated.div
          style={serviceSpring}
          className={`px-8 pt-8 ${styles.tableContainer}`}
        >
          <table className={`${styles.tableOperatingTimes}  w-full`}>
            <thead>
              <tr>
                <th>Day</th>
                <th>Opening</th>
                <th>Closing</th>
                <th>Open 24 hours</th>
              </tr>
            </thead>
            {console.log("operating hours ", detailsState)}
            <tbody>
              {days.map((day) => {
                return (
                  <tr>
                    <td className={`${styles.dayName} py-3 `}>
                      <span>
                        {day}
                        <span className={`text-red-400`}>*</span>
                      </span>
                    </td>
                    <td className={`timePicker`}>
                      <TimePicker
                        allowClear={false}
                        use12Hours
                        disabled={
                          !isEmpty(detailsState.operatingHours)
                            ? !detailsState.operatingHours.find(
                                (item) => item.day === day
                              ).working
                            : !initialHours.find((item) => item.day === day)
                                .working
                        }
                        showNow={false}
                        value={
                          !isEmpty(detailsState.operatingHours)
                            ? moment(
                                detailsState.operatingHours.find(
                                  (item) => item.day === day
                                ).openTime,
                                "HH:mm A"
                              )
                            : moment(
                                initialHours.find((item) => item.day === day)
                                  .openTime,
                                "HH:mm A"
                              )
                        }
                        format="h:mm A"
                        className={`${styles.timePicker} py-3`}
                        onChange={(time, timeString) => {
                          onSelectTime(day, "openTime", timeString);
                        }}
                      />
                    </td>
                    <td className={`timePicker`}>
                      <TimePicker
                        className={`${styles.timePicker} py-3`}
                        allowClear={false}
                        use12Hours
                        disabled={
                          !isEmpty(detailsState.operatingHours)
                            ? !detailsState.operatingHours.find(
                                (item) => item.day === day
                              ).working
                            : !initialHours.find((item) => item.day === day)
                                .working
                        }
                        showNow={false}
                        value={
                          !isEmpty(detailsState.operatingHours)
                            ? moment(
                                detailsState.operatingHours.find(
                                  (item) => item.day === day
                                ).closeTime,
                                "HH:mm A"
                              )
                            : moment(
                                initialHours.find((item) => item.day === day)
                                  .closeTime,
                                "HH:mm A"
                              )
                        }
                        format="h:mm A"
                        onChange={(time, timeString) => {
                          onSelectTime(day, "closeTime", timeString);
                        }}
                      />
                    </td>
                    <td className={`switch-op-times py-3`}>
                      <Switch
                        className={`${styles.switch}`}
                        checked={
                          !isEmpty(detailsState.operatingHours)
                            ? !detailsState.operatingHours.find(
                                (item) => item.day === day
                              ).working
                            : !initialHours.find((item) => item.day === day)
                                .working
                        }
                        onChange={(checked) => onToggle(day, checked)}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </animated.div>
      </div>

      <div className={`${styles.serviceOperatingTimes} mt-10`}>
        <div
          className={`${styles.headerContainer} pl-8 pr-5 py-3 flex justify-between items-center`}
        >
          <text className={`${styles.headerText} w-4/5`}>
            Outside School Hours Care Operating Times
          </text>
          <div
            onClick={() => {
              setCareTable(!careTable);
            }}
            style={{
              background: careTable ? "#8BC334" : "#ffffff",
              color: careTable ? "#ffffff" : "#222222",
              border: !careTable ? "1px solid #E4E4E4" : "none",
            }}
            className={`${styles.toggleIcon} w-1/2 cursor-pointer flex justify-center items-center rounded-full`}
          >
            {careTable ? <FaAngleUp /> : <FaAngleDown />}
          </div>
        </div>
        <animated.div
          style={careSpring}
          className={`px-8 pt-8 ${styles.tableContainer}`}
        >
          <table className={`${styles.tableCareOperatingTimes}  w-full`}>
            <thead>
              <tr>
                <th>Day</th>
                <th>Session 1 Start</th>
                <th>Session 1 Finish</th>
                <th>Session 2 Start</th>
                <th>Session 2 Finish</th>
              </tr>
            </thead>
            <tbody>
              {days.map((day) => {
                return (
                  <tr>
                    <td className={`${styles.dayName} py-3 `}>
                      <span>
                        {day}
                        <span className={`text-red-400`}>*</span>
                      </span>
                    </td>
                    <td className={`timePicker`}>
                      <TimePicker
                        className={`${styles.timePicker1} py-3`}
                        allowClear={false}
                        use12Hours
                        showNow={false}
                        value={
                          !isEmpty(detailsState.outsideSchoolHours)
                            ? moment(
                                detailsState.outsideSchoolHours.find(
                                  (item) => item.day === day
                                ).session1Start,
                                "HH:mm A"
                              )
                            : moment(
                                initialOutsideSchoolHours.find(
                                  (item) => item.day === day
                                ).session1Start,
                                "HH:mm A"
                              )
                        }
                        format="h:mm A"
                        onChange={(time, timeString) => {
                          onSelectTimeOutsideSchoolHours(
                            day,
                            "session1Start",
                            timeString
                          );
                        }}
                      />
                    </td>
                    <td className={`timePicker`}>
                      <TimePicker
                        className={`${styles.timePicker1} py-3`}
                        allowClear={false}
                        use12Hours
                        showNow={false}
                        value={
                          !isEmpty(detailsState.outsideSchoolHours)
                            ? moment(
                                detailsState.outsideSchoolHours.find(
                                  (item) => item.day === day
                                ).session1Finish,
                                "HH:mm A"
                              )
                            : moment(
                                initialOutsideSchoolHours.find(
                                  (item) => item.day === day
                                ).session1Finish,
                                "HH:mm A"
                              )
                        }
                        format="h:mm A"
                        onChange={(time, timeString) => {
                          onSelectTimeOutsideSchoolHours(
                            day,
                            "session1Finish",
                            timeString
                          );
                        }}
                      />
                    </td>
                    <td className={`timePicker`}>
                      <TimePicker
                        className={`${styles.timePicker1} py-3`}
                        allowClear={false}
                        use12Hours
                        showNow={false}
                        value={
                          !isEmpty(detailsState.outsideSchoolHours)
                            ? moment(
                                detailsState.outsideSchoolHours.find(
                                  (item) => item.day === day
                                ).session2Start,
                                "HH:mm A"
                              )
                            : moment(
                                initialOutsideSchoolHours.find(
                                  (item) => item.day === day
                                ).session2Start,
                                "HH:mm A"
                              )
                        }
                        format="h:mm A"
                        onChange={(time, timeString) => {
                          onSelectTimeOutsideSchoolHours(
                            day,
                            "session2Start",
                            timeString
                          );
                        }}
                      />
                    </td>
                    <td className={`timePicker`}>
                      <TimePicker
                        className={`${styles.timePicker1} py-3`}
                        allowClear={false}
                        use12Hours
                        showNow={false}
                        value={
                          !isEmpty(detailsState.outsideSchoolHours)
                            ? moment(
                                detailsState.outsideSchoolHours.find(
                                  (item) => item.day === day
                                ).session2Finish,
                                "HH:mm A"
                              )
                            : moment(
                                initialOutsideSchoolHours.find(
                                  (item) => item.day === day
                                ).session2Finish,
                                "HH:mm A"
                              )
                        }
                        format="h:mm A"
                        onChange={(time, timeString) => {
                          onSelectTimeOutsideSchoolHours(
                            day,
                            "session2Finish",
                            timeString
                          );
                        }}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </animated.div>
      </div>

      <div className={`${styles.serviceOperatingTimes} mt-10`}>
        <div
          className={`${styles.headerContainer} pl-8 pr-5 py-3 flex justify-between items-center`}
        >
          <text className={`${styles.headerText}`}>
            Vacation Care Operating Times
          </text>
          <div
            onClick={() => {
              setVacayTable(!vacayTable);
            }}
            style={{
              background: vacayTable ? "#8BC334" : "#ffffff",
              color: vacayTable ? "#ffffff" : "#222222",
              border: !vacayTable ? "1px solid #E4E4E4" : "none",
            }}
            className={`${styles.toggleIcon} cursor-pointer flex justify-center items-center rounded-full`}
          >
            {vacayTable ? <FaAngleUp /> : <FaAngleDown />}
          </div>
        </div>
        <animated.div
          style={vacaySpring}
          className={`px-8 pt-8 ${styles.tableContainer}`}
        >
          <table className={`${styles.tableOperatingTimes}  w-full`}>
            <thead>
              <tr>
                <th>Day</th>
                <th>Opening</th>
                <th>Closing</th>
                <th>Open 24 hours</th>
              </tr>
            </thead>
            <tbody>
              {days.map((day) => {
                return (
                  <tr>
                    <td className={`${styles.dayName} py-3 `}>
                      <span>
                        {day}
                        <span className={`text-red-400`}>*</span>
                      </span>
                    </td>
                    <td className={`timePicker`}>
                      <TimePicker
                        allowClear={false}
                        use12Hours
                        disabled={
                          !isEmpty(detailsState.vacationCareHours)
                            ? !detailsState.vacationCareHours.find(
                                (item) => item.day === day
                              ).open24Hours
                            : !initialVacationCareHours.find(
                                (item) => item.day === day
                              ).open24Hours
                        }
                        showNow={false}
                        value={
                          !isEmpty(detailsState.vacationCareHours)
                            ? moment(
                                detailsState.vacationCareHours.find(
                                  (item) => item.day === day
                                ).openTime,
                                "HH:mm A"
                              )
                            : moment(
                                initialVacationCareHours.find(
                                  (item) => item.day === day
                                ).openTime,
                                "HH:mm A"
                              )
                        }
                        format="h:mm A"
                        className={`${styles.timePicker} py-3`}
                        onChange={(time, timeString) => {
                          onSelectTimeVacationCareHours(
                            day,
                            "openTime",
                            timeString
                          );
                        }}
                      />
                    </td>
                    <td className={`timePicker`}>
                      <TimePicker
                        allowClear={false}
                        use12Hours
                        disabled={
                          !isEmpty(detailsState.vacationCareHours)
                            ? !detailsState.vacationCareHours.find(
                                (item) => item.day === day
                              ).open24Hours
                            : !initialVacationCareHours.find(
                                (item) => item.day === day
                              ).open24Hours
                        }
                        showNow={false}
                        value={
                          !isEmpty(detailsState.vacationCareHours)
                            ? moment(
                                detailsState.vacationCareHours.find(
                                  (item) => item.day === day
                                ).closeTime,
                                "HH:mm A"
                              )
                            : moment(
                                initialVacationCareHours.find(
                                  (item) => item.day === day
                                ).closeTime,
                                "HH:mm A"
                              )
                        }
                        format="h:mm A"
                        className={`${styles.timePicker} py-3`}
                        onChange={(time, timeString) => {
                          onSelectTimeVacationCareHours(
                            day,
                            "closeTime",
                            timeString
                          );
                        }}
                      />
                    </td>
                    <td className={`switch-op-times py-3`}>
                      <Switch
                        className={`${styles.switch}`}
                        checked={
                          !isEmpty(detailsState.vacationCareHours)
                            ? !detailsState.vacationCareHours.find(
                                (item) => item.day === day
                              ).open24Hours
                            : !initialVacationCareHours.find(
                                (item) => item.day === day
                              ).open24Hours
                        }
                        onChange={(checked) =>
                          onToggleVacationCareHours(day, checked)
                        }
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </animated.div>
      </div>

      <div className={`flex justify-center items-center mt-14 mb-8`}>
        <button
          onClick={onUpdateServiceDetails}
          disabled={saving}
          className={`${styles.saveButton}`}
        >
          {saving ? (
            <div className={`flex justify-center items-center`}>
              <FaSpinner className={`animate-spin`} />{" "}
              <span className={`ml-3`}>Please wait...</span>
            </div>
          ) : (
            `Save service hours`
          )}
        </button>
      </div>
    </div>
  );
};

export default OperatingTimes;
