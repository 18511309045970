import * as React from "react";

import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { Button, Modal, Icon } from "semantic-ui-react";

import styles from "./ChangeServiceModal.module.css";
import BlueButton from "../../BlueButton";
import { useStoreActions, useStoreState } from "../../../hooks";
import { getPaymentMethods } from "../../../api/requests";
import { FaCheck, FaTimes, FaDollarSign } from "react-icons/fa";
import ConfirmRedirectModal from "../ConfirmRedirectModal";

const { useEffect, useState, useCallback } = React;

function ChangeServiceModal({
  visibility,
  onOpenModal,
  onCloseModal,
  onCloseChangeService,
}) {
  const history = useHistory();

  const isAdmin = useStoreState((state) => state.authStore.isAdmin);

  const services = useStoreState((state) => state.authStore.services);

  const hubURL = process.env.REACT_APP_HUB_URL;

  const [openRedirectModal, setOpenRedirectModal] = useState(false);

  const [redirectMessage, setRedirectMessage] = useState("");

  const [redirectPathUrl, setRedirectPathUrl] = useState("");

  const setDisableNavBar = useStoreActions(
    (actions) => actions.appStore.setDisableMenu
  );
  const hasPaymentMethods = useStoreState(
    (state) => state.userStore.hasPaymentMethods
  );
  const paymentMethods = useStoreState(
    (state) => state.userStore.paymentMethods
  );

  const selectedService = useStoreState(
    (state) => state.authStore.selectedService
  );

  const setSelectedService = useStoreActions(
    (actions) => actions.authStore.setSelectedService
  );

  const onAllServicesClick = () => {
    history.push("/qip/user-management/services");
    onCloseModal();
  };

  const onAddNewServiceClick = () => {
    history.push("/qip/user-management/services/upgrade");
    onCloseModal();
  };

  const onRedirect = () => {
    window.open(`${hubURL}${redirectPathUrl}`);
    setOpenRedirectModal(false);
    onCloseModal();
  };

  return (
    <Modal
      size="small"
      centered={false}
      open={visibility}
      onOpen={onOpenModal}
      onClose={onCloseModal}
    >
      <div className={styles.modalContainer}>
        <Button
          className={styles.closeButton}
          onClick={onCloseModal}
          size="big"
          icon="close"
        />
        {openRedirectModal && (
          <ConfirmRedirectModal
            message={redirectMessage}
            buttonText={`Go to Holistic HUB`}
            visibility={openRedirectModal}
            onCloseModal={() => setOpenRedirectModal(false)}
            onConfirm={onRedirect}
          />
        )}

        <text className={styles.headerText}>Change service</text>
        {console.log("services change ", services)}
        {services &&
          services.map((service) => {
            return service.status === "active" ? (
              <button
                key={service.id}
                className={
                  service.id === selectedService.id
                    ? styles.serviceSelectedButtonContainer
                    : styles.serviceButtonContainer
                }
                onClick={() => {
                  if (service.id !== selectedService.id) {
                    localStorage.setItem("selectedService", service.id);
                    setSelectedService(service);
                    setDisableNavBar(false);
                    window.location = "/qip/nqa";
                  }
                }}
              >
                <div className={`flex justify-start items-center`}>
                  <div
                    className={`${
                      service.id === selectedService.id
                        ? styles.serviceCheck
                        : styles.serviceNotCheck
                    } flex justify-center items-center`}
                  >
                    <FaCheck />
                  </div>
                  <span className={`ml-4`}> {service.name}</span>
                </div>
              </button>
            ) : service.status === "overdue" ? (
              <div
                key={service.id}
                className={`${styles.lapsedButton} flex justify-between items-center`}
              >
                <div className={`flex justify-start items-center`}>
                  <div
                    className={`${styles.lapsedIcon} flex justify-center items-center`}
                  >
                    <FaTimes />
                  </div>
                  <span className={`ml-4`}>{`${
                    service.name
                  } ${"(Payment failed)"} `}</span>
                </div>

                <button
                  className={`${styles.subscribeButton} flex justify-center items-center ml-3`}
                  onClick={() => {
                    setRedirectMessage(
                      "You will be redirected to Holistic HUB to finish Subscribing"
                    );
                    setRedirectPathUrl(`payment/failed`);

                    setOpenRedirectModal(true);
                  }}
                  // onClick={() => {
                  //   window.open(`${hubURL}subscription/payment-details`);

                  //   onCloseModal();
                  //   // window.location = "/qip/nqa";
                  // }}
                >
                  {/* <FaDollarSign /> */}
                  <span className={`ml-3`}>Payment&nbsp;Overdue</span>
                </button>
              </div>
            ) : service.status === "notoverdue" ? (
              <div
                key={service.id}
                className={`${styles.lapsedButton} flex justify-between items-center`}
              >
                <div className={`flex justify-start items-center`}>
                  <div
                    className={`${styles.lapsedIcon} flex justify-center items-center`}
                  >
                    <FaTimes />
                  </div>
                  <span className={`ml-4`}>{`${
                    service.name
                  } ${"(Payment failed)"} `}</span>
                </div>

                <button
                  className={`${styles.subscribeButton} flex justify-center items-center ml-3`}
                  onClick={() => {
                    if (service.id !== selectedService.id) {
                      localStorage.setItem("selectedService", service.id);
                      setSelectedService(service);
                      setDisableNavBar(false);
                      window.location = "/qip/nqa";
                    }
                  }}
                >
                  {/* <FaDollarSign /> */}
                  <span className={`ml-3`}>Payment&nbsp;Failed</span>
                </button>
              </div>
            ) : service.status === "resubscribe" ? (
              <div
                key={service.id}
                className={`${styles.lapsedButton} flex justify-between items-center`}
              >
                <div className={`flex justify-start items-center`}>
                  <div
                    className={`${styles.lapsedIcon} flex justify-center items-center`}
                  >
                    <FaTimes />
                  </div>
                  <span className={`ml-4`}>{`${
                    service.name
                  } ${"Cancelled"} `}</span>
                </div>

                <button
                  className={`${styles.subscribeButton} flex justify-center items-center ml-3`}
                  onClick={() => {
                    setRedirectMessage(
                      "You will be redirected to Holistic HUB to finish Resubscribing"
                    );
                    setRedirectPathUrl(`resubscribe`);

                    setOpenRedirectModal(true);
                    // window.location = "/qip/nqa";
                  }}
                >
                  <FaDollarSign />
                  <span className={`ml-3`}>Resubscribe</span>
                </button>
              </div>
            ) : service.status === "subscribe" ? (
              <div
                key={service.id}
                className={`${styles.lapsedButton} flex justify-between items-center`}
              >
                <div className={`flex justify-start items-center`}>
                  <div
                    className={`${styles.lapsedIcon} flex justify-center items-center`}
                  >
                    <FaTimes />
                  </div>
                  <span className={`ml-4`}>{`${
                    service.name
                  } ${"Trial Ended"} `}</span>
                </div>

                <button
                  className={`${styles.subscribeButton} flex justify-center items-center ml-3`}
                  onClick={() => {
                    setRedirectMessage(
                      "You will be redirected to Holistic HUB to finish Subscribing"
                    );
                    setRedirectPathUrl(`resubscribe`);

                    setOpenRedirectModal(true);
                  }}
                >
                  <FaDollarSign />
                  <span className={`ml-3`}>Subscribe</span>
                </button>
              </div>
            ) : (
              <> </>
            );
          })}

        <div className={styles.displayAddServiceButtonGroup}>
          <BlueButton
            className={styles.displayAllServiceButton}
            width="100%"
            height="60px"
            buttonText="All services"
            onClick={onAllServicesClick}
          />

          {isAdmin && (
            <BlueButton
              className={styles.displayAddServiceButton}
              width="100%"
              height="60px"
              buttonText="Add new service"
              onClick={() => {
                setRedirectMessage(
                  "You will redirected to Holistic HUB to add a new service"
                );
                setRedirectPathUrl(`plans/app_redirect-new_service`);

                setOpenRedirectModal(true);
              }}
            />
          )}
        </div>
      </div>
    </Modal>
  );
}

ChangeServiceModal.propTypes = {
  visibility: PropTypes.bool.isRequired,
  onOpenModal: PropTypes.func.isRequired,
  onCloseChangeService: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired,
};

export default ChangeServiceModal;
