import * as React from "react";

import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";

import { useStoreState, useStoreActions } from "../../hooks";
import NQAView from "../../pages/NQA/NQAView";
import ServiceDetails from "../ServiceDetails";
import NQADashboard from "../../pages/NQA/NQADashboard";
import MentorFeedbackView from "../../pages/NQA/MentorFeedbackView";

export default function NQA() {
  const match = useRouteMatch();
  const serviceDetails = useStoreState(
    (state) => state.nqaStore.serviceDetails
  );

  const remainingTrial = useStoreState(
    (state) => state.userStore.remainingTrial
  );

  const setDisableNavBar = useStoreActions(
    (actions) => actions.appStore.setDisableMenu
  );

  const userSelectedService = useStoreState(
    (state) => state.authStore.selectedService
  );

  if (userSelectedService && userSelectedService.redirectPaymentFailed) {
    setDisableNavBar(true);
    return <Redirect to={`/qip/update-payment-method`} />;
  }

  if (remainingTrial <= 0) {
    return <Redirect to={`/qip/pricing-plans`} />;
  }

  return (
    <Switch>
      <Route exact path={`${match.path}`} component={NQADashboard} />
      <Route
        path={`${match.path}/service-details`}
        render={() =>
          serviceDetails ? (
            <ServiceDetails />
          ) : (
            <Redirect to={`${match.path}`} />
          )
        }
      />
      <Route
        exact
        path={`${match.path}/mentor-feedback`}
        render={(props) => <MentorFeedbackView {...props} />}
        // component={MentorFeedbackView}
      />
      <Route
        exact
        path={`${match.path}/:nqaId`}
        render={() =>
          serviceDetails ? <NQAView /> : <Redirect to={`${match.path}`} />
        }
      />
    </Switch>
  );
}
