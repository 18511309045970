import * as React from 'react';

import PropTypes from 'prop-types';
import { Button, Modal } from 'semantic-ui-react';

import styles from './ServiceRemovedModal.module.css';
import BlueButton from '../../BlueButton';

function ServiceRemovedModal({ visibility, onOpenModal, onCloseModal }) {

  return (
    <Modal
      size="small"
      centered={false} 
      open={visibility}
      onOpen={onOpenModal}
      onClose={onCloseModal}
    >
      <div className={styles.modalContainer}>

        <Button
          className={styles.closeButton}
          onClick={onCloseModal}
          size="big"
          icon="close"
        />

        <text className={styles.descriptionText}>
          Your subscription for this service will remain active until the end of your subscription period.
          We would recommend you download your latest QIP reports before your subscription ends.
        </text>

        <div>

          <BlueButton
            className={styles.blueButton}
            buttonText="Close"
            onClick={onCloseModal}
          />

          <button
            className={styles.getReportsButton}
            onClick={onCloseModal}
          >
            Get reports
          </button>

        </div>

      </div>
    </Modal>
  );
}

ServiceRemovedModal.propTypes = {
  visibility: PropTypes.bool.isRequired,
  onOpenModal: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  onGetReports: PropTypes.func.isRequired,
};

export default ServiceRemovedModal;
