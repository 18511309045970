// import lib
import * as React from 'react';
const { useContext, useEffect, useState } = React;
import PropTypes from 'prop-types';
import { Icon } from 'semantic-ui-react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { FaSpinner } from 'react-icons/fa';

// import components
import { useStoreState } from '../../../hooks';
import styles from './PeopleResponsible.module.css';
import BlueButton from '../../../components/BlueButton';
import { ServiceSteps } from '../../../components/Steps';
import { ServiceDetailsContext } from '../../../context/ServiceDetailsContext';

// People responsible component
function PeopleResponsible({ onNextStep, onSaveStep, onSkipServiceDetails }) {
	const [fieldChecked, setFieldChecked] = useState(false);
	const [errors, setErrors] = useState([]);
	const history = useHistory();
	const match = useRouteMatch();
	const isLoggedIn = useStoreState(state => state.authStore.isLoggedIn);

	const { saveLoading, serviceDetailsState, changeInputDispatch } = useContext(
		ServiceDetailsContext
	);

	const [initialEmployees, setInitialEmployees] = useState(
		serviceDetailsState.input.employees != undefined
			? serviceDetailsState.input.employees.length > 0
				? serviceDetailsState.input.employees
				: []
			: []
	);
	const [employees, setEmployees] = useState(
		serviceDetailsState.input.employees
			? serviceDetailsState.input.employees.length
			: 0
	);

	// set employees details on change
	const setEmployeeDetails = (index, fieldInput, value) => {
		setErrors([]);
		const currentEmployees = serviceDetailsState.input.employees
			? [...serviceDetailsState.input.employees]
			: [
					{
						id: index,
						name: '',
						position: '',
					},
			  ];

		if (currentEmployees.find(employee => employee.id === index)) {
			currentEmployees[
				currentEmployees.findIndex(employee => employee.id === index)
			][fieldInput] = value;
		} else {
			const employeeDetail = {
				id: index,
				name: '',
				position: '',
			};

			employeeDetail[fieldInput] = value;
			currentEmployees.push(employeeDetail);
		}

		changeInputDispatch('employees', currentEmployees);
	};

	// check if all fields are filled in and set errors
	const EmployeesValidation = () => {
		let tempErrors = [];

		if (employees <= 0) return tempErrors;

		Array.from({ length: employees }, (v, index) => {
			let message = '';
			let field = 'all';
			const filterItems = serviceDetailsState?.input?.employees.filter(
				item => item.id === index + 1
			);

			if (serviceDetailsState.input.employees && filterItems.length === 0) {
				field = 'all';
				message = 'This field is required.';
			} else {
				if (!filterItems[0].name) {
					field = 'name';
					message = 'This field is required.';
				}

				if (!filterItems[0].position) {
					field = 'position';
					message = 'This field is required.';
				}
			}

			if (message.length > 0) {
				if (!tempErrors.some(error => error.index === index)) {
					const tempError = {
						index: index,
						field: field,
						message: message,
					};
					tempErrors = [...tempErrors, tempError];
				}
			}
		});

		setErrors([...tempErrors]);

		return tempErrors.length <= 0;
	};

	const onSave = async () => {
		setFieldChecked(true);

		if (!(employees > 0 && errors.length <= 0)) {
			return;
		}

		if (!EmployeesValidation()) {
			return;
		}

		await onSaveStep();

		onNextStep();
		history.push(
			`${match.path
				.split('/')
				.slice(0, match.path.split('/').length - 1)
				.join('/')}/11`
		);
	};

	useEffect(() => {
		if (employees < 0) setEmployees(0);
	}, [employees]);

	const setAdditionalInputValue = (key, itemName) => {
		if (!key) return '';

		if (serviceDetailsState?.input?.employees.length > 0) {
			const filterItems = serviceDetailsState?.input?.employees.filter(
				item => item.id === key
			);

			return filterItems.length > 0 ? filterItems[0][itemName] : '';
		}

		return '';
	};

	return (
		<div className={styles.mainContainer}>
			{isLoggedIn && (
				<>
					<div className={styles.headerContainer}>
						<h1>Complete your service details</h1>
						<p>
							Please fill out all missing fields marked with an ‘
							<span className={styles.required}>*</span>’
						</p>
					</div>
				</>
			)}
			<div className={styles.mainCard}>
				<div className={styles.cardContent}>
					<h1 className={styles.headerText}>
						Name and position of person(s) responsible for submitting this
						Quality Improvement Plan (QIP)
					</h1>

					<text className={styles.pleaseComplete}>
						Please complete the below fields.
					</text>

					<text className={styles.descriptionText}>
						How many employees will work on your QIP?
					</text>

					<input
						className={styles.workforceVal}
						type="number"
						value={employees}
						onChange={e => {
							setErrors([]);
							if (!isNaN(e.target.value)) {
								changeInputDispatch(
									'employees',
									initialEmployees.slice(0, Math.abs(parseInt(e.target.value)))
								);
							}
							setEmployees(Math.abs(parseInt(e.target.value)));
						}}
					/>
					{!(employees > 0) && fieldChecked && (
						<div className={`p-2 text-red-400 font-thin`}>
							Please provide number of employees that will work on your QIP.
						</div>
					)}
					<div>
						{employees > 0
							? Array.from({ length: employees }, (v, index) => (
									<div className={styles.educatorEntry} key={index}>
										<div>
											<text className={styles.inputFieldHeader}>
												Full Name <span className={styles.required}>*</span>
											</text>

											<input
												className={styles.inputBox}
												type="text"
												value={setAdditionalInputValue(index + 1, 'name')}
												onChange={e =>
													setEmployeeDetails(index + 1, 'name', e.target.value)
												}
											/>
											<span className={`p-1 text-red-400 font-thin`}>
												{errors.map(error => {
													if (error.index === index) {
														if (
															error.field === 'all' ||
															error.field === 'name'
														) {
															return error.message;
														}
													}
												})}
											</span>
										</div>

										<div>
											<text className={styles.inputFieldHeader}>
												Position<span className={styles.required}>*</span>
											</text>

											<input
												className={styles.inputBox}
												type="text"
												value={setAdditionalInputValue(index + 1, 'position')}
												onChange={e =>
													setEmployeeDetails(
														index + 1,
														'position',
														e.target.value
													)
												}
											/>
											<span className={`p-1 text-red-400 font-thin`}>
												{errors.map(error => {
													if (error.index === index) {
														if (
															error.field === 'all' ||
															error.field === 'position'
														) {
															return error.message;
														}
													}
												})}
											</span>
										</div>
									</div>
							  ))
							: null}
					</div>

					<div className={styles.buttonHolders}>
						{/* // buttonText="Save step" */}
						<BlueButton
							buttonText={
								saveLoading ? (
									<div className={`flex justify-center items-center`}>
										<FaSpinner className={`animate-spin`} />
										<span className={`block pl-2`}>Save step</span>
									</div>
								) : (
									<> Save step</>
								)
							}
							onClick={onSave}
						/>

						<button
							className={styles.buttonNextStep}
							onClick={() =>
								history.push(
									`${match.path
										.split('/')
										.slice(0, match.path.split('/').length - 1)
										.join('/')}/11`
								)
							}
						>
							Next step
						</button>
					</div>

					<div className={styles.buttonStep}>
						<button
							className={styles.buttonStepBack}
							onClick={() =>
								history.push(
									`${match.path
										.split('/')
										.slice(0, match.path.split('/').length - 1)
										.join('/')}/9`
								)
							}
						>
							<Icon name="angle left" />
							Back a step
						</button>

						<button
							className={styles.buttonFinishLater}
							onClick={onSkipServiceDetails}
						>
							Finish Later
							<Icon name="angle double right" />
						</button>
					</div>
				</div>

				<div className={styles.stepsColumn}>
					{/* {!isLoggedIn && (
            <div
              className={`${styles.stepItemContainer} ${styles.stepItemTickContainer}`}
              onClick={() => history.push(`/qip/auth/legal-notice`)}
            >
              <div className={styles.stepContainerText}>
                <div className={styles.stepItemIndicatorContainer}>
                  <div className={styles.stepItemFillDone} />
                </div>
                Welcome
              </div>
            </div>
          )} */}
					<ServiceSteps route={match.path} />
					{/* {stepsDone.map(steps => (
            <div
              key={steps.id}
              className={stepsIndicator.find(step => step.id === steps.id).completed ? `${styles.stepItemContainer} ${styles.stepItemTickContainer}` : `${styles.stepItemContainer} ${styles.stepItemCrossContainer}`}
              onClick={() => history.push(`${match.path.split('/').slice(0, match.path.split('/').length - 1).join('/')}/${steps.id}`)}
            >
              <div
                className={styles.stepContainerText}
              >
                <div className={styles.stepItemIndicatorContainer}>
                  {stepsIndicator.find(step => step.id === steps.id).completed
                    ? <div className={styles.stepItemFillDone} />
                    : (
                      <Icon
                        className={styles.stepItemUnfinished}
                        name="close"
                      />
                    )}
                </div>
                {steps.stepsName}
              </div>
            </div>
          ))}

          <div className={styles.CurrentItemContainer}>

            <div className={styles.stepItemCurrent}>
              <div className={styles.stepItemFillDone} />
            </div>

            <text className={styles.stepItemCurrentText}>
              QIP submission
            </text>

          </div>

          {stepsNotDone.map(steps => (
            <div
              key={steps.id}
              className={styles.stepItemContainer}
            >
              <Link
                className={styles.stepItemCurrentText}
                to={`${match.path.split('/').slice(0, match.path.split('/').length - 1).join('/')}/${steps.id}`}
              >
                <div className={styles.stepItemIndicatorContainer}>
                  <div className={styles.stepItemFill} />
                </div>
                {steps.stepsName}
              </Link>
            </div>
          ))} */}
				</div>
			</div>
		</div>
	);
}

PeopleResponsible.propTypes = {
	onNextStep: PropTypes.func,
	onSkipStep: PropTypes.func,
	onSaveStep: PropTypes.func,
	onSkipServiceDetails: PropTypes.func,
};

export default PeopleResponsible;
