import * as React from "react";

import PropTypes from "prop-types";
import { Button, Modal } from "semantic-ui-react";

import styles from "./ChangeStatusModal.module.css";

function ChangeStatusModal({
  visibility,
  onOpenModal,
  onCloseModal,
  onSelectStatus,
  onClearStatus,
}) {
  const onStatusClick = (status) => {
    onSelectStatus("status", status);
    onCloseModal();
  };

  const onClearSelectionStatus = () => {
    onClearStatus();
    onCloseModal();
  };

  return (
    <Modal
      size="small"
      centered={false}
      open={visibility}
      onOpen={onOpenModal}
      onClose={onCloseModal}
    >
      <div className={styles.modalContainer}>
        <Button
          className={styles.closeButton}
          onClick={onCloseModal}
          size="big"
          icon="close"
        />

        <text className={styles.headerText}>Change task status</text>

        <button
          className={styles.statusContainer}
          onClick={() => onStatusClick(1)}
        >
          Not started
        </button>

        <button
          className={styles.statusContainer}
          onClick={() => onStatusClick(2)}
        >
          In progress
        </button>

        <button
          className={styles.statusContainer}
          onClick={() => onStatusClick(3)}
        >
          Completed
        </button>
        <div className={styles.clearSection}>
          <button
            className={styles.clearSectionButton}
            onClick={onClearSelectionStatus}
          >
            Clear selection
          </button>
        </div>
      </div>
    </Modal>
  );
}

ChangeStatusModal.propTypes = {
  onSelectStatus: PropTypes.func,
  visibility: PropTypes.bool.isRequired,
  onOpenModal: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired,
};

export default ChangeStatusModal;
