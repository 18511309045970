import * as React from "react";

import ReactPixel from "react-facebook-pixel";

import PropTypes from "prop-types";
import { HiCheckCircle } from "react-icons/hi";
import { useHistory, Redirect } from "react-router-dom";
import { FaRegArrowAltCircleLeft } from "react-icons/fa";
import { Icon } from "semantic-ui-react";
import styles from "./PricingPlans.module.css";
import BlueButton from "../../components/BlueButton";
import SkipButton from "../../components/SkipButton";
import { useStoreState, useStoreActions } from "../../hooks";
import { isEmpty } from "lodash";

const { useState, useEffect, useCallback } = React;

function PricingPlans({ setPlanChoice, setPlanSubscription, location }) {
  const history = useHistory();

  const [bannerVisible, setBannerVisible] = useState(false);

  useStoreActions((state) => state.appStore.setHeaderMenu(false));

  const isLoggedIn = useStoreState((state) => state.authStore.isLoggedIn);

  const userId = useStoreState((state) => state.authStore.authObject.userId);

  const user = useStoreState((state) => state.userStore.loggedInUser);

  const services = useStoreState((state) => state.authStore.services);

  const disableMenu = useStoreState((state) => state.appStore.disableMenu);

  const remainingTrial = useStoreState(
    (state) => state.userStore.remainingTrial
  );

  const subExpired = remainingTrial <= 0 ? true : false;

  useStoreActions((state) => state.appStore.setFirstSignUp(false));

  const logout = useStoreActions((actions) => actions.authStore.logout);
  const clearNQASession = useStoreActions(
    (actions) => actions.nqaStore.clearSession
  );
  const clearUserSession = useStoreActions(
    (actions) => actions.userStore.clearSession
  );

  const showBanner = useStoreActions((state) => {
    state.appStore.showBanner(bannerVisible);
  });

  const fetchUserDetails = useStoreActions(
    (actions) => actions.userStore.fetchUserDetails
  );

  const [portalState, setPortalState] = useState("monthly");
  const [mentoringState, setMentoringState] = useState("monthly");

  //####################### REACT PIXEL ################################
  const advancedMatching = { em: "some@email.com" }; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/pixel-with-ads/conversion-tracking#advanced_match
  const options = {
    autoConfig: true, // set pixel's autoConfig
    debug: false, // enable logs
  };

  ReactPixel.init("791679465004341", advancedMatching, options);

  ReactPixel.pageView();
  {
    /* ReactPixel.track(event, data);
  ReactPixel.trackSingle('791679465004341', event, data);
  ReactPixel.trackCustom(event, data); */
  }
  //####################### REACT PIXEL ################################
  const redirectUrl = isLoggedIn ? "/qip/payment" : "/qip/auth/signup";

  const getUserDetails = useCallback(async () => {
    try {
      await fetchUserDetails({
        centerId: services[0].id,
        userId,
      });
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    getUserDetails();
  }, [userId]);

  const MentoringPlanBenefits = () => {
    const benefitsArray = [
      "Everything inside the QIP Portal",
      "Request feedback to all NQAs",
      "Get guidance from our mentors",
      "Termly mentoring sessions",
      "Monthly bespoke service resources",
      "A&R preview & preparation",
      "Access monthly expert webinars",
      "Email and phone support",
    ];

    return benefitsArray.map((item) => (
      // <div key={item} className={styles.checkTextGroup}>
      <div key={item} className={`flex justify-start items-center py-2`}>
        <HiCheckCircle color="#8BC334" />
        <text className={styles.checkText}>{item}</text>
      </div>
    ));
  };

  const SatPlanBenefits = () => {
    const benefitsArray = [
      "Create & save QIP/SATs online",
      "Generate fast QIP/SAT reports",
      "Achieve NQA goals as a team",
      "Access our exclusive resources",
      "A&R preview & preparation",
      "New monthly resources",
      "Access monthly expert webinars",
      "Email and phone support",
    ];

    return benefitsArray.map((item) => (
      // <div key={item} className={styles.checkTextGroup}>
      <div key={item} className={`flex justify-start items-center py-2`}>
        <HiCheckCircle color="#8BC334" />
        <text className={styles.checkText}>{item}</text>
      </div>
    ));
  };

  const FreePlanBenefits = () => {
    const benefitsArray = [
      "Create & save QIP/SATs online",
      "Generate fast QIP/SAT reports",
      "Achieve NQA goals as a team",
      "Access our exclusive resources",
      "A&R preview & preparation",
      "New monthly resources",
      "Access monthly expert webinars",
      "Email and phone support",
    ];

    return benefitsArray.map((item) => (
      // <div key={item} className={styles.checkTextGroup}>
      <div key={item} className={`flex justify-start items-center py-2`}>
        <HiCheckCircle color="#8BC334" />
        <text className={`${styles.checkText} `}>{item}</text>
      </div>
    ));
  };

  return (
    <>
      {user && user.role === "admin" ? (
        <div className={styles.mainContainer}>
          <div className={`${styles.backButtonContainer} mt-8`}>
            {!subExpired && isEmpty(location.state) && !disableMenu && (
              <button
                className={`${styles.backButtonText} text-xl text-gray-400 px-10 py-2 rounded-lg hover:bg-gray-50 `}
                onClick={() => {
                  setBannerVisible(true);
                  history.goBack();
                }}
              >
                <span>NQA dashboard</span>
              </button>
            )}

            {!subExpired &&
              !isEmpty(location) &&
              !isEmpty(location.state) &&
              !isEmpty(location.state.from) && (
                <button
                  className={styles.backButtonArrow}
                  onClick={() => history.goBack()}
                >
                  <FaRegArrowAltCircleLeft
                    className={styles.backButton}
                    color="#C4C4C4"
                    size={24}
                  />
                </button>
              )}

            {!subExpired &&
              !isEmpty(location) &&
              !isEmpty(location.state) &&
              location.state.forAdditionalService && (
                <button
                  className={styles.backButtonArrow}
                  onClick={() => history.goBack()}
                >
                  <FaRegArrowAltCircleLeft
                    className={styles.backButton}
                    color="#C4C4C4"
                    size={24}
                  />
                </button>
              )}
          </div>

          {isLoggedIn && isEmpty(location.state) && (
            <div className={styles.headerText}>
              {!subExpired ? (
                <>
                  <h1>Signup to a plan</h1>
                  <text>
                    To continue to enjoy all the benefits of Holistic QIP after
                    your trial period ends, signup to one of our plans.
                  </text>
                </>
              ) : (
                <>
                  <h1>Signup to a plan</h1>
                  <text>
                    {`Choose either a QIP/SAT Portal, or Portal + Mentor subscription (monthly or yearly) and select ‘buy now’.`}
                  </text>
                </>
              )}
            </div>
          )}

          {isLoggedIn &&
            !isEmpty(location.state) &&
            location.state.forAdditionalService && (
              <div className={styles.headerText}>
                <>
                  <h1>
                    Add&nbsp;another&nbsp;service&nbsp;to&nbsp;your&nbsp;account
                  </h1>
                  <text>
                    Choose either a QIP/SAT Portal, or Portal + Mentor
                    subscription (monthly or yearly) and select &apos;buy
                    now&apos;
                  </text>
                </>
              </div>
            )}

          <div
            className={
              !isLoggedIn
                ? `${styles.cardsContainer} ${styles.loggedOut}`
                : styles.cardsContainer
            }
          >
            {!isLoggedIn && (
              <div className={styles.cardGroup}>
                <div className={styles.cardGroupHeader}>
                  <div className={styles.cardTabHighlighted}>
                    <text>Promotional</text>
                  </div>
                  <div className={styles.emptyColumn} />
                </div>

                <div className={styles.cardContainer}>
                  <div className={styles.cardContent}>
                    <div className={styles.cardHeaderGroup}>
                      <text className={styles.selectedCardHeader}>Free</text>
                    </div>

                    <h4 className={styles.selectedCardSubheader}>
                      30 day trial
                    </h4>

                    {!isLoggedIn && (
                      <div className={styles.cardDescriptionGreen}>
                        <Icon
                          className={styles.cardDescriptionGreenIcon}
                          name="info circle"
                        />
                        <text>
                          No payment details required. Choose a payment plan
                          after 30 days.
                        </text>
                      </div>
                    )}

                    <div className={styles.selectedCardDescription}>
                      <text>
                        Get full access to the Holistic QIP app for 30 days free
                        of charge!
                      </text>
                    </div>

                    <FreePlanBenefits />

                    <BlueButton
                      className={styles.blueButton}
                      buttonText="Start trial"
                      link="/qip/auth/free-trial"
                    />
                  </div>
                </div>
              </div>
            )}

            <div className={styles.cardGroup}>
              <div className={styles.cardGroupHeader}>
                <div
                  className={
                    portalState === "monthly"
                      ? styles.cardTabHighlighted
                      : `${styles.cardTabFaded} ${styles.cardTabBorder} ${styles.left}`
                  }
                  onClick={() => setPortalState("monthly")}
                >
                  <text>Monthly</text>
                </div>

                <div
                  className={
                    portalState === "yearly"
                      ? styles.cardTabHighlighted
                      : `${styles.cardTabFaded} ${styles.cardTabBorder} ${styles.right}`
                  }
                  onClick={() => setPortalState("yearly")}
                >
                  <text>Yearly</text>
                </div>
              </div>

              <div className={styles.cardContainer}>
                <div className={styles.cardContent}>
                  <div className={styles.cardHeaderGroup}>
                    <text
                      style={{
                        fontSize: "20px",
                        lineHeight: "50px",
                        marginRight: ".5rem",
                      }}
                    >
                      $
                    </text>
                    <text className={styles.selectedCardHeader}>
                      {portalState === "monthly" ? "97" : "997"}
                    </text>
                    <text
                      style={{
                        fontSize: "20px",
                        lineHeight: "55px",
                        alignSelf: "flex-end",
                      }}
                    >
                      {portalState === "yearly" ? (
                        "/yearly"
                      ) : (
                        <span>
                          /month<span className={styles.asterisk}>*</span>
                        </span>
                      )}
                    </text>
                  </div>

                  <h4 className={styles.selectedCardSubheader}>
                    QIP/SAT Portal
                  </h4>

                  {!isLoggedIn && (
                    <div className={styles.cardDescriptionGreen}>
                      <Icon
                        className={styles.cardDescriptionGreenIcon}
                        name="info circle"
                      />
                      <text>
                        Your first 30 days are free! Payments start after this
                        introductory period.
                      </text>
                    </div>
                  )}

                  <div className={styles.selectedCardDescription}>
                    <text>
                      Full access to the Holistic QIP app and all our available
                      resources.
                    </text>
                  </div>

                  <SatPlanBenefits />

                  <BlueButton
                    className={styles.blueButton}
                    buttonText="Buy now"
                    onClick={() => {
                      setPlanChoice("portal");
                      setPlanSubscription(portalState);
                      if (
                        !isEmpty(location.state) &&
                        location.state.forAdditionalService
                      ) {
                        history.push(`/qip/add-new-service`);
                      } else {
                        history.push(redirectUrl);
                      }
                    }}
                  />

                  <div className={styles.pricingFooterText}>
                    <text>
                      <i>
                        <span className={styles.asterisk}>*</span>Minimum 6
                        month commitment.
                      </i>
                    </text>
                  </div>
                </div>
              </div>
            </div>

            <div className={styles.cardGroup}>
              <div className={styles.cardGroupHeader}>
                <div
                  className={
                    mentoringState === "monthly"
                      ? styles.cardTabHighlighted
                      : `${styles.cardTabFaded} ${styles.cardTabBorder} ${styles.left}`
                  }
                  onClick={() => setMentoringState("monthly")}
                >
                  <text>Monthly</text>
                </div>

                <div
                  className={
                    mentoringState === "yearly"
                      ? styles.cardTabHighlighted
                      : `${styles.cardTabFaded} ${styles.cardTabBorder} ${styles.right}`
                  }
                  onClick={() => setMentoringState("yearly")}
                >
                  <text>Yearly</text>
                </div>
              </div>

              <div className={styles.cardContainer}>
                <div className={styles.cardContent}>
                  <div className={styles.cardHeaderGroup}>
                    <text
                      style={{
                        fontSize: "20px",
                        lineHeight: "50px",
                        marginRight: ".5rem",
                      }}
                    >
                      $
                    </text>
                    <text className={styles.selectedCardHeader}>
                      {mentoringState === "monthly" ? "197" : "1,997"}
                    </text>
                    <text
                      style={{
                        fontSize: "20px",
                        lineHeight: "55px",
                        alignSelf: "flex-end",
                      }}
                    >
                      {mentoringState === "monthly" ? (
                        <span>
                          /month<span className={styles.asterisk}>*</span>
                        </span>
                      ) : (
                        "/year"
                      )}
                    </text>
                  </div>

                  <h4 className={styles.selectedCardSubheader}>
                    Portal + Mentoring
                  </h4>

                  {!isLoggedIn && (
                    <div className={styles.cardDescriptionGreen}>
                      <Icon
                        className={styles.cardDescriptionGreenIcon}
                        name="info circle"
                      />
                      <text>
                        Your first 30 days are free! Payments start after this
                        introductory period.
                      </text>
                    </div>
                  )}

                  <div className={styles.selectedCardDescription}>
                    <text>
                      The QIP Portal plus guidance & feedback from our mentors.
                    </text>
                  </div>

                  <MentoringPlanBenefits />

                  <BlueButton
                    className={styles.blueButton}
                    buttonText="Buy now"
                    onClick={() => {
                      setPlanChoice("mentoring");
                      setPlanSubscription(mentoringState);
                      if (
                        !isEmpty(location.state) &&
                        location.state.forAdditionalService
                      ) {
                        history.push(`/qip/add-new-service`);
                      } else {
                        history.push(redirectUrl);
                      }
                    }}
                  />

                  <div className={styles.pricingFooterText}>
                    <text>
                      <i>
                        <span className={styles.asterisk}>*</span>Minimum 6
                        month commitment.
                      </i>
                    </text>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={styles.pricesText}>
            <text>All prices in Australian Dollars, and include GST.</text>
          </div>
        </div>
      ) : (
        <>
          {user && user.role === "member" && subExpired ? (
            <div className={`flex justify-center items-center`}>
              <div
                className={`bg-white w-1/4 flex flex-col justify-center items-center py-14 px-12 mt-32 rounded-lg shadow-lg`}
              >
                <p className={`text-center text-xl pt-10 pb-14 `}>
                  Your administrator&apos;s account has expired. Please contact
                  your childcare&apos;s service administrator for more
                  information.
                </p>
                <SkipButton
                  className={styles.saveButton}
                  buttonText="Ok"
                  onClick={() => {
                    logout();
                    clearNQASession();
                    clearUserSession();
                  }}
                />
              </div>
            </div>
          ) : (
            <> </>
          )}{" "}
        </>
      )}
    </>
  );
}

PricingPlans.propTypes = {
  setPlanChoice: PropTypes.func,
  setPlanSubscription: PropTypes.func,
};

export default PricingPlans;
