import * as React from "react";

import PropTypes from "prop-types";
import { Modal } from "semantic-ui-react";

import SkipButton from "../../SkipButton";
import styles from "./LogoutModal.module.css";
import { useStoreActions } from "../../../hooks";

function LogoutModal({ visibility, onOpenModal, onCloseModal }) {
  const logout = useStoreActions((actions) => actions.authStore.logout);
  const clearNQASession = useStoreActions(
    (actions) => actions.nqaStore.clearSession
  );
  const clearUserSession = useStoreActions(
    (actions) => actions.userStore.clearSession
  );

  const onLogout = async () => {
    await logout();
    localStorage.removeItem("selectedService");
    clearNQASession();
    clearUserSession();
  };

  const onLogoutConfirm = async () => {
    await onLogout();
    onCloseModal();
  };

  return (
    <Modal
      size="small"
      centered
      open={visibility}
      onOpen={onOpenModal}
      onClose={onCloseModal}
    >
      <div className={styles.modalContainer}>
        <text className={styles.logoutText}>Do you wish to log out?</text>

        <SkipButton
          className={styles.saveButton}
          buttonText="Log out"
          onClick={onLogoutConfirm}
        />
      </div>
    </Modal>
  );
}

LogoutModal.propTypes = {
  visibility: PropTypes.bool.isRequired,
  onOpenModal: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired,
};

export default LogoutModal;
