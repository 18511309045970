import React, { useState, useContext } from "react";
import { Link, useHistory, useRouteMatch } from "react-router-dom";

import { ServiceDetailsContext } from "../../context/ServiceDetailsContext";
import styles from "./ServiceSteps.module.css";

import { Steps, Divider } from "antd";
import * as icons from "react-icons/fa";
import { Icon } from "semantic-ui-react";

const { Step } = Steps;

const onboardingSteps = [
  {
    id: 1,
    stepsName: "Location",
  },
  {
    id: 2,
    stepsName: "Postal Address",
  },
  {
    id: 3,
    stepsName: "Contact Details",
  },
  {
    id: 4,
    stepsName: "Providers",
  },
  {
    id: 5,
    stepsName: "Supervisor",
  },
  {
    id: 6,
    stepsName: "Leader",
  },
  {
    id: 7,
    stepsName: "Operating hours",
  },
  {
    id: 8,
    stepsName: "Additional info",
  },
  {
    id: 9,
    stepsName: "Educators",
  },
  {
    id: 10,
    stepsName: "QIP submission",
  },
  {
    id: 11,
    stepsName: "Philosophy",
  },
  {
    id: 12,
    stepsName: "Upload logo",
  },
];

const allSteps = [
  {
    id: 0,
    stepsName: "Service name",
  },
  {
    id: 1,
    stepsName: "Location",
  },
  {
    id: 2,
    stepsName: "Postal Address",
  },
  {
    id: 3,
    stepsName: "Contact Details",
  },
  {
    id: 4,
    stepsName: "Providers",
  },
  {
    id: 5,
    stepsName: "Supervisor",
  },
  {
    id: 6,
    stepsName: "Leader",
  },
  {
    id: 7,
    stepsName: "Operating hours",
  },
  {
    id: 8,
    stepsName: "Additional info",
  },
  {
    id: 9,
    stepsName: "Educators",
  },
  {
    id: 10,
    stepsName: "QIP submission",
  },
  {
    id: 11,
    stepsName: "Philosophy",
  },
  {
    id: 12,
    stepsName: "Upload logo",
  },
];

const ServiceSteps = ({ route = "/" }) => {
  const [currentStep, setCurrentStep] = useState(
    Number(route.split("/")[route.split("/").length - 1])
  );

  const [baseRoute, setBaseRoute] = useState(
    route
      .split("/")
      .slice(0, route.split("/").length - 1)
      .join("/")
  );

  const history = useHistory();

  const {
    onboarding,
    serviceDetailsState,
    changeInputDispatch,
    stepsIndicator,
  } = useContext(ServiceDetailsContext);

  const [routeSteps, setRouteSteps] = useState(
    onboarding ? onboardingSteps : allSteps
  );

  const setStepStatus = (dot, { status, index }) => {
    switch (status) {
      case "process":
        return <span>{<icons.FaCircle className={`text-black`} />} </span>;

      case "wait":
        return <span>{status}</span>;

      case "finish":
        return (
          <span> {<icons.FaCheckCircle className={`text-green-500`} />} </span>
        );

      case "error":
        return <span>{<icons.FaTimesCircle />} </span>;
    }
  };

  return (
    <>
      {routeSteps.map((step, i) => {
        let indicator = stepsIndicator.filter((s) => {
          return s.id === step.id;
        });

        if (indicator[0].id === currentStep) {
          return (
            <div key={i} className={styles.CurrentItemContainer}>
              <div className={styles.stepItemCurrent}>
                <div className={styles.currentItemFill} />
              </div>

              <text className={styles.stepItemCurrentText}>
                {step.stepsName}
              </text>
            </div>
          );
        } else {
          if (indicator[0].completed) {
            return (
              <div
                key={i}
                className={`${styles.stepItemContainer} ${styles.stepItemTickContainer}`}
                onClick={() => history.push(`${baseRoute}/${step.id}`)}
              >
                <div className={styles.stepContainerText}>
                  <div className={styles.stepItemIndicatorContainer}>
                    <div className={styles.stepItemFillDone} />
                  </div>
                  <span className={`${styles.stepsName}`}>
                    {step.stepsName}
                  </span>
                </div>
              </div>
            );
          } else {
            return (
              <div
                key={i}
                className={`${styles.stepItemContainer} ${styles.stepItemCrossContainer}`}
                onClick={() => history.push(`${baseRoute}/${step.id}`)}
              >
                <div className={styles.stepContainerText}>
                  <div className={styles.stepItemIndicatorContainer}>
                    <Icon className={styles.stepItemUnfinished} name="close" />
                  </div>
                  <span className={`${styles.stepsName}`}>
                    {step.stepsName}
                  </span>
                </div>
              </div>
            );
          }
        }
      })}
    </>
  );
};

export default ServiceSteps;
