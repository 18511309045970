import * as React from 'react';

import { useHistory, useRouteMatch } from 'react-router-dom';
import { Dropdown, Input, Modal } from 'semantic-ui-react';
import { FaRegArrowAltCircleLeft } from 'react-icons/fa';
  
import styles from './ServicesList.module.css';
import { useStoreState } from '../../../hooks';
import SkipButton from '../../../components/SkipButton';
import ServiceCard from '../../../components/ServiceCard';
import ServiceRemovedModal from '../../../components/Modals/ServiceRemovedModal';

const { useState } = React;


const filterOptions = [
  {
    key: 'A-Z',
    text: 'A-Z',
    value: 'A-Z',
  },
  {
    key: 'Z-A',
    text: 'Z-A',
    value: 'Z-A',
  },
  {
    key: '0-9',
    text: '0-9',
    value: '0-9',
  },
  {
    key: '9-0',
    text: '9-0',
    value: '9-0',
  },
];

function ServicesList() {
  const history = useHistory();
  const match = useRouteMatch();

  const services = useStoreState(state => state.authStore.user.services);

  const [removeServiceModalVis, setRemoveServiceModalVis] = useState(false);
  const [serviceRemovedModalVis, setServiceRemovedModalVis] = useState(false);

  return (
    <div className={styles.mainContainer}>

      <div className={styles.backButtonContainer}>

        <button
          className={styles.backButtonText}
          onClick={() => history.goBack()}
        >
          <FaRegArrowAltCircleLeft
            className={styles.backButton}
            color="#C4C4C4"
            size={24}
          />
        </button>

      </div>

      <Modal
        size="small"
        centered={false}
        open={removeServiceModalVis}
        onOpen={() => setRemoveServiceModalVis(true)}
        onClose={() => setRemoveServiceModalVis(false)}
      >

        <div className={styles.modalContainer}>

          <text className={styles.modalHeaderText}>
            Are you sure you want to remove this service?
          </text>

          <div>

            <button
              className={styles.modalYesButton}
              onClick={() => {
                setRemoveServiceModalVis(false);
                setServiceRemovedModalVis(true);
              }}
            >
              Yes
            </button>

            <SkipButton
              className={styles.modalNoButton}
              buttonText="No"
              onClick={() => setRemoveServiceModalVis(false)}
            />

          </div>

        </div>
      </Modal>

      <ServiceRemovedModal
        visibility={serviceRemovedModalVis}
        onOpenModal={() => setServiceRemovedModalVis(true)}
        onCloseModal={() => setServiceRemovedModalVis(false)}
        onGetReports={() => setServiceRemovedModalVis(false)}
      />

      <div className={styles.content}>

        <div className={styles.headerContainer}>

          <div className={styles.headerTextGroup}>

            <h1 className={styles.headerText}>
              All services
            </h1>

            <text className={styles.headerSubText}>
              List of all services on this account
            </text>

          </div>

          <button
            className={styles.backToDashboardButton}
            onClick={() => history.push('/qip/user-management/')}
          >
            Back to dashboard
          </button>

        </div>

        <div className={styles.searchFilterRow}>

          <div>

            <Input
              className={styles.searchInput}
              icon="search"
              placeholder="Type name"
            />

            <Dropdown
              className={styles.filterDropdown}
              inline
              header="Filters"
              placeholder="A-Z"
              options={filterOptions}
              defaultValue={filterOptions[0].value}
            />

            <Dropdown
              className={styles.filterDropdown}
              inline
              header="Services"
              placeholder="Renews"
            />

          </div>

          <button
            className={styles.newServiceButton}
            onClick={() => history.push(`${match.path}/upgrade`)}
          >
            New Service
          </button>

        </div>

        {services.map(service =>
          (
            <ServiceCard
              key={service.id}
              serviceData={service}
              onRemoveService={() => setRemoveServiceModalVis(true)}
            />
          )
        )}

      </div>

    </div >
  );
}

export default ServicesList;
