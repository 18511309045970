import * as React from "react";

import { Dropdown } from "semantic-ui-react";
import { useHistory } from "react-router-dom";
import { FaRegArrowAltCircleLeft, FaSpinner } from "react-icons/fa";

import styles from "./UserCreation.module.css";

import BlueButton from "../../../components/BlueButton";
import InputComponent from "../../../components/InputComponent";
import UserCreatedModal from "../../../components/Modals/UserCreatedModal";
import UserExistingModal from "../../../components/Modals/UserExistingModal";
import { useStoreState } from "../../../hooks";
import { createNewUser } from "../../../api/requests";

const { useCallback, useReducer, useState } = React;

const createReducer = (state, action) => {
  switch (action.type) {
    case "CREATE": {
      const updatedInput = {
        ...state.input,
        [action.field]: action.value,
      };

      const updatedValidation = {
        ...state.validation,
        [action.field]: action.valid,
      };

      return {
        input: updatedInput,
        validation: updatedValidation,
      };
    }
    default:
      return state;
  }
};

const userRoles = [
  // {
  //   key: 1,
  //   value: 'admin',
  //   text: 'Admin',
  // },
  {
    key: 2,
    value: "member",
    text: "Member",
  },
];

const states = [
  {
    key: 1,
    value: "NSW",
    text: "NSW",
  },
  {
    key: 2,
    value: "QLD",
    text: "QLD",
  },
  {
    key: 3,
    value: "SA",
    text: "SA",
  },
  {
    key: 4,
    value: "TAS",
    text: "TAS",
  },
  {
    key: 5,
    value: "VIC",
    text: "VIC",
  },
  {
    key: 6,
    value: "WA",
    text: "WA",
  },
  {
    key: 7,
    value: "ACT",
    text: "ACT",
  },
  {
    key: 8,
    value: "NT",
    text: "NT",
  },
];

function UserCreation() {
  const history = useHistory();

  const services = useStoreState((state) => state.authStore.services);

  const centerId = useStoreState((state) => state.nqaStore.serviceDetails.id);

  const [fieldChecked, setFieldChecked] = useState(false);

  const initialState = {
    input: {
      email: "",
      userType: "USER",
      // password: '',
      role: "member",
      streetAddress: "",
      suburb: "",
      postalCode: "",
      telNum: "",
      mobileNum: "",
      additionalEmail: "",
    },
    validation: {
      email: false,
      // password: false,
      role: true,
      streetAddress: true,
      suburb: true,
      state: true,
      postalCode: true,
      telNum: true,
      mobileNum: true,
      additionalEmail: true,
    },
    formValidation: false,
  };

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  const [saveLoading, setLoading] = useState(false);
  const [userCreatedModalVis, setUserCreatedModalVis] = useState(false);
  const [userExistingModalVis, setUserExistingModalVis] = useState(false);

  const [formState, formDispatch] = useReducer(createReducer, initialState);

  const [centerIds, setCenterIds] = useState([]);

  const [cId, setCId] = useState(centerId);

  const changeInputDispatch = useCallback((field, value, validity = true) => {
    const action = {
      type: "CREATE",
      field,
      value,
      valid: validity,
    };
    formDispatch(action);
  }, []);

  const setName = (fieldName, value, _) => {
    if (fieldName === "firstName") {
      setFirstName(value);
    } else {
      setLastName(value);
    }
  };

  const onCreateUser = async () => {
    setFieldChecked(true);

    if (
      !formState.input.email ||
      // && formState.input.role
      // && formState.input.password
      !firstName ||
      !lastName ||
      centerIds.length <= 0
    ) {
      return;
    }

    setLoading(true);

    try {
      const payload = {
        ...formState.input,
        name: `${firstName} ${lastName}`,
        centerIds,
        centerId: cId,
      };

      const response = await createNewUser(payload);

      if (response) {
        setLoading(false);
        setUserCreatedModalVis(true);
      }
    } catch (error) {
      setLoading(false);
      setUserExistingModalVis(true);
    }
    //   } else {
    //     alert("Please fill all required fields.");
    //   }
  };

  const onConfirmExisting = () => {
    setUserExistingModalVis(false);
  };

  const onConfirm = () => {
    setUserCreatedModalVis(false);
    history.push("/qip/user-management");
  };

  return (
    <div className={styles.mainContainer}>
      <div className={styles.backButtonContainer}>
        <button
          className={styles.backButtonText}
          onClick={() => history.goBack()}
        >
          <FaRegArrowAltCircleLeft
            className={styles.backButton}
            color="#C4C4C4"
            size={24}
          />
        </button>
      </div>

      {userCreatedModalVis && (
        <UserCreatedModal
          visibility={userCreatedModalVis}
          onOpenModal={() => setUserCreatedModalVis(true)}
          onCloseModal={onConfirm}
        />
      )}

      {userExistingModalVis && (
        <UserExistingModal
          visibility={userExistingModalVis}
          onOpenModal={() => setUserExistingModalVis(true)}
          onCloseModal={onConfirmExisting}
        />
      )}

      <div className={styles.content}>
        <div className={styles.headerContainer}>
          <div className={styles.headerTextGroup}>
            <h1 className={styles.headerText}>Create a new user</h1>

            <text className={styles.headerSubText}>
              Add a user to your team and assign them to a Centre
            </text>
          </div>

          <button
            className={styles.backToDashboardButton}
            onClick={() => history.push("/qip/user-management/")}
          >
            Back to dashboard
          </button>
        </div>

        <div className={styles.cardContainer}>
          <div className={styles.detailsContent}>
            <h2 className={styles.cardHeaderText}>User details</h2>

            <div className={styles.inputRow}>
              <InputComponent
                className={styles.inputBox}
                fieldName="email"
                label="Users email address"
                type="email"
                required
                showNotFilled={fieldChecked}
                inputValue={formState.input.email}
                setInputValue={changeInputDispatch}
              />

              {/*<InputComponent
                    className={styles.inputBox}
                    fieldName="password"
                    label="Password"
                    type="password"
                    required
                    inputValue={formState.input.password}
                    setInputValue={changeInputDispatch}
                  />*/}
            </div>

            <div className={styles.inputRow}>
              <InputComponent
                className={styles.inputBox}
                fieldName="firstName"
                label="First name"
                type="text"
                required
                showNotFilled={fieldChecked}
                inputValue={firstName}
                setInputValue={(_, value) => setFirstName(value)}
              />

              <InputComponent
                className={styles.inputBox}
                fieldName="lastName"
                label="Last Name"
                type="text"
                required
                showNotFilled={fieldChecked}
                inputValue={lastName}
                setInputValue={(_, value) => setLastName(value)}
              />
            </div>

            <div className={styles.inputRow1}>
              <div className={styles.dropdownGroup1}>
                <text className={styles.dropdownText}>
                  User role <span className={styles.required}>*</span>
                </text>

                <Dropdown
                  className={styles.dropdownBox1}
                  disabled
                  selection
                  fluid
                  multiple
                  placeholder="Choose"
                  options={userRoles}
                  value={formState.input.role}
                  // onChange={(_, data) => changeInputDispatch(
                  //   'role',
                  //   data.value
                  // )}
                />
              </div>

              <div className={styles.dropdownGroup1}>
                <text className={styles.dropdownText}>
                  Add to centre(s) <span className={styles.required}>*</span>
                </text>
                <Dropdown
                  className={styles.dropdownBox1}
                  fluid
                  selection
                  multiple
                  placeholder="Choose"
                  // defaultValue={
                  //   services.filter((service) => {
                  //     return service.id === centerId;
                  //   })[0].name
                  // }
                  options={services.map((service) => ({
                    text: service.name,
                    value: service.id,
                  }))}
                  onChange={(_, data) => {
                    // setCId(data.value);
                    setCenterIds(data.value);
                    // changeInputDispatch("service", data.text);
                  }}
                />
                {fieldChecked && centerIds.length <= 0 && (
                  <div className={`text-red-400 p-2 font-thin italic`}>
                    This field is required.
                  </div>
                )}
              </div>
            </div>

            <BlueButton
              className={styles.blueButton}
              // buttonText="Create user"

              buttonText={
                saveLoading ? (
                  <div className={`flex justify-center items-center`}>
                    <FaSpinner className={`animate-spin`} />
                    <span className={`block pl-2`}>Create user</span>
                  </div>
                ) : (
                  <> Create user</>
                )
              }
              onClick={onCreateUser}
            />
          </div>
          {/*<div className={styles.detailsContent}>

                <h2 className={styles.cardHeaderText}>
                  User address
                </h2>

                <div className={styles.inputRow}>

                  <InputComponent
                    className={styles.inputBox}
                    fieldName="streetAddress"
                    label="Street Address"
                    type="text"
                    inputValue={formState.input.streetAddress}
                    setInputValue={changeInputDispatch}
                  />

                  <InputComponent
                    className={styles.inputBox}
                    fieldName="suburb"
                    label="Suburb"
                    type="text"
                    inputValue={formState.input.suburb}
                    setInputValue={changeInputDispatch}
                  />

                </div>

                <div className={styles.inputRow}>

                  <div className={styles.dropdownGroup}>

                    <text className={styles.dropdownText}>
                      State/territory
                    </text>

                    <Dropdown
                      className={styles.dropdownBox}
                      fluid
                      selection
                      value={formState.input.state}
                      options={states}
                      placeholder="Choose"
                      onChange={(e, data) => changeInputDispatch(
                        'state',
                        data.value
                      )}
                    />

                  </div>

                  <InputComponent
                    className={styles.inputBox}
                    fieldName="postalCode"
                    label="Postcode"
                    type="number"
                    inputValue={formState.input.postalCode}
                    setInputValue={changeInputDispatch}
                  />

                </div>

              </div>

              <div className={styles.detailsContent}>

                <h2 className={styles.cardHeaderText}>
                  Contact details
                </h2>

                <div className={styles.inputRow}>

                  <InputComponent
                    className={styles.inputBox}
                    fieldName="telNum"
                    label="Telephone number"
                    type="text"
                    inputValue={formState.input.telNum}
                    setInputValue={changeInputDispatch}
                  />

                  <InputComponent
                    className={styles.inputBox}
                    fieldName="mobileNum"
                    label="Mobile number"
                    type="text"
                    inputValue={formState.input.mobileNum}
                    setInputValue={changeInputDispatch}
                  />

                </div>

                <div className={styles.inputRow}>

                  <InputComponent
                    className={styles.inputBox}
                    fieldName="additionalEmail"
                    label="E-mail address"
                    type="email"
                    inputValue={formState.input.additionalEmail}
                    setInputValue={changeInputDispatch}
                  />

                </div>

              </div>*/}
        </div>
      </div>
    </div>
  );
}

export default UserCreation;
