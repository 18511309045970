import * as React from "react";

import PropTypes from "prop-types";
import { Button, Modal, Icon } from "semantic-ui-react";

import InputComponent from "../../InputComponent";
import styles from "./MentorFeedbackModal.module.css";
import FeedbackSentModal from "../FeedbackSentModal/";
import { useStoreState } from "../../../hooks";
import { requestFeedback } from "../../../api/requests";
import { FaSpinner, FaInfoCircle } from "react-icons/fa";
import { message } from "antd";

const { useCallback, useState } = React;

function MentorFeedbackModal({ visibility, onOpenModal, onCloseModal }) {
  const [selectedNQAList, setSelectedNQAList] = useState([]);
  const [feedbackSentModalVisibility, setFeedbackSentModalVisibility] =
    useState(false);
  const [feedbackComment, setFeedbackComment] = useState("");

  const [requesting, setRequesting] = useState(false);

  const user = useStoreState((state) => state.userStore.loggedInUser);

  const onSelectNQA = useCallback(
    (nqa) => {
      if (selectedNQAList.includes(nqa))
        setSelectedNQAList(selectedNQAList.filter((item) => item !== nqa));
      else setSelectedNQAList(selectedNQAList.concat(nqa));
    },
    [selectedNQAList]
  );

  const onRequestFeedback = async () => {
    if (selectedNQAList.length === 0) {
      message.warning({
        content: (
          <div className={`flex justify-center items-center`}>
            <FaInfoCircle className={`text-red-400`} />
            <span className={`ml-2`}>
              Please select NQA(s) to request for feedback.
            </span>
          </div>
        ),
        // duration: 1,
        icon: <> </>,
      });
      return;
    }
    setRequesting(true);
    const { name, email, phone } = user;
    try {
      const response = await requestFeedback({
        userId: user.id,
        centerId: user.centerId,
        comments: feedbackComment,
        requestNQA: selectedNQAList,
        status: 1,
      });
      if (response) {
        setRequesting(false);
        setFeedbackSentModalVisibility(true);
      }
    } catch (error) {
      console.log(error);
      setRequesting(false);
    }
    setRequesting(false);
  };

  return (
    <Modal
      size="small"
      centered
      open={visibility}
      onOpen={onOpenModal}
      onClose={onCloseModal}
    >
      <div className={styles.modalContainer}>
        <Button
          className={styles.closeButton}
          onClick={onCloseModal}
          size="big"
          icon="close"
        />

        <text className={styles.headerText}>Get mentor feedback for NQA’s</text>

        <text className={styles.descriptionText}>
          Get our professional mentors to review your NQA documentation. You can
          select below which NQA’s you want reviewed and our mentor will contact
          you soon.
        </text>

        <div className={styles.selectionGroup}>
          <div className={styles.selectionRow}>
            <button
              className={
                selectedNQAList.includes(1)
                  ? styles.nqaButtonSelected
                  : styles.nqaButton
              }
              onClick={() => onSelectNQA(1)}
            >
              {selectedNQAList.includes(1) && (
                <Icon className={styles.checkIcon} name="check" size="small" />
              )}
              <text>NQA 1</text>
            </button>

            <button
              className={
                selectedNQAList.includes(2)
                  ? styles.nqaButtonSelected
                  : styles.nqaButton
              }
              onClick={() => onSelectNQA(2)}
            >
              {selectedNQAList.includes(2) && (
                <Icon className={styles.checkIcon} name="check" size="small" />
              )}
              <text>NQA 2</text>
            </button>

            <button
              className={
                selectedNQAList.includes(3)
                  ? styles.nqaButtonSelected
                  : styles.nqaButton
              }
              onClick={() => onSelectNQA(3)}
            >
              {selectedNQAList.includes(3) && (
                <Icon className={styles.checkIcon} name="check" size="small" />
              )}
              <text>NQA 3</text>
            </button>

            <button
              className={
                selectedNQAList.includes(4)
                  ? styles.nqaButtonSelected
                  : styles.nqaButton
              }
              onClick={() => onSelectNQA(4)}
            >
              {selectedNQAList.includes(4) && (
                <Icon className={styles.checkIcon} name="check" size="small" />
              )}
              <text>NQA 4</text>
            </button>

            <button
              className={
                selectedNQAList.includes(5)
                  ? styles.nqaButtonSelected
                  : styles.nqaButton
              }
              onClick={() => onSelectNQA(5)}
            >
              {selectedNQAList.includes(5) && (
                <Icon className={styles.checkIcon} name="check" size="small" />
              )}
              <text>NQA 5</text>
            </button>

            <button
              className={
                selectedNQAList.includes(6)
                  ? styles.nqaButtonSelected
                  : styles.nqaButton
              }
              onClick={() => onSelectNQA(6)}
            >
              {selectedNQAList.includes(6) && (
                <Icon className={styles.checkIcon} name="check" size="small" />
              )}
              <text>NQA 6</text>
            </button>

            <button
              className={
                selectedNQAList.includes(7)
                  ? styles.nqaButtonSelected
                  : styles.nqaButton
              }
              onClick={() => onSelectNQA(7)}
            >
              {selectedNQAList.includes(7) && (
                <Icon className={styles.checkIcon} name="check" size="small" />
              )}
              <text>NQA 7</text>
            </button>
          </div>
        </div>

        <div className={styles.divider} />

        <text className={styles.headerText}>Add a note for your mentor</text>

        <text className={styles.descriptionText}>
          Provide additional details about your request and/or sections fo NQA’s
          you are having problems with.
        </text>

        <div className={styles.commentSection}>
          <InputComponent
            fieldName={`feedbackComment`}
            setInputValue={(fieldName, value) => {
              setFeedbackComment(value);
            }}
            className={styles.commentSectionText}
            textArea
            type="text"
            label="Additional comments here"
          />
        </div>

        <button
          disabled={requesting}
          className={styles.requestFeedbackButton}
          onClick={onRequestFeedback}
          // onClick={onSendRequestFeedback}
        >
          {requesting ? (
            <div className={`w-full flex items-center`}>
              <FaSpinner className={`animate-spin`} />{" "}
              <span className={`ml-2`}> Requesting...</span>
            </div>
          ) : (
            <> Request feedback</>
          )}
        </button>

        <text className={styles.requestFeedNote}>
          <strong>Please note:</strong> The purpose of the feedback is general
          in nature. If you would like detailed specific feedback about your
          entire QIP – please{" "}
          <a
            href="mailto:info@holisticqip.com.au"
            target="_blank"
            rel="noreferrer"
          >
            contact our team.
          </a>
        </text>

        <FeedbackSentModal
          visibility={feedbackSentModalVisibility}
          onOpenModal={() => setFeedbackSentModalVisibility(true)}
          onCloseModal={() => {
            setFeedbackSentModalVisibility(false);
            onCloseModal();
          }}
        />
      </div>
    </Modal>
  );
}

MentorFeedbackModal.propTypes = {
  visibility: PropTypes.bool.isRequired,
  onOpenModal: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired,
};

export default MentorFeedbackModal;
