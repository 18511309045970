import * as React from "react";

import PropTypes from "prop-types";
// import Switch from "react-ios-switch";
import { Dropdown, Icon } from "semantic-ui-react";
import { Link, useHistory, useRouteMatch } from "react-router-dom";

import styles from "./OperationalHours.module.css";
import BlueButton from "../../../components/BlueButton";
import { ServiceDetailsContext } from "../../../context/ServiceDetailsContext";
import { TimePicker, Switch } from "antd";
import moment from "moment";
import { isEmpty } from "lodash";

import { useStoreState } from "../../../hooks";

import { ServiceSteps } from "../../../components/Steps";

import {
  FaSpinner,
  FaAngleUp,
  FaAngleDown,
  FaAngleLeft,
  FaAngleRight,
} from "react-icons/fa";

import { useSpring, animated, config } from "react-spring";

const { useContext, useEffect, useState } = React;

const days = [
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday",
];

const initialHours = [
  {
    day: "monday",
    openTime: "06:00AM",
    closeTime: "07:00PM",
    working: true,
  },
  {
    day: "tuesday",
    openTime: "06:00AM",
    closeTime: "07:00PM",
    working: true,
  },
  {
    day: "wednesday",
    openTime: "06:00AM",
    closeTime: "07:00PM",
    working: true,
  },
  {
    day: "thursday",
    openTime: "06:00AM",
    closeTime: "07:00PM",
    working: true,
  },
  {
    day: "friday",
    openTime: "06:00AM",
    closeTime: "07:00PM",
    working: true,
  },
  {
    day: "saturday",
    openTime: "06:00AM",
    closeTime: "07:00PM",
    working: false,
  },
  {
    day: "sunday",
    openTime: "06:00AM",
    closeTime: "07:00PM",
    working: false,
  },
];

const initialOutsideSchoolHours = [
  {
    day: "monday",
    session1Start: "06:00AM",
    session1Finish: "07:00AM",
    session2Start: "06:00AM",
    session2Finish: "07:00AM",
  },
  {
    day: "tuesday",
    session1Start: "06:00AM",
    session1Finish: "07:00AM",
    session2Start: "06:00AM",
    session2Finish: "07:00AM",
  },
  {
    day: "wednesday",
    session1Start: "06:00AM",
    session1Finish: "07:00AM",
    session2Start: "06:00AM",
    session2Finish: "07:00AM",
  },
  {
    day: "thursday",
    session1Start: "06:00AM",
    session1Finish: "07:00AM",
    session2Start: "06:00AM",
    session2Finish: "07:00AM",
  },
  {
    day: "friday",
    session1Start: "06:00AM",
    session1Finish: "07:00AM",
    session2Start: "06:00AM",
    session2Finish: "07:00AM",
  },
  {
    day: "saturday",
    session1Start: "06:00AM",
    session1Finish: "07:00AM",
    session2Start: "06:00AM",
    session2Finish: "07:00AM",
  },
  {
    day: "sunday",
    session1Start: "06:00AM",
    session1Finish: "07:00AM",
    session2Start: "06:00AM",
    session2Finish: "07:00AM",
  },
];

const initialVacationCareHours = [
  {
    day: "monday",
    openTime: "06:00AM",
    closeTime: "07:00PM",
    open24Hours: false,
  },
  {
    day: "tuesday",
    openTime: "06:00AM",
    closeTime: "07:00PM",
    open24Hours: false,
  },
  {
    day: "wednesday",
    openTime: "06:00AM",
    closeTime: "07:00PM",
    open24Hours: false,
  },
  {
    day: "thursday",
    openTime: "06:00AM",
    closeTime: "07:00PM",
    open24Hours: false,
  },
  {
    day: "friday",
    openTime: "06:00AM",
    closeTime: "07:00PM",
    open24Hours: false,
  },
  {
    day: "saturday",
    openTime: "06:00AM",
    closeTime: "07:00PM",
    open24Hours: true,
  },
  {
    day: "sunday",
    openTime: "06:00AM",
    closeTime: "07:00PM",
    open24Hours: true,
  },
];

function OperationalHours({ onNextStep, onSaveStep, onSkipServiceDetails }) {
  const history = useHistory();
  const match = useRouteMatch();

  const [serviceTable, setServiceTable] = useState(true);
  const [careTable, setCareTable] = useState(false);
  const [vacayTable, setVacayTable] = useState(false);

  const [enableSep, setEnableSep] = useState(true);

  const serviceSpring = useSpring({
    opacity: serviceTable ? 1 : 0,
    display: serviceTable ? "block" : "none",
  });
  const careSpring = useSpring({
    opacity: careTable ? 1 : 0,
    display: careTable ? "block" : "none",
  });

  const vacaySpring = useSpring({
    opacity: vacayTable ? 1 : 0,
    display: vacayTable ? "block" : "none",
  });

  const {
    saveLoading,
    serviceDetailsState,
    changeInputDispatch,
    stepsIndicator,
  } = useContext(ServiceDetailsContext);

  const isLoggedIn = useStoreState((state) => state.authStore.isLoggedIn);
  const [fieldChecked, setFieldChecked] = useState(false);

  const onToggle = (day, value) => {
    const operatingHours = !isEmpty(serviceDetailsState.input.operatingHours)
      ? [...serviceDetailsState.input.operatingHours]
      : [...initialHours];
    operatingHours.find((item) => item.day === day).working = !value;

    changeInputDispatch("operatingHours", operatingHours);
  };

  const onToggleVacationCareHours = (day, value) => {
    const vacationCareHours = !isEmpty(
      serviceDetailsState.input.vacationCareHours
    )
      ? [...serviceDetailsState.input.vacationCareHours]
      : [...initialVacationCareHours];
    vacationCareHours.find((item) => item.day === day).open24Hours = !value;

    changeInputDispatch("vacationCareHours", vacationCareHours);
  };

  const onSelectTimeVacationCareHours = (day, timeField, value) => {
    const vacationCareHours = !isEmpty(
      serviceDetailsState.input.vacationCareHours
    )
      ? [...serviceDetailsState.input.vacationCareHours]
      : [...initialVacationCareHours];
    vacationCareHours.find((item) => item.day === day)[timeField] = value;

    changeInputDispatch("vacationCareHours", vacationCareHours);
  };

  const onSelectTime = (day, timeField, value) => {
    const operatingHours = !isEmpty(serviceDetailsState.input.operatingHours)
      ? [...serviceDetailsState.input.operatingHours]
      : [...initialHours];
    operatingHours.find((item) => item.day === day)[timeField] = value;

    changeInputDispatch("operatingHours", operatingHours);
  };

  const onSelectTimeOutsideSchoolHours = (day, timeField, value) => {
    const outsideSchoolHours = !isEmpty(
      serviceDetailsState.input.outsideSchoolHours
    )
      ? [...serviceDetailsState.input.outsideSchoolHours]
      : [...initialOutsideSchoolHours];
    outsideSchoolHours.find((item) => item.day === day)[timeField] = value;

    changeInputDispatch("outsideSchoolHours", outsideSchoolHours);
  };

  const onSave = async () => {
    setFieldChecked(true);
    await onSaveStep();
    onNextStep();
    history.push(
      `${match.path
        .split("/")
        .slice(0, match.path.split("/").length - 1)
        .join("/")}/8`
    );
  };

  useEffect(() => {
    if (isEmpty(serviceDetailsState.input.operatingHours)) {
      changeInputDispatch("operatingHours", [...initialHours]);
    }

    if (isEmpty(serviceDetailsState.input.outsideSchoolHours)) {
      changeInputDispatch("outsideSchoolHours", [...initialOutsideSchoolHours]);
    }

    if (isEmpty(serviceDetailsState.input.vacationCareHours)) {
      changeInputDispatch("vacationCareHours", [...initialVacationCareHours]);
    }
  }, [
    changeInputDispatch,
    serviceDetailsState.input.operatingHours,
    serviceDetailsState.input.outsideSchoolHours,
    serviceDetailsState.input.vacationCareHours,
  ]);

  return (
    <div className={styles.mainContainer}>
      {isLoggedIn && (
        <>
          <div className={styles.serviceHeaderContainer}>
            <h1>Complete your service details</h1>
            <p>
              Please fill out all missing fields marked with an ‘
              <span className={styles.required}>*</span>’
            </p>
          </div>
        </>
      )}
      <div className={styles.mainCard}>
        <div className={styles.cardContent}>
          <h1 className={styles.serviceHeaderText}>Operating Hours</h1>

          <text className={styles.descriptionText}>
            Please complete the below fields by providing the daily operating
            hours for your service.
          </text>

          <div className={`${styles.serviceOperatingTimes}`}>
            <div
              className={`${styles.headerContainer} pl-8 pr-5 py-3 flex justify-between items-center`}
            >
              <div className={`${styles.headerText} w-4/5 `}>
                Service Operating Times
              </div>
              <div
                onClick={() => {
                  setCareTable(false);
                  setVacayTable(false);
                  if (serviceTable) {
                    setServiceTable(false);
                  } else {
                    setServiceTable(true);
                  }
                }}
                style={{
                  background: serviceTable ? "#8BC334" : "#ffffff",
                  color: serviceTable ? "#ffffff" : "#222222",
                  border: !serviceTable ? "1px solid #E4E4E4" : "none",
                }}
                className={`${styles.toggleIcon} w-1/5 cursor-pointer flex justify-center items-center rounded-full overflow-hidden`}
              >
                {serviceTable ? <FaAngleUp /> : <FaAngleDown />}
              </div>
            </div>
            <animated.div
              style={serviceSpring}
              className={`px-8 pt-8 ${styles.tableContainer}`}
            >
              <table className={`${styles.tableOperatingTimes}  w-full`}>
                <thead>
                  <tr>
                    <th>Day</th>
                    <th>Opening</th>
                    <th>Closing</th>
                    <th>Open 24 hours</th>
                  </tr>
                </thead>
                <tbody>
                  {days.map((day) => {
                    return (
                      <tr>
                        <td className={`${styles.dayName} py-3 `}>
                          <span>
                            {day}
                            <span className={`text-red-400`}>*</span>
                          </span>
                        </td>
                        <td className={`timePicker`}>
                          <TimePicker
                            allowClear={false}
                            use12Hours
                            disabled={
                              !isEmpty(serviceDetailsState.input.operatingHours)
                                ? !serviceDetailsState.input.operatingHours.find(
                                    (item) => item.day === day
                                  ).working
                                : !initialHours.find((item) => item.day === day)
                                    .working
                            }
                            showNow={false}
                            defaultValue={
                              !isEmpty(serviceDetailsState.input.operatingHours)
                                ? moment(
                                    serviceDetailsState.input.operatingHours.find(
                                      (item) => item.day === day
                                    ).openTime,
                                    "HH:mm A"
                                  )
                                : moment(
                                    initialHours.find(
                                      (item) => item.day === day
                                    ).openTime,
                                    "HH:mm A"
                                  )
                            }
                            format="h:mm A"
                            className={`${styles.timePicker} py-3`}
                            onChange={(time, timeString) => {
                              onSelectTime(day, "openTime", timeString);
                            }}
                          />
                        </td>
                        <td className={`timePicker`}>
                          <TimePicker
                            className={`${styles.timePicker} py-3`}
                            allowClear={false}
                            use12Hours
                            disabled={
                              !isEmpty(serviceDetailsState.input.operatingHours)
                                ? !serviceDetailsState.input.operatingHours.find(
                                    (item) => item.day === day
                                  ).working
                                : !initialHours.find((item) => item.day === day)
                                    .working
                            }
                            showNow={false}
                            defaultValue={
                              !isEmpty(serviceDetailsState.input.operatingHours)
                                ? moment(
                                    serviceDetailsState.input.operatingHours.find(
                                      (item) => item.day === day
                                    ).closeTime,
                                    "HH:mm A"
                                  )
                                : moment(
                                    initialHours.find(
                                      (item) => item.day === day
                                    ).closeTime,
                                    "HH:mm A"
                                  )
                            }
                            format="h:mm A"
                            onChange={(time, timeString) => {
                              onSelectTime(day, "closeTime", timeString);
                            }}
                          />
                        </td>
                        <td className={`switch-op-times py-3`}>
                          <Switch
                            className={`${styles.switch}`}
                            checked={
                              !isEmpty(serviceDetailsState.input.operatingHours)
                                ? !serviceDetailsState.input.operatingHours.find(
                                    (item) => item.day === day
                                  ).working
                                : !initialHours.find((item) => item.day === day)
                                    .working
                            }
                            onChange={(checked) => onToggle(day, checked)}
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </animated.div>
          </div>

          <div className={`${styles.serviceOperatingTimes} mt-10`}>
            <div
              className={`${styles.headerContainer} pl-8 pr-5 py-3 flex justify-between items-center`}
            >
              <div className={`${styles.headerText} w-4/5`}>
                Outside School Hours Care Operating Times
              </div>
              <div
                onClick={() => {
                  setServiceTable(false);
                  setVacayTable(false);
                  if (careTable) {
                    setCareTable(false);
                  } else {
                    setCareTable(true);
                  }
                }}
                style={{
                  background: careTable ? "#8BC334" : "#ffffff",
                  color: careTable ? "#ffffff" : "#222222",
                  border: !careTable ? "1px solid #E4E4E4" : "none",
                }}
                className={`${styles.toggleIcon} w-1/5 cursor-pointer flex justify-center items-center rounded-full`}
              >
                {careTable ? <FaAngleUp /> : <FaAngleDown />}
              </div>
            </div>

            <animated.div
              style={careSpring}
              className={`px-8 pt-8 ${styles.tableContainer}`}
            >
              <table className={`${styles.tableCareOperatingTimes}  w-full`}>
                <thead>
                  <tr>
                    <th>Day</th>
                    <th
                      className={`${
                        window.innerWidth <= 920
                          ? enableSep
                            ? ""
                            : "hidden"
                          : ""
                      }`}
                    >
                      Session 1 Start
                    </th>
                    <th
                      className={`${
                        window.innerWidth <= 920
                          ? enableSep
                            ? ""
                            : "hidden"
                          : ""
                      }`}
                    >
                      Session 1 Finish
                    </th>
                    {window.innerWidth <= 920 && (
                      <th
                        onClick={() => setEnableSep(!enableSep)}
                        className={`cursor-pointer pl-4`}
                      >
                        {!enableSep ? (
                          <FaAngleLeft className={`text-3xl font-thin`} />
                        ) : (
                          <FaAngleRight className={`text-3xl font-thin`} />
                        )}
                      </th>
                    )}
                    <th
                      className={`${
                        window.innerWidth <= 920
                          ? !enableSep
                            ? ""
                            : "hidden"
                          : ""
                      }`}
                    >
                      Session 2 Start
                    </th>
                    <th
                      className={`${
                        window.innerWidth <= 920
                          ? !enableSep
                            ? ""
                            : "hidden"
                          : ""
                      }`}
                    >
                      Session 2 Finish
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {days.map((day) => {
                    return (
                      <tr>
                        <td className={`${styles.dayName} py-3 `}>
                          <span>
                            {day}
                            {/* <span className={`text-red-400`}>*</span> */}
                          </span>
                        </td>

                        <td
                          className={`timePicker  ${
                            window.innerWidth <= 920
                              ? enableSep
                                ? ""
                                : "hidden"
                              : ""
                          }`}
                        >
                          <TimePicker
                            className={`${styles.timePicker1} py-3`}
                            allowClear={false}
                            use12Hours
                            showNow={false}
                            defaultValue={
                              !isEmpty(
                                serviceDetailsState.input.outsideSchoolHours
                              )
                                ? moment(
                                    serviceDetailsState.input.outsideSchoolHours.find(
                                      (item) => item.day === day
                                    ).session1Start,
                                    "HH:mm A"
                                  )
                                : moment(
                                    initialOutsideSchoolHours.find(
                                      (item) => item.day === day
                                    ).session1Start,
                                    "HH:mm A"
                                  )
                            }
                            format="h:mm A"
                            onChange={(time, timeString) => {
                              onSelectTimeOutsideSchoolHours(
                                day,
                                "session1Start",
                                timeString
                              );
                            }}
                          />
                        </td>

                        <td
                          className={`timePicker  ${
                            window.innerWidth <= 920
                              ? enableSep
                                ? ""
                                : "hidden"
                              : ""
                          }`}
                        >
                          <TimePicker
                            className={`${styles.timePicker1} py-3`}
                            allowClear={false}
                            use12Hours
                            showNow={false}
                            defaultValue={
                              !isEmpty(
                                serviceDetailsState.input.outsideSchoolHours
                              )
                                ? moment(
                                    serviceDetailsState.input.outsideSchoolHours.find(
                                      (item) => item.day === day
                                    ).session1Finish,
                                    "HH:mm A"
                                  )
                                : moment(
                                    initialOutsideSchoolHours.find(
                                      (item) => item.day === day
                                    ).session1Finish,
                                    "HH:mm A"
                                  )
                            }
                            format="h:mm A"
                            onChange={(time, timeString) => {
                              onSelectTimeOutsideSchoolHours(
                                day,
                                "session1Finish",
                                timeString
                              );
                            }}
                          />
                        </td>

                        {window.innerWidth <= 920 && (
                          <td
                            className={`flex justify-center items-center`}
                          ></td>
                        )}

                        <td
                          className={`timePicker  ${
                            window.innerWidth <= 920
                              ? !enableSep
                                ? ""
                                : "hidden"
                              : ""
                          }`}
                        >
                          <TimePicker
                            className={`${styles.timePicker1} py-3`}
                            allowClear={false}
                            use12Hours
                            showNow={false}
                            defaultValue={
                              !isEmpty(
                                serviceDetailsState.input.outsideSchoolHours
                              )
                                ? moment(
                                    serviceDetailsState.input.outsideSchoolHours.find(
                                      (item) => item.day === day
                                    ).session2Start,
                                    "HH:mm A"
                                  )
                                : moment(
                                    initialOutsideSchoolHours.find(
                                      (item) => item.day === day
                                    ).session2Start,
                                    "HH:mm A"
                                  )
                            }
                            format="h:mm A"
                            onChange={(time, timeString) => {
                              onSelectTimeOutsideSchoolHours(
                                day,
                                "session2Start",
                                timeString
                              );
                            }}
                          />
                        </td>

                        <td
                          className={`timePicker  ${
                            window.innerWidth <= 920
                              ? !enableSep
                                ? ""
                                : "hidden"
                              : ""
                          }`}
                        >
                          <TimePicker
                            className={`${styles.timePicker1} py-3`}
                            allowClear={false}
                            use12Hours
                            showNow={false}
                            defaultValue={
                              !isEmpty(
                                serviceDetailsState.input.outsideSchoolHours
                              )
                                ? moment(
                                    serviceDetailsState.input.outsideSchoolHours.find(
                                      (item) => item.day === day
                                    ).session2Finish,
                                    "HH:mm A"
                                  )
                                : moment(
                                    initialOutsideSchoolHours.find(
                                      (item) => item.day === day
                                    ).session2Finish,
                                    "HH:mm A"
                                  )
                            }
                            format="h:mm A"
                            onChange={(time, timeString) => {
                              onSelectTimeOutsideSchoolHours(
                                day,
                                "session2Finish",
                                timeString
                              );
                            }}
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </animated.div>
          </div>

          <div className={`${styles.serviceOperatingTimes} mt-10`}>
            <div
              className={`${styles.headerContainer} pl-8 pr-5 py-3 flex justify-between items-center`}
            >
              <div className={`${styles.headerText} w-4/5`}>
                Vacation Care Operating Times
              </div>
              <div
                onClick={() => {
                  setServiceTable(false);
                  setCareTable(false);
                  if (vacayTable) {
                    setVacayTable(false);
                  } else {
                    setVacayTable(true);
                  }
                }}
                style={{
                  background: vacayTable ? "#8BC334" : "#ffffff",
                  color: vacayTable ? "#ffffff" : "#222222",
                  border: !vacayTable ? "1px solid #E4E4E4" : "none",
                }}
                className={`${styles.toggleIcon} w-1/5 cursor-pointer flex justify-center items-center rounded-full`}
              >
                {vacayTable ? <FaAngleUp /> : <FaAngleDown />}
              </div>
            </div>
            <animated.div
              style={vacaySpring}
              className={`px-8 pt-8 ${styles.tableContainer}`}
            >
              <table className={`${styles.tableOperatingTimes}  w-full`}>
                <thead>
                  <tr>
                    <th>Day</th>
                    <th>Opening</th>
                    <th>Closing</th>
                    <th>Open 24 hours</th>
                  </tr>
                </thead>
                <tbody>
                  {days.map((day) => {
                    return (
                      <tr>
                        <td className={`${styles.dayName} py-3 `}>
                          <span>
                            {day}
                            {/* <span className={`text-red-400`}>*</span> */}
                          </span>
                        </td>
                        <td className={`timePicker`}>
                          <TimePicker
                            allowClear={false}
                            use12Hours
                            disabled={
                              !isEmpty(
                                serviceDetailsState.input.vacationCareHours
                              )
                                ? !serviceDetailsState.input.vacationCareHours.find(
                                    (item) => item.day === day
                                  ).open24Hours
                                : !initialVacationCareHours.find(
                                    (item) => item.day === day
                                  ).open24Hours
                            }
                            showNow={false}
                            defaultValue={
                              !isEmpty(
                                serviceDetailsState.input.vacationCareHours
                              )
                                ? moment(
                                    serviceDetailsState.input.vacationCareHours.find(
                                      (item) => item.day === day
                                    ).openTime,
                                    "HH:mm A"
                                  )
                                : moment(
                                    initialVacationCareHours.find(
                                      (item) => item.day === day
                                    ).openTime,
                                    "HH:mm A"
                                  )
                            }
                            format="h:mm A"
                            className={`${styles.timePicker} py-3`}
                            onChange={(time, timeString) => {
                              onSelectTimeVacationCareHours(
                                day,
                                "openTime",
                                timeString
                              );
                            }}
                          />
                        </td>
                        <td className={`timePicker`}>
                          <TimePicker
                            allowClear={false}
                            use12Hours
                            disabled={
                              !isEmpty(
                                serviceDetailsState.input.vacationCareHours
                              )
                                ? !serviceDetailsState.input.vacationCareHours.find(
                                    (item) => item.day === day
                                  ).open24Hours
                                : !initialVacationCareHours.find(
                                    (item) => item.day === day
                                  ).open24Hours
                            }
                            showNow={false}
                            defaultValue={
                              !isEmpty(
                                serviceDetailsState.input.vacationCareHours
                              )
                                ? moment(
                                    serviceDetailsState.input.vacationCareHours.find(
                                      (item) => item.day === day
                                    ).closeTime,
                                    "HH:mm A"
                                  )
                                : moment(
                                    initialVacationCareHours.find(
                                      (item) => item.day === day
                                    ).closeTime,
                                    "HH:mm A"
                                  )
                            }
                            format="h:mm A"
                            className={`${styles.timePicker} py-3`}
                            onChange={(time, timeString) => {
                              onSelectTimeVacationCareHours(
                                day,
                                "closeTime",
                                timeString
                              );
                            }}
                          />
                        </td>
                        <td className={`switch-op-times py-3`}>
                          <Switch
                            className={`${styles.switch}`}
                            checked={
                              !isEmpty(
                                serviceDetailsState.input.vacationCareHours
                              )
                                ? !serviceDetailsState.input.vacationCareHours.find(
                                    (item) => item.day === day
                                  ).open24Hours
                                : !initialVacationCareHours.find(
                                    (item) => item.day === day
                                  ).open24Hours
                            }
                            onChange={(checked) =>
                              onToggleVacationCareHours(day, checked)
                            }
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </animated.div>
          </div>

          <div className={styles.buttonGroup}>
            <BlueButton // buttonText="Save step"
              buttonText={
                saveLoading ? (
                  <div className={`flex justify-center items-center`}>
                    <FaSpinner className={`animate-spin`} />
                    <span className={`block pl-2`}>Save step</span>
                  </div>
                ) : (
                  <> Save step</>
                )
              }
              onClick={onSave}
            />

            <button
              className={styles.buttonNextStep}
              onClick={() =>
                history.push(
                  `${match.path
                    .split("/")
                    .slice(0, match.path.split("/").length - 1)
                    .join("/")}/8`
                )
              }
            >
              Next step
            </button>
          </div>

          <div className={styles.buttonStep}>
            <button
              className={styles.buttonStepBack}
              onClick={() =>
                history.push(
                  `${match.path
                    .split("/")
                    .slice(0, match.path.split("/").length - 1)
                    .join("/")}/6`
                )
              }
            >
              <Icon name="angle left" />
              Back a step
            </button>

            <button
              className={styles.buttonFinishLater}
              onClick={onSkipServiceDetails}
            >
              Finish Later
              <Icon name="angle double right" />
            </button>
          </div>
        </div>

        <div className={styles.stepsColumn}>
          <ServiceSteps route={match.path} />
        </div>
      </div>
    </div>
  );
}

OperationalHours.propTypes = {
  onNextStep: PropTypes.func,
  onSkipStep: PropTypes.func,
  onSaveStep: PropTypes.func,
  onSkipServiceDetails: PropTypes.func,
};

export default OperationalHours;
