import * as React from "react";

import PropTypes from "prop-types";
import { Icon, Button } from "semantic-ui-react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { FaArrowUp, FaDownload, FaSpinner } from "react-icons/fa";

import styles from "./CenterBranding.module.css";

import BlueButton from "../../../components/BlueButton";
import InputComponent from "../../../components/InputComponent";
import DeleteServiceLogoModal from "../../../components/Modals/DeleteServiceLogoModal/DeleteServiceLogoModal";
import { ServiceDetailsContext } from "../../../context/ServiceDetailsContext";
import { uploadImage, getLogo, deleteAttachment } from "../../../api/requests";
import { useStoreState } from "../../../hooks";
import moment from "moment";
import { isEmpty } from "lodash";

import { ServiceSteps } from "../../../components/Steps";

const { useContext, useRef, useState, useEffect } = React;

const stepsDone = [
  // {
  // id: 0,
  // stepsName: 'Welcome',
  // },
  {
    id: 1,
    stepsName: "Location",
  },
  {
    id: 2,
    stepsName: "Postal Address",
  },
  {
    id: 3,
    stepsName: "Contact Details",
  },
  {
    id: 4,
    stepsName: "Providers",
  },
  {
    id: 5,
    stepsName: "Supervisor",
  },
  {
    id: 6,
    stepsName: "Leader",
  },
  {
    id: 7,
    stepsName: "Operating hours",
  },
  {
    id: 8,
    stepsName: "Additional info",
  },
  {
    id: 9,
    stepsName: "Educators",
  },
  {
    id: 10,
    stepsName: "QIP submission",
  },
  {
    id: 11,
    stepsName: "Philosophy",
  },
];

function CenterBranding({ onUpdateStep, onFinish }) {
  const history = useHistory();
  const match = useRouteMatch();

  const isLoggedIn = useStoreState((state) => state.authStore.isLoggedIn);
  const signupCreds = useStoreState((state) => state.authStore.signUpCreds);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [imageResource, setImageResource] = useState({});

  const { serviceDetailsState, changeInputDispatch, stepsIndicator } =
    useContext(ServiceDetailsContext);

  const inputRef = useRef(null);

  const [label, setLabel] = useState("");
  const [image, setImage] = useState("");
  const [uploadLoading, setLoading] = useState(false);

  const onChangeLabel = (name, value) => {
    setLabel(value);
  };

  const onUploadPrompt = () => {
    // if (!label) {
    // alert('Please type in your image label first.');
    // } else {
    inputRef.current.click();
    // }
  };

  const removeLogo = async () => {
    try {
      await deleteAttachment(serviceDetailsState.input.logo.id);

      await fetchLogo();
      await onUpdateStep();
    } catch (error) {
      console.log(error);
    }
  };

  const fetchLogo = async () => {
    setLoading(true);
    try {
      const logoResource = await getLogo(
        isLoggedIn ? serviceDetailsState.input.id : signupCreds.centerId,
        "logo"
      );

      // setImageResource(logoResource);

      await changeInputDispatch("logo", logoResource, !isEmpty(logoResource));

      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const onUploadImage = async (image_string) => {
    setLoading(true);

    const imagePayload = {
      label,
      centerId: "",
      title: "logo",
      entity: "centers",
      name: "center_logo",
      data: image_string,
    };
    imagePayload.centerId = isLoggedIn
      ? serviceDetailsState.input.id
      : signupCreds.centerId;

    await uploadImage(imagePayload);

    await fetchLogo();

    await onUpdateStep();
    //   await fetchLogo();

    setLoading(false);
  };

  const onSelectImage = (event) => {
    const files = event.target.files;
    const reader = new FileReader();

    reader.readAsDataURL(files[0]);

    reader.onload = (e) => {
      setImage(e.target.result);
      onUploadImage(e.target.result);
    };
  };

  return (
    <>
      {showDeleteModal && (
        <DeleteServiceLogoModal
          visibility={showDeleteModal}
          onCloseModal={() => {
            setShowDeleteModal(false);
          }}
          onRemoveLogo={async () => {
            await removeLogo();
            setShowDeleteModal(false);
          }}
        />
      )}
      <div className={styles.mainContainer}>
        {isLoggedIn && (
          <>
            <div className={styles.headerContainer}>
              <h1>Complete your service details</h1>
              <p>
                Please fill out all missing fields marked with an ‘
                <span className={styles.required}>*</span>’
              </p>
            </div>
          </>
        )}

        {uploadLoading ? (
          <div
            className={`w-full h-screen flex flex-col justify-center items-center`}
          >
            <FaSpinner
              className={`animate-spin text-qipGreen font-bold text-6xl`}
            />
            <span className={`block`}> Loading data...</span>
          </div>
        ) : (
          <div className={styles.mainCard}>
            <div className={styles.cardContent}>
              <h1 className={styles.headerText}>Upload service logo</h1>

              <text className={styles.descriptionText}>
                Complete the service details by uploading the service logo. All
                image files can be uploaded. Minimum resolution 200x200 pixels.
              </text>
              {!isEmpty(serviceDetailsState.input.logo) ? (
                <>
                  <div className={styles.selectedImageContainer}>
                    <div className={styles.uploadedLeftContainer}>
                      <div className={styles.uploadedImageContainer}>
                        <img
                          className={styles.uploadedLogo}
                          alt=""
                          src={serviceDetailsState.input.logo.url}
                        />
                      </div>
                      <div className={styles.uploadedDateContainer}>
                        <text className={styles.uploadedDate}>
                          {`Uploaded on ${moment
                            .utc(
                              serviceDetailsState.input.logo.created_at,
                              "YYYY-MM-DD hh:mm:ss"
                            )
                            .local()
                            .format("DD/MM/YYYY")}`}

                          {/* {Uploaded on 12/03/2020} */}
                        </text>
                      </div>
                    </div>

                    <div className={styles.actionButtonGroup}>
                      <div className={styles.actionButton}>
                        <Button
                          className={styles.reportRemoveButton}
                          icon="close"
                          size="massive"
                          onClick={() => {
                            setShowDeleteModal(true);
                          }}
                        />

                        <text className={styles.actionButtonText}>Remove</text>
                      </div>

                      <div className={styles.actionButton}>
                        <Button
                          className={styles.reportTasksButton}
                          icon="download"
                          onClick={() =>
                            (window.location.href = `${process.env.REACT_APP_API_URL}/api/uploads/${serviceDetailsState.input.logo.id}`)
                          }
                        />
                        {/* <a
                          className={`${styles.reportTasksButton} flex justify-center items-center cursor-pointer rounded hover:text-white`}
                          href={logo}
                          download={imageResource.name}
                        >
                          <FaDownload />
                        </a> */}

                        <text className={`w-full ${styles.actionButtonText}`}>
                          Download
                        </text>
                      </div>
                    </div>
                  </div>
                  <div className={styles.buttonHolders}>
                    <BlueButton buttonText="Finish" onClick={onFinish} />
                  </div>
                </>
              ) : (
                <>
                  {/*<InputComponent
                      className={styles.labelInput}
                      required
                      type="text"
                      fieldName="label"
                      label="Image Label"
                      isInvalid={!label}
                      inputValue={label}
                      setInputValue={onChangeLabel}
                    />*/}

                  <>
                    <input
                      ref={inputRef}
                      type="file"
                      style={{ display: "none" }}
                      onChange={onSelectImage}
                    />
                    <div
                      className={`${styles.uploadArea} flex flex-col justify-center items-center`}
                      onClick={onUploadPrompt}
                    >
                      <FaArrowUp size={64} />
                      <p>Upload logo here</p>
                    </div>
                  </>

                  <div className={styles.buttonHolders}>
                    <BlueButton
                      // buttonText="Save uploaded logo"
                      buttonText="Upload Logo"
                      // onClick={() => onUploadImage(image)}
                      onClick={onUploadPrompt}
                    />
                  </div>
                </>
              )}

              <div className={styles.buttonStep}>
                <button
                  className={styles.buttonStepBack}
                  onClick={() =>
                    history.push(
                      `${match.path
                        .split("/")
                        .slice(0, match.path.split("/").length - 1)
                        .join("/")}/11`
                    )
                  }
                >
                  <Icon name="angle left" />
                  Back a step
                </button>

                <button className={styles.buttonFinishLater} onClick={onFinish}>
                  Finish Later
                  <Icon name="angle double right" />
                </button>
              </div>
            </div>

            <div className={styles.stepsColumn}>
              <ServiceSteps route={match.path} />
              {/* {stepsDone.map((steps) => (
              <div
                key={steps.id}
                className={
                  stepsIndicator.find((step) => step.id === steps.id).completed
                    ? `${styles.stepItemContainer} ${styles.stepItemTickContainer}`
                    : `${styles.stepItemContainer} ${styles.stepItemCrossContainer}`
                }
                onClick={() =>
                  history.push(
                    `${match.path
                      .split("/")
                      .slice(0, match.path.split("/").length - 1)
                      .join("/")}/${steps.id}`
                  )
                }
              >
                <div className={styles.stepContainerText}>
                  <div className={styles.stepItemIndicatorContainer}>
                    {stepsIndicator.find((step) => step.id === steps.id)
                      .completed ? (
                      <div className={styles.stepItemFillDone} />
                    ) : (
                      <Icon
                        className={styles.stepItemUnfinished}
                        name="close"
                      />
                    )}
                  </div>
                  {steps.stepsName}
                </div>
              </div>
            ))}

            <div className={styles.currentItemContainer}>
              <div className={styles.stepItemCurrent}>
                <div className={styles.currentItemFill} />
              </div>

              <text className={styles.stepItemCurrentText}>Upload logo</text>
            </div> */}
            </div>
          </div>
        )}
      </div>
    </>
  );
}

CenterBranding.propTypes = {
  onFinish: PropTypes.func,
};

export default CenterBranding;
