import React from "react";
import { Modal } from "semantic-ui-react";
import styles from "./ConfirmRemoveSatModal.module.css";
import { FaExclamation, FaTimes, FaSpinner, FaCopy } from "react-icons/fa";

const { useState, useRef } = React;

const ConfirmRemoveSatModal = ({
  satQuestion,
  visibility,
  onCloseModal,
  onOpenModal,
  onConfirm,
}) => {
  const textRef = useRef(null);
  const [loading, setLoading] = useState(false);

  const onCopytoClipboard = () => {
    const currentText = textRef.current;
    currentText.select();
    document.execCommand("copy");
  };

  return (
    <Modal
      open={visibility}
      onClose={onCloseModal}
      onOpen={onOpenModal}
      size={`small`}
      closeOnDimmerClick={false}
    >
      <div
        className={`${styles.modalContainer}  flex flex-col items-center py-16`}
      >
        <div className={`w-full flex flex-col items-center px-14`}>
          <div
            onClick={onCloseModal}
            className={`${styles.topCloseButton} absolute top-0 right-0 flex justify-center items-center `}
          >
            <FaTimes />
          </div>
          <div
            className={`${styles.warningIcon} flex justify-center items-center rounded-full `}
          >
            <FaExclamation />
          </div>
          <text className={`${styles.headerText} my-8`}>
            This question is assigned to SAT!
          </text>
          <text className={`${styles.headerSub} py-2 px-3 mb-4`}>
            Your description will be deleted
          </text>
          <textarea
            ref={textRef}
            readOnly
            className={`${styles.satDescription} w-full mb-8 p-4`}
            value={satQuestion.satData.sat_description}
          />
          <button
            onClick={() => onCopytoClipboard()}
            className={`${styles.copyButton} flex justify-center items-center`}
          >
            <FaCopy /> <span className={`ml-2`}>Copy to clipboard</span>
          </button>
        </div>
        <hr className={`${styles.borderLine} my-8`} />
        <div className={`w-full flex flex-col items-center px-14`}>
          <text className={`${styles.footerText}`}>Choose what to do</text>
          <div className={`flex justify-center items-center mt-8`}>
            <button
              onClick={onCloseModal}
              className={`${styles.closeButton} mr-2`}
            >{`Keep & Close`}</button>
            <button
              onClick={() => {
                setLoading(true);
                onConfirm();
              }}
              className={`${styles.confirmButton} ml-2`}
            >
              {" "}
              {loading ? (
                <div className={`flex justify-center items-center`}>
                  {" "}
                  <FaSpinner className={`animate-spin`} />{" "}
                  <span className={`ml-2`}>Please wait...</span>
                </div>
              ) : (
                <span>{`Continue to Remove`}</span>
              )}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmRemoveSatModal;
