import * as React from "react";

import { Icon } from "semantic-ui-react";
import { useHistory } from "react-router-dom";
import {
  FaRegUser,
  FaChartBar,
  FaRegHeart,
  FaFlagCheckered,
  FaEnvelope,
  FaRetweet,
  FaBook,
  FaCog,
  FaUsers,
  FaRegCommentDots,
  FaQuestion,
  FaUserAlt,
  FaHeart,
} from "react-icons/fa";

import styles from "./Header.module.css";
import logoImg from "../../static/images/logo.png";
import { useStoreState, useStoreActions } from "../../hooks";
import LogoutModal from "../Modals/LogoutModal";
import NewServicesModal from "../Modals/NewServicesModal";
import ChangeServiceModal from "../Modals/ChangeServiceModal";
import { FaSignOutAlt } from "react-icons/fa";
import { isEmpty } from "lodash";
import { Badge } from "antd";
import { fetchUserCenters } from "../../api/requests";
// import HelpModal from '../Modals/HelpModal'

const { useState, useCallback } = React;

export default function Header() {
  const history = useHistory();

  const headerMenu = useStoreState((state) => state.appStore.headerMenu);

  const isLoggedIn = useStoreState((state) => state.authStore.isLoggedIn);
  const totalProgress = useStoreState((state) => state.nqaStore.totalProgress);

  const userSelectedService = useStoreState(
    (state) => state.authStore.selectedService
  );

  const unreadMessagesCount = useStoreState(
    (state) => state.appStore.unreadMessagesCount
  );

  const [logoutModalVisibility, setLogoutModalVisibility] = useState(false);
  const [servicesModalVisibility, setChangeServicesModalVisibility] =
    useState(false);

  const [newServicesModalVisibility, setNewServicesModalVisibility] =
    useState(false);
  // const [helpModalVisibility, setHelpModalVisibility] = useState(false);

  const [navState, setNavState] = useState("nqa");

  const remainingTrial = useStoreState(
    (state) => state.userStore.remainingTrial
  );

  const disableNavbar = useStoreState((state) => state.appStore.disableMenu);

  const services = useStoreState((state) => state.authStore.services);

  const activeServicesCount = useStoreState(
    (state) => state.authStore.activeServicesCount
  );

  const openNewService = () => {
    setChangeServicesModalVisibility(false);
    setNewServicesModalVisibility(true);
  };

  const repeatFetch = useCallback(() => {
    if (!isEmpty(userSelectedService)) {
      // localStorage.removeItem("centerRefetch");
      return <>{userSelectedService.name} </>;
    } else {
      if (localStorage.getItem("inteli_token") !== null) {
        // location.reload();
      } else {
        location.reload();
      }
    }
  }, []);

  return (
    <div className={styles.headerContainer}>
      {newServicesModalVisibility && (
        <NewServicesModal
          visibility={newServicesModalVisibility}
          onOpenModal={() => setNewServicesModalVisibility(true)}
          onCloseModal={() => setNewServicesModalVisibility(false)}
        />
      )}

      {servicesModalVisibility && (
        <ChangeServiceModal
          visibility={servicesModalVisibility}
          onOpenModal={() => setChangeServicesModalVisibility(true)}
          onCloseModal={() => setChangeServicesModalVisibility(false)}
          onCloseChangeService={openNewService}
        />
      )}
      {/*{helpModalVisibility
        && (
          <HelpModal
            visibility={helpModalVisibility}
            onOpenModal={() => setHelpModalVisibility(true)}
            onCloseModal={() => setHelpModalVisibility(false)}
          />
        )
      }*/}
      {logoutModalVisibility && (
        <LogoutModal
          visibility={logoutModalVisibility}
          onOpenModal={() => setLogoutModalVisibility(true)}
          onCloseModal={() => setLogoutModalVisibility(false)}
        />
      )}

      <div className={styles.mobileHeader}>
        <div className={styles.headerRow}>
          <div className={styles.headerTop}>
            <div className={styles.headerLeft}>
              {isLoggedIn && (disableNavbar ? disableNavbar : headerMenu) ? (
                <div className={styles.headerCenter}>
                  <button
                    className={styles.serviceNavigationIconContainer}
                    onClick={() => setChangeServicesModalVisibility(true)}
                  >
                    <FaRetweet size={35} />
                    <text className={styles.serviceNavigationText}>
                      {!isEmpty(userSelectedService)
                        ? userSelectedService.name
                        : ""}
                    </text>
                  </button>
                </div>
              ) : (
                <div className={styles.headerCenterEmpty} />
              )}
            </div>

            <div className={styles.headerRight}>
              {isLoggedIn && (disableNavbar ? disableNavbar : headerMenu) && (
                <div
                  style={{ opacity: disableNavbar ? 0.75 : 1 }}
                  className={styles.detailsFinishedWrapper}
                >
                  <div className={styles.detailsFinishedContent}>
                    <div className={styles.detailsFinishedPercentageContainer}>
                      <text className={styles.detailsFinishedPercentage}>
                        {/* {Math.ceil(totalProgress)
                          ? Math.ceil(totalProgress)
                          : "0"} */}
                        {Number(totalProgress) !== 99.94462901439645
                          ? Math.floor(totalProgress)
                            ? Math.floor(totalProgress)
                            : "0"
                          : "100"}
                      </text>

                      <text className={styles.detailsFinishedPercentSymbol}>
                        %
                      </text>
                    </div>

                    <div className={styles.detailsFinishedTextContainer}>
                      <text className={styles.detailsFinishedText}>
                        QIP Finished
                      </text>

                      <div className={styles.progressBar}>
                        <div
                          className={styles.progressBarInside}
                          // style={{ width: `${Math.ceil(totalProgress)}%` }}
                          style={{
                            width: `${
                              Number(totalProgress) !== 94462901439645
                                ? Math.floor(totalProgress)
                                : 100
                            }%`,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {isLoggedIn && (disableNavbar ? disableNavbar : headerMenu) && (
                <>
                  <div className={styles.helpContainer}>
                    <button
                      className={styles.helpButton}
                      // onClick={() => setHelpModalVisibility(true)}
                      // onClick={() =>
                      //   (window.location.href =
                      //     "https://holisticqip.com.au/help/")
                      // }
                      onClick={() =>
                        window.open(
                          "https://holisticmanagement.net.au/get-help/",
                          "_blank"
                        )
                      }
                    >
                      Help
                    </button>
                  </div>
                </>
              )}
              {isLoggedIn && (disableNavbar ? disableNavbar : headerMenu) && (
                <>
                  <div
                    className={styles.logoutContainer}
                    onClick={() => setLogoutModalVisibility(true)}
                  >
                    <Icon className={styles.logoutIcon} name="sign out" />
                  </div>
                </>
              )}
            </div>
          </div>

          <div className={styles.headerBottom}>
            <button
              className={styles.logoContainer}
              onClick={() => history.push("/")}
            >
              <img src={logoImg} width="250" height="65" alt="QIP logo" />
            </button>

            {isLoggedIn && (disableNavbar ? disableNavbar : headerMenu) ? (
              <div
                style={{
                  opacity: disableNavbar ? 0.75 : 1,
                }}
                className={`${styles.headerCenter} ${
                  disableNavbar ? styles.disableNavbar : ""
                }`}
              >
                <button
                  className={
                    navState === "nqa"
                      ? `${styles.navigationIconContainer} ${styles.active}`
                      : styles.navigationIconContainer
                  }
                  // onClick={() => history.push('/qip/nqa-dashboard')}
                  onClick={() => {
                    history.push("/qip/nqa-dashboard");
                    setNavState("nqa");
                    window.scrollTo({ top: 0 });
                  }}
                >
                  <FaBook fontSize="30" />
                  <text className={styles.navigationIconText}>NQA&apos;s</text>
                </button>

                <button
                  className={
                    navState === "reports"
                      ? `${styles.navigationIconContainer} ${styles.active}`
                      : styles.navigationIconContainer
                  }
                  // onClick={() => history.push('/qip/reports')}
                  onClick={() => {
                    history.push("/qip/reports");
                    setNavState("reports");
                    window.scrollTo({ top: 0 });
                  }}
                >
                  <FaChartBar fontSize="30" />
                  <text className={styles.navigationIconText}>Reports</text>
                </button>

                <button
                  className={
                    navState === "tasks"
                      ? `${styles.navigationIconContainer} ${styles.active}`
                      : styles.navigationIconContainer
                  }
                  // onClick={() => history.push('/qip/tasks')}
                  onClick={() => {
                    history.push("/qip/tasks");
                    setNavState("tasks");
                    window.scrollTo({ top: 0 });
                  }}
                >
                  <FaFlagCheckered fontSize="30" />
                  <text className={styles.navigationIconText}>Tasks</text>
                </button>

                <button
                  // className={styles.navigationIconContainer}
                  className={
                    navState === "resource"
                      ? `${styles.navigationIconContainer} ${styles.active}`
                      : styles.navigationIconContainer
                  }
                  onClick={() => {
                    history.push("/qip/user-resource");
                    setNavState("resource");
                    window.scrollTo({ top: 0 });
                  }}
                  // onClick={() =>
                  //   (window.location.href =
                  //     "https://holisticqip.com.au/user-resource/")
                  // }
                >
                  <FaRegHeart fontSize="30" />
                  <text className={styles.navigationIconText}>Resources</text>
                </button>

                <button
                  className={
                    navState === "messages"
                      ? `${styles.navigationIconContainer} ${styles.active}`
                      : styles.navigationIconContainer
                  }
                  // onClick={() => history.push('/qip/messages')}
                  onClick={() => {
                    
                    history.push("/qip/messages", {
                      tab: 1,
                    });
                    setNavState("messages");
                    window.scrollTo({ top: 0 });
                  }}
                >
                  <Badge count={unreadMessagesCount}>
                    <FaEnvelope style={{ color: `white` }} fontSize="30" />
                  </Badge>
                  <text className={styles.navigationIconText}>Messages</text>
                </button>

                <button
                  className={
                    navState === "user-management"
                      ? `${styles.navigationIconContainer} ${styles.active}`
                      : styles.navigationIconContainer
                  }
                  // onClick={() => history.push('/qip/user-management')}
                  onClick={() => {
                    history.push("/qip/user-management");
                    setNavState("user-management");
                    window.scrollTo({ top: 0 });
                  }}
                >
                  <FaRegUser fontSize="30" />
                  <text className={styles.navigationIconText}>Account</text>
                </button>
              </div>
            ) : (
              <div className={styles.serviceDetailsWrapper}>
                <div className={styles.detailsFinishedContent}>
                  <div className={styles.detailsFinishedPercentageContainer}>
                    <text className={styles.detailsFinishedPercentage}>30</text>

                    <text className={styles.detailsFinishedPercentSymbol}>
                      %
                    </text>
                  </div>

                  <text className={styles.detailsFinishedText}>
                    Of Your service details finished
                  </text>
                </div>

                <div className={styles.progressBar}>
                  <div
                    className={styles.progressBarInside}
                    style={{ width: `30%` }}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className={styles.desktopHeader}>
        <div className={styles.headerRow}>
          <button
            className={styles.logoContainer}
            onClick={() => history.push("/")}
          >
            <img src={logoImg} width="250" height="65" alt="QIP logo" />
          </button>

          {isLoggedIn && (disableNavbar ? disableNavbar : headerMenu) ? (
            <div
              className={`${styles.headerCenter} ${
                disableNavbar ? styles.disableNavbar : ""
              }`}
            >
              <button
                style={{ opacity: disableNavbar ? 0.75 : 1 }}
                className={
                  navState === "nqa"
                    ? `${styles.navigationIconContainer} ${styles.active}`
                    : styles.navigationIconContainer
                }
                // onClick={() => history.push('/qip/nqa-dashboard')}
                onClick={() => {
                  history.push("/qip/nqa-dashboard");
                  setNavState("nqa");
                  window.scrollTo({ top: 0 });
                }}
              >
                <FaBook fontSize="30" />
                <text className={styles.navigationIconText}>NQA&apos;s</text>
              </button>

              <button
                style={{ opacity: disableNavbar ? 0.75 : 1 }}
                className={
                  navState === "reports"
                    ? `${styles.navigationIconContainer} ${styles.active}`
                    : styles.navigationIconContainer
                }
                // onClick={() => history.push('/qip/reports')}
                onClick={() => {
                  history.push("/qip/reports");
                  setNavState("reports");
                  window.scrollTo({ top: 0 });
                }}
              >
                <FaChartBar fontSize="30" />
                <text className={styles.navigationIconText}>Reports</text>
              </button>

              <button
                style={{ opacity: disableNavbar ? 0.75 : 1 }}
                className={
                  navState === "tasks"
                    ? `${styles.navigationIconContainer} ${styles.active}`
                    : styles.navigationIconContainer
                }
                // onClick={() => history.push('/qip/tasks')}
                onClick={() => {
                  history.push("/qip/tasks");
                  setNavState("tasks");
                  window.scrollTo({ top: 0 });
                }}
              >
                <FaFlagCheckered fontSize="30" />
                <text className={styles.navigationIconText}>Tasks</text>
              </button>

              <button
                style={{ opacity: disableNavbar ? 0.75 : 1 }}
                // className={styles.navigationIconContainer}
                className={
                  navState === "resource"
                    ? `${styles.navigationIconContainer} ${styles.active}`
                    : styles.navigationIconContainer
                }
                onClick={() => {
                  history.push("/qip/user-resource");
                  setNavState("resource");
                  window.scrollTo({ top: 0 });
                }}
                // onClick={() =>
                //   (window.location.href =
                //     "https://holisticqip.com.au/user-resource/")
                // }
              >
                <FaRegHeart fontSize="30" />
                <text className={styles.navigationIconText}>Resources</text>
              </button>

              <button
                style={{ opacity: disableNavbar ? 0.75 : 1 }}
                className={
                  navState === "messages"
                    ? `${styles.navigationIconContainer} ${styles.active}`
                    : styles.navigationIconContainer
                }
                // onClick={() => history.push('/qip/messages')}
                onClick={() => {
                  history.push("/qip/messages", {
                    tab: 1,
                  });
                  setNavState("messages");
                  window.scrollTo({ top: 0 });
                }}
              >
                <Badge count={unreadMessagesCount}>
                  <FaEnvelope style={{ color: `white` }} fontSize="30" />
                </Badge>
                <text className={styles.navigationIconText}>Messages</text>
              </button>

              <button
                style={{ opacity: disableNavbar ? 0.75 : 1 }}
                className={
                  navState === "user-management"
                    ? `${styles.navigationIconContainer} ${styles.active}`
                    : styles.navigationIconContainer
                }
                // onClick={() => history.push('/qip/user-management')}
                onClick={() => {
                  history.push("/qip/user-management");
                  setNavState("user-management");
                  window.scrollTo({ top: 0 });
                }}
              >
                <FaRegUser fontSize="30" />
                <text className={styles.navigationIconText}>Account</text>
              </button>

              <button
                style={{ pointerEvents: "auto" }}
                className={styles.serviceNavigationIconContainer}
                onClick={() => setChangeServicesModalVisibility(true)}
              >
                <FaRetweet size={35} />
                <text className={styles.serviceNavigationText}>
                  {!isEmpty(userSelectedService)
                    ? userSelectedService.name
                    : ""}
                </text>
              </button>
            </div>
          ) : (
            <div className={styles.serviceDetailsWrapper}>
              <div className={styles.detailsFinishedContent}>
                <div className={styles.detailsFinishedPercentageContainer}>
                  <text className={styles.detailsFinishedPercentage}>30</text>

                  <text className={styles.detailsFinishedPercentSymbol}>%</text>
                </div>

                <text className={styles.detailsFinishedText}>
                  Of Your service details finished
                </text>
              </div>

              <div className={styles.progressBar}>
                <div
                  className={styles.progressBarInside}
                  style={{ width: `30%` }}
                />
              </div>
            </div>
          )}

          {isLoggedIn && (disableNavbar ? disableNavbar : headerMenu) && (
            <div
              style={{ opacity: disableNavbar ? 0.75 : 1 }}
              className={styles.detailsFinishedWrapper}
            >
              <div className={styles.detailsFinishedContent}>
                <div className={styles.detailsFinishedPercentageContainer}>
                  <text className={styles.detailsFinishedPercentage}>
                    {/* {console.log(`total progress `, totalProgress)} */}
                    {/* {Math.ceil(totalProgress) ? Math.ceil(totalProgress) : "0"} */}
                    {Number(totalProgress) !== 99.94462901439645
                      ? Math.floor(totalProgress)
                        ? Math.floor(totalProgress)
                        : "0"
                      : "100"}
                  </text>

                  <text className={styles.detailsFinishedPercentSymbol}>%</text>
                </div>

                <text className={styles.detailsFinishedText}>QIP Finished</text>
              </div>

              <div className={styles.progressBar}>
                <div
                  className={styles.progressBarInside}
                  // style={{ width: `${Math.ceil(totalProgress)}%` }}
                  style={{
                    width: `${
                      Number(totalProgress) !== 94462901439645
                        ? Math.floor(totalProgress)
                        : 100
                    }%`,
                  }}
                />
              </div>
            </div>
          )}
          {isLoggedIn && (disableNavbar ? disableNavbar : headerMenu) && (
            <>
              <div className={styles.helpContainer}>
                <button
                  className={styles.helpButton}
                  // onClick={() => setHelpModalVisibility(true)}
                  // onClick={() =>
                  //   (window.location.href = "https://holisticqip.com.au/help/")
                  // }
                  onClick={() =>
                    window.open("https://holisticmanagement.net.au/get-help/", "_blank")
                  }
                >
                  Help
                </button>
              </div>
            </>
          )}

          {isLoggedIn && (disableNavbar ? disableNavbar : headerMenu) && (
            <>
              <div
                className={styles.logoutContainer}
                onClick={() => setLogoutModalVisibility(true)}
              >
                <Icon className={styles.logoutIcon} name="sign out" />
                {/* <FaSignOutAlt className={`${styles.logoutIcon} text-4xl`} /> */}
              </div>
            </>
          )}
        </div>
      </div>

      <div className={`${styles.mobileAdminHeader} ${styles.adminHeader}`}>
        <div className={`${styles.headerRow} flex-wrap`}>
          <div
            className={`${styles.headerTop} w-full flex items-center justify-between`}
          >
            <button
              className={styles.logoContainer}
              onClick={() => history.push("/")}
            >
              <img src={logoImg} width="250" height="65" alt="QIP logo" />
            </button>

            <div className={`flex items-center`}>
              {isLoggedIn && (disableNavbar ? disableNavbar : headerMenu) && (
                <>
                  <div className={styles.helpContainer}>
                    <button
                      className={styles.helpButton}
                      onClick={() =>
                    window.open("https://holisticmanagement.net.au/get-help/", "_blank")
                  }
                    >
                      Help
                    </button>
                  </div>
                </>
              )}

              {isLoggedIn && (disableNavbar ? disableNavbar : headerMenu) && (
                <>
                  <div
                    className={styles.logoutContainer}
                    onClick={() => setLogoutModalVisibility(true)}
                  >
                    <Icon className={styles.logoutIcon} name="sign out" />
                  </div>
                </>
              )}
            </div>
          </div>

          <div className={`${styles.headerBottom} w-full`}>
            {isLoggedIn && (disableNavbar ? disableNavbar : headerMenu) && (
              <div
                style={{ opacity: disableNavbar ? 0.75 : 1 }}
                className={`${styles.headerCenter} `}
              >
                <button
                  className={
                    navState === "dashboard"
                      ? `${styles.navigationIconContainer} ${styles.active}`
                      : styles.navigationIconContainer
                  }
                  onClick={() => {
                    history.push("/qip/admin/user-management/user-list");
                    setNavState("dashboard");
                    window.scrollTo({ top: 0 });
                  }}
                >
                  <FaCog fontSize="30" />
                  <text className={styles.navigationIconText}>Dashboard</text>
                </button>

                <button
                  className={
                    navState === "subscribers"
                      ? `${styles.navigationIconContainer} ${styles.active}`
                      : styles.navigationIconContainer
                  }
                  onClick={() => {
                    history.push("/qip/admin/subscribers/summary");
                    setNavState("subscribers");
                    window.scrollTo({ top: 0 });
                  }}
                >
                  <FaUsers fontSize="30" />
                  <text className={styles.navigationIconText}>Subscribers</text>
                </button>

                <button
                  className={
                    navState === "messages"
                      ? `${styles.navigationIconContainer} ${styles.active}`
                      : styles.navigationIconContainer
                  }
                  onClick={() => {
                    history.push("/qip/admin/messages");
                    setNavState("messages");
                    window.scrollTo({ top: 0 });
                  }}
                >
                  <FaEnvelope fontSize="30" />
                  <text className={styles.navigationIconText}>Messages</text>
                </button>

                <button
                  className={
                    navState === "feedback"
                      ? `${styles.navigationIconContainer} ${styles.active}`
                      : styles.navigationIconContainer
                  }
                  onClick={() => {
                    history.push("/qip/admin/request-feedback/list");
                    setNavState("feedback");
                    window.scrollTo({ top: 0 });
                  }}
                >
                  <FaRegCommentDots fontSize="30" />
                  <text className={styles.navigationIconText}>Feedback</text>
                </button>

                <button
                  className={
                    navState === "questions"
                      ? `${styles.navigationIconContainer} ${styles.active}`
                      : styles.navigationIconContainer
                  }
                  onClick={() => {
                    history.push("/qip/admin/questions");
                    setNavState("questions");
                    window.scrollTo({ top: 0 });
                  }}
                >
                  <FaQuestion fontSize="30" />
                  <text className={styles.navigationIconText}>Questions</text>
                </button>

                <button
                  className={
                    navState === "accounts"
                      ? `${styles.navigationIconContainer} ${styles.active}`
                      : styles.navigationIconContainer
                  }
                  onClick={() => {
                    history.push("/qip/user-management");
                    setNavState("accounts");
                    window.scrollTo({ top: 0 });
                  }}
                >
                  <FaUserAlt fontSize="30" />
                  <text className={styles.navigationIconText}>Accounts</text>
                </button>

                <button
                  className={
                    navState === "resources"
                      ? `${styles.navigationIconContainer} ${styles.active}`
                      : styles.navigationIconContainer
                  }
                  onClick={() => {
                    history.push("/qip/admin/resources");
                    setNavState("resources");
                    window.scrollTo({ top: 0 });
                  }}
                >
                  <FaHeart fontSize="30" />
                  <text className={styles.navigationIconText}>Resources</text>
                </button>

                <button
                  className={
                    navState === "tasks"
                      ? `${styles.navigationIconContainer} ${styles.active}`
                      : styles.navigationIconContainer
                  }
                  onClick={() => {
                    history.push("/qip/admin/tasks");
                    setNavState("tasks");
                    window.scrollTo({ top: 0 });
                  }}
                >
                  <FaFlagCheckered fontSize="30" />
                  <text className={styles.navigationIconText}>Tasks</text>
                </button>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className={`${styles.desktopAdminHeader} ${styles.adminHeader}`}>
        <div className={styles.headerRow}>
          <button
            className={styles.logoContainer}
            onClick={() => history.push("/")}
          >
            <img src={logoImg} width="250" height="65" alt="QIP logo" />
          </button>

          {isLoggedIn && (disableNavbar ? disableNavbar : headerMenu) && (
            <div className={styles.headerCenter}>
              <button
                className={
                  navState === "dashboard"
                    ? `${styles.navigationIconContainer} ${styles.active}`
                    : styles.navigationIconContainer
                }
                onClick={() => {
                  history.push("/qip/admin/user-management/user-list");
                  setNavState("dashboard");
                  window.scrollTo({ top: 0 });
                }}
              >
                <FaCog fontSize="30" />
                <text className={styles.navigationIconText}>Dashboard</text>
              </button>

              <button
                className={
                  navState === "subscribers"
                    ? `${styles.navigationIconContainer} ${styles.active}`
                    : styles.navigationIconContainer
                }
                onClick={() => {
                  history.push("/qip/admin/subscribers/summary");
                  setNavState("subscribers");
                  window.scrollTo({ top: 0 });
                }}
              >
                <FaUsers fontSize="30" />
                <text className={styles.navigationIconText}>Subscribers</text>
              </button>

              <button
                className={
                  navState === "messages"
                    ? `${styles.navigationIconContainer} ${styles.active}`
                    : styles.navigationIconContainer
                }
                onClick={() => {
                  history.push("/qip/admin/messages");
                  setNavState("messages");
                  window.scrollTo({ top: 0 });
                }}
              >
                <FaEnvelope fontSize="30" />
                <text className={styles.navigationIconText}>Messages</text>
              </button>

              <button
                className={
                  navState === "feedback"
                    ? `${styles.navigationIconContainer} ${styles.active}`
                    : styles.navigationIconContainer
                }
                onClick={() => {
                  history.push("/qip/admin/request-feedback/list");
                  setNavState("feedback");
                  window.scrollTo({ top: 0 });
                }}
              >
                <FaRegCommentDots fontSize="30" />
                <text className={styles.navigationIconText}>Feedback</text>
              </button>

              <button
                className={
                  navState === "questions"
                    ? `${styles.navigationIconContainer} ${styles.active}`
                    : styles.navigationIconContainer
                }
                onClick={() => {
                  history.push("/qip/admin/questions");
                  setNavState("questions");
                  window.scrollTo({ top: 0 });
                }}
              >
                <FaQuestion fontSize="30" />
                <text className={styles.navigationIconText}>Questions</text>
              </button>

              <button
                className={
                  navState === "accounts"
                    ? `${styles.navigationIconContainer} ${styles.active}`
                    : styles.navigationIconContainer
                }
                onClick={() => {
                  history.push("/qip/admin/user-management/user-list");
                  setNavState("accounts");
                  window.scrollTo({ top: 0 });
                }}
              >
                <FaUserAlt fontSize="30" />
                <text className={styles.navigationIconText}>Accounts</text>
              </button>

              <button
                className={
                  navState === "resources"
                    ? `${styles.navigationIconContainer} ${styles.active}`
                    : styles.navigationIconContainer
                }
                onClick={() => {
                  history.push("/qip/admin/resources");
                  setNavState("resources");
                  window.scrollTo({ top: 0 });
                }}
              >
                <FaHeart fontSize="30" />
                <text className={styles.navigationIconText}>Resources</text>
              </button>

              <button
                className={
                  navState === "tasks"
                    ? `${styles.navigationIconContainer} ${styles.active}`
                    : styles.navigationIconContainer
                }
                onClick={() => {
                  history.push("/qip/admin/tasks");
                  setNavState("tasks");
                  window.scrollTo({ top: 0 });
                }}
              >
                <FaFlagCheckered fontSize="30" />
                <text className={styles.navigationIconText}>Tasks</text>
              </button>
            </div>
          )}

          {isLoggedIn && (disableNavbar ? disableNavbar : headerMenu) && (
            <>
              <div className={styles.helpContainer}>
                <button
                  className={styles.helpButton}
                  // onClick={() => setHelpModalVisibility(true)}
                  onClick={() =>
                    window.open("https://holisticmanagement.net.au/get-help/", "_blank")
                  }
                >
                  Help
                </button>
              </div>
            </>
          )}

          {isLoggedIn && (disableNavbar ? disableNavbar : headerMenu) && (
            <>
              <div
                className={styles.logoutContainer}
                onClick={() => setLogoutModalVisibility(true)}
              >
                <Icon className={styles.logoutIcon} name="sign out" />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
