import * as React from 'react';

import PropTypes from 'prop-types';
import { Button, Dropdown, Modal } from 'semantic-ui-react';

import styles from './ChangeSubscriptionModal.module.css';
import BlueButton from '../../BlueButton';
import InputComponent from '../../InputComponent';

const updateType = [
  {
    key: '1',
    value: 1,
    text: 'Add An Additional Service'
  },
  {
    key: '2',
    value: 2,
    text: 'Remove A Service'
  },
  {
    key: '3',
    value: 3,
    text: 'Upgrade to Portal + Mentoring'
  },
  {
    key: '4',
    value: 4,
    text: 'Downgrade to the QIP Portal'
  },
  {
    key: '5',
    value: 5,
    text: 'Cancel Subscription'
  },
  {
    key: '6',
    value: 6,
    text: 'Other'
  },
];

function ChangeSubscriptionModal({ visibility, onOpenModal, onCloseModal }) {

  return (
    <Modal
      size="medium"
      centered
      open={visibility}
      onOpen={onOpenModal}
      onClose={onCloseModal}
    >
      <div className={styles.modalContainer}>

        <Button
          className={styles.closeButton}
          onClick={onCloseModal}
          size="big"
          icon="close"
        />

        <div className={styles.modalContent}>

          <text className={styles.headerText}>
            Looking to update your subscription?
          </text>

          <text className={styles.subText}>
            Tell us how you wish to update your subscription and we can help you
          </text>

          <div className={styles.inputGroup}>

            <div className={styles.inputColumn}>
              <InputComponent
                fieldName="firstName"
                label="First name"
                type="text"
                required
              />      
              <InputComponent
                fieldName="lastName"
                label="Last name"
                type="text"
                required
              />              
            </div>            
            <div className={styles.inputColumn}>
              <InputComponent
                fieldName="email"
                label="Email address"
                type="email"
                required
              />       
              <InputComponent
                fieldName="serviceName"
                label="Service name"
                type="text"
                required
              />              
            </div>
            <div className={styles.inputColumn}>
              <InputComponent
                className={styles.serviceNumberInput}
                fieldName="serviceApprovalNumber"
                label="Service approval number"
                type="text"
                required
              />   

              <div className={styles.dropdownGroup}>

                <text className={styles.dropdownText}>
                  Select update type<span className={styles.required}>*</span>
                </text>

                <Dropdown
                  className={styles.dropdownBox}
                  fluid
                  selection
                  placeholder="Choose"
                  options={updateType}
                />

              </div>              
            </div>

            <div className={styles.inputTextAreaColumn}>
              <InputComponent
                className={styles.inputComments}
                label="Comments"
                type="text"
                textArea
              />
            </div>

          </div>

          <BlueButton
            className={styles.blueButton}
            buttonText="Send"
          />

        </div>

      </div>
    </Modal>
  );
}

ChangeSubscriptionModal.propTypes = {
  visibility: PropTypes.bool.isRequired,
  onOpenModal: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired,
};

export default ChangeSubscriptionModal;
