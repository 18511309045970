import * as React from "react";

import { Route, Switch, useRouteMatch, Redirect } from "react-router-dom";

import MessageBoard from "../../pages/Messages/MessageBoard";
import { useStoreState, useStoreActions } from "../../hooks";

export default function Messages() {
  const match = useRouteMatch();

  const remainingTrial = useStoreState(
    (state) => state.userStore.remainingTrial
  );

  const setDisableNavBar = useStoreActions(
    (actions) => actions.appStore.setDisableMenu
  );

  const userSelectedService = useStoreState(
    (state) => state.authStore.selectedService
  );

  if (userSelectedService && userSelectedService.redirectPaymentFailed) {
    setDisableNavBar(true);
    return <Redirect to={`/qip/update-payment-method`} />;
  }

  const subExpired = remainingTrial <= 0 ? true : false;

  if (subExpired) {
    return <Redirect to={`/qip/pricing-plans`} />;
  }

  return (
    <Switch>
      <Route
        path={`${match.path}`}
        render={(props) => <MessageBoard {...props} />}
      />
    </Switch>
  );
}
