import { Action, action, thunk, Thunk } from "easy-peasy";
import { Task, TaskInfo, TaskInfoPayload, LevelInformation } from "../../types";
import { getQuestion, fetchNQAQuestions } from "../../api/requests";

export interface TaskModel {
  tasks: Task[] | undefined;
  setTasks: Action<TaskModel, Task[]>;
  selectedTask?: Task;
  setSelectedTask: Action<TaskModel, Task>;
  // setSelectedTask: Action<TaskModel, number>; // for testing only
  fetchTasks: Thunk<TaskModel, string>;
  TaskInfo?: TaskInfo;
  setTaskInfo: Action<TaskModel, TaskInfo>;
  fetchTaskInfo: Thunk<TaskModel, TaskInfoPayload>;
  levelInformation: LevelInformation;
  setLevelInformation: Action<TaskModel, LevelInformation>;
  resetLevelInformation: Action<TaskModel, LevelInformation>;
}

// status: 1 - not started, 2 - in progress, 3 - completed
const dummyTasks = [
  {
    id: 1,
    name: "Upload missing documentation for NQA1",
    nqaArea: "1.1.1",
    asignee: "Peter Jenkins",
    taskDue: "21/10/2020",
    status: 2,
    summary:
      "John can you please add the missing checklist for main reception. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.",
  },
  {
    id: 2,
    name: "Finish NQA3 for Little Smarties St Claire",
    nqaArea: "1.2.1",
    asignee: "Peter Jenkins",
    taskDue: "21/10/2020",
    status: 1,
    summary:
      "John can you please add the missing checklist for main reception. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.",
  },
  {
    id: 3,
    name: "Finish NQA3 for Little Smarties St Claire",
    nqaArea: "1.2.3",
    asignee: "Peter Jenkins",
    taskDue: "21/10/2020",
    status: 3,
    summary:
      "John can you please add the missing checklist for main reception. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.",
  },
  {
    id: 4,
    name: "Upload missing documentation for NQA1",
    nqaArea: "1.4.5",
    asignee: "Peter Jenkins",
    taskDue: "21/10/2020",
    status: 3,
    summary:
      "John can you please add the missing checklist for main reception. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.",
  },
  {
    id: 5,
    name: "Finish NQA3 for Little Smarties St Claire",
    nqaArea: "1.1.1",
    asignee: "Peter Jenkins",
    taskDue: "21/10/2020",
    status: 1,
    summary:
      "John can you please add the missing checklist for main reception. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.",
  },
];

const taskStore: TaskModel = {
  tasks: dummyTasks,
  levelInformation: {
    navigate: false,
    nqaLevel: 0,
    nqaSublevel: 0,
    qtype: 0,
    qId: 0,
  },
  setTasks: action((state, payload) => {
    state.tasks = payload;
  }),
  setSelectedTask: action((state, payload) => {
    state.selectedTask = payload;
  }),
  fetchTasks: thunk(async (actions, payload) => {
    const repsonse = await fetch(payload); //placeholder
    const taskData = await repsonse.json();
    actions.setTasks(taskData);
  }),
  setTaskInfo: action((state, payload) => {
    state.TaskInfo = payload;
  }),
  fetchTaskInfo: thunk(async (actions, payload) => {
    const nqaList = payload.nqaList;
    const question = await getQuestion(Number(payload.qId));
    const questionType = question.type;
    const nqaLevels = nqaList.levels;
    const taskType = payload.taskType;

    let nqaLevel = 0;
    let nqaSubLevel = 0;

    let qFound = false;

    for (var x = 0; x < nqaLevels.length; x++) {
      for (var y = 0; y < nqaLevels[x].subLevels.length; y++) {
        const questions = await fetchNQAQuestions(
          payload.centerId,
          questionType,
          nqaLevels[x].subLevels[y],
          payload.serviceCatId
        );

        const getQ = questions.find((q) => q.id === question.id);

        if (typeof getQ !== "undefined") {
          qFound = true;

          actions.setTaskInfo({
            qId: Number(payload.qId),
            desription:
              taskType === "evidence"
                ? getQ.yesDescription
                : getQ.noDescription,
            nqaLevel: x,
            nqaSubLevel: y,
            nqaLabel: nqaLevels[x].subLevels[y].label,
            questionType,
          });

          return;
        }
      }
    }

    // nqaLevels.forEach((level) => {
    //   level.subLevels.forEach(async (sublevel) => {
    //     const questions = await fetchNQAQuestions(
    //       payload.centerId,
    //       questionType,
    //       sublevel
    //     );

    //     const getQ = questions.find((q) => q.id === question.id);

    //     if (typeof getQ !== "undefined") {
    //       qFound = true;

    //       actions.setTaskInfo({
    //         qId: Number(payload.qId),
    //         desription:
    //           taskType === "evidence"
    //             ? getQ.yesDescription
    //             : getQ.noDescription,
    //         nqaLevel,
    //         nqaSubLevel,
    //         nqaLabel: sublevel.label,
    //         questionType,
    //       });

    //       return;
    //     } else {
    //       nqaSubLevel = nqaSubLevel + 1;
    //     }
    //   });

    //   if (qFound) {
    //     return;
    //   } else {
    //     nqaLevel = nqaLevel + 1;
    //   }
    // });
  }),
  setLevelInformation: action((state, payload) => {
    state.levelInformation = payload;
  }),
  resetLevelInformation: action((state, payload) => {
    state.levelInformation = {
      navigate: false,
      nqaLevel: 0,
      nqaSublevel: 0,
      qtype: 0,
      qId: 0,
    };
  }),
};

export default taskStore;
