import * as React from "react";

import { Dropdown } from "semantic-ui-react";
import { useHistory } from "react-router-dom";
import { FaRegArrowAltCircleLeft, FaSpinner } from "react-icons/fa";

import styles from "./UserUpdate.module.css";

import BlueButton from "../../../components/BlueButton";
import SkipButton from "../../../components/SkipButton";
import InputComponent from "../../../components/InputComponent";
import DeleteUserModal from "../../../components/Modals/DeleteUserModal";
import CreateNewPasswordModal from "../../../components/Modals/CreateNewPasswordModal";
import PasswordSaveConfirmedModal from "../../../components/Modals/PasswordSaveConfirmedModal";
import { useStoreState } from "../../../hooks";
import {
  deleteUser,
  updateUserDetails,
  fetchUserCenters,
  removeUser,
} from "../../../api/requests";

import useSWR, { mutate } from "swr";
import { isEmpty } from "lodash";

const { useCallback, useReducer, useState, useEffect } = React;

const updateReducer = (state, action) => {
  switch (action.type) {
    case "UPDATE": {
      const updatedInput = {
        ...state.input,
        [action.field]: action.value,
      };

      const updatedValidation = {
        ...state.validation,
        [action.field]: action.valid,
      };

      return {
        input: updatedInput,
        validation: updatedValidation,
      };
    }
    default:
      return state;
  }
};

const userRoles = [
  {
    key: 1,
    value: "admin",
    text: "Admin",
  },
  {
    key: 2,
    value: "member",
    text: "Member",
  },
];

const states = [
  {
    key: 1,
    value: "NSW",
    text: "NSW",
  },
  {
    key: 2,
    value: "QLD",
    text: "QLD",
  },
  {
    key: 3,
    value: "SA",
    text: "SA",
  },
  {
    key: 4,
    value: "TAS",
    text: "TAS",
  },
  {
    key: 5,
    value: "VIC",
    text: "VIC",
  },
  {
    key: 6,
    value: "WA",
    text: "WA",
  },
  {
    key: 7,
    value: "ACT",
    text: "ACT",
  },
  {
    key: 8,
    value: "NT",
    text: "NT",
  },
];

function UserUpdate() {
  const history = useHistory();

  const isAdmin = useStoreState((state) => state.authStore.isAdmin);

  const services = useStoreState((state) => state.authStore.services);
  const currentUser = useStoreState((state) => state.userStore.loggedInUser);
  const selectedUser = useStoreState((state) => state.userStore.selectedUser);
  const service = useStoreState((state) => state.authStore.selectedService);

  const adminAttr = useStoreState((state) => state.authStore.adminAttr);

  const centerId = useStoreState((state) => state.nqaStore.serviceDetails.id);

  const [firstName, setFirstName] = useState(selectedUser.name.split(" ")[0]);
  const [lastName, setLastName] = useState(selectedUser.name.split(" ")[1]);

  const [fieldChecked, setFieldChecked] = useState(false);

  const [centerIds, setCenterIds] = useState([]);

  const [updateLoading, setLoading] = useState(false);
  const [deleteModalVis, setDeleteModalVis] = useState(false);
  const [createPasswordModalVis, setPasswordModalVis] = useState(false);
  const [confirmationModalVisibility, setConfirmationModalVis] =
    useState(false);

  const initialState = {
    input: {
      ...selectedUser,
    },
    validation: {
      email: selectedUser.email ? true : false,
      role: true,
      streetAddress: true,
      suburb: true,
      state: true,
      postalCode: true,
      telnum: true,
      mobilenum: true,
      additionalEmail: true,
    },
    formValidation: false,
  };

  const [formState, formDispatch] = useReducer(updateReducer, initialState);

  const changeInputDispatch = useCallback((field, value, validity = true) => {
    const action = {
      type: "UPDATE",
      field,
      value,
      valid: validity,
    };
    formDispatch(action);
  }, []);

  const setName = (fieldName, value, _) => {
    if (fieldName === "firstName") {
      setFirstName(value);
    } else {
      setLastName(value);
    }
  };

  const onDeleteUser = () => {
    if (selectedUser.id === currentUser.id || selectedUser.role === "admin") {
      // alert("Cannot delete logged in user.");
    } else {
      setDeleteModalVis(true);
    }
  };

  const onUserDeleteConfirm = async () => {
    setLoading(true);

    try {
      const response = await deleteUser(
        Number(selectedUser.id),
        Number(service.id),
        Number(adminAttr.admin_id)
      );

      // const response = await removeUser(Number(selectedUser.id));

      if (response) {
        setLoading(false);
        setDeleteModalVis(false);
        history.goBack();
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      setDeleteModalVis(false);
    }
  };

  const onSavePassword = async () => {
    // await onSavePassword(newPassword) this will be a thunk in userStore
    setPasswordModalVis(false);
    setConfirmationModalVis(true);
  };

  const onPasswordSavedConfirm = () => {
    setConfirmationModalVis(false);
    history.goBack();
  };

  const onSaveUser = async () => {
    setFieldChecked(true);

    if (
      !formState.input.email ||
      // !formState.input.role ||
      !firstName ||
      !lastName ||
      centerIds.length <= 0
    ) {
      return;
    }

    setLoading(true);

    try {
      const payload = {
        ...formState.input,
        name: `${firstName} ${lastName}`,
        centerIds,
      };

      const response = await updateUserDetails(payload);

      if (response) {
        setLoading(false);
        history.push("/qip/user-management");
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
    // } else {
    //   alert("Please fill all required fields.");
    // }
  };

  const { data: userCenters, isValidating } = useSWR(
    ["/user/centers", selectedUser.id],
    (_, user_id) => fetchUserCenters(selectedUser.id),
    {
      revalidateOnMount: true,
      onSuccess: (data) => {
        setCenterIds(
          data.map((service) => {
            return service.id;
          })
        );
      },
      onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
        // Only retry up to 3 times.
        if (retryCount && retryCount >= 3) {
          if (!userCenters) setFetchLimitError(true);

          return;
        }
        // Retry after 3 seconds.
        setTimeout(() => revalidate({ retryCount: retryCount + 1 }), 3000);
      },
    }
  );

  useEffect(() => {
    if (!isEmpty(userCenters)) {
      setCenterIds(userCenters.map((center) => center.id));
    }
  }, [selectedUser.id]);

  return (
    <div className={styles.mainContainer}>
      <div className={styles.backButtonContainer}>
        <button
          className={styles.backButtonText}
          onClick={() => history.goBack()}
        >
          <FaRegArrowAltCircleLeft
            className={styles.backButton}
            color="#C4C4C4"
            size={24}
          />
        </button>
      </div>

      {deleteModalVis && (
        <DeleteUserModal
          visibility={deleteModalVis}
          onOpenModal={() => setDeleteModalVis(true)}
          onCloseModal={() => setDeleteModalVis(false)}
          onRemoveUser={onUserDeleteConfirm}
        />
      )}

      {createPasswordModalVis && (
        <CreateNewPasswordModal
          visibility={createPasswordModalVis}
          onOpenModal={() => setPasswordModalVis(true)}
          onCloseModal={() => setPasswordModalVis(false)}
          onSavePassword={onSavePassword}
        />
      )}

      {confirmationModalVisibility && (
        <PasswordSaveConfirmedModal
          visibility={confirmationModalVisibility}
          onOpenModal={() => setConfirmationModalVis(true)}
          onCloseModal={onPasswordSavedConfirm}
        />
      )}

      <div className={styles.content}>
        <div className={styles.headerContainer}>
          <div className={styles.headerTextGroup}>
            <h1 className={styles.headerText}>Update a user</h1>

            <text className={styles.headerSubText}>
              Add, remove and edit users and user details here.
            </text>
          </div>

          <button
            className={styles.backToDashboardButton}
            onClick={() => history.push("/qip/user-management/")}
          >
            Back to dashboard
          </button>
        </div>

        <div className={styles.cardContainer}>
          <div className={styles.detailsContent}>
            <h2 className={styles.cardHeaderText}>User details</h2>

            <div className={styles.inputRow}>
              {/*
                  <InputComponent
                    className={styles.inputBox}
                    fieldName="username"
                    label="Username"
                    type="text"
                    disabled
                    inputValue={formState.input.username}
                    setInputValue={changeInputDispatch}
                  />
                  */}
              <InputComponent
                disabled
                className={styles.inputBoxDisabled}
                fieldName="email"
                label="User's email address"
                type="email"
                required
                inputValue={formState.input.email}
                setInputValue={changeInputDispatch}
              />
            </div>

            <div className={styles.inputRow}>
              <InputComponent
                className={styles.inputBox}
                fieldName="firstName"
                label="First name"
                type="text"
                required
                showNotFilled={fieldChecked}
                inputValue={firstName}
                setInputValue={setName}
              />

              <InputComponent
                className={styles.inputBox}
                fieldName="lastName"
                label="Last name"
                type="text"
                required
                showNotFilled={fieldChecked}
                inputValue={lastName}
                setInputValue={setName}
              />
            </div>

            <div className={styles.inputRow1}>
              <div className={styles.dropdownGroup1}>
                <text className={styles.dropdownText}>
                  User Role<span className={styles.required}>*</span>
                </text>
                <Dropdown
                  className={styles.dropdownBox1}
                  disabled
                  selection
                  multiple
                  fluid
                  placeholder="Choose"
                  defaultValue={
                    selectedUser.role === "admin" ? "admin" : "member"
                  }
                  options={userRoles}
                  // value={formState.input.role}
                />

                {/*onChange=
                    {(_, data) => changeInputDispatch(
                    'role',
                    data.value
                    )}*/}
              </div>
              {isAdmin && (
                <div className={styles.dropdownGroup1}>
                  <text className={styles.dropdownText}>
                    Add to centres<span className={styles.required}>*</span>
                  </text>

                  <Dropdown
                    className={styles.dropdownBox1}
                    selection
                    fluid
                    multiple
                    disabled={selectedUser.role === "admin"}
                    placeholder="Choose"
                    defaultValue={
                      userCenters
                        ? userCenters.map((service) => service.id)
                        : []
                    }
                    options={services.map((service) => ({
                      text: service.name,
                      value: service.id,
                    }))}
                    onChange={(_, data) => {
                      // setCId(data.value);

                      setCenterIds(data.value);
                    }}
                  />
                  {fieldChecked && centerIds.length <= 0 && (
                    <div className={`text-red-400 p-2 font-thin italic`}>
                      This field is required.
                    </div>
                  )}
                </div>
              )}
            </div>

            <div className={styles.buttonGroup}>
              <BlueButton // buttonText="Save changes" onClick={onSaveUser}
                buttonText={
                  updateLoading ? (
                    <div className={`flex justify-center items-center`}>
                      <FaSpinner className={`animate-spin`} />
                      <span className={`block pl-2`}>Save changes</span>
                    </div>
                  ) : (
                    <> Save changes</>
                  )
                }
                onClick={onSaveUser}
              />

              <button
                className={styles.newUserButton}
                onClick={() => setPasswordModalVis(true)}
              >
                New password
              </button>

              {isAdmin && selectedUser.role !== "admin" && (
                <SkipButton
                  buttonText="Remove user"
                  className={`${styles.removeUser} ml-4`}
                  onClick={onDeleteUser}
                />
              )}
            </div>
          </div>
          {/*<div className={styles.detailsContent}>

                <h2 className={styles.cardHeaderText}>
                  User address
                </h2>

                <div className={styles.inputRow}>

                  <InputComponent
                    className={styles.inputBox}
                    fieldName="streetAddress"
                    label="Street Address"
                    type="text"
                    inputValue={formState.input.streetAddress}
                    setInputValue={changeInputDispatch}
                  />

                  <InputComponent
                    className={styles.inputBox}
                    fieldName="suburb"
                    label="Suburb"
                    type="text"
                    inputValue={formState.input.suburb}
                    setInputValue={changeInputDispatch}
                  />

                </div>

                <div className={styles.inputRow}>

                  <div className={styles.dropdownGroup}>

                    <text className={styles.dropdownText}>
                      State/territory
                    </text>

                    <Dropdown
                      className={styles.dropdownBox}
                      fluid
                      selection
                      value={formState.input.state}
                      options={states}
                      placeholder="Choose"
                      onChange={(e, data) => changeInputDispatch(
                        'state',
                        data.value
                      )}
                    />

                  </div>

                  <InputComponent
                    className={styles.inputBox}
                    fieldName="postalCode"
                    label="Postcode"
                    type="number"
                    inputValue={formState.input.postalCode}
                    setInputValue={changeInputDispatch}
                  />

                </div>

              </div>

              <div className={styles.detailsContent}>

                <h2 className={styles.cardHeaderText}>
                  Contact details
                </h2>

                <div className={styles.inputRow}>

                  <InputComponent
                    className={styles.inputBox}
                    fieldName="telnum"
                    label="Telephone number"
                    type="number"
                    inputValue={formState.input.telnum}
                    setInputValue={changeInputDispatch}
                  />

                  <InputComponent
                    className={styles.inputBox}
                    fieldName="mobilenum"
                    label="Mobile number"
                    type="number"
                    inputValue={formState.input.mobilenum}
                    setInputValue={changeInputDispatch}
                  />

                </div>

                <div className={styles.inputRow}>
                  <InputComponent
                    className={styles.inputBox}
                    fieldName="additionalEmail"
                    label="E-mail address"
                    type="email"
                    inputValue={formState.input.additionalEmail}
                    setInputValue={changeInputDispatch}
                  />
                </div>

              </div>*/}
        </div>
      </div>
    </div>
  );
}

export default UserUpdate;
