import * as React from "react";

import PropTypes from "prop-types";
import { Modal } from "semantic-ui-react";
import { FaInfo } from "react-icons/fa";
import { useHistory, useRouteMatch } from "react-router-dom";

import styles from "./FullReportModal.module.css";
import BlueButton from "../../BlueButton";

function FullReportModal({ visibility, onOpenModal, onCloseModal }) {
  const history = useHistory();
  const match = useRouteMatch();

  return (
    <Modal
      size="tiny"
      centered
      open={visibility}
      onOpen={onOpenModal}
      onClose={onCloseModal}
    >
      <div className={styles.modalContainer}>
        <div className={`flex justify-center items-center  `}>
          <div
            className={`rounded-full overflow-hidden p-7`}
            style={{ backgroundColor: "#feeded" }}
          >
            <FaInfo className={styles.infoIcon} />
          </div>
        </div>
        <text className={styles.headerText}>
          A full submission report can only be generated once your{" "}
          <span
            className={`font-semibold underline cursor-pointer hover:text-blue-500`}
            // onClick={() => {
            //   window.location = "/qip/nqa/service-details";
            // }}
            onClick={() => history.push(`/qip/nqa/service-details`)}
          >
            {" "}
            Service Details
          </span>{" "}
          have been completed.
        </text>

        <BlueButton
          className={styles.blueButton}
          buttonText="Back"
          onClick={onCloseModal}
        />
      </div>
    </Modal>
  );
}

FullReportModal.propTypes = {
  visibility: PropTypes.bool.isRequired,
  onOpenModal: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired,
};

export default FullReportModal;
