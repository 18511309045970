import * as React from "react";
import { useHistory } from "react-router-dom";
import { FaSpinner } from "react-icons/fa";
import { Dropdown } from "semantic-ui-react";
import BlueButton from "../../components/BlueButton";
import InputComponent from "../../components/InputComponent";
import styles from "./Help.module.css";

const { useState } = React;

const serviceTypeOption = [
  {
    key: "1",
    value: "Functionality Question",
    text: "Functionality Question",
  },
  {
    key: "2",
    value: "Bug Report",
    text: "Bug Report",
  },
  {
    key: "3",
    value: "Feature Suggestion",
    text: "Feature Suggestion",
  },
  {
    key: "4",
    value: "Account or general enquiry",
    text: "Account or general enquiry",
  },
  {
    key: "5",
    value: "Other",
    text: "Other",
  }
];

function Help () {
  const history = useHistory();
  const [submitting, setSubmitting] = useState(false);

  return (
    <div className={`${styles.mainContainer} mt-12 md:mb-20 lg:mb-12`}>

      <div className={`${styles.headerTextContainer} mb-12`}>
        <h1 className={`mb-2.5`}>How can we help you?</h1>
        <text>We take great pride in offering outstanding support. Please complete the form below and one of our team will get back to you within 24hrs (Mon – Fri).</text>
      </div>

      <div className={`${styles.formContainer} py-28 px-20`}>

        <div className={`${styles.formRow} ${styles.w_half} flex justify-between`}>

          <div className={styles.dropdownGroup}>
            <text>
              Support type
            </text>

            <Dropdown
              className={`flex items-center`}
              fluid
              selection
              placeholder="What is your request about?"
              options={serviceTypeOption}
              onChange={(e, data) =>
                setStateVal("support_type", data.value)
              }
            />
          </div>

          <InputComponent
            label="Your Name"
            type="text"
            fieldName="YourName"
          />

		</div>

        <div className={`${styles.formRow} ${styles.w_half} flex justify-between`}>

          <InputComponent
            label="Service Name"
            type="text"
            fieldName="ServiceName"
			required
          />

          <InputComponent
            label="Email address"
            type="email"
            fieldName="EmailAddress"
			required
          />

		</div>

        <div className={`${styles.formRow} ${styles.w_full}`}>

          <InputComponent
            label="Subject"
            type="text"
            fieldName="Subject"
          />

		</div>

        <div className={`${styles.formRow} ${styles.w_full}`}>

          <InputComponent
            label="Please explain your issue, question, or request"
            textArea
            fieldName="question"
			required
          />

		</div>

        <div className={`${styles.formRow} ${styles.w_half} flex flex-wrap justify-between`}>

          <text className={`w-full mb-4`}>If you are experiencing an issue, please let us know the browser and device used.</text>

          <InputComponent
            label="Browser type"
            type="text"
            fieldName="BrowserType"
			placeholder="e.g. Chrome, Firefox, Internet Explorer..."
          />

          <InputComponent
            label="Device type"
            type="text"
            fieldName="DeviceType"
			placeholder="e.g. PC, Mac, Tablet..."
          />

		</div>

        <div className={`${styles.formRow} ${styles.w_full}`}>

          <input
            type="file"
            style={{ display: "none" }}
          />
                   
          <text>Screenshot (if applicable)</text>

          <div
            className={styles.uploadPlaceholder}
          >
            <text> Select files </text>
          </div>

		</div>

        <BlueButton
          disabled={submitting}
          className={`mt-10 mb-5`}
          buttonText={
            submitting ? (
              <div className={`flex justify-center items-center`}>
                <FaSpinner className={`animate-spin`} />
                <span className={`block pl-2`}>Submit Ticket</span>
              </div>
            ) : (
              <> Submit Ticket</>
            )
          }
          />

          <text className={`${styles.formText} block`}>
            We offer support Monday to Friday, 9am – 5 pm AEST. The majority of support requests are responded to within a few hours, but we do our best to answer all questions by the next business day. We are unavailable on weekends and Australian Holidays.
          </text>

      </div>

    </div>
  );

}
 
export default Help;