import * as React from "react";

import PropTypes from "prop-types";
import { Modal } from "semantic-ui-react";

import styles from "./PaymentUpdatedModal.module.css";
import BlueButton from "../../BlueButton";

function PaymentUpdatedModal({ visibility, onOpenModal, onCloseModal }) {
  return (
    <Modal
      size="small"
      centered={true}
      open={visibility}
      onOpen={onOpenModal}
      onClose={onCloseModal}
    >
      <div className={`${styles.modalContainer} pt-8 pb-12 mt-12 mb-8`}>
        <text className={styles.headerText}>Thank you!</text>
        <text className={`${styles.headerText} mb-12`}>Payment received.</text>

        <BlueButton buttonText="Ok" onClick={onCloseModal} />
      </div>
    </Modal>
  );
}

PaymentUpdatedModal.propTypes = {
  visibility: PropTypes.bool.isRequired,
  onOpenModal: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired,
};

export default PaymentUpdatedModal;
