import * as React from "react";

import PropTypes from "prop-types";
import { Button, Modal } from "semantic-ui-react";

import styles from "./ChooseNQAModal.module.css";
import BlueButton from "../../BlueButton";

const { useState } = React;

function ChooseNQAModal({
  visibility,
  onOpenModal,
  onCloseModal,
  onSetAreas,
  nqaList,
}) {
  const [selectedNQAList, setSelectedNQAList] = useState(nqaList);

  const onSelectNQA = (nqa) => {
    if (selectedNQAList.includes(nqa)) {
      setSelectedNQAList(selectedNQAList.filter((number) => number !== nqa));
    } else {
      setSelectedNQAList(selectedNQAList.concat(nqa));
    }
  };

  const onClearSelectedNQAs = () => {
    setSelectedNQAList([]);
    onSetAreas([]);
  };

  const onFilterTasks = () => {
    onSetAreas(selectedNQAList);
    onCloseModal();
  };

  return (
    <Modal
      size="small"
      centered={false}
      open={visibility}
      onOpen={onOpenModal}
      onClose={onCloseModal}
    >
      <div className={styles.modalContainer}>
        <Button
          className={styles.closeButton}
          onClick={onCloseModal}
          size="big"
          icon="close"
        />

        <text className={styles.headerText}>Choose NQA Areas</text>

        <div className={styles.nqaGroup}>
          {[...Array(7).keys()].map((nqa) => (
            <button
              className={
                selectedNQAList.includes(nqa + 1)
                  ? styles.nqaSelectedOption
                  : styles.nqaOption
              }
              key={nqa}
              onClick={() => onSelectNQA(nqa + 1)}
            >
              NQA{nqa + 1}
            </button>
          ))}
        </div>

        <BlueButton
          className={styles.blueButton}
          buttonText="Filter tasks"
          height="80px"
          onClick={onFilterTasks}
        />

        <div className={styles.clearSection}>
          <button
            className={styles.clearSectionButton}
            onClick={onClearSelectedNQAs}
          >
            Clear selection
          </button>
        </div>
      </div>
    </Modal>
  );
}

ChooseNQAModal.propTypes = {
  visibility: PropTypes.bool.isRequired,
  onSetAreas: PropTypes.func.isRequired,
  onOpenModal: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  nqaList: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export default ChooseNQAModal;
