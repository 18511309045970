import * as React from "react";

import { Route, Switch, useRouteMatch, Redirect } from "react-router-dom";

import ServicesList from "../../pages/Services/ServicesList";
import UpgradeServiceView from "../../pages/Services/UpgradeServiceView";
import { useStoreState, useStoreActions } from "../../hooks";

export default function Services() {
  const match = useRouteMatch();

  const remainingTrial = useStoreState(
    (state) => state.userStore.remainingTrial
  );

  const setDisableNavBar = useStoreActions(
    (actions) => actions.appStore.setDisableMenu
  );

  const userSelectedService = useStoreState(
    (state) => state.authStore.selectedService
  );

  if (userSelectedService && userSelectedService.redirectPaymentFailed) {
    setDisableNavBar(true);
    return <Redirect to={`/qip/update-payment-method`} />;
  }
  const subExpired = remainingTrial <= 0 ? true : false;

  if (subExpired) {
    return <Redirect to={`/qip/pricing-plans`} />;
  }

  return (
    <Switch>
      <Route exact path={`${match.path}`} component={ServicesList} />
      <Route
        exact
        path={`${match.path}/upgrade`}
        component={UpgradeServiceView}
      />
    </Switch>
  );
}
