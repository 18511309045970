import * as React from "react";

import PropTypes from "prop-types";
import { Dropdown, Icon } from "semantic-ui-react";
import { Link, useHistory, useRouteMatch, useParams } from "react-router-dom";

import styles from "./PhysicalLocation.module.css";
import BlueButton from "../../../components/BlueButton";
import InputComponent from "../../../components/InputComponent";
import { ServiceDetailsContext } from "../../../context/ServiceDetailsContext";

import { useStoreState } from "../../../hooks";

import { ServiceSteps } from "../../../components/Steps";
import { FaSpinner } from "react-icons/fa";

const { useContext, useState } = React;

const States = [
  {
    key: 1,
    value: "NSW",
    text: "NSW",
  },
  {
    key: 2,
    value: "QLD",
    text: "QLD",
  },
  {
    key: 3,
    value: "SA",
    text: "SA",
  },
  {
    key: 4,
    value: "TAS",
    text: "TAS",
  },
  {
    key: 5,
    value: "VIC",
    text: "VIC",
  },
  {
    key: 6,
    value: "WA",
    text: "WA",
  },
  {
    key: 7,
    value: "ACT",
    text: "ACT",
  },
  {
    key: 8,
    value: "NT",
    text: "NT",
  },
];

function PhysicalLocation({ onNextStep, onSaveStep, onSkipServiceDetails }) {
  const history = useHistory();
  const match = useRouteMatch();

  const {
    saveLoading,
    onboarding,
    serviceDetailsState,
    changeInputDispatch,
    stepsIndicator,
  } = useContext(ServiceDetailsContext);

  const isLoggedIn = useStoreState((state) => state.authStore.isLoggedIn);

  const [fieldChecked, setFieldChecked] = useState(false);

  const { thisStep } = useParams();

  const onSave = async () => {
    setFieldChecked(true);

    if (
      !serviceDetailsState.validation.locationStreet ||
      !serviceDetailsState.validation.locationSuburb
    ) {
      return;
    }
    await onSaveStep();

    onNextStep();
    history.push(
      `${match.path
        .split("/")
        .slice(0, match.path.split("/").length - 1)
        .join("/")}/2`
    );
    // } else alert("Please fill all required fields.");
  };

  return (
    <div className={styles.mainContainer}>
      {isLoggedIn && (
        <>
          <div className={styles.headerContainer}>
            <h1>Complete your service details</h1>
            <p>
              Please fill out all missing fields marked with an ‘
              <span className={styles.required}>*</span>’
            </p>
          </div>
        </>
      )}
      <div className={styles.mainCard}>
        <div className={styles.cardContent}>
          <h1 className={styles.headerText}>Physical location</h1>

          <text className={styles.descriptionText}>
            Please complete the below fields.
          </text>

          <div className={styles.inputRow}>
            <div className={styles.inputColumn}>
              <InputComponent
                fieldName="locationStreet"
                label="Street address"
                type="text"
                required
                showNotFilled={fieldChecked}
                isInvalid={!serviceDetailsState.validation.locationStreet}
                inputValue={serviceDetailsState.input.locationStreet}
                setInputValue={changeInputDispatch}
              />
            </div>

            <div className={styles.inputColumn}>
              <InputComponent
                fieldName="locationSuburb"
                label="Suburb"
                type="text"
                required
                showNotFilled={fieldChecked}
                isInvalid={!serviceDetailsState.validation.locationSuburb}
                inputValue={serviceDetailsState.input.locationSuburb}
                setInputValue={changeInputDispatch}
              />
              <div className={styles.dropdownGroup}>
                <text className={styles.dropdownName}>State/territory</text>

                <Dropdown
                  className={styles.dropdownBox}
                  fluid
                  search
                  selection
                  value={serviceDetailsState.input.locationState}
                  options={States}
                  placeholder="Choose"
                  onChange={(e, data) =>
                    changeInputDispatch(
                      "locationState",
                      data.value,
                      serviceDetailsState.input.locationState ? true : false
                    )
                  }
                />
              </div>
            </div>

            <div className={styles.inputColumn}>
              <InputComponent
                fieldName="locationPostalCode"
                label="Postcode"
                type="text"
                inputValue={serviceDetailsState.input.locationPostalCode}
                setInputValue={changeInputDispatch}
              />
            </div>
          </div>

          <div className={styles.buttonGroup}>
            <BlueButton // buttonText="Save step"
              buttonText={
                saveLoading ? (
                  <div className={`flex justify-center items-center`}>
                    <FaSpinner className={`animate-spin`} />
                    <span className={`block pl-2`}>Save step</span>
                  </div>
                ) : (
                  <> Save step</>
                )
              }
              onClick={onSave}
            />

            <button
              className={styles.buttonNextStep}
              onClick={() =>
                history.push(
                  `${match.path
                    .split("/")
                    .slice(0, match.path.split("/").length - 1)
                    .join("/")}/2`
                )
              }
            >
              Next step
            </button>
          </div>

          <div
            className={`${styles.buttonStep} ${
              onboarding ? "flex justify-end" : "flex justify-between"
            } `}
          >
            {!onboarding && (
              <button className={styles.buttonStepDisabled}>
                <Icon name="angle left" />
                Back a step
              </button>
            )}

            <button
              className={styles.buttonFinishLater}
              onClick={onSkipServiceDetails}
            >
              Finish Later
              <Icon name="angle double right" />
            </button>
          </div>
        </div>

        <div className={styles.stepsColumn}>
          <ServiceSteps route={match.path} />
          {/* <div className={styles.CurrentItemContainer}>
            <div className={styles.stepItemCurrent}>
              <div className={styles.stepItemFillDone} />
            </div>

            <text className={styles.stepItemCurrentText}>Location</text>
          </div>
          {stepsNotDone.map((steps) => (
            <div key={steps.id} className={styles.stepItemContainer}>
              <Link
                className={styles.stepItemCurrentText}
                to={`${match.path
                  .split("/")
                  .slice(0, match.path.split("/").length - 1)
                  .join("/")}/${steps.id}`}
              >
                <div className={styles.stepItemIndicatorContainer}>
                  <div className={styles.stepItemFill} />
                </div>
                <text>{steps.stepsName}</text>
              </Link>
            </div>
          ))} */}
        </div>
      </div>
    </div>
  );
}

PhysicalLocation.propTypes = {
  onNextStep: PropTypes.func,
  onSkipStep: PropTypes.func,
  onSaveStep: PropTypes.func,
  onSkipServiceDetails: PropTypes.func,
  steps: PropTypes.func,
};

export default PhysicalLocation;
