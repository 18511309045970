import * as React from "react";

import { Modal } from "semantic-ui-react";
import { FaRegArrowAltCircleLeft, FaSpinner } from "react-icons/fa";
import { Route, useHistory, useRouteMatch } from "react-router-dom";

import styles from "./MessageBoard.module.css";
import MessageView from "../MessageView";
import MessageInbox from "../MessageInbox";
import BlueButton from "../../../components/BlueButton";
import ComposeMessageView from "../ComposeMessageView";
import DeleteMessageModal from "../../../components/Modals/DeleteMessageModal";
import ResourceDownloadingModal from "../../../components/Modals/ResourceDownloadingModal";
import useSWR, { mutate } from "swr";
import {
  getUserMessages,
  deleteUnreadMessage,
  deleteReadMessage,
  readMessage,
} from "../../../api/requests";
import { useStoreActions, useStoreState } from "../../../hooks";
import { isEmpty } from "lodash";

const { useEffect, useState } = React;

function MessageBoard({ location }) {
  const history = useHistory();
  const match = useRouteMatch();

  const [unreadMessages] = useState(12);
  const [importantMessages] = useState(7);
  const [selectedOption, setOption] = useState(1);

  const userId = useStoreState((state) => state.authStore.authObject.userId);

  const messages = useStoreState((state) => state.appStore.messages);
  // const [messages, setMessages] = useState([]);

  const [msgToDelete, setMsgToDelete] = useState({});

  const [loadingDelete, setLoadingDelete] = useState(false);

  const [fetchLimitError, setFetchLimitError] = useState(false);

  const selectedMessage = useStoreState(
    (state) => state.appStore.selectedMessage
  );

  const setSelectedMessage = useStoreActions(
    (actions) => actions.appStore.setSelectedMessage
  );

  // const [selectedMessage, setSelectedMessage] = useState({});

  const [fromView, setFromView] = useState(false);

  const [loadingView, setLoadingView] = useState(false);

  const [backButton, setBackButton] = useState(false);

  const [deleteModalVisibility, setDeleteModalVisibility] = useState(false);
  const [downloadModalVisibility, setDownloadModalVisibility] = useState(false);
  const [messageSentModalVisibility, setMessageSentModalVisibility] =
    useState(false);
  const [messageDeletedModalVisibility, setMessageDeletedModalVisibility] =
    useState(false);

  const onDeleteMessage = async () => {
    if (!isEmpty(msgToDelete)) {
      try {
        setLoadingDelete(true);
        if (fromView) {
          const response = await deleteReadMessage(
            msgToDelete.message_id,
            userId
          );

          const response1 = await deleteUnreadMessage(
            msgToDelete.message_id,
            userId
          );
        } else {
          if (msgToDelete.read) {
            const response = await deleteReadMessage(
              msgToDelete.message_id,
              userId
            );
            const response1 = await deleteUnreadMessage(
              msgToDelete.message_id,
              userId
            );
          } else {
            const response = await deleteUnreadMessage(
              msgToDelete.message_id,
              userId
            );
          }
        }

        await mutate(["/messages", userId]);
        setDeleteModalVisibility(false);
        setMessageDeletedModalVisibility(true);
        setLoadingDelete(false);
        if (fromView) {
          history.goBack();
          setFromView(false);
        }
      } catch (error) {
        setLoadingDelete(false);
        setDeleteModalVisibility(false);
        console.log(error);
      }
    }
  };

  const onMessageDeletedConfirmation = () => {
    setMessageDeletedModalVisibility(false);

    if (history.location !== match.path) history.replace(`${match.path}`);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    setOption(1);
  };

  const viewMessage = async (message) => {
    if (!message.read) {
      try {
        const response = await readMessage(message.message_id, userId);
        setLoadingView(false);
      } catch (error) {
        setLoadingView(false);
        console.log(error);
      }
    } else {
      setLoadingView(false);
    }
  };

  const onSelectMesage = async (message) => {
    if (!message.read) {
      setLoadingView(true);
      setOption(3);
      history.push(`${match.path}/${message.message_id}`);
      await viewMessage(message);
      await mutate(["/messages", userId]);
      setSelectedMessage(message);
      setLoadingView(false);
    } else {
      setSelectedMessage(message);
      setOption(3);
      history.push(`${match.path}/${message.message_id}`);
    }
  };

  const onNavigateTab = (tabNumber) => {
    setOption(tabNumber);

    if (history.location !== match.path) history.push(`${match.path}`);
  };

  const onMessageSentConfirmation = () => {
    setMessageSentModalVisibility(false);

    if (history.location !== match.path) history.replace(`${match.path}`);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    setOption(1);
  };

  useEffect(() => {
    if (history.action === "POP") {
      history.replace(`${match.path}`);
      setOption(1);
    }
  }, [history, history.action, match.path]);

  const DisplayTabChoice = () => {
    if (
      selectedOption === 1 ||
      (!isEmpty(location.state) && location.state.tab === 1)
    ) {
      return !isEmpty(messages) ? (
        <MessageInbox
          messages={messages}
          onDelete={(msg) => {
            setMsgToDelete(msg);
            setDeleteModalVisibility(true);
          }}
          onSelectMessage={onSelectMesage}
        />
      ) : (
        <div
          className={`bg-white flex flex-col justify-center items-center py-14`}
        >
          <FaSpinner
            className={`animate-spin text-qipGreen font-bold text-6xl`}
          />
          <span className={`py-2`}>Loading messages...</span>
        </div>
      );
    } else if (selectedOption === 2) {
      return (
        <ComposeMessageView
          onSendMessage={() => setMessageSentModalVisibility(true)}
        />
      );
    }

    return !isEmpty(selectedMessage) ? (
      <Route path={`${match.path}/:messageId`}>
        <MessageView
          message={selectedMessage}
          onDelete={(msg) => {
            setFromView(true);
            setMsgToDelete(selectedMessage);
            setDeleteModalVisibility(true);
          }}
          onDownload={() => setDownloadModalVisibility(true)}
          loadingView={loadingView}
        />
      </Route>
    ) : (
      <div
        className={`w-full flex flex-col justify-center items-center bg-white py-10`}
      >
        <FaSpinner
          className={`animate-spin text-qipGreen font-bold text-6xl`}
        />

        <div>Loading message...</div>
      </div>
    );
  };

  // const { data, isValidating: fetchingMessages } = useSWR(
  //   ["/messages", userId],
  //   (_, userId) => getUserMessages(userId),
  //   {
  //     onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
  //       // Only retry up to 3 times.
  //       if (retryCount && retryCount >= 3) {
  //         if (!messages) setFetchLimitError(true);

  //         return;
  //       }
  //       // Retry after 3 seconds.
  //       setTimeout(() => revalidate({ retryCount: retryCount + 1 }), 3000);
  //     },
  //     onSuccess: (data) => {
  //       console.log("data m ", data);
  //       setMessages(data);
  //       const curMesage = data.messages.filter(
  //         (msg) => Number(msg.message_id) === Number(selectedMessage.message_id)
  //       )[0];
  //       setSelectedMessage(curMesage);
  //     },
  //   }
  // );

  return (
    <div className={styles.mainContainer}>
      {messageDeletedModalVisibility && (
        <Modal
          size="small"
          centered={false}
          open={messageDeletedModalVisibility}
          onOpen={() => setMessageDeletedModalVisibility(true)}
          onClose={() => setMessageDeletedModalVisibility(false)}
        >
          <div className={styles.modalContainer}>
            <text className={styles.modalHeaderText}>
              Message is now deleted
            </text>
            <BlueButton
              className={styles.blueButton}
              buttonText="Ok"
              onClick={onMessageDeletedConfirmation}
            />
          </div>
        </Modal>
      )}

      {messageSentModalVisibility && (
        <Modal
          size="small"
          centered={false}
          open={messageSentModalVisibility}
          onOpen={() => setMessageSentModalVisibility(true)}
          onClose={() => setMessageSentModalVisibility(false)}
        >
          <div className={styles.modalContainer}>
            <text className={styles.modalHeaderText}>Message Sent</text>
            <BlueButton
              className={styles.blueButton}
              buttonText="Ok"
              onClick={onMessageSentConfirmation}
            />
          </div>
        </Modal>
      )}

      {downloadModalVisibility && (
        <ResourceDownloadingModal
          size="small"
          centered={false}
          visibility={downloadModalVisibility}
          onOpenModal={() => setDownloadModalVisibility(true)}
          onCloseModal={() => setDownloadModalVisibility(false)}
        />
      )}

      {deleteModalVisibility && (
        <DeleteMessageModal
          size="small"
          centered={false}
          visibility={deleteModalVisibility}
          loading={loadingDelete}
          onRemoveMessage={onDeleteMessage}
          onOpenModal={() => setDeleteModalVisibility(true)}
          onCloseModal={() => setDeleteModalVisibility(false)}
        />
      )}

      <div className={styles.backButtonContainer}>
        <button
          className={
            selectedOption !== 3
              ? styles.backButtonText
              : styles.backButtonTextMessage
          }
          onClick={() => history.goBack()}
        >
          {selectedOption !== 3 ? (
            <FaRegArrowAltCircleLeft
              className={styles.backButton}
              color="#C4C4C4"
              size={24}
            />
          ) : (
            <> Back to message </>
          )}
        </button>
      </div>

      <div className={styles.content}>
        <h1 className={styles.headerText}>Messages</h1>

        <text className={styles.headerSubText}>
          See the most important updates from the team at Holistic QIP
        </text>

        {/* <div className={styles.cardContainer}>
          <div className={styles.navigationOptionsRow}>
            <div
              className={
                selectedOption === 1
                  ? styles.navigationOptionSelected
                  : styles.navigationOption
              }
              onClick={() => onNavigateTab(1)}
            >
              <text className={styles.navigationOptionsText}>Inbox</text>

              <div
                className={`${styles.messageCounterGroup} ${styles.messageLeft}`}
              >
                <text className={styles.numberOfMessages}>
                  {unreadMessages}
                </text>

                <text className={styles.messagesText}>unread</text>
              </div>

              <div
                className={`${styles.messageCounterGroup} ${styles.messageRight}`}
              >
                <text className={styles.numberOfMessages}>
                  {importantMessages}
                </text>

                <text className={styles.messagesText}>important</text>
              </div>
            </div>

            <div
              className={
                selectedOption === 2
                  ? styles.navigationOptionSelected
                  : styles.navigationOption
              }
              onClick={() => onNavigateTab(2)}
            >
              Compose a message
            </div>
          </div>
        </div> */}
      </div>

      <div className={`${styles.cardContent} mt-16`}>
        <div
          className={`${styles.messagesInfoRow} flex flex-start items-center`}
        >
          <div
            className={`${styles.messagesInfo} flex flex-start items-center`}
          >
            <div className={`mr-10 border-r border-r-4 pr-10`}>
              <span className={`${styles.inboxHeader} mr-4`}>Inbox</span>{" "}
              <span className={styles.inboxHeaderCount}>
                {messages ? messages.inboxCount : 0}
              </span>
            </div>
            <div>
              <span className={`${styles.unreadHeaderCount} mr-4`}>
                {messages ? messages.unreadCount : 0}
              </span>
              <span className={styles.unreadHeader}>Unread</span>
            </div>
          </div>
        </div>
        <DisplayTabChoice />
      </div>
    </div>
  );
}

export default MessageBoard;
