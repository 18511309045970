import * as React from "react";

import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import styles from "./BlueButton.module.css";

function BlueButton({
  className,
  link,
  buttonText,
  disabled,
  width,
  height,
  onClick,
  ...rest
}) {
  const customSize = () => {
    if (width && !height) {
      return {
        width: `${width}`,
      };
    } else if (!width && height) {
      return {
        height: `${height}`,
      };
    }

    return {
      width: `${width}`,
      height: `${height}`,
    };
  };

  return link ? (
    <Link className={className} to={link}>
      <button
        disabled={disabled}
        className={styles.buttonContainer}
        style={width || height ? customSize() : null}
        onClick={onClick}
        {...rest}
      >
        {buttonText}
      </button>
    </Link>
  ) : (
    <div className={className}>
      <button
        disabled={disabled}
        className={styles.buttonContainer}
        style={width || height ? customSize() : null}
        onClick={onClick}
        {...rest}
      >
        {buttonText}
      </button>
    </div>
  );
}

BlueButton.defaultProps = {
  link: "",
};

BlueButton.propTypes = {
  link: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
  buttonText: PropTypes.string,
};

export default BlueButton;
