import * as React from 'react';

import PropTypes from 'prop-types';
import { Button, Modal } from 'semantic-ui-react';
import SkipButton from '../../../components/SkipButton';
import styles from './CancelSubscriptionModal.module.css';

const { useState } = React;

function CancelSubscriptionModal({ visibility, onOpenModal, onCloseModal, onCancel }) {

  const [cancelButtonSelected, setCancelButtonSelected] = useState(1);

  return (
    <Modal
      size="small"
      centered
      open={visibility}
      onOpen={onOpenModal}
      onClose={onCloseModal}
    >
      <div className={styles.modalContainer}>

        <Button
          className={styles.closeButton}
          onClick={onCloseModal}
          size="big"
          icon="close"
        />

        <text className={styles.headerText}>
          Which subscriptions do you want to cancel?
        </text>

        <div className={styles.subscriptionLists}>
          <div className={styles.subscription}>
            <text className={styles.subscriptionName}>
              Little Smarties
            </text>
            <div className={styles.buttonGroup}>

              <Button
                className={cancelButtonSelected === 1 ? styles.cancelButtonSelected : styles.cancelButton}
                onClick={() => setCancelButtonSelected(1)}
                size="big"
                icon="close"
              />
              <text className={styles.cancelText}>
                Cancel
              </text>
            </div>
          </div>
          <div className={styles.subscription}>
            <text className={styles.subscriptionName}>
              Little Smarties
            </text>
            <div className={styles.buttonGroup}>
              <Button
                className={cancelButtonSelected === 2 ? styles.cancelButtonSelected : styles.cancelButton}
                onClick={() => setCancelButtonSelected(2)}
                size="big"
                icon="close"
              />
              <text className={styles.cancelText}>
                Cancel
              </text>
            </div>
          </div>
          <div className={styles.subscription}>
            <text className={styles.subscriptionName}>
              Little Smarties
            </text>
            <div className={styles.buttonGroup}>
              <Button
                className={cancelButtonSelected === 3 ? styles.cancelButtonSelected : styles.cancelButton}
                onClick={() => setCancelButtonSelected(3)}
                size="big"
                icon="close"
              />
              <text className={styles.cancelText}>
                Cancel
              </text>
            </div>
          </div>
        </div>

        <div className={styles.buttonContainer}>
          <SkipButton
            buttonText="Cancel subscription"
            className={styles.cancelSubscription}
            onClick={onCancel}
          />

        </div>
      </div>

    </Modal>
  );
}

CancelSubscriptionModal.propTypes = {
  visibility: PropTypes.bool.isRequired,
  onOpenModal: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSavePassword: PropTypes.func.isRequired,
};

export default CancelSubscriptionModal;
