import React from "react";

const { useState } = React;

const Input = ({
  required = true,
  className,
  labelClassName,
  inputClassName,
  label,
  placeholder = "",
  value = "",
  isEmail = false,
  isPhone = false,
  isNumeric = false,
  checkIsValidEmail,
  validEmail = false,
  onChange = () => null,
}) => {
  const testEmailValue = (val) => {
    checkIsValidEmail(/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(val));
  };

  const onValueChange = (val) => {
    if (isEmail) {
      testEmailValue(val);
      onChange(val);
      return;
    }
    if (isPhone) {
      if (/^[\d-()+]*$/.test(val)) {
        onChange(val);
      }
      return;
    }

    if (isNumeric) {
      if (/^[\d]*$/.test(val)) {
        onChange(val);
      }
      return;
    }

    onChange(val);
  };

  return (
    <label className={`${className}`}>
      <span className={`${labelClassName} pb-1`}>
        {label}
        {required && <span className={`text-red-400`}>*</span>}
      </span>

      <input
        style={{
          border:
            !value && required ? "1px solid #F56666" : "1px solid #000000",
        }}
        type={`text`}
        className={`${inputClassName} w-full p-4`}
        placeholder={placeholder}
        value={value}
        onChange={(e) => {
          onValueChange(e.target.value);
        }}
      />
      <div className={`text-red-400 px-1 py-1 h-4`}>
        {required && !value && <span>This field is required</span>}
        {required && value && isEmail && !validEmail && (
          <span>Please provide a valid email address</span>
        )}
      </div>
    </label>
  );
};

export default Input;
