import React from "react";
import styles from "./Checkbox.module.css";
import { FaCheck, FaSpinner } from "react-icons/fa";

const { useState } = React;

const Checkbox = ({
  CheckIcon = FaCheck,
  loading = false,
  disabled = false,
  onToggle = () => null,
  checked = false,
  style = {
    width,
    height,
    backgroundColor,
    color,
    borderRadius,
    fontWeight,
    fontSize,
    lineHeight,
  },
}) => {
  const {
    width,
    height,
    backgroundColor,
    color,
    borderRadius,
    fontWeight,
    fontSize,
    lineHeight,
  } = style;

  const [hover, setHover] = useState(false);

  return (
    <div
      className={`${styles.checkBox}`}
      onMouseEnter={() => {
        setHover(true);
      }}
      onMouseLeave={() => {
        setHover(false);
      }}
      style={{
        width: width || "35px",
        height: height || "35px",
        backgroundColor: checked
          ? `${backgroundColor || "#8bc334"}`
          : "#ffffff",
        borderRadius: borderRadius || "3px",
        fontWeight: fontWeight || "900",
        fontSize: fontSize || "15px",
        lineHeight: lineHeight || "32px",
        border: checked ? `0px` : "1px solid #E4E4E4",
      }}
      onClick={() => {
        if (!disabled) {
          onToggle(checked);
        }
      }}
    >
      {loading ? (
        <FaSpinner
          className={`animate-spin`}
          style={{
            color: checked
              ? `${color || "#ffffff"}`
              : `${backgroundColor || "#8bc334"}`,
          }}
        />
      ) : (
        <CheckIcon
          style={{
            color: checked
              ? `${color || "#ffffff"}`
              : (disabled ? false : hover)
              ? `${backgroundColor || "#8bc334"}`
              : "#E4E4E4",
          }}
        />
      )}
    </div>
  );
};

export default Checkbox;
