import * as React from "react";

import PropTypes from "prop-types";
import { Button, Dropdown, Modal } from "semantic-ui-react";

import styles from "./NewServicesModal.module.css";
import BlueButton from "../../BlueButton";
import InputComponent from "../../InputComponent";
import { useStoreActions, useStoreState } from "../../../hooks";
import PasswordSavedModal from "../PasswordSavedModal";
import { FaSpinner } from "react-icons/fa";

const { useCallback, useReducer, useState, useEffect } = React;

const serviceTypeOption = [
  {
    key: "1",
    value: "Long Daycare",
    text: "Long Daycare",
  },
  {
    key: "2",
    value: "Family Daycare",
    text: "Family Daycare",
  },
  {
    key: "3",
    value: "Pre-school",
    text: "Pre-school",
  },
  {
    key: "4",
    value: "OOSH",
    text: "OOSH",
  },
];

function NewServicesModal({ visibility, onOpenModal, onCloseModal }) {
  const [fname, set_fname] = useState("");
  const [lname, set_lname] = useState("");
  const [email, set_email] = useState("");
  const [new_service_name, set_new_service_name] = useState("");
  const [current_service_name, set_current_service_name] = useState("");
  const [
    new_service_approval_number,
    set_new_service_approval_number,
  ] = useState("");
  const [
    current_service_approval_number,
    set_current_service_approval_number,
  ] = useState("");
  const [new_service_type, set_new_service_type] = useState("");
  const [comments, set_comments] = useState("");
  const [modalVisibility, setModalVisibility] = useState(false);
  const [alertMsg, setAlertMsg] = useState(
    "We have received your request. We'll get in touch with you as soon as we process your request."
  );

  useStoreActions((actions) => actions.nqaStore.fetchServiceDetails);

  const services = useStoreState((state) => state.authStore.services);
  const userId = useStoreState((state) => state.authStore.authObject.userId);

  const apiURI = process.env.REACT_APP_API_URL;

  const [fieldChecked, setFieldChecked] = useState(false);

  const [submitting, setSubmitting] = useState(false);

  const handleFormSubmit = async () => {
    setFieldChecked(true);

    if (
      !(
        fname &&
        lname &&
        email &&
        new_service_name &&
        current_service_name &&
        new_service_approval_number &&
        current_service_approval_number &&
        new_service_type
      )
    ) {
      return;
    }

    setSubmitting(true);

    const payload = {
      center_id: services[0].id,
      user_id: userId,
      fname,
      lname,
      email,
      new_service_name,
      current_service_name,
      new_service_approval_number,
      current_service_approval_number,
      new_service_type,
      comments,
    };

    const rawResponse = await fetch(
      `${apiURI}/api/notifications/service/request`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      }
    );

    setSubmitting(false);

    setModalVisibility(true);
  };

  const onSaveConfirm = () => {
    onCloseModal(true);
    setModalVisibility(false);
  };

  const setStateVal = (key, value) => {
    switch (key) {
      case "fname":
        set_fname(value);
        break;
      case "lname":
        set_lname(value);
        break;
      case "email":
        set_email(value);
        break;
      case "new_service_name":
        set_new_service_name(value);
        break;
      case "new_service_approval_number":
        set_new_service_approval_number(value);
        break;
      case "current_service_name":
        set_current_service_name(value);
        break;
      case "current_service_approval_number":
        set_current_service_approval_number(value);
        break;
      case "new_service_type":
        set_new_service_type(value);
        break;
      case "comments":
        set_comments(value);
        break;
    }
  };

  return (
    <Modal
      size="medium"
      centered
      open={visibility}
      onOpen={onOpenModal}
      onClose={onCloseModal}
    >
      <PasswordSavedModal
        visibility={modalVisibility}
        onOpenModal={() => setModalVisibility(true)}
        onCloseModal={() => setModalVisibility(false)}
        onSaveConfirm={onSaveConfirm}
        content={alertMsg}
      />

      <div className={styles.modalContainer}>
        <Button
          className={styles.closeButton}
          onClick={onCloseModal}
          size="big"
          icon="close"
        />

        <div className={styles.modalContent}>
          <text className={styles.headerText}>Add a new service request</text>

          <text className={styles.subText}>
            Please complete the field below and we’ll be in touch about adding
            to your current subscription.
          </text>

          <div className={styles.inputGroup}>
            <div className={styles.inputColumn}>
              <InputComponent
                label="First name"
                type="text"
                required
                showNotFilled={fieldChecked && !fname}
                fieldName="fname"
                inputValue={fname}
                setInputValue={setStateVal}
              />

              <InputComponent
                label="Last name"
                type="text"
                required
                showNotFilled={fieldChecked && !lname}
                fieldName="lname"
                inputValue={lname}
                setInputValue={setStateVal}
              />
            </div>

            <div className={styles.inputColumn}>
              <InputComponent
                label="Email address"
                type="email"
                required
                showNotFilled={fieldChecked && !email}
                fieldName="email"
                inputValue={email}
                setInputValue={setStateVal}
              />

              <InputComponent
                label="Current Service name"
                type="text"
                required
                showNotFilled={fieldChecked && !current_service_name}
                fieldName="current_service_name"
                inputValue={current_service_name}
                setInputValue={setStateVal}
              />
            </div>

            <div className={styles.inputColumn}>
              <InputComponent
                className={styles.serviceNumberInput}
                label="Current Service approval number"
                type="text"
                required
                showNotFilled={fieldChecked && !current_service_approval_number}
                fieldName="current_service_approval_number"
                inputValue={current_service_approval_number}
                setInputValue={setStateVal}
              />

              <InputComponent
                label="NEW Service name"
                type="text"
                required
                showNotFilled={fieldChecked && !new_service_name}
                fieldName="new_service_name"
                inputValue={new_service_name}
                setInputValue={setStateVal}
              />
            </div>

            <div className={styles.inputColumn}>
              <InputComponent
                className={styles.serviceNumberInput}
                label="NEW Service approval number"
                type="text"
                required
                showNotFilled={fieldChecked && !new_service_approval_number}
                fieldName="new_service_approval_number"
                inputValue={new_service_approval_number}
                setInputValue={setStateVal}
              />

              <div className={styles.dropdownGroup}>
                <text className={styles.dropdownText}>
                  NEW Service type <span className={styles.required}>*</span>
                </text>

                <Dropdown
                  className={styles.dropdownBox}
                  fluid
                  selection
                  placeholder="Choose"
                  options={serviceTypeOption}
                  onChange={(e, data) =>
                    setStateVal("new_service_type", data.value)
                  }
                />
                {fieldChecked && !new_service_type && (
                  <div className={`text-red-400 p-2 font-thin italic`}>
                    This field is required.{" "}
                  </div>
                )}
              </div>
            </div>

            <div className={styles.inputTextAreaColumn}>
              <InputComponent
                className={styles.inputComments}
                label="Comments"
                type="text"
                textArea
                fieldName="comments"
                inputValue={comments}
                setInputValue={setStateVal}
              />
            </div>
          </div>

          <BlueButton
            disabled={submitting}
            className={styles.blueButton}
            buttonText={
              submitting ? (
                <div className={`flex justify-center items-center`}>
                  <FaSpinner className={`animate-spin`} />
                  <span className={`block pl-2`}>Send</span>
                </div>
              ) : (
                <> Send</>
              )
            }
            onClick={handleFormSubmit}
          />
        </div>
      </div>
    </Modal>
  );
}

NewServicesModal.propTypes = {
  visibility: PropTypes.bool.isRequired,
  onOpenModal: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired,
};

export default NewServicesModal;
