import * as React from 'react';

import { useHistory, useParams } from 'react-router-dom';
import { FaRegArrowAltCircleLeft } from 'react-icons/fa';

import BlueButton from '../../components/BlueButton';
import InputComponent from '../../components/InputComponent';
import PasswordSavedModal from '../../components/Modals/PasswordSavedModal';
import styles from './ForgotPassword.module.css';

const { useState } = React;

export default function ForgotPassword() 
{  const history = useHistory();

  const [modalVisibility, setModalVisibility] = useState(false);
  const [backToAuth, setBackToAuth] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [alertMsg, setAlertMsg] = useState("Please check your email for a password reset link");
  const { token } = useParams();
  const apiURI = process.env.REACT_APP_API_URL;

  const onSendCodeToEmail = async () => {
    const payload = {
      email
    }
    const rawResponse = await fetch(`${apiURI}/api/notifications/reset/request`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    });
    setBackToAuth(true);
    setModalVisibility(true);
  };

  const onSavePassword = async () => {
    if(password !== passwordConfirm) {
      setAlertMsg("Passwords do not match!");
      setModalVisibility(true);
      return;
    }

    if(password.length < 8) {
      setAlertMsg("Password must contain at least 8 characters");
      setModalVisibility(true);
      return;
    }

    const payload = {
      password,
      token,
    }
    const rawResponse = await fetch(`${apiURI}/api/notifications/reset/confirm`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    });
    setAlertMsg("You can now login with your new password");
    setBackToAuth(true);
    setModalVisibility(true);
  };

  const onSaveConfirm = () => {
    setModalVisibility(false);
    if(backToAuth) {
      history.push('/qip/auth');
    }
  };

  const setInputEmail = (field, value) => {
    setEmail(value);
  }

  const setInputPassword = (field, value) => {
    setPassword(value);
  }

  const setInputPasswordConfirm = (field, value) => {
    setPasswordConfirm(value);
  }

  return (
    <div className={styles.mainContainer}>

      <div className={styles.backButtonContainer}>

        <button
          className={styles.backButtonText}
          onClick={() => history.goBack()}
        >
          <FaRegArrowAltCircleLeft
            className={styles.backButton}
            color="#C4C4C4"
            size={24}
          />
        </button>

      </div>

      <PasswordSavedModal
        visibility={modalVisibility}
        onOpenModal={() => setModalVisibility(true)}
        onCloseModal={() => setModalVisibility(false)}
        onSaveConfirm={onSaveConfirm}
        content={alertMsg}
      />

      {!token
        ? (
          <div className={styles.mainCard}>

            <div className={styles.cardContent}>

              <div className={styles.textGroup}>

                <h2 className={styles.headerText}>
                  Forgot password?
                </h2>

                <div className={styles.bodyText}>
                  Enter your email address and we’ll send you a code to reset your password.
                </div>

              </div>

              <InputComponent
                label="Enter your email address here"
                type="email"
                fieldName="email"
                inputValue={email}
                setInputValue={setInputEmail}
              />

              <BlueButton
                className={styles.sendCodeButton}
                buttonText="Send code"
                onClick={onSendCodeToEmail}
              />

            </div>

          </div>
        )
        : (
          <div className={styles.mainCard}>

            <div className={styles.cardContent}>

              <div className={styles.textGroup}>

                <h2 className={styles.headerText}>
                  New password
                </h2>

                <div className={styles.bodyText}>
                  Choose your new password. Make sure to include a number to make it safe.
                </div>

              </div>

              <InputComponent
                label="Type new password"
                type="password"
                fieldName="password"
                inputValue={password}
                setInputValue={setInputPassword}
              />

              <InputComponent
                label="Repeat the password"
                type="password"
                fieldName="passwordConfirm"
                inputValue={passwordConfirm}
                setInputValue={setInputPasswordConfirm}
              />

              <BlueButton
                className={styles.sendCodeButton}
                buttonText="Save Password"
                onClick={onSavePassword}
              />

            </div>

          </div>
        )}
    </div>
  );
}
