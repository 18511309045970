import React from "react";
import styles from "./SatReportView.module.css";
import ServiceDetails from "../../../components/SatReport/ServiceDetails";
import OperatingTimes from "../../../components/SatReport/OperatingTimes";
import ServicePhilosophy from "../../../components/SatReport/ServicePhilosophy";
import NQASection from "../../../components/SatReport/NQASection";
import { fetchSATQuestions, getLawsRegulations } from "../../../api/requests";
import { useStoreActions, useStoreState } from "../../../hooks";

const { useState, useEffect, useCallback } = React;

const SatReportView = () => {
  const centerId = useStoreState((state) => state.nqaStore.serviceDetails.id);
  const [satQuestions, setSatQuestions] = useState([]);
  const [lawsRegulations, setLawsRegulations] = useState({});
  const [currentNqa, setCurrentNqa] = useState(1);

  const onFetchSatQuestions = async () => {
    try {
      const response = await fetchSATQuestions(centerId);
      setSatQuestions(response);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchLawsRegulations = useCallback(async () => {
    try {
      const response = await getLawsRegulations(currentNqa, centerId);

      setLawsRegulations(response);
    } catch (error) {
      console.log(error);
    }
  }, [currentNqa]);

  useEffect(() => {
    onFetchSatQuestions();
  }, []);

  useEffect(() => {
    fetchLawsRegulations();
  }, [currentNqa]);

  return (
    <div className={`${styles.satReportContainer} w-full mb-1 py-16`}>
      <div
        className={`${styles.footerHow} flex flex-col justify-center items-start mb-12 pb-12`}
      >
        <text
          className={`${styles.headerHow} md:px-8 lg:px-24 mb-2 font-medium`}
        >
          How does this work?
        </text>
        <text className={`${styles.headerText} text-justify md:pl-8 lg:pl-24`}>
          {`This Self Assessment Tool helps you identify and document your 5 Key Practices across all NQA areas, ready to upload to the Regulatory Authority Portal without having to duplicate effort. Easily add or update your service details, philosophy and service operating times. You can also assess and document your National Law & Regulations for each NQA.`}
        </text>
      </div>
      <ServiceDetails />
      <OperatingTimes />
      <ServicePhilosophy />
      <NQASection
        questions={satQuestions}
        refetchQuestions={() => onFetchSatQuestions()}
        lawsRegulations={lawsRegulations}
        fetchLawsRegulations={(nqa) => {
          if (currentNqa !== nqa) {
            setCurrentNqa(nqa);
          } else {
            fetchLawsRegulations();
          }
        }}
      />
    </div>
  );
};

export default SatReportView;
