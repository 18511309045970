import * as React from "react";

import PropTypes from "prop-types";
import { Button, Modal } from "semantic-ui-react";

import BlueButton from "../../../components/BlueButton";
import styles from "./NoticeCancelSubscriptionModal.module.css";

function NoticeCancelSubscriptionModal({
  visibility,
  onOpenModal,
  onCloseModal,
  onRemoveMessage,
}) {
  return (
    <Modal
      size="small"
      centered
      open={visibility}
      onOpen={onOpenModal}
      onClose={onCloseModal}
    >
      <div className={styles.modalContainer}>
        <Button
          className={styles.closeButton}
          onClick={onCloseModal}
          size="big"
          icon="close"
        />

        <text className={styles.text}>
          Your subscription for cancelled services will remain active until the
          end of your subscription period. We would recommend you download your
          latest QIP reports for each service before your subscription ends.
        </text>

        <div className={styles.buttonGroup}>
          <BlueButton
            onClick={onCloseModal}
            className={styles.blueButton}
            buttonText="Go Back"
          />

          <button className={styles.nextTipButton} onClick={onRemoveMessage}>
            Get Reports
          </button>
        </div>
      </div>
    </Modal>
  );
}

NoticeCancelSubscriptionModal.propTypes = {
  visibility: PropTypes.bool.isRequired,
  onOpenModal: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  onRemoveMessage: PropTypes.func.isRequired,
};

export default NoticeCancelSubscriptionModal;
