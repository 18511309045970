import * as React from "react";

import PropTypes from "prop-types";
import { Button, Modal } from "semantic-ui-react";

import BlueButton from "../../BlueButton";
import styles from "./NoticeReactivateSubscriptionModal.module.css";

function NoticeReactivateSubscriptionModal({
  visibility,
  onOpenModal,
  onCloseModal,
  onRemoveMessage,
}) {
  return (
    <Modal
      size="small"
      centered
      open={visibility}
      onOpen={onOpenModal}
      onClose={onCloseModal}
    >
      <div className={styles.modalContainer}>
        <Button
          className={styles.closeButton}
          onClick={onCloseModal}
          size="big"
          icon="close"
        />

        <text className={styles.text}>
          Your subscription has been reactivated!
        </text>

        <div className={styles.buttonGroup}>
          <BlueButton
            onClick={onCloseModal}
            className={styles.blueButton}
            buttonText="Ok"
          />

          {/* <button className={styles.nextTipButton} onClick={onRemoveMessage}>
            Get Reports
          </button> */}
        </div>
      </div>
    </Modal>
  );
}

NoticeReactivateSubscriptionModal.propTypes = {
  visibility: PropTypes.bool.isRequired,
  onOpenModal: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  onRemoveMessage: PropTypes.func.isRequired,
};

export default NoticeReactivateSubscriptionModal;
