import * as React from "react";

import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";

import Payment from "../Payment";
import LoginCard from "../../pages/LoginCard";
import LoginOnly from "../../pages/LoginOnly";
import ServiceDetails from "../ServiceDetails";
import LegalNotice from "../../pages/LegalNotice";
import PricingPlans from "../../pages/PricingPlans/NonAuth/PricingPlans";
import FreeTrialForm from "../../pages/FreeTrialForm";
import PaymentSignupForm from "../../pages/PaymentSignUp/PaymentSignUpForm";
import ForgotPassword from "../../pages/ForgotPassword";
import ServiceSignup from "../ServiceSignup/ServiceSignup";
import ViewServiceDashboard from "../ViewServiceDashboard/ViewServiceDashboard";

import LogoutRedirect from "../../pages/LogoutRedirect";

const { useState } = React;

export default function LoggedOutPages() {
  const match = useRouteMatch();

  const [planChoice, setPlanChoice] = useState(""); // portal or mentoring
  const [planSubscription, setPlanSubscription] = useState("monthly"); // monthly or yearly

  return (
    <Switch>
      <Route
        exact
        path={`${match.path}/forgot-password`}
        component={ForgotPassword}
      />
      <Route
        path={`${match.path}/forgot-password/:token`}
        component={ForgotPassword}
      />

      {/* <Route exact path={`${match.path}/login`} component={LoginOnly} /> */}

      <Route exact path={`${match.path}/login`} component={LogoutRedirect} />

      <Route
        exact
        path={`${match.path}/pricing-plans`}
        render={() => (
          <PricingPlans
            setPlanChoice={setPlanChoice}
            setPlanSubscription={setPlanSubscription}
          />
        )}
      />
      <Route
        exact
        path={`${match.path}/free-trial`}
        component={FreeTrialForm}
      />
      <Route
        exact
        path={`${match.path}/signup`}
        render={() => (
          <PaymentSignupForm
            planSubscription={planSubscription}
            planChoice={planChoice}
          />
        )}
      />
      <Route
        exact
        path={`${match.path}/payment`}
        render={() => (
          <Payment
            planVariant={planSubscription}
            plan={planChoice}
            stripeState={"nonauth"}
          />
        )}
      />
      <Route
        exact
        path={`${match.path}/legal-notice`}
        component={LegalNotice}
      />
      {/* <Route path={`${match.path}/onboarding`} component={ServiceDetails} /> */}
      <Route
        path={`${match.path}/onboarding`}
        render={() => <ServiceDetails onboarding={true} />}
      />

      <Route
        path={`${match.path}/finish-free-trial-signup`}
        render={() => <ServiceSignup />}
      />
      {/* 
      <Route
        path={`${match.path}/view-service-dashboard`}
        render={() => <ViewServiceDashboard />}
      /> */}

      {/* <Route exact path={`${match.path}`} component={LoginCard} /> */}
      <Route exact path={`${match.path}`} component={LogoutRedirect} />
    </Switch>
  );
}
