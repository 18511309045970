import * as React from 'react';

import { useHistory, useRouteMatch, useParams } from 'react-router-dom';
import { FaRegArrowAltCircleLeft, FaChevronRight, FaChevronLeft } from 'react-icons/fa';
import styles from './ResourceCenterWebinars.module.css';
import prod1 from '../../../static/images/critical-reflection-the-holistic-way.jpg';
import { Button, Button as IconButton } from 'semantic-ui-react';
import BlueButton from '../../../components/BlueButton';

const { useState } = React;  

export default function ResourceCenterWebinars() {
  const history = useHistory();
  const match = useRouteMatch();
  const { webinarsChoiceId } = useParams();
  const [selectedOption, setOption] = useState(parseInt(webinarsChoiceId));
  const [nqaVisibility, setNQAVisibility] = useState(false);

  const onBackToResources = () => {
    const currentPath = match.path.split('/');
    const dashboardPath = currentPath.slice(0, currentPath.length - 1).join('/');

    history.push(dashboardPath);
  };

  return (
    <div className={styles.mainContainer}>

      <div className={styles.backButtonContainer}>

        <button
          className={styles.backButtonText}
          onClick={() => history.goBack()}
        >
          <FaRegArrowAltCircleLeft
            className={styles.backButton}
            color="#C4C4C4"
            size={24}
          />
        </button>

      </div>  
      
      <div className={styles.resourcesContainer}> 

        <div className={styles.webinarsResourceHeader}>

          <div className={styles.headerTextGroup}>

            <span className={styles.userDashboardText}>
              Resource Centre
            </span>

            <span className={styles.userDashboardAltText}>
              Find resources to help you with your QIP/SAT and to navigate the Holistic QIP App.
            </span>

          </div>
          <button
            className={styles.backToDashboardButton}
            onClick={() => history.push('/qip/user-resource/')}
          >
            Back to dashboard
          </button>

        </div>

        <div className={styles.chooseNQA}>

          <span className={styles.havingProblemText}>
            Having a problem with NQA&apos;s? Click here and choose.
          </span>
  
          <div>
            {/* Show hide this buttons if Clicked */}
            {!nqaVisibility
              ? (
                <IconButton
                  className={styles.ArrowButton}
                  icon="arrow down"
                  circular
                  onClick={() => setNQAVisibility(prev => !prev)}
                />
              )
              : (
                <IconButton
                  className={styles.ArrowButton}
                  icon="arrow up"
                  circular
                  onClick={() => setNQAVisibility(false)}
                />
              )
            }
          </div>
        </div>

        {/* Show Hide this section if Button is Clicked */}
        {nqaVisibility
          && (
            <div className={styles.nqaSelectionGroup}>
              <div className={styles.nqaStagesRow}>
                {[...Array(7).keys()].map(stage => (
                  <div
                    key={stage}
                    className={styles.nqaStageBubble}
                  >
                    NQA{stage + 1}
                  </div>
                ))}
              </div>
              {[...Array(3).keys()].map(row => (
                <div
                  key={row}
                  className={styles.nqaLevelsRow}
                >
                  {[...Array(7).keys()].map(level => (
                    <div
                      key={level}
                      className={styles.nqaLevelBubble}
                    >
                      {level + 1}.{row + 1}
                    </div>
                  ))}
                </div>
              ))}
            </div>
          )}
        {/* End Of View When Clicked */}

        <div className={styles.navigationOptionsRow}>

          <div
            className={selectedOption === 1 ? styles.navigationOptionSelected : styles.navigationOption}
            onClick={() => setOption(1)}
          >
            QIP/SAT resources
          </div>

          <div
            className={`${selectedOption === 2 ? styles.navigationOptionSelected : styles.navigationOption} ${styles.middleNavigationOptionBorder}`}  
            onClick={() => setOption(2)}
          >
            Shop resources
          </div>

          <div
            className={selectedOption === 3 ? styles.navigationOptionSelected : styles.navigationOption}
            onClick={() => setOption(3)}
          >
            App tutorials
          </div>

        </div>

        <div className={styles.contentHolder}>

          {

            selectedOption === 2 ?
              <>
              <div className={styles.shopResourcesContainer}>

                <div className={styles.shopResourcesImg}>
                  <img src={prod1}/>
                </div>

                <div className={styles.shopResourcesContent}>

                  <h2>Holistic Critical Reflection</h2>

                  <text className={`block font-medium mb-1.5`}>Over 100 Critical Reflection tools across all 7 NQA</text>

                  <text className={`block font-light`}>A complete guide to How, What and Why Critically Reflect in your service.</text>

                  <ul>
                    <li>300 NQF evidences for an exceeding achievement.</li>
                    <li>Over 250 QIP Goals and Strategies</li>
                    <li>Over 120 Checklists, forms, templates and surveys to help any director!</li>
                  </ul>

                  <text className={`${styles.prize} block font-normal`}>$147.00 inc.GST</text>

                  <BlueButton
                    className={`${styles.viewButton}`}
                    buttonText="View Product"
                    onClick={() =>
                      (window.location.href = "https://holisticmanagement.net.au/product/")
                    }
                  />

                </div>

              </div>

              <div className={`${styles.resourcesPagination} flex justify-between`}>

                <Button>
                  <FaChevronLeft className={`mr-1`} size={12} />
                  Previous resource
                </Button>

                <Button>
                  All resources
                </Button>

                <Button>
                  Next resource
                  <FaChevronRight className={`ml-1`} size={12} />
                </Button>

              </div>

              </>

              :

              <>

              <div className={styles.knowledgebaseContent}>

                <div className={styles.webinarContainer}>

                  <div className={styles.webinarTextGroup}>

                    <text className={styles.webinarLabel}>
                      { selectedOption === 1 ? 'Webinars' : 'Webinars' }
                    </text>

                    <text className={styles.webinarHeaderTitle}>
                      { selectedOption === 1 ? 'How to create engaging mind maps' : 'QIP app basic tutorial for complete beginners' }
                    </text>

                  </div>

                  <Button
                    className={styles.playButton}
                    size="massive"
                    icon="play"
                    circular
                  />

                </div>

                <div className={styles.webinarDescriptionRow}>

                  <div className={styles.webinarDescriptionTextColumn}>

                    <text className={styles.webinarDescriptionHeader}>
                      { selectedOption === 1 ? 'Webinar description' : 'Tutorial description' }
                    </text>

                    <text className={`text-xl block font-semibold mb-6`}>
                      Viewing time: 3 minutes
                    </text>

                    { selectedOption === 1 ?
              
                      <p className={styles.webinarDescriptionBody}>
                        Curriculum decision making contributes to each child’s learning and development outcomes in relation to their identity, connection with the community, wellbeing, confidence as learners and effectiveness as communicators.
                        <br />
                        <br />
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Parturient montes nascetur   ridiculus mus mauris vitae ultricies leo integer. Laoreet id donec ultrices tincidunt arcu. Viverra mauris in aliquam sem. In vitae turpis massa sed.
                        <br />
                        Eget gravida cum sociis natoque penatibus et magnis dis parturient. In tellus integer feugiat scelerisque varius morbi. Aliquam sem et tortor consequat id porta. Ac turpis egestas maecenas pharetra convallis posuere.
                        Non odio euismod lacinia at quis risus sed vulputate. Diam sollicitudin tempor id eu nisl nunc mi ipsum faucibus. Pharetra vel turpis nunc eget lorem dolor.
                      </p>
              
                      : 
  
                      <p className={styles.webinarDescriptionBody}>
                        Curriculum decision making contributes to each child’s learning and development outcomes in relation to their identity, connection with the     community, wellbeing, confidence as learners and effectiveness as communicators.
                        <br />
                        <br />
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Parturient montes nascetur ridiculus mus mauris vitae ultricies leo integer. Laoreet id donec ultrices tincidunt arcu. Viverra mauris in aliquam sem. In vitae turpis massa sed.
                        <br />
                        <br />
                        Eget gravida cum sociis natoque penatibus et magnis dis parturient. In tellus integer feugiat scelerisque varius morbi. Aliquam sem et tortor consequat id porta. Ac turpis egestas maecenas pharetra convallis posuere.
                      Non odio euismod lacinia at quis risus sed vulputate. Diam sollicitudin tempor id eu nisl nunc mi ipsum faucibus. Pharetra vel turpis nunc eget lorem dolor.
                      </p>  
              
                    }

                  </div>

                  <div className={styles.webinarDetailsColumn}>

                    <text className={styles.webinarDetailLabel}>
                      { selectedOption === 1 ? 'Webinar by' : 'Tutorial by' }
                    </text>

                    <text className={styles.webinarDetailText}>
                      { selectedOption === 1 ? 'Johnattan Patterson' : 'Johnattan Patterson' }
                    </text>

                    <text className={styles.webinarDetailLabel}>
                      Posted on
                    </text>

                    <text className={styles.webinarDetailText}>
                      { selectedOption === 1 ? '12/11/2020' : '12/11/2020' }
                    </text>

                    {selectedOption === 1
                      && (
                        <>
                          <text className={styles.webinarDetailLabel}>
                            Useful for
                          </text>
  
                          <div style={{ marginTop: '1.5rem' }}>

                            <text className={`${styles.nqaTags} ${styles.nqa1}`}>
                              NQA 1.1
                            </text>

                            <text className={`${styles.nqaTags} ${styles.nqa7}`}>
                            NQA 7.1
                            </text>

                          </div>                  
                        </>
                      )
                    }

                  </div>

                </div>

              </div>

              <div className={`${styles.resourcesPagination} flex justify-between`}>

                <Button>
                  <FaChevronLeft className={`mr-1`} size={12} />
                  Previous tutorial
                </Button>

                <Button>
                  All tutorials
                </Button>

                <Button>
                  Next tutorial
                  <FaChevronRight className={`ml-1`} size={12} />
                </Button>

              </div>

              </>

          }

        </div>
      
      </div>  
        
    </div>
  );
}
