// import lib
import * as React from 'react';
const { useContext, useEffect, useState } = React;
import PropTypes from 'prop-types';
import { Icon } from 'semantic-ui-react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { FaSpinner } from 'react-icons/fa';

// import components
import styles from './NumberOfEducators.module.css';
import BlueButton from '../../../components/BlueButton';
import { ServiceDetailsContext } from '../../../context/ServiceDetailsContext';
import { useStoreState } from '../../../hooks';
import { ServiceSteps } from '../../../components/Steps';

// Number of educators component
function NumberOfEducators({ onNextStep, onSaveStep, onSkipServiceDetails }) {
	const match = useRouteMatch();
	const history = useHistory();
	const isLoggedIn = useStoreState(state => state.authStore.isLoggedIn);
	const [fieldChecked, setFieldChecked] = useState(false);
	const [errors, setErrors] = useState([]);

	const {
		saveLoading,
		serviceDetailsState,
		changeInputDispatch,
		stepsIndicator,
	} = useContext(ServiceDetailsContext);

	const [initialEducators, setInitialEducators] = useState(
		serviceDetailsState.input.educators != undefined
			? serviceDetailsState.input.educators.length > 0
				? serviceDetailsState.input.educators
				: []
			: []
	);

	const [educators, setEducators] = useState(
		serviceDetailsState.input.educators
			? serviceDetailsState.input.educators.length
			: 0
	);

	const setEducatorDetails = (index, fieldInput, value) => {
		setErrors([]);
		const currentEducators = serviceDetailsState.input.educators
			? [...serviceDetailsState.input.educators]
			: [
					{
						id: index,
						name: '',
						address: '',
						qualifications: '',
					},
			  ];

		if (currentEducators.find(educator => educator.id === index)) {
			currentEducators[
				currentEducators.findIndex(educator => educator.id === index)
			][fieldInput] = value;
		} else {
			const educatorDetail = {
				id: index,
				name: '',
				address: '',
				qualifications: '',
			};

			educatorDetail[fieldInput] = value;
			currentEducators.push(educatorDetail);
		}

		changeInputDispatch('educators', currentEducators);
	};

	// field validation
	const EducatorsValidation = () => {
		let tempErrors = [];

		if (educators <= 0) return tempErrors;

		Array.from({ length: educators }, (v, index) => {
			let message = '';
			let field = 'all';
			const filterItems = serviceDetailsState?.input?.educators.filter(
				item => item.id === index + 1
			);

			if (serviceDetailsState.input.educators && filterItems.length === 0) {
				field = 'all';
				message = 'This field is required.';
			} else {
				if (!filterItems[0].name) {
					field = 'name';
					message = 'This field is required.';
				}
			}

			if (message.length > 0) {
				if (!tempErrors.some(error => error.index === index)) {
					const tempError = {
						index: index,
						field: field,
						message: message,
					};
					tempErrors = [...tempErrors, tempError];
				}
			}
		});

		setErrors([...tempErrors]);

		return tempErrors.length <= 0;
	};

	// save service details
	const onSave = async () => {
		setFieldChecked(true);

		if (!EducatorsValidation()) {
			return;
		}

		if (educators <= 0) {
			return;
		}

		await onSaveStep();

		onNextStep();
		history.push(
			`${match.path
				.split('/')
				.slice(0, match.path.split('/').length - 1)
				.join('/')}/10`
		);
		// } else alert("Please fill all required fields.");
	};

	useEffect(() => {
		if (educators < 0) setEducators(0);
	}, [educators]);

	// Input value change
	const setAdditionalInputValue = (key, itemName) => {
		if (!key) return '';

		if (serviceDetailsState?.input?.educators.length > 0) {
			const filterItems = serviceDetailsState?.input?.educators.filter(
				item => item.id === key
			);

			return filterItems.length > 0 ? filterItems[0][itemName] : '';
		}

		return '';
	};

	return (
		<div className={styles.mainContainer}>
			{isLoggedIn && (
				<>
					<div className={styles.headerContainer}>
						<h1>Complete your service details</h1>
						<p>
							Please fill out all missing fields marked with an ‘
							<span className={styles.required}>*</span>’
						</p>
					</div>
				</>
			)}

			<div className={styles.mainCard}>
				<div className={styles.cardContent}>
					<h1 className={`${styles.headerText} overflow-clip`}>
						Number of educators at the service
					</h1>

					<text className={styles.pleaseComplete}>
						Please complete the below fields.
					</text>

					<text className={styles.descriptionText}>
						How many educators are registered at your service?
					</text>

					<input
						className={styles.workforceVal}
						type="number"
						value={educators}
						onChange={e => {
							setErrors([]);

							if (!isNaN(e.target.value)) {
								changeInputDispatch(
									'educators',
									initialEducators.slice(0, Math.abs(parseInt(e.target.value)))
								);
							}
							setEducators(Math.abs(parseInt(e.target.value)));
						}}
					/>
					{!(educators > 0) && fieldChecked && (
						<div className={`p-2 text-red-400 font-thin`}>
							Please provide number of educators registered at your service.{' '}
						</div>
					)}

					<div>
						{educators > 0
							? Array.from({ length: educators }, (v, index) => (
									<React.Fragment key={index}>
										<div className={styles.educatorEntry}>
											<div className={`flex-none`}>
												<text className={styles.inputFieldHeader}>
													Educator full name{' '}
													<span className={styles.required}>*</span>
												</text>

												<input
													className={styles.inputBox}
													type="text"
													value={setAdditionalInputValue(index + 1, 'name')}
													onChange={e =>
														setEducatorDetails(
															index + 1,
															'name',
															e.target.value
														)
													}
												/>
												<span className={`p-1 text-red-400 font-thin`}>
													{errors.map(error => {
														if (error.index === index) {
															if (
																error.field === 'all' ||
																error.field === 'name'
															) {
																return error.message;
															}
														}
													})}
												</span>
											</div>

											<div className={`flex-none`}>
												<text className={styles.inputFieldHeader}>
													Educator address
												</text>

												<input
													className={styles.inputBox}
													type="text"
													value={setAdditionalInputValue(index + 1, 'address')}
													onChange={e =>
														setEducatorDetails(
															index + 1,
															'address',
															e.target.value
														)
													}
												/>
											</div>

											<div className={`flex-grow lg:mt-2 mt-3`}>
												<text className={styles.inputFieldHeader}>
													Educator qualifications
												</text>

												<input
													className={styles.inputBox}
													type="text"
													value={setAdditionalInputValue(
														index + 1,
														'qualifications'
													)}
													onChange={e =>
														setEducatorDetails(
															index + 1,
															'qualifications',
															e.target.value
														)
													}
												/>
												<span className={`p-1 text-red-400 font-thin`}></span>
											</div>
										</div>
									</React.Fragment>
							  ))
							: null}
					</div>

					<div className={styles.buttonHolders}>
						<BlueButton // buttonText="Save step"
							buttonText={
								saveLoading ? (
									<div className={`flex justify-center items-center`}>
										<FaSpinner className={`animate-spin`} />
										<span className={`block pl-2`}>Save step</span>
									</div>
								) : (
									<> Save step</>
								)
							}
							onClick={onSave}
						/>

						<button
							className={styles.buttonNextStep}
							onClick={() =>
								history.push(
									`${match.path
										.split('/')
										.slice(0, match.path.split('/').length - 1)
										.join('/')}/10`
								)
							}
						>
							Next step
						</button>
					</div>

					<div className={styles.buttonStep}>
						<button
							className={styles.buttonStepBack}
							onClick={() =>
								history.push(
									`${match.path
										.split('/')
										.slice(0, match.path.split('/').length - 1)
										.join('/')}/8`
								)
							}
						>
							<Icon name="angle left" />
							Back a step
						</button>

						<button
							className={styles.buttonFinishLater}
							onClick={onSkipServiceDetails}
						>
							Finish Later
							<Icon name="angle double right" />
						</button>
					</div>
				</div>

				<div className={styles.stepsColumn}>
					<ServiceSteps route={match.path} />
				</div>
			</div>
		</div>
	);
}

NumberOfEducators.propTypes = {
	onNextStep: PropTypes.func,
	onSkipStep: PropTypes.func,
	onSaveStep: PropTypes.func,
	onSkipServiceDetails: PropTypes.func,
};

export default NumberOfEducators;
