import * as React from 'react';

import { Route, Switch, useRouteMatch } from 'react-router-dom';

import EditSubscription from '../../pages/Billing/EditSubscription';
import DowngradeSubscription from '../../pages/Billing/DowngradeSubscription';
import UpgradeSubscription from '../../pages/Billing/UpgradeSubscription';

export default function UserResource() {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route
        exact
        path={`${match.path}/edit-subscription`}
        component={EditSubscription}
      />     
      <Route
        exact
        path={`${match.path}/upgrade-subscription`}
        component={UpgradeSubscription}
      /> 
      <Route
        exact
        path={`${match.path}/downgrade-subscription`}
        component={DowngradeSubscription}
      />
    </Switch>
  );
}
