import * as React from "react";

import PropTypes from "prop-types";

import styles from "./SkipButton.module.css";
import { Link } from "react-router-dom";

function SkipButton({
  className,
  link,
  buttonText,
  onClick,
  width,
  height,
  disabled = false,
}) {
  const customSize = () => {
    if (width && !height) {
      return {
        width: `${width}`,
      };
    } else if (!width && height) {
      return {
        height: `${height}`,
      };
    }

    return {
      width: `${width}`,
      height: `${height}`,
    };
  };

  return link ? (
    <Link className={className} to={link}>
      <button
        className={styles.buttonContainer}
        style={width || height ? customSize() : null}
        onClick={onClick}
      >
        {buttonText}
      </button>
    </Link>
  ) : (
    <button
      disabled={disabled}
      className={
        className
          ? `${
              !disabled ? styles.buttonContainer : styles.disabled
            } ${className}`
          : `${!disabled ? styles.buttonContainer : styles.disabled}`
      }
      style={width || height ? customSize() : null}
      onClick={onClick}
    >
      {buttonText}
    </button>
  );
}

SkipButton.propTypes = {
  link: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
  buttonText: PropTypes.string,
};

export default SkipButton;
