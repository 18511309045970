import React from "react";
import Modal from "@material-ui/core/Modal";
import { makeStyles } from "@material-ui/core/styles";
import styles from "./ChooseService.module.css";
import { FaTimes } from "react-icons/fa";

import { useStoreActions, useStoreState } from "../../../hooks";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: "#ffffff",
    width: "500px",
    height: "400px",
    padding: theme.spacing(2, 4, 3),
  },
}));

const ChooseService = ({ isOpen, setIsOpen }) => {
  const classes = useStyles();

  const isLoggedIn = useStoreState((state) => state.authStore.isLoggedIn);

  const services = useStoreState((state) => state.authStore.services);

  const selectedService = useStoreState(
    (state) => state.authStore.selectedService
  );

  const setSelectedService = useStoreActions(
    (actions) => actions.authStore.setSelectedService
  );

  return (
    <Modal className={`${classes.modal} outline-none`} open={isOpen}>
      <div
        className={`${classes.paper} outline-none flex flex-col justify-center items-center relative py-10`}
      >
        <div
          onClick={() => setIsOpen(false)}
          className={`${styles.closeIcon} cursor-pointer absolute top-0 right-0 flex justify-center items-center`}
        >
          <FaTimes />
        </div>
        <text
          className={`${styles.headerText} my-4`}
        >{`Choose a service`}</text>
        <div className={`w-full overflow-y-auto `}>
          {isLoggedIn &&
            services.map((service) => {
              return (
                <div
                  onClick={() => {
                    setSelectedService(service);

                    setIsOpen(false);
                    window.location.reload();
                  }}
                  className={`${styles.serviceItem} ${
                    selectedService
                      ? selectedService.id === service.id
                        ? styles.selected
                        : ""
                      : ""
                  }  flex justify-center items-center mb-2 cursor-pointer `}
                >
                  {service.name}
                </div>
              );
            })}
        </div>
      </div>
    </Modal>
  );
};

export default ChooseService;
