export const Products = () => {
  const env = process.env.REACT_APP_PRICEID;

  if (process.env.REACT_APP_PRICEID === "TEST") {
    return {
      withNoFreeTrial: {
        portal: {
          yearly: "price_1HjdVfDeO3XG77wNA9yXwT1m",
          monthly: "price_1HjdVfDeO3XG77wNweTXFOcB",
        },
        mentoring: {
          yearly: "price_1HjdWJDeO3XG77wNw70ZhCMf",
          monthly: "price_1HjdWJDeO3XG77wN2ZWtoJzb",
        },
      },
      withFreeTrial: {
        portal: {
          yearly: "price_1ISv7XDeO3XG77wNZHPAFVGy",
          monthly: "price_1ISv7HDeO3XG77wNIKR5IAk3",
          // monthly: "price_1JIq4HDeO3XG77wNu7sfk1zK",
        },
        mentoring: {
          yearly: "price_1ISv8XDeO3XG77wNAPhSKSsG",
          monthly: "price_1ISv8MDeO3XG77wNfKE2mu9G",
        },
      },
    };
  }

  if (process.env.REACT_APP_PRICEID === "LIVE") {
    return {
      withNoFreeTrial: {
        portal: {
          yearly: "price_1IOWbPDeO3XG77wNBFPuWdQR",
          monthly: "price_1IOWbPDeO3XG77wNEHMAsNwB",
        },
        mentoring: {
          yearly: "price_1IOWb0DeO3XG77wN3N58kPjh",
          monthly: "price_1IOWb0DeO3XG77wNEhS9fx7l",
        },
      },
      withFreeTrial: {
        portal: {
          yearly: "price_1ISmBJDeO3XG77wNESEFUbyJ",
          monthly: "price_1ISmAZDeO3XG77wNRRCmGou1",
        },
        mentoring: {
          yearly: "price_1ISmCKDeO3XG77wNbv89lSUH",
          monthly: "price_1ISmByDeO3XG77wNxXqNbNWM",
        },
      },
    };
  }

  // else {
  //   // test
  //   return {
  //     withNoFreeTrial: {
  //       portal: {
  //         yearly: "price_1HjdVfDeO3XG77wNA9yXwT1m",
  //         monthly: "price_1HjdVfDeO3XG77wNweTXFOcB",
  //       },
  //       mentoring: {
  //         yearly: "price_1HjdWJDeO3XG77wNw70ZhCMf",
  //         monthly: "price_1HjdWJDeO3XG77wN2ZWtoJzb",
  //       },
  //     },
  //     withFreeTrial: {
  //       portal: {
  //         yearly: "price_1ISv7XDeO3XG77wNZHPAFVGy",
  //         monthly: "price_1ISv7HDeO3XG77wNIKR5IAk3",
  //       },
  //       mentoring: {
  //         yearly: "price_1ISv8XDeO3XG77wNAPhSKSsG",
  //         monthly: "price_1ISv8MDeO3XG77wNfKE2mu9G",
  //       },
  //     },
  //   };
  // }
};
