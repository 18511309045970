import * as React from "react";

import useSWR from "swr";
import PropTypes from "prop-types";
import { Button, Modal, Icon, Dropdown } from "semantic-ui-react";

import styles from "./NQAStrategyModal.module.css";

import BlueButton from "../../BlueButton";
import SkipButton from "../../SkipButton";
import InputComponent from "../../InputComponent";
import ChangeStatusModal from "../ChangeStatusModal";
import DeleteTaskModal from "../DeleteTaskModal";
import {
  fetchCenterUsers,
  postComply,
  postTask,
  updateTask,
  deleteTask,
} from "../../../api/requests";
import { FaInfoCircle, FaTimes, FaSpinner } from "react-icons/fa";
import { Alert, message } from "antd";
import { useParams } from "react-router-dom";
import { useStoreActions, useStoreState } from "../../../hooks";

const { useEffect, useRef, useState } = React;

function NQAStrategyModal({
  visibility,
  onOpenModal,
  onCloseModal,
  onMutation,
  refreshModal,
  strategyObject,
}) {
  const { nqaId } = useParams();

  const tasksRef = useRef(null);

  const [taskDetail, setTaskDetail] = useState(undefined); // taskDetail values reset when task detail view is folded
  const [tasksVisibility, setTasksVisibility] = useState(
    strategyObject.openTasks
  );

  const [strategyItem, setStrategyItem] = useState(strategyObject);
  const [selectedPriorityValue, setSelectedPriorityValue] = useState(null);
  const handlePriorityChange = (event) => {
    setSelectedPriorityValue(event.target.value);
  };

  const [newTask, setNewTask] = useState({
    name: "",
    asignee: "",
    taskDue: "",
    summary: "",
    status: 1,
  });
  // const [resourceDocuments, setDocuments] = useState();

  const [taskModalVis, setTaskModalVis] = useState(false);
  const [statusModalVis, setStatusModalVis] = useState(false);

  // const [imagesLoading, setImagesLoading] = useState(true);
  const [complyLoading, setComplyLoading] = useState(false);

  // 1 - task status edit, 2 - new evidence task status edit
  const [changeStatusParent, setChangeStatusParent] = useState(1);

  const [showDeleteTaskModal, setShowDeleteTaskModal] = useState(false);

  const [fieldsTaskChecked, setFieldsTaskChecked] = useState(false);

  const [closing, setClosing] = useState(false);

  const userId = useStoreState((state) => state.authStore.authObject.userId);

  const onChangeStatus = (parent) => {
    setChangeStatusParent(parent);
    setStatusModalVis(true);
  };

  const onManageNewTask = (statusField, value) => {
    setNewTask({
      ...newTask,
      [statusField]: value,
    });
  };

  const onChangeHandler = (statusField, value) => {
    setTaskDetail({
      ...taskDetail,
      [statusField]: value,
    });
  };

  const onSelectTaskItem = (task) => {
    if (taskDetail) {
      if (task.id === taskDetail.id) setTaskDetail(undefined);
      else setTaskDetail(task);
    } else setTaskDetail(task);
  };
  const [updatedPriorityValue, setUpdatedPriorityValue] = useState(
    taskDetail?.priority ? taskDetail?.priority : ""
  );
  useEffect(() => {
    const priorityVal = taskDetail?.priority ? taskDetail?.priority : "";
    setUpdatedPriorityValue(priorityVal || "");
  }, [taskDetail]);
  const handlePriorityUpdate = (event) => {
    setUpdatedPriorityValue(event.target.value);
  };

  const onInputChangeDispatch = (statusField, value) => {
    setStrategyItem({
      ...strategyItem,
      [statusField]: value,
    });
  };

  const onCreateTask = async () => {
    setFieldsTaskChecked(true);
    if (
      !(newTask.name && newTask.status && newTask.summary && newTask.asignee)
    ) {
      return;
    }

    const taskPayload = {
      userId,
      nqaId: Number(nqaId),
      centerId: strategyObject.centerId,
      questionId: strategyObject.questionId,
      type: "goal",
      name: newTask.name,
      taskDue: newTask.taskDue,
      status: newTask.status,
      summary: newTask.summary,
      asignee: newTask.asignee,
      step_outcome: newTask.step_outcome,
      success_measure: newTask.success_measure,
      progress_notes: newTask.progress_notes,
      priority: selectedPriorityValue,
    };

    // if (Object.values(taskPayload).every(Boolean)) {
    setComplyLoading(true);

    try {
      const response = await postTask(taskPayload);

      if (response) {
        setComplyLoading(false);
        setTaskModalVis(true);
        setFieldsTaskChecked(false);
      }
      setNewTask({
        name: "",
        asignee: "",
        taskDue: "",
        summary: "",
        status: 1,
      });
    } catch (error) {
      console.log(error);
      setFieldsTaskChecked(false);
      setComplyLoading(false);
    }
    // } else {
    //   message.warning({
    //     content: (
    //       <div className={`flex justify-center items-center`}>
    //         <FaInfoCircle className={`text-red-400`} />
    //         <span className={`ml-2`}>Please fill up all fields.</span>
    //       </div>
    //     ),
    //     // duration: 1,
    //     icon: <> </>,
    //   });
    // }
    //alert("Please fill up all fields.");
  };

  const onUpdateTask = async () => {
    setComplyLoading(true);

    try {
      const response = await updateTask({
        ...taskDetail,
        priority: updatedPriorityValue,
        userId,
      });

      if (response) await refreshModal(strategyObject);
      setComplyLoading(false);
    } catch (error) {
      console.log(error);
      setComplyLoading(false);
    }
  };

  // const loadDocuments = useCallback(async () => {
  //   const docs = [];

  //   for (let index = 0; index < strategyObject.downloadResources.length; index++) {
  //     const document = strategyObject.downloadResources[index];

  //     try {
  //       const imageUrl = await fetchImage(document.id);

  //       docs.push({
  //         ...document,
  //         url: imageUrl
  //       });

  //     } catch (error) {
  //       console.log(error);
  //       setImagesLoading(false);
  //     }
  //   }

  //   setDocuments(docs);
  //   setImagesLoading(false);
  // }, [strategyObject.downloadResources]);

  const onSave = async () => {
    // if (strategyObject.goalStrategy !== strategyItem.goalStrategy) {
    setComplyLoading(true);

    try {
      const response = await postComply(
        strategyItem.centerId,
        strategyItem.questionId,
        strategyItem.status,
        strategyItem.goalStrategy,
        strategyItem.self_assessment,
        {
          notes: strategyItem.notes,
        }
      );

      if (response) {
        //   setComplyLoading(false);
        await onMutation();
      }
    } catch (error) {
      console.log(error);
      setComplyLoading(false);
    }
    // } else onCloseModal();
  };

  const onSaveStrategy = async () => {
    if (
      strategyObject.goalStrategy !== strategyItem.goalStrategy ||
      strategyObject.self_assessment !== strategyItem.self_assessment
    ) {
      setComplyLoading(true);

      try {
        const response = await postComply(
          strategyItem.centerId,
          strategyItem.questionId,
          strategyItem.status,
          strategyItem.goalStrategy,
          strategyItem.self_assessment,
          {
            notes: strategyItem.notes,
          }
        );

        if (response) {
          setComplyLoading(false);
          await refreshModal(strategyObject);
          // onCloseModal();
        }
      } catch (error) {
        console.log(error);
        await refreshModal(strategyObject);
        setComplyLoading(false);
      }
    }
    // else onCloseModal();
  };

  const onDeleteTask = async () => {
    try {
      const response = await deleteTask(taskDetail.id);

      setShowDeleteTaskModal(false);
      if (response) await refreshModal(strategyObject);
    } catch (error) {
      console.log(error);
      setShowDeleteTaskModal(false);
    }
  };

  const { data: users } = useSWR(
    ["/users", strategyObject.centerId],
    (_, centerId) => fetchCenterUsers(centerId)
  );

  useEffect(() => {
    if (strategyObject.openTasks) {
      if (tasksRef.current)
        tasksRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [strategyObject.openTasks]);

  // useEffect(() => {
  //   loadDocuments();
  // }, [loadDocuments]);

  return (
    <Modal
      size="large"
      centered={false}
      open={visibility}
      onOpen={onOpenModal}
      onClose={onCloseModal}
    >
      {showDeleteTaskModal && (
        <DeleteTaskModal
          visibility={showDeleteTaskModal}
          onCloseModal={() => {
            setShowDeleteTaskModal(false);
          }}
          onRemoveTask={onDeleteTask}
        />
      )}
      {!users ? (
        <div
          className={`w-full h-screen flex flex-col justify-center items-center`}
        >
          <FaSpinner
            className={`animate-spin text-qipGreen font-bold text-6xl`}
          />
          <span className={`block`}> Loading data...</span>
        </div>
      ) : (
        <Modal.Content scrolling style={{ maxHeight: "95vh" }}>
          <div className={styles.modalContainer}>
            <button
              className={styles.closeButton}
              disabled={closing}
              onClick={() => {
                setClosing(true);
                onCloseModal();
              }}
              size="big"
            >
              {closing ? (
                <div className={` flex justify-center items-center text-3xl`}>
                  <FaSpinner className={`animate-spin`} />
                </div>
              ) : (
                <div className={`flex justify-center items-center text-3xl`}>
                  <FaTimes />
                </div>
              )}
            </button>

            {statusModalVis && (
              <ChangeStatusModal
                visibility={statusModalVis}
                onSelectStatus={
                  changeStatusParent === 1 ? onChangeHandler : onManageNewTask
                }
                onOpenModal={() => setStatusModalVis(true)}
                onCloseModal={() => setStatusModalVis(false)}
              />
            )}

            {taskModalVis && (
              <Modal
                centered
                size="small"
                open={taskModalVis}
                onOpen={() => setTaskModalVis(true)}
                onClose={() => setTaskModalVis(false)}
              >
                <div className={styles.taskConfirmationModal}>
                  <text className={styles.headerText}>
                    Task set and team member notified.
                  </text>

                  <BlueButton
                    buttonText="Close"
                    className={styles.blueButton}
                    onClick={async () => {
                      setTaskModalVis(false);
                      await refreshModal(strategyObject);
                    }}
                  />
                </div>
              </Modal>
            )}

            {complyLoading ? (
              <div
                className={`w-full h-screen flex flex-col justify-center items-center`}
              >
                <FaSpinner
                  className={`animate-spin text-qipGreen font-bold text-6xl`}
                />

                <span className={`block`}>Saving changes...</span>
              </div>
            ) : (
              <div className={styles.modalContent}>
                <div className={styles.modalHeaderRow}>
                  <text className={styles.headerText}>
                    Here is your QIP goal (what)
                  </text>

                  <Alert
                    className={`mt-8`}
                    message={
                      <div className={`py-10 px-14 text-2xl text-center`}>
                        {strategyObject.noDescription
                          ? strategyObject.noDescription
                          : `
                          Provide ways for children’s ideas, wonderings and experiements to be
                          recorded in the program (e.g. reflections book, wonder wall,
                          electronic programming, or morning and afternoon minutes)
                        `}
                      </div>
                    }
                    type={`info`}
                  />

                  {/* <text className={styles.subText}>
                    {strategyObject.noDescription
                      ? strategyObject.noDescription
                      : `
                          Provide ways for children’s ideas, wonderings and experiements to be
                          recorded in the program (e.g. reflections book, wonder wall,
                          electronic programming, or morning and afternoon minutes)
                        `}
                  </text> */}
                </div>

                {/* <div className={styles.downloadResourceGroup}>
                    
                    <text className={styles.downloadResourceHeader}>
                      Download resources
                    </text>

                    {strategyObject.downloadResources.map(document => (
                      <div
                        className={styles.downloadResourceRow}
                        key={document.name}
                      >

                        <div className={styles.downloadResourceDisplay}>

                          <div className={styles.downloadResourceDetails}>

                      {/* <div className={styles.resourceImageContainer}>
                        <img
                          className={styles.resourceImage}
                          alt="Resource image"
                          src={`data:image/jpeg;base64,${document.url}`}
                        />
                      </div>
                            <text className={styles.downloadResourceFileName}>
                              {document.name}
                            </text>

                            <text className={styles.downloadResourceUploadDate}>
                              Uploaded on {document.uploadDate}
                            </text>

                          </div>

                        </div>

                        <div className={styles.documentButtonGroup}>

                          <Button
                            className={styles.downloadButton}
                            icon="download"
                          />

                          <text className={styles.documentButtonText}>
                            Download
                          </text>

                        </div>

                      </div>
                    ))}

                  </div>

                  <div className={styles.videoTutorialRow}>

                    <div className={styles.videoContainer}>

                      <div className={styles.videoTextGroup}>

                        <text className={styles.videoLabel}>
                          Tutorial
                        </text>

                        <text className={styles.videoHeaderTitle}>
                          Defining childcare goals: An expert’s advice
                        </text>

                      </div>

                      <Button
                        className={styles.playButton}
                        size="massive"
                        icon="play"
                        circular
                      />

                    </div>

                    <text className={styles.videoHelperText}>
                      Need help on this subject? Watch this video tutorial.
                    </text>

                  </div> */}

                <div className={styles.divider} />

                <div className={styles.goalStrategyRow}>
                  <text className={styles.headerText}>
                    What outcome or goal do we seek?
                  </text>
                  <text className={styles.subText}>
                    Set your goal strategy (how)
                  </text>
                  <InputComponent
                    fieldName="self_assessment"
                    type="text"
                    inputValue={strategyItem?.self_assessment}
                    setInputValue={onInputChangeDispatch}
                    label="Issue identified during self-assessment"
                  />
                  <InputComponent
                    className={styles.goalStrategyBox}
                    textArea
                    fieldName="goalStrategy"
                    inputValue={strategyItem.goalStrategy}
                    setInputValue={onInputChangeDispatch}
                    label="Write your QIP goal strategy here"
                  />

                  <BlueButton
                    className={styles.blueButton}
                    buttonText="Save changes"
                    onClick={onSaveStrategy}
                  />
                </div>

                <div ref={tasksRef} className={styles.divider} />

                <div className={styles.strategyTaskRow}>
                  <text className={styles.tasksHeaderText}>
                    Manage your goal tasks (who)
                  </text>

                  {strategyObject.tasks.length !== 0 && (
                    <>
                      <div className={styles.tasksCountRow}>
                        <div>
                          <div>
                            <text>{strategyObject.tasks.length}</text>

                            <text>assigned tasks</text>
                          </div>

                          {!tasksVisibility ? (
                            <button
                              className={styles.showTasksButton}
                              onClick={() =>
                                setTasksVisibility((prev) => !prev)
                              }
                            >
                              Show tasks
                            </button>
                          ) : (
                            <BlueButton
                              buttonText="Close tasks"
                              onClick={() =>
                                setTasksVisibility((prev) => !prev)
                              }
                            />
                          )}
                        </div>
                      </div>

                      {tasksVisibility && users && (
                        <div className={styles.tasksGroup}>
                          {strategyObject.tasks.map((task) => (
                            <div
                              key={task.id}
                              className={styles.taskItemContainer}
                            >
                              <div
                                className={styles.taskContentRow}
                                onClick={() => onSelectTaskItem(task)}
                              >
                                <div className={styles.taskDescriptionColumn}>
                                  <text
                                    className={styles.taskDescriptionHeader}
                                  >
                                    {task.name}
                                  </text>

                                  <div
                                    className={styles.taskDescriptionSubtext}
                                  >
                                    <text
                                      className={
                                        task.status === 1
                                          ? styles.taskDetailNotStarted
                                          : task.status === 2
                                          ? styles.taskDetailInProgress
                                          : styles.taskDetailCompleted
                                      }
                                    >
                                      {task.status === 1
                                        ? "Not started"
                                        : task.status === 2
                                        ? "In progress"
                                        : "Completed"}
                                    </text>

                                    <text>
                                      {/* {users &&
                                        users?.filter(
                                          (user) =>
                                            parseInt(user.id) === task.assignee
                                        )[0].name
                                      } */}
                                      {
                                        users.find(
                                          (user) =>
                                            parseInt(user.id) ===
                                            taskDetail?.assignee
                                        )?.name
                                      }
                                    </text>

                                    <text>
                                      {task.taskDue
                                        ? task.taskDue
                                            .substring(0, 10)
                                            .replace(/-/g, "/")
                                        : ""}
                                    </text>
                                  </div>
                                </div>

                                <Button
                                  className={
                                    taskDetail && taskDetail.id === task.id
                                      ? styles.taskCollapseButtonSelected
                                      : styles.taskCollapseButton
                                  }
                                  icon={
                                    taskDetail && taskDetail.id === task.id
                                      ? "arrow up"
                                      : "arrow down"
                                  }
                                  onClick={() => setTaskDetail(task)}
                                />
                              </div>

                              {taskDetail && taskDetail.id === task.id ? (
                                <div className={styles.taskDetails}>
                                  <InputComponent
                                    fieldName="name"
                                    label="Task name"
                                    type="text"
                                    inputValue={taskDetail.name}
                                    setInputValue={onChangeHandler}
                                  />

                                  <div className={styles.dropdownRow}>
                                    <div className={styles.dropdownGroup}>
                                      <text className={styles.dropdownText}>
                                        Task for
                                      </text>

                                      <Dropdown
                                        className={styles.dropdownBox}
                                        fluid
                                        selection
                                        scrolling
                                        placeholder="Choose"
                                        options={users.map((user) => ({
                                          text: user.name,
                                          value: user.id,
                                        }))}
                                        defaultValue={
                                          // users &&
                                          // taskDetail?.assignee &&
                                          // users.filter(
                                          //   (user) =>
                                          //     parseInt(user.id) ===
                                          //     taskDetail.assignee
                                          // )[0].id
                                          users.find(
                                            (user) =>
                                              parseInt(user.id) ===
                                              taskDetail?.assignee
                                          )?.id
                                        }
                                        onChange={(e, data) =>
                                          onChangeHandler("asignee", data.value)
                                        }
                                      />
                                    </div>

                                    <div className={styles.dropdownGroup}>
                                      <text className={styles.dropdownText}>
                                        To be completed by
                                      </text>

                                      <div className={styles.datePickerGroup}>
                                        <input
                                          className={styles.datePickerBox}
                                          type="date"
                                          value={taskDetail.taskDue.substring(
                                            0,
                                            10
                                          )}
                                          onChange={(e) =>
                                            onChangeHandler(
                                              "taskDue",
                                              e.target.value
                                            )
                                          }
                                        />

                                        {/* <Icon
                                                  className={styles.datePickerIcon}
                                                  name="caret down"
                                                /> */}
                                      </div>
                                    </div>
                                  </div>

                                  <InputComponent
                                    className={styles.summaryTextBox}
                                    fieldName="summary"
                                    label="Task summary"
                                    textArea
                                    inputValue={taskDetail.summary}
                                    setInputValue={onChangeHandler}
                                  />
                                  <InputComponent
                                    className={styles.summaryTextBox}
                                    fieldName="step_outcome"
                                    label="How will we get this outcome? (Steps)"
                                    textArea
                                    inputValue={taskDetail?.step_outcome}
                                    setInputValue={onChangeHandler}
                                  />

                                  <InputComponent
                                    className={styles.summaryTextBox}
                                    fieldName="success_measure"
                                    label="Success Measure"
                                    textArea
                                    inputValue={taskDetail?.success_measure}
                                    setInputValue={onChangeHandler}
                                  />

                                  <InputComponent
                                    className={styles.summaryTextBox}
                                    fieldName="progress_notes"
                                    label="Progress Notes"
                                    textArea
                                    inputValue={taskDetail?.progress_notes}
                                    setInputValue={onChangeHandler}
                                  />

                                  <text className={styles.dropdownText}>
                                    Priority
                                  </text>
                                  <div
                                    className={`flex justify-start items-center gap-4 my-2`}
                                  >
                                    <div>
                                      <input
                                        type="radio"
                                        name="priority"
                                        value="low"
                                        id="lowupdate"
                                        checked={updatedPriorityValue === "low"}
                                        onChange={handlePriorityUpdate}
                                      />
                                      <label
                                        htmlFor="lowupdate"
                                        className="ml-2 text-xl"
                                      >
                                        Low
                                      </label>
                                    </div>
                                    <div>
                                      <input
                                        type="radio"
                                        name="priority"
                                        value="medium"
                                        id="mediumupdate"
                                        checked={
                                          updatedPriorityValue === "medium"
                                        }
                                        onChange={handlePriorityUpdate}
                                      />
                                      <label
                                        htmlFor="mediumupdate"
                                        className="ml-2 text-xl"
                                      >
                                        Medium
                                      </label>
                                    </div>
                                    <div>
                                      <input
                                        type="radio"
                                        name="priority"
                                        value="high"
                                        id="highupdate"
                                        checked={
                                          updatedPriorityValue === "high"
                                        }
                                        onChange={handlePriorityUpdate}
                                      />
                                      <label
                                        htmlFor="highupdate"
                                        className="ml-2 text-xl"
                                      >
                                        High
                                      </label>
                                    </div>
                                  </div>

                                  <div className={styles.dropdownGroup}>
                                    <text className={styles.dropdownText}>
                                      Change task status
                                    </text>

                                    <div
                                      className={
                                        styles[
                                          `statusSelect${taskDetail.status}`
                                        ]
                                      }
                                      onClick={() => onChangeStatus(1)}
                                    >
                                      <text className={styles.dropdownText}>
                                        {taskDetail.status === 1
                                          ? "Not started"
                                          : taskDetail.status === 2
                                          ? "In progress"
                                          : "Completed"}
                                      </text>

                                      <Icon name="caret down" />
                                    </div>
                                  </div>
                                  <div
                                    className={`w-full flex justify-center items-center`}
                                  >
                                    <BlueButton
                                      className={styles.blueButton}
                                      buttonText="Update task"
                                      onClick={onUpdateTask}
                                    />

                                    <div className={`px-5`}></div>

                                    <SkipButton
                                      className={styles.redButton}
                                      buttonText={`Remove task`}
                                      onClick={() => {
                                        setShowDeleteTaskModal(true);
                                      }}
                                    />
                                  </div>
                                </div>
                              ) : null}
                            </div>
                          ))}
                        </div>
                      )}

                      <text className={styles.headerText}>
                        Add another task
                      </text>
                    </>
                  )}

                  <div className={styles.strategyTaskForm}>
                    <InputComponent
                      fieldName="name"
                      label="Task name"
                      type="text"
                      required
                      showNotFilled={fieldsTaskChecked}
                      inputValue={newTask.name}
                      setInputValue={onManageNewTask}
                    />

                    <div className={styles.dropdownRow}>
                      <div className={styles.dropdownGroup}>
                        <text className={styles.dropdownText}>Task for</text>

                        <Dropdown
                          className={styles.dropdownBox}
                          fluid
                          selection
                          scrolling
                          placeholder="Choose"
                          options={users.map((user) => ({
                            text: user.name,
                            value: user.id,
                          }))}
                          // defaultValue={newTask.asignee}
                          onChange={(e, data) =>
                            onManageNewTask("asignee", data.value)
                          }
                        />
                        {fieldsTaskChecked && !newTask.asignee && (
                          <div className={`p-2 text-red-400 font-thin`}>
                            This field is required.{" "}
                          </div>
                        )}
                      </div>

                      <div className={styles.dropdownGroup}>
                        <text className={styles.dropdownText}>
                          To be completed by
                        </text>

                        <div className={styles.datePickerGroup}>
                          <input
                            className={styles.datePickerBox}
                            type="date"
                            value={newTask.taskDue}
                            onChange={(e) =>
                              onManageNewTask("taskDue", e.target.value)
                            }
                          />

                          {fieldsTaskChecked && !newTask.taskDue && (
                            <div className={`p-2 text-red-400 font-thin`}>
                              This field is required.{" "}
                            </div>
                          )}

                          {/* <Icon
                                className={styles.datePickerIcon}
                                name="caret down"
                              /> */}
                        </div>
                      </div>
                    </div>

                    <InputComponent
                      className={styles.summaryTextBox}
                      fieldName="summary"
                      label="Task summary"
                      textArea
                      required
                      showNotFilled={fieldsTaskChecked}
                      inputValue={newTask.summary}
                      setInputValue={onManageNewTask}
                    />

                    <InputComponent
                      className={styles.summaryTextBox}
                      fieldName="step_outcome"
                      label="How will we get this outcome? (Steps)"
                      textArea
                      showNotFilled={fieldsTaskChecked}
                      inputValue={newTask?.step_outcome}
                      setInputValue={onManageNewTask}
                    />

                    <InputComponent
                      className={styles.summaryTextBox}
                      fieldName="success_measure"
                      label="Success Measure"
                      textArea
                      showNotFilled={fieldsTaskChecked}
                      inputValue={newTask?.success_measure}
                      setInputValue={onManageNewTask}
                    />

                    <InputComponent
                      className={styles.summaryTextBox}
                      fieldName="progress_notes"
                      label="Progress Notes"
                      textArea
                      showNotFilled={fieldsTaskChecked}
                      inputValue={newTask?.progress_notes}
                      setInputValue={onManageNewTask}
                    />

                    <text className={styles.dropdownText}>Priority</text>
                    <div
                      className={`flex justify-start items-center gap-4 my-2`}
                    >
                      <div>
                        <input
                          type="radio"
                          name="priority"
                          value="low"
                          id="low"
                          checked={selectedPriorityValue === "low"}
                          onChange={handlePriorityChange}
                        />
                        <label htmlFor="low" className="ml-2 text-xl">
                          Low
                        </label>
                      </div>
                      <div>
                        <input
                          type="radio"
                          name="priority"
                          value="medium"
                          id="medium"
                          checked={selectedPriorityValue === "medium"}
                          onChange={handlePriorityChange}
                        />
                        <label htmlFor="medium" className="ml-2 text-xl">
                          Medium
                        </label>
                      </div>
                      <div>
                        <input
                          type="radio"
                          name="priority"
                          value="high"
                          id="high"
                          checked={selectedPriorityValue === "high"}
                          onChange={handlePriorityChange}
                        />
                        <label htmlFor="high" className="ml-2 text-xl">
                          High
                        </label>
                      </div>
                    </div>

                    <div className={styles.dropdownGroup}>
                      <text className={styles.dropdownText}>
                        Change task status
                      </text>

                      <div
                        className={
                          styles[
                            `statusSelect${
                              newTask.status !== undefined ? newTask.status : 1
                            }`
                          ]
                        }
                        onClick={() => onChangeStatus(2)}
                      >
                        <text className={styles.dropdownText}>
                          {newTask.status === 1
                            ? "Not started"
                            : newTask.status === 2
                            ? "In progress"
                            : newTask.status === 3
                            ? "Completed"
                            : "Not started"}
                        </text>

                        <Icon name="caret down" />
                      </div>
                    </div>

                    <BlueButton
                      className={styles.blueButton}
                      buttonText="Set task"
                      onClick={onCreateTask}
                    />
                  </div>
                </div>

                <div className={styles.divider} />

                {/* <button className={styles.addTaskButton}>
                    <Icon name="plus circle" />
                    <text>
                      Add another task
                    </text>
                  </button>
                  <div className={styles.divider} /> */}

                <div className={styles.generalCommentsRow}>
                  <text className={styles.headerText}>
                    General notes or comments
                  </text>

                  <InputComponent
                    fieldName={`notes`}
                    className={styles.summaryTextBox}
                    label="Add additional comments here"
                    inputValue={strategyItem.notes}
                    setInputValue={onInputChangeDispatch}
                    textArea
                  />

                  <BlueButton
                    className={styles.blueButton}
                    buttonText="Save changes"
                    onClick={onSave}
                  />
                </div>
              </div>
            )}
          </div>
        </Modal.Content>
      )}
    </Modal>
  );
}

NQAStrategyModal.propTypes = {
  visibility: PropTypes.bool.isRequired,
  onOpenModal: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  refreshModal: PropTypes.func.isRequired,
  onMutation: PropTypes.func.isRequired,
  strategyObject: PropTypes.shape({
    downloadResources: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        title: PropTypes.string,
        uploadDate: PropTypes.string,
        url: PropTypes.string,
      })
    ),
    tasks: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        centerId: PropTypes.number,
        questionId: PropTypes.number,
        name: PropTypes.string,
        nqaArea: PropTypes.string,
        asignee: PropTypes.string,
        taskDue: PropTypes.string,
        status: PropTypes.number,
        summary: PropTypes.string,
      })
    ),
    comments: PropTypes.string,
    taskCount: PropTypes.number,
    goalStrategy: PropTypes.string,
    self_assessment: PropTypes.string,
    notes: PropTypes.string,
    status: PropTypes.number,
    centerId: PropTypes.number,
    questionId: PropTypes.number,
    noDescription: PropTypes.string,
    openTasks: PropTypes.bool,
  }).isRequired,
};

export default NQAStrategyModal;
