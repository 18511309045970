import React from "react";
import { Modal } from "semantic-ui-react";
import styles from "./AddWarningSatLimit.module.css";
import { FaTimes } from "react-icons/fa";

const AddWarmingSatLimit = ({ visibility, onCloseModal, onOpenModal }) => {
  return (
    <Modal
      open={visibility}
      onClose={onCloseModal}
      onOpen={onOpenModal}
      size={`small`}
      closeOnDimmerClick={false}
    >
      <div className={`${styles.modalContainer} flex flex-col items-center`}>
        <div
          onClick={onCloseModal}
          className={`${styles.closeButton} absolute top-0 right-0 flex justify-center items-center `}
        >
          <FaTimes />
        </div>
        <text
          className={`${styles.headerText} mt-20`}
        >{`You’ve reach 5 favourites for this element`}</text>
        <text
          className={`${styles.headerSub} mt-2 mb-8`}
        >{`To add another favourite to your collection, please remove one of your existing favourites.`}</text>
        <button
          onClick={onCloseModal}
          className={`${styles.continueButton} mb-14`}
        >
          Continue
        </button>
      </div>
    </Modal>
  );
};

export default AddWarmingSatLimit;
