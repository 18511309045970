import * as React from "react";

import PropTypes from "prop-types";
import { Modal } from "semantic-ui-react";
import { FaSpinner } from "react-icons/fa";
import styles from "./InvoiceDownloadingModal.module.css";

function InvoiceDownloadingModal({ visibility, onOpenModal, onCloseModal }) {
  const downloadProgress = 60;

  return (
    <Modal
      size="small"
      centered={true}
      open={visibility}
      onOpen={onOpenModal}
      onClose={onCloseModal}
    >
      <div className={styles.modalContainer}>
        <text className={styles.headerText}>Invoice is being downloaded</text>

        {/* <div className={styles.progressBarContainer}>
          <div
            className={styles.progressBar}
            style={{ width: `${downloadProgress}%` }}
          />
        </div> */}

        <FaSpinner
          className={`animate-spin text-qipGreen font-bold text-6xl`}
        />
      </div>
    </Modal>
  );
}

InvoiceDownloadingModal.propTypes = {
  visibility: PropTypes.bool.isRequired,
  onOpenModal: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired,
};

export default InvoiceDownloadingModal;
