import * as React from 'react';

import { Route, Switch, useRouteMatch } from 'react-router-dom';

import BillingDetails from '../../pages/Billing/BillingDetails';

export default function Billing() {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route
        path={`${match.path}/:billingChoiceId`}
        component={BillingDetails}
      />
    </Switch>
  );
}
