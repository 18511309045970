import * as React from 'react';

import PropTypes from 'prop-types';
import { Button, Modal } from 'semantic-ui-react';

import styles from './SubmissionErrorModal.module.css';
import BlueButton from '../../BlueButton';

function SubmissionErrorModal({ visibility, onOpenModal, onCloseModal }) {

  return (
    <Modal
      size="tiny"
      centered={false}
      open={visibility}
      onOpen={onOpenModal}
      onClose={onCloseModal}
    >
      <div className={styles.modalContainer}>
  
        <Button
          icon="info"
          circular
          className={styles.warningInfoIcon}
        />

        <text className={styles.headerText}>
          A full submission report can only be sent once the Service Details have been completed (100%)
        </text>

        <BlueButton
          className={styles.blueButton}
          buttonText="Back"
          onClick={onCloseModal}
        />

      </div>
    </Modal >
  );
}

SubmissionErrorModal.propTypes = {
  visibility: PropTypes.bool.isRequired,
  onOpenModal: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired,
};

export default SubmissionErrorModal;
