import * as React from "react";

import { Button, Icon, Label } from "semantic-ui-react";
import {
  FaRegArrowAltCircleLeft,
  FaInfoCircle,
  FaSignOutAlt,
  FaTimes,
  FaSpinner,
} from "react-icons/fa";

import { FcInfo } from "react-icons/fc";

import { useHistory, useRouteMatch } from "react-router-dom";

import styles from "./NQADashboard.module.css";

import { useStoreActions, useStoreState } from "../../../hooks";
import MentorFeedbackModal from "../../../components/Modals/MentorFeedbackModal";
import { getUserProducts, replyFeedbackList } from "../../../api/requests";
import FirstSignupWelcomeModal from "../../../components/Modals/FirstSignupWelcomeModal/FirstSignupWelcomeModal";
import { isEmpty } from "lodash";
import useSWR, { mutate } from "swr";

const { useCallback, useEffect, useState } = React;

function NQADashboard() {
  const history = useHistory();
  const match = useRouteMatch();

  const nqaData = useStoreState((state) => state.nqaStore.NQAList);

  const userId = useStoreState((state) => state.authStore.authObject.userId);

  const isAdmin = useStoreState((state) => state.authStore.isAdmin);

  const selectedService = useStoreState(
    (state) => state.authStore.selectedService
  );

  const [userProduct, setUserProduct] = useState("QIP Portal");

  const fetchNQAData = useStoreActions(
    (actions) => actions.nqaStore.fetchNQAData
  );
  const setSelectedNQA = useStoreActions(
    (actions) => actions.nqaStore.setSelectedNQA
  );
  const fetchUserDetails = useStoreActions(
    (state) => state.userStore.fetchUserDetails
  );
  const fetchServiceDetails = useStoreActions(
    (actions) => actions.nqaStore.fetchServiceDetails
  );

  const adminAttr = useStoreState((state) => state.authStore.adminAttr);

  const remainingTrial = useStoreState(
    (state) => state.userStore.remainingTrial
  );

  const [fetchLimitError, setFetchLimitError] = useState(false);

  const user = useStoreState((state) => state.userStore.loggedInUser);

  const [nqaLoading, setLoading] = useState(nqaData ? false : true);
  const [feedbackModalVisibility, setFeedbackModalVisibility] = useState(false);
  const [showWelcome, setShowWelcome] = useState(
    localStorage.getItem(`${userId}_completed`) ? true : false
  );

  const onSelectNQA = (nqa) => {
    setSelectedNQA(nqa);
    history.push(`${match.path}/${nqa}`);
  };

  const { data: feedbacks } = useSWR(
    ["/feedbacks"],
    () => replyFeedbackList(),
    {
      onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
        // Only retry up to 3 times.
        if (retryCount && retryCount >= 3) {
          if (!feedbacks) setFetchLimitError(true);

          return;
        }
        // Retry after 3 seconds.
        setTimeout(() => revalidate({ retryCount: retryCount + 1 }), 3000);
      },
    }
  );

  const fetchUserProduct = useCallback(async () => {
    try {
      const response = await getUserProducts(userId, selectedService.id);

      if (!isEmpty(response)) {
        const selectedProduct = response.filter(
          (product) => Number(product.center_id) === Number(selectedService.id)
        );

        if (!isEmpty(selectedProduct)) {
          setUserProduct(selectedProduct[0].product);
        } else {
          setUserProduct(null);
        }
      } else {
        setUserProduct(null);
      }
    } catch (error) {
      console.log(error);
    }
  }, [userId, selectedService]);

  const getUserDetails = useCallback(
    async (centerId) => {
      try {
        await fetchUserDetails({
          centerId,
          userId,
        });
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    },
    [fetchUserDetails, userId]
  );

  const getNQAData = useCallback(async () => {
    try {
      const response = await fetchServiceDetails(userId);

      if (response) {
        const nqaFetchResponse = fetchNQAData();

        if (nqaFetchResponse) {
          await getUserDetails(response);

          setLoading(false);
        }
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }, [fetchNQAData, fetchServiceDetails, getUserDetails, userId]);

  useEffect(() => {
    fetchUserProduct();
  }, [userId, selectedService]);

  useEffect(() => {
    if (!nqaData) getNQAData();
  }, [getNQAData, nqaData]);

  return nqaLoading ? (
    // <div className={styles.spinnerDiv}>
    <div
      className={`h-screen w-full flex flex-col justify-center items-center`}
    >
      <FaSpinner className={`animate-spin text-qipGreen font-bold text-6xl`} />

      <span className={`w-full text-center block`}>Loading data...</span>
    </div>
  ) : (
    <div className={styles.mainContainer}>
      <FirstSignupWelcomeModal
        visibility={showWelcome}
        onCloseModal={() => setShowWelcome(false)}
      />

      <div className={styles.content}>
        {feedbackModalVisibility && (
          <MentorFeedbackModal
            visibility={feedbackModalVisibility}
            onOpenModal={() => setFeedbackModalVisibility(true)}
            onCloseModal={() => setFeedbackModalVisibility(false)}
          />
        )}
        <div className={`flex items-center ${styles.commingsoonContiner}`}>
          <FcInfo size={70} className={`ml-2.5`} />
          <text className={styles.commingSoon}>
            <span className={styles.comingSoonText}>NEW!</span> Introducing our
            new Self Assessment Tool to help you identify and document your 5
            Key Practices across all NQA areas, ready to upload to your portal.
            Find it under{" "}
            <span
              className={`font-semibold text-blue-400 underline cursor-pointer hover:text-blue-500`}
              onClick={() => history.push(`/qip/reports`)}
            >
              Reports
            </span>{" "}
            (SAT Report tab), and inside each NQA area. To learn more, watch our
            short introductory video inside the App Tutorials section of{" "}
            <span
              className={`font-semibold text-blue-400 underline cursor-pointer hover:text-blue-500`}
              onClick={() => history.push(`/qip/user-resource`)}
            >
              Resources
            </span>
          </text>
        </div>
        <div className={`${styles.dashboardHeaderContainer} `}>
          <div className={styles.dashboardTextContainer}>
            <h1 className={styles.dashboardTextHeader}>NQA Dashboard</h1>

            <text className={styles.dashboardTextDescription}>
              See your progress for all NQA’s, easily edit & update all
              elements, and provide your NQA evidence.
            </text>
          </div>
          {console.log(
            "%cUSER PRODUCT ::",
            "background: black; color: white",
            userProduct
          )}

          {(isAdmin &&
            userProduct &&
            userProduct.toLocaleLowerCase().includes("mentoring") &&
            remainingTrial > -7) ||
          (isAdmin && userProduct === null && remainingTrial > 0) ? (
            <div className={styles.feedbackButtonsContainer}>
              <div className={styles.buttonGroup}>
                <text className={styles.feedbackButtonsHeader}>
                  Request mentor feedback
                </text>

                <button
                  className={styles.requestFeedbackButton}
                  onClick={() => setFeedbackModalVisibility(true)}
                >
                  Request feedback
                </button>

                {/* <Label pointing="below">Coming soon</Label> */}
              </div>

              {!isEmpty(feedbacks) &&
                feedbacks.filter(
                  (fb) =>
                    Number(fb.center_id) === Number(selectedService.id) &&
                    fb.save_or_publish === 2
                ).length > 0 && (
                  <div className={`${styles.buttonGroup} ml-5`}>
                    <text className={styles.feedbackButtonsHeader}>
                      See
                      <span className={styles.reportsCount}>
                        {!isEmpty(feedbacks) &&
                          feedbacks.filter(
                            (fb) =>
                              Number(fb.center_id) ===
                                Number(selectedService.id) &&
                              fb.save_or_publish === 2
                          ).length}
                      </span>
                      previous reports
                    </text>

                    <button
                      className={styles.viewFeedbackButton}
                      onClick={() => {
                        if (!isEmpty(feedbacks)) {
                          history.push(`${match.path}/mentor-feedback`, {
                            feedbacks: feedbacks.filter(
                              (fb) =>
                                Number(fb.center_id) ===
                                  Number(selectedService.id) &&
                                fb.save_or_publish === 2
                            ),
                          });
                        }
                      }}
                    >
                      View feedback
                    </button>
                  </div>
                )}
            </div>
          ) : (
            <> </>
          )}
        </div>

        <div className={styles.nqaCardsContainer}>
          <div className={styles.nqaCardRow}>
            {!isEmpty(nqaData) && nqaData[0].completed !== undefined
              ? nqaData.map((nqa) => (
                  <div key={nqa.id} className={styles.nqaCard}>
                    <div className={styles.nqaHeaderRow}>
                      <text className={styles.nqaHeaderText}>NQA{nqa.id}</text>
                      {/* {console.log(
                    "nqa progress ",
                    nqa.id,
                    " percent-complete: ",
                    nqa.completed
                  )} */}
                      {Math.ceil(nqa.completed) === 100 && (
                        <Button
                          className={styles.nqaHeaderCheck}
                          icon="check"
                          circular
                        />
                      )}

                      {/* {Number(nqa.id) !== 2 ? (
                        <>
                          {Math.floor(nqa.completed) === 100 && (
                            <Button
                              className={styles.nqaHeaderCheck}
                              icon="check"
                              circular
                            />
                          )}
                        </>
                      ) : (
                        <>
                          {nqa.completed === 99.6124031007752 && (
                            <Button
                              className={styles.nqaHeaderCheck}
                              icon="check"
                              circular
                            />
                          )}
                        </>
                      )} */}
                    </div>

                    <div className={styles.progressBarContainer}>
                      <div
                        className={styles.progressBar}
                        // style={{ width: `${Math.ceil(nqa.completed)}%` }}
                        style={{
                          width: `${
                            nqa.id !== 2
                              ? Math.floor(nqa.completed)
                              : nqa.completed !== 99.6124031007752
                              ? Math.floor(nqa.completed)
                              : 100
                          }%`,
                        }}
                      />
                    </div>

                    <div className={styles.progressBarText}>
                      <text>
                        {Math.ceil(nqa.completed) === 100
                          ? "Finished"
                          : "Completed"}
                        {/* {nqa.id !== 2
                          ? Math.floor(nqa.completed) === 100
                            ? "Finished"
                            : "Completed"
                          : nqa.completed !== 99.6124031007752
                          ? Math.floor(nqa.completed) === 100
                            ? "Finished"
                            : "Completed"
                          : "Completed"} */}
                      </text>

                      {/* <text>{Math.ceil(nqa.completed)} %</text> */}
                      <text>
                        {nqa.id !== 2
                          ? Math.floor(nqa.completed)
                          : nqa.completed !== 99.6124031007752
                          ? Math.floor(nqa.completed)
                          : 100}
                        %
                      </text>
                    </div>

                    <button
                      className={styles.nqaButton}
                      onClick={() => onSelectNQA(nqa.id)}
                    >
                      {Math.ceil(nqa.completed) === 100
                        ? `Completed NQA${nqa.id}`
                        : Math.ceil(nqa.completed) > 0 &&
                          Math.ceil(nqa.completed) < 100
                        ? `Continue NQA${nqa.id}`
                        : `Start NQA${nqa.id}`}

                      {/* {nqa.id !== 2
                        ? Math.floor(nqa.completed) === 100
                          ? `Completed NQA${nqa.id}`
                          : Math.floor(nqa.completed) > 0 &&
                            Math.floor(nqa.completed) < 100
                          ? `Continue NQA${nqa.id}`
                          : `Start NQA${nqa.id}`
                        : nqa.completed !== 99.6124031007752
                        ? Math.floor(nqa.completed) === 100
                          ? `Completed NQA${nqa.id}`
                          : Math.floor(nqa.completed) > 0 &&
                            Math.floor(nqa.completed) < 100
                          ? `Continue NQA${nqa.id}`
                          : `Start NQA${nqa.id}`
                        : "Completed NQA2"} */}
                    </button>
                  </div>
                ))
              : Array.from({ length: 7 }).map((nqa, k) => (
                  <div key={k} className={styles.nqaCard}>
                    <div className={styles.nqaHeaderRow}>
                      <text className={`${styles.nqaHeaderText} `}>
                        NQA{k + 1}
                      </text>
                    </div>

                    <div className={styles.progressBarContainer}>
                      <div
                        className={`w-full animate-pulse h-8 bg-gray-200`}

                        // style={{
                        //   width: `${
                        //     nqa.id !== 2
                        //       ? Math.floor(nqa.completed)
                        //       : nqa.completed !== 99.6124031007752
                        //       ? Math.floor(nqa.completed)
                        //       : 100
                        //   }%`,
                        // }}
                      />
                    </div>

                    <div className={styles.progressBarText}>
                      <text className={` w-16 h-7 bg-gray-300`}></text>

                      <text className={` w-7 h-7 bg-gray-300`}></text>
                    </div>

                    <button
                      disabled={true}
                      onClick={() => onSelectNQA(k + 1)}
                      className={`${styles.nqaButton}`}
                    >
                      Start NQA{k + 1}{" "}
                    </button>
                  </div>
                ))}

            <div className={styles.serviceDetailsProgressCard}>
              <div className={styles.serviceDetailIconContainer}>
                <Icon className={styles.serviceDetailIcon} name="cog" />
              </div>
              <text className={styles.serviceDetailText}>
                Complete or update your service details
              </text>

              <button
                className={styles.serviceDetailButton}
                onClick={() => history.push(`${match.path}/service-details`)}
              >
                View Details
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NQADashboard;
