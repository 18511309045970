import * as React from "react";
import PropTypes from "prop-types";

import { Link, useHistory, useRouteMatch } from "react-router-dom";

import styles from "./NominatedSupervisor.module.css";
import BlueButton from "../../../components/BlueButton";
import InputComponent from "../../../components/InputComponent";
import { ServiceDetailsContext } from "../../../context/ServiceDetailsContext";
import { Icon } from "semantic-ui-react";
import { useStoreState } from "../../../hooks";
import ServiceSteps from "../../../components/Steps/ServiceSteps";

import { FaSpinner } from "react-icons/fa";

const { useContext, useState } = React;

const stepsNotDone = [
  {
    id: 6,
    stepsName: "Leader",
  },
  {
    id: 7,
    stepsName: "Operating hours",
  },
  {
    id: 8,
    stepsName: "Additional info",
  },
  {
    id: 9,
    stepsName: "Educators",
  },
  {
    id: 10,
    stepsName: "QIP submission",
  },
  {
    id: 11,
    stepsName: "Philosophy",
  },
  {
    id: 12,
    stepsName: "Upload logo",
  },
];

const stepsDone = [
  {
    id: 1,
    stepsName: "Location",
  },
  {
    id: 2,
    stepsName: "Postal Address",
  },
  {
    id: 3,
    stepsName: "Contact Details",
  },
  {
    id: 4,
    stepsName: "Providers",
  },
];

function NominatedSupervisor({ onNextStep, onSaveStep, onSkipServiceDetails }) {
  const history = useHistory();
  const match = useRouteMatch();

  const {
    saveLoading,
    serviceDetailsState,
    changeInputDispatch,
    stepsIndicator,
  } = useContext(ServiceDetailsContext);

  const isLoggedIn = useStoreState((state) => state.authStore.isLoggedIn);
  const [fieldChecked, setFieldChecked] = useState(false);

  const onSave = async () => {
    setFieldChecked(true);
    if (
      !serviceDetailsState.validation.supervisorFirstName ||
      !serviceDetailsState.validation.supervisorLastName ||
      !serviceDetailsState.validation.supervisorEmail ||
      !serviceDetailsState.validation.supervisorPhoneNum
    ) {
      return;
    }
    await onSaveStep();

    onNextStep();
    history.push(
      `${match.path
        .split("/")
        .slice(0, match.path.split("/").length - 1)
        .join("/")}/6`
    );
    // } else alert("Please fill all required fields.");
  };

  return (
    <div className={styles.mainContainer}>
      {isLoggedIn && (
        <>
          <div className={styles.headerContainer}>
            <h1>Complete your service details</h1>
            <p>
              Please fill out all missing fields marked with an ‘
              <span className={styles.required}>*</span>’
            </p>
          </div>
        </>
      )}

      <div className={styles.mainCard}>
        <div className={styles.cardContent}>
          <h1 className={styles.headerText}>Nominated Supervisor</h1>

          <text className={styles.descriptionText}>
            Please complete the below contact fields.
          </text>

          <div className={styles.inputRow}>
            <div className={styles.inputColumn}>
              <InputComponent
                fieldName="supervisorFirstName"
                label="First name"
                type="text"
                required
                showNotFilled={fieldChecked}
                isInvalid={!serviceDetailsState.validation.supervisorFirstName}
                inputValue={serviceDetailsState.input.supervisorFirstName}
                setInputValue={changeInputDispatch}
              />

              <InputComponent
                fieldName="supervisorLastName"
                label="Last name"
                type="text"
                required
                showNotFilled={fieldChecked}
                isInvalid={!serviceDetailsState.validation.supervisorLastName}
                inputValue={serviceDetailsState.input.supervisorLastName}
                setInputValue={changeInputDispatch}
              />
            </div>

            <div className={styles.inputColumn}>
              <InputComponent
                fieldName="supervisorEmail"
                label="Email address"
                type="email"
                required
                showNotFilled={fieldChecked}
                isInvalid={!serviceDetailsState.validation.supervisorEmail}
                inputValue={serviceDetailsState.input.supervisorEmail}
                setInputValue={changeInputDispatch}
              />

              <InputComponent
                fieldName="supervisorPhoneNum"
                label="Phone number"
                type="text"
                required
                showNotFilled={fieldChecked}
                isInvalid={!serviceDetailsState.validation.supervisorPhoneNum}
                inputValue={serviceDetailsState.input.supervisorPhoneNum}
                setInputValue={changeInputDispatch}
              />
            </div>

            <div className={styles.inputColumn}>
              <InputComponent
                fieldName="supervisorMobileNum"
                label="Mobile number"
                type="text"
                inputValue={serviceDetailsState.input.supervisorMobileNum}
                setInputValue={changeInputDispatch}
              />
              <InputComponent
                fieldName="supervisorFaxNum"
                label="Fax number"
                type="text"
                inputValue={serviceDetailsState.input.supervisorFaxNum}
                setInputValue={changeInputDispatch}
              />
            </div>
          </div>

          <div className={styles.buttonGroup}>
            <BlueButton // buttonText="Save step"
              buttonText={
                saveLoading ? (
                  <div className={`flex justify-center items-center`}>
                    <FaSpinner className={`animate-spin`} />
                    <span className={`block pl-2`}>Save step</span>
                  </div>
                ) : (
                  <> Save step</>
                )
              }
              onClick={onSave}
            />

            <button
              className={styles.buttonNextStep}
              onClick={() =>
                history.push(
                  `${match.path
                    .split("/")
                    .slice(0, match.path.split("/").length - 1)
                    .join("/")}/6`
                )
              }
            >
              Next step
            </button>
          </div>

          <div className={styles.buttonStep}>
            <button
              className={styles.buttonStepBack}
              onClick={() =>
                history.push(
                  `${match.path
                    .split("/")
                    .slice(0, match.path.split("/").length - 1)
                    .join("/")}/4`
                )
              }
            >
              <Icon name="angle left" />
              Back a step
            </button>

            <button
              className={styles.buttonFinishLater}
              onClick={onSkipServiceDetails}
            >
              Finish Later
              <Icon name="angle double right" />
            </button>
          </div>
        </div>

        <div className={styles.stepsColumn}>
          {/* {!isLoggedIn && (
            <div
              className={`${styles.stepItemContainer} ${styles.stepItemTickContainer}`}
              onClick={() => history.push(`/qip/auth/legal-notice`)}
            >
              <div className={styles.stepContainerText}>
                <div className={styles.stepItemIndicatorContainer}>
                  <div className={styles.stepItemFillDone} />
                </div>
                Welcome
              </div>
            </div>
          )} */}
          <ServiceSteps route={match.path} />
          {/* {stepsDone.map((steps) => (
            <div
              key={steps.id}
              className={
                stepsIndicator.find((step) => step.id === steps.id).completed
                  ? `${styles.stepItemContainer} ${styles.stepItemTickContainer}`
                  : `${styles.stepItemContainer} ${styles.stepItemCrossContainer}`
              }
              onClick={() =>
                history.push(
                  `${match.path
                    .split("/")
                    .slice(0, match.path.split("/").length - 1)
                    .join("/")}/${steps.id}`
                )
              }
            >
              <div className={styles.stepContainerText}>
                <div className={styles.stepItemIndicatorContainer}>
                  {stepsIndicator.find((step) => step.id === steps.id)
                    .completed ? (
                    <div className={styles.stepItemFillDone} />
                  ) : (
                    <Icon className={styles.stepItemUnfinished} name="close" />
                  )}
                </div>
                {steps.stepsName}
              </div>
            </div>
          ))}

          <div className={styles.CurrentItemContainer}>
            <div className={styles.stepItemCurrent}>
              <div className={styles.stepItemFillDone} />
            </div>

            <text className={styles.stepItemCurrentText}>Supervisor</text>
          </div>
          {stepsNotDone.map((steps) => (
            <div key={steps.id} className={styles.stepItemContainer}>
              <Link
                className={styles.stepItemCurrentText}
                to={`${match.path
                  .split("/")
                  .slice(0, match.path.split("/").length - 1)
                  .join("/")}/${steps.id}`}
              >
                <div className={styles.stepItemIndicatorContainer}>
                  <div className={styles.stepItemFill} />
                </div>
                {steps.stepsName}
              </Link>
            </div>
          ))} */}
        </div>
      </div>
    </div>
  );
}

NominatedSupervisor.propTypes = {
  onNextStep: PropTypes.func,
  onSkipStep: PropTypes.func,
  onSaveStep: PropTypes.func,
  onSkipServiceDetails: PropTypes.func,
};

export default NominatedSupervisor;
