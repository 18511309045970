import React from "react";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import { useStoreActions, useStoreState } from "./hooks";
import Header from "./components/Header";
import NewHeader from "./components/NewHeader";
import Footer from "./components/Footer";
import NewFooter from "./components/NewFooter";
import Banner from "./components/Banner";
import PaymentFailedBanner from "./components/PaymentFailedBanner";
import ViewerInfoBar from "./components/ViewerInfoBar";
import LoggedInPages from "./routes/LoggedInPages";
import LoggedOutPages from "./routes/LoggedOutPages";
import ViewServiceDashboard from "./routes/ViewServiceDashboard/ViewServiceDashboard";
import HubAppRedirect from "./routes/HubAppRedirect/HubAppRedirect";
import { FaSpinner } from "react-icons/fa";

// import "./App.css";
// import "semantic-ui-css/semantic.min.css";
import MinimumWidthWarning from "./pages/MinimumWidthWarning";

import { app_cache } from "./utils";

const { useEffect, useState, useCallback } = React;

function App() {
  const isLoggedIn = useStoreState((state) => state.authStore.isLoggedIn);

  const fetchServices = useStoreActions(
    (state) => state.authStore.fetchServices
  );

  const userSelectedService = useStoreState(
    (state) => state.authStore.selectedService
  );

  const loadAuthToken = useStoreActions(
    (actions) => actions.authStore.loadAuthObject
  );

  const getRemainingTrial = useStoreActions(
    (actions) => actions.userStore.setRemainingTrial
  );

  const baseName = useStoreState((state) => state.authStore.baseName);

  const viewMode = useStoreState((state) => state.authStore.viewMode);

  const setIsAdmin = useStoreActions((actions) => actions.authStore.setIsAdmin);

  const [tokenLoading, setLoading] = useState(true);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const [redirectLoading, setRedirectLoading] = useState(false);

  useEffect(() => {
    loadAuthToken().then(() => {
      setLoading(false);
    });
  }, [fetchServices, loadAuthToken, setLoading]);

  useEffect(() => {
    const resize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", resize);
    resize();

    return () => window.removeEventListener("resize", resize);
  }, []);

  const user = useStoreState((state) => state.userStore.loggedInUser);

  const fetchPaymentMethods = useStoreActions(
    (actions) => actions.userStore.fetchPaymentMethods
  );

  const adminAttr = useStoreState((state) => state.authStore.adminAttr);

  const authObj = useStoreState((state) => state.authStore.authObject);

  const setSubExpiration = useStoreActions(
    (actions) => actions.appStore.setSubExpired
  );

  const getRemainingDays = () => {
    // const datetime_end = adminAttr.centers.filter(
    //   (center) => Number(center.center_id) === Number(userSelectedService.id)
    // )[0].datetime_end;
    // console.log("centers ", adminAttr.centers);
    // console.log("date time end ", datetime_end);
    // const dt1 = datetime_end.split("-");
    // //date string: 26-04-2022

    // const year1 = Number(dt1[2]);
    // const month1 = Number(dt1[1].replace(/^0+/, ""));
    // const day1 = Number(dt1[0].replace(/^0+/, ""));

    // const date1 = new Date(year1, month1 - 1, day1, 0, 0, 0);

    // const date2 = new Date();
    // const diffTime = date1 - date2;

    getRemainingTrial(userSelectedService.diffTime);
    return userSelectedService.diffTime;
  };

  const isTrialEndSoon = () => {
    return getRemainingDays() <= 5;
  };

  let isExpiring = false;
  let remainingDays = 0;
  let subExpired = false;
  let hasStripe = false;
  if (authObj && userSelectedService) {
    remainingDays = getRemainingDays();
    console.log("remaining ", remainingDays);
    hasStripe = authObj && authObj.stripeId ? true : false;
    // isExpiring = adminAttr ? isTrialEndSoon() : false;
    if (remainingDays <= 0 && !hasStripe) {
      setSubExpiration(true);
      subExpired = true;
    }
  }

  if (isLoggedIn && authObj && user) {
    if (user.role === "admin") {
      setIsAdmin(true);
      // try {
      //   if (authObj && authObj.stripeId) {
      //     fetchPaymentMethods({ userId: authObj.userId });
      //   }
      // } catch (error) {
      //   console.log(error);
      // }
    }
  }

  console.log("sub expired ", remainingDays <= 0);
  const banner = useStoreState((state) => state.appStore.banner);

  const nonBlockingUrls = [
    "pricing-plans",
    "free-trial",
    "signup",
    "payment",
    "login",
  ];
  const curLoc = window.location.href;
  let block700 = true;
  nonBlockingUrls.forEach((url) => {
    if (curLoc.indexOf(url) > -1) {
      block700 = false;
    }
  });

  return (
    <div
      className={`w-full h-full  min-h-screen flex flex-col justify-between relative`}
    >
      <div className={`flex-1`} style={{ backgroundColor: "#eaeee8" }}>
        {tokenLoading ? (
          <div
            className={`w-full h-screen flex flex-col justify-center items-center`}
          >
            <FaSpinner
              className={`animate-spin text-qipGreen font-bold text-6xl`}
            />

            <div>Logging in...</div>
          </div>
        ) : (
          <Router basename={baseName}>
            {/* {isLoggedIn &&
              isExpiring &&
              !hasStripe &&
              userSelectedService &&
              banner &&
              user &&
              user.role === "admin" && <Banner remaining={remainingDays} />} */}

            {/* {isLoggedIn &&
              userSelectedService &&
              userSelectedService.showPMBanner &&
              userSelectedService.hasSub &&
              user &&
              user.role === "admin" && (
                <PaymentFailedBanner service={userSelectedService} />
              )} */}

            {/* <Header /> */}
            {!redirectLoading && <NewHeader />}
            {user &&
              authObj &&
              isLoggedIn &&
              viewMode &&
              windowWidth >= 768 && <ViewerInfoBar />}

            {windowWidth < 768 && block700 ? (
              <MinimumWidthWarning />
            ) : (
              <Switch>
                {/* <Route
                  path={`/view-hub-admin-service`}
                  render={() => {
                    return (
                      <HubAppRedirect
                        iAmLoading={(val) => setRedirectLoading(val)}
                      />
                    );
                  }}
                /> */}
                <Route
                  path={`/hub-app-launch-redirect`}
                  render={() => {
                    return (
                      <HubAppRedirect
                        iAmLoading={(val) => setRedirectLoading(val)}
                      />
                    );
                  }}
                />
                <Route
                  path={`/view-service-dashboard`}
                  render={() => {
                    return <ViewServiceDashboard />;
                  }}
                />

                <Route
                  path="/qip/auth"
                  render={() => {
                    return !isLoggedIn ? (
                      <LoggedOutPages />
                    ) : (
                      <Redirect to="/qip" />
                    );
                  }}
                />
                <Route
                  path="/qip"
                  render={() => {
                    return isLoggedIn ? (
                      <LoggedInPages />
                    ) : (
                      <Redirect to="/qip/auth" />
                    );
                  }}
                />
                <Route
                  path="/"
                  render={() => {
                    return isLoggedIn ? (
                      <Redirect to="/qip" />
                    ) : (
                      <Redirect to="/qip/auth" />
                    );
                  }}
                />
              </Switch>
            )}
            {/* <Footer /> */}
            <NewFooter />
          </Router>
        )}
      </div>
    </div>
  );
}

export default App;
