import * as React from 'react';

import PropTypes from 'prop-types';
import { Modal } from 'semantic-ui-react';

import BlueButton from '../../BlueButton'; 
import styles from './SuccessSendEmailModal.module.css';

function SendEmailModal({ visibility, onOpenModal, onCloseModal }) {

  return (
    <Modal
      size="small"
      centered
      open={visibility}
      onOpen={onOpenModal}
      onClose={onCloseModal}
    >
      <div className={styles.modalContainer}>

        <text className={styles.headerText}>
          Invoice sent to johndoe@gmail.com
        </text>

        <BlueButton
          className={styles.saveButton}
          buttonText="Ok"
          onClick={onCloseModal}
        />

      </div>

    </Modal>
  );
}

SendEmailModal.propTypes = {
  visibility: PropTypes.bool.isRequired,
  onOpenModal: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired,
};

export default SendEmailModal;
