import * as React from "react";

import useSWR from "swr";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import { Button, Modal, Icon, Dropdown } from "semantic-ui-react";
import  CustomEditor from "../../CkEditor";
import Fancybox from "../../FancyBox/fancybox";
import {
  FaFileUpload,
  FaLink,
  FaCommentAlt,
  FaInfoCircle,
  FaFileAlt,
  FaSpinner,
  FaTimes,
} from "react-icons/fa";
import { Alert, message } from "antd";

import styles from "./NQAEvidenceModal.module.css";

import BlueButton from "../../BlueButton";
import SkipButton from "../../SkipButton";
import InputComponent from "../../InputComponent";
import ChangeStatusModal from "../ChangeStatusModal";
import DeleteTaskModal from "../DeleteTaskModal";
import {
  deleteAttachment,
  fetchCenterUsers,
  postTask,
  updateTask,
  uploadEvidence,
  uploadEvidenceLink,
  uploadEvidenceComment,
  deleteTask,
  editEvidence,
  postComply,
} from "../../../api/requests";
// import moment from "moment";
import moment from "moment-timezone";
import { isEmpty } from "lodash";
import swal, { SweetAlertIcon } from "sweetalert2";
import previewUnavailable from "../../../static/images/preview-unavailable.png";

const { useEffect, useRef, useState } = React;
export const alertMessage = (type, message) => {
  return swal.fire({
    icon: type,
    title: message,
    showConfirmButton: false,
    timer: 1500,
  });
};

function NQAEvidenceModalv2({
  visibility,
  onOpenModal,
  onCloseModal,
  refreshModal,
  onMutation,
  evidenceObject,
}) {
  const { nqaId } = useParams();

  const inputRef = useRef(null);
  const tasksRef = useRef(null);

  const ref = useRef(null);

  const [taskDetail, setTaskDetail] = useState(undefined); // taskDetail values reset when task detail view is folded
  const [tasksVisibility, setTasksVisibility] = useState(
    evidenceObject.openTasks
  );

  const [evidencesVisible, setEvidencesVisible] = useState(false);
  const [notes, setNotes] = useState("");
  const [taskModalVis, setTaskModalVis] = useState(false);
  const [active, setActive] = useState(false);
  const [statusModalVis, setStatusModalVis] = useState(false);
  const [evidenceUploadTitle, setEvidenceUploadTitle] = useState("evidence");
  const [evidenceLinkName, setEvidenceLinkName] = useState("");
  const [evidenceLinkLabel, setEvidenceLinkLabel] = useState("");
  const [evidenceLinkTitle, setEvidenceLinkTitle] = useState("link");
  const [evidenceCommentLabel, setEvidenceCommentLabel] = useState("");
  const [evidenceCommentName, setEvidenceCommentName] = useState("");
  const [evidenceCommentTitle, setEvidenceCommentTitle] = useState("comment");
  const [selectedEvidenceComment, setSelectedEvidenceComment] = useState({});
  const [editEvidenceComment, setEditEvidenceComment] = useState(false);
  // const handleChange = (e) => {
  //   setSelectedEvidenceComment({
  //     ...selectedEvidenceComment,
  //     name: e.target.value,
  //   });
  // };
  const [fieldsCheckedUpload, setfieldsCheckedUpload] = useState(false);
  const [fieldsCheckedLink, setFieldsCheckedLink] = useState(false);
  const [fieldsCheckedComment, setFieldsCheckedComment] = useState(false);

  const [fieldsCheckedTask, setFieldsCheckedTask] = useState(false);

  const [showDeleteTaskModal, setShowDeleteTaskModal] = useState(false);

  const [closing, setClosing] = useState(false);

  const [previewNotAvailable, setPreviewNotAvailable] = useState(false);
  const [summary, setSummary] = useState("");
  const [newTask, setNewTask] = useState({
    status: 1,
    taskDue: "",
    name: "",
  });

  // const [evidenceDocuments, setDocuments] = useState();
  const [evidenceLabel, setEvidenceLabel] = useState("");
  const [evidenceDocument, setEvidenceDocument] = useState({});

  const [selectedEvidenceType, setSelectedEvidenceType] = useState({
    name: "Upload",
  });

  const [postLoading, setLoading] = useState(false);
  // const [imagesLoading, setImagesLoading] = useState(true);

  // 1 - task status edit, 2 - new evidence task status edit
  const [changeStatusParent, setChangeStatusParent] = useState(1);

  const onChangeStatus = (parent) => {
    setChangeStatusParent(parent);
    setStatusModalVis(true);
  };

  const onManageNewTask = (statusField, value) => {
    setNewTask({
      ...newTask,
      [statusField]: value,
    });
  };

  const onChangeHandler = (statusField, value) => {
    setTaskDetail((previousTaskDetail) => ({
      ...previousTaskDetail,
      [statusField]: value,
    }));
  };

  const onSelectTaskItem = (task) => {
    if (taskDetail) {
      if (task.id === taskDetail.id) setTaskDetail(undefined);
      else setTaskDetail(task);
    } else setTaskDetail(task);
  };

  const onSelectEvidence = (event) => {
    const files = event.target.files;

    const ext = files[0].name.split(".")[files[0].name.split(".").length - 1];

    if (ext === "docx" || ext === "xlsx") {
      setPreviewNotAvailable(true);
    } else {
      setPreviewNotAvailable(false);
    }
    const reader = new FileReader();

    reader.readAsDataURL(files[0]);
    reader.onload = (e) => setEvidenceDocument(e.target.result);
  };
  // console.log("evidencedocument", evidenceDocument);
  const [errorCase, setErrorCase] = useState("");
  const onUploadEvidence = async () => {
    setfieldsCheckedUpload(true);
    if (!(evidenceLabel && evidenceDocument)) {
      // alert("Please give the evidence document a filename.");
      // message.warning({
      //   content: (
      //     <div className={`flex justify-center items-center`}>
      //       <FaInfoCircle className={`text-red-400`} />
      //       <span className={`ml-2`}>
      //         Please give the evidence document a filename.
      //       </span>
      //     </div>
      //   ),
      //   // duration: 1,
      //   icon: <> </>,
      // });

      return false;
    }

    const imagePayload = {
      title: evidenceUploadTitle,
      label: evidenceLabel,
      image: evidenceDocument,
      cqrId: evidenceObject.cqrId,
    };

    try {
      const response = await uploadEvidence(imagePayload);
      setfieldsCheckedUpload(false);
      console.log(typeof response);
      if (typeof response === "boolean") {
        setErrorCase("");
        return true;
      } else if (typeof response === "string") {
        setErrorCase(response);
      }
    } catch (error) {
      setfieldsCheckedUpload(false);
      alert("Error has occured.");
      console.log(error);

      return false;
    }
  };
  const onUploadEvidenceLink = async () => {
    setFieldsCheckedLink(true);
    if (!evidenceLinkName && !evidenceLinkLabel) {
      // message.warning({
      //   content: (
      //     <div className={`flex justify-center items-center`}>
      //       <FaInfoCircle className={`text-red-400`} />
      //       <span className={`ml-2`}>
      //         Please provide the evidence URL Link and memorable name.
      //       </span>
      //     </div>
      //   ),
      //   // duration: 1,
      //   icon: <> </>,
      // });
      return false;
    }
    const linkPayload = {
      name: evidenceLinkName,
      label: evidenceLinkLabel,
      title: evidenceLinkTitle,
      cqrId: evidenceObject.cqrId,
    };

    try {
      const response = await uploadEvidenceLink(linkPayload);

      if (response) return true;
    } catch (error) {
      console.log(error);

      return false;
    }
  };
  const onUploadEvidenceComment = async () => {
    setFieldsCheckedComment(true);
    if (!evidenceCommentLabel || !evidenceCommentName) {
      // message.warning({
      //   content: (
      //     <div className={`flex justify-center items-center`}>
      //       <FaInfoCircle className={`text-red-400`} />
      //       <span className={`ml-2`}>
      //         Please provide comment and name as your evidence for this element.
      //       </span>
      //     </div>
      //   ),
      //   // duration: 1,
      //   icon: <> </>,
      // });

      return;
    } else {
      const commentPayload = {
        name: evidenceCommentName,
        label: evidenceCommentLabel,
        title: evidenceCommentTitle,
        cqrId: evidenceObject.cqrId,
      };

      try {
        const response = await uploadEvidenceComment(commentPayload);

        if (response) return true;
      } catch (error) {
        console.log(error);

        return false;
      }
    }
  };

  const onCreateTask = async () => {
    setFieldsCheckedTask(true);
    if (!(newTask.name && newTask.status && summary && newTask.asignee)) {
      return;
    }

    const taskPayload = {
      nqaId: Number(nqaId),
      centerId: evidenceObject.centerId,
      questionId: evidenceObject.questionId,
      type: "evidence",
      name: newTask.name,
      taskDue: newTask.taskDue,
      status: newTask.status,
      summary: summary,
      asignee: newTask.asignee,
    };

    // if (Object.values(taskPayload).every(Boolean)) {
    setLoading(true);

    try {
      const response = await postTask(taskPayload);

      if (response) {
        setLoading(false);
        setFieldsCheckedTask(false);
        setTaskModalVis(true);
      }
      setNewTask({
        status: 1,
        taskDue: "",
        name: "",
        asignee: "",
      });
    } catch (error) {
      console.log(error);
      setFieldsCheckedTask(false);
      setLoading(false);
    }
    // } else
    //   message.warning({
    //     content: (
    //       <div className={`flex justify-center items-center`}>
    //         <FaInfoCircle className={`text-red-400`} />
    //         <span className={`ml-2`}>Please fill up all fields.</span>
    //       </div>
    //     ),
    //     // duration: 1,
    //     icon: <> </>,
    //   });
    // alert("Please fill up all fields.");
  };

  const onUpdateTask = async () => {
    setLoading(true);

    try {
      const response = await updateTask(taskDetail);

      if (response) await refreshModal(evidenceObject);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const onDeleteEvidence = async (attachmentId) => {
    setLoading(true);

    try {
      const response = await deleteAttachment(attachmentId);

      if (response) await refreshModal(evidenceObject);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const onEditEvidence = async (attachmentId) => {
    setLoading(true);

    try {
      const response = await editEvidence(
        attachmentId,
        selectedEvidenceComment.name
      );
      if (response) {
        alertMessage("info", "Evidence edited");
        refreshModal(evidenceObject);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const onSaveEvidence = async (e) => {
    switch (selectedEvidenceType.name) {
      case "Upload":
        setLoading(true);

        try {
          const response = await onUploadEvidence();
          if (response) {
            await refreshModal(evidenceObject);
            alertMessage("success", "Save Evidence");
          }
          setFiles([]);
          setImages([]);
          setfieldsCheckedUpload(false);
          setEvidenceLabel("");
        } catch (error) {
          console.log(error);
        }
        setLoading(false);

        break;
      case "Link":
        setLoading(true);

        try {
          const response = await onUploadEvidenceLink();

          if (response) {
            await refreshModal(evidenceObject);
            alertMessage("success", "Save Evidence");
          }
          setEvidenceLinkLabel("");
          setEvidenceLinkName("");
          setFieldsCheckedLink(false);
        } catch (error) {
          console.log(error);
        }
        setLoading(false);
        break;
      case "Comment":
        setLoading(true);

        try {
          const response = await onUploadEvidenceComment();

          if (response) {
            await refreshModal(evidenceObject);
            alertMessage("success", "Save Evidence");
          }
          setEvidenceCommentName("");
          setEvidenceCommentLabel("");
          setFieldsCheckedComment(false);
        } catch (error) {
          console.log(error);
        }
        setLoading(false);
        break;
    }
  };

  const onDeleteTask = async () => {
    try {
      const response = await deleteTask(taskDetail.id);

      setShowDeleteTaskModal(false);
      if (response) await refreshModal(evidenceObject);
    } catch (error) {
      console.log(error);
      setShowDeleteTaskModal(false);
    }
  };

  // const loadDocuments = useCallback(async () => {
  //   const docs = [];

  //   for (let index = 0; index < evidenceObject.evidenceDocs.length; index++) {
  //     const document = evidenceObject.evidenceDocs[index];

  //     try {
  //       const imageUrl = await fetchImage(document.id);

  //       docs.push({
  //         ...document,
  //         url: imageUrl
  //       });

  //     } catch (error) {
  //       console.log(error);
  //       setImagesLoading(false);
  //     }
  //   }

  //   setDocuments(docs);
  //   setImagesLoading(false);
  // }, [evidenceObject.evidenceDocs]);
  const onSave = async () => {
    if (evidenceDocument) {
      setLoading(true);
      const evidence_note = notes;
      try {
        const response = await postComply(
          evidenceObject.centerId,
          evidenceObject.questionId,
          1,
          evidenceObject?.strategy?.goalStrategy,
          evidenceObject?.strategy?.self_assessment,
          {
            evidence_note,
          }
        );

        if (response) {
          await onMutation();
        }
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    }
    // else onCloseModal();
  };

  const { data: users } = useSWR(
    [`/users`, evidenceObject.centerId],
    (_, centerId) => fetchCenterUsers(centerId)
  );
  const targetUser = users?.find(
    (item) =>
      item.id === JSON.parse(localStorage.getItem("inteli_token")).userId
  );
  // console.log("targetUser", targetUser);
  const evidenceType = [
    {
      name: "Upload",
      icon: <FaFileUpload />,
      node: <> </>,
    },
    {
      name: "Link",
      icon: <FaLink />,
      node: <> </>,
    },
    {
      name: "Comment",
      icon: <FaCommentAlt />,
      node: <> </>,
    },
  ];

  const FileType = ({ fileName }) => {
    const ext = fileName.split(".")[1];

    switch (ext) {
      case "png":
      case "jpeg":
      case "jpg":
      case "gif":
        return (
          <span>
            <span className={``}>Image</span> File
          </span>
        );
      case "xlsx":
      case "xls":
      case "csv":
        return (
          <span>
            <span className={``}>Excel</span> File
          </span>
        );
      case "doc":
      case "docx":
        return (
          <span>
            <span className={``}>Word</span> File
          </span>
        );
      case "pdf":
        return (
          <span>
            <span className={``}>PDF</span> File
          </span>
        );
      case "pot":
      case "potm":
      case "potx":
      case "ppa":
      case "ppt":
      case "pptx":
        return (
          <span>
            <span className={``}>PPT</span> File
          </span>
        );
      default:
        return <span>File</span>;
    }
  };

  useEffect(() => {
    if (evidenceObject.openTasks) {
      if (tasksRef.current)
        tasksRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [evidenceObject.openTasks]);

  // useEffect(() => {
  //   loadDocuments();
  // }, [loadDocuments]);

  const imageTypeRegex = /image\/(png|jpg|jpeg|gif|svg)/gm;
  const [files, setFiles] = useState([]);
  const [images, setImages] = useState([]);
  const [docData, setDocData] = useState(false);

  const changefileHandler = (e) => {
    const { files } = e.target;
    const validFiles = [];
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      if (!file.type.match(imageTypeRegex)) {
        setDocData(true);
      } else {
        setDocData(false);
      }
      validFiles.push(file);
    }
    if (validFiles.length) {
      setFiles(validFiles);
      return;
    }
    alert("Selected images are not of valid type!");
  };
  useEffect(() => {
    const images = [],
      fileReaders = [];
    let isCancel = false;
    if (files.length) {
      files.forEach((file) => {
        const fileReader = new FileReader();
        fileReaders.push(fileReader);
        fileReader.onload = (e) => {
          const { result } = e.target;
          if (result) {
            images.push(result);
          }
          if (images.length === files.length && !isCancel) {
            setImages(images);
            setEvidenceDocument(images.join("&"));
          }
        };
        fileReader.readAsDataURL(file);
      });
    }
    return () => {
      isCancel = true;
      fileReaders.forEach((fileReader) => {
        if (fileReader.readyState === 1) {
          fileReader.abort();
        }
      });
    };
  }, [files]);
  // const [newDocument, setNewDocument] = useState({});
  // useEffect(() => {
  //   let imgData = [];
  //   evidenceObject.evidenceDocs.filter((item) => {
  //     if (
  //       item.title === "evidence" &&
  //       (item.label.split(".")[1].toString() === "png" ||
  //         item.label.split(".")[1].toString() === "jpeg" ||
  //         item.label.split(".")[1].toString() === "jpg" ||
  //         item.label.split(".")[1].toString() === "gif")
  //     ) {
  //       imgData.push(item);
  //       setNewDocument(imgData);
  //     }
  //   });
  // }, []);
  // console.log("objectImages", newDocument);
  const OptionType = ({ optionFile, dataFile, dataId }) => {
    const extFile = optionFile.split(".")[1];

    switch (extFile) {
      case "png":
      case "jpeg":
      case "jpg":
      case "gif":
        return (
          <Fancybox
            options={{
              infinite: false,
              Thumbs: {
                autoStart: false,
              },
            }}
          >
            <div className="flex gap-4 mt-4">
              {dataFile.map((item) => (
                <a data-fancybox={"gallery" + dataId} href={item}>
                  <img alt="" src={item} width="100" height="75" />
                </a>
              ))}
            </div>
          </Fancybox>
        );
      default:
        return "";
    }
  };
  const linkRef = useRef();
  const ButtonType = ({ buttonFile, btnDataFile, btnDataId }) => {
    const extFile = buttonFile.split(".")[1];
    switch (extFile) {
      case "png":
      case "jpeg":
      case "jpg":
      case "gif":
        return (
          <>
            <Fancybox
              options={{
                infinite: false,
                Thumbs: {
                  autoStart: false,
                },
              }}
            >
              <div className={`flex gap-4 ${styles.firstChildDisplay}`}>
                {btnDataFile.map((item) => (
                  <>
                    <a
                      data-fancybox={"gallery" + btnDataId + "new"}
                      href={item}
                      ref={linkRef}
                    >
                      {/* <img
                        alt=""
                        src={item}
                        width="100"
                        height="75"
                        className="hidden"
                      /> */}
                      <Button className={styles.downloadButton} icon="eye" />
                    </a>
                  </>
                ))}
              </div>
            </Fancybox>
          </>
        );
      default:
        return (
          <Button
            className={styles.downloadButton}
            icon="download"
            onClick={() =>
              (window.location.href = `${process.env.REACT_APP_QIP_API_URL}/api/uploads/${btnDataId}`)
            }
          />
        );
    }
  };
  return (
    <Modal
      size="large"
      centered={false}
      open={visibility}
      onOpen={onOpenModal}
      onClose={onCloseModal}
    >
      {showDeleteTaskModal && (
        <DeleteTaskModal
          visibility={showDeleteTaskModal}
          onCloseModal={() => {
            setShowDeleteTaskModal(false);
          }}
          onRemoveTask={onDeleteTask}
        />
      )}

      {!users ? (
        <div
          className={`w-full h-screen flex flex-col justify-center items-center`}
        >
          <FaSpinner
            className={`animate-spin text-qipGreen font-bold text-6xl`}
          />
          <span className={`block`}> Loading data...</span>
        </div>
      ) : postLoading ? (
        <div
          className={`w-full h-screen flex flex-col justify-center items-center`}
        >
          <FaSpinner
            className={`animate-spin text-qipGreen font-bold text-6xl`}
          />
          <span className={`block`}> Loading data...</span>
        </div>
      ) : (
        <Modal.Content scrolling style={{ maxHeight: "95vh" }}>
          <div className={styles.modalContainer}>
            <button
              className={styles.closeButton}
              disabled={closing}
              onClick={() => {
                // setClosing(true);
                onCloseModal();
              }}
              size="big"
            >
              {closing ? (
                <div className={` flex justify-center items-center text-3xl`}>
                  <FaSpinner className={`animate-spin`} />
                </div>
              ) : (
                <div className={`flex justify-center items-center text-3xl`}>
                  <FaTimes />
                </div>
              )}
            </button>

            {statusModalVis && (
              <ChangeStatusModal
                visibility={statusModalVis}
                onSelectStatus={
                  changeStatusParent === 1 ? onChangeHandler : onManageNewTask
                }
                onOpenModal={() => setStatusModalVis(true)}
                onCloseModal={() => setStatusModalVis(false)}
              />
            )}

            {taskModalVis && (
              <Modal
                centered
                size="small"
                open={taskModalVis}
                onOpen={() => setTaskModalVis(true)}
                onClose={() => setTaskModalVis(false)}
              >
                <div className={styles.taskConfirmationModal}>
                  <text className={styles.headerText}>
                    Task set and team member notified.
                  </text>

                  <BlueButton
                    buttonText="Close"
                    className={styles.blueButton}
                    onClick={async () => {
                      setTaskModalVis(false);
                      await refreshModal(evidenceObject);
                    }}
                  />
                </div>
              </Modal>
            )}

            <div className={styles.modalContent}>
              <div className={styles.modalHeaderRow}>
                <text className={styles.headerText}>
                  Provide{" "}
                  {evidenceObject.isCustom
                    ? "evidences of your strength"
                    : "evidences"}
                </text>

                <Alert
                  className={`mt-8`}
                  message={
                    <div className={`py-10 px-14 text-2xl text-center`}>
                      {/* Provide evidence to support policies and procedures for
                      documentation are easily available at the service{" "} */}
                      {evidenceObject.yesDescription
                        ? evidenceObject.yesDescription
                        : "Add or update your QIP/SAT evidences. These can be documents, image to support your ‘YES’ answers."}
                    </div>
                  }
                  type={`info`}
                />
                {/* <text className={styles.subText}>
                  {evidenceObject.yesDescription
                    ? evidenceObject.yesDescription
                    : "Add or update your QIP/SAT evidences. These can be documents, image to support your ‘YES’ answers."}
                </text> */}
              </div>
              <div className={`w-full`} style={{ padding: "1rem 12.5rem" }}>
                <h4 className={`text-4xl text-center mb-8`}>
                  Choose an evidence type
                </h4>
                <div className={`w-full flex justify-center items-center`}>
                  {evidenceType.map((type, index) => (
                    <div
                      key={type.name}
                      className={`relative w-1/3 h-28 flex justify-center items-center cursor-pointer ${
                        styles.evidenceTypeButton
                      } ${
                        selectedEvidenceType.name === type.name
                          ? "bg-evidenceType text-white"
                          : "bg-gray-100 text-gray-400 hover:text-evidenceType"
                      } `}
                      onClick={() => {
                        setfieldsCheckedUpload(false);
                        setFieldsCheckedLink(false);
                        setFieldsCheckedComment(false);
                        setSelectedEvidenceType(type);
                      }}
                    >
                      {selectedEvidenceType.name === type.name && (
                        <div
                          className={styles.evidenceTypeSelectedArrow}
                          style={{ borderTopColor: "#8cc336" }}
                        />
                      )}
                      <div
                        className={`flex justify-center items-center text-xl font-semibold`}
                      >
                        {type.icon}
                        <span className={`ml-2`}> {type.name}</span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className={`mt-12`}>
                {selectedEvidenceType.name === "Upload" && (
                  <div className={styles.uploadEvidenceRow}>
                    {/* <input
                      ref={inputRef}
                      type="file"
                      style={{ display: "none" }}
                      onChange={onSelectEvidence}
                      multiple="multiple"
                    />
                    {!evidenceDocument && (
                      <span className={styles.titleText}>
                        Upload your evidence as a document, image or video.
                      </span>
                    )}
                    <div
                      className={styles.uploadEvidencePlaceholder}
                      onClick={() => inputRef.current.click()}
                    >
                      {evidenceDocument ? (
                        <div className={`flex justify-center`}>
                          {!previewNotAvailable ? (
                            <object
                              // width="80%"
                              // height="80%"
                              className={`object-contain w-full h-96 `}
                              data={evidenceDocument}
                            ></object>
                          ) : (
                            <div
                              className={`w-full h-96 flex justify-center items-center`}
                            >
                              <img src={previewUnavailable} />
                            </div>
                          )}
                        </div>
                      ) : (
                        <div
                          style={{
                            paddingTop: "10%",
                            paddingBottom: "10%",
                          }}
                        >
                          <Icon
                            className={styles.uploadEvidenceIcon}
                            name="arrow up"
                          />

                          <text className={styles.uploadEvidenceText}>
                            Upload evidence
                          </text>
                        </div>
                      )}
                    </div>

                    {fieldsCheckedUpload && !evidenceDocument && (
                      <div className={`p-2 text-red-400 font-thin`}>
                        Please upload your evidence document.
                      </div>
                    )} */}

                    <label htmlFor="file" className={styles.titleText}>
                      Upload your evidence as a document, image or video.
                    </label>
                    {errorCase && (
                      <p className="text-red-700 font-medium text-lg py-2.5">
                        {errorCase}
                      </p>
                    )}
                    <input
                      type="file"
                      id="file"
                      onChange={changefileHandler}
                      className={styles.fileUploader}
                      // accept="image/png, image/jpg, image/jpeg"
                      multiple
                    />
                    {docData === false ? (
                      images.length > 0 && (
                        <div className="flex flex-wrap items-center gap-4 p-5 mt-5 border border-gray-400 border-dashed">
                          {images.map((image, idx) => {
                            return (
                              <p key={idx} className={styles.multiple_img}>
                                <img src={image} alt="" />
                              </p>
                            );
                          })}
                        </div>
                      )
                    ) : (
                      <div className="mt-5">
                        <img
                          src={previewUnavailable}
                          width="220"
                          className="border border-gray-200 border-solid"
                        />
                      </div>
                    )}

                    <InputComponent
                      label="Add a memorable name (it will show on your Strengths Report)"
                      type={`text`}
                      fieldName={`evidenceLabel`}
                      required
                      showNotFilled={fieldsCheckedUpload}
                      inputValue={evidenceLabel}
                      setInputValue={(fieldName, value) =>
                        setEvidenceLabel(value)
                      }
                    />

                    <BlueButton
                      className={styles.blueButton}
                      disabled={postLoading}
                      buttonText="Save evidence image"
                      onClick={onSaveEvidence}
                    />
                  </div>
                )}

                {selectedEvidenceType.name === "Link" && (
                  <div className={styles.linkEvidenceRow}>
                    <InputComponent
                      fieldName={`evidenceLinkName`}
                      placeholder={`https://myexampleurl.com.au`}
                      required
                      showNotFilled={fieldsCheckedLink}
                      setInputValue={(fname, value) => {
                        setEvidenceLinkName(value);
                      }}
                      type={`text`}
                      label={`Provide a URL link as your evidence for this element`}
                    />
                    <InputComponent
                      fieldName={`evidenceLinkLabel`}
                      placeholder={`My short evidence document name`}
                      required
                      showNotFilled={fieldsCheckedLink}
                      setInputValue={(fname, value) => {
                        setEvidenceLinkLabel(value);
                      }}
                      type={`text`}
                      label={
                        <>
                          <span>Add a memorable name</span>
                          <span className={`italic`}>
                            (it will show on your Strengths Report)
                          </span>{" "}
                        </>
                      }
                    />
                    <BlueButton
                      disabled={postLoading}
                      className={styles.blueButton}
                      buttonText="Save evidence"
                      onClick={onSaveEvidence}
                    />
                  </div>
                )}
                {selectedEvidenceType.name === "Comment" && (
                  <div className={styles.commentEvidenceRow}>
                    {/* <InputComponent
                      fieldName={`evidenceCommentName`}
                      placeholder={`Write your comment here`}
                      setInputValue={(fname, value) => {
                        setEvidenceCommentName(value);
                      }}
                      required
                      showNotFilled={fieldsCheckedComment}
                      textArea
                      className={`h-32`}
                      label={`Provide a description of where you can see this evidence in practice (it will show on your Strengths Report)`}
                    /> */}
                    <label className={`mb-2 ${styles.titleText} inline-block`}>
                      Add a memorable description
                      <span className="text-red-400">*</span>
                    </label>
                    <div className={styles.ckeditorStyles}>
                      <CustomEditor
                        initData={evidenceCommentName}
                        onSetData={setEvidenceCommentName}
                      />
                    </div>
                    <InputComponent
                      fieldName={`evidenceCommentLabel`}
                      placeholder={`My short evidence document name`}
                      required
                      showNotFilled={fieldsCheckedComment}
                      setInputValue={(fname, value) => {
                        setEvidenceCommentLabel(value);
                      }}
                      type={`text`}
                      label={`Add a memorable name`}
                    />

                    <BlueButton
                      disabled={postLoading}
                      className={styles.blueButton}
                      buttonText="Save evidence"
                      onClick={onSaveEvidence}
                    />
                  </div>
                )}
              </div>

              <div className={styles.evidenceDocumentGroup}>
                {evidenceObject.evidenceDocs.length > 0 && (
                  <>
                    <div className={`w-full mb-5`}>
                      <div
                        className={`w-full flex justify-between items-center`}
                      >
                        <div className={styles.evidenceCountText}>
                          <span className={styles.evidenceCount}>
                            {evidenceObject.evidenceDocs.length}&nbsp;
                          </span>
                          <span>
                            <span>evidence</span>
                            {evidenceObject.evidenceDocs.length > 1 ? "s" : ""}
                            &nbsp;<span>provided</span>
                          </span>
                        </div>
                        <div>
                          {!evidencesVisible ? (
                            <button
                              className={styles.showTasksButton}
                              onClick={() =>
                                setEvidencesVisible(!evidencesVisible)
                              }
                            >
                              Show evidences
                            </button>
                          ) : (
                            <BlueButton
                              buttonText="Close evidences"
                              onClick={() =>
                                setEvidencesVisible(!evidencesVisible)
                              }
                            />
                          )}
                        </div>
                      </div>
                    </div>
                    {evidencesVisible &&
                      evidenceObject.evidenceDocs.map((document) => (
                        <>
                          {document.title === "evidence" && (
                            <div
                              key={document.id}
                              className={`${styles.evidenceDocumentRow} gap-8`}
                            >
                              <div className={styles.evidenceDocumentDisplay}>
                                {/* <div className={styles.evidenceImageContainer}>
                          <img
                            className={styles.evidenceImage}
                            alt="Evidence image"
                            src={`data:image/jpeg;base64,${document.url}`}
                          />
                        </div> */}

                                <div className={styles.evidenceDocumentDetails}>
                                  <text
                                    className={styles.evidenceDocumentFileName}
                                  >
                                    {document.label}
                                  </text>

                                  <text
                                    className={
                                      styles.evidenceDocumentUploadDate
                                    }
                                  >
                                    <FileType fileName={document.name} />
                                    {` Uploaded | ${moment
                                      .utc(
                                        document.uploadDate,
                                        "YYYY-MM-DD hh:mm:ss"
                                      )
                                      .local()
                                      .format("DD/MM/YYYY")}`}
                                  </text>
                                  {/* {document.url && (
                                    <Fancybox options={{ infinite: false }}>
                                      <div className="flex gap-4 mt-4">
                                        {document.url.map((item) => (
                                          <a
                                            data-fancybox="gallery"
                                            href={item.url}
                                          >
                                            <img
                                              alt=""
                                              src={item.url}
                                              width="100"
                                              height="75"
                                            />
                                          </a>
                                        ))}
                                      </div>
                                    </Fancybox>
                                  )} */}
                                  <OptionType
                                    optionFile={document.label}
                                    dataFile={document.url}
                                    dataId={document.id}
                                  />
                                  {/* <Fancybox
                                    options={{
                                      infinite: false,
                                      Thumbs: {
                                        autoStart: false,
                                      },
                                    }}
                                  >
                                    <div className="flex gap-4 mt-4">
                                      <a
                                        data-fancybox="gallery-1"
                                        href="https://www.plantman.com.au/wp-content/uploads/2022/08/Retaining-Wall-Systems-Factsheet-Cover-optimized-2.jpg"
                                      >
                                        <img
                                          alt=""
                                          src="https://www.plantman.com.au/wp-content/uploads/2022/08/Retaining-Wall-Systems-Factsheet-Cover-optimized-2.jpg"
                                          width="100"
                                          height="75"
                                        />
                                      </a>
                                    </div>
                                  </Fancybox> */}
                                </div>
                              </div>

                              <div
                                className={styles.evidenceDocumentActionGroup}
                              >
                                {targetUser.role === "admin" ? (
                                  <div className={styles.documentButtonGroup}>
                                    <Button
                                      className={styles.removeButton}
                                      icon="close"
                                      onClick={() =>
                                        onDeleteEvidence(document.id)
                                      }
                                    />
                                    {/* <text className={styles.documentButtonText}>
                              Remove
                            </text> */}
                                  </div>
                                ) : (
                                  ""
                                )}
                                <div className={styles.documentButtonGroup}>
                                  <ButtonType
                                    buttonFile={document.label}
                                    btnDataFile={document.url}
                                    btnDataId={document.id}
                                  />

                                  {/* <text className={styles.documentButtonText}>
                                Download
                              </text> */}
                                </div>
                              </div>
                            </div>
                          )}

                          {document.title === "link" && (
                            <div
                              key={document.id}
                              className={styles.evidenceDocumentRow}
                            >
                              <div className={styles.evidenceDocumentDisplay}>
                                {/* <div className={styles.evidenceImageContainer}>
                          <img
                            className={styles.evidenceImage}
                            alt="Evidence image"
                            src={`data:image/jpeg;base64,${document.url}`}
                          />
                        </div> */}

                                <div className={styles.evidenceDocumentDetails}>
                                  <text
                                    className={styles.evidenceDocumentFileName}
                                  >
                                    {document.label}
                                  </text>

                                  <text
                                    className={
                                      styles.evidenceDocumentUploadDate
                                    }
                                  >
                                    {`Link provided | ${moment
                                      .utc(
                                        document.uploadDate,
                                        "YYYY-MM-DD hh:mm:ss"
                                      )
                                      .local()
                                      .format("DD/MM/YYYY")}`}
                                  </text>
                                </div>
                              </div>

                              <div
                                className={styles.evidenceDocumentActionGroup}
                              >
                                {targetUser.role === "admin" ? (
                                  <div className={styles.documentButtonGroup}>
                                    <Button
                                      className={styles.removeButton}
                                      icon="close"
                                      onClick={() =>
                                        onDeleteEvidence(document.id)
                                      }
                                    />
                                    {/* <text className={styles.documentButtonText}>
                              Remove
                            </text> */}
                                  </div>
                                ) : (
                                  ""
                                )}
                                <div className={styles.documentButtonGroup}>
                                  <a
                                    target={`_blank`}
                                    href={document.name}
                                    className={`${styles.anchorButton} flex justify-center items-center p-2`}
                                  >
                                    <FaLink />
                                  </a>
                                </div>
                              </div>
                            </div>
                          )}
                          {document.title === "comment" && (
                            <>
                              <div
                                key={document.id}
                                className={styles.evidenceDocumentRow}
                              >
                                <div className={styles.evidenceDocumentDisplay}>
                                  {/* <div className={styles.evidenceImageContainer}>
                          <img
                            className={styles.evidenceImage}
                            alt="Evidence image"
                            src={`data:image/jpeg;base64,${document.url}`}
                          />
                        </div> */}

                                  <div
                                    className={styles.evidenceDocumentDetails}
                                  >
                                    <text
                                      className={
                                        styles.evidenceDocumentFileName
                                      }
                                    >
                                      {document.label}
                                    </text>

                                    <text
                                      className={
                                        styles.evidenceDocumentUploadDate
                                      }
                                    >
                                      {`Comment added ${
                                        document.userName
                                          ? "by" + " " + document.userName
                                          : ""
                                      } | ${moment
                                        .tz(
                                          document.uploadDate,
                                          "Australia/Sydney"
                                        )
                                        .format("DD/MM/YYYY")}`}
                                    </text>
                                  </div>
                                </div>

                                <div
                                  className={styles.evidenceDocumentActionGroup}
                                >
                                  {targetUser.role === "admin" ? (
                                    <div className={styles.documentButtonGroup}>
                                      <Button
                                        className={styles.removeButton}
                                        icon="close"
                                        onClick={() =>
                                          onDeleteEvidence(document.id)
                                        }
                                      />
                                      {/* <text className={styles.documentButtonText}>
                              Remove
                            </text> */}
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                  <div className={styles.documentButtonGroup}>
                                    {targetUser.role === "admin" ? (
                                      <Button
                                        className={styles.editButton}
                                        icon="edit"
                                        onClick={() => {
                                          if (editEvidenceComment === true) {
                                            setEditEvidenceComment(false);
                                            setSelectedEvidenceComment({});
                                            setActive(false);
                                          } else {
                                            setEditEvidenceComment(true);
                                            setSelectedEvidenceComment(
                                              document
                                            );
                                            setActive(false);
                                          }
                                        }}
                                      />
                                    ) : (
                                      ""
                                    )}
                                    {/* <text className={styles.documentButtonText}>
                                Remove
                              </text> */}
                                  </div>

                                  <div className={styles.documentButtonGroup}>
                                    <div className={` `}>
                                      <Button
                                        className={styles.downloadButton}
                                        icon="file alternate"
                                        onClick={() => {
                                          if (
                                            isEmpty(selectedEvidenceComment)
                                          ) {
                                            setSelectedEvidenceComment(
                                              document
                                            );
                                            setActive(true);
                                            setEditEvidenceComment(false);
                                          } else {
                                            setSelectedEvidenceComment({});
                                            setActive(false);
                                            setEditEvidenceComment(false);
                                          }
                                        }}
                                      />
                                    </div>

                                    {/* <text className={styles.documentButtonText}>
                                Download
                              </text> */}
                                  </div>
                                </div>
                              </div>
                              {selectedEvidenceComment &&
                                active &&
                                selectedEvidenceComment.id === document.id && (
                                  <div
                                    style={{
                                      backgroundColor: "#f8f8f6",
                                      height: "auto",
                                    }}
                                    className={` text-justify text-base py-8 px-10 rounded-lg`}
                                  >
                                    <div
                                      className={styles.ckeditorStyles}
                                      dangerouslySetInnerHTML={{
                                        __html: document.name,
                                      }}
                                    />
                                  </div>
                                )}
                              {editEvidenceComment &&
                                selectedEvidenceComment.id === document.id && (
                                  <div className="p-10 mb-10 bg-red-50">
                                    <h3 className="pb-4 text-3xl font-bold">
                                      Edit evidence
                                    </h3>
                                    <label
                                      htmlFor="comment"
                                      className="block mb-2 text-xl"
                                    >
                                      Edit your evidence document description
                                    </label>
                                    {/* <textarea
                                      className="w-full px-4 py-2 border border-black border-solid rounded"
                                      name="comment"
                                      value={selectedEvidenceComment.name}
                                      onChange={handleChange}
                                    /> */}
                                    <div className={styles.ckeditorStyles}>
                                      
                                      <CustomEditor
                                        initData={selectedEvidenceComment.name}
                                        onSetData={(data) => {
                                          setSelectedEvidenceComment({
                                            ...selectedEvidenceComment,
                                            name: data,
                                          });
                                        }}
                                      />
                                    </div>

                                    <BlueButton
                                      className={styles.blueButton}
                                      disabled={postLoading}
                                      buttonText="Edit evidence"
                                      onClick={() =>
                                        onEditEvidence(document.id)
                                      }
                                    />
                                  </div>
                                )}
                            </>
                          )}
                        </>
                      ))}
                  </>
                )}
              </div>

              <div className={styles.divider} />

              <text className={styles.tasksHeaderText}>
                Manage your {evidenceObject.isCustom ? "strength" : "evidence"}{" "}
                tasks
              </text>
              {evidenceObject.tasks.length !== 0 && (
                <>
                  <div ref={tasksRef} className={`${styles.tasksCountRow}`}>
                    <div>
                      <div>
                        <text>{evidenceObject.tasks.length}</text>

                        <text>assigned tasks</text>
                      </div>

                      {!tasksVisibility ? (
                        <button
                          className={styles.showTasksButton}
                          onClick={() => setTasksVisibility((prev) => !prev)}
                        >
                          Show tasks
                        </button>
                      ) : (
                        <BlueButton
                          buttonText="Close tasks"
                          onClick={() => setTasksVisibility((prev) => !prev)}
                        />
                      )}
                    </div>
                  </div>

                  {tasksVisibility && users && (
                    <div className={styles.tasksGroup}>
                      {evidenceObject.tasks.map((task) => (
                        <div key={task.id} className={styles.taskItemContainer}>
                          <div
                            className={styles.taskContentRow}
                            onClick={() => onSelectTaskItem(task)}
                          >
                            <div className={styles.taskDescriptionColumn}>
                              <text className={styles.taskDescriptionHeader}>
                                {task.name}
                              </text>

                              <div className={styles.taskDescriptionSubtext}>
                                <text
                                  className={
                                    task.status === 1
                                      ? styles.taskDetailNotStarted
                                      : task.status === 2
                                      ? styles.taskDetailInProgress
                                      : styles.taskDetailCompleted
                                  }
                                >
                                  {task.status === 1
                                    ? "Not started"
                                    : task.status === 2
                                    ? "In progress"
                                    : "Completed"}
                                </text>

                                <text>
                                  {/* {
                                    users.filter(
                                      (user) =>
                                        parseInt(user.id) === task.assignee
                                    )[0].name
                                  } */}
                                  {
                                    users.find(
                                      (user) =>
                                        parseInt(user.id) === task?.assignee
                                    )?.name
                                  }
                                </text>

                                <text>
                                  {/* {task.taskDue
                                    ? task.taskDue
                                        .substring(0, 10)
                                        .replace(/-/g, "/")
                                    : ""} */}
                                  {task.taskDue ? (
                                    moment
                                      .utc(task.taskDue, "YYYY-MM-DD hh:mm:ss")
                                      .local()
                                      .format("DD/MM/YYYY")
                                  ) : (
                                    <> </>
                                  )}
                                </text>
                              </div>
                            </div>

                            <Button
                              className={
                                taskDetail && taskDetail.id === task.id
                                  ? styles.taskCollapseButtonSelected
                                  : styles.taskCollapseButton
                              }
                              icon={
                                taskDetail && taskDetail.id === task.id
                                  ? "arrow up"
                                  : "arrow down"
                              }
                              onClick={() => setTaskDetail(task)}
                            />
                          </div>

                          {taskDetail && taskDetail.id === task.id ? (
                            <div className={styles.taskDetails}>
                              <InputComponent
                                fieldName="name"
                                label="Task name"
                                type="text"
                                inputValue={taskDetail.name}
                                setInputValue={onChangeHandler}
                              />

                              <div className={styles.dropdownRow}>
                                <div className={styles.dropdownGroup}>
                                  <text className={styles.dropdownText}>
                                    Task for
                                  </text>

                                  <Dropdown
                                    className={styles.dropdownBox}
                                    fluid
                                    selection
                                    scrolling
                                    placeholder="Choose"
                                    options={users.map((user) => ({
                                      text: user.name,
                                      value: user.id,
                                    }))}
                                    defaultValue={
                                      // users.filter(
                                      //   (user) =>
                                      //     parseInt(user.id) ===
                                      //     taskDetail.assignee
                                      // )[0].id
                                      users.find(
                                        (user) =>
                                          parseInt(user.id) ===
                                          taskDetail?.assignee
                                      )?.id
                                    }
                                    onChange={(e, data) =>
                                      onChangeHandler("asignee", data.value)
                                    }
                                  />
                                </div>

                                <div className={styles.dropdownGroup}>
                                  <text className={styles.dropdownText}>
                                    To be completed by
                                  </text>

                                  <div className={styles.datePickerGroup}>
                                    <input
                                      className={styles.datePickerBox}
                                      type="date"
                                      value={taskDetail.taskDue.substring(
                                        0,
                                        10
                                      )}
                                      onChange={(e) =>
                                        onChangeHandler(
                                          "taskDue",
                                          e.target.value
                                        )
                                      }
                                    />

                                    {/* <Icon
                                                className={styles.datePickerIcon}
                                                name="caret down"
                                              /> */}
                                  </div>
                                </div>
                              </div>

                              {/* <InputComponent
                                className={styles.summaryTextBox}
                                fieldName="summary"
                                label="Task summary hello"
                                textArea
                                inputValue={taskDetail.summary}
                                setInputValue={onChangeHandler}
                              /> */}
                              <label
                                className={`mb-2 ${styles.titleText} inline-block`}
                              >
                                Task summary
                              </label>
                              <div className={styles.ckeditorStyles}>
                               
                                <CustomEditor
                                  initData={taskDetail.summary}
                                  onSetData={(data) => onChangeHandler("summary", data)}
                                />
                              </div>

                              <div className={styles.dropdownGroup}>
                                <text className={styles.dropdownText}>
                                  Change task status
                                </text>

                                <div
                                  className={
                                    styles[`statusSelect${taskDetail.status}`]
                                  }
                                  onClick={() => onChangeStatus(1)}
                                >
                                  <text className={styles.dropdownText}>
                                    {taskDetail.status === 1
                                      ? "Not started"
                                      : taskDetail.status === 2
                                      ? "In progress"
                                      : "Completed"}
                                  </text>

                                  <Icon name="caret down" />
                                </div>
                              </div>
                              <div
                                className={`w-full flex justify-center items-center`}
                              >
                                <BlueButton
                                  className={styles.blueButton}
                                  buttonText="Update task"
                                  onClick={onUpdateTask}
                                />
                                <div className={`px-5`}></div>

                                <SkipButton
                                  className={styles.redButton}
                                  buttonText={`Remove task`}
                                  onClick={() => {
                                    setShowDeleteTaskModal(true);
                                  }}
                                />
                              </div>
                            </div>
                          ) : null}
                        </div>
                      ))}
                    </div>
                  )}
                  <text className={styles.headerText}>Add another task</text>
                </>
              )}

              <div className={styles.evidenceTaskRow}>
                <div className={styles.evidenceTaskForm}>
                  <InputComponent
                    fieldName="name"
                    label="Task name"
                    type="text"
                    required
                    showNotFilled={fieldsCheckedTask}
                    inputValue={newTask.name}
                    setInputValue={onManageNewTask}
                  />

                  <div className={styles.dropdownRow}>
                    <div className={styles.dropdownGroup}>
                      <text className={styles.dropdownText}>Task for</text>

                      <Dropdown
                        className={styles.dropdownBox}
                        fluid
                        selection
                        scrolling
                        placeholder="Choose"
                        options={users.map((user) => ({
                          text: user.name,
                          value: user.id,
                        }))}
                        // defaultValue={newTask.asignee}
                        onChange={(e, data) =>
                          onManageNewTask("asignee", data.value)
                        }
                      />
                      {fieldsCheckedTask && !newTask.asignee && (
                        <div className={`p-2 text-red-400 font-thin`}>
                          This field is required.
                        </div>
                      )}
                    </div>

                    <div className={styles.dropdownGroup}>
                      <text className={styles.dropdownText}>
                        To be completed by
                      </text>

                      {/* <div className={styles.datePickerGroup}> */}

                      <input
                        className={styles.datePickerBox}
                        type="date"
                        value={newTask.taskDue}
                        onChange={(e) =>
                          onManageNewTask("taskDue", e.target.value)
                        }
                      />
                      {fieldsCheckedTask && !newTask.taskDue && (
                        <div className={`p-2 text-red-400 font-thin`}>
                          This field is required.
                        </div>
                      )}

                      {/* <Icon
                          className={styles.datePickerIcon}
                          name="caret down"
                        /> */}

                      {/* </div> */}
                    </div>
                  </div>

                  {/* <InputComponent
                    className={styles.summaryTextBox}
                    fieldName="summary"
                    label="Task summary hehe"
                    textArea
                    required
                    showNotFilled={fieldsCheckedTask}
                    inputValue={newTask.summary}
                    setInputValue={onManageNewTask}
                  /> */}
                  <label className={`mb-2 ${styles.titleText} inline-block`}>
                    Task summary
                    <span className="text-red-400">*</span>
                  </label>
                  <div className={styles.ckeditorStyles}>
                    
                    <CustomEditor
                      initData={null}
                      onSetData={setSummary}
                    />

                    {/* <InputComponent
                      className={styles.summaryTextBox}
                      fieldName="summary"
                      label="Task summary hehe"
                      textArea
                      required
                      showNotFilled={fieldsCheckedTask}
                      inputValue={newTask.summary}
                      setInputValue={onManageNewTask}
                    /> */}
                  </div>
                  {fieldsCheckedTask && !summary && (
                    <div className={`p-2 text-red-400 font-thin`}>
                      This field is required.
                    </div>
                  )}

                  <div className={styles.dropdownGroup}>
                    <text className={styles.dropdownText}>
                      Change task status
                    </text>

                    <div
                      className={
                        styles[
                          `statusSelect${
                            newTask.status !== undefined ? newTask.status : 1
                          }`
                        ]
                      }
                      onClick={() => onChangeStatus(2)}
                    >
                      <text className={styles.dropdownText}>
                        {newTask.status === 1
                          ? "Not started"
                          : newTask.status === 2
                          ? "In progress"
                          : newTask.status === 3
                          ? "Completed"
                          : "Not started"}
                      </text>

                      <Icon name="caret down" />
                    </div>
                  </div>

                  <BlueButton
                    className={styles.blueButton}
                    buttonText="Set task"
                    onClick={onCreateTask}
                  />
                </div>
              </div>

              <div className={styles.divider} />

              <div className={styles.generalCommentsRow}>
                <text className={styles.headerText}>
                  General notes or comments
                </text>

                <label className={`mb-2 ${styles.titleText} inline-block`}>
                  Add additional comments here
                </label>
                <div className={styles.ckeditorStyles}>
                  <CustomEditor
                    initData={evidenceObject.comments}
                    onSetData={setNotes}
                  />
                </div>
                <BlueButton
                  buttonText="Save changes"
                  className={styles.blueButton}
                  onClick={onSave}
                />
              </div>
            </div>
          </div>
        </Modal.Content>
      )}
    </Modal>
  );
}

NQAEvidenceModalv2.propTypes = {
  visibility: PropTypes.bool.isRequired,
  onOpenModal: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  onMutation: PropTypes.func.isRequired,
  refreshModal: PropTypes.func.isRequired,
  evidenceObject: PropTypes.shape({
    evidenceDocs: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        title: PropTypes.string,
        uploadDate: PropTypes.string,
        url: PropTypes.string,
        userName: PropTypes.string,
      })
    ),
    tasks: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        centerId: PropTypes.number,
        questionId: PropTypes.number,
        name: PropTypes.string,
        status: PropTypes.number,
        nqaArea: PropTypes.string,
        taskDue: PropTypes.string,
        summary: PropTypes.string,
        assignee: PropTypes.number,
      })
    ),
    comments: PropTypes.string,
    cqrId: PropTypes.number,
    centerId: PropTypes.number,
    questionId: PropTypes.number,
    yesDescription: PropTypes.string,
    openTasks: PropTypes.bool,
    isCustom: PropTypes.bool,
  }).isRequired,
};

export default NQAEvidenceModalv2;
