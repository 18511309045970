import * as React from "react";
import { useHistory } from "react-router-dom";
import { FaRegArrowAltCircleLeft } from "react-icons/fa";
import styles from "./EditSubscription.module.css";
import BlueButton from "../../../components/BlueButton";
import SkipButton from "../../../components/SkipButton";
import CancelSubscriptionModal from "../../../components/Modals/CancelSubscriptionModal";
import ConfirmCancelSubscriptionModal from "../../../components/Modals/ConfirmCancelSubscriptionModal";
import NoticeCancelSubscriptionModal from "../../../components/Modals/NoticeCancelSubscriptionModal";
import { Tooltip } from "antd";
import { isEmpty } from "lodash";

const { useState } = React;

function EditSubscription({ location }) {
  const history = useHistory();

  if (isEmpty(location)) {
    history.push(`/qip/user-management/billing/2`);
  }

  const [
    cancelSubscriptionModalVisibility,
    setCancelSubscriptionModalVisibility,
  ] = useState(false);
  const [
    confirmCancelSubscriptionModalVisibility,
    setConfirmCancelSubscriptionModalVisibility,
  ] = useState(false);
  const [
    noticeCancelSubscriptionModalVisibility,
    setNoticeCancelSubscriptionModalVisibility,
  ] = useState(false);

  const onConfirmCancel = () => {
    setCancelSubscriptionModalVisibility(false);
    setConfirmCancelSubscriptionModalVisibility(true);
  };

  const onNoticeCancel = () => {
    setConfirmCancelSubscriptionModalVisibility(false);
    setNoticeCancelSubscriptionModalVisibility(true);
  };

  return (
    <div className={styles.mainContainer}>
      <CancelSubscriptionModal
        visibility={cancelSubscriptionModalVisibility}
        onOpenModal={() => setCancelSubscriptionModalVisibility(true)}
        onCloseModal={() => setCancelSubscriptionModalVisibility(false)}
        onCancel={onConfirmCancel}
      />
      <ConfirmCancelSubscriptionModal
        visibility={confirmCancelSubscriptionModalVisibility}
        onOpenModal={() => setConfirmCancelSubscriptionModalVisibility(true)}
        onCloseModal={() => setConfirmCancelSubscriptionModalVisibility(false)}
        onNotice={onNoticeCancel}
      />
      <NoticeCancelSubscriptionModal
        visibility={noticeCancelSubscriptionModalVisibility}
        onOpenModal={() => setNoticeCancelSubscriptionModalVisibility(true)}
        onCloseModal={() => setNoticeCancelSubscriptionModalVisibility(false)}
      />

      <div className={styles.backButtonContainer}>
        <button
          className={styles.backButtonText}
          onClick={() => history.goBack()}
        >
          <FaRegArrowAltCircleLeft
            className={styles.backButton}
            color="#C4C4C4"
            size={24}
          />
        </button>
      </div>

      <div className={styles.content}>
        <div className={styles.headerContainer}>
          <div className={styles.headerTextGroup}>
            <h1 className={styles.headerText}>Edit your subscription</h1>
            <text className={styles.headerSubText}>
              Upgrade or downgrade your subscription or cancel your subscription
              here.
            </text>
          </div>
          <button
            className={styles.backToDashboardButton}
            onClick={() => history.push("/qip/user-management/")}
          >
            Back to dashboard
          </button>
        </div>
        <div className={styles.cardContainer}>
          <div className={styles.row}>
            <div className={styles.subscription}>
              <h2 className={styles.subscriptionHeader}>
                Upgrade from QIP Portal to QIP + mentoring
              </h2>
              <text className={styles.text}>
                Sed ut perspiciatis unde omnis iste natus error sit voluptatem
                accusantium doloremque laudantium, totam rem aperiam, eaque ipsa
                quae ab illo inventore veritatis et quasi architecto beatae
                vitae dicta sunt explicabo.
              </text>

              <BlueButton
                disabled={location.state.product !== `QIP Portal`}
                className={styles.blueButton}
                onClick={() =>
                  history.push(
                    "/qip/user-management/subscription/upgrade-subscription"
                  )
                }
                buttonText="Upgrade now"
              />
            </div>
            <div className={styles.subscription}>
              <h2 className={styles.subscriptionHeader}>
                Downgrade from QIP + Mentoring to QIP Portal
              </h2>
              <text className={styles.text}>
                Sed ut perspiciatis unde omnis iste natus error sit voluptatem
                accusantium doloremque laudantium, totam rem aperiam, eaque ipsa
                quae ab illo inventore veritatis et quasi architecto beatae
                vitae dicta sunt explicabo.
              </text>

              <BlueButton
                disabled={location.state.product === `QIP Portal`}
                className={styles.blueButton}
                onClick={() =>
                  history.push(
                    "/qip/user-management/subscription/downgrade-subscription"
                  )
                }
                buttonText="Downgrade now"
              />
            </div>
            <div className={styles.subscription}>
              <h2 className={styles.subscriptionHeader}>
                Add a new service to your subscription
              </h2>
              <text className={styles.text}>
                Sed ut perspiciatis unde omnis iste natus error sit voluptatem
                accusantium doloremque laudantium, totam rem aperiam, eaque ipsa
                quae ab illo inventore veritatis et quasi architecto beatae
                vitae dicta sunt explicabo.
              </text>
              <button
                className={styles.addNewservice}
                onClick={() =>
                  history.push("/qip/user-management/services/upgrade")
                }
              >
                Add a new service
              </button>
            </div>
            <div className={styles.subscription}>
              <h2 className={styles.subscriptionHeader}>
                Cancel your subscription
              </h2>
              <text className={styles.text}>
                Sed ut perspiciatis unde omnis iste natus error sit voluptatem
                accusantium doloremque laudantium, totam rem aperiam, eaque ipsa
                quae ab illo inventore veritatis et quasi architecto beatae
                vitae dicta sunt explicabo.
              </text>
              <SkipButton
                buttonText="Cancel subscription"
                className={styles.cancelSubscription}
                onClick={setCancelSubscriptionModalVisibility}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditSubscription;
