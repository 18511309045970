import * as React from "react";

import PropTypes from "prop-types";
import { Modal } from "semantic-ui-react";

import SkipButton from "../../SkipButton";
import styles from "./ConfirmRedirectModal.module.css";
import { FaSpinner, FaTimes } from "react-icons/fa";
import BlueButton from "../../BlueButton";

const { useState } = React;

function ConfirmRedirectModal({
  message,
  buttonText,
  visibility,
  onOpenModal,
  onCloseModal,
  onConfirm,
}) {
  return (
    <Modal
      size="small"
      centered
      open={visibility}
      onOpen={onOpenModal}
      closeOnDimmerClick={false}
      onClose={onCloseModal}
    >
      <>
        <div className={`relative ${styles.modalContainer} `}>
          <button
            className={styles.closeButton}
            onClick={() => {
              onCloseModal();
            }}
            size="big"
          >
            {" "}
            <div className={`flex justify-center items-center text-3xl`}>
              <FaTimes />
            </div>{" "}
          </button>
          <text className={styles.headerText}>{message}</text>

          <div className={styles.buttonGroup}>
            <BlueButton buttonText={buttonText} onClick={onConfirm} />

            {/* <SkipButton onClick={onCloseModal} buttonText="No" /> */}
          </div>
        </div>
      </>
    </Modal>
  );
}

ConfirmRedirectModal.propTypes = {
  visibility: PropTypes.bool.isRequired,
  onOpenModal: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  onNotice: PropTypes.func.isRequired,
  onRemoveMessage: PropTypes.func.isRequired,
};

export default ConfirmRedirectModal;
