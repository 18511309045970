import React from "react";
import styles from "./NQASection.module.css";
import { subnqas } from "../../../data/subnqas";
import {
  FaCheck,
  FaExclamation,
  FaAngleUp,
  FaAngleDown,
  FaQuestionCircle,
  FaCopy,
  FaEye,
  FaSpinner,
  FaTimesCircle,
  FaExclamationTriangle,
} from "react-icons/fa";
import {
  useSpring,
  animated,
  config,
  useSprings,
  useTrail,
} from "react-spring";
import { isEmpty, debounce } from "lodash";
import { Popover } from "antd";
import {
  createSatData,
  updateSatData,
  removeSatData,
} from "../../../api/requests";
import ConfirmRemoveSatModal from "../../../components/Modals/ConfirmRemoveSatModal";
import LawsRegulationsModal from "../../../components/Modals/LawsRegulationsModal";
import { useHistory } from "react-router-dom";
import { useStoreActions, useStoreState } from "../../../hooks";

const { useState, useEffect, useRef, useMemo } = React;

const nqas = [
  {
    id: 1,
    label: "NQA1",
    satCount: 45,
  },
  {
    id: 2,
    label: "NQA2",
    satCount: 30,
  },
  {
    id: 3,
    label: "NQA3",
    satCount: 20,
  },
  {
    id: 4,
    label: "NQA4",
    satCount: 20,
  },
  {
    id: 5,
    label: "NQA5",
    satCount: 20,
  },
  {
    id: 6,
    label: "NQA6",
    satCount: 30,
  },
  {
    id: 7,
    label: "NQA7",
    satCount: 30,
  },
];

const wordLimit = 500;

const duration = 3000;

const getQType = (qtype) => {
  switch (qtype) {
    case "embedded":
      return 0;
    case "critical_reflections":
      return 1;
    case "partnerships_with_family":
      return 2;
  }
};

const NQASection = ({
  questions = [],
  refetchQuestions,
  lawsRegulations = {},
  fetchLawsRegulations,
}) => {
  const history = useHistory();

  const subNQAFilter = useRef();

  const [currentPage, setCurrentPage] = useState(1);
  const [currentFilter, setCurrentFilter] = useState(1);
  const [subCurrentFilter, setSubCurrentFilter] = useState("1.1.1");
  const [openSub, setOpenSub] = useState(false);
  const [satLoading, setSatLoading] = useState(false);
  const [selectedSat, setSelectedSat] = useState({});
  const [questionState, setQuestionState] = useState([]);
  const [enablePopover, setEnablePopover] = useState(false);
  const [removeLoading, setRemoveLoading] = useState(false);
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [transitTimer, setTransitTimer] = useState(2000);
  const [startTime, setStartTime] = useState(new Date());
  const [larShow, setLarShow] = useState(false);
  const areaRefs = useRef([]);
  const topDiv = useRef(null);
  const setSelectedNQA = useStoreActions(
    (actions) => actions.nqaStore.setSelectedNQA
  );
  const setLevel = useStoreActions(
    (actions) => actions.taskStore.setLevelInformation
  );

  const subNqaSprings = useSprings(
    subnqas.filter((subnqa) => subnqa.nqa === currentFilter)[0].sub.length,
    subnqas
      .filter((subnqa) => subnqa.nqa === currentFilter)[0]
      .sub.map((item, k) => {
        const length = subnqas.filter(
          (subnqa) => subnqa.nqa === currentFilter
        )[0].sub.length;

        return {
          display: openSub ? "flex" : "none",
          delay: openSub ? k * 30 : 0,
        };
      })
  );

  const updateQuestionsStates = () => {
    if (!isEmpty(questions)) {
      const filteredQuestions = questions.filter(
        (q) => q.label === subCurrentFilter
      );

      setQuestionState([
        ...filteredQuestions.map((q) => {
          if (
            !isEmpty(questionState) &&
            questionState.some((qs) => qs.questionId === q.question_id)
          ) {
            const qs = questionState.filter(
              (qs) => qs.questionId === q.question_id
            )[0];
            return {
              questionId: q.question_id,
              description: qs.description,
              descriptionLength: wordLimit - qs.description.length,
              textOverflow: qs.description.length > wordLimit,
              overFlow: qs.description.length - wordLimit,
            };
          } else {
            return {
              questionId: q.question_id,
              description: q.dataDescription,
              descriptionLength: wordLimit - q.dataDescription.length,
              textOverflow: q.dataDescription.length > wordLimit,
              overFlow: q.dataDescription.length - wordLimit,
            };
          }
        }),
      ]);
    }
  };

  const CharLimitDisplay = ({ question }) => {
    const overflow =
      !isEmpty(questionState) &&
      questionState.some((q) => q.questionId === question.question_id)
        ? questionState.filter((q) => q.questionId === question.question_id)[0]
            .textOverflow
          ? true
          : false
        : false;

    if (overflow) {
      return (
        <>
          <div className={`${styles.satCharCountOverflow} flex justify-start`}>
            <span className={`${styles.overLimitCount}`}>
              {`
                +${
                  questionState.filter(
                    (q) => q.questionId === question.question_id
                  )[0].overFlow
                }
              `}
            </span>{" "}
            <FaExclamationTriangle className={`${styles.iconLimit}`} />
          </div>
          <div className={styles.satCharTextOverflow}>over the limit</div>
        </>
      );
    } else {
      return (
        <>
          <div className={styles.satCharCount}>
            {!isEmpty(questionState) &&
            questionState.some((q) => q.questionId === question.question_id)
              ? questionState.filter(
                  (q) => q.questionId === question.question_id
                )[0].descriptionLength
              : wordLimit}
          </div>

          <div className={styles.satCharText}>characters left</div>
        </>
      );
    }
  };

  const handleOnChange = (value, question) => {
    if (
      !isEmpty(questionState) &&
      questionState.some((q) => q.questionId === question.question_id)
    ) {
      setQuestionState([
        ...questionState.map((q) => {
          if (q.questionId === question.question_id) {
            return {
              questionId: question.question_id,
              description: value,
              descriptionLength: wordLimit - value.length,
              textOverflow: value.length > wordLimit,
              overFlow: value.length - wordLimit,
            };
          } else {
            return q;
          }
        }),
      ]);
    } else {
      setQuestionState([
        ...questionState,
        {
          questionId: question.question_id,
          description: value,
          descriptionLength: wordLimit - value.length,
          textOverflow: value.length > wordLimit,
          overFlow: value.length - wordLimit,
        },
      ]);
    }

    onProcessData(value, question);
  };

  const onProcessData = useMemo(
    () => debounce((value, question) => processSatData(value, question), 2000),
    []
  );

  const processSatData = async (value, question) => {
    popUpBurst();
    if (question.hasData) {
      await onUpdateSatData(value, question);
    } else {
      await onCreateSatData(value, question);
    }

    await refetchQuestions();
  };

  const onRemoveSatData = async (question) => {
    const satId = !isEmpty(question.satData) ? question.satData.id : 0;

    try {
      setRemoveLoading(true);
      const response = await removeSatData(
        question.hasData,
        satId,
        question.question_id,
        question.center_id
      );
      await refetchQuestions();
      setRemoveLoading(false);
    } catch (error) {
      setRemoveLoading(false);
      console.log(error);
    }
  };

  const onCreateSatData = async (value, question) => {
    try {
      const response = await createSatData({
        centerId: question.center_id,
        questionId: question.question_id,
        label: question.label,
        description: question.description,
        satDescription: value,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const onUpdateSatData = async (value, question) => {
    try {
      const response = await updateSatData(question.satData.id, {
        centerId: question.center_id,
        questionId: question.question_id,
        label: question.label,
        description: question.description,
        satDescription: value,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const onCopyToClipboard = (question) => {
    const currentDescription = areaRefs.current[question.question_id];
    currentDescription.select();
    document.execCommand("copy");
  };

  const checkConfirmRemoveSat = async (question) => {
    if (question.hasData && question.hasDataDescription) {
      setShowRemoveModal(true);
    } else {
      await onRemoveSatData(question);
    }
  };

  const popUpBurst = () => {
    setEnablePopover(true);
    const timeout = setTimeout(() => {
      const date = new Date();
      const timeDiff = date.getTime() - startTime.getTime();
      if (timeDiff >= duration) {
        setEnablePopover(false);
      } else {
        setTransitTimer(timeDiff);
      }
    }, 1000);
  };

  const onGoToQuestion = (question) => {
    setLevel({
      navigate: true,
      nqaLevel: parseInt(subCurrentFilter.split(".")[1]) - 1,
      nqaSublevel: parseInt(subCurrentFilter.split(".")[2]) - 1,
      qtype: getQType(question.type),
      qId: question.question_id,
    });
    setSelectedNQA(currentFilter);
    history.push(`/qip/nqa/${currentFilter}`);
  };

  useEffect(() => {
    popUpBurst();
  }, [transitTimer]);

  useEffect(() => {
    return () => {
      onProcessData.cancel();
    };
  }, []);

  useEffect(() => {
    updateQuestionsStates();
  }, [subCurrentFilter, currentFilter, questions]);

  useEffect(() => {
    fetchLawsRegulations(currentFilter);
  }, [currentFilter]);

  return (
    <div className={`w-full  mt-14 pb-14 ${styles.mainContainer}`}>
      {larShow && !isEmpty(lawsRegulations) && (
        <LawsRegulationsModal
          lawsRegs={lawsRegulations}
          nqaId={currentFilter}
          visibility={larShow}
          onCloseModal={() => setLarShow(false)}
          refetchLar={() => fetchLawsRegulations(currentFilter)}
        />
      )}
      {showRemoveModal && (
        <ConfirmRemoveSatModal
          satQuestion={{ questionItem: {}, satData: selectedSat.satData }}
          visibility={showRemoveModal}
          onCloseModal={() => setShowRemoveModal(false)}
          onConfirm={async () => {
            await onRemoveSatData(selectedSat);
            setShowRemoveModal(false);
          }}
        />
      )}
      <div
        ref={subNQAFilter}
        className={`${styles.nqaContainer} md:px-8 lg:px-24`}
      >
        <text className={`${styles.headerNQAText}`}>
          Choose NQA areas to see questions
        </text>
        <div
          className={`${styles.resourceFilterRow} flex justify-between items-center mt-7`}
        >
          {nqas.map((nqa) => {
            return (
              <div
                key={nqa.id}
                className={`relative ${
                  currentFilter === nqa.id
                    ? styles.resourceFilterSelected
                    : styles.resourceFilter
                }  flex flex-col items-center justify-center`}
                onClick={async () => {
                  setSubCurrentFilter(`${nqa.id}.1.1`);
                  setCurrentPage(1);
                  setCurrentFilter(nqa.id);
                }}
              >
                <span>{nqa.label}</span>
                <span
                  style={{
                    color: currentFilter === nqa.id ? "#ffffff" : "#272828",
                  }}
                  className={`${styles.nqaSatCount}`}
                >{`${
                  questions.filter(
                    (q) => Number(q.label.split(".")[0]) === nqa.id
                  ).length
                }/${nqa.satCount} `}</span>
                {questions.filter(
                  (q) => Number(q.label.split(".")[0]) === nqa.id
                ).length !== nqa.satCount && (
                  <div
                    style={{
                      background:
                        currentFilter === nqa.id ? "#ffffff" : "#F56666",
                      color: currentFilter === nqa.id ? "#F56666" : "#ffffff",
                    }}
                    className={`${styles.satCheck} absolute top-0 right-0 mr-2 mt-2 flex justify-center items-center rounded-full `}
                  >
                    <FaExclamation />
                  </div>
                )}

                {questions.filter(
                  (q) => Number(q.label.split(".")[0]) === nqa.id
                ).length === nqa.satCount && (
                  <div
                    style={{
                      background:
                        currentFilter === nqa.id ? "#ffffff" : "#8BC334",
                      color: currentFilter === nqa.id ? "#8BC334" : "#ffffff",
                    }}
                    className={`${styles.satCheck} absolute top-0 right-0 mr-2 mt-2 flex justify-center items-center rounded-full`}
                  >
                    <FaCheck />
                  </div>
                )}
              </div>
            );
          })}
        </div>

        <div
          className={`relative ${`${styles.subResourcesFilterRow}`} ${
            styles[`nqa_${currentFilter}`]
          } flex flex-col pb-10`}
        >
          <div
            className={`relative ${styles.subResourcesFilterSelector} flex justify-center items-center`}
          >
            <span> {`NQA ${subCurrentFilter}`}</span>{" "}
            <div
              className={`flex justify-center items-center absolute right-0`}
            >
              <span>Change</span>
              <div
                onClick={() => {
                  setOpenSub(!openSub);
                }}
                className={`${styles.subDropdown} flex justify-center items-center overflow-hidden mx-2`}
              >
                {openSub ? <FaAngleUp /> : <FaAngleDown />}
              </div>
            </div>
          </div>

          {openSub &&
            subNqaSprings.map(({ ...props }, k) => (
              <animated.div
                key={k}
                style={{ ...props }}
                className={`${
                  subCurrentFilter ===
                  subnqas.filter((subnqa) => subnqa.nqa === currentFilter)[0]
                    .sub[k].label
                    ? styles.subResourcesFilterSelected
                    : styles.subResourcesFilter
                }  ${
                  subCurrentFilter ===
                  subnqas.filter((subnqa) => subnqa.nqa === currentFilter)[0]
                    .sub[k].id
                    ? "opacity-80"
                    : "opacity-1"
                }           items-center justify-center`}
                onClick={() => {
                  setSubCurrentFilter(
                    `${
                      subnqas.filter(
                        (subnqa) => subnqa.nqa === currentFilter
                      )[0].sub[k].id
                    }`
                  );

                  setOpenSub(false);
                }}
              >
                {
                  subnqas.filter((subnqa) => subnqa.nqa === currentFilter)[0]
                    .sub[k].label
                }
              </animated.div>
            ))}
        </div>
      </div>
      {!isEmpty(lawsRegulations) && (
        <div
          className={`${styles.larContainer} pt-5 pb-10 md:px-8 lg:px-24 flex flex-col items-start`}
        >
          <div className={`flex items-center justify-start `}>
            <div className={`${styles.larHeaderText}`}>
              {`Law and Regulations for NQA ${currentFilter}`}
            </div>{" "}
            {lawsRegulations.compliance !== 1 && (
              <div
                className={`${styles.lawStatusNotCompleted}  ml-3 flex justify-center items-center`}
              >
                Not completed
              </div>
            )}
            {lawsRegulations.compliance === 1 && (
              <div
                className={`${styles.lawStatusCompleted}  ml-3 flex justify-center items-center`}
              >
                Completed
              </div>
            )}
          </div>
          <div className={`${styles.larDescription} my-5`}>
            {`Fill out the necessary sections of the National Law (S) and National
            Regulations (R) underpinning Quality Area ${currentFilter}.`}
          </div>
          <button
            onClick={() => setLarShow(true)}
            className={`${styles.larButton}`}
          >
            {lawsRegulations.compliance !== 1
              ? `Complete Laws and Regulations`
              : `Edit Laws and Regulations`}
          </button>
        </div>
      )}

      <div className={`${styles.satQuestionsContainer} `}>
        {/* {isEmpty(
          questions.filter((question) => question.label === subCurrentFilter)
        ) ? (
          <>
            <div
              className={`${styles.wordLimitContainer} flex justify-center items-center pt-10 pb-10`}
            >
              <button
                onClick={() => {
                  setLevel({
                    navigate: true,
                    nqaLevel: parseInt(subCurrentFilter.split(".")[1]) - 1,
                    nqaSublevel: parseInt(subCurrentFilter.split(".")[2]) - 1,
                    qtype: getQType("embedded"),
                    qId: 1,
                  });

                  setSelectedNQA(currentFilter);
                  history.push(`/qip/nqa/${currentFilter}`);
                }}
                className={`${styles.saveSatButton} mr-2`}
              >
                {`Go to NQA${subCurrentFilter}`}
              </button>
              <button
                onClick={() => {
                  subNQAFilter.current.scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                  });
                }}
                className={`${styles.goReportButton} ml-2`}
              >
                Go back to top
              </button>
            </div>
            <div
              className={`${styles.noQuestionsAdded} flex flex-col items-center`}
            >
              <text className={`${styles.noQuestionsHeader} mt-14`}>
                No questions added to SAT
              </text>
              <text
                className={`${styles.noQuestionsText} mt-5 mb-10 md:px-8 lg:px-24`}
              >
                Use the shortcuts below to navigate to NQA areas and choose
                questions that you want to add to your SAT report.
              </text>
            </div>
            <div
              className={`${styles.resourceFilterRow} flex justify-center items-center pb-2.5 mt-7`}
            >
              {nqas.map((nqa) => {
                return (
                  <div
                    key={nqa.id}
                    className={`${styles.resourceFilter1} ${
                      nqa.id === currentFilter
                        ? styles[`resourceFilter1_nqa${currentFilter}`]
                        : ""
                    }  flex items-center justify-center`}
                    onClick={() => history.push(`/qip/nqa/${nqa.id}`)}
                  >
                    {nqa.label}
                  </div>
                );
              })}
            </div>
          </>
        ) : ( */}
        <>
          {" "}
          <div
            className={`${styles.nqaSatQuestionsHeader} flex flex-col items-center`}
          >
            {/* <text
                className={styles.nqaSubSatHeader}
              >{`NQA${subCurrentFilter}`}</text> */}
            <text className={`${styles.nqaSatSub} my-4 `}>
              {`${
                questions.filter((question) => {
                  return question.label === subCurrentFilter;
                }).length
              } out of 5 SAT questions selected for NQA${subCurrentFilter}`}
            </text>
            <text className={`${styles.nqaSatText} md:px-8 lg:px-24`}>
              Update, remove and edit chosen strengths. Or visit other NQA areas
              and choose questions which you want to add to your SAT report. All
              changes here will autosaved.
            </text>
            {/* <button className={`${styles.gotoReportButton} my-10`}>
                Go to report
              </button> */}
          </div>
          {questions
            .filter((question) => question.label === subCurrentFilter)
            .map((question, k) => {
              return (
                <>
                  <div
                    key={question.question_id}
                    className={`${styles.satQuestionContainer} md:px-8 lg:px-24 py-14`}
                  >
                    <div className={`flex items-start mb-12`}>
                      <Popover
                        visible={
                          enablePopover &&
                          selectedSat.question_id === question.question_id
                        }
                        placement={`topLeft`}
                        content={
                          <div
                            className={`${styles.changesSavedNotif} relative flex justify-center items-center`}
                          >
                            <FaCheck />
                            <span className={`ml-2`}>Changes saved!</span>
                            <div
                              className={`${styles.toolTipSavedNotif} absolute left-0`}
                            ></div>
                          </div>
                        }
                      >
                        <div>
                          <text
                            style={{
                              background:
                                !isEmpty(questionState) &&
                                questionState.some(
                                  (q) => q.questionId === question.question_id
                                )
                                  ? questionState.filter(
                                      (q) =>
                                        q.questionId === question.question_id
                                    )[0].textOverflow
                                    ? "#F56666"
                                    : "#388cef"
                                  : "#388cef",
                            }}
                            className={`${styles.satQuestionNumber} `}
                          >
                            {k + 1}
                          </text>
                        </div>
                      </Popover>
                      <div className={`${styles.satQuestionDesc} ml-4`}>
                        {`NQA ${question.label} ${question.description}`}
                      </div>
                    </div>
                    <textarea
                      style={{
                        color:
                          !isEmpty(questionState) &&
                          questionState.some(
                            (q) => q.questionId === question.question_id
                          )
                            ? questionState.filter(
                                (q) => q.questionId === question.question_id
                              )[0].textOverflow
                              ? "#F56666"
                              : "#272828"
                            : "#272828",
                      }}
                      ref={(el) => {
                        areaRefs.current[question.question_id] = el;
                      }}
                      value={
                        !isEmpty(questionState) &&
                        questionState.some(
                          (q) => q.questionId === question.question_id
                        )
                          ? questionState.filter(
                              (q) => q.questionId === question.question_id
                            )[0].description
                          : ""
                      }
                      onChange={(e) => {
                        setSelectedSat(question);
                        handleOnChange(e.target.value, question);
                      }}
                      className={`${styles.satQuestionDescription}`}
                    />
                    <div className={`flex justify-between items-center mt-4`}>
                      <div
                        className={`flex flex-col justiify-center items-start`}
                      >
                        <CharLimitDisplay question={question} />
                      </div>
                      <div className={`flex justify-end items-center`}>
                        <button
                          onClick={() => onCopyToClipboard(question)}
                          className={`${styles.satCopyButton} flex justify-center items-center`}
                        >
                          {" "}
                          <FaCopy />{" "}
                          <span className={`ml-3 `}>Copy to clipboard</span>
                        </button>
                        <button
                          onClick={() => {
                            onGoToQuestion(question);
                          }}
                          className={`${styles.satStrengthButton} mx-4 flex justify-center items-center`}
                        >
                          <FaEye />{" "}
                          <span className={`ml-3`}> Go to strength</span>
                        </button>
                        <button
                          disabled={removeLoading}
                          onClick={() => {
                            setSelectedSat(question);
                            checkConfirmRemoveSat(question);
                          }}
                          className={`${styles.satRemoveButton} flex justify-center items-center`}
                        >
                          {removeLoading &&
                          selectedSat.question_id === question.question_id ? (
                            <FaSpinner className={`animate-spin`} />
                          ) : (
                            <FaTimesCircle />
                          )}{" "}
                          <span className={`ml-3`}>Remove</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
          <div
            className={`${styles.wordLimitContainer} flex justify-center items-center pt-10 pb-10`}
          >
            <button
              onClick={() => {
                setLevel({
                  navigate: true,
                  nqaLevel: parseInt(subCurrentFilter.split(".")[1]) - 1,
                  nqaSublevel: parseInt(subCurrentFilter.split(".")[2]) - 1,
                  qtype: getQType("embedded"),
                  qId: 1,
                });

                setSelectedNQA(currentFilter);
                history.push(`/qip/nqa/${currentFilter}`);
              }}
              className={`${styles.saveSatButton} mr-2`}
            >
              {`Go to NQA${subCurrentFilter}`}
            </button>
            <button
              onClick={() => {
                subNQAFilter.current.scrollIntoView({
                  behavior: "smooth",
                  block: "center",
                });
              }}
              className={`${styles.goReportButton} ml-2`}
            >
              Go back to top
            </button>
          </div>
          <>
            {" "}
            <div
              className={`${styles.noQuestionsAdded} flex flex-col items-center`}
            >
              <text className={`${styles.noQuestionsHeader} mt-14`}>
                NQA Shortcuts
              </text>
              <text
                className={`${styles.noQuestionsText} mt-5 mb-10 md:px-8 lg:px-24`}
              >
                Use the shortcuts below to navigate to NQA areas and choose
                questions that you want to add to your SAT report.
              </text>
            </div>
            <div
              className={`${styles.resourceFilterRow} flex justify-center items-center pb-2.5 mt-7`}
            >
              {nqas.map((nqa) => {
                return (
                  <div
                    key={nqa.id}
                    className={`${styles.resourceFilter1} ${
                      nqa.id === currentFilter
                        ? styles[`resourceFilter1_nqa${currentFilter}`]
                        : ""
                    }  flex items-center justify-center`}
                    onClick={() => history.push(`/qip/nqa/${nqa.id}`)}
                  >
                    {nqa.label}
                  </div>
                );
              })}
            </div>
          </>
        </>
        {/* // )} */}
      </div>
    </div>
  );
};

export default NQASection;
