import * as React from "react";

import { useHistory } from "react-router-dom";
import { FaRegArrowAltCircleLeft, FaInfoCircle } from "react-icons/fa";

import styles from "./DowngradeSubscription.module.css";
import { Button, Icon } from "semantic-ui-react";
import BlueButton from "../../../components/BlueButton";
import DowngradeSubsriptionModal from "../../../components/Modals/DowngradeSubscriptionModal";
import { useStoreActions, useStoreState } from "../../../hooks";
import { changePlan } from "../../../api/requests";
import { FaSpinner } from "react-icons/fa";

const { useState } = React;

const benefitsList = [
  "Create & save QIP/SATs online",
  "Generate fast QIP/SAT reports",
  "Achieve NQA goals as a team",
  "Access our exclusive resources",
  "A&R preview & preparation",
  "New monthly resources",
  "Access monthly expert webinars",
  "Email and phone support",
];

const getPrice = (planSubscription = 1) => {
  if (planSubscription === 1) {
    if (process.env.REACT_APP_PRICEID === "TEST") {
      return "price_1HjdVfDeO3XG77wNweTXFOcB";
    }

    if (process.env.REACT_APP_PRICEID === "LIVE") {
      return "price_1IOWbPDeO3XG77wNEHMAsNwB";
    }
  }
  if (planSubscription === 2) {
    if (process.env.REACT_APP_PRICEID === "TEST") {
      return "price_1HjdVfDeO3XG77wNA9yXwT1m";
    }

    if (process.env.REACT_APP_PRICEID === "LIVE") {
      return "price_1IOWbPDeO3XG77wNBFPuWdQR";
    }
  }
};

function DowngradeSubscription() {
  const history = useHistory();

  const [tab, setTab] = useState(1);

  const userId = useStoreState((state) => state.authStore.authObject.userId);

  const selectedService = useStoreState(
    (state) => state.authStore.selectedService
  );

  const [loading, setLoading] = useState(false);

  const [
    downgradeSubsriptionModalVisibility,
    setdowngradeSubsriptionModalVisibility,
  ] = useState(false);

  const changeSub = async () => {
    setLoading(true);
    try {
      const response = await changePlan(
        userId,
        selectedService.id,
        getPrice(1)
      );
      setLoading(false);
      setdowngradeSubsriptionModalVisibility(true);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  return (
    <div className={styles.mainContainer}>
      <DowngradeSubsriptionModal
        visibility={downgradeSubsriptionModalVisibility}
        onOpenModal={() => setdowngradeSubsriptionModalVisibility(true)}
        onCloseModal={() => setdowngradeSubsriptionModalVisibility(false)}
      />

      <div className={styles.backButtonContainer}>
        <button
          className={styles.backButtonText}
          onClick={() => history.goBack()}
        >
          <FaRegArrowAltCircleLeft
            className={styles.backButton}
            color="#C4C4C4"
            size={24}
          />
        </button>
      </div>

      <div className={`${styles.content} flex`}>
        <div className={styles.leftCardColumn}>
          <div className={`${styles.leftCardColumnContent} flex flex-col`}>
            <Button
              className={`${styles.btnUpgrade} flex items-center justify-center`}
              icon="arrow alternate circle down outline"
            />

            <text className={`${styles.leftCardHeader} mt-7`}>
              Downgrade your subscription to the QIP Portal
            </text>

            <text className={`${styles.descriptionText} mt-5`}>
              By downgrading you will lose the ability to seek feedback &
              guidance from experienced mentors.
            </text>

            <text className={`${styles.descriptionText} flex mt-7`}>
              <FaInfoCircle className={`mr-3.5`} size={40} />
              You and your team will continue to have full access to all of your
              existing settings and content within the Holistic QIP app.
            </text>
          </div>
        </div>

        <div className={`${styles.rightCardColumn} flex flex-col`}>
          <div className={`flex`}>
            <div className={`${styles.cardTabSelected} p-5 w-3/6`}>
              Downgrade
            </div>
          </div>

          <div className={`${styles.subscriptionContent} flex flex-col`}>
            <div
              className={`${styles.subscriptionPriceRow} flex items-baseline`}
            >
              <text className={`mt-3.5 self-start`}>$</text>

              <text>97</text>

              <text>/month</text>
            </div>

            <text className={`${styles.subscriptionHeader} mt-4`}>
              QIP/SAT Portal
            </text>

            <text className={`${styles.descriptionText} mt-4 mb-3`}>
              The Portal without guidance & feedback from our mentors.
            </text>

            {Array.from(benefitsList).map((benefit) => (
              <div
                className={`${styles.subscriptionBenefitEntry} flex mt-4`}
                key={benefit}
              >
                <Icon className={styles.checkCircle} name="check circle" />
                <text>{benefit}</text>
              </div>
            ))}

            <BlueButton
              className={`${styles.blueButton} mt-10`}
              onClick={changeSub}
              // onClick={setdowngradeSubsriptionModalVisibility}

              buttonText={
                loading ? (
                  <div className={`flex justify-center items-center`}>
                    <FaSpinner className={`animate-spin`} />
                    <span className={`ml-2`}>Please wait... </span>
                  </div>
                ) : (
                  `Downgrade now`
                )
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default DowngradeSubscription;
