import * as React from "react";

import useSWR from "swr";
import PropTypes from "prop-types";
import { Button, Dropdown, Icon, Modal } from "semantic-ui-react";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";

import styles from "./ManageTaskModal.module.css";
import { FaSpinner } from "react-icons/fa";
import { useStoreState, useStoreActions } from "../../../hooks";
import BlueButton from "../../BlueButton";
import InputComponent from "../../InputComponent";
import ChangeStatusModal from "../ChangeStatusModal";
import DeleteTaskModal from "../DeleteTaskModal";
import {
  fetchCenterUsers,
  updateTask,
  deleteTask,
} from "../../../api/requests";

const { useCallback, useReducer, useState, useEffect } = React;

const updateReducer = (state, action) => {
  switch (action.type) {
    case "UPDATE": {
      const updatedTask = {
        ...state.task,
        [action.field]: action.value,
      };

      let updatedValidation = {
        ...state.validation,
      };

      if (action.valid !== undefined) {
        updatedValidation = {
          ...state.validation,
          [action.field]: action.valid,
        };
      }

      const formValidity = Object.values(updatedValidation).every(Boolean);

      return {
        task: updatedTask,
        validation: updatedValidation,
        formValidation: formValidity,
      };
    }
    default:
      return state;
  }
};

const getQType = (qtype) => {
  switch (qtype) {
    case "embedded":
      return 0;
    case "critical_reflections":
      return 1;
    case "partnerships_with_family":
      return 2;
  }
};

function ManageTaskModal({ visibility, onOpenModal, onCloseModal, centerId }) {
  const task = useStoreState((state) => state.taskStore.selectedTask);
  const history = useHistory();
  const match = useRouteMatch();

  const [showdeleteTaskModal, setShowDeleteTaskModal] = useState(false);

  const taskInfo = useStoreState((state) => state.taskStore.TaskInfo);

  const initialState = {
    task: { ...task },
    validation: {
      name: true,
      taskDue: true,
      summary: true,
    },
    formValidation: true,
  };

  const [updateLoading, setLoading] = useState(false);
  const [statusModalVis, setStatusModalVis] = useState(false);

  const [formState, formDispatch] = useReducer(updateReducer, initialState);

  const setSelectedNQA = useStoreActions(
    (actions) => actions.nqaStore.setSelectedNQA
  );

  const setLevel = useStoreActions(
    (actions) => actions.taskStore.setLevelInformation
  );

  const changeInputDispatch = useCallback((field, value) => {
    let valid = false;

    if (field === "name" || field === "summary") {
      if (value) valid = true;
      const action = {
        type: "UPDATE",
        field,
        value,
        valid,
      };

      formDispatch(action);
    } else if (field === "taskDue") {
      const newDate = new Date(value);
      const dateNow = new Date();

      if (newDate >= dateNow) valid = true;
      const action = {
        type: "UPDATE",
        field,
        value,
        valid,
      };

      formDispatch(action);
    } else {
      const action = {
        type: "UPDATE",
        field,
        value,
        valid: undefined,
      };

      formDispatch(action);
    }
  }, []);

  const checkStatusStyle = (status) => {
    if (status === 1) return styles.statusSelect1;
    else if (status === 2) return styles.statusSelect2;

    return styles.statusSelect3;
  };

  const checkStatus = (status) => {
    if (status === 1) return "Not started";
    else if (status === 2) return "In progress";

    return "Completed";
  };

  const onUpdateTask = async () => {
    setLoading(true);

    try {
      const response = await updateTask(formState.task);

      if (response) {
        setLoading(false);
        onCloseModal();
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const { data: users } = useSWR([`/users`, centerId], (_, centerId) =>
    fetchCenterUsers(centerId)
  );

  const onGotoQuestion = (nqa) => {
    setLevel({
      navigate: true,
      nqaLevel: taskInfo.nqaLevel,
      nqaSublevel: taskInfo.nqaSubLevel,
      qtype: getQType(taskInfo.questionType),
      qId: task.questionId,
    });

    setSelectedNQA(nqa);

    history.push(`/qip/nqa/${nqa}`);
  };

  const onRemoveTask = async () => {
    try {
      const response = await deleteTask(task.id);

      if (response) {
        setShowDeleteTaskModal(false);
        onCloseModal();
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Modal
      size="large"
      centered={true}
      open={visibility}
      onOpen={onOpenModal}
      onClose={onCloseModal}
    >
      {showdeleteTaskModal && (
        <DeleteTaskModal
          visibility={showdeleteTaskModal}
          onCloseModal={() => {
            setShowDeleteTaskModal(false);
          }}
          onRemoveTask={onRemoveTask}
        />
      )}
      {!users ? (
        <div
          className={`w-full h-screen flex flex-col justify-center items-center`}
        >
          <FaSpinner
            className={`animate-spin text-qipGreen font-bold text-6xl`}
          />
          <span className={`block`}> Loading data...</span>
        </div>
      ) : updateLoading ? (
        <div
          className={`w-full h-screen flex flex-col justify-center items-center`}
        >
          <FaSpinner
            className={`animate-spin text-qipGreen font-bold text-6xl`}
          />
          <span className={`block`}> Loading data...</span>
        </div>
      ) : (
        <div className={styles.modalContainer}>
          <Button
            className={styles.closeButton}
            onClick={onCloseModal}
            size="big"
            icon="close"
          />

          {statusModalVis && (
            <ChangeStatusModal
              visibility={statusModalVis}
              onSelectStatus={changeInputDispatch}
              onClearStatus={() => changeInputDispatch("status", task.status)}
              onOpenModal={() => setStatusModalVis(true)}
              onCloseModal={() => setStatusModalVis(false)}
            />
          )}

          <text className={styles.headerText}>
            {taskInfo && task.type === "evidence"
              ? ` Manage this evidence task`
              : ` Manage this goal task`}
          </text>

          <div className={styles.nqaArea}>
            <div className={styles.nqaAreaRow}>
              <text
                className={`${styles.nqaText} ${styles[`nqa${task.nqaId}`]}`}
              >
                {taskInfo ? `NQA ${taskInfo.nqaLabel}` : ``} <br />
                <span className={`capitalize`}>
                  {" "}
                  {taskInfo && taskInfo.questionType.split("_").join(`\xa0`)}
                </span>
              </text>
              <text className={styles.nqaAnswer}>
                <strong>
                  {taskInfo && task.type === "evidence"
                    ? `Required Evidence: `
                    : taskInfo && `QIP Goal: `}
                </strong>
                {taskInfo && taskInfo.desription}
                {/* The service is to provide a program
                from birth to school transition. I’ve added more text here just
                to see how it will look with multiple text lines and how the
                texty should break. */}
              </text>
            </div>
          </div>

          <InputComponent
            type="text"
            fieldName="name"
            label="Task name"
            inputValue={formState.task.name}
            setInputValue={changeInputDispatch}
            className={styles.inputText}
          />

          <div className={styles.dropdownRow}>
            <div className={styles.dropdownGroup}>
              <text className={styles.dropdownText}>Task for</text>

              <Dropdown
                className={styles.dropdownBox}
                fluid
                selection
                scrolling
                placeholder="Choose"
                options={users.map((user) => ({
                  text: user.name,
                  value: user.id,
                }))}
                defaultValue={
                  users.filter(
                    (user) => Number(user.id) === Number(formState.task.asignee)
                  )[0].id
                }
                onChange={(e, data) =>
                  changeInputDispatch("asignee", data.value)
                }
              />
            </div>

            <div className={styles.dropdownGroup}>
              <text className={styles.dropdownText}>To be completed by</text>

              <div className={styles.datePickerGroup}>
                <input
                  className={styles.datePickerBox}
                  type="date"
                  value={
                    formState.task.taskDue
                      ? formState.task.taskDue.split(" ")[0]
                      : ""
                  }
                  onChange={(e) =>
                    changeInputDispatch("taskDue", e.target.value)
                  }
                />

                {/* <Icon
                    className={styles.datePickerIcon}
                    name="caret down"
                  /> */}
              </div>
            </div>
          </div>

          <InputComponent
            className={styles.summaryTextBox}
            label="Task summary"
            fieldName="summary"
            textArea
            inputValue={formState.task.summary}
            setInputValue={changeInputDispatch}
          />

          <div className={styles.dropdownGroup}>
            <text className={styles.dropdownText}>Change task status</text>

            <div
              className={checkStatusStyle(formState.task.status)}
              onClick={() => setStatusModalVis(true)}
            >
              <text className={styles.dropdownText}>
                {checkStatus(formState.task.status)}
              </text>

              <Icon name="caret down" />
            </div>
          </div>

          <div className={styles.actionButtonGroup}>
            <BlueButton
              className={styles.blueButton}
              buttonText="Update task"
              onClick={onUpdateTask}
              disabled={!formState.formValidation}
            />

            <button
              className={styles.questionButton}
              onClick={() => onGotoQuestion(Number(task.nqaId.split(".")[0]))}
            >
              Go to question
            </button>
          </div>

          <div className={styles.removeTask}>
            <button
              className={styles.removeTaskButton}
              onClick={() => setShowDeleteTaskModal(true)}
            >
              Remove task
            </button>
          </div>
        </div>
      )}
    </Modal>
  );
}

ManageTaskModal.propTypes = {
  centerId: PropTypes.number.isRequired,
  visibility: PropTypes.bool.isRequired,
  onOpenModal: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired,
};

export default ManageTaskModal;
