import * as React from "react";

// import { useHistory } from 'react-router-dom';

import styles from "./Footer.module.css";
import LogoutModal from "../Modals/LogoutModal";
import { useStoreState } from "../../hooks";

const { useState } = React;

export default function Footer() {
  // const history = useHistory();
  const isLoggedIn = useStoreState((state) => state.authStore.isLoggedIn);

  const [logoutModalVisibility, setLogoutModalVisibility] = useState(false);

  return (
    <div className={styles.footer}>
      {logoutModalVisibility && (
        <LogoutModal
          visibility={logoutModalVisibility}
          onOpenModal={() => setLogoutModalVisibility(true)}
          onCloseModal={() => setLogoutModalVisibility(false)}
        />
      )}
      <div className={styles.row}>
        <div className={styles.left}>
          <a
            href="https://holisticqip.com.au/privacy-policy/"
            className={styles.footerNav}
            target="_blank"
            rel="noreferrer"
          >
            Privacy policy
          </a>
          <span className={`${styles.left} text-white font-semibold text-xl`}>
            |
          </span>
          <a
            href="https://holisticqip.com.au/terms-of-service/"
            className={styles.footerNav}
            target="_blank"
            rel="noreferrer"
          >
            {`Terms & Conditions`}
          </a>
          {/*{isLoggedIn
            ? (
              <button
                className={styles.footerNav}
                onClick={() => history.push('/qip/user-management/subscription/upgrade-subscription')}
              >
                Uprade plan
              </button>
            )
            : <div className={styles.headerCenterEmpty} />
          }*/}
        </div>
        <div className={styles.right}>
          <text className={styles.copyright}>
            ©{new Date().getFullYear()} - Holistic Management & Consultancy |
            All rights reserved
          </text>
          {isLoggedIn ? (
            <button
              className={styles.buttonLogout}
              onClick={() => setLogoutModalVisibility(true)}
            >
              Log out
            </button>
          ) : (
            <div className={styles.headerCenterEmpty} />
          )}
        </div>
      </div>
    </div>
  );
}
