import * as React from 'react';

import PropTypes from 'prop-types';
import { Modal } from 'semantic-ui-react';

import styles from './UserRemovedModal.module.css';
import BlueButton from '../../BlueButton';

function UserRemovedModal({ visibility, onOpenModal, onCloseModal }) {

  return (
    <Modal
      size="small" 
      centered={false}
      open={visibility}
      onOpen={onOpenModal}
      onClose={onCloseModal}
    >
      <div className={styles.modalContainer}>

        <text className={styles.headerText}>
          This user is now removed.
        </text>

        <BlueButton
          buttonText="Ok"
          onClick={onCloseModal}
        />

      </div>

    </Modal>
  );
}

UserRemovedModal.propTypes = {
  visibility: PropTypes.bool.isRequired,
  onOpenModal: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired,
};

export default UserRemovedModal;
