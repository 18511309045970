import * as React from "react";

import useSWR, { mutate } from "swr";
import { Button, Label, Dropdown, Input } from "semantic-ui-react";

import styles from "./ReportsDashboard.module.css";

import QIPReportView from "../QIPReportView";
import TasksReportView from "../TasksReportView";
import SatReportView from "../SatReportView";
import ReportRecord from "../../../components/ReportRecord";
import SendEmailModal from "../../../components/Modals/SendEmailModal";
import SubmissionErrorModal from "../../../components/Modals/SubmissionErrorModal";
import ResourceDownloadingModal from "../../../components/Modals/ResourceDownloadingModal";
import { useStoreState } from "../../../hooks";
import { fetchCenterReports } from "../../../api/requests";
import Pagination from "../../../components/Pagination/Pagination";
import { isEmpty } from "lodash";
import { FaSpinner } from "react-icons/fa";

const { useState } = React;

const reportsPerPage = 5;

function ReportsDashboard({ location }) {
  const userId = useStoreState((state) => state.authStore.authObject.userId);
  const centerId = useStoreState((state) => state.nqaStore.serviceDetails.id);

  const [selectedOption, setOption] = useState(
    !isEmpty(location)
      ? !isEmpty(location.state)
        ? location.state.selectionOption
        : 1
      : 1
  );

  const [downloadAttachmentModalVis, setDownloadAttachmentModalVis] =
    useState(false);
  const [submissionErrorModalVisibility, setSubmissionErrorModalVisibility] =
    useState(false);
  const [sendEmailModalVisibility, setEmailModalVisibility] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);

  const [reportFilter, setReportFilter] = useState("All");

  const onGenerateReport = (flag) => {
    if (flag) setSubmissionErrorModalVisibility(true);
  };

  const DisplayTabChoice = () => {
    if (selectedOption === 1) {
      return (
        <QIPReportView
          onGenerateReport={onGenerateReport}
          onGenerateFinish={async () =>
            await mutate(["/reports", centerId, userId])
          }
        />
      );
    }

    if (selectedOption === 2) {
      return <SatReportView />;
    }

    // Array.from(new Set(response.data.map((user: any) => user.id))).map(
    //   (id) => {
    //     return response.data.find((user: any) => user.id === id);
    //   }
    if (selectedOption === 3) {
      return (
        <TasksReportView
          onGenerateFinish={async () =>
            await mutate(["/reports", centerId, userId])
          }
          usersLists={
            reports
              ? Array.from(
                  new Set(
                    reports
                      .filter((report) => {
                        return !(
                          report.type !== "Evidence Tasks Report" &&
                          report.type !== "Goal Tasks Report" &&
                          report.type !== "All Tasks Report"
                        );
                      })
                      .map((report) => report.createdBy)
                      .map((createdBy) => {
                        return reports
                          .filter((report) => {
                            return !(
                              report.type !== "Evidence Tasks Report" &&
                              report.type !== "Goal Tasks Report" &&
                              report.type !== "All Tasks Report"
                            );
                          })
                          .find((report) => report.createdBy === createdBy);
                      })
                  )
                ).map((report) => {
                  return {
                    id: report.id,
                    value: report.createdBy,
                    text: report.createdBy,
                  };
                })
              : []
          }
          setTaskReportFilter={setReportFilter}
          filtered={reportFilter}
        />
      );
    }
  };

  const { data: reports } = useSWR(
    ["/reports", centerId, userId],
    (_, centerId, userId) => fetchCenterReports(centerId, userId)
  );

  return (
    <div className={styles.mainContainer}>
      {downloadAttachmentModalVis && (
        <ResourceDownloadingModal
          visibility={downloadAttachmentModalVis}
          onOpenModal={() => setDownloadAttachmentModalVis(true)}
          onCloseModal={() => setDownloadAttachmentModalVis(false)}
        />
      )}

      {submissionErrorModalVisibility && (
        <SubmissionErrorModal
          visibility={submissionErrorModalVisibility}
          onOpenModal={() => setSubmissionErrorModalVisibility(true)}
          onCloseModal={() => setSubmissionErrorModalVisibility(false)}
        />
      )}

      {sendEmailModalVisibility && (
        <SendEmailModal
          centered={false}
          visibility={sendEmailModalVisibility}
          onOpenModal={() => setEmailModalVisibility(true)}
          onCloseModal={() => setEmailModalVisibility(false)}
        />
      )}

      <div className={styles.content}>
        <h1 className={styles.headerText}>Reports dashboard</h1>

        <text className={styles.headerSubText}>
          Generate shareable reports quickly and easily.
        </text>

        <div className={styles.cardContainer}>
          <div className={styles.navigationOptionsRow}>
            <div
              className={
                selectedOption === 1
                  ? styles.navigationOptionSelected
                  : styles.navigationOption
              }
              onClick={() => {
                // if (selectedOption === 2) {
                //   setCurrentPage(1);
                // }
                setOption(1);
              }}
            >
              Goal/Strength Reports
            </div>

            <div
              className={
                selectedOption === 2
                  ? styles.navigationOptionSelected
                  : styles.navigationOption
              }
              onClick={() => setOption(2)}
            >
              {/* <Label pointing="below">Coming soon</Label> */}
              SAT Report
            </div>

            <div
              className={
                selectedOption === 3
                  ? styles.navigationOptionSelected
                  : styles.navigationOption
              }
              onClick={() => setOption(3)}
            >
              Task Reports
            </div>
          </div>
        </div>
      </div>

      <div className={styles.cardContent}>
        <DisplayTabChoice />

        <div className={styles.filterContainer}>
          <Input
            className={styles.searchInput}
            icon="search"
            placeholder="Type name"
          />

          <Dropdown
            className={styles.filterDropdown}
            inline
            header="Filter"
            placeholder="NQA Area"
          />

          <Dropdown
            className={styles.filterDropdown}
            inline
            header="Filter"
            placeholder="A-Z"
          />

          <Dropdown
            className={styles.filterDropdown}
            inline
            header="Filter"
            placeholder="Date"
          />

          <Dropdown
            className={styles.filterDropdown}
            inline
            header="Filter"
            placeholder="Type"
          />
        </div>
        {!reports ? (
          <div
            className={`w-full h-full flex flex-col justify-center items-center`}
          >
            <FaSpinner
              className={`animate-spin text-qipGreen font-bold text-6xl`}
            />

            <span className={`block`}>Loading reports...</span>
          </div>
        ) : (
          reports
            .filter((report) => {
              if (selectedOption === 1) {
                return (
                  report.type !== "Evidence Tasks Report" &&
                  report.type !== "Goal Tasks Report" &&
                  report.type !== "All Tasks Report"
                );
              }

              if (selectedOption === 3) {
                if (reportFilter === "All") {
                  return !(
                    report.type !== "Evidence Tasks Report" &&
                    report.type !== "Goal Tasks Report" &&
                    report.type !== "All Tasks Report"
                  );
                } else {
                  return (
                    !(
                      report.type !== "Evidence Tasks Report" &&
                      report.type !== "Goal Tasks Report" &&
                      report.type !== "All Tasks Report"
                    ) && report.createdBy === reportFilter
                  );
                }
              }
            })
            .slice(
              currentPage * reportsPerPage - reportsPerPage,
              currentPage * reportsPerPage
            )
            .map((report) => (
              <ReportRecord
                key={report.id}
                reportData={report}
                onEmail={() => setEmailModalVisibility(true)}
                onRemove={async () =>
                  await mutate(["/reports", centerId, userId])
                }
                onDownload={() => setDownloadAttachmentModalVis(true)}
              />
            ))
        )}
        {console.log("reports, , ", reports)}
        {reports &&
          reportsPerPage <
            reports.filter((report) => {
              if (selectedOption === 1) {
                return (
                  report.type !== "Evidence Tasks Report" &&
                  report.type !== "Goal Tasks Report" &&
                  report.type !== "All Tasks Report"
                );
              }

              if (selectedOption === 3) {
                return !(
                  report.type !== "Evidence Tasks Report" &&
                  report.type !== "Goal Tasks Report" &&
                  report.type !== "All Tasks Report"
                );
              }
            }).length && (
            <Pagination
              className={`py-20 bg-white`}
              lastPage={Math.ceil(
                reports.filter((report) => {
                  if (selectedOption === 1) {
                    return (
                      report.type !== "Evidence Tasks Report" &&
                      report.type !== "Goal Tasks Report" &&
                      report.type !== "All Tasks Report"
                    );
                  }
                  if (selectedOption === 3) {
                    if (reportFilter === "All") {
                      return !(
                        report.type !== "Evidence Tasks Report" &&
                        report.type !== "Goal Tasks Report" &&
                        report.type !== "All Tasks Report"
                      );
                    } else {
                      return (
                        !(
                          report.type !== "Evidence Tasks Report" &&
                          report.type !== "Goal Tasks Report" &&
                          report.type !== "All Tasks Report"
                        ) && report.createdBy === reportFilter
                      );
                    }
                  }
                }).length / reportsPerPage
              )}
              onPageChange={setCurrentPage}
              currentPage={currentPage}
            />
          )}
      </div>
    </div>
  );
}

export default ReportsDashboard;
