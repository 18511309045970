import * as React from "react";

import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";

import Billing from "../Billing";
import Services from "../Services";
import Subscription from "../Subscription";
import UsersList from "../../pages/UserManagement/UsersList/";
import UserUpdate from "../../pages/UserManagement/UserUpdate";
import UserCreation from "../../pages/UserManagement/UserCreation";
import UserDashboard from "../../pages/UserManagement/UserDashboard";
import { useStoreState, useStoreActions } from "../../hooks";

export default function UserManagement() {
  const match = useRouteMatch();
  const selectedUser = useStoreState((state) => state.userStore.selectedUser);
  const serviceDetails = useStoreState(
    (state) => state.nqaStore.serviceDetails
  );

  const remainingTrial = useStoreState(
    (state) => state.userStore.remainingTrial
  );

  const setDisableNavBar = useStoreActions(
    (actions) => actions.appStore.setDisableMenu
  );

  const userSelectedService = useStoreState(
    (state) => state.authStore.selectedService
  );

  const isAdmin = useStoreState((state) => state.authStore.isAdmin);

  if (userSelectedService && userSelectedService.redirectPaymentFailed) {
    setDisableNavBar(true);
    return <Redirect to={`/qip/update-payment-method`} />;
  }

  const subExpired = remainingTrial <= 0 ? true : false;

  if (subExpired) {
    return <Redirect to={`/qip/pricing-plans`} />;
  }

  return (
    <Switch>
      <Route
        exact
        path={`${match.path}`}
        render={() =>
          serviceDetails ? (
            isAdmin ? (
              <UserDashboard />
            ) : (
              <Redirect to={`${match.path}/update-user`} />
            )
          ) : (
            <Redirect to="/nqa" />
          )
        }
      />
      <Route
        exact
        path={`${match.path}/user-list`}
        render={() =>
          serviceDetails ? (
            isAdmin ? (
              <UsersList />
            ) : (
              <Redirect to={`${match.path}/update-user`} />
            )
          ) : (
            <Redirect to="/nqa" />
          )
        }
      />
      <Route
        exact
        path={`${match.path}/create-user`}
        render={() =>
          serviceDetails ? (
            isAdmin ? (
              <UserCreation />
            ) : (
              <Redirect to={`${match.path}/update-user`} />
            )
          ) : (
            <Redirect to="/nqa" />
          )
        }
      />
      <Route
        exact
        path={`${match.path}/update-user`}
        render={() =>
          selectedUser ? <UserUpdate /> : <Redirect to={`${match.path}`} />
        }
      />
      <Route path={`${match.path}/services`} component={Services} />
      <Route path={`${match.path}/subscription`} component={Subscription} />
      <Route path={`${match.path}/billing`} component={Billing} />
    </Switch>
  );
}
