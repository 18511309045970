import * as React from "react";

import PropTypes from "prop-types";
import { Modal, Button, Icon } from "semantic-ui-react";
import InputComponent from "../../InputComponent";
import styles from "./AddCustomGoalsEvidencesModal.module.css";
import { FaSpinner } from "react-icons/fa";
import { addCustomQuestion } from "../../../api/requests";

const { useState } = React;

function AddCustomGoalsEvidencesModal({
  customPayload,
  visibility,
  onOpenModal,
  onCloseModal,
  onMutation,
}) {
  const [currentTab, setCurrentTab] = useState(2);
  const [currentHeaderText, setCurrentHeaderText] = useState(1);
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(false);

  const createCustomQuestion = async () => {
    setLoading(true);

    try {
      const response = await addCustomQuestion({
        ...customPayload,
        ...{ description },
      });

      if (response) {
        await onMutation(currentTab).then(() => {
          onCloseModal();
          setLoading(false);
        });
      }
    } catch (error) {
      onCloseModal();
      setLoading(false);
      // console.log(error);
    }
  };

  return (
    <Modal
      size="small"
      centered
      open={visibility}
      onOpen={onOpenModal}
      onClose={onCloseModal}
    >
      <div className={`flex flex-col px-14 pt-14 pb-16`}>
        <Button
          className={styles.closeButton}
          onClick={onCloseModal}
          size="big"
          icon="close"
        />

        <text className={`${styles.headerText} block mb-2.5`}>
          Add your own goal or strength
        </text>
        <text className={`${styles.subText} block mb-11`}>
          Add a custom goal or strength for NQA {customPayload.label}{" "}
          {customPayload.name}.
        </text>

        <div className={`${styles.tabContainer} flex mb-9`}>
          <div
            className={`${
              currentTab === 2 ? styles.selectedTab1 : styles.tab
            } flex items-center justify-center`}
            onClick={() => setCurrentTab(2)}
          >
            Goal
          </div>

          <div
            className={`${
              currentTab === 1 ? styles.selectedTab : styles.tab
            } flex items-center justify-center`}
            onClick={() => setCurrentTab(1)}
          >
            Strength
          </div>
        </div>

        <div className={`${styles.tabBodyContainer}`}>
          {currentTab === 2 ? (
            <>
              {/* goal */}
              <InputComponent
                textArea
                fieldName="noDescription"
                setInputValue={(_, value) => setDescription(value)}
                label="Provide a short description for your custom element."
              />

              <div className={`text-center mt-7`}>
                <button
                  disabled={loading}
                  className={`${styles.saveButton}`}
                  onClick={createCustomQuestion}
                >
                  {loading ? (
                    <div className={`flex justify-center items-center`}>
                      {" "}
                      <FaSpinner className={`animate-spin`} />{" "}
                      <span className={`ml-2`}> Please wait... </span>
                    </div>
                  ) : (
                    <> Save </>
                  )}
                </button>
              </div>
            </>
          ) : (
            <>
              {/* evidence */}
              <InputComponent
                textArea
                fieldName="yesDescription"
                setInputValue={(_, value) => setDescription(value)}
                label="Provide a short description for your custom element."
              />

              <div className={`text-center mt-7`}>
                <button
                  disabled={loading}
                  className={`${styles.saveButton}`}
                  onClick={createCustomQuestion}
                >
                  {loading ? (
                    <div className={`flex justify-center items-center`}>
                      {" "}
                      <FaSpinner className={`animate-spin`} />{" "}
                      <span className={`ml-2`}> Please wait... </span>
                    </div>
                  ) : (
                    <> Save </>
                  )}
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </Modal>
  );
}

AddCustomGoalsEvidencesModal.propTypes = {
  visibility: PropTypes.bool.isRequired,
  onOpenModal: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired,
};

export default AddCustomGoalsEvidencesModal;
