// commented most of this component in case custom form/integration for stripe will be used in place of out of the box stripe component
import * as React from "react";

import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
// import { Dimmer, Loader } from 'semantic-ui-react';

import {
  FaSpinner,
  FaTimesCircle,
  FaTimes,
  FaCcAmex,
  FaCcVisa,
  FaCcJcb,
  FaCcMastercard,
  FaRegArrowAltCircleLeft,
} from "react-icons/fa";

import styles from "./PaymentForm.module.css";
// import InputComponent from '../../../components/InputComponent';
import CardForm from "../../../components/Stripe";
import SkipButton from "../../../components/SkipButton";
import { useStoreActions, useStoreState } from "../../../hooks";
const { useState, useEffect, useCallback } = React;

// const { useReducer, useState } = React;

// const paymentReducer = (state, action) => {
//   switch (action.type) {
//     case 'UPDATE': {
//       const updatedInput = {
//         ...state.input,
//         [action.field]: action.value,
//       };

//       const updatedValidation = {
//         ...state.validation,
//         [action.field]: action.valid
//       };

//       const formValidity = Object.values(updatedValidation).every(Boolean);

//       return {
//         input: updatedInput,
//         validation: updatedValidation,
//         formValidation: formValidity
//       };
//     }
//     default:
//       return state;
//   }
// };

function PaymentForm({ plan, planVariant, stripeState }) {
  // planChoice and planSubscription to be used to determine plan information
  const history = useHistory();

  //  const userId = useStoreState((state) => state.authStore.authObject.userId);

  const user = useStoreState((state) => state.userStore.loggedInUser);

  // const services = useStoreState((state) => state.authStore.services);

  const firstSignUp = useStoreState((state) => state.appStore.firstSignUp);

  const remainingTrial = useStoreState(
    (state) => state.userStore.remainingTrial
  );

  const subExpired = remainingTrial <= 0 ? true : false;

  const logout = useStoreActions((actions) => actions.authStore.logout);
  const clearNQASession = useStoreActions(
    (actions) => actions.nqaStore.clearSession
  );
  const clearUserSession = useStoreActions(
    (actions) => actions.userStore.clearSession
  );

  const fetchUserDetails = useStoreActions(
    (actions) => actions.userStore.fetchUserDetails
  );

  // const getUserDetails = useCallback(async () => {
  //   try {
  //     await fetchUserDetails({
  //       centerId: services[0].id,
  //       userId,
  //     });
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }, []);

  const onLogout = async () => {
    await logout();
    clearNQASession();
    clearUserSession();
  };

  const onLogoutConfirm = async () => {
    await onLogout();
  };

  useEffect(() => {
    // getUserDetails();
  }, []);
  // const initialState = {
  //   input: {
  //     creditCardNumber: '',
  //     creditCardName: '',
  //     cvcNumber: '',
  //     creditCardExpiry: '',
  //   },
  //   validation: {
  //     creditCardNumber: false,
  //     creditCardName: false,
  //     cvcNumber: false,
  //     creditCardExpiry: false,
  //   },
  //   formValidation: false,
  // };

  // Used if stripe integration calls a function to submit payment details, else show stripe component/modal
  // const [paymentLoading, setPaymentLoading] = useState(false);

  // const [paymentDetailState, dispatch] = useReducer(paymentReducer, initialState);

  // const changeInputDispatch = (field, value) => {
  //   let valid = false;

  //   if (value) valid = true;
  //   const action = {
  //     type: 'UPDATE',
  //     field,
  //     value,
  //     valid
  //   };

  //   dispatch(action);
  // };

  // const onSubmitPaymentDetails = async () => {
  //   if (!paymentDetailState.formValidation) {
  //     alert('Please fill up all fields.');

  //     return;
  //   }

  //   // sample payload
  //   const payload = {
  //     ...paymentDetailState.input,
  //     planChoice, // qip portal or qip mentoring
  //     planSubscription, // monthly or yearly
  //   };

  //   setPaymentLoading(true);
  //   // const response = await stripeSubmit(payload);
  //   setPaymentLoading(false);

  //   history.push('/qip/auth/legal-notice');
  // };

  const [askLogout, setAskLogout] = useState(false);
  let infoText = <> </>;

  if (plan == "portal") {
    if (planVariant == "monthly") {
      infoText = (
        <>
          Complete your <span className={`font-medium`}>$97/month{` `}</span>
          subscription to a{` `}
          <span className={`font-medium`}>
            Holistic QIP Portal monthly plan.
          </span>
        </>
      );
    } else {
      infoText = (
        <>
          Complete your <span className={`font-medium`}>$997/year{` `}</span>
          subscription to a{` `}
          <span className={`font-medium`}>Holistic QIP Portal yearly plan</span>
          .
        </>
      );
    }
  }

  if (plan == "mentoring") {
    if (planVariant == "monthly") {
      infoText = (
        <>
          Complete your <span className={`font-medium`}>$197/month{` `}</span>
          subscription to a{" "}
          <span className={`font-medium`}>
            Holistic QIP Portal + Mentoring monthly plan.
          </span>
        </>
      );
    } else {
      infoText = (
        <>
          Complete your <span className={`font-medium`}>$1,997/year{` `}</span>
          subscription to a{` `}
          <span className={`font-medium`}>
            Holistic QIP Portal + Mentoring yearly plan.
          </span>
        </>
      );
    }
  }

  return (
    <div className={styles.mainContainer}>
      {/* <Dimmer active={paymentLoading}>
        <Loader
          active={paymentLoading}
          content="Submitting payment.."
        />
      </Dimmer> */}

      <div className={styles.backButtonContainer}>
        {user && user === "admin" ? (
          <>
            {!subExpired && !firstSignUp ? (
              <button
                className={`${styles.backButtonText} text-xl text-gray-400 px-10 py-2 rounded-lg hover:bg-gray-50 `}
                onClick={() => history.goBack()}
              >
                NQA dashboard
                {/* {/* <FaRegArrowAltCircleLeft */}
                {/* // className={styles.backButton} */}
                {/* // color="#C4C4C4" */}
                {/* // size={24} */}
              </button>
            ) : (
              <> </>
            )}
          </>
        ) : (
          <>
            <button
              className={`${styles.backButtonText}`}
              onClick={() => history.goBack()}
            >
              <FaRegArrowAltCircleLeft
                className={styles.backButton}
                color="#C4C4C4"
                size={24}
              />
            </button>
          </>
        )}
      </div>

      <div className={styles.mainCard}>
        {!askLogout ? (
          <div className={`${styles.cardContent} mt-24`}>
            <h1 className={`${styles.headerText} mb-5`}>Payment</h1>
            <text className={`${styles.descriptionText} mb-7`}>{infoText}</text>
            <div className={`flex justify-start items-center`}>
              <text
                className={styles.descriptionText}
              >{`Pay securely using Stripe.`}</text>
              <FaCcVisa
                className={`mr-2 ml-4`}
                style={{
                  fontSize: "30px",
                  color: "#003E94",
                }}
              />
              <FaCcMastercard
                className={`mr-2`}
                style={{
                  fontSize: "30px",
                  color: "#FD0E26",
                }}
              />{" "}
              <FaCcAmex
                className={`mr-2`}
                style={{
                  fontSize: "30px",
                  color: "#1C4AD4",
                }}
              />
              <FaCcJcb
                className={`mr-2`}
                style={{
                  fontSize: "30px",
                  color: "#004695",
                }}
              />
            </div>
            {/* <InputComponent
                  fieldName="creditCardNumber"
                  label="CC Number"
                  type="text"
                  inputValue={paymentDetailState.input.creditCardNumber}
                  setInputValue={changeInputDispatch}
                />

                <InputComponent
                  fieldName="creditCardName"
                  label="Name"
                  type="text"
                  inputValue={paymentDetailState.input.creditCardName}
                  setInputValue={changeInputDispatch}
                />

                <InputComponent
                  fieldName="cvcNumber"
                  label="CVC"
                  type="text"
                  inputValue={paymentDetailState.input.cvcNumber}
                  setInputValue={changeInputDispatch}
                />

                <InputComponent
                  fieldName="creditCardExpiry"
                  label="Expiry (MM/YY)"
                  type="text"
                  inputValue={paymentDetailState.input.creditCardExpiry}
                  setInputValue={changeInputDispatch}
                />

                <BlueButton
                  className={styles.blueButton}
                  buttonText="Next step"
                  onClick={onSubmitPaymentDetails}
                /> */}
            <CardForm
              className={styles.stripeCard}
              plan={plan}
              planVariant={planVariant}
              stripeState={stripeState}
              callback={setAskLogout}
            />
          </div>
        ) : (
          <div
            className={`bg-white w-1/2 flex flex-col justify-center items-center py-14 px-12 mt-32 rounded-lg`}
          >
            <p className={`text-center text-3xl font-medium`}>
              Payment made. Thank you!
            </p>
            <p className={`text-center text-xl pt-10 pb-14 `}>
              For changes to take effect, please log out of your QIP account and
              log back in.
            </p>
            <SkipButton
              className={styles.saveButton}
              buttonText="Log out"
              onClick={onLogoutConfirm}
            />
          </div>
        )}
      </div>
    </div>
  );
}

PaymentForm.propTypes = {
  plan: PropTypes.string,
  planVariant: PropTypes.string,
  stripeState: PropTypes.string,
};

export default PaymentForm;
