import React from "react";
import styles from "./PaymentMadeModal.module.css";
import { useHistory } from "react-router-dom";
import { Button, Modal } from "semantic-ui-react";
import BlueButton from "../../BlueButton";

const PaymentMadeModal = ({ visibility, onOpenModal, onCloseModal }) => {
  const history = useHistory();

  return (
    <Modal
      size={`small`}
      centered={true}
      closeOnDimmerClick={false}
      open={visibility}
      onOpen={onOpenModal}
      onClose={onCloseModal}
    >
      <div className={styles.modalContainer}>
        <div className={styles.modalMessage}>
          Payment made. Thank you!
          <br />
          Your additional service has been created.
          <br />
          Let&apos;s get it setup.
        </div>
        <BlueButton
          buttonText={`Next`}
          className={styles.modalButton}
          onClick={() => history.push("/qip/legal-notice")}
        />
      </div>
    </Modal>
  );
};

export default PaymentMadeModal;
