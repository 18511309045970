import * as React from "react";

import PropTypes from "prop-types";
import { Button, Input } from "semantic-ui-react";

import styles from "./QIPReportView.module.css";

import BlueButton from "../../../components/BlueButton";
import InputComponent from "../../../components/InputComponent";
import { useStoreState } from "../../../hooks";
import { generateReport } from "../../../api/requests";
import { FaSpinner } from "react-icons/fa";
import { isEmpty } from "lodash";
import FullReportModal from "../../../components/Modals/FullReportModal";

const { useState, useEffect } = React;

function QIPReportView({ onGenerateFinish, onGenerateReportError }) {
  const userId = useStoreState((state) => state.authStore.authObject.userId);
  const centerId = useStoreState((state) => state.nqaStore.serviceDetails.id);

  const isLoggedIn = useStoreState((state) => state.authStore.isLoggedIn);

  const serviceDetails = useStoreState(
    (state) => state.nqaStore.serviceDetails
  );

  const [generateLoading, setLoading] = useState(false);
  const [selectedNQAList, setSelectedNQAList] = useState([1]);
  const [seletectedReportType, setSelectedReportType] = useState(1);

  const [fieldChecked, setFieldChecked] = useState(false);

  const [reportLabel, setReportLabel] = useState("");

  const [serviceDetailsCompleted, setServiceDetailsCompleted] = useState(false);

  const [showFullReportModal, setShowFullReportModal] = useState(false);

  const onSelectNQA = (nqa) => {
    if (selectedNQAList.includes(nqa)) {
      setSelectedNQAList(selectedNQAList.filter((number) => number !== nqa));
    } else {
      setSelectedNQAList(selectedNQAList.concat(nqa));
    }
  };

  const onGenerateReportClick = () => {
    if (seletectedReportType === 4) onGenerateReportError(true);
    else onGenerateReportError(false);
  };

  const onGenerateReport = async () => {
    setFieldChecked(true);
    if (!reportLabel) {
      // alert("Please give this report a name.");
      return false;
    }

    setLoading(true);

    try {
      await generateReport(
        userId,
        centerId,
        reportLabel,
        seletectedReportType,
        selectedNQAList
      );
      await onGenerateFinish();
      // setFieldChecked(true);
    } catch (error) {
      console.log(error);
      // setFieldChecked(true);
    }
    setFieldChecked(false);
    setLoading(false);
  };

  const checkServiceDetailsCompletion = () => {
    const a =
      serviceDetails.name &&
      serviceDetails.approvalNumber &&
      serviceDetails.serviceCatId
        ? true
        : false;

    const b =
      serviceDetails.locationStreet && serviceDetails.locationSuburb
        ? true
        : false;

    const c =
      serviceDetails.postalStreet && serviceDetails.postalSuburb ? true : false;
    const d =
      serviceDetails.contactPhoneNum && serviceDetails.contactEmail
        ? true
        : false;
    const e =
      serviceDetails.providerFirstName &&
      serviceDetails.providerLastName &&
      serviceDetails.providerEmail &&
      serviceDetails.providerPhoneNum
        ? true
        : false;
    const f =
      serviceDetails.supervisorFirstName &&
      serviceDetails.supervisorLastName &&
      serviceDetails.supervisorEmail &&
      serviceDetails.supervisorPhoneNum
        ? true
        : false;
    const g =
      serviceDetails.leaderFirstName &&
      serviceDetails.leaderLastName &&
      serviceDetails.leaderEmail &&
      serviceDetails.leaderPhoneNum
        ? true
        : false;
    const h =
      serviceDetails.operatingHours && serviceDetails.operatingHours.length > 0
        ? true
        : false;
    const i =
      serviceDetails.rooms && serviceDetails.rooms.length > 0 ? true : false;
    const j =
      serviceDetails.educators && serviceDetails.educators.length > 0
        ? true
        : false;
    const k =
      serviceDetails.employees && serviceDetails.employees.length > 0
        ? true
        : false;
    const l = serviceDetails.philosophy ? true : false;
    const m = serviceDetails.logo
      ? !isEmpty(serviceDetails.logo)
        ? true
        : false
      : false;

    return (
      isLoggedIn && a && b && c && d && e && f && g && h && i && j && k && l
    );
  };

  useEffect(() => {
    setServiceDetailsCompleted(checkServiceDetailsCompletion());
  }, []);

  return (
    <>
      {showFullReportModal && (
        <FullReportModal
          visibility={showFullReportModal}
          onCloseModal={() => setShowFullReportModal(false)}
        />
      )}
      <div
        className={`${styles.createReportContainer} flex flex-col mb-1 md:px-16 lg:px-24 py-16`}
      >
        <text className={styles.descriptionText}>
          {`Easily generate Goal & Strengths reports and save them as .pdf files. Choose the type of report you need,
          a Strengths report, a Goals report or a full report and click 'generate report'. 
          You can also filter reports by individual or multiple NQA areas.`}
        </text>

        <div className={`${styles.reportTypeRow} flex justify-between`}>
          <div
            className={`${styles.reportTypeColumn} flex flex-col items-start`}
          >
            <Button
              className={
                seletectedReportType === 1
                  ? styles.reportTypeSelected
                  : styles.reportType
              }
              onClick={() => setSelectedReportType(1)}
              icon="check"
            />

            <text className={styles.reportTypeText}>Goals Report</text>

            {/* <text className={styles.reportTypeSubText}>(QIP)</text> */}
          </div>

          <div
            className={`${styles.reportTypeColumn} flex flex-col items-start`}
          >
            <Button
              className={
                seletectedReportType === 2
                  ? styles.reportTypeSelected
                  : styles.reportType
              }
              onClick={() => setSelectedReportType(2)}
              icon="check"
            />

            <text className={styles.reportTypeText}>Strengths Report</text>

            {/* <text className={styles.reportTypeSubText}>(SAT)</text> */}
          </div>

          <div
            className={`${styles.reportTypeColumn} flex flex-col items-start`}
          >
            <Button
              className={
                seletectedReportType === 3
                  ? styles.reportTypeSelected
                  : styles.reportType
              }
              onClick={() => {
                if (serviceDetailsCompleted) {
                  setSelectedReportType(3);
                } else {
                  setShowFullReportModal(true);
                }
              }}
              icon="check"
            />

            <text className={styles.reportTypeText}>Full Report</text>

            {/* <text className={styles.reportTypeSubText}>(QIP/SAT)</text> */}
          </div>

          <div
            className={`${styles.reportTypeColumn} flex flex-col items-start`}
          >
            <Button
              className={
                seletectedReportType === 4
                  ? styles.reportTypeSelected
                  : styles.reportType
              }
              onClick={() => setSelectedReportType(4)}
              icon="check"
            />

            <text className={styles.reportTypeText}>Evidences Report</text>
          </div>
        </div>

        <text className={styles.nqaReportFilterHeader}>
          Filter report by selected NQA Area
        </text>

        <div className={`${styles.nqaReportFilterRow} flex justify-between`}>
          {[...Array(7).keys()].map((nqa) => (
            <button
              className={
                selectedNQAList.includes(nqa + 1)
                  ? styles.nqaReportFilterSelected
                  : styles.nqaReportFilter
              }
              key={nqa}
              onClick={() => onSelectNQA(nqa + 1)}
            >
              NQA{nqa + 1}
            </button>
          ))}
        </div>

        <div className={styles.reportName}>
          <InputComponent
            label="Give this report a name"
            required
            showNotFilled={fieldChecked}
            inputValue={reportLabel}
            setInputValue={(fieldName, value) => setReportLabel(value)}
          />
        </div>

        <BlueButton
          // buttonText="Generate a Report"
          width="100%"
          height="80px"
          buttonText={
            generateLoading ? (
              <div className={`flex justify-center items-center`}>
                <FaSpinner className={`animate-spin`} />
                <span className={`block pl-2`}>Generating Report...</span>
              </div>
            ) : (
              <> Generate a Report</>
            )
          }
          onClick={onGenerateReport}
        />
      </div>
    </>
  );
}

QIPReportView.propTypes = {
  onGenerateFinish: PropTypes.func,
  onGenerateReportError: PropTypes.func,
};

export default QIPReportView;
