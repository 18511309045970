import * as React from "react";

import { useHistory, useParams } from "react-router-dom";
import { FaRegArrowAltCircleLeft } from "react-icons/fa";

import styles from "./BillingDetails.module.css";
import PastPayments from "../PastPayments";
import PaymentDetails from "../PaymentDetails";
import SubscriptionDetails from "../SubscriptionDetails";
import { useStoreActions, useStoreState } from "../../../hooks";
import { isEmpty } from "lodash";

const { useState, useEffect } = React;

function BillingDetails() {
  const history = useHistory();

  const { billingChoiceId } = useParams();

  const authObj = useStoreState((state) => state.authStore.authObject);

  const [selectedOption, setOption] = useState(parseInt(billingChoiceId));

  const DisplayTabChoice = () => {
    if (selectedOption === 1) {
      return <PaymentDetails />;
    } else if (selectedOption === 2) {
      return <SubscriptionDetails />;
    }

    return <PastPayments />;
  };

  useEffect(() => {
    setOption(parseInt(billingChoiceId));
  }, [billingChoiceId]);

  return (
    <div className={styles.mainContainer}>
      <div className={styles.backButtonContainer}>
        <button
          className={styles.backButtonText}
          onClick={() => history.goBack()}
        >
          <FaRegArrowAltCircleLeft
            className={styles.backButton}
            color="#C4C4C4"
            size={24}
          />
        </button>
      </div>

      <div className={styles.content}>
        <div className={styles.headerContainer}>
          <div className={styles.headerTextGroup}>
            <text className={styles.headerText}>Billing details</text>

            <text className={styles.headerSubText}>
              Payment, subscription and past invoice details.
            </text>
          </div>

          <button
            className={styles.backToDashboardButton}
            onClick={() => history.push("/qip/user-management")}
          >
            Back to dashboard
          </button>
        </div>

        <div className={styles.cardContainer}>
          <div className={styles.navigationOptionsRow}>
            <div
              className={`${
                selectedOption === 2
                  ? styles.navigationOptionSelected
                  : styles.navigationOption
              } w-1/3`}
              onClick={() => {
                history.push(`/qip/user-management/billing/2`);
                // setOption(2);
              }}
            >
              Subscription <br />
              details
            </div>
            {!isEmpty(authObj.stripeId) && (
              <>
                {" "}
                <div
                  className={`${
                    selectedOption === 1
                      ? styles.navigationOptionSelected
                      : styles.navigationOption
                  } ${styles.middleNavigationOptionBorder} w-1/3`}
                  onClick={() => {
                    history.push(`/qip/user-management/billing/1`);
                    // setOption(1);
                  }}
                >
                  Payment <br />
                  details
                </div>{" "}
                <div
                  className={`${
                    selectedOption === 3
                      ? styles.navigationOptionSelected
                      : styles.navigationOption
                  } w-1/3`}
                  onClick={() => {
                    history.push(`/qip/user-management/billing/3`);
                    // setOption(3);
                  }}
                >
                  Past <br />
                  payments
                </div>{" "}
              </>
            )}
          </div>

          <div className={styles.cardContent}>
            <DisplayTabChoice />
          </div>
        </div>
      </div>
    </div>
  );
}

export default BillingDetails;
