import React from "react";
import styles from "./ServiceDetails.module.css";
import Input from "../../_base/Input";
import { useStoreActions, useStoreState } from "../../../hooks";
import { Dropdown } from "semantic-ui-react";
import { FaSpinner } from "react-icons/fa";

const { useState, useEffect } = React;

const serviceTypeOption = [
  {
    key: 1,
    value: "Long Daycare",
    text: "Long Daycare",
  },
  {
    key: 2,
    value: "Family Daycare",
    text: "Family Daycare",
  },
  {
    key: 3,
    value: "Pre-school",
    text: "Pre-school",
  },
  {
    key: 4,
    value: "OOSH",
    text: "OOSH",
  },
];

const ServiceDetails = ({ details = [] }) => {
  const [saving, setSaving] = useState(false);

  const authObject = useStoreState((state) => state.authStore.authObject);
  const updateServiceDetails = useStoreActions(
    (actions) => actions.nqaStore.updateServiceDetails
  );

  const serviceDetails = useStoreState(
    (state) => state.nqaStore.serviceDetails
  );
  const [detailsState, setDetailsState] = useState(serviceDetails);

  const [isValidEmail, seIsValidEmail] = useState(
    /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(serviceDetails.supervisorEmail)
  );

  const onUpdateServiceDetails = async () => {
    const {
      name,
      approvalNumber,
      locationStreet,
      locationSuburb,
      locationState,
      locationPostalCode,
      supervisorFirstName,
      supervisorLastName,
      supervisorEmail,
      supervisorPhoneNum,
      supervisorMobileNum,
      supervisorFaxNum,
    } = detailsState;

    if (
      !(
        (
          isValidEmail &&
          name &&
          approvalNumber &&
          locationStreet &&
          locationSuburb &&
          // locationState &&
          // locationPostalCode &&
          supervisorFirstName &&
          supervisorLastName &&
          supervisorEmail &&
          supervisorPhoneNum
        )
        // supervisorMobileNum &&
        // supervisorFaxNum
      )
    ) {
      return;
    }

    try {
      setSaving(true);
      const payload = {
        serviceDetails: {
          ...detailsState,
          userId: authObject.userId,
        },
        centerId: detailsState.id,
      };

      await updateServiceDetails(payload);
      setSaving(false);
    } catch (error) {
      setSaving(false);
      console.log(error);
    }
  };

  return (
    <div className={` ${styles.serviceDetails} w-full md:px-8 lg:px-24 `}>
      <div className={`${styles.serviceDetail} `}>
        <div className={`flex flex-col items-start mb-10`}>
          <text className={`${styles.headerServiceText}`}>
            Service name and type
          </text>
          <text className={`${styles.headerServiceSub}`}>
            Please update the fields below where applicable.
          </text>
        </div>
        <Input
          className={`flex flex-col items-start`}
          labelClassName={`${styles.serviceLabel}`}
          inputClassName={`${styles.serviceInput}`}
          label={`Service name`}
          placeholder={`Little Smarties`}
          value={detailsState.name}
          onChange={(value) =>
            setDetailsState({ ...detailsState, name: value })
          }
        />

        <div className={`w-full flex justify-between items-center mt-8`}>
          <Input
            className={`mr-5 w-1/2 flex flex-col items-start`}
            labelClassName={`${styles.serviceLabel}`}
            inputClassName={`${styles.serviceInput}`}
            label={`Service approval number`}
            placeholder={`XXXXXXXXXXXX`}
            value={
              detailsState.approvalNumber ? detailsState.approvalNumber : ""
            }
            onChange={(value) =>
              setDetailsState({ ...detailsState, approvalNumber: value })
            }
          />

          <label className={`ml-5 w-1/2 flex flex-col items-start`}>
            <span className={`${styles.serviceLabel} pb-1`}>
              Service type<span className={`text-red-400`}>*</span>
            </span>

            <Dropdown
              className={styles.serviceDropdown}
              fluid
              selection
              placeholder="Choose"
              options={serviceTypeOption}
              defaultValue={
                serviceTypeOption[detailsState.serviceCatId - 1].value
              }
              onChange={(e, data) =>
                setDetailsState({
                  ...detailsState,
                  serviceCatId: serviceTypeOption.filter(
                    (option) => option.value === data.value
                  )[0].key,
                })
              }
            />
            <div className={`text-red-400 px-1 py-1 h-4`}></div>
          </label>
        </div>
      </div>

      <div className={`${styles.serviceDetail} mt-20`}>
        <div className={`flex flex-col items-start mb-10`}>
          <text className={`${styles.headerServiceText}`}>
            Physical location
          </text>
          <text className={`${styles.headerServiceSub}`}>
            Please update the fields below.
          </text>
        </div>

        <div className={`w-full flex justify-between items-center mt-8`}>
          <Input
            className={`mr-5 w-1/2 flex flex-col items-start`}
            labelClassName={`${styles.serviceLabel}`}
            inputClassName={`${styles.serviceInput}`}
            label={`Street address`}
            placeholder={``}
            value={
              detailsState.locationStreet ? detailsState.locationStreet : ""
            }
            onChange={(value) =>
              setDetailsState({ ...detailsState, locationStreet: value })
            }
          />

          <Input
            className={`ml-5 w-1/2 flex flex-col items-start`}
            labelClassName={`${styles.serviceLabel}`}
            inputClassName={`${styles.serviceInput}`}
            label={`Suburb`}
            placeholder={``}
            value={
              detailsState.locationSuburb ? detailsState.locationSuburb : ""
            }
            onChange={(value) =>
              setDetailsState({ ...detailsState, locationSuburb: value })
            }
          />
        </div>
        <div className={`w-full flex justify-between items-center mt-8`}>
          <Input
            className={`mr-5 w-1/2 flex flex-col items-start`}
            labelClassName={`${styles.serviceLabel}`}
            inputClassName={`${styles.serviceInput}`}
            label={`State/territory`}
            required={false}
            placeholder={``}
            value={detailsState.locationState ? detailsState.locationState : ""}
            onChange={(value) =>
              setDetailsState({ ...detailsState, locationState: value })
            }
          />

          <Input
            className={`ml-5 w-1/2 flex flex-col items-start`}
            labelClassName={`${styles.serviceLabel}`}
            inputClassName={`${styles.serviceInput}`}
            label={`Postcode`}
            isNumeric={true}
            required={false}
            placeholder={``}
            value={
              detailsState.locationPostalCode
                ? detailsState.locationPostalCode
                : ""
            }
            onChange={(value) =>
              setDetailsState({ ...detailsState, locationPostalCode: value })
            }
          />
        </div>
      </div>

      <div className={`${styles.serviceDetail} mt-20`}>
        <div className={`flex flex-col items-start mb-10`}>
          <text className={`${styles.headerServiceText}`}>
            Nominated Supervisor
          </text>
          <text className={`${styles.headerServiceSub}`}>
            Please complete the fields below.
          </text>
        </div>

        <div className={`w-full flex justify-between items-center mt-8`}>
          <Input
            className={`mr-5 w-1/2 flex flex-col items-start`}
            labelClassName={`${styles.serviceLabel}`}
            inputClassName={`${styles.serviceInput}`}
            label={`First name`}
            placeholder={``}
            value={
              detailsState.supervisorFirstName
                ? detailsState.supervisorFirstName
                : ""
            }
            onChange={(value) =>
              setDetailsState({ ...detailsState, supervisorFirstName: value })
            }
          />

          <Input
            className={`ml-5 w-1/2 flex flex-col items-start`}
            labelClassName={`${styles.serviceLabel}`}
            inputClassName={`${styles.serviceInput}`}
            label={`Last name`}
            placeholder={``}
            value={
              detailsState.supervisorLastName
                ? detailsState.supervisorLastName
                : ""
            }
            onChange={(value) =>
              setDetailsState({ ...detailsState, supervisorLastName: value })
            }
          />
        </div>
        <div className={`w-full flex justify-between items-center mt-8`}>
          <Input
            className={`mr-5 w-1/2 flex flex-col items-start`}
            labelClassName={`${styles.serviceLabel}`}
            inputClassName={`${styles.serviceInput}`}
            isEmail={true}
            validEmail={isValidEmail}
            checkIsValidEmail={(valid) => seIsValidEmail(valid)}
            label={`E-mail address`}
            placeholder={`office@yourwebsite.com`}
            value={
              detailsState.supervisorEmail ? detailsState.supervisorEmail : ""
            }
            onChange={(value) =>
              setDetailsState({ ...detailsState, supervisorEmail: value })
            }
          />

          <Input
            className={`ml-5 w-1/2 flex flex-col items-start`}
            labelClassName={`${styles.serviceLabel}`}
            inputClassName={`${styles.serviceInput}`}
            label={`Phone number`}
            isPhone={true}
            placeholder={`XXXXXXXXXXXX`}
            value={
              detailsState.supervisorPhoneNum
                ? detailsState.supervisorPhoneNum
                : ""
            }
            onChange={(value) =>
              setDetailsState({
                ...detailsState,
                supervisorPhoneNum: value,
              })
            }
          />
        </div>
        <div className={`w-full flex justify-between items-center mt-8`}>
          <Input
            className={`mr-5 w-1/2 flex flex-col items-start`}
            labelClassName={`${styles.serviceLabel}`}
            inputClassName={`${styles.serviceInput}`}
            label={`Mobile number`}
            isPhone={true}
            required={false}
            placeholder={`XXXXXXXXXXXX`}
            value={
              detailsState.supervisorMobileNum
                ? detailsState.supervisorMobileNum
                : ""
            }
            onChange={(value) =>
              setDetailsState({
                ...detailsState,
                supervisorMobileNum: value,
              })
            }
          />

          <Input
            className={`ml-5 w-1/2 flex flex-col items-start`}
            labelClassName={`${styles.serviceLabel}`}
            inputClassName={`${styles.serviceInput}`}
            label={`Fax number`}
            isPhone={true}
            required={false}
            placeholder={`XXXXXXXXXXXX`}
            value={
              detailsState.supervisorFaxNum ? detailsState.supervisorFaxNum : ""
            }
            onChange={(value) =>
              setDetailsState({ ...detailsState, supervisorFaxNum: value })
            }
          />
        </div>
      </div>
      <div className={`flex justify-center items-center my-20`}>
        <button
          disabled={saving}
          onClick={onUpdateServiceDetails}
          className={`${styles.saveServiceButton}`}
        >
          {saving ? (
            <div className={`flex justify-center items-center`}>
              <FaSpinner className={`animate-spin`} />{" "}
              <span className={`ml-3`}>Please wait...</span>
            </div>
          ) : (
            `Save service details`
          )}
        </button>
      </div>
    </div>
  );
};

export default ServiceDetails;
